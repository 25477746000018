import React, { useEffect, useRef, useState } from "react";
import "./UpdateRegisterForm.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Drawer from "@mui/material/Drawer";
import { Typography, CircularProgress, Autocomplete } from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import WorkOutlineSharpIcon from "@mui/icons-material/WorkOutlineSharp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Tooltip from "@mui/material/Tooltip";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Context from "../Context";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { industry } from "../../jobseeker/cdata";
import Profile from "../ProfileChange";
import Education from "../JobSeekerComponent/Education";
import KeySkill from "../JobSeekerComponent/KeySkill";
import ProfileSummery from "../JobSeekerComponent/ProfileSummery";
import ResumeCard from "../JobSeekerComponent/ResumeCard";
import FamilyDetail from "../JobSeekerComponent/FamilyDetail";
import VideoUploader from "../JobSeekerComponent/VideoUploader";
import useAxios from "../../../../api/useAxios";
import { Outlet, useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SchoolIcon from "@mui/icons-material/School";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GradeIcon from "@mui/icons-material/Grade";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import PrefferedRole from "../JobSeekerComponent/PrefferedRoles";
import WorkDetails from "../JobSeekerComponent/WorkDetails";
import jobMatchesimg from "../../../../img/jobMatcheImg.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 220;

const theme = createTheme();

const UpdateRegisterForm = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [open, setOpen] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [put, setput] = useState(false);
  const [quickactive, setQuickActive] = useState("quickactive");
  let [profile, setprofile] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectYear, setselectYear] = useState();
  const [state, setState] = React.useState(false);
  const [jobMatches, setJobMatches] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    ...theme.mixins.toolbar,
    justifyContent: "space-around",
  }));

  const [experience, setExperience] = useState([]);

  const [graduationValue, setGraduationValue] = useState([]);

  const Navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  const axiosData = useAxios();

  const Location = useLocation();

  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));

  const formik = useFormik({
    initialValues: {
      industry: "",
      Experience: "",
      currentLocation: "",
      work_status: "",
      linkedIn: "",
      graduation: "",
      PrefferedRole:"",
      work_details: {
        current_position: "",
        current_companyName: "",
        notice_period: "",
        current_CTC: "",
        expected_CTC: "",
      },
    },

    validationSchema: Yup.object({
      linkedIn: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?linkedin\.com\/.*/,
        "Invalid LinkedIn URL format. It should start with www.linkedin.com/ or https://www.linkedin.com/"
      ),
      Experience:
        selectYear === "Experienced"
          ? Yup.string().required("Experience year is required")
          : Yup.string(),
      work_status:
        graduationValue === "Graduate"
          ? Yup.string().required("Experience is required")
          : Yup.string(),
    }),

    onSubmit: async (values) => {
      if (profile.graduation === values.graduation) {
        if (values.work_status === "Fresher") {
          values.PrefferedRole ="";
          values.work_details ={
            current_position: "",
            current_companyName: "",
            notice_period: "",
            current_CTC: "",
            expected_CTC: "",
          };
        } else {
          values.PrefferedRole ="";
          values.work_details = {
            current_position: profile.work_details.current_position,
            current_companyName: profile.work_details.current_companyName,
            notice_period: profile.work_details.notice_period,
            current_CTC: profile.work_details.current_CTC,
            expected_CTC: profile.work_details.expected_CTC,
          };
        }
      } else {
        values.PrefferedRole ="";
        values.work_details = {
          current_position: "",
          current_companyName: "",
          notice_period: "",
          current_CTC: "",
          expected_CTC: "",
        };
      }
      if (values.work_status === "Fresher") {
        values.Experience = "";
      }
      if (values.graduation === "Student") {
        values.Experience = "";
        values.work_status = "Fresher";
      }
      setIsSubmitting(true);
      await axiosData.put(`/seeker/email/${Location.state}`, values);
      setput(true);
      setIsSubmitting(false);
      handleClose();
      formik.handleReset("");
    },
  });

  const handleClickOpen = () => {
    formik.setFieldValue("currentLocation", profile.currentLocation);
    formik.setFieldValue("graduation", profile.graduation);
    formik.setFieldValue("industry", profile.industry);
    if (profile.work_status === "Fresher") {
      formik.setFieldValue("", profile.Experience);
    } else {
      formik.setFieldValue("Experience", profile.Experience);
    }

    formik.setFieldValue("work_status", profile.work_status);
    formik.setFieldValue("linkedIn", profile.linkedIn);
    setOpen(true);
    setput(false);
  };

  useEffect(() => {
    if (seekerDetails === null || seekerDetails === undefined) {
      Navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      axiosData
        .get(`/seekers/email/${Location.state}`)
        .then((data) => {
          profile = data.data;
          if (profile.email_id === seekerDetails.emailId) {
            if(profile.Keyskill === ""||profile.Keyskill === null||profile.Keyskill === undefined){
              setJobMatches(true);
            }else if(profile.work_status === "Experienced"){
              if(profile.work_details?.current_position === ""|| profile.work_details?.current_position === null||profile.work_details?.current_position === undefined){
                setJobMatches(true);
              }else{
                setJobMatches(false);
              }
              
            }else if(profile.PrefferedRole === ""||profile.PrefferedRole === null||profile.PrefferedRole === undefined){
              setJobMatches(true);
            }else{
              setJobMatches(false);
            }
            setprofile({ ...profile });
            const industry = profile.industry;
            const emailId = profile.email_id;
            const id = profile._id;
            const name = profile.full_name;
            localStorage.setItem(
              "seeker",
              JSON.stringify({ industry, emailId, id, name })
            );
            
          } else {
          }
        })
        .catch((error) => console.error(error));
      setput(false);
      setLoading(false);
    }
  }, [put]);
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  useEffect(() => {
    setGraduationValue(formik.values.graduation);
  }, [formik.values.graduation]);

  useEffect(() => {
    setselectYear(formik.values.work_status);
  }, [formik.values.work_status]);

  const ResumeRoute = useRef(null);
  const FamilyDetailsRoute = useRef(null);
  const KeySkillRoute = useRef(null);
  const JobRoleRoute = useRef(null);
  const EducationRoute = useRef(null);
  const ProfileSummaryRoute = useRef(null);

  const handleLinkClick = (linkId, ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    setQuickActive(linkId);
  };

  useEffect(() => {
    const activeLink = sessionStorage.getItem("activeLink");
    if (activeLink === "recommendedJobs") {
      setQuickActive("quickactive6");
    } else if (activeLink === "appliedJobs") {
      setQuickActive("quickactive7");
    } else if (activeLink === "Needsupport") {
      setQuickActive("quickactive1");
    }
    sessionStorage.removeItem("activeLink");
  }, []);

  const [industryArray, setIndustryArray] = useState();

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;
      if (data) {
        setExperience(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    IndustryData();
    experienceData();
  }, []);

  if (loading === false) {
    return (
      <>
        <Context.Provider
          value={{
            profile: profile,
            setput: (value) => {
              setput(value);
            },
          }}
        >
          <div>
            <ThemeProvider theme={theme}>
              <Box className="fullcontent" sx={{ p: 2 }}>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-describedby="alert-dialog-slide-description"
                  sx={{zIndex:"99999"}}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{"Update Profile"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Grid item xs={12} sm={12} md={8}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ mt: 1 }}>
                              <FormControl fullWidth>
                                <FormLabel
                                  align="left"
                                  id="demo-row-radio-buttons-group-label"
                                  required
                                >
                                  Graduation
                                </FormLabel>
                                <RadioGroup
                                  defaultValue={formik.values.graduation}
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="graduation"
                                  onBlur={formik.handleBlur}
                                  value={formik.values.graduation}
                                  onChange={(e, v) => {
                                    formik.setFieldValue(
                                      "graduationValue",
                                      e.target.value
                                    );
                                  }}
                                  {...formik.getFieldProps("graduation")}
                                >
                                  <FormControlLabel
                                    value="Student"
                                    control={<Radio />}
                                    label={"Student"}
                                  />
                                  <FormControlLabel
                                    value="Graduate"
                                    control={<Radio />}
                                    label={"Graduate"}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {formik.values.graduation === "Graduate" ? (
                                <FormControl>
                                  <FormLabel
                                    align="left"
                                    id="demo-row-radio-buttons-group-label"
                                  >
                                    Experience
                                  </FormLabel>
                                  <RadioGroup
                                    defaultValue={formik.values.work_status}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="work_status"
                                    value={formik.values.work_status}
                                    onChange={(e, v) => {
                                      formik.setFieldValue(
                                        "selectYear",
                                        e.target.value
                                      );
                                    }}
                                    {...formik.getFieldProps("work_status")}
                                  >
                                    <FormControlLabel
                                      value="Fresher"
                                      control={<Radio />}
                                      label="Fresher"
                                    />
                                    <FormControlLabel
                                      value="Experienced"
                                      control={<Radio />}
                                      label="Experienced"
                                    />
                                  </RadioGroup>
                                  {formik.touched?.work_status &&
                                    formik.errors?.work_status && (
                                      <Typography
                                        sx={{ color: "red", fontSize: "13px" }}
                                      >
                                        {formik.errors?.work_status}
                                      </Typography>
                                    )}
                                </FormControl>
                              ) : null}
                              {formik.values.work_status === "Experienced" &&
                              formik.values.graduation === "Graduate" ? (
                                <FormControl sx={{ mt: 1 }} fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Work Experience
                                  </InputLabel>
                                  <Select
                                    name="Experience"
                                    labelId="demo-simple-select-label"
                                    id="Experience"
                                    label="Work Experience"
                                    value={formik.values.Experience}
                                    onChange={formik.handleChange}
                                    MenuProps={MenuProps}
                                  >
                                    {experience
                                      ? experience.map((data, index) => (
                                          <MenuItem value={data} keys={index}>
                                            {data}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </Select>
                                </FormControl>
                              ) : null}{" "}
                              {formik.touched?.Experience &&
                                formik.errors?.Experience && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "13px" }}
                                  >
                                    {formik.errors?.Experience}
                                  </Typography>
                                )}
                              {industryArray && (
                                <FormControl sx={{ mt: 1 }} fullWidth required>
                                  <Autocomplete
                                    sx={{ mt: 2 }}
                                    filterOptions={filterOptions}
                                    id="industry"
                                    name="industry"
                                    options={industryArray}
                                    onBlur={formik.handleBlur}
                                    onChange={(event, value) => {
                                      setSelectedIndustry(value);

                                      formik.setFieldValue("industry", value);
                                    }}
                                    disableClearable={
                                      formik.values.industry ? false : true
                                    }
                                    value={formik.values.industry}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        id="industry"
                                        label="Preferred Industry"
                                        name="industry"
                                        onClick={() =>
                                          params.inputProps.onInputClick()
                                        }
                                      />
                                    )}
                                    onOpen={(event) => event.preventDefault()}
                                    PopperComponent={({
                                      children,
                                      ...popperProps
                                    }) => (
                                      <Popper
                                        {...popperProps}
                                        placement="bottom-start"
                                        modifiers={{
                                          flip: {
                                            enabled: false,
                                          },
                                          preventOverflow: {
                                            enabled: true,
                                            boundariesElement: "scrollParent",
                                          },
                                        }}
                                        PopperProps={{
                                          placement: "bottom-start", // Placement set to "bottom-start"
                                        }}
                                      >
                                        <Paper>{children}</Paper>
                                      </Popper>
                                    )}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: "180px",
                                        overflowY: "auto",
                                      },
                                    }}
                                  />
                                </FormControl>
                              )}
                              <TextField
                                margin="normal"
                                fullWidth
                                multiline
                                placeholder="Mention the city you live in"
                                id="Current Location"
                                label="Current Location"
                                name="currentLocation"
                                value={formik.values.currentLocation}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z.,' ]/gi,
                                      ""
                                    );                              
                                    formik.setFieldValue(
                                      "currentLocation",
                                      value
                                    );
                                  }
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <RoomOutlinedIcon
                                        sx={{ color: "#135c82" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                margin="normal"
                                fullWidth
                                placeholder="LinkedIn Url"
                                id="linkedIn"
                                label="linkedIn"
                                name="linkedIn"
                                onBlur={formik.handleBlur}
                                value={formik.values.linkedIn}
                                onChange={formik.handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LinkedInIcon sx={{ color: "#3e82d2" }} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {formik.touched.linkedIn &&
                              formik.errors.linkedIn ? (
                                <div style={{ color: "red" }}>
                                  {formik.errors.linkedIn}
                                </div>
                              ) : null}
                            </Box>
                          </Box>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" type="submit">
                        Update{" "}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            style={{
                              color: "#ffffff",
                              marginLeft: 12,
                            }}
                          />
                        )}
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>

                <Dialog
                  open={jobMatches}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText style={{ textAlign: "center" }}>
                      <p
                        style={{
                          fontFamily: "Poppins-SemiBold",
                          color: "#444",
                          fontSize: "18px",
                        }}
                      >
                        Optimize job matches—complete your profile today.
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins-SemiBold",
                          color: "#999",
                          fontSize: "12px",
                          marginTop: "-10px",
                        }}
                      >
                        Complete your profile mandatory fields for personalized
                        job recommendations. Your input guides us to find the
                        best opportunities for you.
                      </p>
                      <img
                        src={jobMatchesimg}
                        alt="jobMatchesimg"
                        height="300px"
                        width="300px"
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: -4,
                      mb: 1,
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: "4px",
                        fontFamily: "Poppins-Medium",
                        backgroundColor: "#EC2172",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#d91261",
                        },
                      }}
                      onClick={() => {
                        if(profile.Keyskill === ""||profile.Keyskill === null||profile.Keyskill === undefined){
                          setJobMatches(false);
                          handleLinkClick("quickactive2", KeySkillRoute);
                          sessionStorage.clear();
                        }else if(profile.work_details.current_position === ""||profile.work_details.current_position === null||profile.work_details.current_position === undefined){
                          setJobMatches(false);
                          handleLinkClick("quickactive21", JobRoleRoute);
                          sessionStorage.clear();
                        }else{
                          setJobMatches(false);
                        }
                       
                      }}
                    >
                      Okay, Got it!
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid container>
                  <Grid
                    sx={{
                      mb: 1,
                      pt: 1,
                      pl: 2,
                      mt: -1,
                      position: "sticky",
                      top: "0",
                      backgroundColor: "white",
                      borderRadius: 2,
                    }}
                    id="mobileView"
                    container
                    xs={12}
                    sm={12}
                  >
                    <div id="mobileView">
                      <div
                        style={{
                          display: "flex",
                          width: "90%",
                        }}
                      >
                        <MenuIcon onClick={toggleDrawer(true)} />

                        <Typography sx={{ fontSize: "18px", pb: 1, ml: 1 }}>
                          <b>Quick Links</b>
                        </Typography>
                      </div>

                      <Drawer
                        className="QDrawer"
                        sx={{
                          width: drawerWidth,
                          flexShrink: 0,
                          "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            boxSizing: "border-box",
                            backgroundColor: "#339fcd",
                          },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={state}
                        onClose={toggleDrawer(false)}
                      >
                        <DrawerHeader sx={{ backgroundColor: "white" }}>
                          <Typography sx={{ fontSize: "18px" }}>
                            <b>Quick Links</b>
                          </Typography>{" "}
                          <CloseIcon
                            sx={{ ml: 0.3 }}
                            onClick={toggleDrawer(false)}
                          />
                        </DrawerHeader>
                        <Divider />
                        <Grid container flexDirection="column">
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              mt: 2,
                              display: "flex",
                            }}
                          >
                            <InsertDriveFileIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />{" "}
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() =>
                                handleLinkClick("quickactive", ResumeRoute)
                              }
                            >
                              Resume
                            </Typography>
                          </Grid>
                          {profile.work_status === "Fresher" ? (
                            <Grid
                              onClick={toggleDrawer(false)}
                              sx={{
                                pb: 2,
                                cursor: "pointer",
                                ml: 3,
                                display: "flex",
                                mt: 1,
                              }}
                            >
                              <ContactSupportIcon
                                sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                              />
                              <Typography
                                sx={{ fontWeight: 550, color: "white" }}
                                onClick={() => {
                                  handleLinkClick(
                                    "quickactive1",
                                    FamilyDetailsRoute
                                  );
                                }}
                              >
                                Need Job Support
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <GradeIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() =>
                                handleLinkClick("quickactive2", KeySkillRoute)
                              }
                            >
                              Key Skill
                            </Typography>
                          </Grid>
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <GradeIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() =>
                                handleLinkClick("quickactive21", JobRoleRoute)
                              }
                            >
                              {profile.work_status === "Experienced"
                                ? "Work Details"
                                : "Preferred Job Role"}
                            </Typography>
                          </Grid>
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <SchoolIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() =>
                                handleLinkClick("quickactive3", EducationRoute)
                              }
                            >
                              Education
                            </Typography>
                          </Grid>
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <SummarizeIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() =>
                                handleLinkClick(
                                  "quickactive4",
                                  ProfileSummaryRoute
                                )
                              }
                            >
                              Profile Summary
                            </Typography>
                          </Grid>
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <WorkOutlineSharpIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() => {
                                Navigate("/main/RecommendedJob", {
                                  state: {
                                    industry: profile.industry,
                                    _id: profile._id,
                                    email_id: profile.email_id,
                                  },
                                });
                              }}
                            >
                              Recommended Jobs
                            </Typography>
                          </Grid>
                          <Grid
                            onClick={toggleDrawer(false)}
                            sx={{
                              pb: 2,
                              cursor: "pointer",
                              ml: 3,
                              display: "flex",
                              mt: 1,
                            }}
                          >
                            <WorkOutlineSharpIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: 0.5 }}
                            />
                            <Typography
                              sx={{ fontWeight: 550, color: "white" }}
                              onClick={() => {
                                Navigate("/main/AppliedJobs", {
                                  state: {
                                    industry: profile.industry,
                                    _id: profile._id,
                                    email_id: profile.email_id,
                                  },
                                });
                              }}
                            >
                              Applied Jobs
                            </Typography>
                          </Grid>
                        </Grid>
                      </Drawer>
                    </div>
                  </Grid>

                  <Grid
                    container
                    className="seekerCard"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ p: 3, behavior: "smooth" }}
                    id="basicDetailGrid"
                  >
                    <Grid xs={12} sm={12} md={12} lg={7}>
                      <Box className="profileTopContent">
                        <Box className="profileImageContent">
                          <Profile
                            setput={setput}
                            number={profile.mobile_number}
                          />
                        </Box>
                        <Box sx={{ ml: 3, mt: -1 }}>
                          <Box
                            className="profileFullName"
                            sx={{ display: "flex", width: "100%" }}
                          >
                            <Typography
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              {profile.full_name}
                            </Typography>
                            <Button sx={{ mt: 0.5 }} onClick={handleClickOpen}>
                              <Tooltip title="Edit" arrow>
                                <EditIcon />
                              </Tooltip>
                            </Button>
                          </Box>

                          <Box className="avator1" sx={{ mt: 1, ml: 3 }}>
                            {profile.email_id && (
                              <div className="profileEmail">
                                <Grid container>
                                  <Grid lg={1} md={1} sm={1} xs={1}>
                                    <MailOutlineIcon
                                      className="profileIconView"
                                      sx={{ mr: 1, color: "#135c82" }}
                                    />
                                  </Grid>
                                  <Grid
                                    sx={{ pl: 1 }}
                                    lg={11}
                                    md={11}
                                    sm={11}
                                    xs={10}
                                  >
                                    <Typography
                                      gutterBottom
                                      className="profileEmailTypography"
                                      sx={{
                                        ml: 0.4,
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {profile.email_id}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {profile.mobile_number && (
                              <div
                                style={{ display: "flex" }}
                                className="profileNum"
                              >
                                <SmartphoneIcon
                                  className="profileIconView"
                                  sx={{ mr: 1, color: "rgb(19 92 130)" }}
                                />
                                <Typography className="profileNumTypography">
                                  +91 {profile.mobile_number}
                                </Typography>
                              </div>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="basicDetailSize">
                        <Grid container>
                          <Grid item lg={6} md={12} xs={12}>
                            {profile.currentLocation ? (
                              <Grid sx={{ display: "flex", mt: 1 }}>
                                <RoomOutlinedIcon
                                  className="profileIconView"
                                  sx={{ mr: 1, color: "#135c82" }}
                                />
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                  className="basicDetailSizeTypography"
                                >
                                  {profile.currentLocation}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>

                          <Grid item lg={6} md={12} xs={12}>
                            {profile.industry ? (
                              <Grid sx={{ display: "flex", mt: 1 }}>
                                <BusinessOutlinedIcon
                                  className="profileIconView"
                                  sx={{ mr: 1, color: "rgb(19 92 130)" }}
                                />{" "}
                                <Typography className="basicDetailSizeTypography">
                                  {profile.industry}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>

                          {profile.graduation ? (
                            <Grid item lg={6} md={12} xs={12}>
                              <Grid sx={{ display: "flex", mt: 1 }}>
                                <WorkOutlineSharpIcon
                                  className="profileIconView"
                                  sx={{ mr: 1, color: "#135c82" }}
                                />
                                <Typography className="basicDetailSizeTypography">
                                  {profile.graduation}
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : null}

                          {profile.work_status ? (
                            <Grid item lg={6} md={12} xs={12}>
                              <Grid sx={{ display: "flex", mt: 1 }}>
                                <WorkOutlineSharpIcon
                                  className="profileIconView"
                                  sx={{ mr: 1, color: "#135c82" }}
                                />
                                <Typography className="basicDetailSizeTypography">
                                  {profile.work_status}
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : null}

                          {profile.work_status === "Experienced" ? (
                            <Grid item lg={6} md={12} xs={12}>
                              <Grid sx={{ display: "flex", mt: 1 }}>
                                {profile.Experience && (
                                  <>
                                    {" "}
                                    <CalendarTodayIcon
                                      className="profileIconView"
                                      sx={{ mr: 1, color: "rgb(19 92 130)" }}
                                    />
                                    <Typography className="basicDetailSizeTypography">
                                      {profile.Experience}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          ) : null}

                          {profile.linkedIn ? (
                            <>
                              <Grid item lg={6} md={12} xs={12}>
                                <Grid sx={{ display: "flex", mt: 1 }}>
                                  {" "}
                                  <LinkedInIcon
                                    className="profileIconView"
                                    sx={{ mr: 1, color: "#3e82d2" }}
                                  />
                                  <Typography className="basicDetailSizeTypography">
                                    <a
                                      className="linkA"
                                      href={profile.linkedIn}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {profile.linkedIn}
                                    </a>
                                  </Typography>{" "}
                                </Grid>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid className="videoGrid" xs={12} sm={12} md={12} lg={5}>
                      <VideoUploader setput={setput} />
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      mt: 2,
                      mr: 2,
                      pt: 2,
                      pl: 2,
                      height: "20rem",
                    }}
                    className="seekerCard"
                    id="hide"
                    container
                    xs={12}
                    sm={12}
                    md={2.3}
                    lg={1.8}
                  >
                    <div style={{ height: "300px" }}>
                      <Grid container flexDirection="column">
                        <Typography sx={{ fontSize: "18px", pb: 2 }}>
                          <b>Quick Links</b>
                        </Typography>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              handleLinkClick("quickactive", ResumeRoute);
                              sessionStorage.clear();
                            }}
                          >
                            Resume
                          </Typography>
                        </Grid>
                        {profile.work_status === "Fresher" ? (
                          <Grid sx={{ pb: 2, cursor: "pointer" }}>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 550 }}
                              id={
                                quickactive === "quickactive1"
                                  ? "quickactive"
                                  : "quickactive1"
                              }
                              onClick={() => {
                                handleLinkClick(
                                  "quickactive1",
                                  FamilyDetailsRoute
                                );
                                sessionStorage.setItem(
                                  "activeLink",
                                  "Needsupport"
                                );
                              }}
                            >
                              Need Job Support
                            </Typography>
                          </Grid>
                        ) : null}
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive2"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              handleLinkClick("quickactive2", KeySkillRoute);
                              sessionStorage.clear();
                            }}
                          >
                            Key Skill
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive21"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              handleLinkClick("quickactive21", JobRoleRoute);
                              sessionStorage.clear();
                            }}
                          >
                            {profile.work_status === "Experienced"
                              ? "Work Details"
                              : "Preferred Job Role"}
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive3"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              handleLinkClick("quickactive3", EducationRoute);
                              sessionStorage.clear();
                            }}
                          >
                            Education
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive4"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              handleLinkClick(
                                "quickactive4",
                                ProfileSummaryRoute
                              );
                              sessionStorage.clear();
                            }}
                          >
                            Profile Summary
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive6"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              sessionStorage.setItem(
                                "activeLink",
                                "recommendedJobs"
                              );
                              Navigate("/main/RecommendedJob", {
                                state: {
                                  industry: profile.industry,
                                  _id: profile._id,
                                  email_id: profile.email_id,
                                },
                              });
                            }}
                          >
                            Recommended Jobs
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 550 }}
                            id={
                              quickactive === "quickactive7"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              sessionStorage.setItem(
                                "activeLink",
                                "appliedJobs"
                              );
                              Navigate("/main/AppliedJobs", {
                                state: {
                                  industry: profile.industry,
                                  _id: profile._id,
                                  email_id: profile.email_id,
                                },
                              });
                            }}
                          >
                            Applied Jobs
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid
                    className="sideGridScrollHide"
                    sx={{
                      mt: 2,
                    }}
                    container
                    xs={12}
                    sm={12}
                    md={9.4}
                    lg={10}
                  >
                    <Grid
                      ref={ResumeRoute}
                      className="seekerCard"
                      sx={{ p: 4, behavior: "smooth" }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <ResumeCard setput={setput} />
                    </Grid>
                    {profile.work_status === "Fresher" ? (
                      <Grid
                        ref={FamilyDetailsRoute}
                        className="seekerCard"
                        sx={{ p: 4, mt: 2, behavior: "smooth" }}
                        xs={12}
                        md={12}
                        lg={12}
                        id={
                          quickactive === "quickactive1"
                            ? "quickactive"
                            : "quickactive1"
                        }
                        onClick={() => {
                          handleLinkClick("quickactive1", FamilyDetailsRoute);
                          sessionStorage.setItem("activeLink", "Needsupport");
                        }}
                      >
                        <FamilyDetail setput={setput} />
                      </Grid>
                    ) : null}
                    <Grid
                      ref={KeySkillRoute}
                      className="seekerCard"
                      sx={{ p: 4, mt: 2, behavior: "smooth" }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <KeySkill setput={setput} />
                    </Grid>
                    <Grid
                      ref={JobRoleRoute}
                      className="seekerCard"
                      sx={{ p: 4, mt: 2, behavior: "smooth" }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      {profile.work_status === "Experienced" ? (
                        <WorkDetails setput={setput} />
                      ) : (
                        <PrefferedRole setput={setput} />
                      )}
                    </Grid>

                    <Grid
                      ref={EducationRoute}
                      className="seekerCard"
                      sx={{ p: 4, mt: 2, behavior: "smooth" }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <Education setput={setput} />
                    </Grid>

                    <Grid
                      ref={ProfileSummaryRoute}
                      className="seekerCard"
                      sx={{ p: 4, mt: 2, behavior: "smooth" }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <ProfileSummery setput={setput} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </ThemeProvider>
          </div>
        </Context.Provider>
        <Outlet />
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default UpdateRegisterForm;
