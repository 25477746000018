import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  MailOutline as MailOutlineIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./employer.css";
import { Data } from "./Data";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputAdornment from "@mui/material/InputAdornment";
import logo from "../../../img/logo_for-landingpage-3.png";
import BusinessIcon from "@mui/icons-material/Business";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate, Outlet } from "react-router";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Popper from "@mui/material/Popper";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useLocationFind } from "../LocationFind";

import leftimg from "../../../img/Left-employer.png";
import lefttamilimg from "../../../img/Left-tamil-employer.png";
import { FormHelperText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Industry from "../../../img/economy 2.png";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import Designation from "../../../img/cv 1.png";
import sussesfullimg from "../../../img/Group 28.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import districtActive from "../../../img/location-active.png";
import Location from "../../../img/location (1) 1.png";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createTheme();

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function Employer() {
  const storedState = localStorage.getItem("currentLocationState");
  const storedCountry = localStorage.getItem("currentLocationCountry");
  const storedArea = localStorage.getItem("currentLocationArea");
  const storedPincode = localStorage.getItem("currentLocationPincode");

  const { locationData, locatioError } = useLocationFind();
  const location = useLocation();

  const { t, i18n, ready } = useTranslation();
  const ref = React.createRef(null);
  const inputRef = useRef(null);
  const axiosData = useAxios();
  const [focusout, setfocus] = useState();
  const [focusNo, setFocusNo] = useState();
  const [emailErr, setEmailError] = useState(true);
  const [valid, setvalid] = useState(true);
  const [pinLoad, setpinLoad] = useState(false);

  const [selectedIndustry, setSelectedIndustry] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [industryArray, setIndustryArray] = useState();
  const [districtData, setDistrictData] = useState([]);

  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");

  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [comdata, setComdata] = useState([]);

  const navigate = useNavigate();

  const [alertm, setalert] = useState(false);
  const [alerte, setalerte] = useState(false);

  const [district, setDistrict] = useState([]);

  useEffect(() => {
    if (alertm !== false) {
      setMobilePopUp(true);
      formik.setFieldValue("mobile_number", "");
    }
    setalert(false);
  }, [alertm]);
  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
      formik.setFieldValue("email_id", "");
    }
    setalerte(false);
  }, [alerte, location.state]);

  const errors = {};
  const validate = async (values) => {
    const errors = {};
    if (!values.mobile_number) {
      errors.mobile_number = t("Mobile_Number_must_be_Required");
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setvalid(true);
    } else if (/^\d{10}$/.test(values.mobile_number)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `users/Mobile/${values.mobile_number}`
          );

          if (response.status === 200) {
            setalert(true);
          } else if (response.status === 204) {
            setalert(false);
          }

          setvalid(false);

          return () => controller.abort();
        } catch (error) {}
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      company_name: "",
      industry: "",
      district: "",
      contact_person: "",
      designation: "",
      email_id: "",
      mobile_number: "",
      company_total_employees: "",
      pincode: "",
      state: "Tamil Nadu",
      country: "",
      currentLocation: "",
    },
    validate,
    validationSchema: Yup.object({
      company_name: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Company_Name_must_be_Required"))
        .matches(/^[^'].*[^']$/, t("Invalid_format"))
        .test(
          t("no_repeated_single_quotes"),
          t("String_cannot"),
          (value) => {
            return !/^'{2,}(?:\s*'{1,}[^']*')*'$/.test(value);
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' ' ' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "''";
          }
        ),
      industry: Yup.string().required(t("Select_Industry2")),
      district: Yup.string().required(t("Select_District2")),
      company_total_employees: Yup.string().required(
        t("Select_company_total_employees")
      ),
      currentLocation: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Current_Location_must_be_Required")),
      contact_person: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Contact_person_name_is_required")),
      designation: Yup.string()
        .min(3, t("Must_be_minimum_3_characters_or_above"))
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Designation_must_be_Required")),
      mobile_number: Yup.number()
        .required(t("Mobile_Number_must_be_Required2")),
      pincode: Yup.string().required(t("pincode_is_Required")),
      state: Yup.string().required("state must be Required"),
    }),

    onSubmit: (values) => {
      // const pincodeSplit = values.pincode.split("/");
      // const [a, b] = pincodeSplit;
      // values.pincode = a;
      // values.area = b;
      // if (storedCountry) {
      //   values.state = storedState;
      // }
      // if (storedCountry) {
      //   values.country = storedCountry;
      // }
      // values.email_id = formEmail;
      // setIsSubmitting(true);
      // Apifetch(values);
    },
  });
  const submitForm = (values) => {
    const pincodeSplit = values.pincode.split("/");
    const [a, b] = pincodeSplit;
    values.pincode = a;
    values.area = b;

    if (storedCountry) {
      values.state = storedState;
    }
    if (storedCountry) {
      values.country = storedCountry;
    }
    values.registertype = location.state.registertype;
    values.registerby = location.state.registerby;
    values.email_id = formEmail;
    setIsSubmitting(true);
    Apifetch(values);
  };
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const Apifetch = (data) => {
    axiosData
      .post("employers", data)
      .then((res) => {
        axiosData
          .post(
            "users",
            {
              usertype: "provider",
              status: "InActive",
              uniqueId: data.mobile_number,
              password: data.mobile_number,
              email_id: data.email_id,
              user_id: res.data.user_id,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {});
        setIsSubmitting(false);
        // navigate(
        //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
        // );
        handleClickOpened();
        // navigate(
        //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
        // );
        handleClickOpened();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const [adDistrict, setAdDistrict] = useState([]);
  const [PincodeData, setPincodeData] = useState([]);
  const [PostOfficeData, setPostOfficeData] = useState([]);
  const [newPincode, setPincode] = useState([]);

  const areaPincode = async (values) => {
    // setAdDistrict(values);
    try {
      setpinLoad(true);
      let getOneAxiospin = [];

      if (formik.values.state === "Tamil Nadu") {
        let getOneAxiospin1 = await axiosData.get(
          `/distritarea/getArea/${values}`
        );
        getOneAxiospin = getOneAxiospin1.data;
      } else {
        const getOnepinAxios = await axiosData.get(
          `/General/PuducherryPostoffice`
        );
        const data = getOnepinAxios?.data?.collections?.data;

        if (data) {
          const getDistrictPin = data.filter((i) => {
            return i.District === values;
          });
          getOneAxiospin = [...getDistrictPin];
        }
      }
      setpinLoad(false);
      const datapin = getOneAxiospin;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        setPincodeData(getpin[0]);
        setPostOfficeData(getpost[0]);
        setPincode(datapin);
        setComdata([...getPostFinal]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/General/${values}/pincode`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.pincode;
        });
        const getpost = datapin.map((i) => {
          return i.officeName;
        });
        const getpost1 = getpost;
        const result = getpin.reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const handleEmailBlur = () => {
    if (!formEmail) {
      setFormEmailError(t("EmailId_must_be_Required"));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formEmail)) {
      setFormEmailError(t("Invalid_email_format"));
    } else {
      setFormEmailError("");
    }
  };

  const handleEmailChange = async (event) => {
    const email_id = event.target.value.replace(/[^a-z0-9@.]/gi, "");
    setFormEmail(email_id);
    if (!email_id) {
      setfocus(true);
      setFormEmailError(t("EmailId_must_be_Required"));
      errors.email_id = t("EmailId_must_be_Required");
      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setfocus(false);
      setFormEmailError(t("Invalid_email_address"));
      errors.email_id = t("Invalid_email_address");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setEmailError(true);
      setFormEmailError("");
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
            formik.setFieldValue("email_id", event.target.value);
          }
        } catch (error) {}
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      errors.email_id = "Invalid email address";
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      setEmailError(true);
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const lang = sessionStorage.getItem("lang");

  const employeroptions = [
    "1 to 50",
    "51 to 150",
    "151 to 250",
    "251 to 500",
    "501 to 750",
    "751 to 900",
    "901 to 1000",
    "1001+",
  ];
  const [openok, setOpenok] = React.useState(false);
  const themeds = useTheme();
  const fullScreen = useMediaQuery(themeds.breakpoints.down("md"));

  const handleClickOpened = () => {
    if (location.state.registertype === "home") {
      setOpenok(true);
    } else {
      navigate(-1);
    }
  };

  const handleCloseed = () => {
    setOpenok(false);
    navigate(ROUTINGDATA.LAND);
  };

  const votingDistrict  = async (district) => {
    try {
      const getOneAxios =await axiosData.get(`/General/countries/${district}`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.districtName;
        });
        data.sort();
        setDistrictData(data);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const PuducherryState = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/Puducherry`);
      const data = getOneAxios?.data?.collections?.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchCountryData = async(pincodeValue)=> {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincodeValue}`);
      if (response.data && response.data[0].PostOffice) {
        setpinLoad(true);
          const datapin = response.data[0].PostOffice;
          const postalCodes = datapin.filter(postOffice => postOffice.District.toLowerCase() === formik.values.district.toLowerCase());
          if(postalCodes.length > 0){
            if (datapin) {
              const getpin = datapin.map((i) => {
                return i.Pincode;
              });
              const getpost = datapin.map((i) => {
                return i.Name;
              });
              const getpost1 = getpost;
              const result = getpin.reduce(
                (accumulator, currentPincode, index) => {
                  return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
                },
                []
              );
              const getPostFinal = result.map((i) => {
                return i;
              });
              setPincodeData(getpin);
              setPostOfficeData(getpost);
              setPincode(datapin);
              setComdata([...getPostFinal]);
              setpinLoad(false);
            }
          }else{
            setPincodeData([]);
            setPostOfficeData([]);
            setPincode([]);
            setComdata([]);
            setpinLoad(false);
          }
        
      } else {
        console.log("No postal codes found for the specified district.");
      }
    } catch (error) {
      console.error("Error fetching postal codes:", error.message);
    }

  };
  const votingDistrictOthers = async (state) => {
    const stateValue = state === ""?"all":state;
    try {
      const getOneAxios = await axiosData.get(`/statewiseDistrict/${stateValue}`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    IndustryData();
    if (storedState === "Tamil Nadu") {
      votingDistrict();
    }else
    if (storedState === "Puducherry") {
      PuducherryState();
    }else{
      votingDistrictOthers(storedState);
    }  
  }, []);

  if (ready) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Email_Id_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Mobile_number_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Mobile_number_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="main-grid"
        >
          <CssBaseline />
          <Grid className="img-grid" item xs={false} sm={false} md={4}>
            <Box className="sideimg">
              <div className="sideimglogo">
                <img src={logo} alt="logo" style={{ width: "75%" }} />
              </div>
              <div className="sidecontect">
                <Typography
                  variant="h6"
                  className={lang === "tamil" ? "side-tamil-1" : "side-english"}
                  gutterBottom
                >
                  {t("Side_Content2")}
                </Typography>
                <div className="listofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Build_Your_Company2")}
                  </div>
                </div>
                <div className="listofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Hire_Employees")}
                  </div>
                </div>
                <div className="listofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Find_Your_Employers")}
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid
            className="second-grid"
            item
            xs={12}
            sm={12}
            md={8}
            component={Paper}
            elevation={4}
            square
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  my: 3,
                  mx: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BusinessIcon
                  sx={{ mb: 2, width: 56, height: 56, color: "#ed1e70" }}
                />

                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ color: "#135c82" }}
                >
                  {t("Employer_Registration")}
                </Typography>

                <Box sx={{ mt: 1 }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        placeholder={t("Enter_Your_Company_Name")}
                        id="company_name"
                        label={t("Company_Name")}
                        name="company_name"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z0-9()&@ .$#_-]/gi,
                              ""
                            );
                            formik.setFieldValue("company_name", value);
                          }
                        }}
                        value={formik.values.company_name}
                      />
                      {formik.touched.company_name &&
                      formik.errors.company_name ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.company_name}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        placeholder={t("Enter_Your_Email_ID2")}
                        id="email_id"
                        label={t("Email_Id2")}
                        name="formEmail"
                        onBlur={handleEmailBlur}
                        ref={ref}
                        onBlurCapture={() => {
                          setfocus(true);
                        }}
                        onChange={handleEmailChange}
                        value={formEmail}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formEmailError && focusout ? (
                        <div style={{ color: "red" }}>{formEmailError}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {industryArray && (
                        <FormControl sx={{ mt: 1, mb: 1 }} fullWidth required>
                          <Autocomplete
                            disableClearable={
                              formik.values.industry ? false : true
                            }
                            filterOptions={filterOptions}
                            id="industry"
                            name="industry"
                            options={industryArray}
                            onBlur={formik.handleBlur}
                            onChange={(event, value) => {
                              setSelectedIndustry(value);
                              formik.setFieldValue("industry", value);
                            }}
                            value={formik.values.industry}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="industry"
                                label={t("Preffered_Industry2")}
                                name="industry"
                                // onClick={() => params.inputProps.onInputClick()}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: { maxHeight: "180px", overflowY: "auto" },
                            }}
                          />
                          {formik.touched.industry && formik.errors.industry ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.industry}
                            </div>
                          ) : null}
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                      <FormControl fullWidth required>
                        <Autocomplete
                          disableClearable={
                            formik.values.district ? false : true
                          }
                          fullWidth
                          filterOptions={filterOptions}
                          name="district"
                          id="district"
                          value={formik.values.district}
                          onChange={(event, value) => {
                            formik.setFieldValue("district", value);
                            searchobj(value);
                            formik.setFieldValue("pincode", "");
                          }}
                          onBlur={formik.handleBlur}
                          options={districtData}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={t("District")}
                              //onClick={() => params.inputProps.onInputClick()}
                              InputLabelProps={{
                                style: { marginLeft: "7px" },
                              }}
                            />
                          )}
                          onOpen={(event) => event.preventDefault()}
                          PopperComponent={({ children, ...popperProps }) => (
                            <Popper
                              {...popperProps}
                              placement="bottom-start"
                              modifiers={{
                                flip: {
                                  enabled: false,
                                },
                                preventOverflow: {
                                  enabled: true,
                                  boundariesElement: "scrollParent",
                                },
                              }}
                              PopperProps={{
                                placement: "bottom-start", // Placement set to "bottom-start"
                              }}
                            >
                              <Paper>{children}</Paper>
                            </Popper>
                          )}
                          ListboxProps={{
                            style: { maxHeight: "180px", overflowY: "auto" },
                          }}
                        />
                        {formik.touched.district && formik.errors.district ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.district}
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          formik.values.district ? "" : "please select District"
                        }
                      >
                        <FormControl fullWidth required sx={{ marginY: 2 }}>
                          <Autocomplete
                            disableClearable={
                              formik.values.pincode ? false : true
                            }
                            disablePortal
                            freeSolo
                            disabled={
                              formik.values.district && !pinLoad ? false : true
                            }
                            id="pincode"
                            name="pincode"
                            options={comdata}
                            value={formik.values.pincode}
                            onChange={(event, value) => {
                              formik.setFieldValue("pincode", value);
                            }}
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField
                                required
                                // onChange={formik.handleChange}
                                {...params}
                                label={t("Pincode")}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {pinLoad ? (
                                        <CircularProgress
                                          // size={24}
                                          style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            color: "#4991dd",
                                          }}
                                        />
                                      ) : (
                                        <ShareLocationRoundedIcon
                                          sx={{
                                            color: formik.values.district
                                              ? "#414141"
                                              : "#bdbdbd",
                                          }}
                                        />
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />

                          
                          {formik.touched.pincode && formik.errors.pincode ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.pincode}
                            </div>
                          ) : null}
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          {t("company_total_employees")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="company_total_employees"
                          value={formik.values.company_total_employees}
                          label={t("company_total_employees")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={"1 to 50"}>1 to 50</MenuItem>
                          <MenuItem value={"51 to 150"}>51 to 150</MenuItem>
                          <MenuItem value={"151 to 250"}>151 to 250</MenuItem>
                          <MenuItem value={"251 to 500"}>251 to 500</MenuItem>
                          <MenuItem value={"501 to 750"}>501 to 750</MenuItem>
                          <MenuItem value={"751 to 900"}>751 to 900</MenuItem>
                          <MenuItem value={"901 to 1000"}>901 to 1000</MenuItem>
                          <MenuItem value={"1001+"}>1001+</MenuItem>
                        </Select>
                      </FormControl>
                      {formik.touched.company_total_employees &&
                      formik.errors.company_total_employees ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.company_total_employees}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        placeholder={t("Enter_Your_Contact_Person_Name")}
                        id="contact_person"
                        label={t("Contact_Person_Name")}
                        name="contact_person"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z'. ]/gi,
                              ""
                            );
                            formik.setFieldValue("contact_person", value);
                          }
                        }}
                        value={formik.values.contact_person}
                      />
                      {formik.touched.contact_person &&
                      formik.errors.contact_person ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.contact_person}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        placeholder={t("Enter_Your_Designation")}
                        id="designation"
                        label={t("Designation")}
                        name="designation"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z'. ]/gi,
                              ""
                            );
                            formik.setFieldValue("designation", value);
                          }
                        }}
                        value={formik.values.designation}
                      />
                      {formik.touched.designation &&
                      formik.errors.designation ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.designation}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        ref={inputRef}
                        placeholder={t("Enter_Your_Mobile_Number2")}
                        name="mobile_number"
                        label={t("Mobile_Number2")}
                        type="Number"
                        id="mobile_number"
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        onBlurCapture={() => {
                          setFocusNo(true);
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        value={formik.values.mobile_number}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <b>+91</b>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.mobile_number}
                        </div>
                      ) : null}
                    </Grid>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={
                        !formEmailError &&
                        formik.isValid &&
                        formik.dirty &&
                        isSubmitting === false
                          ? false
                          : true
                      }
                    >
                      {t("Submit2")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid> */}
        {/* <Outlet /> */}
        <div className="overall-jobseeker-form">
        <div className="left-jobseekers-img">
        <div style={{ visibility: "hidden" }}>fsdfsd</div>
        <div className="leftimg-box">
            <img src={lang === "tamil" ? lefttamilimg : leftimg} alt="img" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="jobseeker-form-box">
              <div className="jobseeker-form-header">
                <h2>
                  <span>{t("Employer")} </span>
                  {t("Registration")}
                </h2>
              </div>
              <div className="jobseeker-box-reg-form">
                <div className="jobseeker-box-inputs">
                  <div className="jobseeker-textfield">
                    <label>{t("Company_Name")}<text>*</text></label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="company_name"
                      required
                      variant="outlined"
                      size="small"
                      name="company_name"
                      placeholder={t("Enter_Your_Company_Name")}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z0-9()&@ .$#_-]/gi,
                            ""
                          );
                          formik.setFieldValue("company_name", value);
                        }
                      }}
                      value={formik.values.company_name}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.company_name &&
                      formik.errors.company_name ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.company_name}
                        </Typography>
                      ) : (
                        <Typography sx={{ visibility: "hidden", fontSize: "13px"  }}>
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  {industryArray && (
                    <div className="jobseeker-textfield">
                      <label>{t("Select_Industry")}<text>*</text></label>
                      {industryArray && (
                        <>
                          <Autocomplete
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            disableClearable={
                              formik.values.industry ? false : true
                            }
                            filterOptions={filterOptions}
                            size="small"
                            id="industry"
                            name="industry"
                            options={industryArray}
                            onBlur={formik.handleBlur}
                            onChange={(event, value) => {
                              setSelectedIndustry(value);
                              formik.setFieldValue("industry", value);
                            }}
                            value={formik.values.industry}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                placeholder={t("Preffered_Industry")}
                                id="industry"
                                name="industry"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        className="Industryimg"
                                        src={Industry}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                // onClick={() => params.inputProps.onInputClick()}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: { maxHeight: "180px", overflowY: "auto" },
                            }}
                          />
                          <FormHelperText>
                            {formik.touched.industry &&
                            formik.errors.industry ? (
                              <Typography
                                sx={{ color: "red", fontSize: "13px" }}
                              >
                                {formik.errors.industry}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  color: "red",
                                  fontSize: "13px",
                                  visibility: "hidden",
                                }}
                              >
                                none
                              </Typography>
                            )}
                          </FormHelperText>
                        </>
                      )}
                    </div>
                  )}
                  <div className="jobseeker-textfield">
                    <label> {t("company_total_employees")}<text>*</text></label>
                    {/* <Tooltip title="Select Your Pin Code" arrow> */}
                    <Autocomplete
                      disableClearable={
                        formik.values.company_total_employees ? false : true
                      }
                      size="small"
                      required
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      name="company_total_employees"
                      id="company-total-employees"
                      options={employeroptions}
                      value={formik.values.company_total_employees}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "company_total_employees",
                          newValue
                        );
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={t("Select_company_total_employees")}
                          name="company_total_employees"
                        />
                      )}
                    />
                    <FormHelperText>
                      {formik.touched.company_total_employees &&
                      formik.errors.company_total_employees ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.company_total_employees}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Company_Location")}<text>*</text></label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="currentLocation"
                      variant="outlined"
                      size="small"
                      name="currentLocation"
                      placeholder={t("Locate_Me")}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value;
                          formik.setFieldValue("currentLocation", value);
                        }
                      }}
                      value={formik.values.currentLocation}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <MyLocationIcon sx={{ color: "#5C6BF4" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.currentLocation &&
                      formik.errors.currentLocation ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.currentLocation}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  {/* <div className="jobseeker-textfield">
                    <label>{t("select_state")}<text>*</text></label>
                    <div className="jobseeker-btn-img">
                      <Stack direction="row" className="mobile-for-Work-Status">
                        <Button
                          sx={{ marginLeft: "0px" }}
                          className={
                            formik.values.state === "Tamil Nadu"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(event) => {
                            const value = "Tamil Nadu";
                            formik.setFieldValue("state", value);
                            formik.setFieldValue("district", "");
                            formik.setFieldValue("pincode", "");
                          }}
                        >
                          {formik.values.state === "Tamil Nadu" ? (
                            <img src={districtActive} />
                          ) : (
                            <img src={Location} />
                          )}{" "}
                          <span
                            className={
                              formik.values.state === "Tamil Nadu"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Tamil_Nadu")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.state === "Puducherry"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(event) => {
                            const value = "Puducherry";
                            formik.setFieldValue("state", value);
                            formik.setFieldValue("district", "");
                            formik.setFieldValue("pincode", "");
                          }}
                        >
                          {formik.values.state === "Puducherry" ? (
                            <img src={districtActive} />
                          ) : (
                            <img src={Location} />
                          )}{" "}
                          <span
                            className={
                              formik.values.state === "Puducherry"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Puducherry")}{" "}
                          </span>
                        </Button>
                      </Stack>
                      <FormHelperText>
                        {formik.touched.state && formik.errors.state ? (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors.state}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "13px",
                              visibility: "hidden",
                            }}
                          >
                            none
                          </Typography>
                        )}
                      </FormHelperText>
                    </div>
                  </div> */}
                  {districtData && (
                  <div className="jobseeker-textfield">
                    <label>{t("District")}<text>*</text></label>
                    {/* <Tooltip title="Select Your District" arrow> */}
                    <Autocomplete
                      size="small"
                      required
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      disableClearable={formik.values.district ? false : true}
                      filterOptions={filterOptions}
                      name="district"
                      id="district"
                      value={formik.values.district}
                      onChange={(event, value) => {
                        formik.setFieldValue("district", value);
                        //searchobj(value);
                        formik.setFieldValue("pincode", "");
                      }}
                      onBlur={formik.handleBlur}
                      options={districtData}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          placeholder={t("Select_District")}
                          name="district"
                          // onChange={(e) =>{
                          //   if(e.target.value.length >=3){
                          //     votingDistrict(e.target.value);
                          //   }
                          //   }}
                        />
                      )}
                      onOpen={(event) => event.preventDefault()}
                      PopperComponent={({ children, ...popperProps }) => (
                        <Popper
                          {...popperProps}
                          placement="bottom-start"
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                          }}
                          PopperProps={{
                            placement: "bottom-start",
                          }}
                        >
                          <Paper>{children}</Paper>
                        </Popper>
                      )}
                      ListboxProps={{
                        style: { maxHeight: "180px", overflowY: "auto" },
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.district && formik.errors.district && !formik.values.district? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.district}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                    {/* </Tooltip> */}
                  </div>)}
                  <div className="jobseeker-textfield">
                    <label>{t("Pincode")}<text>*</text></label>
                    {/* <Tooltip title="Select Your Pin Code" arrow> */}
                    <Autocomplete
                      size="small"
                      disableClearable={formik.values.pincode ? false : true}
                      disablePortal
                      freeSolo
                      disabled={
                        formik.values.district && !pinLoad ? false : true
                      }
                      required
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="pincode"
                      name="pincode"
                      options={comdata}
                      value={formik.values.pincode}
                      onChange={(event, value) => {
                        formik.setFieldValue("pincode", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          name="pincode"
                          onChange={(e) => {
                            if (e.target.value.length >= 5) {
                              fetchCountryData(e.target.value);
                            }
                          }
                        }
                          placeholder={t("Enter_Pincode(Optional)")}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {pinLoad ? (
                                  <CircularProgress
                                    // size={24}
                                    style={{
                                      width: "1.4rem",
                                      height: "1.4rem",
                                      color: "#4991dd",
                                    }}
                                  />
                                ) : (
                                  <ShareLocationRoundedIcon
                                    sx={{
                                      color: formik.values.district
                                        ? "#414141"
                                        : "#bdbdbd",
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <FormHelperText>
                      {formik.touched.pincode && formik.errors.pincode ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.pincode}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>

                  <Divider>{t("Contact-Details")}</Divider>

                  <div className="jobseeker-textfield">
                    <label>{t("Full_Name")}<text>*</text></label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="contact_person"
                      required
                      variant="outlined"
                      size="small"
                      name="contact_person"
                      placeholder={t("Enter_Your_Full_Name")}
                      onBlur={formik.handleBlur}
                      fullWidth
                      onChange={(e) => {
                        if (e) {
                          const fullname = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("contact_person", fullname);
                        }
                      }}
                      value={formik.values.contact_person}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.contact_person &&
                      formik.errors.contact_person ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.contact_person}
                        </Typography>
                      ) : (
                        <Typography sx={{ visibility: "hidden" }}>
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Designation")}<text>*</text></label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="designation"
                      required
                      variant="outlined"
                      size="small"
                      name="designation"
                      placeholder={t("Enter_Your_Designation")}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("designation", value);
                        }
                      }}
                      value={formik.values.designation}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className="Industryimg"
                              style={{ marginLeft: "0px" }}
                              src={Designation}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.designation &&
                      formik.errors.designation ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.designation}
                        </Typography>
                      ) : (
                        <Typography sx={{ visibility: "hidden", fontSize: "13px" }}>
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Email_Id")}<text>*</text></label>
                    {/* <Tooltip title="Enter your Email" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="email_id"
                      variant="outlined"
                      size="small"
                      name="formEmail"
                      required
                      ref={ref}
                      onBlurCapture={() => {
                        setfocus(true);
                      }}
                      placeholder={t("Enter_Your_Email_ID")}
                      onBlur={handleEmailBlur}
                      onChange={handleEmailChange}
                      value={formEmail}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formEmailError && focusout ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formEmailError}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Mobile_Number")}<text>*</text></label>
                    {/* <Tooltip title="Enter Your Mobile Number" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      required
                      id="mobile_number"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="mobile_number"
                      onBlur={formik.handleBlur}
                      onChange={handleChange}
                      onBlurCapture={() => {
                        setFocusNo(true);
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      value={formik.values.mobile_number}
                      placeholder={t("Enter_Your_Mobile_Number")}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "medium",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              +91
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo ? (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.mobile_number}
                        </div>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="uploade-note" style={{ marginTop: "4px" }}>
                    {t("You_accept_the")}{" "}
                    <text
                      className="terms-and-conditions"
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      {t("Terms_and_conditions")}{" "}
                    </text>{" "}
                    {t("and")}{" "}
                    <text
                      className="terms-and-conditions"
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      {t("Privacy_policy")}{" "}
                    </text>{" "}
                    {t("of")}{" "}
                    <text className="terms-and-conditions-1">
                      {" "}
                      {process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{" "}
                    </text>{" "}
                    {t("by_clicking_the")}
                  </div>
                  <div>
                    <Button
                      //type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={
                        !formEmailError &&
                        formik.isValid &&
                        formik.dirty &&
                        isSubmitting === false
                          ? false
                          : true
                      }
                      component="label"
                      onClick={() => {
                        submitForm(formik.values);
                      }}
                    >
                      {t("Submit")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",

                            marginLeft: 12,
                          }}
                        />
                      )}
                      {/* <VisuallyHiddenInput type="file" /> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={openok}
          aria-labelledby="responsive-dialog-title"
          maxWidth={600}
          className="dailog"
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            <p className="Successfully-text">{t("Thank_you_registration")}</p>
            <text className="Check-text">{t("Check_your_mail")}</text>
          </DialogTitle>
          <DialogContent className="ahjahg">
            <DialogContentText>
              <div className="set-widthfordailogbox">
                <div className="img-complete">
                  <img src={sussesfullimg} />
                </div>
                <div>
                  {/* <p className="apply-your-text">{t("Now_you_can_Hire")}</p> */}
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="btn-successfully" onClick={handleCloseed}>
                    {t("Home_Page")}
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default Employer;
