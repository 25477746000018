import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
  Typography,
  Paper,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Fab,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import "../PostJob/PostJob.css";
import useAxios from "../../../api/useAxios";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  ManageSearchRounded as ManageSearchRoundedIcon,
  Label,
} from "@mui/icons-material/";
import { saveAs } from "file-saver";
import defaultimg from "../../../img/mentorImage.png";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";
import WestIcon from "@mui/icons-material/West";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import "jspdf-autotable";
import SkeletonMlaCard from "../../admin-panel/admin-components/StatCards/SkeletonMlaCard";
import ExportCS from "../../admin-panel/admin-components/ExportComponents/ExportCS";
import ExportPDF from "../../admin-panel/admin-components/ExportComponents/ExportPDF";
import StatCardsDatabase from "./StatCardsDatabase";
import { useLocation } from "react-router-dom";
import DatabaseSeekerDialog from "./DatabaseSeekerDialog";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import StatCardsDatabaseIndustry from "./StatCardsDatabaseIndustry";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./DatabaseAccess.css";
function ViewDatabase() {
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const Location = useLocation();
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [accessCount, setAccessCount] = useState(0);
  const [viewSeekersAlertCount, setViewSeekersAlertCount] = useState(0);
  const [expiryAccessAlertDays, setExpiryAccessAlertDays] = useState(0);
  const [decide, setDecide] = useState(false);
  const [pinLoad, setpinLoad] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });
  const [viewAccessCount, setViewAccessCount] = useState(false);
  const [expiryAlert, setExpiryAlert] = useState(false);
  const [expiryAlertDays, setExpiryAlertDays] = useState(0);
  const [advSearch, setAdvSearch] = useState(false);
  const [loading, setLoading] = useState({
    isLoading: false,
    dialogLoad: false,
  });
  const [districtData, setDistrictData] = useState([]);
  const [district_name, setDistrict_name] = useState("");
  const [accessDatabase, setAccessDatabase] = useState("");
  const [pincodeByDistrict, setPincodeByDistrict] = useState([]);
  const [getPincode, setGetPincode] = useState("");
  const [industryArray, setIndustryArray] = useState();
  const [searchdisplay, setsearchdisplay] = useState(false);
  const [industryget, setindustryget] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectProfilePopup, setSelectProfilePopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [getOneID, setGetOneID] = useState("");
  const [searchdata, setsearchdata] = useState("");
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [dateenable, setdateenable] = useState(false);
  let [empData, setempData] = useState({});
  let [empDataAccess, setempDataAccess] = useState({});
  let [empDataAccessArray, setempDataAccessArray] = useState([]);
  let [industryAccessArray, setIndustryAccessArray] = useState([]);
  let [viewedSeekersArray, setViewedSeekersArray] = useState([]);
  let [resumeDownloadedSeekersArray, setResumeDownloadedSeekersArray] =
    useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchicon, setsearchicon] = useState(false);
  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [getShortlistSeeker, setGetShortlistSeeker] = useState({});
  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const adminSeekerSearch = sessionStorage.getItem("admin_seeker_search");
  let industrygetDB = [];
  let viewedSeekers = [];
  let downloadResumeSeekers = [];
  const selectedApiForExcel = useApi.map((i) => {
    return {
      "Full Name": i.full_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      "Current Location": i.currentLocation,
      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
      Experience: i.Experience,
      "Pin-code": i.pincode,
    };
  });
  const headers = [
    [
      "Full Name",
      "Email",
      "Mobile",
      "Industry",
      "Current Location",
      "Voting District",
      "MP Constituency",
      "MLA Constituency",
      "Created Date",
    ],
  ];
  const datas = useApi.map((user) => {
    return [
      user.full_name,
      user.email_id,
      user.mobile_number,

      user.industry,
      user.currentLocation,
      user.votingDistrict,
      user.mpConstituency,
      user.mlaConstituency,

      user.createdDate,
    ];
  });
  const handleChange = () => {
    setOpen(!open);
  };
  const handleChangeNotes = () => {
    setSelectProfilePopup(!selectProfilePopup);
  };
  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->
  const fetchApi = async () => {
    setLoading({ ...loading, isLoading: true });
    setGetPincode("");
    setDistrict_name("");
    setindustryget("");
    setAdvSearch(false);
    try {
      const getOneAxios2 = await axiosData.get(`/General/datadaseaccessamount`);
      const data2 = getOneAxios2.data.collections;
      setViewSeekersAlertCount(data2.viewseeker_count_alert);
      setExpiryAccessAlertDays(data2.expiry_alert_before_days);
      const getOneAxios = await axiosData.get(
        `databaseaccess/${userDetails.emailId}/user`
      );
      const dataEmployer22 = getOneAxios.data;
      const dataEmployer = dataEmployer22.reverse();
      const industryAccess = dataEmployer[0]?.data_accessType_by_industry;
      industrygetDB.push(...industryAccess.split(","));
      setIndustryAccessArray(industryAccess.split(","));
      axiosData
        .get(`employers/email/${userDetails.emailId}/users`)
        .then(async (data) => {
          empData = data.data;
          if (empData.email_id === userDetails.emailId) {
            setempData({ ...empData });
          }
        });
      setempDataAccessArray(dataEmployer);
      setempDataAccess({ ...dataEmployer[0] });
      filterseeker(dataEmployer[0]);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterseeker = async (dataEmployer) => {
    setLoading({ ...loading, isLoading: true });
    setGetPincode("");
    setDistrict_name("");
    setindustryget("");
    setAdvSearch(false);
    try {
      const response = await axiosData.get(
        `databaseaccess/${dataEmployer._id}`
      );

      if (response.status === 200) {
        const startDate = moment().format("DD-MM-YYYY");
        const expiryDate = response.data?.data_access_expiry_Date;
        const start_date = moment(startDate, "DD-MM-YYYY");
        const expiry_date = moment(expiryDate, "DD-MM-YYYY");
        const daysDifference = expiry_date.diff(start_date, "days");
        if (daysDifference <= expiryAccessAlertDays) {
          setExpiryAlertDays(daysDifference);
          setExpiryAlert(true);
        }
        setAccessCount(response.data.CV_access_limit);
    const viewSeekersGet = response.data.viewed_seekers;
    const downloadResumeSeekersGet = response.data.resume_downloaded_seekers;
    if (
      viewSeekersGet === null ||
      viewSeekersGet === undefined ||
      viewSeekersGet === "" ||
      viewSeekersGet?.length === 0
    ) {
      setResumeDownloadedSeekersArray([]);
      setViewedSeekersArray([]);
    } else {
      downloadResumeSeekers.push(downloadResumeSeekersGet?.split(","));
      setResumeDownloadedSeekersArray(downloadResumeSeekersGet?.split(","));
      viewedSeekers.push(...viewSeekersGet?.split(","));
      setViewedSeekersArray(viewSeekersGet?.split(","));
    }
        if (response.data.CV_access_limit === 0) {
          setViewAccessCount(true);
        }
      } else {
      }
    } catch (err) {}
    setEndDate(moment());
    setempDataAccess({ ...dataEmployer });
    if (dataEmployer) {
      setAccessDatabase(
        `${dataEmployer.data_accessType}/${dataEmployer.data_accessType_by_industry}`
      );
      IndustryData(dataEmployer);
      try {
        const useAxiosData2 = await axiosData.post(`seekers/databaseaccess`, {
          data_accessType: dataEmployer.data_accessType,
          data_accessType_by_industry: dataEmployer.data_accessType_by_industry,
          data_accessType_by_work_status:
            dataEmployer.data_accessType_by_work_status,
        });
        if (useAxiosData2.status === 204) {
          setGetApi([]);
        } else {
          const datefromseeker = useAxiosData2.data[0].createdDate;
          const date = moment(datefromseeker, "DD-MM-YYYY");
          setStartDate(date);
          useAxiosData2.data.reverse();
          let bool = false;
          if (adminSeekerSearch !== null) {
            bool = adminSeekerSearch.length > 0;
            setLoading({ ...loading, isLoading: false });
          }
          if (true === bool) {
            searchdatas(adminSeekerSearch, useAxiosData2.data);
            setGetApi(useAxiosData2.data);
            setsearchicon(true);
            setLoading({ ...loading, isLoading: false });
          } else if (advSearch === true) {
            advanceSearch(useAxiosData2.data);
            setLoading({ ...loading, isLoading: false });
          } else {
            setGetApi(useAxiosData2.data);
            getFilterValue(filterName.status, "Total", useAxiosData2.data);
          }
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  };
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/${id}`);
      const { data } = getOneAxios;
      setGetOne(data);
      try {
        const getOneAxios = await axiosData.get(`jobapply/getbyseekerid/${id}`);
        const datas = getOneAxios.data;

        if (getOneAxios.status === 204) {
          setGetShortlistSeeker("");
        } else {
          const databaseSeeker = datas.filter((seekers) =>
            seekers.source_from.includes(`Database-${empDataAccess._id}`)
          );
          setLoading({ ...loading, dialogLoad: false });
          if (databaseSeeker.length === 0) {
            setGetShortlistSeeker("");
          } else {
            setGetShortlistSeeker(databaseSeeker);
          }
        }
      } catch (err) {
        console.log("Error", err);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  const downloadResume = (id) => {
    saveAs(`${baseUrl}seekers/resume/${id}`, id + "Resume");
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA === valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (statusvalue, value, alldatas) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      statusForAll(statusvalue, yes);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.createdDate, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas);
    }
  };
  const statusForAll = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
      setLoading({ ...loading, isLoading: false });
    } else if (
      statusvalue === "A" ||
      statusvalue === "P" ||
      statusvalue === "D"
    ) {
      const today = get.filter((i) => i.status === statusvalue);
      const data = today;
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
      setLoading({ ...loading, isLoading: false });
    }
  };

  const handleStartDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
    }
    if (startDate === null) {
      setstartdisabled(false);
    }
    setStartDate(date);
    setstartError(null);
  };
  const handleEndDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
    }
    if (endDate === null) {
      setenddisabled(false);
    }
    setEndDate(date);
    setendError(null);
  };
  const handleClickFilter = (statusvalue) => {
    const filteredData = getApi.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate);
      return date.isBetween(start, endDate, null, "[]");
    });
    statusForAll(statusvalue, filteredData);
  };

  const handleClickRefresh = () => {
    fetchApi();
    setsearchdata("");
    setstartdisabled(false);
    setenddisabled(false);
    setstartError(null);
    setendError(null);
  };

  const closeFunctionCall = () => {
    getFilterValue("all", "Total", getApi);
  };

  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      setGetShortlistSeeker({});
      fetchApi();
      setsearchdata("");
      vodingDistrict();
      handleSort(orderBy);
    }
  }, []);
  const searchdatas = (searchdata, getApi) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = getApi.filter((item) => {
      var valuess =
        item.full_name.match(searchtext) ||
        item.email_id.match(searchtext2) ||
        item.votingDistrict.match(searchtext2) ||
        item.mlaConstituency.match(searchtext2) ||
        item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const IndustryData = async (empAccessType) => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        if (empAccessType.data_accessType === "IT") {
          const valuesdataF = data.filter((s) => s.includes("IT/Computers"));
          const valuesdataF2 = valuesdataF.filter((s) =>
            empAccessType.data_accessType_by_industry.includes(s)
          );
          setIndustryArray(valuesdataF2);
        } else if (empAccessType.data_accessType === "Non-IT") {
          const valuesdataNonIT = data.filter(
            (n) => !n.includes("IT/Computers")
          );
          const valuesdataNonIT2 = valuesdataNonIT.filter((s) =>
            empAccessType.data_accessType_by_industry.includes(s)
          );
          setIndustryArray(valuesdataNonIT2);
        } else {
          const valuesdataNonAll = data.filter((s) =>
            empAccessType.data_accessType_by_industry.includes(s)
          );
          setIndustryArray(valuesdataNonAll);
        }
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const updateViewedSeeker = async (id) => {
    try {
      const response = await axiosData.put(
        `seekers/${id}`,
        { Database_view: "Yes" },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
      } else if (response.status === 403) {
      }
    } catch (err) {}
  };

  const updateAccessCount = async (id) => {
    //setLoading({ ...loading, isLoading: true });
    const viewedData = id.join(",");
    try {
      const response = await axiosData.put(
        `databaseaccess/${empDataAccess._id}`,
        { CV_access_limit: accessCount - 1, viewed_seekers: viewedData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        try {
          const response = await axiosData.get(
            `databaseaccess/${empDataAccess._id}`
          );
          if (response.status === 200) {
            setAccessCount(response.data.CV_access_limit);
            const viewSeekersGet2 = response.data.viewed_seekers;
            const downloadResumeSeekersGet2 =
              response.data.resume_downloaded_seekers;
            viewedSeekers.push(...viewSeekersGet2?.split(","));
            setViewedSeekersArray(viewSeekersGet2?.split(","));
            downloadResumeSeekers.push(
              ...downloadResumeSeekersGet2?.split(",")
            );
            setResumeDownloadedSeekersArray(
              downloadResumeSeekersGet2?.split(",")
            );
            if (response.data.CV_access_limit === 0) {
              setViewAccessCount(true);
            }
           setLoading({ ...loading, isLoading: false });
          } else {
            //setLoading({ ...loading, isLoading: false });
          }
        } catch (err) {}
      } else if (response.status === 403) {
      }
    } catch (err) {}
  };
  const getPincodeByDistrict = async (value) => {
    setpinLoad(true);
    try {
      const pin = await axiosData.get(`distritarea/getArea/${value}`);
      const { data } = pin;
      if (data) {
        const datapin = data;
        if (datapin) {
          const getpin = datapin.map((i) => {
            return i.Pincode;
          });
          const getpost = datapin.map((i) => {
            return i.Post_Office;
          });
          const getpost1 = getpost[0];
          const result = getpin[0].reduce(
            (accumulator, currentPincode, index) => {
              return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
            },
            []
          );
          const getPostFinal = result.map((i) => {
            return i;
          });
          setpinLoad(false);
          setPincodeByDistrict([...getPostFinal]);
        }
      }
    } catch (err) {}
  };

  const advanceSearch = (datavalue) => {
    const searchtindustry = new RegExp(industryget, "i");
    const searchdistrict = new RegExp(district_name, "i");
    let data = [];

    if (industryget === "" || industryget === null) {
      data = datavalue;
    } else {
      data = datavalue.filter((item) => {
        return item.industry.match(searchtindustry);
      });
    }
    if (district_name !== "" || district_name !== null) {
      data = data.filter((item) => {
        return item.votingDistrict.match(searchdistrict);
      });
    }
    if (getPincode) {
      data = data.filter((item) => {
        return item.pincode == getPincode;
      });
    }
    setUseApi(data);
    setTotalRows(data.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = () => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : !dateRange.openStartTool
          ? handleToolClose()
          : handleToolClose();
      };
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);
  if (loading.isLoading === false) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            <Tooltip
              arrow
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.EMPLOYERUPDATE,
                  {
                    state: userDetails.emailId,
                  }
                );
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                id="backIcon"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <div style={{ flexGrow: 1, textAlign: "center" }}>
              <Typography id="headingSeekers">
                Job Seekers Data
                {/* {empDataAccess.data_accessType === "Non-IT"
                  ? "Non-IT Job Seekers Data"
                  : empDataAccess.data_accessType === "IT"
                  ? "IT Job Seekers Data"
                  : empDataAccess.data_accessType === "Both"
                  ? "IT/Non-IT Job Seekers Data"
                  : "Job Seekers Data"} */}
              </Typography>
            </div>
          </div>
          <div>
            <Grid item xs={12} sm={6} md={1.7}>
              <FormControl sx={{ width: "100%", marginRight: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    style={{ fontSize: "16px", fontFamily: "Poppins-Medium" }}
                  >
                    {" "}
                    Select Your Resume Access
                  </label>
                  <Typography style={{ color: "#1976d2" }}>
                    Note:Your selected resume access ends on{" "}
                    <span style={{ color: "red" }}>
                      {empDataAccess.data_access_expiry_Date}
                    </span>
                  </Typography>
                </div>
                <Select
                  sx={{ marginTop: "20px" }}
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={accessDatabase}
                  onChange={(e) => {
                    setAccessDatabase(e.target.value);
                    industrygetDB.push();
                    setIndustryAccessArray([]);
                    if (e.target.value) {
                      const datas = empDataAccessArray.filter(
                        (valueD) =>
                          `${valueD.data_accessType}/${valueD.data_accessType_by_industry}` ===
                          e.target.value
                      );
                      if (datas.length > 1) {
                        filterseeker(datas[0]);
                        const industryAccess =
                          datas[0].data_accessType_by_industry;
                        setIndustryAccessArray(industryAccess.split(","));
                        industrygetDB.push(...industryAccess.split(","));
                      } else {
                        filterseeker(datas[0]);
                        const industryAccess =
                          datas[0].data_accessType_by_industry;
                        setIndustryAccessArray(industryAccess.split(","));
                        industrygetDB.push(...industryAccess.split(","));
                      }
                    }
                  }}
                >
                  {empDataAccessArray.map((d) => (
                    <MenuItem
                      key={d._id}
                      gutterBottom
                      sx={{
                        width: "100%",
                        wordBreak: "break-all",
                        wordWrap: "break-word",
                        lineHeight: "2em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        transition: "0.5s",
                        padding: "0px 5px",
                        borderRadius: "5px",
                      }}
                      className="nameTitleviewdmenuitem"
                      value={`${d.data_accessType}/${d.data_accessType_by_industry}`}
                    >{`${
                      d.data_accessType === "IT"
                        ? `${d.data_accessType_by_industry} Seekers(Access Taken Date :${d.data_access_taken_Date})`
                        : d.data_accessType === "Non-IT"
                        ? `${d.data_accessType_by_industry} Seekers(Access Taken Date :${d.data_access_taken_Date})`
                        : d.data_accessType === "Both"
                        ? `${d.data_accessType_by_industry} Seekers(Access Taken Date :${d.data_access_taken_Date})`
                        : "All Seekers Resume Access"
                    } `}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </div>
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <StatCardsDatabase
                getApi={getApi}
                empDataAccess={empDataAccess}
                empData={empData}
              />
            </Box>
          </Card>
          {industryAccessArray?.length > 1 ? (
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box>
                  <Divider textAlign="left" sx={{ width: "100%" }}>
                    <span className="seeker-text">
                      Industry Based Total Seekers
                    </span>
                  </Divider>
                </Box>
                <Grid
                  container
                  direction="row"
                  spacing={5}
                  md={12}
                  sx={{ mb: "24px", width: "100%" }}
                >
                  {industryAccessArray?.map((i, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <StatCardsDatabaseIndustry getApi={getApi} industry={i} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
          ) : null}

          <Card sx={{ padding: "20px", mb: 8 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {advSearch ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={3} sm={12} xs={12}>
                        {industryArray && (
                          <FormControl sx={{ mb: 1 }} fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              Industry
                            </FormLabel>
                            <Autocomplete
                              disableClearable={industryget ? false : true}
                              filterOptions={filterOptions}
                              id="industry"
                              name="industry"
                              options={industryArray}
                              onChange={(event, value) => {
                                setindustryget(value);
                              }}
                              value={industryget}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="industry"
                                  label={"Select Industry"}
                                  name="industry"
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start",
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <FormControl fullWidth>
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            District Name
                          </FormLabel>
                          <Autocomplete
                            fullWidth
                            disableClearable={district_name ? false : true}
                            filterOptions={filterOptions}
                            name="district_name"
                            id="votingDistrict"
                            value={district_name}
                            onChange={(event, value, reason) => {
                              setDistrict_name(value);
                              if (reason === "selectOption") {
                                getPincodeByDistrict(value);
                                setGetPincode("");
                              }
                              if (reason === "clear") {
                                setPincodeByDistrict([]);
                                setGetPincode("");
                              }
                              if (value === "") {
                                setGetPincode("");
                              }
                            }}
                            options={districtData}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Select District"}
                                InputLabelProps={{
                                  style: { marginLeft: "7px" },
                                }}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start",
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <FormControl
                          fullWidth
                          disabled={district_name ? false : true}
                        >
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            Pincode
                          </FormLabel>
                          <Autocomplete
                            disabled={district_name ? false : true}
                            disableClearable={getPincode ? false : true}
                            disablePortal
                            id="pincode"
                            name="pincode"
                            options={pincodeByDistrict}
                            onChange={(event, value, reason) => {
                              setGetPincode(value);
                            }}
                            value={getPincode}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                labelId="demo-simple-select-label"
                                name="pincode"
                                id="pincode"
                                value={getPincode}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {pinLoad ? (
                                        <CircularProgress
                                          style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            color: "#4991dd",
                                          }}
                                        />
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                        sx={{ display: "grid", placeItems: "center" }}
                      >
                        <Box
                          width={"100%"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Button
                            variant="contained"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                              setGetPincode("");
                              setDistrict_name("");
                              setindustryget("");
                              setsearchdisplay(false);
                              closeFunctionCall();
                              setAdvSearch(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                              advanceSearch(getApi);
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                      {/* <Grid item md={4} sm={12} xs={12}>
                        <Tooltip title="Export As Excel" placement="bottom">
                          <ExportCS
                            selectedApiForExcel={selectedApiForExcel}
                            fileName={"Seeker-Data"}
                          />
                        </Tooltip>
                        <Tooltip title="Export As PDF" placement="bottom">
                          <ExportPDF
                            headers={headers}
                            datas={datas}
                            fileName={"Job-Seeker"}
                          />
                        </Tooltip>
                      </Grid> */}
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%", sm: "100%" },
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={2} sm={4} alignItems="center">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(filterName.status);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                        </>

                        <Tooltip title="Refresh" placement="bottom">
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={handleClickRefresh}
                          >
                            <RefreshSharpIcon />
                          </Fab>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} sm={4}>
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : adminSeekerSearch}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setsearchdata(e.currentTarget.value);

                          if (e.currentTarget.value.length === 0) {
                            setUseApi(getApi);
                            setTotalRows(getApi.length);
                            setPage(0);
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(e.currentTarget.value, getApi);
                          }
                        }}
                      />

                      {/* <Box display="flex">
                            <Tooltip title="Export As Excel" placement="bottom">
                              <ExportCS
                                selectedApiForExcel={selectedApiForExcel}
                                fileName={"Seeker-Data"}
                              />
                            </Tooltip>
                            <Tooltip title="Export As PDF" placement="bottom">
                              <ExportPDF
                                headers={headers}
                                datas={datas}
                                fileName={"Job-Seeker"}
                              />
                            </Tooltip>
                          </Box> */}
                    </Grid>
                    {/* <Grid item xs={4} md={1.5} sm={2}>
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        {searchicon ? (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#FF0000",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#cc0000" },
                            }}
                            onClick={() => {
                              closeFunctionCall();
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem("admin_seeker_search", "");
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                        ) : (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={() => setsearchicon(true)}
                          >
                            <SearchRoundedIcon />
                          </Fab>
                        )}
                      </Box>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={4}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      <div style={{ display: "grid", placeItems: "center" }}>
                        <Box>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              borderRadius: "16px",
                            }}
                            startIcon={<ManageSearchRoundedIcon />}
                            onClick={() => {
                              setAdvSearch(true);
                            }}
                          >
                            Advanced Search
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
            <Typography style={{ color: "#1976d2", margin: "10px" }}>
              Note:Your remaining Job seeker view count is{" "}
              <span style={{ color: "red" }}>{accessCount}</span>
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell> Profile</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Created Date</TableCell>
                    <TableCell> Mobile</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      >
                        Experience
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      No.of.count Shortlisted by You
                    </TableCell>
                    <TableCell align="center"> Viewed Seeker</TableCell>
                    <TableCell align="center"> Resume Downloaded</TableCell>
                    <TableCell align="center"> District</TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell>
                              {" "}
                              {
                                <img
                                  src={
                                    item?.profileUpload
                                      ? `${baseUrl}seekers/profile/${item.mobile_number}`
                                      : ""
                                  }
                                  onError={(e) => {
                                    e.target.src = defaultimg;
                                  }}
                                  alt="img"
                                  height={"50"}
                                  width="50"
                                  style={{ clipPath: "circle(50%)" }}
                                />
                              }{" "}
                            </TableCell>
                            <TableCell>{item.full_name}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {item.email_id}{" "}
                            </TableCell>
                            <TableCell>{item.createdDate}</TableCell>
                            <TableCell>{item.mobile_number}</TableCell>
                            <TableCell>{item.work_status}</TableCell>
                            <TableCell align="center">
                              {item.Database_shortlisted_count}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {viewedSeekersArray?.filter((d) => d == item._id)
                                ?.length > 0 ? (
                                <CheckCircleIcon
                                  sx={{
                                    padding: "0px",
                                    marginRight: "10px",
                                    color: "green",
                                  }}
                                  fontSize="small"
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {resumeDownloadedSeekersArray?.filter(
                                (d) => d == item._id
                              )?.length > 0 ? (
                                <CheckCircleIcon
                                  sx={{
                                    padding: "0px",
                                    marginRight: "10px",
                                    color: "green",
                                  }}
                                  fontSize="small"
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="center">{item.votingDistrict}</TableCell>
                            <TableCell>
                              <Grid container>
                                <Grid item md={8} sm={12} xs={12}>
                                  <Tooltip title="Full Details" placement="top">
                                    <IconButton
                                      className="icon-btn"
                                      size="Medium"
                                      onClick={() => {
                                        setGetShortlistSeeker("");
                                        setGetOneID(item._id);
                                        if (
                                          viewedSeekersArray?.filter(
                                            (d) => d == item._id
                                          )?.length > 0
                                        ) {
                                          getOneData(item._id);
                                          handleChange();
                                        } else {
                                          if (
                                            accessCount <=
                                            parseInt(viewSeekersAlertCount)
                                          ) {
                                            setViewAccessCount(true);
                                          } else {
                                            const stringRepresentation = String(item._id);
                                            viewedSeekers.push(stringRepresentation);
                                            const datasView = [];
                                            datasView.push(...viewedSeekersArray);
                                            datasView.push(stringRepresentation);
                                            setViewedSeekersArray(datasView);
                                            getOneData(item._id);
                                            updateAccessCount(datasView);
                                            handleChange();
                                          }
                                        }
                                      }}
                                    >
                                      <OpenInNewRoundedIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Profile Video"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="icon-btn"
                                      size="Medium"
                                      onClick={() => {
                                        getOneData(item._id);
                                        setDecide(true);
                                      }}
                                      disabled={!item.videoUpload}
                                    >
                                      <VideocamRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                  {/* <Tooltip
                                    title="Download Resume"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="icon-btn"
                                      onClick={() =>
                                        downloadResume(item.mobile_number)
                                      }
                                      size="medium"
                                      disabled={!item.fileUpload}
                                    >
                                      <FileDownloadIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                  {!item.linkedIn ? null : (
                                    <Tooltip
                                      title="LinkedIn Profile"
                                      placement="top"
                                    >
                                      <a
                                        href={item.linkedIn}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <IconButton
                                          className="icon-btn-link"
                                          sx={{
                                            transition: "all 0.3s",
                                          }}
                                          size="medium"
                                        >
                                          <LinkedInIcon />
                                        </IconButton>
                                      </a>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <DatabaseSeekerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
            filterName={filterName}
            setFilterName={setFilterName}
            fetchApi={fetchApi}
            filterseeker={filterseeker}
            dialogLoad={loading.dialogLoad}
            setGetOne={setGetOne}
            empDataAccess={empDataAccess}
            empData={empData}
            downloadResumeSeekers={downloadResumeSeekers}
            resumeDownloadedSeekersArray={resumeDownloadedSeekersArray}
            setResumeDownloadedSeekersArray={setResumeDownloadedSeekersArray}
            getShortlistSeeker={getShortlistSeeker}
            selectProfilePopup={selectProfilePopup}
            handleChangeNotes={handleChangeNotes}
          />
          <Dialog
            open={decide}
            onClose={() => setDecide(false)}
            fullWidth
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#339fcd",
                borderRadius: "20px",
                height: "50%",
              },
            }}
          >
            <DialogTitle>
              <Box textAlign="right">
                <IconButton onClick={() => setDecide(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <video
                className="VideoInput_video"
                width="100%"
                height="100%"
                controls
                src={
                  getOne?.videoUpload
                    ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                    : ""
                }
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={viewAccessCount}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiPaper-root": { width: "500px", borderRadius: "20px" },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontFamily: "Poppins-Medium",
                  color: "#444",
                  mt: 3,
                  textAlign: "center",
                }}
              >
                {accessCount === 0
                  ? "Your reached the limit of view access"
                  : `Your Remaining Seeker View Access Count is `}
                {accessCount === 0 ? null : (
                  <span style={{ color: "black" }}>{accessCount}</span>
                )}
                {accessCount === 0 ? null : (
                  <p>Are you sure to view this job seeker?</p>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mr: 2, mb: 1, mt: -2 }}>
              {accessCount === 0 ? null : (
                <Button
                  onClick={() => {
                    setViewAccessCount(false);
                  }}
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#ed1067",
                    borderColor: "#ed1067",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: "10px",
                    "&:hover": {
                      textTransform: "capitalize",
                      color: "#ed1067",
                      borderColor: "#ed1067",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={() => {
                  if (accessCount === 0) {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.EMPLOYERUPDATE,
                      {
                        state: userDetails.emailId,
                      }
                    );
                  } else {
                    setViewAccessCount(false);
                    //updateViewedSeeker(getOneID);
                    const stringRepresentation = String(getOneID);
                    viewedSeekers.push(stringRepresentation);
                    const datasView = [];
                    datasView.push(...viewedSeekersArray);
                    datasView.push(stringRepresentation);
                    setViewedSeekersArray(datasView);
                    getOneData(getOneID);
                    updateAccessCount(datasView);
                    handleChange();
                  }
                }}
                autoFocus
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: " white",
                  backgroundColor: "#339fcd",
                  borderColor: "#339fcd",
                  fontFamily: "Poppins-Medium",
                  transition: "all 0.3s",
                  borderRadius: "10px",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#339fcd",
                    borderColor: "#339fcd",
                    fontFamily: "Poppins-Medium",
                  },
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={expiryAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiPaper-root": { width: "500px", borderRadius: "20px" },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Important Notice"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontFamily: "Poppins-Medium",
                  color: "#444",
                  mt: 3,
                  textAlign: "center",
                }}
              >
                Your resume access will expire in
                {" "}<span style={{ color: "red" }}>{expiryAlertDays}</span>{" "}
                days. Please take action to renew your access or contact us
                for support.Thank you!.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mr: 2, mb: 1, mt: -2 }}>
              <Button
                onClick={() => {
                  setExpiryAlert(false);
                }}
                autoFocus
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: " white",
                  backgroundColor: "#339fcd",
                  borderColor: "#339fcd",
                  fontFamily: "Poppins-Medium",
                  transition: "all 0.3s",
                  borderRadius: "10px",
                  "&:hover": {
                    textTransform: "capitalize",
                    color: "#339fcd",
                    borderColor: "#339fcd",
                    fontFamily: "Poppins-Medium",
                  },
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default ViewDatabase;
