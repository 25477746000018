import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Volunteer.css";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";

import { useNavigate } from "react-router";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Popper from "@mui/material/Popper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";

import { useLocationFind } from "../LocationFind";

import PersonIcon from "@mui/icons-material/Person";

import EmailIcon from "@mui/icons-material/Email";
import Industry from "../../../img/economy 2.png";
import pincodeimg from "../../../img/navigation-2.png";
import leftimg from "../../../img/Left-voluter.png";
import leftTamil from "../../../img/Left-voluter-tamil.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sussesfullimg from "../../../img/Registered-img.png";
import { FormHelperText } from "@mui/material";
import Country from "../../../img/world-1.png";
import office from "../../../img/company-4-voluter.png";
import officeactive from "../../../img/company-4-voluter-active.png";
import Checkbox from "@mui/material/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createTheme();
const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function Volunteer() {
  const ref = React.createRef(null);
  const axiosData = useAxios();
  const [focusout, setfocus] = useState();
 
  const [buttonDisable, setbuttonDisable] = useState(true);
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n, ready } = useTranslation();
  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [emailErr, setEmailError] = useState(true);
  const [valid, setvalid] = useState(true);
  const [checkWhatsapp, setCheckWhatsapp] = useState("unchecked");
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [alertm, setalert] = useState(false);
  const [alerte, setalerte] = useState(false);
  const [focusNo, setFocusNo] = useState(false);
  const [industryArray, setIndustryArray] = useState();
  const [districtData, setDistrictData] = useState([]);
  const [countrycodeData, setCountryCodeData] = useState([]);
  const [mobileCode, setMobileCode] = useState();
  const [comdata, setComdata] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);
  const storedState = localStorage.getItem("currentLocationState");
  const storedCountry = localStorage.getItem("currentLocationCountry");
  const storedArea = localStorage.getItem("currentLocationArea");
  const storedPincode = localStorage.getItem("currentLocationPincode");
  const { locationData, locatioError } = useLocationFind();
  

  const [csrcheck, setcsrcheck] = useState("No");
  const [countrydata, setCountrydata] = useState([]);
  const themeds = useTheme();
  const [openok, setOpenok] = React.useState(false);
  const fullScreen = useMediaQuery(themeds.breakpoints.down("md"));

  const handleClickOpened = () => {
    setOpenok(true);
  };

  const handleCloseed = () => {
    setOpenok(false);
    navigate(ROUTINGDATA.LAND);
  };
  // useEffect(() => {
  //   if (
  //     (storedArea === "" || storedArea === null || storedArea === undefined) &&
  //     (storedPincode === "" ||
  //       storedPincode === null ||
  //       storedPincode === undefined) &&
  //     (storedCountry === "" ||
  //       storedCountry === null ||
  //       storedCountry === undefined) &&
  //     (storedState === "" ||
  //       storedPincode === null ||
  //       storedPincode === undefined)
  //   ) {
  //     formik.setFieldValue("currentLocation", "");
  //   } else {
  //     //formik.setFieldValue("currentLocation", `${storedArea},${storedPincode}`);
  //   }
  // }, [locationData]);
  useEffect(() => {
    if (alertm !== false) {
      setMobilePopUp(true);
      formik.setFieldValue("mobile_number", "");
    }
    setalert(false);
  }, [alertm]);
  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
      formik.setFieldValue("email_id", "");
    }
    setalerte(false);
  }, [alerte]);
  const errors = {};
  const validate = async (values) => {
    const errors = {};

    if (!values.mobile_number) {
      errors.mobile_number = t("Mobile_Number_must_be_Required");
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setvalid(true);
    }
    return errors;
  };

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const handleEmailChange = async (event) => {
    const email_id = event.target.value.replace(/[^a-z0-9@.]/gi, "");
    setFormEmail(email_id);
    if (!email_id) {
      setfocus(true);
      setFormEmailError(t("EmailId_must_be_Required3"));
      errors.email_id = t("EmailId_must_be_Required3");
      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setfocus(false);
      setFormEmailError(t("Invalid_email_address"));
      errors.email_id = t("Invalid_email_address");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setEmailError(true);
      setFormEmailError("");
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
            formik.setFieldValue("email_id", event.target.value);
          }
        } catch (error) {}
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      errors.email_id = "Invalid email address";
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      setEmailError(true);
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      email_id: "",
      country: "",
      source_type: "Individual",
      comments: "",
      csr_option: "",
      whatsapp_number: "",
    },
    validate,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, t("Must_be_3"))
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Name_must_be_Required"))
        .matches(/^[^'].*[^']$/, t("Invalid_format"))
        .matches(/^[a-zA-Z\s][a-zA-Z\s.]*$/, t("Invalid_format"))
        .matches(/^[a-zA-Z\s.]*$/, t("Invalid_format"))
        .test(t("no_repeated_single_quotes"), t("String_cannot"), (value) => {
          return !/^'{2,}(?:\s*'{1,}[^']*')*'$/.test(value);
        })
        .test(t("no_repeated_single_quotes"), t("Invalid_format"), (value) => {
          return !/\.\./.test(value);
        })
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' ' ' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "''";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== ".";
          }
        ),
      mobile_number: Yup.number()
      .min(100, t("Not_Valid_Mobile_Number!"))
      .required(
        t("Mobile_Number_must_be_Required3")
      ),
      whatsapp_number: Yup.number()
      .min(100, t("Invalid_format"))
      .required(
        t("Whatsapp_number_must_be_Required")
      ),
      country: Yup.string().required(t("Country_must_be_required")),
      comments: Yup.string()
        .min(3, t("Must_be_3"))
        .required(t("Comments_must_be_required")),
    }),

    onSubmit: (values) => {
      // if (storedCountry) {
      //   values.state = storedState;
      // }
      // if (storedCountry) {
      //   values.country = storedCountry;
      // }
      // const pincodeSplit = values.pincode.split("/");
      // const [a, b] = pincodeSplit;
      // values.pincode = a;
      // values.area = b;
      // values.email_id = formEmail;
      // Apifetch(values);
    },
  });
  const submitForm = (values) => {
    // if (storedCountry) {
    //   values.state = storedState;
    // }
    // if (storedCountry) {
    //   values.country = storedCountry;
    // }
    // const pincodeSplit = values.pincode.split("/");
    // const [a, b] = pincodeSplit;
    // values.pincode = a;
    // values.area = b;
    values.email_id = formEmail;
    values.csr_option = csrcheck;
    Apifetch(values);
  };
  const Apifetch = (data) => {
    data.mobile_number = `${mobileCode}${data.mobile_number}`
    data.whatsapp_number = `${mobileCode}${data.whatsapp_number}`
    axiosData
      .post("helpforjobseeker", data)
      .then((res) => {
        axiosData
          .post(
            "users",
            {
              usertype: "helpforjobseeker",
              status: "InActive",
              uniqueId: data.mobile_number,
              password: data.mobile_number,
              email_id: data.email_id,
              user_id: res.data.user_id,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {});
        setIsSubmitting(false);
        // navigate(
        //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
        // );
        handleClickOpened();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  // const Apifetch = (data) => {
  //   axiosData
  //     .post("volunteer", data)
  //     .then((res) => {
  //       axiosData
  //         .post(
  //           "users",
  //           {
  //             usertype: "volunteer",
  //             status: "InActive",
  //             uniqueId: data.mobile_number,
  //             password: data.mobile_number,
  //             email_id: data.email_id,
  //             user_id: res.data.user_id,
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         )
  //         .then((res) => {});
  //       setIsSubmitting(false);
  //       // navigate(
  //       //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
  //       // );
  //       handleClickOpened();
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  // };
  const handleEmailBlur = () => {
    if (!formEmail) {
      setFormEmailError(t("EmailId_must_be_Required3"));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formEmail)) {
      setFormEmailError(t("Invalid_email_format"));
    } else {
      setFormEmailError("");
    }
  };
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if(value.length <= 17){
      formik.handleChange(e);
    }
  };

  const searchobj = async (values) => {
    // setAdDistrict(values);

    try {
      setpinLoad(true);
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      setpinLoad(false);
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
      }

      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const lang = sessionStorage.getItem("lang");
  const CountryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/countries`);
      const data = getOneAxios.data;
      setCountryCodeData(data);
      setMobileCode(91);
      setSelectedIndustry("India");
      formik.setFieldValue("country", "India");
      const countries = data.map((i) => {
        return i.countries;
      });
      if (countries) {
        setCountrydata(countries);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    CountryData();
    vodingDistrict();
  }, []);

  const clear = () => {
    formik.setFieldValue("name", "");
    formik.setFieldValue("mobile_number", "");
    setFormEmail("");
    formik.setFieldValue("country", "");
    formik.setFieldValue("comments", "");
    formik.setFieldValue("csr_option", "");
    formik.setFieldValue("whatsapp_number", "");
  };

  if (ready) {
    return (
      // <ThemeProvider theme={theme}>
      //   <Dialog TransitionComponent={Transition} open={emailPopUp}>
      //     <DialogTitle
      //       bgcolor="rgb(16 143 178)"
      //       sx={{ color: "white", fontWeight: 600 }}
      //     >
      //       {t("Email_Exits")}
      //     </DialogTitle>
      //     <DialogContent sx={{ mt: 3 }}>
      //       <DialogContentText sx={{ fontWeight: 600 }}>
      //         {t("This_Email_Id_already_exist")}
      //       </DialogContentText>
      //     </DialogContent>
      //     <DialogActions>
      //       <Button
      //         color="primary"
      //         variant="contained"
      //         onClick={() => setEmailPopUp(false)}
      //       >
      //         {t("Ok")}
      //       </Button>
      //     </DialogActions>
      //   </Dialog>

      //   <Dialog TransitionComponent={Transition} open={mobilePopUp}>
      //     <DialogTitle
      //       bgcolor="rgb(16 143 178)"
      //       sx={{ color: "white", fontWeight: 600 }}
      //     >
      //       {t("Mobile_Number_Exits")}
      //     </DialogTitle>
      //     <DialogContent sx={{ mt: 3 }}>
      //       <DialogContentText sx={{ fontWeight: 600 }}>
      //         {t("This_Mobile_number_already_exist")}
      //       </DialogContentText>
      //     </DialogContent>
      //     <DialogActions>
      //       <Button
      //         color="primary"
      //         variant="contained"
      //         onClick={() => setMobilePopUp(false)}
      //       >
      //         {t("Ok")}
      //       </Button>
      //     </DialogActions>
      //   </Dialog>
      //   <Grid
      //     container
      //     component="main"
      //     sx={{ height: "100vh" }}
      //     className="main-grid"
      //   >
      //     <CssBaseline />
      //     <Grid className="img-grid" item xs={false} sm={false} md={4}>
      //       <Box className="sideimg">
      //         <div className="sideimglogo">
      //           <img src={logo} alt="logo" style={{ width: "75%" }} />
      //         </div>
      //         <div className="sidecontect">
      //           <Typography variant="h6" gutterBottom>
      //             {t("Side_Content3")}
      //           </Typography>
      //           <div className="listofsidecontect">
      //             <div>
      //               <CheckCircleIcon sx={{ color: "green" }} />
      //             </div>
      //             <div>{t("Help_others")}</div>
      //           </div>
      //           <div className="listofsidecontect">
      //             <div>
      //               <CheckCircleIcon sx={{ color: "green" }} />
      //             </div>

      //             <div>{t("Lift_others")}</div>
      //           </div>
      //           <div className="listofsidecontect">
      //             <div>
      //               <CheckCircleIcon sx={{ color: "green" }} />
      //             </div>
      //             <div>{t("Keep_Supporting")} </div>
      //           </div>
      //         </div>
      //       </Box>
      //     </Grid>
      //     <Grid
      //       className="second-grid"
      //       item
      //       xs={12}
      //       sm={12}
      //       md={8}
      //       component={Paper}
      //       elevation={4}
      //       square
      //     >
      //       <form onSubmit={formik.handleSubmit}>
      //         <Box
      //           sx={{
      //             my: 3,
      //             mx: 8,
      //             display: "flex",
      //             flexDirection: "column",
      //             alignItems: "center",
      //           }}
      //         >
      //           <VolunteerActivismIcon
      //             sx={{ mb: 1, width: 56, height: 56, color: "#ed1e70" }}
      //           />

      //           <Typography
      //             component="h1"
      //             variant="h5"
      //             sx={{ color: "#135c82" }}
      //           >
      //             {t("Volunteer_Registration")}
      //           </Typography>

      //           <Box sx={{ mt: 1 }}>
      //             <Grid container rowSpacing={2}>
      //               <Grid item xs={12}>
      //                 <TextField
      //                   required
      //                   margin="normal"
      //                   fullWidth
      //                   placeholder={t("Enter_Volunteer_Name")}
      //                   id="name"
      //                   label={t("Volunteer_Name")}
      //                   name="name"
      //                   onBlur={formik.handleBlur}
      //                   onChange={(e) => {
      //                     if (e) {
      //                       const value = e.target.value.replace(
      //                         /[^a-z'. ]/gi,
      //                         ""
      //                       );
      //                       formik.setFieldValue("name", value);
      //                     }
      //                   }}
      //                   value={formik.values.name}
      //                 />
      //                 {formik.touched.name && formik.errors.name ? (
      //                   <div style={{ color: "red" }}>
      //                     {formik.errors.name}
      //                   </div>
      //                 ) : null}
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <TextField
      //                   required
      //                   margin="normal"
      //                   fullWidth
      //                   placeholder={t("Enter_Current_Mobile_Number3")}
      //                   name="mobile_number"
      //                   label={t("Mobile_Number3")}
      //                   type="Number"
      //                   id="mobile_number"
      //                   onBlurCapture={() => {
      //                     setFocusNo(true);
      //                   }}
      //                   onWheel={(e) => {
      //                     e.preventDefault();
      //                     e.target.blur();
      //                   }}
      //                   onKeyDown={(e) =>
      //                     exceptThisSymbols.includes(e.key) &&
      //                     e.preventDefault()
      //                   }
      //                   onBlur={formik.handleBlur}
      //                   onChange={handleChange}
      //                   value={formik.values.mobile_number}
      //                   InputProps={{
      //                     startAdornment: (
      //                       <InputAdornment position="start">
      //                         <b>+91</b>
      //                       </InputAdornment>
      //                     ),
      //                   }}
      //                 />
      //                 {formik.touched.mobile_number &&
      //                 formik.errors.mobile_number &&
      //                 focusNo ? (
      //                   <div style={{ color: "red" }}>
      //                     {formik.errors.mobile_number}
      //                   </div>
      //                 ) : null}
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <TextField
      //                   margin="normal"
      //                   fullWidth
      //                   required
      //                   placeholder={t("Enter_Email_ID3")}
      //                   id="email_id"
      //                   label={t("Email_Id3")}
      //                   name="formEmail"
      //                   onChange={handleEmailChange}
      //                   onBlur={handleEmailBlur}
      //                   value={formEmail}
      //                   onBlurCapture={() => {
      //                     setfocus(true);
      //                   }}
      //                   InputLabelProps={{
      //                     style: { color: "black" },
      //                   }}
      //                   InputProps={{
      //                     startAdornment: (
      //                       <InputAdornment position="start">
      //                         <MailOutlineIcon />
      //                       </InputAdornment>
      //                     ),
      //                   }}
      //                 />
      //                 {formEmailError && focusout ? (
      //                   <div style={{ color: "red" }}>{formEmailError}</div>
      //                 ) : null}
      //               </Grid>
      //               <Grid item xs={12}>
      //                 <TextField
      //                   required
      //                   margin="normal"
      //                   fullWidth
      //                   placeholder={t("Enter_Your_Designation3")}
      //                   id="designation"
      //                   label={t("Designation3")}
      //                   name="designation"
      //                   onBlur={formik.handleBlur}
      //                   onChange={(e) => {
      //                     if (e) {
      //                       const value = e.target.value.replace(
      //                         /[^a-z'. ']/gi,
      //                         ""
      //                       );
      //                       formik.setFieldValue("designation", value);
      //                     }
      //                   }}
      //                   value={formik.values.designation}
      //                 />
      //                 {formik.touched.designation &&
      //                 formik.errors.designation ? (
      //                   <div style={{ color: "red" }}>
      //                     {formik.errors.designation}
      //                   </div>
      //                 ) : null}
      //               </Grid>
      //               <Grid item xs={12}>
      //                 {industryArray && (
      //                   <FormControl sx={{ mt: 1, mb: 1 }} fullWidth required>
      //                     <Autocomplete
      //                       disableClearable={
      //                         formik.values.industry ? false : true
      //                       }
      //                       filterOptions={filterOptions}
      //                       id="industry"
      //                       name="industry"
      //                       options={industryArray}
      //                       onBlur={formik.handleBlur}
      //                       onChange={(event, value) => {
      //                         setSelectedIndustry(value);
      //                         formik.setFieldValue("industry", value);
      //                       }}
      //                       value={formik.values.industry}
      //                       renderInput={(params) => (
      //                         <TextField
      //                           required
      //                           {...params}
      //                           id="industry"
      //                           label={t("Preffered_Industry")}
      //                           name="industry"
      //                           // onClick={() => params.inputProps.onInputClick()}
      //                         />
      //                       )}
      //                       onOpen={(event) => event.preventDefault()}
      //                       PopperComponent={({ children, ...popperProps }) => (
      //                         <Popper
      //                           {...popperProps}
      //                           placement="bottom-start"
      //                           modifiers={{
      //                             flip: {
      //                               enabled: false,
      //                             },
      //                             preventOverflow: {
      //                               enabled: true,
      //                               boundariesElement: "scrollParent",
      //                             },
      //                           }}
      //                           PopperProps={{
      //                             placement: "bottom-start", // Placement set to "bottom-start"
      //                           }}
      //                         >
      //                           <Paper>{children}</Paper>
      //                         </Popper>
      //                       )}
      //                       ListboxProps={{
      //                         style: { maxHeight: "180px", overflowY: "auto" },
      //                       }}
      //                     />
      //                     {formik.touched.industry && formik.errors.industry ? (
      //                       <div style={{ color: "red" }}>
      //                         {formik.errors.industry}
      //                       </div>
      //                     ) : null}
      //                   </FormControl>
      //                 )}
      //               </Grid>

      //               <Grid item xs={12} sx={{ marginTop: 1 }}>
      //                 <FormControl fullWidth required>
      //                   <Autocomplete
      //                     disableClearable={
      //                       formik.values.district ? false : true
      //                     }
      //                     fullWidth
      //                     filterOptions={filterOptions}
      //                     name="district"
      //                     id="district"
      //                     value={formik.values.district}
      //                     onChange={(event, value) => {
      //                       formik.setFieldValue("district", value);
      //                       searchobj(value);
      //                       formik.setFieldValue("pincode", "");
      //                     }}
      //                     onBlur={formik.handleBlur}
      //                     options={districtData}
      //                     getOptionLabel={(option) => option}
      //                     renderInput={(params) => (
      //                       <TextField
      //                         required
      //                         {...params}
      //                         label={t("District3")}
      //                         // onClick={() => params.inputProps.onInputClick()}
      //                       />
      //                     )}
      //                     onOpen={(event) => event.preventDefault()}
      //                     PopperComponent={({ children, ...popperProps }) => (
      //                       <Popper
      //                         {...popperProps}
      //                         placement="bottom-start"
      //                         modifiers={{
      //                           flip: {
      //                             enabled: false,
      //                           },
      //                           preventOverflow: {
      //                             enabled: true,
      //                             boundariesElement: "scrollParent",
      //                           },
      //                         }}
      //                         PopperProps={{
      //                           placement: "bottom-start", // Placement set to "bottom-start"
      //                         }}
      //                       >
      //                         <Paper>{children}</Paper>
      //                       </Popper>
      //                     )}
      //                     ListboxProps={{
      //                       style: { maxHeight: "180px", overflowY: "auto" },
      //                     }}
      //                   />
      //                   {formik.touched.district && formik.errors.district ? (
      //                     <div style={{ color: "red" }}>
      //                       {formik.errors.district}
      //                     </div>
      //                   ) : null}
      //                 </FormControl>
      //               </Grid>

      //               <Grid item xs={12}>
      //                 <Tooltip
      //                   title={
      //                     formik.values.district ? "" : "please select District"
      //                   }
      //                 >
      //                   <FormControl fullWidth required sx={{ marginY: 2 }}>
      //                     <Autocomplete
      //                       disableClearable={
      //                         formik.values.pincode ? false : true
      //                       }
      //                       disablePortal
      //                       freeSolo
      //                       disabled={
      //                         formik.values.district && !pinLoad ? false : true
      //                       }
      //                       id="pincode"
      //                       name="pincode"
      //                       options={comdata}
      //                       value={formik.values.pincode}
      //                       onChange={(event, value) => {
      //                         formik.setFieldValue("pincode", value);
      //                       }}
      //                       onBlur={formik.handleBlur}
      //                       renderInput={(params) => (
      //                         <TextField
      //                           required
      //                           // onChange={formik.handleChange}
      //                           {...params}
      //                           label={t("Pincode")}
      //                           InputProps={{
      //                             ...params.InputProps,
      //                             startAdornment: (
      //                               <InputAdornment position="start">
      //                                 {pinLoad ? (
      //                                   <CircularProgress
      //                                     // size={24}
      //                                     style={{
      //                                       width: "1.4rem",
      //                                       height: "1.4rem",
      //                                       color: "#4991dd",
      //                                     }}
      //                                   />
      //                                 ) : (
      //                                   <ShareLocationRoundedIcon
      //                                     sx={{
      //                                       color: formik.values.district
      //                                         ? "#414141"
      //                                         : "#bdbdbd",
      //                                     }}
      //                                   />
      //                                 )}{" "}
      //                               </InputAdornment>
      //                             ),
      //                           }}
      //                         />
      //                       )}
      //                     />

      //                     {/* <TextField
      //                   margin="normal"
      //                   fullWidth
      //                   name="pincode"
      //                   label={t("Pincode")}
      //                   type="Number"
      //                   id="pincode"
      //                   onWheel={(e) => {
      //                     e.preventDefault();
      //                     e.target.blur();
      //                   }}
      //                   onKeyDown={(e) =>
      //                     exceptThisSymbols.includes(e.key) &&
      //                     e.preventDefault()
      //                   }
      //                   onBlurCapture={() => {
      //                     setFocusPin(true);
      //                   }}
      //                   placeholder={t("Enter_Pincode(Optional)")}
      //                   onBlur={formik.handleBlur}
      //                   onChange={handleChangePincode}
      //                   value={formik.values.pincode}
      //                 /> */}
      //                     {formik.touched.pincode && formik.errors.pincode ? (
      //                       <div style={{ color: "red" }}>
      //                         {formik.errors.pincode}
      //                       </div>
      //                     ) : null}
      //                   </FormControl>
      //                 </Tooltip>
      //               </Grid>

      //               <Button
      //                 type="submit"
      //                 fullWidth
      //                 variant="contained"
      //                 sx={{ mt: 3, mb: 2 }}
      //                 disabled={
      //                   !formEmailError &&
      //                   formik.isValid &&
      //                   formik.dirty &&
      //                   isSubmitting === false
      //                     ? false
      //                     : true
      //                 }
      //               >
      //                 {t("Submit3")}
      //                 {isSubmitting && (
      //                   <CircularProgress
      //                     size={24}
      //                     sx={{
      //                       position: "absolute",
      //                       top: "50%",
      //                       left: "50%",
      //                       marginTop: "-12px",
      //                       marginLeft: "-12px",
      //                     }}
      //                   />
      //                 )}
      //               </Button>
      //             </Grid>
      //           </Box>
      //         </Box>
      //       </form>
      //     </Grid>
      //   </Grid>
      //   <Outlet />
      // </ThemeProvider>
      <>
        <div className="overall-jobseeker-form">
          <div className="left-jobseekers-img">
            <div style={{ visibility: "hidden" }}>fsdfsd</div>
            <div className="leftimg-box">
              <img src={lang === "tamil" ? leftTamil : leftimg} alt="img" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="jobseeker-form-box">
              <div className="jobseeker-form-header">
                <h2>
                  <span>{t("Help_for_Job_Seekers")}</span> {t("Registration")}
                </h2>
              </div>
              <div className="jobseeker-box-reg-form">
                <div className="jobseeker-box-inputs">
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Are_you")}
                      <text>*</text>
                    </label>
                    <div className="jobseeker-btn-img">
                      <Stack
                        direction="row"
                        className="mobile-for-gender voluter-space"
                        spacing={2}
                      >
                        <Button
                          className={
                            formik.values.source_type === "Individual"
                              ? "jobseeker-btn-gender-active voluter-btn-active"
                              : "jobseeker-btn-gender  voluter-btn"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("source_type", "Individual");
                            setCheckWhatsapp("unchecked");
                            clear();
                            setbuttonDisable(false)
                            setcsrcheck("No");
                            setFormEmailError("");
                            setfocus(false);
                            formik.touched.name = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.name = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.country = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.country = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.mobile_number = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.mobile_number = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.whatsapp_number = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.whatsapp_number = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.comments = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.comments = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.email_id = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.email_id = "";
                            formik.setErrors({ ...formik.errors });
                          }}
                          startIcon={
                            formik.values.source_type === "Individual" ? (
                              <PersonIcon className="gendericon-Active voluter-icon-active" />
                            ) : (
                              <PersonIcon className="gendericon voluter-icon" />
                            )
                          }
                        >
                          <span
                            className={
                              formik.values.source_type === "Individual"
                                ? "btn-space-name-active colorfont-active"
                                : "btn-space-name colorfont"
                            }
                          >
                            {t("Individual")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.source_type === "Company"
                              ? "jobseeker-btn-gender-active voluter-btn-active"
                              : "jobseeker-btn-gender voluter-btn"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("source_type", "Company");
                             setCheckWhatsapp("unchecked");
                            clear();
                            setbuttonDisable(false)
                            setcsrcheck("No");
                            setFormEmailError("");
                            setfocus(false);
                            formik.touched.name = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.name = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.country = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.country = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.mobile_number = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.mobile_number = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.whatsapp_number = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.whatsapp_number = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.comments = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.comments = "";
                            formik.setErrors({ ...formik.errors });
                            formik.touched.email_id = undefined;
                            formik.setTouched({ ...formik.touched });
                            formik.errors.email_id = "";
                            formik.setErrors({ ...formik.errors });
                          }}
                        >
                          {formik.values.source_type === "Company" ? (
                            <img className="girlimg" src={officeactive} />
                          ) : (
                            <img className="girlimg" src={office} />
                          )}
                          <span
                            className={
                              formik.values.source_type === "Company"
                                ? "btn-space-name-active colorfont-active"
                                : "btn-space-name colorfont"
                            }
                          >
                            {t("company")}
                          </span>
                        </Button>
                      </Stack>
                    </div>
                    <FormHelperText>
                      <div style={{ visibility: "hidden" }}>wsdwdqw</div>
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    {formik.values.source_type === "Company" ? (
                      <label>
                        {t("Company_Name")}
                        <text>*</text>
                      </label>
                    ) : (
                      <label>
                        {t("Full_Name")}
                        <text>*</text>
                      </label>
                    )}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="name"
                      required
                      variant="outlined"
                      size="small"
                      name="name"
                      placeholder={
                        formik.values.source_type === "Individual"
                          ? t("Individual_name")
                          : t("company_name")
                      }
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          const formattedName = value.charAt(0).toUpperCase() + value.slice(1);
                          formik.setFieldValue("name", formattedName);
                          setbuttonDisable(true)
                        }
                      }}
                      value={formik.values.name}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {formik.values.source_type === "Individual" ? (
                              <PersonIcon />
                            ) : (
                              <img className="girlimg" alt="" src={office} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.name && formik.errors.name ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.name}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ visibility: "hidden", fontSize: "13px" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Country")}
                      <text>*</text>
                    </label>
                    {countrydata && (
                      <Autocomplete
                        filterOptions={filterOptions}
                        sx={{ marginTop: "8px", backgroundColor: "white" }}
                        disableClearable={formik.values.country ? false : true}
                        size="small"
                        id="country"
                        name="country"
                        options={countrydata}
                        onBlur={formik.handleBlur}
                        onChange={(event, value) => {
                          if (
                            value !== undefined ||
                            value !== null ||
                            value !== ""
                          ) {
                            const mobileCodeGet = countrycodeData.filter(
                              (data) => data.countries === value
                            );
                            setMobileCode(mobileCodeGet[0]?.country_code);
                            setSelectedIndustry(value);
                            formik.setFieldValue("country", value);
                          }
                        }}
                        value={formik.values.country}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder={t("country_Name")}
                            id="country"
                            name="country"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    className="Industryimg"
                                    alt="icon"
                                    src={Country}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            // onClick={() => params.inputProps.onInputClick()}
                          />
                        )}
                        onOpen={(event) => event.preventDefault()}
                        PopperComponent={({ children, ...popperProps }) => (
                          <Popper
                            {...popperProps}
                            placement="bottom-start"
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent",
                              },
                            }}
                            PopperProps={{
                              placement: "bottom-start", // Placement set to "bottom-start"
                            }}
                          >
                            <Paper>{children}</Paper>
                          </Popper>
                        )}
                        ListboxProps={{
                          style: { maxHeight: "180px", overflowY: "auto" },
                        }}
                      />
                    )}
                    <FormHelperText>
                      {formik.touched.country && formik.errors.country ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.country}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ visibility: "hidden", fontSize: "13px" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Email_Id")}
                      <text>*</text>
                    </label>
                    {/* <Tooltip title="Enter your Email" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      size="small"
                      required
                      placeholder={t("Enter_Email_ID3")}
                      id="email_id"
                      name="formEmail"
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      value={formEmail}
                      onBlurCapture={() => {
                        setfocus(true);
                      }}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formEmailError && focusout ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formEmailError}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Mobile_Number")}
                      <text>*</text>
                    </label>
                    {/* <Tooltip title="Enter Your Mobile Number" arrow> */}
                    <div className="whatsappnumber-active">
                      <p>
                        <Checkbox
                          className="checkbox"
                          size="small"
                          checked={checkWhatsapp === 'checked'}
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setCheckWhatsapp("checked");
                              formik.setFieldValue(
                                "whatsapp_number",
                                formik.values.mobile_number
                              );
                            } else {
                              setCheckWhatsapp("unchecked");
                              formik.setFieldValue("whatsapp_number", "");
                            }
                          }}
                        />
                        {t("Update_now_on")} <text>{t("WhatsApp")}</text>
                        {t("click_the_button")}
                      </p>
                    </div>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      required
                      id="mobile_number"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="mobile_number"
                      placeholder={t("Enter_Your_Mobile_Number")}
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onBlurCapture={() => {
                        setFocusNo(true);
                      }}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value) {
                          if (checkWhatsapp === "checked") {
                            formik.setFieldValue(
                              "whatsapp_number",
                              e.target.value
                            );
                          } else  {
                            
                          }
                        }
                      }}
                      value={formik.values.mobile_number}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "medium",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              {mobileCode}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.mobile_number}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  {checkWhatsapp === "unchecked" ? (
                    <div className="jobseeker-textfield">
                      <label>
                        {t("Whatsapp_Number")}
                        <text>*</text>
                      </label>
                      <TextField
                        sx={{ marginTop: "8px", backgroundColor: "white" }}
                        required
                        id="whatsapp_number"
                        variant="outlined"
                        size="small"
                        type="number"
                        name="whatsapp_number"
                        placeholder={t("Enter_Your_whatsapp_number")}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlurCapture={() => {
                          setFocusNo(true);
                        }}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        value={formik.values.whatsapp_number}
                        fullWidth
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        InputProps={{
                          // ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <span
                                style={{
                                  fontSize: "medium",
                                  fontFamily: "Poppins-SemiBold",
                                }}
                              >
                                {mobileCode}
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* </Tooltip> */}
                      <FormHelperText>
                        {formik.touched.whatsapp_number &&
                        formik.errors.whatsapp_number &&
                        focusNo ? (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors.whatsapp_number}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ fontSize: "13px", visibility: "hidden" }}
                          >
                            none
                          </Typography>
                        )}
                      </FormHelperText>
                    </div>
                  ) : null}
                  {formik.values.source_type === "Company" ? (
                    <div
                      className="jobseeker-textfield"
                      style={{
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        sx={{ padding: "0px", marginRight: "10px" }}
                        value="checked"
                        onClick={(e) => {
                          if (e.target.checked === true) {
                            setcsrcheck("Yes");
                            formik.setFieldValue(
                              "csr_option",
                              formik.values.csr_option
                            );
                          } else {
                            setcsrcheck("No");
                            formik.setFieldValue("csr_option", "");
                          }
                        }}
                      />
                      <label style={{ padding: "0px" }}>
                        CSR (Corporate Social Responsibility)
                      </label>
                    </div>
                  ) : null}

                  <div className="jobseeker-textfield">
                    <label>
                      {t("Comments")}
                      <text>*</text>
                    </label>
                    <TextField
                      multiline
                      rows={5}
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="comments"
                      required
                      variant="outlined"
                      size="small"
                      name="comments"
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value;
                          formik.setFieldValue("comments", value);
                        }
                      }}
                      value={formik.values.comments}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.comments && formik.errors.comments ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.comments}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ visibility: "hidden", fontSize: "13px" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div>
                    <div className="uploade-note" style={{ marginTop: "4px" }}>
                      {t("You_accept_the")}{" "}
                      <text
                        className="terms-and-conditions"
                        onClick={() => {
                          navigate(
                            `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {t("Terms_and_conditions")}{" "}
                      </text>{" "}
                      {t("and")}{" "}
                      <text
                        className="terms-and-conditions"
                        onClick={() => {
                          navigate(
                            `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {t("Privacy_policy")}{" "}
                      </text>{" "}
                      {t("of")}{" "}
                      <text className="terms-and-conditions-1">
                        {" "}
                        {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}{" "}
                      </text>{" "}
                      {t("by_clicking_the")}
                    </div>
                    <div style={{ marginTop: "15px", marginBottom: "5px" }}>
                      <Button
                        // type="submit"
                        disabled={
                          !formEmailError &&
                          formik.isValid &&
                          formik.dirty && buttonDisable === true &&
                          isSubmitting === false
                            ? false
                            : true
                        }
                        onClick={() => {
                          submitForm(formik.values);
                        }}
                        component="label"
                        variant="contained"
                        fullWidth
                      >
                        {t("Submit")}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            style={{
                              color: "#ffffff",

                              marginLeft: 12,
                            }}
                          />
                        )}
                        {/* <VisuallyHiddenInput type="file" /> */}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Email_Id_already_exist1")}
              {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}
              {t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Mobile_number_already_exist1")}
              {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}
              {t("This_Mobile_number_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={openok}
          aria-labelledby="responsive-dialog-title"
          maxWidth={600}
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}
          className="dailog"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            <p className="Successfully-text">{t("Thank_you_registration")}</p>
            {/* <text className="Check-text">{t("Check_your_mail")}</text> */}
          </DialogTitle>
          <DialogContent className="ahjahg">
            <DialogContentText>
              <div className="set-widthfordailogbox">
                <div className="img-complete">
                  <img src={sussesfullimg} />
                </div>
                <div>
                  {/* <p className="apply-your-text">{t("dream_jobs")}</p> */}
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="btn-successfully" onClick={handleCloseed}>
                    {t("Home_Page")}
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default Volunteer;
