import * as React from "react";
import { useState, useEffect } from "react";
import logo from "../../../img/logo_for-landingpage-3.png";
import AppBar from "@mui/material/AppBar";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ReactComponent as TwitterX } from "../../../img/twitter-x-seeklogo.com-4.svg";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import "../Landingpage.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { CurrentStateContext } from "../../../App";

export default function Landingheaderpage(props) {
  const navigate = useNavigate();
  const [headericon, setheadericon] = useState("35px");
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const [headerbtn, setheaderbtn] = useState("whitesmoke");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { t, i18n, ready } = useTranslation();
  const [langMenu, setLangMenu] = useState(false);
  const openMenu = Boolean(langMenu);
  const { languagetranslator } = useContext(CurrentStateContext);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeLanguage = (val) => {
    props.setlanguagetranslator(val);
    // localStorage.setItem("lang",val)
  };
  const lang = sessionStorage.getItem("lang");
  useEffect(() => {
    handleChangeLanguage();
  }, [languagetranslator]);

  const location = useLocation();
  const currentUrl = location.pathname;

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      sx={{ zIndex: "9999" }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`, {
            state: navigate(),
          });
        }}
      >
        <SearchIcon
          sx={{
            backgroundColor: "#ed1067",
            padding: "5px",
            color: "white",
            marginRight: "10px",
            borderRadius: "50px",
          }}
        />

        <p style={{ color: "#0675a2" }}>{t("JOB_SEEKER")}</p>
      </MenuItem>
      <MenuItem
        onClick={() =>
          navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`)
        }
      >
        <BusinessIcon
          sx={{
            backgroundColor: "#ed1067",
            padding: "5px",
            color: "white",
            marginRight: "10px",
            borderRadius: "50px",
          }}
        />

        <p style={{ color: "#0675a2" }}>{t("EMPLOYER")}</p>
      </MenuItem>
      <MenuItem
        onClick={() =>
          navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS)
        }
        size="small"
      >
        <ContactPageIcon
          sx={{
            backgroundColor: "#ed1067",
            padding: "5px",
            color: "white",
            marginRight: "10px",
            borderRadius: "50px",
          }}
        />

        <p style={{ color: "#0675a2" }}>{t("Call-To-Action-1")}</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScrollPosition > 0) {
        setHeaderBackground("whitesmoke"); // Change the header background color to blue when scrolled down
        setheaderbtn("#0675a2");
        setheadericon("0");
      } else {
        setHeaderBackground("transparent"); // Change the header background color to transparent when at the top
        setheaderbtn("white");
        setheadericon("35px");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (ready) {
    return (
      <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
        <div className="headericon">
          <div
            className="headericonspace"
            style={{ height: headericon, transition: "0.2s" }}
          >
            <div className="headerdetails centerpoints-header">
              <EmailIcon fontSize="small" />
              <div>
                <a
                  href="mailto:contactus@usglobalsolutions.com"
                  style={{ color: "#1976d2" }}
                >
                  contactus@usglobalsolutions.com
                </a>
              </div>
            </div>
            <div className="headerdetails  centerpoints-header iconsforheaders">
              <PhoneIphoneIcon fontSize="small" />
              <div>
                <a href="tel:+91 9080400330" style={{ color: "#1976d2" }}>
                  +91 9080400330
                </a>
              </div>
            </div>
            <div className="headerdetails headericoncenter ">
              <div className="iconsspace">
                <a href={process.env.REACT_APP_LINKEDIN_URL} target="_blank">
                  <LinkedInIcon
                    fontSize="small"
                    className="footer-icon-hover-5"
                    sx={{
                      color: "#0077b5",
                      padding: "3px",
                      borderRadius: "50%",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
              <div className="iconsspace">
                <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank">
                  <YouTubeIcon
                    fontSize="small"
                    className="footer-icon-hover-2"
                    sx={{
                      color: "#ff0000",
                      padding: "3px",
                      borderRadius: "50%",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
              <div className="iconsspace">
                <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank">
                  <FacebookIcon
                    fontSize="small"
                    className="footer-icon-hover-1"
                    sx={{
                      color: "#1877f2",
                      padding: "3px",
                      borderRadius: "50%",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>

              <div className="iconsspace">
                <a href={process.env.REACT_APP_TWITTER_URL} target="_blank">
                  <TwitterX
                    className="footer-icon-hover-3"
                    style={{
                      width: "21px",
                      height: "21px",
                      padding: "3px",
                      borderRadius: "50%",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
              <div className="iconsspace">
                <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank">
                  <InstagramIcon
                    fontSize="small"
                    className="footer-icon-hover-4"
                    sx={{
                      color: "#ed1067",
                      padding: "3px",
                      borderRadius: "50%",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <AppBar
          className="boxshadowlanding"
          position="static"
          sx={{
            backgroundColor: headerBackground,
            transition: "0.3s",
            position: "sticky",
            top: 0,
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div">
              <div className="headerlogoimg">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(ROUTINGDATA.LAND);
                  }}
                  src={logo}
                  alt="img"
                />
              </div>
            </Typography>
            <Box sx={{ flexGrow: "1" }}></Box>
            <Button
              id="basic-button"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={(event) => {
                setLangMenu(event.currentTarget);
              }}
              className={
                lang === "tamil" || "en" || "hindi" || "telugu" || "malayalam"
                  ? "header-link-active"
                  : "login-btn-header"
              }
              sx={{
                marginRight: "10px",
                color: headerbtn,
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              size="small"
            >
              {lang === "en" ? "English" : lang === "hindi" ? "हिंदी" : lang === "telugu" ? "తెలుగు" : lang === "malayalam" ? "മലയാളം" : "தமிழ்"}
              {langMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={langMenu}
              open={openMenu}
              onClose={() => {
                setLangMenu(false);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setLangMenu(false);
                  handleChangeLanguage("tamil");
                  sessionStorage.setItem("lang", "tamil");
                  handleClose();
                }}
              >
                <b>{t("Tamil")}</b>(Tamil)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLangMenu(false);

                  handleChangeLanguage("en");
                  sessionStorage.setItem("lang", "en");
                  handleClose();
                }}
              >
                <b>{t("English")}</b> (English)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLangMenu(false);
                  handleChangeLanguage("hindi");
                  sessionStorage.setItem("lang", "hindi");
                  handleClose();
                }}
              >
                <b>हिंदी</b>(Hindi)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLangMenu(false);
                  handleChangeLanguage("telugu");
                  sessionStorage.setItem("lang", "telugu");
                  handleClose();
                }}
              >
                <b>తెలుగు</b>(Telugu)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLangMenu(false);
                  handleChangeLanguage("malayalam");
                  sessionStorage.setItem("lang", "malayalam");
                  handleClose();
                }}
              >
                <b>മലയാളം</b>(Malayalam)
              </MenuItem>
            </Menu>
            {/* <Button
              className={
                lang === "tamil" ? "header-link-active" : "login-btn-header"
              }
              sx={{
                marginRight: "10px",
                color: headerbtn,
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              size="small"
              onClick={() => {
                handleChangeLanguage("tamil");
                sessionStorage.setItem("lang", "tamil");
                handleClose();
              }}
            >
              {t("Tamil")}
            </Button>

            <Button
              className={
                lang === "en" ? "header-link-active" : "login-btn-header"
              }
              sx={{
                marginRight: "10px",
                color: headerbtn,
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              onClick={() => {
                handleChangeLanguage("en");
                sessionStorage.setItem("lang", "en");
                handleClose();
              }}
              size="small"
            >
              {t("English")}
            </Button> */}

            <div>
              <Button
                className="reg-btn-header"
                sx={{
                  marginRight: "5px",
                  fontWeight: "600",
                  borderBottom: "3px solid transparent",
                  color: headerbtn,
                }}
                onClick={() => {
                  navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`, {
                    state: languagetranslator,
                  });
                }}
              >
                {t("JOB_SEEKER")}
              </Button>
            </div>
            <Button
              className="reg-btn-header"
              onClick={() =>
                navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`,{state:{registertype:"home",registerby:"home"}})
              }
              sx={{
                marginRight: "10px",
                fontWeight: "600",
                borderBottom: "3px solid transparent",
                color: headerbtn,
              }}
            >
              {t("EMPLOYER")}
            </Button>
            {/* <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                color: "#ed1067",
                fontWeight: "600",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              <TranslateIcon />
              <ArrowDropDownIcon />
            </Button>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={open}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setlanguagetranslator("tamil");
                  handleClose();
                }}
              >
                தமிழ்
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setlanguagetranslator("en");
                  handleClose();
                }}
              >
                English
              </MenuItem>
            </Menu>  */}

            <Button
              className={
                lang === "tamil" ? "login-btn-header-tamil" : "login-btn-header"
              }
              sx={{
                marginRight: "10px",
                color: headerbtn,
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              onClick={() => navigate(ROUTINGDATA.LOGIN)}
              size="small"
            >
              {t("Login")}
            </Button>
            <Button
              className={
                currentUrl === ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS
                  ? "reg-btn-header-contact"
                  : "reg-btn-header"
              }
              sx={{
                color: headerbtn,
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
                padding: "5px 10px",
              }}
              onClick={() =>
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS)
              }
            >
              <text style={{ marginRight: "5px" }}>{t("Call-To-Action")}</text>
              <text style={{ letterSpacing: "-1px" }}>{t("us")}</text>
            </Button>
            <Button
              className={
                lang === "tamil"
                  ? "login-btn-header-tamil-1"
                  : "login-btn-header"
              }
              sx={{
                marginRight: "10px",

                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid #1976d2",
                display: { xs: "none", md: "flex" },
              }}
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.ADVERTISEMENT
                  // {
                  //   state: { user_id: userDetails.userId, company_name: userDetails.company_name,email_id:userDetails.emailId,mobile_number:userDetails.mobile_number },
                  // }
                );
              }}
              size="small"
              variant="outlined"
            >
              ad
            </Button>

            <Box
              sx={{ display: { xs: "flex", md: "none" } }}
              className="more-icon"
            >
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon sx={{ color: "#ed1067" }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </Box>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
