import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Chip,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
} from "@mui/material";
import useAxios from "../../../../api/useAxios";

import { useLocation } from "react-router-dom";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material/";
import Breadcrumb from "../../BreadCrumb";

const InvestorTodayCollectionReport = () => {
  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [Onepincode, setOnePincode] = useState("");
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const investor_email = JSON.parse(localStorage.getItem("investor"));
  const location = useLocation();
  // const email = location.state?.email_id;
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (pincode) => {
    setOpen(!open);

    setOnePincode(pincode);
  };
  const axiosData = useAxios();
  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get(
        `investors/pincode/${investor_email}`
      );
      const { data } = useAxiosData;
      data.sort((a, b) => {
        return a.pincode - b.pincode;
      });
      setGetApi(data);
      setUseApi(data);
      setTotalRows(data.length);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchApi();
  }, [investor_email]);

  return (
    <div><div className="breadcrumb-desgin">
    <Breadcrumb />
  </div>
  <div className="space-between-sor-table">
    <Card
      sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <h3 className="seeker-text">My Leads</h3>
      </Box>
    </Card>
    <div className="tablecardinvestor">
      <Card sx={{ padding: "20px" , mb: 8}}>
        <div style={{ textAlign: "right" }}>
          <div className="btn-dasboard-investor">
            <Button variant="contained">employefrom</Button>
            <Button variant="contained">localservices</Button>
          </div>
        </div>
        <TableContainer
          component={Paper}
          sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-row">
                {/* <TableCell> Profile</TableCell> */}
                <TableCell>Pincode</TableCell>
                <TableCell> Ad</TableCell>
                <TableCell align="center"> Job Post</TableCell>

                <TableCell align="center"> Local Service</TableCell>
                <TableCell align="center">Assign</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {useApi.length > 0 ? (
                <>
                  {useApi.map((item) => (
                    <TableRow
                      // key={item._id}
                      hover
                      className="tablecell"
                    >
                      <TableCell>sgfsdf</TableCell>

                      <TableCell>afasdf</TableCell>
                      <TableCell align="center">asasdsa</TableCell>
                      <TableCell align="center">asfasf</TableCell>
                      <TableCell align="center">
                        {/* {item.pincode_franchiser ? (
                      <Typography>
                        {item.pincode_franchiser.pincode_franchiser_email}
                      </Typography>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          handleChange(item.pincode);
                        }}
                      >
                        Assign Pincode
                      </Button>
                    )} */}
                        sgfdfgdf
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  <TableRow>
                    {/* <TableCell></TableCell> */}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                    <TableCell>
                      {" "}
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Barlow-Bold" }}
                      >
                        NO Data Available
                      </Typography>
                    </TableCell>
                    {/* <TableCell></TableCell> */}

                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  </div></div>
  )
}

export default InvestorTodayCollectionReport