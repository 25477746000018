import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import useAxios from "../../../../api/useAxios";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  Tooltip,
  TablePagination,
  Grid,
  Typography,
  Paper,
  Switch,
  Fab,
  Chip,
  Skeleton,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  TextField,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import EditIcon from "@mui/icons-material/Edit";

import {
  CheckRounded as CheckRoundedIcon,
  RefreshSharp as RefreshSharpIcon,
} from "@mui/icons-material/";
import { ShareLocationRounded as ShareLocationRoundedIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as Warning } from "../../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import defaultimg from "../../../../img/addefaultimage.jpg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import "jspdf-autotable";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS.js";
import ExportPDF from "../../../admin-panel/admin-components/ExportComponents/ExportPDF.js";
import { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../Landingpage/Landingpage.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Slide, Slider } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AdminAdvertisementSettings() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [filterPosition, setFilterPosition] = useState("all");
  const [filterStatus, setFilterStatus] = useState("");
  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState("");
  const [getOneAD, setGetOneAD] = useState("");
  const [getOneAdDetails, setGetOneAdDetails] = useState({});
  const [postJopEditPopup, setPostJopEditPopup] = useState(false);
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [dateenable, setdateenable] = useState(true);
  const [isValid, setIsValid] = useState(true);
  // <!----SORt--->
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchicon, setsearchicon] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [confirmCloseAd, setConfirmCloseAd] = useState(false);
  const [openAd, setOpenAd] = useState(false);
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [openDelete, setOpenDelete] = useState(false);
  const [uploadEnable, setUploadEnable] = useState(false);
  const snack = JSON.parse(sessionStorage.getItem("seeker-setting"));
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [imageSize, setImageSize] = useState(false);
  const minDate = moment("01-06-2023", "DD-MM-YYYY");
  const maxDate = moment();
  const [addGetList, setAddGetList] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [addGetListDays, setAddGetListDays] = useState([
    "oneweek",
    "twoweek",
    "threeweek",
    "fourweek",
    "fifteendays",
    "thirtydays",
  ]);
  const [pinLoad, setpinLoad] = useState(false);
  const [comdata, setComdata] = useState([]);
  const [widthSize, setWidthSize] = useState("1092px");
  const [heightSize, setheightSize] = useState("275px");
  const [videoAd, setVideoAd] = useState("");
  const [size, setSize] = useState();
  const [source, setSource] = useState();
  const [open2, setOpen2] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [toShowImage, setToShowImage] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(null);
  const [Company, setCompany] = useState("");
  const [openLogo, setOpenLogo] = useState(false);
  const [toShowImageLogo, setToShowImageLogo] = useState("");
  const [uploadedImagesLogo, setUploadedImagesLogo] = useState([]);
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const [pictureLogo, setPictureLogo] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const [onOffset, setonOffset] = useState({
    check: "",
    ad_id: "",
  });
  var editor = "";
  const [picture2, setPicture2] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const handleCancelLogo = () => {
    setPictureLogo({
      ...pictureLogo,
      cropperOpen: false,
    });
  };
  const MAX_FILE_SIZE_VIDEO =
    1048576 * parseInt(process.env.REACT_APP_VIDEO_SIZE_FOR_AD);
  const fileInputField = useRef(null);
  const fileInputField2 = useRef(null);
  const getAddDetails = async (getDetails) => {
    try {
      const response = await axiosData.get(`addsdetails`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const weekData = response.data.filter(
          (d) => d.position === getDetails.image_position
        );
        const week = weekData[0];
        setAddGetList(week);
        setWidthSize(week.width);
        setheightSize(week.height);
      }
      if (response.status === 204 || response.data.length === 0) {
        //setLoading(false);
        //setAddGetList("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const stickyDivStyle = {
    width:
      widthSize === "" || widthSize === undefined || widthSize === null
        ? 0
        : `${widthSize.split("px")[0]}`,
    height:
      heightSize === "" || widthSize === undefined || widthSize === null
        ? 0
        : `${heightSize.split("px")[0]}`,
  };
  const handleClose = () => {
    setOpen2(false);
  };
  const handleClickOpen = () => {
    setOpen2(true);
  };
  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];
    setPicture2({
      ...picture2,
      img: imgFile,
      cropperOpen: true,
    });
    if (imgFile) {
      handleClickOpen();
      fileInputField.current.value = "";
    } else {
      return;
    }
    const maxSize =
      2 * parseInt(stickyDivStyle.width) * parseInt(stickyDivStyle.height);
    if (imgFile.size > maxSize) {
      setImageSize(true);
      fileInputField.current.value = "";
      return;
    }
  };
  const handleCancel = () => {
    setPicture2({
      ...picture2,
      cropperOpen: false,
    });
  };
  const handleSlider = (event, value) => {
    setPicture2({
      ...picture2,
      zoom: value,
    });
  };
  const setEditorRef = (ed) => {
    editor = ed;
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const handleSave = () => {
    setToShowImage("");
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      if (croppedImg) {
        setPicture2({
          ...picture2,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
        var block = croppedImg.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 2000;
        const img = b64toBlob(realData, contentType, sliceSize);

        setToShowImage((prevImages) => [...prevImages, croppedImg]);
        setUploadedImages({ img });
      } else {
      }
    }
  };
  const handleFileChangeVideo = async (e) => {
    setVideoAd("");
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size;
    setSize(fileSize);
    const url = URL.createObjectURL(file);
    setSource(url);
    const values = { videoUpload: "" };
    if (!file) return;
    if (e.target.files[0]) {
      values.videoUpload = e.target.files[0];
    }
    if (e.target.files[0].size < MAX_FILE_SIZE_VIDEO) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let newSelectedAttachment = {};
        newSelectedAttachment.file = file;
        newSelectedAttachment.blobData = e.target.result;
        if (file.type.includes("video")) {
          setVideoAd(newSelectedAttachment.blobData);
        }
      };
      reader.readAsDataURL(file);
      fileInputField2.current.value = "";
      setrefresh(!refresh);
      setTimeout(() => {}, 5000);
    } else {
      setVideoUpload(true);
      fileInputField2.current.value = "";
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        const temp = getPostFinal.reverse();
        temp.push("Select All");
        temp.reverse();
        setComdata([...temp]);
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setstartError(null);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setendError(null);
  };
  const fetchApi = async () => {
    setLoading({ ...loading, isLoading: true });
    try {
      const useAxiosData = await axiosData.get(`advertisement`);
      const { data } = useAxiosData;
      data.reverse();
      if (useAxiosData.status === 204) {
        setGetApi([]);
        setLoading({ ...loading, isLoading: false });
      } else {
        setGetApi(data);
        handleClickFilter(data, startDate, endDate);
        setsearchicon(true);
        setPage(page);
        setLoading({ ...loading, isLoading: false });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleSliderLogo = (event, value) => {
    setPictureLogo({
      ...pictureLogo,
      zoom: value,
    });
  };
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  const handleClickFilter = (data, startdate, enddate) => {
    const filteredData = data.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startdate).subtract(1, "days");
      return date.isBetween(start, enddate, null, "[]");
    });
    if (filterPosition === "all") {
      if (filterStatus === "") {
        setUseApi(filteredData);
        setsearchicon(true);
        setTotalRows(filteredData.length);
        setPage(0);
      } else {
        const filteredDataFinal = filteredData.filter(
          (d) => d.status === filterStatus
        );
        setUseApi(filteredDataFinal);
        setsearchicon(true);
        setTotalRows(filteredDataFinal.length);
        setPage(0);
      }
    } else {
      const filteredData2 = filteredData.filter(
        (d) => d.image_position === filterPosition
      );
      if (filterStatus === "") {
        setUseApi(filteredData2);
        setsearchicon(true);
        setTotalRows(filteredData2.length);
        setPage(0);
      } else {
        const filteredDataFinal = filteredData2.filter(
          (d) => d.status === filterStatus
        );
        setUseApi(filteredDataFinal);
        setsearchicon(true);
        setTotalRows(filteredDataFinal.length);
        setPage(0);
      }
    }
  };

  const handleSelectPosition = (data, positionData, statusData) => {
    const filteredData1 = data.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate).subtract(1, "days");
      return date.isBetween(start, endDate, null, "[]");
    });
    if (positionData === "all") {
      if (statusData === "") {
        setUseApi(filteredData1);
        setsearchicon(true);
        setTotalRows(filteredData1.length);
        setPage(0);
      } else {
        const filteredDataFinal = filteredData1.filter(
          (d) => d.status === statusData
        );
        setUseApi(filteredDataFinal);
        setsearchicon(true);
        setTotalRows(filteredDataFinal.length);
        setPage(0);
      }
    } else {
      const filteredData = filteredData1.filter(
        (d) => d.image_position === positionData
      );
      if (statusData === "") {
        setUseApi(filteredData);
        setsearchicon(true);
        setTotalRows(filteredData.length);
        setPage(0);
      } else {
        const filteredDataFinal = filteredData.filter(
          (d) => d.status === statusData
        );
        setUseApi(filteredDataFinal);
        setsearchicon(true);
        setTotalRows(filteredDataFinal.length);
        setPage(0);
      }
    }
  };
  const handleSaveLogo = () => {
    setToShowImageLogo("");
    setUploadedImagesLogo("");
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      if (croppedImg) {
        setPictureLogo({
          ...pictureLogo,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
        var block = croppedImg.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 512;
        const img = b64toBlob(realData, contentType, sliceSize);
        setToShowImageLogo((prevImages) => [...prevImages, croppedImg]);
        setUploadedImagesLogo((prevImages) => [...prevImages, img]);
      } else {
      }
    }
  };
  const updateAd = async (values) => {
    setLoading({ ...loading, isLoading: true });
    const id = getOneAdDetails._id;
    if (uploadedImages !== null) {
      var block = picture2.croppedImg.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var sliceSize = 2000;
      const img = b64toBlob(realData, contentType, sliceSize);
      values.addUpload = img;
    } else if (videoAd !== "") {
      if (
        pictureLogo.croppedImg === "" ||
        pictureLogo.croppedImg === undefined ||
        pictureLogo.croppedImg === null
      ) {
        var block1 = videoAd.split(";");
        var contentType1 = block1[0].split(":")[1];
        var realData1 = block1[1].split(",")[1];
        var sliceSize1 = 2000;
        const videobase = b64toBlob(realData1, contentType1, sliceSize1);
        values.addUpload = videobase;
      } else {
        var block2 = pictureLogo.croppedImg.split(";");
        var contentType2 = block2[0].split(":")[1];
        var realData2 = block2[1].split(",")[1];
        var sliceSize2 = 512;
        const img = b64toBlob(realData2, contentType2, sliceSize2);
        values.videoThumbnailImage = img;
        var block3 = videoAd.split(";");
        var contentType3 = block3[0].split(":")[1];
        var realData3 = block3[1].split(",")[1];
        var sliceSize3 = 2000;
        const videobase = b64toBlob(realData3, contentType3, sliceSize3);
        values.addUpload = videobase;
      }
    } else if (toShowImageLogo !== "") {
      var block4 = pictureLogo.croppedImg.split(";");
      var contentType4 = block4[0].split(":")[1];
      var realData4 = block4[1].split(",")[1];
      var sliceSize4 = 512;
      const img = b64toBlob(realData4, contentType4, sliceSize4);
      values.videoThumbnailImage = img;
    } else {
    }
    try {
      const response = await axiosData.put(
        `advertisement/Details/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        window.location.reload();
        setPostJopEditPopup(false);
        fetchApi();
      } else if (response.status === 403) {
      }
    } catch (err) {}
  };
  const handleFileChangeLogo = (e) => {
    let imgFile = e.target.files[0];
    setPictureLogo({
      ...pictureLogo,
      img: imgFile,
      cropperOpen: true,
    });
    if (!imgFile) {
      return;
    } else {
      handleClickOpenLogo();
      fileInputField.current.value = "";
    }
    const maxSize = 2 * 1024 * 1024;
    if (imgFile.size > maxSize) {
      setImageSize(true);
      fileInputField.current.value = "";
      return;
    }
  };
  const handleClickOpenLogo = () => {
    setOpenLogo(true);
  };
  useEffect(() => {
    vodingDistrict();
    fetchApi();
    setFilterPosition("all");
    setFilterStatus("");
  }, []);
  const handleClickRefresh = (data) => {
    setStartDate(moment());
    setEndDate(moment());
    setstartdisabled(false);
    setenddisabled(false);
    setstartError(null);
    setendError(null);
    setFilterPosition("all");
    setFilterStatus("");
    handleClickFilter(data, moment(), moment());
  };
  const updateASettings = async (check, id) => {
    if (check === false) {
      try {
        const useAxiosData2 = await axiosData.put(`advertisement/${id}`, {
          status: "Close",
        });
        const { data } = useAxiosData2;
        if (useAxiosData2.status === 204) {
        } else {
          setonOffset({
            ...onOffset,
            check: "",
            ad_id: "",
          });
          fetchApi();
        }
        setLoading({ ...loading, isLoading: false });
      } catch (err) {
        console.log("Error", err);
      }
    } else {
      try {
        const useAxiosData3 = await axiosData.put(`advertisement/${id}`, {
          status: "Open",
        });
        const { data } = useAxiosData3;
        if (useAxiosData3.status === 204) {
        } else {
          setonOffset({
            ...onOffset,
            check: "",
            ad_id: "",
          });
          fetchApi();
        }
        setLoading({ ...loading, isLoading: false });
      } catch (err) {
        console.log("Error", err);
      }
    }
  };
  const selectedApiForExcel = useApi.map((i) => {
    return {
      "Company URL": i.company_url,
      "Advertisement Position":
        i.image_position === "bottomimagefooter"
          ? "Bottom Ad"
          : i.image_position === "popupimage"
          ? "Popup Ad"
          : i.image_position === "bottomimage"
          ? "Flash Ad"
          : i.image_position === "topimage"
          ? "Top Ad"
          : i.image_position === "sideimageright"
          ? "Side Ad Right"
          : imageSize.image_position === "sideimageleft"
          ? "Side Ad Left"
          : i.image_position === "instagrampostsize"
          ? "Instagrampost Ad"
          : i.image_position === "facebookpostsize"
          ? "Facebookpost Ad"
          : i.image_position === "popupfacebookimage"
          ? "Poppup Facebookpost Ad"
          : "Center Ad",
      "Days Validity": i.days_conut,
      "Total Amount": i.total_amount,
      Name: i.ad_person_name,
      "Email ID": i.ad_email_id,
      "Mobile Number": i.ad_mobile_number,
      District: i.Ad_district,
      Pincode: i.pincode,
      "Created Date": i.createdDate,
      Status: i.status,
    };
  });
  const headers = [
    [
      "Company URL",
      "Advertisement Position",
      "Days Validity",
      "Total Amount",
      "Name",
      "Email ID",
      "Mobile Number",
      "District",
      "Pincode",
      "Created Date",
      "Status",
    ],
  ];
  const datas = useApi.map((user) => {
    return [
      user.company_url,
      user.image_position === "bottomimagefooter"
        ? "Bottom Ad"
        : user.image_position === "popupimage"
        ? "Popup Ad"
        : user.image_position === "bottomimage"
        ? "Flash Ad"
        : user.image_position === "topimage"
        ? "Top Ad"
        : user.image_position === "sideimageright"
        ? "Side Ad Right"
        : user.image_position === "sideimageleft"
        ? "Side Ad Left"
        : user.image_position === "instagrampostsize"
        ? "Instagrampost Ad"
        : user.image_position === "facebookpostsize"
        ? "Facebookpost Ad"
        : user.image_position === "popupfacebookimage"
        ? "Poppup Facebookpost Ad"
        : "Center Ad",
      user.days_conut,
      user.total_amount,
      user.ad_person_name,
      user.ad_email_id,
      user.ad_mobile_number,
      user.Ad_district,
      user.pincode,
      user.createdDate,
      user.status,
    ];
  });
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`advertisement/${id}`);
      const data = getOneAxios.data;
      setGetOneAdDetails(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const deleteUser = async (id) => {
    try {
      await axiosData.delete(`advertisement/${id}`);
      setOpenDelete(false);
    } catch (err) {
      console.log("Error", err);
    }
    fetchApi();
  };
  const postDataEdit = (data, _id) => {
    setPostJopEditPopup(true);
    setCompany(data.company_url);
    // formik.setFieldValue("Ad_district", data.Ad_district);
    // formik.setFieldValue("image_position", data.image_position);
    // formik.setFieldValue("days_conut", data.days_conut);
    // formik.setFieldValue("Ad_pincode", data.Ad_pincode);
    //setUploadedImages(data.addUpload)
  };
  if (!loading.isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", mb: 8 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container spacing={3}>
                  {dateenable ? (
                    <Grid item xs={12} md={2.2} sm={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%", sm: "100%" },
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            // minDate={minDate}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  {dateenable ? (
                    <Grid item xs={12} md={2.2} sm={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={1.5} sm={2} alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {dateenable ? (
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(getApi, startDate, endDate);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Refresh" placement="bottom">
                            <Fab
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                setFilterPosition("all");
                                setFilterStatus("");
                                handleClickRefresh(getApi);
                              }}
                            >
                              <RefreshSharpIcon />
                            </Fab>
                          </Tooltip>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={1.5} sm={2} alignItems="center">
                    {adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                      <>
                        <Box display="flex">
                          <Tooltip title="Export As Excel" placement="bottom">
                            <ExportCS
                              selectedApiForExcel={selectedApiForExcel}
                              fileName={"Advertise-History"}
                            />
                          </Tooltip>
                          <Tooltip title="Export As PDF" placement="bottom">
                            <ExportPDF
                              headers={headers}
                              datas={datas}
                              fileName={"Advertise-History"}
                            />
                          </Tooltip>
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={2} md={2.4}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Filter By Position
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterPosition}
                        label="Filter By Position"
                        onChange={(e) => {
                          setFilterPosition(e.target.value);
                          handleSelectPosition(
                            getApi,
                            e.target.value,
                            filterStatus
                          );
                        }}
                      >
                        <MenuItem value={"all"}>All Ad</MenuItem>
                        <MenuItem value={"popupimage"}>Popup Ad</MenuItem>
                        <MenuItem value={"topimage"}>Top Ad</MenuItem>
                        <MenuItem value={"bottomimagefooter"}>
                          Bottom Ad
                        </MenuItem>
                        <MenuItem value={"sideimageright"}>
                          Side Ad Right
                        </MenuItem>
                        <MenuItem value={"sideimageleft"}>
                          Side Ad Left
                        </MenuItem>
                        <MenuItem value={"centerimage"}>Center Ad</MenuItem>
                        <MenuItem value={"bottomimage"}>Flash Ad</MenuItem>
                        <MenuItem value={"facebookpostsize"}>
                          Facebookpost Ad
                        </MenuItem>
                        <MenuItem value={"instagrampostsize"}>
                          Instagrampost Ad
                        </MenuItem>
                        <MenuItem value={"popupfacebookimage"}>
                          Poppup Facebookpost Ad
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2.2}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Filter By Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterStatus}
                        label="Filter By Status"
                        onChange={(e) => {
                          setFilterStatus(e.target.value);
                          handleSelectPosition(
                            getApi,
                            filterPosition,
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value={"Open"}>Open</MenuItem>
                        <MenuItem value={"Close"}>Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell> Ad Image</TableCell>
                    <TableCell> Company URL</TableCell>
                    <TableCell>Ad Position</TableCell>
                    <TableCell> Days Valid</TableCell>
                    <TableCell>Ad Payment Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell> District</TableCell>
                    <TableCell> Pincode</TableCell>
                    <TableCell align="center"> Number of clicks</TableCell>
                    <TableCell> Created Date</TableCell>
                    <TableCell align="center"> Total Amount</TableCell>
                    <TableCell align="center"> Received Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                      <TableCell align="center"> Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell>
                              {" "}
                              {item.addUpload.mimetype === "video/mp4" ? (
                                <video
                                  poster={
                                    item?.videoThumbnailImage
                                      ? `${baseUrl}advertisement/advertisement/image/thumbnail/${item._id}/users`
                                      : defaultimg
                                  }
                                  key={item.id}
                                  height={"50"}
                                  width="50"
                                  style={{ clipPath: "circle(50%)" }}
                                  onClick={() => {
                                    setGetOneAD(item.addUpload.mimetype);
                                    setGetOne(item._id);
                                    setOpenAd(true);
                                  }}
                                >
                                  <source
                                    src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : (
                                <img
                                  src={
                                    item?.addUpload
                                      ? `${baseUrl}advertisement/advertisement/image/${item._id}/users`
                                      : ""
                                  }
                                  onError={(e) => {
                                    e.target.src = defaultimg;
                                  }}
                                  onClick={() => {
                                    setGetOneAdDetails({});
                                    getOneData(item._id);
                                    setGetOneAD(item.addUpload.mimetype);
                                    setGetOne(item._id);
                                    setOpenAd(true);
                                  }}
                                  alt="img"
                                  height={"50"}
                                  width="50"
                                  style={{ clipPath: "circle(50%)" }}
                                />
                              )}{" "}
                            </TableCell>
                            <TableCell>
                              <Typography
                                gutterBottom
                                sx={{
                                  width: "200px",
                                  wordWrap: "break-word",
                                  lineHeight: "2em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  transition: "0.5s",
                                  padding: "0px 5px",
                                  borderRadius: "5px",
                                }}
                                className="nameTitleServiceProvider"
                              >
                                {item.company_url === ""
                                  ? "No URL Provided"
                                  : item.company_url}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {item.image_position === "bottomimagefooter"
                                ? "Bottom Ad"
                                : item.image_position === "popupimage"
                                ? "Popup Ad"
                                : item.image_position === "bottomimage"
                                ? "Flash Ad"
                                : item.image_position === "topimage"
                                ? "Top Ad"
                                : item.image_position === "sideimageright"
                                ? "Side Ad Right"
                                : item.image_position === "sideimageleft"
                                ? "Side Ad Left"
                                : item.image_position === "instagrampostsize"
                                ? "Instagrampost Ad"
                                : item.image_position === "facebookpostsize"
                                ? "Facebookpost Ad"
                                : item.image_position === "popupfacebookimage"
                                ? "Poppup Facebookpost Ad"
                                : "Center Ad"}{" "}
                            </TableCell>

                            <TableCell>{item.days_conut}</TableCell>
                            <TableCell>
                              {item.ad_payment_type === "click_based"
                                ? "Click Based"
                                : "Ad Based"}
                            </TableCell>
                            <TableCell>{item.ad_person_name}</TableCell>
                            <TableCell>{item.ad_email_id}</TableCell>
                            <TableCell>{item.ad_mobile_number}</TableCell>
                            <TableCell>{item.Ad_district}</TableCell>
                            <TableCell>{item.pincode}</TableCell>
                            <TableCell align="center">
                              {item.number_of_clicks}
                            </TableCell>
                            <TableCell>{item.createdDate}</TableCell>
                            <TableCell align="center">
                              {item.total_amount}
                            </TableCell>
                            <TableCell align="center">
                              {item.received_amount}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip
                                title={
                                  item.status === "Open" ? "Open" : "Closed"
                                }
                                placement="right"
                              >
                                <Chip
                                  label={item.status}
                                  color={
                                    item.status === "Open" ? "success" : "error"
                                  }
                                  variant="contained"
                                />
                              </Tooltip>
                            </TableCell>
                            {adminDetails_CEO?.emailId ===
                            process.env
                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                              <TableCell align="center">
                                {adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                                  <Tooltip
                                    title={
                                      item.status === "Open" ? "Off" : "On"
                                    }
                                    placement="top"
                                  >
                                    <Switch
                                      checked={
                                        item.status === "Open" ? true : false
                                      }
                                      onChange={(e) => {
                                        setonOffset({
                                          ...onOffset,
                                          check: e.target.checked,
                                          ad_id: item._id,
                                        });
                                        setConfirmCloseAd(true);
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                                  <Tooltip title="Edit Ad Details" arrow>
                                    <IconButton
                                      aria-label="Example"
                                      sx={{ justifyContent: "end" }}
                                      onClick={() => {
                                        setGetOneAdDetails({});
                                        getOneData(item._id);
                                        console.log(
                                          "item.addUpload.mimetype",
                                          item.addUpload.mimetype
                                        );
                                        setGetOneAD(item.addUpload.mimetype);
                                        getAddDetails(item);
                                        postDataEdit(item, item._id);
                                        setVideoAd("");
                                        setToShowImage("");
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                                  <Tooltip title="Delete" placement="top">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setGetOneAdDetails({});
                                        getOneData(item._id);
                                        setOpenDelete(true);
                                        setVideoAd("");
                                        setToShowImage("");
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <Dialog
          open={openLogo}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          sx={{ zIndex: 9999 }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {pictureLogo.cropperOpen && (
                <Box display="block">
                  <AvatarEditor
                    ref={setEditorRef}
                    image={pictureLogo.img}
                    width={
                      getOneAD === "video/mp4"
                        ? parseInt(stickyDivStyle.width)
                        : 200
                    }
                    height={
                      getOneAD === "video/mp4"
                        ? parseInt(stickyDivStyle.height)
                        : 200
                    }
                    border={20}
                    color={[255, 255, 255, 0.6]}
                    rotate={0}
                    scale={pictureLogo.zoom}
                  />
                  <Slider
                    aria-label="raceSlider"
                    value={pictureLogo.zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSliderLogo}
                  />
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCancelLogo();
                setOpenLogo(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSaveLogo();
                setOpenLogo(false);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postJopEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Update Ad Details"}
          </DialogTitle>
          <DialogContent>
            <Grid container columnSpacing={3}>
              {getOneAD === "video/mp4" ? null : (
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="company_url"
                    label="Company URL"
                    name="company_url"
                    onChange={(e) => {
                      setIsValid("");
                      if (e) {
                        const inputUrl = e.target.value;
                        const urlPattern =
                          /^(ftp|http|https):\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]+.*/;
                        setIsValid(urlPattern.test(inputUrl));
                        setCompany(inputUrl);
                      }
                    }}
                    value={Company}
                  />
                  <div style={{ visibility: isValid ? "hidden" : "visible" }}>
                    <div className="inuputsgapsforbannererror">
                      <p style={{ color: "red" }}>Invalid URL</p>
                    </div>
                  </div>
                </Grid>
              )}
              {/* <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Ad Positions
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="image_position"
                      label="image_position"
                      name="image_position"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.image_position}
                    >
                      {addGetList.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.image_position &&
                  formik.errors.image_position ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.image_position}
                    </div>
                  ) : null}
                </Grid> */}
              {/* <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Number of days
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="days_conut"
                      label="days_conut"
                      name="days_conut"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.days_conut}
                    >
                      {addGetListDays.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.days_conut && formik.errors.days_conut ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.days_conut}
                    </div>
                  ) : null}
                </Grid> */}
              {/* <Grid item xs={12} sx={{ marginTop: 1.5 }}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      disableClearable={
                        formik.values.Ad_district ? false : true
                      }
                      fullWidth
                      name="Ad_district"
                      id="Ad_district"
                      value={formik.values.Ad_district}
                      onChange={(event, value) => {
                        formik.setFieldValue("Ad_district", value);
                        searchobj(value);
                        formik.setFieldValue("Ad_pincode", "");
                      }}
                      onBlur={formik.handleBlur}
                      options={districtData}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="District"
                          //onClick={() => params.inputProps.onInputClick()}
                          InputLabelProps={{
                            style: { marginLeft: "7px" },
                          }}
                        />
                      )}
                      onOpen={(event) => event.preventDefault()}
                      PopperComponent={({ children, ...popperProps }) => (
                        <Popper
                          {...popperProps}
                          placement="bottom-start"
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                          }}
                          PopperProps={{
                            placement: "bottom-start", // Placement set to "bottom-start"
                          }}
                        >
                          <Paper>{children}</Paper>
                        </Popper>
                      )}
                      ListboxProps={{
                        style: { maxHeight: "180px", overflowY: "auto" },
                      }}
                    />
                    {formik.touched.Ad_district && formik.errors.Ad_district ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Ad_district}
                      </div>
                    ) : null}
                  </FormControl>
                </Grid> */}
              {/* <Grid item xs={12}>
                  <Tooltip
                    title={
                      formik.values.Ad_district ? "" : "please Select District"
                    }
                  >
                    <FormControl fullWidth required sx={{ marginY: 2 }}>
                      <Autocomplete
                        disableClearable={
                          formik.values.Ad_pincode ? false : true
                        }
                        disablePortal
                        freeSolo
                        id="Ad_pincode"
                        name="Ad_pincode"
                        disabled={
                          formik.values.Ad_district && !pinLoad ? false : true
                        }
                        options={comdata}
                        value={formik.values.Ad_pincode}
                        onChange={(event, value) => {
                          formik.setFieldValue("Ad_pincode", value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            required
                            // onChange={formik.handleChange}
                            {...params}
                            label="Pincode"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {pinLoad ? (
                                    <CircularProgress
                                      // size={24}
                                      style={{
                                        width: "1.4rem",
                                        height: "1.4rem",
                                        color: "#4991dd",
                                      }}
                                    />
                                  ) : (
                                    <ShareLocationRoundedIcon
                                      sx={{
                                        color: formik.values.Ad_district
                                          ? "#414141"
                                          : "#bdbdbd",
                                      }}
                                    />
                                  )}{" "}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid> */}

              <Grid item xs={12} sx={{ marginTop: 2, alignContent: "center" }}>
                {getOneAD !== undefined ? (
                  getOneAD === "video/mp4" ? (
                    <video
                      poster={
                        toShowImageLogo === ""
                          ? getOneAdDetails?.videoThumbnailImage
                            ? `${baseUrl}advertisement/advertisement/image/thumbnail/${getOneAdDetails._id}/users`
                            : null
                          : toShowImageLogo
                      }
                      key={getOneAdDetails._id}
                      muted
                      controls
                      height={`${parseInt(stickyDivStyle.height)}px`}
                      width={`${parseInt(stickyDivStyle.width)}px`}
                      className="VideoInput_video"
                      src={
                        videoAd === undefined ||
                        videoAd === "" ||
                        videoAd === null
                          ? getOneAdDetails.addUpload
                            ? `${baseUrl}advertisement/advertisement/image/${getOneAdDetails._id}/users`
                            : null
                          : videoAd
                      }
                    />
                  ) : (
                    <img
                      src={
                        toShowImage === ""
                          ? getOneAdDetails?.addUpload
                            ? `${baseUrl}advertisement/advertisement/image/${getOneAdDetails._id}/users`
                            : null
                          : toShowImage
                      }
                      onError={(e) => {
                        e.target.src = defaultimg;
                      }}
                      alt="img"
                      height={`${parseInt(stickyDivStyle.height)}px`}
                      width={`${parseInt(stickyDivStyle.width)}px`}
                    />
                  )
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <div>
                    <Tooltip title="Change ad" arrow>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (getOneAD === "video/mp4") {
                            setVideoAd("");
                            fileInputField2.current.click();
                          } else {
                            fileInputField.current.click();
                          }
                        }}
                      >
                        {getOneAD === "video/mp4"
                          ? "Change Ad Video"
                          : " Change Ad Image"}
                      </Button>
                    </Tooltip>
                    <input
                      className="VideoInput_input"
                      ref={fileInputField2}
                      style={{ visibility: "hidden" }}
                      type="file"
                      onChange={handleFileChangeVideo}
                      accept=".mov,.mp4"
                    />
                    <input
                      ref={fileInputField}
                      style={{ visibility: "hidden" }}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                  {getOneAD !== undefined ? (
                    getOneAD === "video/mp4" ? (
                      <div>
                        <Tooltip title="Upload Your Video Thumbnail" arrow>
                          <Button
                            variant="contained"
                            color="error"
                            disabled={uploadEnable}
                            onClick={() => {
                              fileInputField.current.click();
                              setToShowImageLogo("");
                              setUploadedImagesLogo("");
                            }}
                          >
                            Upload Your Video Thumbnail
                          </Button>
                        </Tooltip>
                        {/* {logoUpload ? (
                  <div style={{ color: "red" }}> {dataMPError}</div>
                ) : null} */}
                        <input
                          ref={fileInputField}
                          style={{ visibility: "hidden" }}
                          type="file"
                          accept="image/*,.jpg,.jpeg,.png"
                          onChange={handleFileChangeLogo}
                        />
                      </div>
                    ) : null
                  ) : null}
                </div>
                {/* <Typography>
            Based on ad position and days validity the ad price is{" "}
            <span>
              {" "}
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "15px",
                  color: "#038fc7",
                }}
              />
              {payAmount}
            </span>
                  </Typography> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPostJopEditPopup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                const value = {
                  company_url: getOneAD === "video/mp4" ? "" : Company,
                };
                updateAd(value);
              }}
              disabled={isValid ? false : true}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog TransitionComponent={Transition} open={videoUpload}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Video Upload
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {`Video size is too large upload < ${process.env.REACT_APP_VIDEO_SIZE_FOR_AD}MB file.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setVideoUpload(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={confirmCloseAd}
          fullWidth
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    Once you Submitted,You can't modify your advertisement
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setConfirmCloseAd(false);
                        setonOffset({
                          ...onOffset,
                          check: "",
                          ad_id: "",
                        });
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",

                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#f2f2f2",
                          backgroundColor: "#339fcd",
                          borderColor: "#f2f2f2",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "lowercase",

                        color: "white",
                        backgroundColor: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        "&:hover": {
                          textTransform: "lowercase",

                          color: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        updateASettings(onOffset.check, onOffset.ad_id);
                        setConfirmCloseAd(false);
                        fetchApi();
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={openAd}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "rgb(16 143 178)",
            },
          }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Advertisement full view
          </DialogTitle>
          <DialogContent
            style={{
              width: "100%",
              padding: "5px",
              alignItems: "center",
            }}
          >
            {" "}
            {getOneAD != undefined ? (
              getOneAD === "video/mp4" ? (
                <video
                  poster={
                    getOneAdDetails.videoThumbnailImage
                      ? `${baseUrl}advertisement/advertisement/image/thumbnail/${getOne}/users`
                      : ""
                  }
                  key={getOneAdDetails._id}
                  muted
                  controls
                  style={{ width: "100%", height: "100%" }}
                >
                  <source
                    src={`${baseUrl}advertisement/advertisement/image/${getOne}/users`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    getOne
                      ? `${baseUrl}advertisement/advertisement/image/${getOne}/users`
                      : ""
                  }
                  onError={(e) => {
                    e.target.src = defaultimg;
                  }}
                  alt="img"
                />
              )
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setOpenAd(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={openDelete}
          fullWidth
          onClose={() => setOpenDelete(false)}
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure want to delete this advertisement?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    You can't undo this action
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenDelete();
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",
                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "lowercase",
                        color: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "white",
                          backgroundColor: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        deleteUser(getOneAdDetails._id);
                      }}
                    >
                      Delete{" "}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          sx={{ zIndex: 9999 }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {picture2.cropperOpen && (
                <Box display="block">
                  <AvatarEditor
                    ref={setEditorRef}
                    image={picture2.img}
                    width={parseInt(stickyDivStyle.width)}
                    height={parseInt(stickyDivStyle.height)}
                    border={20}
                    color={[255, 255, 255, 0.6]}
                    rotate={0}
                    scale={picture2.zoom}
                  />
                  <Slider
                    aria-label="raceSlider"
                    value={picture2.zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSlider}
                  />
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCancel();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default AdminAdvertisementSettings;
