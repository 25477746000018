import React, { useEffect, useState } from "react";

import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination,
  Card,
  CardContent,
  Chip,
  FormControl,
  Paper,
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
const Moment = require("moment");
function InvestorDashboard() {
  const axiosData = useAxios();
  const navigate = useNavigate();

  const [verifierData, setVerifier] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const partnerName = JSON.parse(localStorage.getItem("investor"));

  const [getApi, setGetApi] = useState([]);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const verifiervalue = sessionStorage.getItem("verifierradio");
  const verifierPageNumbe = sessionStorage.getItem("verifier_page_number");
  const verifierPageNumber = parseInt(verifierPageNumbe);
  const setVerifierPageNumber = verifierPageNumber ? verifierPageNumber : 0;

  const [advertisement, setadvertisement] = useState([]);
  const getAdvertisment = async (district, pincode) => {
    const len = pincode.length;
    const ad = await axiosData(`advertisement/districtwise`, {
      district: district,
      Pincode: pincode,
    });
    const { data } = ad;
    if (data) {
      setadvertisement();
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getVerifierEmailId = async () => {
    const verifier = await axiosData.get(`investors/email/${partnerName}`);
    const { data } = verifier;
    setGetApi(data);
    getAdvertisment(data.district_name, data.allocate_pincode_postOffice);

    // getFilterValue(verifiervalue ? verifiervalue : "all", data);
  };
  const getFilterValue = (value, getapi) => {
    if (value === "all") {
      setVerifier([...getapi]);
      setTotalRows([...getapi].length);
      setPage(setVerifierPageNumber);
    } else if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Declined"
    ) {
      const fill = getapi.filter((d) => d.fieldVerification === value);
      setVerifier(fill);
      setTotalRows(fill.length);
      setPage(setVerifierPageNumber);
    } else {
      const ver = getapi.filter((i) => i.VerifierEmail === value);

      setVerifier(ver);
      setTotalRows(ver.length);
      setPage(setVerifierPageNumber);
    }
  };
  const closeFunctionCall = () => {
    setVerifier(getApi);
    setTotalRows(getApi.length);
    setPage(0);
  };
  const searchdatas = (searchdata) => {
    const datas = getApi.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      var valuess =
        item.Name.match(searchtext) || item.email_id.match(searchtext);

      if (valuess != null) {
        return item;
      }
    });
    setVerifier(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    getVerifierEmailId();
    setPage(setVerifierPageNumber);
    sessionStorage.removeItem("verifier_page_number");
  }, []);
  return (
    <>
      <Box>dxfd</Box>
    </>
  );
}

export default InvestorDashboard;
