import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import useAxios from "../../../api/useAxios";
import "./DatabaseAccess.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  TextField,
  Typography,
  Chip,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as Warning } from "../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import company1 from "../../../img/company 1.png";
import company2 from "../../../img/company 2.png";
import company3 from "../../../img/company 3.png";
import rightTick from "../../../img/right tick.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

function BuyDatabaseAccess() {
  const [industryArray, setIndustryArray] = useState([]);
  const [industryClick, setIndustryClick] = useState("IT");
  const [accessAmount, setAccessAmount] = useState([]);
  const [accessCV, setAccessCV] = useState([]);
  const [industryWorkType, setIndustryWorkType] = useState("");
  const [accessAmountFinal, setAccessAmountFinal] = useState(0);
  const [cvAccessLimit, setCvAccessLimit] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [finalIndustrySelect, setFinalIndustrySelect] = useState([]);
  const [industryMobile, setIndustryMobile] = useState("");
  const [loading, setLoading] = useState(true);
  const [typeIndustry, setTypeIndustry] = useState([
    "Fresher",
    "Experienced",
    "Both",
  ]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [endDate3, setEndDate3] = useState(moment().add(3, "months"));
  const [endDate6, setEndDate6] = useState(moment().add(6, "months"));
  const [endDate12, setEndDate12] = useState(moment().add(12, "months"));
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [expTypeDialog, setExpTypeDialog] = useState(false);
  const axiosData = useAxios();
  let [empData, setempData] = useState({});
  const [urls, seturls] = useState();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigate = useNavigate();
  const handleListItemClick = (value) => {
    setFinalIndustrySelect([
      ...finalIndustrySelect,
      `${industryMobile}(${value})`,
    ]);
    setExpTypeDialog(false)
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const handleDelete = (value) => {
    if (value.includes(industryWorkType)) {
      const newValues = [...finalIndustrySelect];
      newValues.splice(newValues.indexOf(`${value}`), 1);
      setFinalIndustrySelect(newValues);
    } else {
      const newValues = [...finalIndustrySelect];
      newValues.splice(newValues.indexOf(`${value}(${industryWorkType})`), 1);
      setFinalIndustrySelect(newValues);
    }
  };
  const handleDelete2 = (value2) => {
    if (value2.includes(industryWorkType)) {
      const newValues2 = [...selectedIndustry];
      newValues2.splice(newValues2.indexOf(value2), 1);
      setSelectedIndustry(newValues2);
    } else {
      const newValues2 = [...selectedIndustry];
      newValues2.splice(newValues2.indexOf(value2), 1);
      setSelectedIndustry(newValues2);
    }
  };
  const IndustryData = async (value) => {
    setLoading(true);
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        if (value === "IT") {
          const fillData = data.filter((f) => f.includes("IT/Computers"));
          setIndustryArray([...fillData]);
          setLoading(false);
        } else if (value === "Non-IT") {
          const fillData2 = data.filter((f) => !f.includes("IT/Computers"));
          setIndustryArray([...fillData2]);
          setLoading(false);
        } else if (value === "Both") {
          setIndustryArray([...data]);
          setLoading(false);
        }
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const selectIndestry = (value) => {
    setSelectedIndustry([]);
    setFinalIndustrySelect([]);
    setIndustryClick(value);
    IndustryData(value);
  };
  const getcvcount = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/cvaccesscount`);
      const data = getOneAxios.data.collections;
      if (data) {
        setAccessCV(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getpaymentforallpincode = async () => {
    const paysettings = await axiosData.get(`General/phonepepaymentdetials`);
    seturls(paysettings.data.collections);
    try {
      const getOneAxios = await axiosData.get(`/General/datadaseaccessamount`);
      const data = getOneAxios.data.collections;
      if (data) {
        setAccessAmount(data);
        axiosData
          .get(`employers/email/${userDetails.emailId}/users`)
          .then(async (data) => {
            empData = data.data;
            if (empData.email_id === userDetails.emailId) {
              setempData({ ...empData });
              setSelectedIndustry([`${empData.industry}`]);
              setFinalIndustrySelect([`${empData.industry}(${"Fresher"})`]);
              setIndustryWorkType("Fresher");
              if (empData.industry.includes("IT/Computers")) {
                setIndustryClick("IT");
                IndustryData("IT");
              } else {
                setIndustryClick("Non-IT");
                IndustryData("Non-IT");
              }
            }
          });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const databaseAccessPost = async (amount) => {
    let res;
    try {
      res = await axiosData.post(`databaseaccess`, {
        company_name: empData.company_name,
        Employer_email_id: empData.email_id,
        Employer_mobile_number: empData.mobile_number,
        Employer_id: empData._id,
        Employer_Name: empData.contact_person,
        pincode: empData.pincode,
        area: empData.pincode,
        state: empData.state,
        country: empData.country,
        data_accessType: industryClick,
        data_accessType_by_industry: finalIndustrySelect.join(","),
        data_accessType_by_work_status: "",
        data_access_expiry_Date: endDate.format("DD-MM-YYYY"),
        payment_amount: amount,
        data_access_data_count: "",
        data_access_from_Date: startDate,
        data_access_to_Date: endDate,
        CV_access_limit: cvAccessLimit,
      });
      if (res.status === 201) {
        navigate(
          ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.DataBaseThanksCard,
          {
            state: userDetails.emailId,
          }
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const subscription = async (amount) => {
    let res;
    try {
      res = await axiosData.post(`databaseaccess`, {
        company_name: empData.company_name,
        Employer_email_id: empData.email_id,
        Employer_mobile_number: empData.mobile_number,
        Employer_id: empData._id,
        Employer_Name: empData.contact_person,
        pincode: empData.pincode,
        area: empData.pincode,
        state: empData.state,
        country: empData.country,
        data_accessType: industryClick,
        data_accessType_by_industry: finalIndustrySelect.join(","),
        data_accessType_by_work_status: "",
        data_access_expiry_Date: endDate.format("DD-MM-YYYY"),
        payment_amount: amount,
        data_access_data_count: "",
        data_access_from_Date: startDate,
        data_access_to_Date: endDate,
        CV_access_limit: cvAccessLimit,
      });
      if (res.status === 201) {
        const response = await axiosData.post(`${ROUTINGDATA.PAYMENT}`, {
          merchantTransactionId: "MUID" + Date.now(),
          merchantUserId: "T" + Date.now(),
          amount: res.data.payment_amount,
          data: {
            district: res.data.Ad_district,
            mobile: res.data.Employer_mobile_number,
            email: res.data.Employer_email_id,
            pincode: res.data.pincode,
            area: res.data.area,
            no_posting: 1,
            updateQuery: {
              data_access_status: "Open", //*
            },
            listingQuery: { _id: res.data._id },
            collection: "databaseaccessemployers", //*
            emailtemplate: "paymentmessage",
            successnavigate: "view-resumes",
            failednavigation: "buy-Resume-access", //*
            link: "", //*
            admintemplate: "admintemplate",
          },
   
          emailvarialbles: [
            {
              key: "name",
              value: res.data.company_name,
            },
          ],
          send: true,
          usertype: "Employer",
          paymentcategory: "DataBaseAccess",
          callbackUrl:  urls.callbackurl ? urls.callbackurl :`https://velaivendum.com/p-services/payment/phonepe/callback`,
          redriectUrl: `https://www.velaivendum.com/#/main/DataBaseThanksCard`,
        });
        window.location.replace(response.data);

        // navigate(
        //   ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.DataBaseThanksCard,
        //   {
        //     state: userDetails.emailId,
        //   }
        // );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getpaymentforallpincode();
      getcvcount();
    }
  }, []);
  if (loading === false) {
    return (
      <div>
        <Tooltip
          arrow
          onClick={() => {
            navigate(-1);
          }}
          className="GoBackTooltip"
          sx={{ backgroundColor: "#339fcd" }}
          title="Go Back"
        >
          <IconButton
            className="GoBackTooltip"
            sx={{ backgroundColor: "#339fcd", mt: 1, mb: -1 }}
          >
            <ArrowBackIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
        <h1>Your Plan, Your Choice</h1>
        <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
          After purchasing any plan, you can receive updated data every day till
          the plan expires.
        </p>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "Poppins-Medium",
          }}
        >
          Core
        </p>
        <Box sx={{ mt: -2 }} display={"flex"} gap={"10px"}>
          <Button
            sx={
              industryClick === "IT"
                ? {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#108FB2",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "#086883",
                    },
                  }
                : {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#fff",
                    color: "black",
                    border: "1px solid #108FB2",
                    ":hover": {
                      border: "1px solid #0b84a5",
                    },
                  }
            }
            onClick={() => {
              selectIndestry("IT");
            }}
          >
            IT
          </Button>
          <Button
            sx={
              industryClick === "Non-IT"
                ? {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#108FB2",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "#086883",
                    },
                  }
                : {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#fff",
                    color: "black",
                    border: "1px solid #108FB2",
                    ":hover": {
                      border: "1px solid #0b84a5",
                    },
                  }
            }
            onClick={() => {
              selectIndestry("Non-IT");
            }}
          >
            Non IT
          </Button>
          <Button
            sx={
              industryClick === "Both"
                ? {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#108FB2",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "#086883",
                    },
                  }
                : {
                    textTransform: "capitalize",
                    boxShadow: 0,
                    fontFamily: "Poppins-Medium",
                    padding: "5px 20px",
                    width: "100px",
                    backgroundColor: "#fff",
                    color: "black",
                    border: "1px solid #108FB2",
                    ":hover": {
                      border: "1px solid #0b84a5",
                    },
                  }
            }
            onClick={() => {
              selectIndestry("Both");
            }}
          >
            Both
          </Button>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              md: `${"block"}`,
            },
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "Poppins-Medium",
            }}
          >
            Industry
          </p>
          <div style={{ marginTop: "-12px" }}>
            {industryArray && (
              <Autocomplete
                sx={{
                  marginTop: "8px",
                  backgroundColor: "white",
                  width: "500px",
                }}
                multiple
                freeSolo
                disableClearable
                filterOptions={filterOptions}
                size="large"
                id="industry"
                name="industry"
                options={industryArray}
                onChange={(event, value) => {
                  if (event.target.checked === true) {
                    if (
                      value === undefined ||
                      value === null ||
                      value?.length === 0
                    ) {
                      setSelectedIndustry([]);
                      setFinalIndustrySelect([]);
                    } else if (event.target.checked === undefined) {
                      const removeValue2 = industryArray.filter(
                        (d) => !value.includes(d)
                      );
                      const removeValue = removeValue2[0];
                      handleDelete(removeValue);
                      handleDelete2(removeValue);
                    } else if (event.target.checked === true) {
                      setSelectedIndustry(value);
                      setFinalIndustrySelect([
                        ...finalIndustrySelect,
                        `${value}(${industryWorkType})`,
                      ]);
                    }
                  }
                }}
                value={selectedIndustry}
                renderTags={(value, getTagProps) =>
                  finalIndustrySelect.map((option, index) => (
                    <Chip
                      key={option}
                      label={`${option}`}
                      onClick={() => {
                        handleDelete(option);
                        handleDelete2(option);
                      }}
                      onDelete={() => {
                        handleDelete(option);
                        handleDelete2(option);
                      }}
                    />
                  ))
                }
                renderOption={(props, industryArray, { selected }) => (
                  <div
                    style={{
                      marginTop: "8px",
                      marginLeft: "8px",
                      backgroundColor: "white",
                      height: "50px",
                      width: "300px",
                    }}
                  >
                    <li
                      {...props}
                      class={
                        selectedIndustry.includes(industryArray)
                          ? ""
                          : "dropdown-submenu"
                      }
                      disabled
                    >
                      {selectedIndustry.includes(industryArray) ? (
                        <CheckCircleIcon
                          sx={{
                            padding: "0px",
                            marginRight: "10px",
                            color: "green",
                          }}
                          fontSize="small"
                        />
                      ) : null}
                      {industryArray}
                      {selectedIndustry.includes(industryArray) ? null : (
                        <ul
                          class={
                            selectedIndustry.includes(industryArray)
                              ? ""
                              : "dropdown"
                          }
                        >
                          {typeIndustry.map((s, index) => (
                            <MenuItem key={index}>
                              <Checkbox
                                sx={{ padding: "0px", marginRight: "10px" }}
                                checked={
                                  selected === true
                                    ? finalIndustrySelect.includes(
                                        `${industryArray}(${s})`
                                      )
                                    : null
                                }
                                value={"checked"}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFinalIndustrySelect([
                                      ...finalIndustrySelect,
                                      `${industryArray}(${s})`,
                                    ]);
                                    setIndustryWorkType(s);
                                  } else {
                                  }
                                }}
                              />
                              {s}
                            </MenuItem>
                          ))}
                        </ul>
                      )}
                    </li>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder={"Preferred Industry"}
                    id="industry"
                    name="industry"
                  />
                )}
                ListboxProps={{
                  style: { maxHeight: "180px", overflowY: "auto" },
                }}
              />
            )}
          </div>
          {finalIndustrySelect === undefined ||
          finalIndustrySelect === null ||
          finalIndustrySelect?.length === 0 ? (
            <div style={{ color: "red", fontSize: "15px" }}>
              Select industry
            </div>
          ) : null}
        </Box>
        <Box
          sx={{
            display: {
              xs: `${"block"}`,
              md: "none",
            },
          }}
        >
          {" "}
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "Poppins-Medium",
            }}
          >
            Industry
          </p>
          <div style={{ marginTop: "-12px" }}>
            {industryArray && (
              <Autocomplete
              className="dataBasePurchaseAutocomplete"
                multiple
                freeSolo
                disableClearable
                filterOptions={filterOptions}
                size="large"
                id="industry"
                name="industry"
                options={industryArray}
                onChange={(event, value) => {
                    if (
                      value === undefined ||
                      value === null ||
                      value?.length === 0
                    ) {
                      setSelectedIndustry([]);
                    } else  {
                      setSelectedIndustry(value);
                      setIndustryMobile(event.target.innerText)
                      setIndustryWorkType("")
                      setExpTypeDialog(true)
                    }
                }}
                value={selectedIndustry}
                renderTags={(value, getTagProps) =>
                  finalIndustrySelect.map((option, index) => (
                    <Chip
                      key={option}
                      label={`${option}`}
                      onClick={() => {
                        handleDelete(option);
                        handleDelete2(option);
                      }}
                      onDelete={() => {
                        handleDelete(option);
                        handleDelete2(option);
                      }}
                    />
                  ))
                }
                renderOption={(props, industryArray, { selected }) => (
                  <div
                    style={{
                      marginTop: "8px",
                      marginLeft: "8px",
                      backgroundColor: "white",
                      height: "50px",
                      width: "300px",
                    }}
                  >
                    <li
                      {...props}
                      class={
                        selectedIndustry.includes(industryArray)
                          ? ""
                          : "dropdown-submenu"
                      }
                      disabled
                    >
                      {selectedIndustry.includes(industryArray) ? (
                        <CheckCircleIcon
                          sx={{
                            padding: "0px",
                            marginRight: "10px",
                            color: "green",
                          }}
                          fontSize="small"
                        />
                      ) : null}
                      {industryArray}   
                    </li>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder={"Preferred Industry"}
                    id="industry"
                    name="industry"
                  />
                )}
                ListboxProps={{
                  style: { maxHeight: "180px", overflowY: "auto" },
                }}
              />
            )}
          </div>
          {finalIndustrySelect === undefined ||
          finalIndustrySelect === null ||
          finalIndustrySelect?.length === 0 ? (
            <div style={{ color: "red", fontSize: "15px" }}>
              Select industry
            </div>
          ) : null}
        </Box>
        <div
          className="dataBasePurchaseTopDiv"
        >
          <Box
           className="dataBasePurchaseCard"
          >
            <div
              style={{
                display: "flex",
                fontFamily: "Poppins-Medium",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", fontFamily: "Poppins-Medium" }}>
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    fontFamily: "Poppins-Medium",
                    marginRight: "10px",
                  }}
                >
                  3 Month
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#6F6F6F",
                    marginTop: "40px",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  Plan
                </p>
              </div>
              <img
                src={company1}
                alt="company1"
                style={{ marginTop: "18px", height: "40px", width: "40px" }}
              />
            </div>
            <p
              style={{
                fontFamily: "Poppins-Medium",
                color: "rgb(16, 178, 32)",
                marginTop: "-34px",
              }}
            >
              {accessCV["threemonths"]} CV Access
            </p>
            <div
             className="dataBasePurchaseTime"
            >
              <p className="dataBasePurchaseTime1">
                From: {startDate.format("DD-MM-YYYY")}
              </p>
              <p className="dataBasePurchaseTime2">
                End: {endDate3.format("DD-MM-YYYY")}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Features
              </p>
              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["threemonths"]} Seekers Shortlist
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["threemonths"]} Resume Download
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["threemonths"]} Seeker Profile View
                </p>
              </div>
            </div>
            <del style={{ color: "#6F6F6F", fontSize: "20px" }}>Rs.15000</del>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-18px",
              }}
            >
              <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                Rs.{accessAmount["threemonths"]}
              </p>
              <p style={{ fontSize: "22px", color: "#10B220" }}>80% Off</p>
            </div>
            <Button
              disabled={
                finalIndustrySelect === undefined ||
                finalIndustrySelect === null ||
                finalIndustrySelect?.length === 0
                  ? true
                  : false
              }
              sx={{
                textTransform: "capitalize",
                boxShadow: 0,
                fontFamily: "Poppins-Medium",
                padding: "5px 20px",
                width: "100%",
                backgroundColor: "#108FB2",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#086883",
                },
              }}
              onClick={() => {
                setAccessAmountFinal(accessAmount["threemonths"]);
                setConfirmDialog(true);
                setEndDate(endDate3);
                setCvAccessLimit(300);
              }}
            >
              Subscribe
            </Button>
          </Box>
          <Box className="dataBasePurchaseCard">
            <div
              style={{
                display: "flex",
                fontFamily: "Poppins-Medium",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", fontFamily: "Poppins-Medium" }}>
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    fontFamily: "Poppins-Medium",
                    marginRight: "10px",
                  }}
                >
                  6 Month
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#6F6F6F",
                    marginTop: "40px",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  Plan
                </p>
              </div>
              <img
                src={company2}
                alt="company2"
                style={{ marginTop: "18px", height: "40px", width: "40px" }}
              />
            </div>
            <p
              style={{
                fontFamily: "Poppins-Medium",
                color: "rgb(16, 178, 32)",
                marginTop: "-34px",
              }}
            >
              {accessCV["sixmonths"]} CV Access
            </p>
            <div
             className="dataBasePurchaseTime"
            >
              <p  className="dataBasePurchaseTime1">
                From: {startDate.format("DD-MM-YYYY")}
              </p>
              <p className="dataBasePurchaseTime2">
                End: {endDate6.format("DD-MM-YYYY")}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Features
              </p>
              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["sixmonths"]} Seekers Shortlist
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["sixmonths"]} Resume Download
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["sixmonths"]} Seeker Profile View
                </p>
              </div>
            </div>
            <del style={{ color: "#6F6F6F", fontSize: "20px" }}>Rs.30000</del>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-18px",
              }}
            >
              <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                Rs.{accessAmount["sixmonths"]}
              </p>
              <p style={{ fontSize: "22px", color: "#10B220" }}>80% Off</p>
            </div>
            <Button
              disabled={
                finalIndustrySelect === undefined ||
                finalIndustrySelect === null ||
                finalIndustrySelect?.length === 0
                  ? true
                  : false
              }
              sx={{
                textTransform: "capitalize",
                boxShadow: 0,
                fontFamily: "Poppins-Medium",
                padding: "5px 20px",
                width: "100%",
                backgroundColor: "#108FB2",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#086883", // Change to the desired hover color
                },
              }}
              onClick={() => {
                setConfirmDialog(true);
                setAccessAmountFinal(accessAmount["sixmonths"]);
                setEndDate(endDate6);
                setCvAccessLimit(500);
              }}
            >
              Subscribe
            </Button>
          </Box>
          <Box className="dataBasePurchaseCard">
            <div
              style={{
                display: "flex",
                fontFamily: "Poppins-Medium",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", fontFamily: "Poppins-Medium" }}>
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    fontFamily: "Poppins-Medium",
                    marginRight: "10px",
                  }}
                >
                  12 Month
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#6F6F6F",
                    marginTop: "40px",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  Plan
                </p>
              </div>
              <img
                src={company3}
                alt="company3"
                style={{ marginTop: "18px", height: "40px", width: "40px" }}
              />
            </div>
            <p
              style={{
                fontFamily: "Poppins-Medium",
                color: "rgb(16, 178, 32)",
                marginTop: "-34px",
              }}
            >
              {accessCV["twelvemonths"]} CV Access
            </p>
            <div
              className="dataBasePurchaseTime"
            >
              <p className="dataBasePurchaseTime1">
                From: {startDate.format("DD-MM-YYYY")}
              </p>
              <p className="dataBasePurchaseTime2">
                End: {endDate12.format("DD-MM-YYYY")}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Features
              </p>
              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["twelvemonths"]} Seekers Shortlist
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["twelvemonths"]} Resume Download
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <img
                  src={rightTick}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    marginTop: "15px",
                  }}
                />
                <p style={{ fontFamily: "Poppins-Medium", color: "#444" }}>
                  {accessCV["twelvemonths"]} Seeker Profile View
                </p>
              </div>
            </div>
            <del style={{ color: "#6F6F6F", fontSize: "20px" }}>Rs.60000</del>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-18px",
              }}
            >
              <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                Rs.{accessAmount["twelvemonths"]}
              </p>
              <p style={{ fontSize: "22px", color: "#10B220" }}>80% Off</p>
            </div>
            <Button
              disabled={
                finalIndustrySelect === undefined ||
                finalIndustrySelect === null ||
                finalIndustrySelect?.length === 0
                  ? true
                  : false
              }
              sx={{
                textTransform: "capitalize",
                boxShadow: 0,
                fontFamily: "Poppins-Medium",
                padding: "5px 20px",
                width: "100%",
                backgroundColor: "#108FB2",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#086883", // Change to the desired hover color
                },
              }}
              onClick={() => {
                setAccessAmountFinal(accessAmount["twelvemonths"]);
                setConfirmDialog(true);
                setEndDate(endDate12);
                setCvAccessLimit(1000);
              }}
            >
              Subscribe
            </Button>
          </Box>
          <Dialog open={expTypeDialog}>
      <DialogTitle>Select Work Status Type</DialogTitle>
      <div style={{padding:"5px",display:"flex",justifyContent:"center" }}>
      <RadioGroup
      sx={{ display:"flex",justifyContent:"center" }}
    aria-labelledby="demo-radio-buttons-group-label"
    value={industryWorkType}
    onChange={(e) => handleListItemClick(e.target.value)}
    name="radio-buttons-group"
  >
    {typeIndustry.map((type) => (
    <FormControlLabel sx={{mt: 1}} key={type} value={type}control={<Radio />} label={type} />))}
  </RadioGroup>
  </div>
    </Dialog>
          <Dialog
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "20px",
              },
              zIndex:"99999"
            }}
            open={confirmDialog}
            fullWidth
          >
            <Box>
              <Box>
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "space-around",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ paddingTop: "10px" }}>
                      <Warning height={100} width={100} />
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        paddingTop: "10px",
                        fontFamily: "Poppins-SemiBold",
                      }}
                    >
                      Are you sure?
                    </Typography>
                    <Typography
                      sx={{
                        paddingTop: "10px",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Once submitted,You can't modify your access details
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium",

                        mt: 2,
                      }}
                    >
                      Selected Data:
                    </Typography>
                    <Card>
                      <TableContainer
                        className="dataBasePurchaseTable"
                        style={{
                          maxHeight: "200px",
                          overflow: "auto",
                        }}
                      >
                        <Table>
                          <TableHead
                            sx={{
                              backgroundColor: "#339fcd",
                              textAlign: "center",
                            }}
                          >
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", color: "white" }}
                              >
                                Industry
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", color: "white" }}
                              >
                                JobSeeker Category
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              backgroundColor: "white",
                              textAlign: "center",
                            }}
                          >
                            {finalIndustrySelect === undefined ||
                            finalIndustrySelect === null ||
                            finalIndustrySelect?.length === 0 ? (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>No Data</TableCell>
                              </TableRow>
                            ) : (
                              finalIndustrySelect?.map((item, index) => {
                                const [category, experience] = item.split("(");
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{category}</TableCell>
                                    <TableCell>
                                      {experience.replace(")", "")}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                    <Box
                      sx={{
                        paddingTop: "20px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setConfirmDialog(false);
                        }}
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          borderColor: "#ed1067",
                          color: "#ed1067",
                          fontFamily: "Poppins-Medium",
                          transition: "all 0.3s",
                          borderRadius: "10px",
                          "&:hover": {
                            textTransform: "capitalize",
                            color: "#ed1067",
                            borderColor: "#ed1067",
                            fontFamily: "Poppins-Medium",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          color: " white",
                          backgroundColor: "#339fcd",
                          borderColor: "#339fcd",
                          fontFamily: "Poppins-Medium",
                          transition: "all 0.3s",
                          borderRadius: "10px",
                          "&:hover": {
                            textTransform: "capitalize",
                            color: "#339fcd",
                            borderColor: "#339fcd",
                            fontFamily: "Poppins-Medium",
                          },
                        }}
                        onClick={() => {
                          setConfirmDialog(false);
                          subscription(accessAmountFinal);
                          // databaseAccessPost(accessAmountFinal);
                          //setOpensubscribe(true);
                        }}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>

        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default BuyDatabaseAccess;
