import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  Typography,
  TablePagination,
  Paper,
  Button,
  Box,
} from "@mui/material";
import Breadcrumb from "../../BreadCrumb";
import useAxios from "../../../../api/useAxios";

import { useLocation } from "react-router-dom";

const InvestorCreatePincodeFranchiser = () => {
  const investor_email = JSON.parse(localStorage.getItem("investor"));
  const [useApi, setUseApi] = useState([]);
  const axiosData = useAxios();
  const fetch_api = async () => {
    try {
      const useAxiosData = await axiosData.get(
        `investors/email/${investor_email}`
      );
      const { data } = useAxiosData;
      setUseApi(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetch_api();
  }, [investor_email]);

  return (
    <div>
      <div style={{ margin: "20px 0px" }}>
        <Breadcrumb />
      </div>
      <div style={{ margin: "20px 0px" }}>
        <Card
          sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <h3 className="seeker-text">Create Pincode Franchiser</h3>
          </Box>
        </Card>
      </div>
      <div style={{ margin: "20px 0px" }}>
        <Card sx={{ padding: "20px", mb: 8 }}>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow className="table-row">
                  {/* <TableCell> Profile</TableCell> */}
                  <TableCell>Pincode</TableCell>
                  <TableCell> Name</TableCell>
                  <TableCell align="center"> Email</TableCell>

                  <TableCell align="center"> Mobile Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {useApi.length > 0 ? (
                  <>
                    {useApi?.pincode_franchiser.map((item) => (
                      <TableRow
                        // key={item._id}
                        hover
                        className="tablecell"
                      >
                        <TableCell>{item.pincode}</TableCell>

                        <TableCell>
                          {`(${item.jobamount})${item.jobcount}`}
                        </TableCell>
                        <TableCell align="center">
                          {`(${item.adamount})${item.adcount}`}
                        </TableCell>
                        <TableCell align="center">{`(${item.localseriviceamount})${item.localservicecount}`}</TableCell>
                        <TableCell align="center">
                          {item.pincode_franchiser ? (
                            <Typography>
                              {item.pincode_franchiser.pincode_franchiser_email}
                            </Typography>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{ textTransform: "capitalize" }}
                              onClick={() => {
                                //handleChange(item.pincode);
                              }}
                            >
                              Assign Pincode
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      {/* <TableCell></TableCell> */}

                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell>
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      {/* <TableCell></TableCell> */}

                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            // count={totalRows}
            // rowsPerPage={rowsPerPage}
            // page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </div>
  );
};

export default InvestorCreatePincodeFranchiser;
