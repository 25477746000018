import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Divider, Skeleton } from "@mui/material";
import useAxios from "../../../../api/useAxios";
import StatMPcards from "../../../admin-panel/admin-components/StatCards/StatMPcards";

import StatMLAcards from "../../../admin-panel/admin-components/StatCards/StatMLAcards";
import SkeletonSeekCard from "../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import SkeletonMlaCard from "../../../admin-panel/admin-components/StatCards/SkeletonMlaCard";
import StatCardForNeedSupport from "../../../admin-panel/admin-components/StatCards/StatCardForNeedSupport";

function MPDashboard() {
  const axiosData = useAxios();
  const [mpData, setMpData] = useState({});
  const [freshers, setfreshers] = useState([]);
  const [registered, setRegistered] = useState([]);
  const [fulldata, setfulldata] = useState([]);
  const [shortlisted, setShortlisted] = useState(0);
  const [supported, setSupported] = useState([]);
  const [mlaConstituencyList, setMlaConstituencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const mp = JSON.parse(localStorage.getItem("mp"));

  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mpconstituencyseeker", {
        email_id: mp,
      });
      const { data } = MpData;
      setMpData(data[0]);
      const mladata = data[0].mla_constituency_list;

      setMlaConstituencyList(data[0].mla_constituency_list);
      const needsupport = mladata.flatMap((i) => {
        return i.needSupport;
      });
      setfulldata(needsupport.length);
      const pending = needsupport.filter((i) => {
        return i.shortList === "";
      });

      setfreshers(pending.length);

      const shortListed = mladata.flatMap((i) => {
        return i.shortListed;
      });

      setShortlisted(shortListed.length);

      const supported = needsupport.filter((i) => {
        return i.supported === "MP";
      });
      setSupported(supported.length);
      const totalRegistered = mladata.flatMap((i) => {
        return i.seekers;
      });

      setRegistered(totalRegistered.length);
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getMpEmailId();
  }, []);
  if (isLoading === false) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
            >
              {mpData.mp_constituency}'s Dashboard
            </Typography>
          </Card>

          <StatMPcards
            registered={registered}
            freshers={freshers}
            need={fulldata}
            shortlisted={shortlisted}
            supported={supported}
          />
          {mlaConstituencyList.map((item, index) => {
            const needsupport = item.needSupport;

            const totalFreshers = item.totalFresher;

            const totalRegistered = item.seekers;

            return (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Card sx={{ borderRadius: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <Divider textAlign="left">
                        <span className="seeker-text">
                          {item.mla_constituency}
                        </span>
                      </Divider>
                    </Box>
                    <Divider />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Divider />
                    <Box sx={{ width: "100%" }}>
                      <Divider textAlign="left" sx={{ width: "100%" }}>
                        <p>
                          {" "}
                          <span className="seeker-text">Need Support</span>
                        </p>
                      </Divider>
                    </Box>

                    <StatCardForNeedSupport state={item} ind={index} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Divider textAlign="left" sx={{ width: "100%" }}>
                        <span className="seeker-text">Types Of Support</span>
                      </Divider>
                    </Box>

                    <StatMLAcards state={item} ind={index} />
                  </Box>
                </Card>
              </Box>
            );
          })}
        </Box>
      </>
    );
  } else {
    const mlacn = [
      { keyD: "mla0" },
      { keyD: "mla1" },
      { keyD: "mla2" },
      { keyD: "mla3" },
      { keyD: "mla4" },
      { keyD: "mla5" },
    ];
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>

          {mlacn.map((i) => (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Card sx={{ borderRadius: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Skeleton
                      variant="text"
                      width={230}
                      height={40}
                      sx={{ borderRadius: "15px" }}
                    />
                  </Box>
                  <Divider />
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      variant="text"
                      width={230}
                      height={40}
                      sx={{ borderRadius: "15px" }}
                    />
                  </Box>
                  <SkeletonSeekCard />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      variant="text"
                      width={230}
                      height={40}
                      sx={{ borderRadius: "15px" }}
                    />
                  </Box>

                  <SkeletonMlaCard />
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      </>
    );
  }
}

export default MPDashboard;
