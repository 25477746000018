import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import { Tooltip, IconButton } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import moment from "moment";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import employerProfile from "../../../../img/employerProfile/employerUpdate-logo.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const renderParagraphs = (description) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(description, "text/html");
  const paragraphs = htmlDoc.querySelectorAll("p");
  const listItems = htmlDoc.querySelectorAll("li");

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    if (paragraph.innerHTML.trim() === "<br>") {
      paragraph.parentNode.removeChild(paragraph);
    }
  }

  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i];
    if (listItem.innerHTML.trim() === "<br>") {
      listItem.parentNode.removeChild(listItem);
    }
  }

  const firstElement = [...paragraphs, ...listItems].find(
    (element) => element.innerText.trim() !== ""
  );

  if (listItems) {
    listItems.forEach((item) => {
      item.classList.add("selected-li");
    });
  }

  return firstElement ? firstElement.outerHTML : null;
};

const getTodayDate = moment().format("DD-MM-YYYY");

function AppliedJobs() {
  let location = useLocation();
  const axiosData = useAxios();
  const [mobilesearch, setmobilesearch] = useState(false);
  const navigate = useNavigate();
  const [list, setlist] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [seekerProfileDetails, setSeekerProfileDetails] = useState();
  const [Applyed, setApplyed] = useState([]);
  const [jobApplied, setJobApplied] = useState(false);
  const [description, setDescription] = useState([]);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const [jobDescription, setJobDescription] = useState([]);
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [jobsApplied, setJobsApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const baseUrl = axiosData.defaults.baseURL;
  const getSeekerDetail = async () => {
    try {
      const response = await axiosData.get(`seekers/${seekerDetails.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setSeekerProfileDetails(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const searchResult1 = async (result) => {
    try {
      const response = await axiosData.post(`jobs/search`, result, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setJobDetails([...response.data]);
        setDescription([...response.data.map((job) => job.description)]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  if (location.state.email_id === "") {
    searchResult1(location.state.search);
  }
  const jobs = async (applied) => {
    applied.map(async (element, index) => {
      try {
        const response = await axiosData.get(
          `jobs/getOne/${element.job_id}`,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data) {
          jobDetails.push(response.data);
          setJobDetails([...jobDetails]);
          jobDescription.push(response.data);
          setDescription([...jobDescription]);

          setLoading(false);
          if (jobDetails[0]) {
            setlist(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const ApplyedJob = async () => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyseekerid/${seekerDetails.id}`,
        { industry: location.state.industry },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setApplyed(response.data);

        jobs(response.data.reverse());
      }
      if (response.status === 204) {
        setLoading(false);
        setJobsApplied(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (seekerDetails === null || seekerDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      ApplyedJob();
    }
  }, []);

  const moreDetails = (description) => {
    setOpenMoreDetails(true);
    setJobDescription(
      jobDetails.find((item) => item.job_description === description)
    );
  };

  const Apply = async (job) => {
    setJobApplied(true);
    await axiosData.post(
      `jobapply/`,
      {
        job_id: job._id,
        employer_id: job.user_id,
        seeker_id: seekerDetails.id,
        seeker_name: seekerProfileDetails.full_name,
        seeker_email: seekerProfileDetails.email_id,
        seeker_mobile_number: seekerProfileDetails.mobile_number,
        job_title: job.job_title,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    ApplyedJob();
    jobs();
  };

  const match = (Id) => {
    let value = true;
    Applyed.map((values, index) => {
      if (Id === values.job_id) {
        value = false;
      }
    });

    return value;
  };

  const [searchQuery, setSearchQuery] = useState([]);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    key_skills: null,
    job_location: null,
    industry: null,
  });

  const handleSuggestionslocation = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length >= 3) {
      try {
        const response = await axiosData.get(
          `/jobs/suggestionlocation/${query}`
        );
        setSuggestionArray(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArray([...response.data]);
        } else {
          setSuggestionArray([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleSuggestionsSkills = async (event) => {
    setSuggestionArray([]);
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length >= 3) {
      try {
        const response = await axiosData.get(
          `/jobs/suggestionKeyskills/${query}`
        );
        setSuggestionArray(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArray([...response.data]);
        } else {
          setSuggestionArray([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);

    setSuggestionArray([]);
  };
  const searchResult = async (result) => {
    if (
      selectedOptions.industry === null &&
      selectedOptions.key_skills === null &&
      selectedOptions.job_location === null
    ) {
      setSearchPopUp(true);
    } else {
      try {
        const response = await axiosData.post(`jobs/search`, selectedOptions, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          setJobDetails([...response.data]);
          setDescription([...response.data.map((job) => job.description)]);

          setmobilesearch(false);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearch = () => {
    searchResult(selectedOptions);
  };

  const appliedJob = async () => {
    const response = await axiosData.get(
      `jobapply/getbyseekerid/${seekerDetails.id}`
    );
  };

  if (loading === false) {
    return (
      <div>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography sx={{ fontSize: "28px", fontWeight: 600 }}>
              Applied Jobs
            </Typography>
          </div>
          <div>
            <Tooltip
              arrow
              onClick={() => {
                navigate("/main/seekerProfile", {
                  state: location.state.email_id,
                });
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{ backgroundColor: "#339fcd" }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Dialog
          TransitionComponent={Transition}
          open={jobApplied}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "rgb(16 143 178)",
              width: "300px",
            },
          }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Apply
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
              Your job applied successfully!.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setJobApplied(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails}
          jobDescription={jobDescription}
          sx={{ "& .MuiPaper-root": { width: "500px" },zIndex:"99999" }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription.job_description ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html: jobDescription.job_description,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                No description for this job
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={searchPopUp}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Search
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Atleast one field is required for search !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setSearchPopUp(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={jobsApplied}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Jobs applied
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              No jobs applied !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setJobsApplied(false);
                navigate("/main/seekerProfile", {
                  state: location.state.email_id,
                });
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <div className="jobFullContend">
          <div className="recommendedJobCard">
            {jobDetails.map((jobs, index) => (
              <Card className="recommendedJob-InnerCard" key={jobs._id}>
                <div className="ApplyCard">
                  <CardContent>
                    {jobs.job_status === "InActive" ? (
                      <div className="AppliedDiv">
                        <Chip
                          className="AppliedButton"
                          sx={{ ml: 1, mr: -3, mt: -3.5 }}
                          label="Inactive"
                          size="small"
                          color="error"
                          variant="outlined"
                        />
                      </div>
                    ) : (
                      <div className="AppliedDiv">
                        {!match(jobs._id) && (
                          <Chip
                            className="AppliedButton"
                            sx={{ ml: 1, mr: -3, mt: -3.5 }}
                            label="Active"
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </div>
                    )}
                    <Typography className="nameTitleServiceProvider" sx={{ fontWeight: 600, fontSize: "26px", wordWrap: "break-word",
                                  lineHeight: "2em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  transition: "0.5s",
                                  backgroundColor: "#22668a",
                                  color: "white",
                                  padding: "0px 5px",
                                  textTransform: "uppercase",
                                  borderRadius: "5px",
                                  marginBottom:"10px" }}>
                      {jobs.job_title}
                    </Typography>

                    <Grid container>
                      <Grid lg={9} md={9} sm={9} xs={9}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "20px" }}
                        >
                          {jobs.company_name}
                        </Typography>
                        <Grid lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.4,
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobs.industry}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className="CompanySideLogo"
                        lg={3}
                        md={3}
                        sm={2}
                        xs={2}
                      >
                        <img
                          alt=""
                          id="employerProfile"
                          src={jobs.employer_email_id?`${baseUrl}employers/logo/email/${jobs.employer_email_id}/users`:null}
                          onError={(e)=>{
                            e.target.src=employerProfile
                         }}
                          className="jobImgLogo"
                        />
                      </Grid>
                    </Grid>

                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Skills</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                           {jobs.key_skills.trim().replace(/,$/, '.') || null}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Experience</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          { jobs.experience_type === "Freshers Only" ? "Freshers Only" : `${jobs.experience_min}${"-"}${jobs.experience_max}`}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Salary</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", wordWrap: "break-word",
                          lineHeight: "2em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          transition: "0.5s",
                          backgroundColor: "#22668a",
                          color: "white",
                          padding: "0px 5px",
                          textTransform: "uppercase",
                          borderRadius: "5px"}}
                          color="text.secondary"
                        >
                          { jobs.salary_range_max  ? `${jobs.salary_range_min}${"-"}${jobs.salary_range_max}${jobs.salary_type === "Month"
                                  ? " Per Month"
                                  : jobs.salary_type === "Year"
                                  ? " LPA"
                                  : null}` : "Not Disclosed"}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Location</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobs.job_location}
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <DescriptionIcon
                        fontSize="small"
                        sx={{ color: "rgb(145 145 145)" }}
                      />
                      {jobs.job_description ? (
                        <Typography
                          className="JobDescriptionOverflow"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                            mt: -1.8,
                          }}
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: renderParagraphs(jobs.job_description),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                          }}
                          color="text.secondary"
                        >
                          No description for this job
                        </Typography>
                      )}
                    </div>
                  </CardContent>
                  <div className="ApplyButton">
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "99%",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          moreDetails(jobs.job_description);
                        }}
                        sx={{ ml: 1, mr: 1 }}
                        variant="outlined"
                        color="success"
                        size="small"
                      >
                        View More
                      </Button>

                      {match(jobs._id) &&
                        (jobs.job_status === "InActive" ? (
                          <Button disabled variant="contained" size="small">
                            Apply
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              Apply(jobs);
                            }}
                          >
                            Apply
                          </Button>
                        ))}
                    </CardActions>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default AppliedJobs;
