import React from "react";
import { Box, Card, Grid, styled, Typography, Tooltip } from "@mui/material";
import CountUp from "react-countup";
import {
  Diversity3 as Diversity3Icon,
  Apartment as ApartmentIcon,
  LocalLibrary as LocalLibraryIcon,
  PlayLesson as PlayLessonIcon,
  Pending as PendingIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

const StyledCard = styled(Card)(({ theme }) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const MProute =
    ROUTINGDATA.LAND + ROUTINGDATA.MPConstituency + ROUTINGDATA.MPDASHBOARD;
  const MLAroute =
    ROUTINGDATA.LAND + ROUTINGDATA.MLAconstituency + ROUTINGDATA.MLAdashboard;

  return {
    "&.MuiCard-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "16px !important",
        flexDirection: "row",
        gap: "10px",
      },

      padding: "25px",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      alignContent: "space-between",
      boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
     
      transition: "0.2s",
    },
  };
});

function StatMLAcards({ state, ind }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const MProute =
    ROUTINGDATA.LAND + ROUTINGDATA.MPConstituency + ROUTINGDATA.MPDASHBOARD;
  const MLAroute =
    ROUTINGDATA.LAND + ROUTINGDATA.MLAconstituency + ROUTINGDATA.MLAdashboard;

  const [training, accomodation, courseFees, Mentorship, others] = [
    state.Training,
    state.Accommodation,
    state["Course Fees"],
    state.Mentorship,
    state.Others,
  ];

  const cardList = [
    {
      name: "Training",
      count: training.length,
      bgColor: "#ffa07a",
      svg: <LocalLibraryIcon fontSize="large" />,
      filterValue: "Training",
    },
    {
      name: "Accommodation",
      count: accomodation.length,
      bgColor: "#6b8e23",
      svg: <ApartmentIcon fontSize="large" />,
      filterValue: "Accommodation",
    },
    {
      name: "Course Fees",
      count: courseFees.length,
      bgColor: "#0077b5",
      svg: <PlayLessonIcon fontSize="large" />,
      filterValue: "Course Fees",
    },
    {
      name: "MentorShip",
      count: Mentorship.length,
      bgColor: "#ffa07a",
      svg: <Diversity3Icon fontSize="large" />,
      filterValue: "Mentorship",
    },
    {
      name: "Others",
      count: others.length,
      bgColor: "#ffa07a",
      svg: <PendingIcon fontSize="large" />,
      filterValue: "Others",
    },
  ];
  const handleNeedAllClick = (event) => {
    sessionStorage.setItem("mpneedall_active", event);
    sessionStorage.removeItem("mpneedshort_activ");
    sessionStorage.removeItem("mpall_active");
  };
  const handleClick = (event) => {
    if (currentUrl == MLAroute) {
      sessionStorage.setItem("active", event);
      sessionStorage.removeItem("mpmlaneedshort_active");
      sessionStorage.removeItem("mpmlaneedall_active");
      sessionStorage.removeItem("activeneed");
    } else {
      sessionStorage.setItem("mp_active", event);

      if (event === "dash") {
        sessionStorage.removeItem("mpneed_active");
        sessionStorage.removeItem("mpall_active");
        sessionStorage.removeItem("mpneedshort_active");
        sessionStorage.removeItem("mpneedall_active");
      }
    }
  };
  const handleNeedClick = (event) => {
    sessionStorage.setItem("mpneed_active", event);
    sessionStorage.removeItem("mpall_active");
  };
  const handleClickLog = (event) => {
    sessionStorage.setItem("activeneed", event);
    sessionStorage.removeItem("active");
  };

  const handleAllClick = (event) => {
    sessionStorage.setItem("mpmlaneedall_active", event);
    sessionStorage.removeItem("mpmlaneedshort_active");
    sessionStorage.removeItem("active");
  };
  const handleShortClick = (event) => {
    sessionStorage.setItem("mpmlaneedshort_active", event);
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Tooltip
              title={
                currentUrl == MProute || currentUrl == MLAroute
                  ? "Go to this filter"
                  : ""
              }
              arrow
            >
              <StyledCard
                sx={{
                  "&.MuiCard-root:hover": {
                    boxShadow: `${
                      currentUrl == MProute || currentUrl == MLAroute
                        ? "0 0 8px 2px rgba(0,0,0,0.4) !important"
                        : "0 0 8px 2px rgba(0,0,0,0.1) !important"
                    }`,
                    cursor:`${currentUrl == MProute || currentUrl == MLAroute
                      ? "pointer"
                      : ""}`
                  },
                }}
                elevation={6}
                onClick={() => {
                  if (currentUrl == MProute) {
                    // sessionStorage.setItem(`shortradio${ind}`, null);
                    // sessionStorage.setItem(
                    //   `mpmlaneedradio${ind}`,
                    //   item.filterValue
                    // );
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MPConstituency +
                        "/" +
                        ROUTINGDATA.MP_MLAneedSupport,
                      {
                        state: {
                          mla: state,
                          typesOfSupportFilterValue: item.filterValue,
                          index: ind,
                        },
                      }
                    );
                    handleNeedAllClick(`mpneedall${ind}`);
                    handleClick(`mp${ind}`);
                    handleNeedClick(`mpneed${ind}`);
                  } else if (currentUrl == MLAroute) {
                    // sessionStorage.setItem(`shortradio`, item.filterValue);

                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MLAconstituency +
                        "/" +
                        ROUTINGDATA.MLAneedSupport,
                      {
                        state: {
                          typesOfSupportFilterValue: item.filterValue,
                          needSupportFilterValue: null,
                        },
                      }
                    );

                    handleClickLog("need");
                    handleAllClick(`mpMLAall`);
                  }
                }}
              >
                <Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    <Box sx={{ color: `${item.bgColor}` }}>{item.svg}</Box>
                    <CountUp
                      style={{
                        fontSize: "26px",
                        color: `${item.bgColor}`,
                      }}
                      start={0}
                      end={item.count}
                      duration={1}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Poppins-SemiBold", color: "inherit" }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatMLAcards;
