import React, { useEffect, useState, useRef } from "react";
import "./NeedSupport.css";
import { useFormik } from "formik";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Box,
  Typography,
  Tooltip,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { saveAs } from "file-saver";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exceptThisSymbols = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Backspace",
];

const numberExceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

const NeedSupport = () => {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const Location = useLocation();
  const navigate = useNavigate();
  const [family, setFamily] = useState();
  const [brother, setBrother] = useState(["", "", ""]);
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [getFamilyData, setGetFamilyData] = useState(false);
  let [help, setHelp] = useState();
  const [support, setSupport] = useState(false);
  const [addBrother, setAddBrother] = useState(false);
  const [addBrother1, setAddBrother1] = useState(false);
  const [addBrother2, setAddBrother2] = useState(false);
  const [noFamilyData, setNoFamilyData] = useState(false);
  const [updateSupport, setUpdateSupport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Deletebro, setDeletebro] = useState();
  const [general, setGeneral] = useState([]);
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const fileInputField = useRef(null);
  const [resumeUpload, setResumeUpload] = useState("");
  const [resume, setResume] = useState();
  const [file, setfile] = useState(false);
  const relation = ["Father", "Mother", "Brother1", "Brother2", "Brother3"];
  const [isDelete, setIsDelete] = useState(false);
  const [deleteAadhar, setDeleteAadhar] = useState(false);
  const [focus, setFocus] = useState(false);
  const [data, setdata] = useState({
    Name: "",
    Qualification: "",
    Occupation: "",
    AnnualIncome: 0,
    OwnHouse: "",
    Land: "",
  });
  const validate = async (values) => {
    if (formik.isValid && formik.dirty && IsSubmitting === false) {
      setDeletebro(true);
    } else {
      setDeletebro(false);
    }
  };

  const [typeOfSupport, setTypeOfSupport] = useState("");
  const MAX_FILE_SIZE = 1048576 * 5; //5Mb
  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setResumeUpload(true);
      setResume(false);
      fileInputField.current.value = "";
    } else if (e.target.files[0]) {
      formik.setFieldValue("aadharUpload", e.target.files[0]);
      setResume(e.target.files[0]);
      setfile(true);
    }
  };
  const deleteFile = () => {
    fileInputField.current.value = "";
    setResume(null);
    formik.setFieldValue("aadharUpload", null);
  };

  const formatAadharNumber = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, "");
    const formattedNumber = onlyNumbers.replace(/(\d{4})(?=\d)/g, "$1 ");
    return formattedNumber;
  };

  const handleChange = (event) => {
    setFocus(false)
    const formattedValue = formatAadharNumber(event.target.value);
    if (formattedValue.length <= 14) {
      formik.handleChange(event);
    }
  };

  const formik = useFormik({
    initialValues: {
      Father: data,
      Mother: data,
      Brother1: data,
      Brother2: data,
      Brother3: data,
      Name: "",
      email_id: "",
      TypeOfSupport: "",
      Others: "",
      amount: "",
      aadharNumber: "",
      aadharUpload: {},
    },

    validate,

    validationSchema: Yup.object().shape({
      Father: Yup.object({
        Name: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required("required"),
        Qualification: Yup.string().required("required"),
        Occupation: Yup.string().required("required"),
        AnnualIncome: Yup.string().required("required"),
        OwnHouse: Yup.string().required("required"),
        Land: Yup.string().required("required"),
      }),
      Mother: Yup.object({
        Name: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required("required"),
        Qualification: Yup.string().required("required"),
        Occupation: Yup.string().required("required"),
        AnnualIncome: Yup.string().required("required"),
        OwnHouse: Yup.string().required("required"),
        Land: Yup.string().required("required"),
      }),
      Brother1: Yup.object({
        Name: addBrother
          ? Yup.string()
              .max(50, "Must be 50 characters or less")
              .required("required")
          : Yup.string().max(50, "Must be 50 characters or less"),
        Qualification: addBrother
          ? Yup.string().required("required")
          : Yup.string(),
        Occupation: addBrother
          ? Yup.string().required("required")
          : Yup.string(),
        AnnualIncome: addBrother
          ? Yup.string().required("required")
          : Yup.string(),
        OwnHouse: addBrother ? Yup.string().required("required") : Yup.string(),
        Land: addBrother ? Yup.string().required("required") : Yup.string(),
      }),
      Brother2: Yup.object({
        Name: addBrother1
          ? Yup.string().max(50, "50 characters or less").required("required")
          : Yup.string().max(50, "Must be 50 characters or less"),
        Qualification: addBrother1
          ? Yup.string().required("required")
          : Yup.string(),
        Occupation: addBrother1
          ? Yup.string().required("required")
          : Yup.string(),
        AnnualIncome: addBrother1
          ? Yup.string().required("required")
          : Yup.string(),
        OwnHouse: addBrother1
          ? Yup.string().required("required")
          : Yup.string(),
        Land: addBrother1 ? Yup.string().required("required") : Yup.string(),
      }),
      Brother3: Yup.object({
        Name: addBrother2
          ? Yup.string()
              .max(50, "Must be 50 characters or less")
              .required("required")
          : Yup.string().max(50, "Must be 50 characters or less"),
        Qualification: addBrother2
          ? Yup.string().required("required")
          : Yup.string(),
        Occupation: addBrother2
          ? Yup.string().required("required")
          : Yup.string(),
        AnnualIncome: addBrother2
          ? Yup.string().required("required")
          : Yup.string(),
        OwnHouse: addBrother2
          ? Yup.string().required("required")
          : Yup.string(),
        Land: addBrother2 ? Yup.string().required("required") : Yup.string(),
      }),
      TypeOfSupport: Yup.string().required("required"),
      Others:
        typeOfSupport === "Others"
          ? Yup.string().required("required")
          : Yup.string(),
      amount:
        typeOfSupport === "Training" ||
        typeOfSupport === "Course Fees" ||
        typeOfSupport === "Accommodation"
          ? Yup.string().required("required")
          : Yup.string(),
      aadharNumber: Yup.string()
        .matches(/^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/, {
          message: "Invalid Aadhar number",
          excludeEmptyString: true,
        })
        .required("Aadhar number required"),
    }),

    onSubmit: async (values) => {
      if (resume) {
        values.aadharUpload = resume;
      }
      setIsSubmitting(true);
      if (getFamilyData) {
        const putFamily = await axiosData.put(
          `/family/${Location.state.email_id}`,
          {
            ...values,
            email_id: Location.state.email_id,
            Name: Location.state.Name,
          }
        );
        await axiosData.put(
          `/family/admin/${Location.state.email_id}`,
          {
            aadharUpload: values.aadharUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        FamilyDetail();
        if (putFamily.status === 201) {
          setUpdateSupport(false);
          navigate("/main/seekerProfile", {
            state: Location.state.email_id,
          });
        } else {
          setSupport(true);
          setIsSubmitting(false);
        }

        await axiosData.put(`/seeker/email/${Location.state.email_id}`, {
          TypeOfSupport: values.TypeOfSupport,
          Others: values.Others,
          amount: values.amount,
        });

        formik.handleReset("");
      } else {
        const postFamily = await axiosData.post(`/family`, {
          ...values,
          mla_constituency: Location.state.mla_constituency,
          mpConstituency: Location.state.mpConstituency,
          email_id: Location.state.email_id,
          Name: Location.state.Name,
          votingDistrict: Location.state.votingDistrict,
          industry: Location.state.industry,
          mobile_number: Location.state.mobile_number,
          aadharUpload: resume,
        });
        await axiosData.put(
          `/family/admin/${Location.state.email_id}`,
          {
            aadharUpload: values.aadharUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        FamilyDetail();
        if (postFamily.status === 201) {
          setUpdateSupport(false);
          if (values.TypeOfSupport !== "Mentorship") {
            navigate("/main/BankDetails", {
              state: {
                email_id: Location.state.email_id,
                Name: Location.state.full_name,
                TypeOfSupport: Location.state.TypeOfSupport,
                Others: Location.state.Others,
                amount: Location.state.amount,
                HelpStatus: Location.state.HelpStatus,
              },
            });
          } else {
            navigate("/main/seekerProfile", {
              state: Location.state.email_id,
            });
          }
        } else {
          setSupport(true);
          setIsSubmitting(false);
        }
        formik.handleReset("");
      }

      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    setTypeOfSupport(formik.values.TypeOfSupport);
  }, [formik.values.TypeOfSupport]);

  const FamilyDetail = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `/family/${Location.state.email_id}`
      );

      const { data } = getOneAxios;
      setFamily({ ...family, data });

      if (getOneAxios.status !== 204) {
        setLoading(false);
        setGetFamilyData(true);
      }

      if (getOneAxios.status === 204) {
        setLoading(false);
        setNoFamilyData(true);
      }

      const seeker = await axiosData
        .get(`/seekers/email/${Location.state.email_id}`)
        .then((data) => {
          help = data.data;
          setHelp({ ...help });
        });

      if (data) {
        formik.setFieldValue("Father.Name", data.Father.Name);
        formik.setFieldValue("Father.Qualification", data.Father.Qualification);
        formik.setFieldValue("Father.Occupation", data.Father.Occupation);
        formik.setFieldValue("Father.AnnualIncome", data.Father.AnnualIncome);
        formik.setFieldValue("Father.OwnHouse", data.Father.OwnHouse);
        formik.setFieldValue("Father.Land", data.Father.Land);

        formik.setFieldValue("Mother.Name", data.Mother.Name);
        formik.setFieldValue("Mother.Qualification", data.Mother.Qualification);
        formik.setFieldValue("Mother.Occupation", data.Mother.Occupation);
        formik.setFieldValue("Mother.AnnualIncome", data.Mother.AnnualIncome);
        formik.setFieldValue("Mother.OwnHouse", data.Mother.OwnHouse);
        formik.setFieldValue("Mother.Land", data.Mother.Land);

        formik.setFieldValue("Brother1.Name", data.Brother1.Name);
        formik.setFieldValue(
          "Brother1.Qualification",
          data.Brother1.Qualification
        );
        formik.setFieldValue("Brother1.Occupation", data.Brother1.Occupation);
        formik.setFieldValue(
          "Brother1.AnnualIncome",
          data.Brother1.AnnualIncome
        );
        formik.setFieldValue("Brother1.OwnHouse", data.Brother1.OwnHouse);
        formik.setFieldValue("Brother1.Land", data.Brother1.Land);

        formik.setFieldValue("Brother2.Name", data.Brother2.Name);
        formik.setFieldValue(
          "Brother2.Qualification",
          data.Brother2.Qualification
        );
        formik.setFieldValue("Brother2.Occupation", data.Brother2.Occupation);
        formik.setFieldValue(
          "Brother2.AnnualIncome",
          data.Brother2.AnnualIncome
        );
        formik.setFieldValue("Brother2.OwnHouse", data.Brother2.OwnHouse);
        formik.setFieldValue("Brother2.Land", data.Brother2.Land);

        formik.setFieldValue("Brother3.Name", data.Brother3.Name);
        formik.setFieldValue(
          "Brother3.Qualification",
          data.Brother3.Qualification
        );
        formik.setFieldValue("Brother3.Occupation", data.Brother3.Occupation);
        formik.setFieldValue(
          "Brother3.AnnualIncome",
          data.Brother3.AnnualIncome
        );
        formik.setFieldValue("Brother3.OwnHouse", data.Brother3.OwnHouse);
        formik.setFieldValue("Brother3.Land", data.Brother3.Land);

        formik.setFieldValue("TypeOfSupport", data.TypeOfSupport);
        formik.setFieldValue("aadharNumber", data.aadharNumber);
        formik.setFieldValue("aadharUpload", data.aadharUpload);
        setResume(data.aadharUpload);

        if (data.TypeOfSupport !== "Others") {
          formik.setFieldValue("Others", "");
        } else {
          formik.setFieldValue("Others", data.Others);
        }
        if (
          data.TypeOfSupport === "Course Fees" ||
          data.TypeOfSupport === "Accommodation" ||
          data.TypeOfSupport === "Training"
        ) {
          formik.setFieldValue("amount", data.amount);
        } else {
          formik.setFieldValue("", data.amount);
        }
      }

      return family;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const deletebro = (bro) => {
    const fields = [
      "Name",
      "Qualification",
      "Occupation",
      "AnnualIncome",
      "OwnHouse",
      "Land",
    ];

    // Remove Yup validation for the specified fields
    fields.forEach((field) => {
      formik.setFieldValue(`${bro}.${field}`, "");
      formik.setFieldError(`${bro}.${field}`, "");
      formik.setFieldTouched(`${bro}.${field}`, false);
    });
  };

  const Generaldata = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/Needsupport`);
      const data = getOneAxios.data;
      if (data) {
        setGeneral(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    Generaldata();
    if (seekerDetails === null || seekerDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      FamilyDetail();
    }
  }, []);

  if (loading === false) {
    return (
      <>
        <Dialog
          TransitionComponent={Transition}
          open={support}
          sx={{ "& .MuiPaper-root": { width: "300px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Need Support
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Your not eligible for support !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setSupport(false);
                navigate("/main/seekerProfile", {
                  state: Location.state.email_id,
                });
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {Location.state.action === "view" && general !== undefined && (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Dialog
                TransitionComponent={Transition}
                open={deleteAadhar}
                sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
              >
                <DialogTitle
                  bgcolor="rgb(16 143 178)"
                  sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
                >
                  Delete resume
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                  <DialogContentText sx={{ fontWeight: 600 }}>
                    Are you sure, you want to delete resume
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setDeleteAadhar(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isDelete}
                    onClick={async () => {
                      setIsDelete(true);
                      await axiosData.delete(
                        `/family/proof/${Location.state.email_id}`
                      );
                      FamilyDetail();
                      setIsDelete(false);
                      setDeleteAadhar(false);
                    }}
                  >
                    Yes{" "}
                    {isDelete && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "#ffffff",
                          marginLeft: "4px",
                          marginBottom: "-2px",
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog TransitionComponent={Transition} open={resumeUpload}>
                <DialogTitle
                  bgcolor="rgb(16 143 178)"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  Aadhar proof Upload
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                  <DialogContentText sx={{ fontWeight: 600 }}>
                    {"Aadhar proof size is too large upload < 5MB file."}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setResumeUpload(false)}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                TransitionComponent={Transition}
                open={updateSupport}
                sx={{ "& .MuiPaper-root": { width: "500px" } }}
              >
                <DialogTitle
                  bgcolor="rgb(16 143 178)"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  Are you sure !
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                  <DialogContentText sx={{ fontWeight: 600 }}>
                    Please verify submitted details, once you submitted you
                    can't modify.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setUpdateSupport(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Ok{" "}
                    {IsSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "rgb(171 72 188)",
                          marginLeft: 12,
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: 700,
                      width: "100%",
                      alignContent: "space-between",
                    }}
                  >
                    Family Details
                  </Typography>
                  <Tooltip
                    arrow
                    onClick={() => {
                      navigate("/main/seekerProfile", {
                        state: Location.state.email_id,
                      });
                    }}
                    className="GoBackTooltip"
                    sx={{ backgroundColor: "#339fcd" }}
                    title="Go Back"
                  >
                    <IconButton
                      className="GoBackTooltip"
                      sx={{ backgroundColor: "#339fcd" }}
                    >
                      <ArrowBackIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <TableContainer>
                <Table
                  sx={{ mt: 2 }}
                  aria-label="simple table"
                  className="needSupportTable"
                >
                  <TableHead>
                    <TableRow className="familyDetailHead">
                      <TableCell align="center">Relation</TableCell>
                      <TableCell align="center">Family Details</TableCell>
                      <TableCell align="center">Qualification</TableCell>
                      <TableCell align="center">Occupation</TableCell>
                      <TableCell align="center">Annual Income</TableCell>
                      <TableCell align="center">Own House</TableCell>
                      <TableCell align="center">Plot/Land</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableRow>
                    <TableCell align="center">Father</TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ width: 130 }}
                        placeholder="Father Name"
                        label="Father Name"
                        id="fatherName"
                        name="Father.Name"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z.,' ]/gi,
                              ""
                            );
                            formik.setFieldValue("Father.Name", value);
                          }
                        }}
                        value={formik.values.Father.Name}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched?.Father?.Name &&
                        formik.errors?.Father?.Name && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.Name}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Qualification
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="Qualification"
                          label="Qualification"
                          onBlur={formik.handleBlur}
                          name="Father.Qualification"
                          onChange={formik.handleChange}
                          value={formik.values.Father.Qualification}
                        >
                          {general.Qualification ? (
                            general.Qualification.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value="PG">PG</MenuItem>
                              <MenuItem value="UG">UG</MenuItem>
                              <MenuItem value="HSC">HSC</MenuItem>
                              <MenuItem value="SSLC">SSLC</MenuItem>
                              <Divider />
                              <MenuItem value="No Education">
                                No Education
                              </MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Father?.Qualification &&
                        formik.errors?.Father?.Qualification && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.Qualification}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Occupation
                        </InputLabel>
                        <Select
                          name="Father.Occupation"
                          labelId="demo-simple-select-label"
                          id="Occupation"
                          label="Occupation"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Father.Occupation}
                        >
                          {general.Occupation ? (
                            general.Occupation.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Govt Job"}>Govt Job</MenuItem>
                              <MenuItem value={"Private Job"}>
                                Private Job
                              </MenuItem>
                              <MenuItem value={"Self Employed"}>
                                Self Employed
                              </MenuItem>
                              <MenuItem value={"Labor"}>Labor</MenuItem>
                              <MenuItem value={"No Job"}>No Job</MenuItem>
                              <MenuItem value={"Late"}>Late</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Father?.Occupation &&
                        formik.errors?.Father?.Occupation && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.Occupation}
                          </Typography>
                        )}
                    </TableCell>

                    <TableCell align="center">
                      <TextField
                        sx={{ width: 130 }}
                        type="number"
                        name="Father.AnnualIncome"
                        labelId="demo-simple-select-label"
                        id="AnnualIncome"
                        label="Annual Income"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.Father.AnnualIncome}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                        }
                      />
                      {formik.touched?.Father?.AnnualIncome &&
                        formik.errors?.Father?.AnnualIncome && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.AnnualIncome}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Own House
                        </InputLabel>
                        <Select
                          name="Father.OwnHouse"
                          labelId="demo-simple-select-label"
                          id="OwnHouse"
                          label="OwnHouse"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Father.OwnHouse}
                        >
                          {general.OwnHouse ? (
                            general.OwnHouse.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Father?.OwnHouse &&
                        formik.errors?.Father?.OwnHouse && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.OwnHouse}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Plot/Land
                        </InputLabel>
                        <Select
                          name="Father.Land"
                          labelId="demo-simple-select-label"
                          id="Land"
                          label="Plot/Land"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Father.Land}
                        >
                          {general.OwnHouse ? (
                            general.OwnHouse.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Father?.Land &&
                        formik.errors?.Father?.Land && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Father?.Land}
                          </Typography>
                        )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">Mother</TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ width: 130 }}
                        p
                        laceholder="Mother Name"
                        name="Mother.Name"
                        label="Mother Name"
                        id="mother"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z.,' ]/gi,
                              ""
                            );
                            formik.setFieldValue("Mother.Name", value);
                          }
                        }}
                        value={formik.values.Mother.Name}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched?.Mother?.Name &&
                        formik.errors?.Mother?.Name && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.Name}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Qualification
                        </InputLabel>

                        <Select
                          name="Mother.Qualification"
                          labelId="demo-simple-select-label"
                          id="Qualification"
                          label="Qualification"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Mother.Qualification}
                        >
                          {general.Qualification ? (
                            general.Qualification.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value="PG">PG</MenuItem>
                              <MenuItem value="UG">UG</MenuItem>
                              <MenuItem value="HSC">HSC</MenuItem>
                              <MenuItem value="SSLC">SSLC</MenuItem>
                              <Divider />
                              <MenuItem value="No Education">
                                No Education
                              </MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Mother?.Qualification &&
                        formik.errors?.Mother?.Qualification && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.Qualification}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Occupation
                        </InputLabel>
                        <Select
                          name="Mother.Occupation"
                          labelId="demo-simple-select-label"
                          id="Occupation"
                          label="Occupation"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Mother.Occupation}
                        >
                          {general.Occupation ? (
                            general.motherOccupation.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Govt Job"}>Govt Job</MenuItem>
                              <MenuItem value={"Private Job"}>
                                Private Job
                              </MenuItem>
                              <MenuItem value={"Self Employed"}>
                                Self Employed
                              </MenuItem>
                              <MenuItem value={"Labor"}>Labor</MenuItem>
                              <MenuItem value={"Home maker"}>
                                Home maker
                              </MenuItem>
                              <MenuItem value={"No Job"}>No Job</MenuItem>
                              <MenuItem value={"Late"}>Late</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Mother?.Occupation &&
                        formik.errors?.Mother?.Occupation && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.Occupation}
                          </Typography>
                        )}
                    </TableCell>

                    <TableCell align="center">
                      <TextField
                        sx={{ width: 130 }}
                        type="number"
                        name="Mother.AnnualIncome"
                        labelId="demo-simple-select-label"
                        id="AnnualIncome"
                        label="Annual Income"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.Mother.AnnualIncome}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                        }
                      />
                      {formik.touched?.Mother?.AnnualIncome &&
                        formik.errors?.Mother?.AnnualIncome && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.AnnualIncome}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Own House
                        </InputLabel>
                        <Select
                          name="Mother.OwnHouse"
                          labelId="demo-simple-select-label"
                          id="OwnHouse"
                          label="Own House"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Mother.OwnHouse}
                        >
                          {general.OwnHouse ? (
                            general.OwnHouse.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Mother?.OwnHouse &&
                        formik.errors?.Mother?.OwnHouse && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.OwnHouse}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ width: 130 }}>
                        <InputLabel id="demo-simple-select-label">
                          Plot/Land
                        </InputLabel>
                        <Select
                          name="Mother.Land"
                          labelId="demo-simple-select-label"
                          id="Land"
                          label="Plot/Land"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Mother.Land}
                        >
                          {general.OwnHouse ? (
                            general.OwnHouse.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched?.Mother?.Land &&
                        formik.errors?.Mother?.Land && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors?.Mother?.Land}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip arrow title="Add Brother">
                        <IconButton
                          disabled={brother[0] !== ""}
                          onClick={() => {
                            brother[0] = "add";
                            setBrother([...brother]);
                            setAddBrother(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>

                  {(brother[0] === "add" ||
                    formik.values.Brother1.Name !== "") && (
                    <TableRow sx={{ display: brother[0] }}>
                      <TableCell align="center">Brother1</TableCell>
                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          placeholder="brother Name"
                          name="Brother1.Name"
                          label="Brother Name"
                          id="brother"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value.replace(
                                /[^a-z.,' ]/gi,
                                ""
                              );
                              formik.setFieldValue("Brother1.Name", value);
                            }
                          }}
                          value={formik.values.Brother1.Name}
                        />
                        {formik.touched?.Brother1?.Name &&
                          formik.errors?.Brother1?.Name && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.Name}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Qualification
                          </InputLabel>

                          <Select
                            name="Brother1.Qualification"
                            labelId="demo-simple-select-label"
                            id="Qualification"
                            label="Qualification"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother1.Qualification}
                          >
                            {general.Qualification ? (
                              general.Qualification.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="HSC">HSC</MenuItem>
                                <MenuItem value="SSLC">SSLC</MenuItem>
                                <Divider />
                                <MenuItem value="No Education">
                                  No Education
                                </MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother1?.Qualification &&
                          formik.errors?.Brother1?.Qualification && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.Qualification}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Occupation
                          </InputLabel>
                          <Select
                            name="Brother1.Occupation"
                            labelId="demo-simple-select-label"
                            id="Occupation"
                            label="Occupation"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother1.Occupation}
                          >
                            {general.Occupation ? (
                              general.Occupation.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Govt Job"}>Govt Job</MenuItem>
                                <MenuItem value={"Private Job"}>
                                  Private Job
                                </MenuItem>
                                <MenuItem value={"Self Employed"}>
                                  Self Employed
                                </MenuItem>
                                <MenuItem value={"Labor"}>Labor</MenuItem>
                                <MenuItem value={"No Job"}>No Job</MenuItem>
                                <MenuItem value={"Late"}>Late</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother1?.Occupation &&
                          formik.errors?.Brother1?.Occupation && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.Occupation}
                            </Typography>
                          )}
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          type="number"
                          name="Brother1.AnnualIncome"
                          labelId="demo-simple-select-label"
                          id="AnnualIncome"
                          label="Annual Income"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Brother1.AnnualIncome}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onKeyDown={(e) =>
                            numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                          }
                        />
                        {formik.touched?.Brother1?.AnnualIncome &&
                          formik.errors?.Brother1?.AnnualIncome && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.AnnualIncome}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Own House
                          </InputLabel>
                          <Select
                            name="Brother1.OwnHouse"
                            labelId="demo-simple-select-label"
                            id="OwnHouse"
                            label="Own House"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother1.OwnHouse}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother1?.OwnHouse &&
                          formik.errors?.Brother1?.OwnHouse && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.OwnHouse}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Plot/Land
                          </InputLabel>
                          <Select
                            name="Brother1.Land"
                            labelId="demo-simple-select-label"
                            id="Land"
                            label="Plot/Land"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother1.Land}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother1?.Land &&
                          formik.errors?.Brother1?.Land && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother1?.Land}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ display: "flex" }}>
                          <Tooltip arrow title="Add Brother">
                            <IconButton
                              disabled={brother[1] !== ""}
                              onClick={() => {
                                brother[1] = "add";
                                setBrother([...brother]);
                                setAddBrother1(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Delete Brother">
                            <IconButton
                              disabled={brother[1] === "add"}
                              onClick={() => {
                                brother[0] = "";
                                setBrother([...brother]);
                                deletebro("Brother1");
                                setAddBrother(false);
                                formik.setFieldValue(
                                  "Father.Name",
                                  formik.values?.Father.Name
                                );
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}

                  {(brother[1] === "add" ||
                    formik.values.Brother2.Name !== "") && (
                    <TableRow sx={{ display: brother[1] }}>
                      <TableCell align="center">Brother2</TableCell>
                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          placeholder="brother Name"
                          name="Brother2.Name"
                          label="Brother Name"
                          id="brother"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value.replace(
                                /[^a-z.,' ]/gi,
                                ""
                              );
                              formik.setFieldValue("Brother2.Name", value);
                            }
                          }}
                          value={formik.values.Brother2.Name}
                        />
                        {formik.touched?.Brother2?.Name &&
                          formik.errors?.Brother2?.Name && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.Name}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Qualification
                          </InputLabel>

                          <Select
                            name="Brother2.Qualification"
                            labelId="demo-simple-select-label"
                            id="Qualification"
                            label="Qualification"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother2.Qualification}
                          >
                            {general.Qualification ? (
                              general.Qualification.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="HSC">HSC</MenuItem>
                                <MenuItem value="SSLC">SSLC</MenuItem>
                                <Divider />
                                <MenuItem value="No Education">
                                  No Education
                                </MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother2?.Qualification &&
                          formik.errors?.Brother2?.Qualification && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.Qualification}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Occupation
                          </InputLabel>
                          <Select
                            name="Brother2.Occupation"
                            labelId="demo-simple-select-label"
                            id="Occupation"
                            label="Occupation"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother2.Occupation}
                          >
                            {general.Occupation ? (
                              general.Occupation.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Govt Job"}>Govt Job</MenuItem>
                                <MenuItem value={"Private Job"}>
                                  Private Job
                                </MenuItem>
                                <MenuItem value={"Self Employed"}>
                                  Self Employed
                                </MenuItem>
                                <MenuItem value={"Labor"}>Labor</MenuItem>
                                <MenuItem value={"No Job"}>No Job</MenuItem>
                                <MenuItem value={"Late"}>Late</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother2?.Occupation &&
                          formik.errors?.Brother2?.Occupation && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.Occupation}
                            </Typography>
                          )}
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          type="number"
                          name="Brother2.AnnualIncome"
                          labelId="demo-simple-select-label"
                          id="AnnualIncome"
                          label="Annual Income"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Brother2.AnnualIncome}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onKeyDown={(e) =>
                            numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                          }
                        />
                        {formik.touched?.Brother2?.AnnualIncome &&
                          formik.errors?.Brother2?.AnnualIncome && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.AnnualIncome}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Own House
                          </InputLabel>
                          <Select
                            name="Brother2.OwnHouse"
                            labelId="demo-simple-select-label"
                            id="OwnHouse"
                            label="Own House"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother2.OwnHouse}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother2?.OwnHouse &&
                          formik.errors?.Brother2?.OwnHouse && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.OwnHouse}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Plot/Land
                          </InputLabel>
                          <Select
                            name="Brother2.Land"
                            labelId="demo-simple-select-label"
                            id="Land"
                            label="Plot/Land"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother2.Land}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother2?.Land &&
                          formik.errors?.Brother2?.Land && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother2?.Land}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Add Brother">
                          <IconButton
                            disabled={brother[2] !== ""}
                            onClick={() => {
                              brother[2] = "add";
                              setBrother([...brother]);
                              setAddBrother2(true);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Delete Brother">
                          <IconButton
                            disabled={brother[2] === "add"}
                            onClick={() => {
                              brother[1] = "";
                              setBrother([...brother]);
                              deletebro("Brother2");
                              setAddBrother1(false);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )}

                  {(brother[2] === "add" ||
                    formik.values.Brother3.Name !== "") && (
                    <TableRow sx={{ display: brother[2] }}>
                      <TableCell align="center">Brother3</TableCell>
                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          placeholder="brother Name"
                          name="Brother3.Name"
                          label="Brother Name"
                          id="brother"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value.replace(
                                /[^a-z.,' ]/gi,
                                ""
                              );
                              formik.setFieldValue("Brother3.Name", value);
                            }
                          }}
                          value={formik.values.Brother3.Name}
                        />
                        {formik.touched?.Brother3?.Name &&
                          formik.errors?.Brother3?.Name && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.Name}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Qualification
                          </InputLabel>

                          <Select
                            name="Brother3.Qualification"
                            labelId="demo-simple-select-label"
                            id="Qualification"
                            label="Qualification"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother3.Qualification}
                          >
                            {general.Qualification ? (
                              general.Qualification.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="HSC">HSC</MenuItem>
                                <MenuItem value="SSLC">SSLC</MenuItem>
                                <Divider />
                                <MenuItem value="No Education">
                                  No Education
                                </MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother3?.Qualification &&
                          formik.errors?.Brother3?.Qualification && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.Qualification}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Occupation
                          </InputLabel>
                          <Select
                            name="Brother3.Occupation"
                            labelId="demo-simple-select-label"
                            id="Occupation"
                            label="Occupation"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother3.Occupation}
                          >
                            {general.Occupation ? (
                              general.Occupation.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Govt Job"}>Govt Job</MenuItem>
                                <MenuItem value={"Private Job"}>
                                  Private Job
                                </MenuItem>
                                <MenuItem value={"Self Employed"}>
                                  Self Employed
                                </MenuItem>
                                <MenuItem value={"Labor"}>Labor</MenuItem>
                                <MenuItem value={"No Job"}>No Job</MenuItem>
                                <MenuItem value={"Late"}>Late</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother3?.Occupation &&
                          formik.errors?.Brother3?.Occupation && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.Occupation}
                            </Typography>
                          )}
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          sx={{ width: 130 }}
                          type="number"
                          name="Brother3.AnnualIncome"
                          labelId="demo-simple-select-label"
                          id="AnnualIncome"
                          label="Annual Income"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Brother3.AnnualIncome}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onKeyDown={(e) =>
                            numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                          }
                        />
                        {formik.touched?.Brother3?.AnnualIncome &&
                          formik.errors?.Brother3?.AnnualIncome && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.AnnualIncome}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Own House
                          </InputLabel>
                          <Select
                            name="Brother3.OwnHouse"
                            labelId="demo-simple-select-label"
                            id="OwnHouse"
                            label="Own House"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother3.OwnHouse}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother3?.OwnHouse &&
                          formik.errors?.Brother3?.OwnHouse && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.OwnHouse}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ width: 130 }}>
                          <InputLabel id="demo-simple-select-label">
                            Plot/Land
                          </InputLabel>
                          <Select
                            name="Brother3.Land"
                            labelId="demo-simple-select-label"
                            id="Land"
                            label="Plot/Land"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.Brother3.Land}
                          >
                            {general.OwnHouse ? (
                              general.OwnHouse.map((data, i) => {
                                return (
                                  <MenuItem key={i} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </>
                            )}
                          </Select>
                        </FormControl>
                        {formik.touched?.Brother3?.Land &&
                          formik.errors?.Brother3?.Land && (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors?.Brother3?.Land}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Delete Brother">
                          <IconButton
                            onClick={() => {
                              brother[2] = "";
                              setBrother([...brother]);
                              deletebro("Brother3");
                              setAddBrother2(false);
                              formik.setFieldValue(
                                "Father.Name",
                                formik.values?.Father.Name
                              );
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              </TableContainer>

              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12} sm={12} md={10}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                      <FormControl sx={{ mr: 2, mt: 2, width: 223 }}>
                        <InputLabel id="demo-simple-select-label">
                          Type of support
                        </InputLabel>
                        <Select
                          name="TypeOfSupport"
                          labelId="demo-simple-select-label"
                          id="Type of support"
                          label="Type of support"
                          onChange={(e, v) => {
                            formik.setFieldValue(
                              "TypeOfSupport",
                              e.target.value
                            );
                            formik.setFieldValue("amount", "");
                          }}
                          value={formik.values.TypeOfSupport}
                        >
                          {general.TypeOfSupport ? (
                            general.TypeOfSupport.map((data, i) => {
                              return (
                                <MenuItem key={i} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <>
                              <MenuItem value={"Training"}>Training</MenuItem>
                              <MenuItem value={"Accommodation"}>
                                Accommodation
                              </MenuItem>
                              <MenuItem value={"Course Fees"}>
                                Course Fees
                              </MenuItem>
                              <MenuItem value={"Mentorship"}>
                                Mentorship
                              </MenuItem>
                              <MenuItem value={"Others"}>Others</MenuItem>
                            </>
                          )}
                        </Select>
                      </FormControl>
                      {formik.touched.TypeOfSupport &&
                        formik.errors.TypeOfSupport && (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors.TypeOfSupport}
                          </Typography>
                        )}
<FormControl>
                      {formik.values.TypeOfSupport === "Others" ? (
                        <TextField
                          fullWidth
                          sx={{ mt: 2, width: 223 }}
                          name="Others"
                          labelId="demo-simple-select-label"
                          id="Others"
                          label="Others"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.Others}
                        />
                      ) : null}
                      {formik.values.TypeOfSupport === "Others" &&
                        formik.touched.Others &&
                        formik.errors.Others && (
                          <Typography
                            sx={{ color: "red", fontSize: "13px", ml: 0.5 }}
                          >
                            {formik.errors.Others}
                          </Typography>
                        )}
                        </FormControl>

<FormControl>
                      {formik.values.TypeOfSupport === "Training" ||
                      formik.values.TypeOfSupport === "Course Fees" ||
                      formik.values.TypeOfSupport === "Accommodation" ? (
                        <TextField
                          fullWidth
                          sx={{ mt: 2, width: 223 }}
                          type="number"
                          name="amount"
                          labelId="demo-simple-select-label"
                          id="amount"
                          label="Approximate amount"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.amount}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onKeyDown={(e) =>
                            numberExceptThisSymbols.includes(e.key) && e.preventDefault()
                          }
                        />
                      ) : null}
                      {(formik.values.TypeOfSupport === "Training" ||
                        formik.values.TypeOfSupport === "Course Fees" ||
                        formik.values.TypeOfSupport === "Accommodation") &&
                        formik.touched.amount &&
                        formik.errors.amount && (
                          <Typography
                            sx={{ color: "red", fontSize: "13px", ml: 0.5 }}
                          >
                            {formik.errors.amount}
                          </Typography>
                        )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 2, width: 223 }}
                        name="aadharNumber"
                        labelId="demo-simple-select-label"
                        id="aadharNumber"
                        label="Aadhaar Number"
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        value={formatAadharNumber(formik.values.aadharNumber)}
                        onBlurCapture={() => {
                          setFocus(true);
                        }}
                        maxLength={14}
                        InputProps={{
                          style: { color: "black" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(e) =>
                          !exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                      {formik.touched.aadharNumber && focus &&
                        formik.errors.aadharNumber && (
                          <Typography
                            sx={{ color: "red", fontSize: "13px", ml: 0.5 }}
                          >
                            {formik.errors.aadharNumber}
                          </Typography>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                      <Box sx={{ display: "flex" }}>
                        <div>
                          <input
                            className="resumefile"
                            id="file-upload"
                            type="file"
                            accept=".pdf,image/jpeg,image/png"
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            ref={fileInputField}
                            required
                          />
                          <div>
                            <Button
                              disabled={
                                resume || family?.data?.aadharUpload
                                  ? true
                                  : false
                              }
                              htmlFor="file"
                              variant="contained"
                              size="medium"
                              color="success"
                              className="aadharUpload"
                              sx={{ borderRadius: 10 }}
                              name="aadharUpload"
                              onClick={() => {
                                fileInputField.current.click();
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.aadharUpload}
                            >
                              <AddCircleOutlineIcon className="aadharIcon" />
                              Aadhaar Proof
                            </Button>
                            <p className="info1">{".pdf/.jpg/.png/.jpeg"}</p>
                            <p className="info2">{"File size < 5Mb"}</p>
                          </div>
                        </div>
                        {file === true ? (
                          <>
                          <Tooltip title="Delete proof" arrow>
                            <IconButton sx={{ height: "50%", mt: 1.6 }}>
                              <DeleteForeverIcon
                                sx={{ color: "red", fontSize: 30 }}
                                onClick={() => {
                                  setfile(false);
                                  deleteFile();
                                }}
                              />
                            </IconButton>
                          </Tooltip><Typography className="aadharName" sx={{mt:3}} >{resume.name}</Typography> 
                          </>
                        ) : null}

                        {family?.data?.aadharUpload ? (
                          <>
                          <IconButton
                            sx={{ height: "50%", mt: 1.8 }}
                            onClick={() => {
                              setDeleteAadhar(true);
                            }}
                          >
                            <Tooltip title="Delete proof" arrow>
                              <DeleteForeverIcon
                                sx={{
                                  color: "rgb(243 81 81)",
                                  border: "1px solid rgb(243 81 81)",
                                  borderRadius: "50%",
                                }}
                              />
                            </Tooltip>
                          </IconButton><Typography className="aadharName" sx={{mt:3}} >{ family ? family?.data?.aadharUpload.name : null}</Typography>
                          </>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid sx={{ position: "relative" }} xs={12} sm={12} md={2}>
                  <Button
                    disabled={
                      (formik.isValid || formik.dirty) &&
                      formik.values.aadharUpload &&
                      resume
                        ? false
                        : true
                    }
                    sx={{ m: 3.4, position: "absolute", right: 0 }}
                    onClick={() => {
                      setUpdateSupport(true);
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
        {family
          ? Location.state.action === "edit" && (
              <>
                <Dialog
                  TransitionComponent={Transition}
                  open={noFamilyData}
                  sx={{ "& .MuiPaper-root": { width: "300px" } }}
                >
                  <DialogTitle
                    bgcolor="rgb(16 143 178)"
                    sx={{ color: "white", fontWeight: 600 }}
                  >
                    Need Support
                  </DialogTitle>
                  <DialogContent sx={{ mt: 3 }}>
                    <DialogContentText sx={{ fontWeight: 600 }}>
                      No data available, please update your family details !
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        setNoFamilyData(false);
                        navigate("/main/seekerProfile", {
                          state: Location.state.email_id,
                        });
                      }}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: 700,
                      width: "100%",
                      alignContent: "space-between",
                    }}
                  >
                    Family Details
                  </Typography>
                  <Tooltip
                    arrow
                    onClick={() => {
                      navigate("/main/seekerProfile", {
                        state: Location.state.email_id,
                      });
                    }}
                    className="GoBackTooltip"
                    sx={{ backgroundColor: "#339fcd" }}
                    title="Go Back"
                  >
                    <IconButton
                      className="GoBackTooltip"
                      sx={{ backgroundColor: "#339fcd" }}
                    >
                      <ArrowBackIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TableContainer>
                  <Table sx={{ mt: 2 }} aria-label="simple table">
                    <TableHead>
                      <TableRow className="familyDetailHead">
                        <TableCell align="center">Relation</TableCell>
                        <TableCell align="center">Family Details</TableCell>
                        <TableCell align="center">Qualification</TableCell>
                        <TableCell align="center">Occupation</TableCell>
                        <TableCell align="center">Annual Income</TableCell>
                        <TableCell align="center">Own House</TableCell>
                        <TableCell align="center">Plot/Land</TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell align="center">Actions</TableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    {family.data?.Father?.Name && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          Father
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Father.Name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Father.Qualification}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Father.Occupation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {`${family.data.Father.AnnualIncome}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Father.OwnHouse}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Father.Land}
                        </TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell>
                            <Tooltip arrow title="Edit Details">
                              <IconButton>
                                <EditIcon
                                  sx={{
                                    color: "rgba(25,118,210,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/main/needSupport", {
                                      state: {
                                        email_id: Location.state.email_id,
                                        action: "view",
                                      },
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )}

                    {family.data?.Mother?.Name && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          Mother
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Mother.Name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Mother.Qualification}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Mother.Occupation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {`${family.data.Mother.AnnualIncome}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Mother.OwnHouse}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Mother.Land}
                        </TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell>
                            <Tooltip arrow title="Edit Details">
                              <IconButton>
                                <EditIcon
                                  sx={{
                                    color: "rgba(25,118,210,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/main/needSupport", {
                                      state: {
                                        email_id: Location.state.email_id,
                                        action: "view",
                                      },
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )}

                    {family.data?.Brother1?.Name && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          Brother1
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother1.Name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother1.Qualification}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother1.Occupation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {`${family.data.Brother1.AnnualIncome}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother1.OwnHouse}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother1.Land}
                        </TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell>
                            <Tooltip arrow title="Edit Details">
                              <IconButton>
                                <EditIcon
                                  sx={{
                                    color: "rgba(25,118,210,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/main/needSupport", {
                                      state: {
                                        email_id: Location.state.email_id,
                                        action: "view",
                                      },
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )}

                    {family.data?.Brother2?.Name && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          Brother2
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother2.Name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother2.Qualification}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother2.Occupation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {`${family.data.Brother2.AnnualIncome}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother2.OwnHouse}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother2.Land}
                        </TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell>
                            <Tooltip arrow title="Edit Details">
                              <IconButton>
                                <EditIcon
                                  sx={{
                                    color: "rgba(25,118,210,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/main/needSupport", {
                                      state: {
                                        email_id: Location.state.email_id,
                                        action: "view",
                                      },
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )}

                    {family.data?.Brother3?.Name && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          Brother3
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.Name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.Qualification}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.Occupation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.AnnualIncome}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.OwnHouse}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {family.data.Brother3.Land}
                        </TableCell>
                        {family.data.VerifierName ||
                        help?.HelpStatus === "No" ||
                        family?.data?.finalVerification ===
                          "Completed" ? null : (
                          <TableCell>
                            <Tooltip arrow title="Edit Details">
                              <IconButton>
                                <EditIcon
                                  sx={{
                                    color: "rgba(25,118,210,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/main/needSupport", {
                                      state: {
                                        email_id: Location.state.email_id,
                                        action: "view",
                                      },
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>

                <Box sx={{ mt: 2, ml: 2 }}>
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: 700,
                      width: "100%",
                      alignContent: "space-between",
                      ml: -2,
                    }}
                  >
                    Verification Details
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <div className="FamilyDetailsAlign">
                      <div>
                        <Typography
                          className="FamilyNameDetailsAlign"
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            mr: 1,
                          }}
                        >
                          <text>Type of support</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                          color="text.secondary"
                        >
                          {family.data.TypeOfSupport}
                        </Typography>
                      </div>
                    </div>

                    {family.data.TypeOfSupport === "Others" ? (
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            className="FamilyNameDetailsAlign"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text>Other support</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {family.data.Others}
                          </Typography>
                        </div>
                      </div>
                    ) : null}
                    {family.data.TypeOfSupport === "Training" ||
                    family.data.TypeOfSupport === "Course Fees" ||
                    family.data.TypeOfSupport === "Accommodation" ? (
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            className="FamilyNameDetailsAlign"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text> Approximate amount</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {family.data.amount}
                          </Typography>
                        </div>
                      </div>
                    ) : null}
                  </Box>
                  <Box>
                    <Box>
                      {family.data.UpiNumber ? (
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              className="FamilyNameDetailsAlign"
                              gutterBottom
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text>GPay Number</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {family?.data?.UpiNumber}
                            </Typography>
                          </div>
                        </div>
                      ) : null}
                    </Box>
                    <Box>
                      {family.data.BankDetails.AccHolderName !== "" ? (
                        <>
                          <div className="FamilyDetailsAlign">
                            <div>
                              <Typography
                                className="FamilyNameDetailsAlign"
                                gutterBottom
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  mr: 1,
                                }}
                              >
                                <text>AccHolder Name</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                                color="text.secondary"
                              >
                                {family?.data?.BankDetails?.AccHolderName}
                              </Typography>
                            </div>
                          </div>

                          <div className="FamilyDetailsAlign">
                            <div>
                              <Typography
                                className="FamilyNameDetailsAlign"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  mr: 1,
                                }}
                              >
                                <text> Account Number</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                                color="text.secondary"
                              >
                                {family?.data?.BankDetails?.AccountNumber}
                              </Typography>
                            </div>
                          </div>

                          <div className="FamilyDetailsAlign">
                            <div>
                              <Typography
                                className="FamilyNameDetailsAlign"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  mr: 1,
                                }}
                              >
                                <text> IFSC Number</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                                color="text.secondary"
                              >
                                {family?.data?.BankDetails?.IfscCode}
                              </Typography>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                  <Box>
                    <div className="FamilyDetailsAlign">
                      <div>
                        <Typography
                          gutterBottom
                          className="FamilyNameDetailsAlign"
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            mr: 1,
                          }}
                        >
                          <text>Aadhar Number</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                          color="text.secondary"
                        >
                          {family.data.aadharNumber}
                        </Typography>
                      </div>
                    </div>

                    {family?.data?.aadharUpload ? (
                      <>
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              className="FamilyNameDetailsAlign"
                              gutterBottom
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text> Aadhar Proof</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "16px",
                                mt: -0.8,
                              }}
                              color="text.secondary"
                            >
                              {family?.data?.aadharUpload?.name}{" "}
                              <Tooltip title="Download Resume" arrow>
                                <IconButton>
                                  <FileDownloadOutlinedIcon
                                    onClick={() => {
                                      saveAs(
                                        `${baseUrl}/family/proof/${Location.state.email_id}`,
                                        family.data.aadharUpload.name
                                      );
                                    }}
                                    size="medium"
                                    sx={{
                                      color: "green",
                                      border: "1px solid green",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Box>

                  {help && (
                    <Box>
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            className="FamilyNameDetailsAlign"
                            gutterBottom
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text> Help Status</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {help.HelpStatus}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  )}

                  {help?.HelpStatus === "Yes" ? (
                    <Box>
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            gutterBottom
                            className="FamilyNameDetailsAlign"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text> Field Verification</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {family.data.fieldVerification}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  ) : null}
                  {family?.data?.contactPerson !== "" ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: 30,
                          fontWeight: 700,
                          width: "100%",
                          alignContent: "space-between",
                          ml: -2,
                        }}
                      >
                        Contact Person Details
                      </Typography>

                      <Box>
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              gutterBottom
                              className="FamilyNameDetailsAlign"
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text> Contact Person</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {family.data.contactPerson}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                      <Box>
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              gutterBottom
                              className="FamilyNameDetailsAlign"
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text>Contact Email</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {family.data.contactEmail}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                      <Box>
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              gutterBottom
                              className="FamilyNameDetailsAlign"
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text> Contact Mobile</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {family.data.contactMobile}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    </>
                  ) : null}

                  {family?.data?.contactPerson !== "" ? null : family?.data
                      ?.finalVerification === "Completed" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ mb: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Final verification completed, we will inform further
                          details.
                        </Alert>
                      </Stack>
                    </Box>
                  ) : family?.data?.fieldVerification === "Completed" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ mb: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Field verification completed admin will verify the
                          final verification, we will inform further details.
                        </Alert>
                      </Stack>
                    </Box>
                  ) : family?.data?.VerifierName ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ mb: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Verifier has assigned to your profile for field
                          verification we will contact for further details.
                        </Alert>
                      </Stack>
                    </Box>
                  ) : null}

                  {family?.data?.contactPerson !== "" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          variant="filled"
                          severity="info"
                          sx={{ mb: 2 }}
                        >
                          <div>
                            {" "}
                            As per your need support this person "
                            {family?.data?.contactPerson}" has been assigned for
                            your support.
                          </div>
                        </Alert>
                      </Stack>
                    </Box>
                  ) : null}

                  {help?.HelpStatus === "No" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ mb: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Your not eligible for support !
                        </Alert>
                      </Stack>
                    </Box>
                  ) : null}
                </Box>
              </>
            )
          : null}
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};
export default NeedSupport;
