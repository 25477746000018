import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  CircularProgress,
  inputLabelClasses,
  TextField,
} from "@mui/material";
function DeclineDialog({
  declineOpen,
  setDeclineOpen,
  value,
  setValue,
  filterName,
  updateDecline,
  getPutId,
  getEmail,
}) {
  const [disabled, setdisabled] = useState(true);
  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "20px",
          },zIndex:"99999"
        }}
        open={declineOpen}
        // onClose={() => {
        //   setDeclineOpen(false);
        // }}
      >
        <Box>
          <DialogTitle>
            <span style={{ color: "#ffffff" }}>Reason</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ color: "white", fontFamily: "Poppins-Medium" }}
            >
              To reject this approval, please enter Reason here. We will send
              updates occasionally.
            </DialogContentText>
            <TextField
              autoFocus
              name="reason"
              id="name"
              value={value}
              onChange={(e) => {
                setValue(e.currentTarget.value);
                if (e.currentTarget.value === "") {
                  setdisabled(true);
                } else {
                  setdisabled(false);
                }
              }}
              sx={{
                ".MuiInput-underline::before": {
                  borderBottom: "2px solid #ffffff",
                },
                ".MuiInput-underline:hover::before": {
                  borderBottom: "2px solid #ffffff",
                },
                ".MuiInput-underline::after": {
                  borderBottom: "2px solid #ffffff",
                },
              }}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "white",
                  },
                  color: "white",
                },
              }}
              required
              label="Reason"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#f2f2f2",
                textTransform: "lowercase",

                color: "black",
                borderColor: "white",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "#f2f2f2",
                  backgroundColor: "#339fcd",
                  borderColor: "#f2f2f2",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                setDeclineOpen(false);

                setValue("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={filterName.isLoading || disabled}
              sx={{
                textTransform: "lowercase",

                color: "white",
                backgroundColor: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                transition: "all 0.3s",
                "&:hover": {
                  textTransform: "lowercase",

                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={(e) => updateDecline(getPutId, getEmail)}
            >
              Submit{" "}
              {filterName.isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DeclineDialog;
