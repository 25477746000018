import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../Login.css";
import bgImg from "../../../img/login image/job-search.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAxios from "../../../api/useAxios";


import Divider from "@mui/material/Divider";
import logo from "../../../img/logo_for-landingpage-3.png";


function ForgotPW2() {
  const axiosData = useAxios();
  const navigate = useNavigate();
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const NewPasswordBtn = () => setNewShowPassword((show) => !show);
  const ConfirmPasswordBtn = () => setConfirmShowPassword((show) => !show);
  let location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`);
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Password Required")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
          /[a-zA-Z]/,
          "Password can only contain a combination of alphabets and numeric."
        ),

      confirmPassword: Yup.string()
        .required("Password Required")
        .oneOf([Yup.ref("newPassword"), null], "Password must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const password = values.confirmPassword;
      try {
        await axiosData.put(`users/${location.state.email_id}`, {
          password,
        });
        try {
          await axiosData.put(`password/${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN:location.state.email_id}`, {
            password,
          });
        } catch (err) {
          console.log(err);
        }
        handleClickOpen();
      } catch (err) {
        console.log(err);
      }
      resetForm({ values: "" });
    },
  });
  return (
    <React.Fragment>
      
      <div id="forgotpasswordpage" className="forgotpasswordpage">
        <div className="forgotpasswordpage-logo-top">
          <div className="forgotpasswordimg">
            <img src={logo} />
          </div>
        </div>
        <div className="forgotpassword-input-box">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Reset Password</h2>
              </div>
              <div className="forgotpassword-contect">
                <p>
                  Enter your new Password and then only you can use your new password for login
                </p>
              </div>
              <div className="space-forgotpassword">
                <div className="login-textfield-1">
                  <label>New Password</label>
                  <OutlinedInput
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z0-9@$#₹]/gi,
                            ""
                          )
                          formik.setFieldValue("newPassword", value);
                        }
                      }}
                      name="newPassword"
                      value={formik.values.newPassword}
                      onBlur={formik.handleBlur}
                      id="outlined-adornment-password"
                      type={showNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={NewPasswordBtn}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <Tooltip title="hide">
                                <VisibilityOff fontSize="small" />
                              </Tooltip>
                            ) : null}
                            {!showNewPassword ? (
                              <Tooltip title="show">
                                <Visibility fontSize="small" />
                              </Tooltip>
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  <FormHelperText>
                  {formik.touched.newPassword &&
                      formik.errors.newPassword ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.newPassword}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                  </FormHelperText>
                </div>
                <div className="login-textfield-1">
                  <label>Confirm Password</label>
                  <OutlinedInput
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z0-9@$#₹]/gi,
                            ""
                          )
                          formik.setFieldValue("confirmPassword", value);
                        }
                      }}
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onBlur={formik.handleBlur}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={ConfirmPasswordBtn}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Tooltip title="hide">
                                <VisibilityOff fontSize="small" />
                              </Tooltip>
                            ) : null}
                            {!showConfirmPassword ? (
                              <Tooltip title="show">
                                <Visibility fontSize="small" />
                              </Tooltip>
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  <FormHelperText>
                  {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.confirmPassword}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                  </FormHelperText>
                </div>
                <div className="loginbtn">
                  <Button variant="contained"   disabled={formik.isValid && formik.dirty ? false : true} fullWidth type="submit">
                  Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <CheckCircleIcon
                sx={{ mt: -0.3, color: "green" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Password Reset Successfully!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "green" }}
            >
              Go back and Login again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "green",
                borderColor: "green",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "green",
                  borderColor: "green",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      </div>
    </React.Fragment>
  );
}
export default ForgotPW2;
