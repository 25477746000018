import React, { useEffect, useState } from "react";
import "./PostJob.css";
import useAxios from "../../../api/useAxios";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateIcon from "@mui/icons-material/Update";
import ReactQuill from "react-quill";
import WestIcon from "@mui/icons-material/West";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import img from "../../../img/noDataImg.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  OutlinedInput,
  Autocomplete,
  Card,
  CardActions,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ShareLocationRounded as ShareLocationRoundedIcon } from "@mui/icons-material";
import { Data } from "../employer/Data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function PostedJob() {
  const axiosData = useAxios();
  const [jobDetails, setJobDetails] = useState([]);
  const [filterJobDetails, setFilterJobDetails] = useState(jobDetails);
  const [getOne, setGetOne] = useState({});
  const [getId, setGetId] = useState([]);
  const [postJopPopup, setPostJopPopup] = useState(false);
  const [postJopPopupClose, setPostJopPopupClose] = useState(false);
  const [postJob, setPostJob] = useState(false);
  const [postJopEditPopup, setPostJopEditPopup] = useState(false);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const [jobDescription, setJobDescription] = useState([]);
  const [industryArray, setIndustryArray] = useState();
  const [from, setFrom] = useState();
  const [copyDialogBox, setCopyDialogBox] = useState(false);
  const [copyData, setCopyData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [experienceType, setExperienceType] = useState();
  const [focus, setfocus] = useState(false);
  const [focusNo, setFocusNo] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [to, setTo] = useState();
  const today = moment().subtract(1, "days");
  const [loading, setLoading] = useState(true);
  const [postedJobPagination, setPostedJobPagination] = useState(10);
  const [postedJobData, setPostedJobData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [postedJobSubcription, setPostedJobSubcription] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [comdata, setComdata] = useState([]);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event, data) => {
    setTempdata(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [experience, setExperience] = useState([]);
  const [eductionType, setEductionType] = useState([]);
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const [skill, setSkill] = useState("");
  const [chip, setChip] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [Tempdata, setTempdata] = useState("");
  const [value, setValue] = useState(0);
  const [districtData, setDistrictData] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("selectedTab", newValue);
  };
  let location = useLocation();
  const userdata = async () => {
    try {
      const response = await axiosData.get(
        `employers/email/${userDetails.emailId}/users`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setPostedJobData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;

      if (data) {
        setExperience(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const educationData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/education`);
      const data = getOneAxios?.data?.educationData;

      if (data) {
        setEductionType(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    userdata();
    IndustryData();
    experienceData();
    educationData();
    vodingDistrict();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const count = (status) => {
    const data = [];
    jobDetails.map((element, index) => {
      if (element.job_status === status) {
        data.push(element);
      }
    });

    return data.length;
  };

  const navigate = useNavigate();
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };
  const formik = useFormik({
    initialValues: {
      job_title: "",
      experience_min: "",
      experience_max: "",
      experience_type: "",
      salary_type: "",
      salary_range_min: "",
      salary_range_max: "",
      education_details: "",
      key_skills: "",
      job_location: "",
      number_of_positions: "",
      industry: "",
      job_description: "",
      contact_email_id: "",
      phone_number: "",
      employer_mobile_number: "",
      employer_email_id: "",
      district: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      job_title: Yup.string().required("Job Title must be Required"),
      experience_min:
        experienceType === "Experience"
          ? Yup.string()
              .required("Minimum Experience Required")
              .test(
                "experience_min",
                "Minimum value must be less than Maximum value",
                function (values) {
                  const { experience_max } = this.parent;
                  if (experience_max) {
                    const Splitmax = experience_max.split(" ");
                    const Splitmin = values.split(" ");
                    const [c, d] = Splitmax;
                    const [a, b] = Splitmin;

                    const max = parseFloat(c);
                    const min = parseFloat(a);
                    return max > min;
                  }
                }
              )
          : Yup.string(),
      experience_max:
        experienceType === "Experience"
          ? Yup.string()
              .required("Maximum Experience Required")
              .test(
                "experience_max",
                "Maximum value must be greater than Minimum value",
                function (values) {
                  const { experience_min } = this.parent;
                  if (experience_min) {
                    const Splitmin = experience_min.split(" ");
                    const Splitmax = values.split(" ");
                    const [c, d] = Splitmin;
                    const [a, b] = Splitmax;
                    const min = parseFloat(c);
                    const max = parseFloat(a);
                    return min < max;
                  }
                }
              )
          : Yup.string(),

      job_location: Yup.string().required("Job Location is required"),
      salary_range_min: Yup.number()
        .test(
          "salary_range_min",
          "Minimum Salary must be less than Maximum Salary",
          function (value) {
            const { salary_range_max } = this.parent;
            if (!salary_range_max || !value) return true;
            return value < salary_range_max;
          }
        )
        .required("Minimum Salary Must be Required"),
      salary_range_max: Yup.number()
        .test(
          "salary_range_max",
          "Maximum Salary must be greater than Minimum Salary",
          function (value) {
            const { salary_range_min } = this.parent;
            if (!salary_range_min || !value) return true;
            return value > salary_range_min;
          }
        )
        .required("Maximum Salary Must be Required"),
      industry: Yup.string().required("Select Industry"),
      job_description: Yup.string().required("Required"),
      education_details: Yup.string().required(
        "Education Details must be Required"
      ),
      number_of_positions: Yup.number().required(
        "Number of Positions Must be Required"
      ),

      contact_email_id: Yup.string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid Email")
        .required("Email is must be Required"),
      phone_number: Yup.number()
        .min(6000000000, "Not Valid Phone Number!")
        .max(9999999999, "Not Valid Phone Number!")
        .required("Phone number is required"),
      district: Yup.string().required("Select District"),
      pincode: Yup.string().required("Pincode Must be Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (chip.length !== 0) {
        formik.values.key_skills = chip.toString();
      }
      if (chip[0] === undefined) {
        formik.values.key_skills = "";
      }
      setIsSubmitting(true);
      const pincodeSplit = values.pincode.split("/");
      const [a, b] = pincodeSplit;
      values.pincode = a;
      values.area = b;
      const From = from.format("DD/MM/YYYY");
      const To = to.format("DD/MM/YYYY");
      const jobstatus = "Active";
      const data = {
        ...values,
        expiry_date_from: From,
        expiry_date_to: To,
        job_status: jobstatus,
      };
      const _id = getId;
      try {
        const response = await axiosData.put(`jobs/${_id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 201) {
          resetForm();
          jobs();
          setPostJopEditPopup(false);
          setIsSubmitting(false);
        } else if (response.status === 403) {
          setDeclined(true);
        }
      } catch (err) {
        if (err.response.status === 403) {
          localStorage.removeItem("user");
          setDeclined(true);
        }
      }
    },
  });
  const allLoadMore = () => {
    setPostedJobPagination(postedJobPagination + 10);
  };

  const allLoadLess = () => {
    setPostedJobPagination(postedJobPagination - 10);
  };
  const filteredJobs = (index, data) => {
    if (index === 1) {
      const get = data.filter((i) => i.job_status === "Active");
      if (get.length === 0) {
        setFilterJobDetails("");
      } else {
        setIndexTab(1);
        setFilterJobDetails(get);
      }
    } else if (index === 2) {
      const get = data.filter((i) => i.job_status === "InActive");
      if (get.length === 0) {
        setFilterJobDetails("");
        setPostJob(true);
      } else {
        setFilterJobDetails(get);

        setIndexTab(2);
      }
    } else {
      setIndexTab(0);
      setFilterJobDetails(data);
    }
  };
  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const handleCopyMethod = async (value) => {
    const From = from.format("DD/MM/YYYY");
    const To = to.format("DD/MM/YYYY");
    const jobstatus = "Active";
    if (
      postedJobData.no_of_free_posting <= 0 &&
      postedJobData.no_of_hot_posting <= 0 &&
      postedJobData.no_of_Standard_posting <= 0
    ) {
      setPostedJobSubcription(true);
      setIsSubmitting(false);
    } else {
      const data = {
        company_name: value.company_name,
        contact_email_id: value.contact_email_id,
        education_details: value.education_details,
        employer_mobile_number: value.employer_mobile_number,
        experience_max: value.experience_max,
        experience_min: value.experience_min,
        industry: value.industry,
        job_description: value.job_description,
        job_id: value.job_id,
        job_location: value.job_location,
        job_title: value.job_title,
        key_skills: value.key_skills,
        salary_type: value.salary_type,
        number_of_positions: value.number_of_positions,
        phone_number: value.phone_number,
        salary_range_max: value.salary_range_max,
        salary_range_min: value.salary_range_min,
        unique_code: value.unique_code,
        user_id: value.user_id,
        expiry_date_from: From,
        expiry_date_to: To,
        job_status: jobstatus,
        employer_email_id: userDetails.emailId,
      };
      try {
        const response = await axiosData.post(`jobs`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status == 201) {
          if (postedJobData.no_of_free_posting > 0) {
            await axiosData.put(
              `employers/email/${userDetails.emailId}`,
              { no_of_free_posting: postedJobData.no_of_free_posting - 1 },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
          if (value.need_type === "Urgent") {
            value.job_id = response.data._id;
            value.user_id = response.data.user_id;
            try {
              await axiosData.put(
                `employers/email/${userDetails.emailId}`,
                { no_of_hot_posting: postedJobData.no_of_hot_posting - 1 },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              const re = await axiosData.post("urgentjobs", data, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            try {
              await axiosData.put(
                `employers/email/${userDetails.emailId}`,
                {
                  no_of_Standard_posting:
                    postedJobData.no_of_Standard_posting - 1,
                },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
            } catch (err) {
              console.log(err);
            }
          }
          jobs();
          setCopyDialogBox(false);
          setIsSubmitting(false);
        }
      } catch (err) {
        if (err.response.status === 403) {
          setDeclined(true);
        }
        console.log(err);
      }
    }
  };
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const jobs = async () => {
    try {
      const response = await axiosData.get(
        `jobs/getbyEmail_id/${userDetails.emailId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data === "") {
        setLoading(false);
        setPostJob(true);
      } else {
        setFilterJobDetails(response.data.reverse());
        setJobDetails(response.data.reverse());
        const datasset = response.data.reverse();
        setLoading(false);
        filteredJobs(indexTab, datasset.reverse());
      }

      filteredJobs(indexTab, response.data.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      jobs();
    }
  }, []);
  const getOneData = async (_id) => {
    try {
      const getOneAxios = await axiosData.get(`jobs/getOne/${_id}`);
      const { data } = getOneAxios;
      data.expiry_date_from = new Date(
        data.expiry_date_from
      ).toLocaleDateString();
      data.expiry_date_to = new Date(data.expiry_date_to).toLocaleDateString();
      setGetOne(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleFromDateChange = (event) => {
    const newEndDate = moment(event);
    setFrom(newEndDate);
  };
  const handleEndDateChange = (event) => {
    const newEndDate = moment(event);
    setTo(newEndDate);
  };
  const deleteUser = async (getId) => {
    const _id = getId;
    try {
      await axiosData.delete(`jobs/${_id}`);
    } catch (err) {
      console.log("Error", err);
    }
    try {
      await axiosData.delete(`jobapply/user/${_id}`);
    } catch (err) {
      console.log("Error", err);
    }
    try {
      await axiosData.delete(`urgentjobs/${_id}`);
    } catch (err) {
      console.log("Error", err);
    }
    setPostJopPopup(false);
    jobs();
  };
  const closeJob = async (getId) => {
    const _id = getId;
    try {
      await axiosData.put(
        `jobs/expiryJobs`,
        { job_status: "InActive", _id: _id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log("Error", err);
    }
    try {
      await axiosData.put(
        `jobapply/user/${_id}`,
        { job_status: "InActive" },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log("Error", err);
    }
    if (getOne.need_type === "Urgent") {
      try {
        await axiosData.put(
          `urgentjobs/expiryJobs`,
          { job_status: "InActive", _id: _id },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.log("Error", err);
      }
    }
    setPostJopPopupClose(false);
    jobs();
  };
  const moreDetails = (description) => {
    setOpenMoreDetails(true);
    setJobDescription(
      jobDetails.find((item) => item.job_description === description)
    );
  };

  const handleSkill = (e) => {
    const { value } = e.target;
    const regex = /^[A-Za-z#+/.0-9 ]*$/;
    if (regex.test(value)) {
      setSkill(value);
    }
  };
  const addSkill = (Context) => {
    const lowercaseSkill = skill.toLowerCase();
    const lowercaseChip = chip.map((item) => item.toLowerCase());

    if (lowercaseChip.includes(lowercaseSkill)) {
      setSkill("");
      return;
    }

    chip.push(skill);
    setChip([...chip]);

    const uniqueArray = Array.from(new Set(chip.map((item) => item)));
    setChip(uniqueArray);

    const temp = uniqueArray.join(", ");
    if (temp) {
      formik.setFieldValue("key_skills", temp);
    }

    setSkill("");
  };
  const handleDelete = (index) => {
    chip.splice(index, 1);
    setChip([...chip]);
  };

  const CopyJob = (data) => {
    setCopyData(data);
    setCopyDialogBox(true);
    const From = moment(data.expiry_date_from, "DD/MM/YYYY");
    const To = moment(data.expiry_date_to, "DD/MM/YYYY");
    setFrom(From);
    setTo(To);
  };

  const postDataEdit = (data, _id) => {
    const value = `${data.pincode}/${data.area}`;
    data.pincode = value;
    setGetId(_id);
    setPostJopEditPopup(true);
    formik.setFieldValue("job_title", data.job_title);
    formik.setFieldValue("experience_type", data.experience_type);
    formik.setFieldValue("experience_min", data.experience_min);
    formik.setFieldValue("experience_max", data.experience_max);
    formik.setFieldValue("salary_range_min", data.salary_range_min);
    formik.setFieldValue("salary_range_max", data.salary_range_max);
    formik.setFieldValue("job_location", data.job_location);
    formik.setFieldValue("industry", data.industry);
    formik.setFieldValue("job_description", data.job_description);
    formik.setFieldValue("district", data.district);
    formik.setFieldValue("contact_email_id", data.contact_email_id);
    formik.setFieldValue("education_details", data.education_details);
    formik.setFieldValue("key_skills", data.key_skills);
    formik.setFieldValue("phone_number", data.phone_number);
    formik.setFieldValue("number_of_positions", data.number_of_positions);
    formik.setFieldValue("employer_mobile_number", data.employer_mobile_number);
    formik.setFieldValue("employer_email_id", userDetails.emailId);
    formik.setFieldValue("salary_type", data.salary_type);
    formik.setFieldValue("pincode", data.pincode);
    const From = moment(data.expiry_date_from, "DD/MM/YYYY");
    const To = moment(data.expiry_date_to, "DD/MM/YYYY");
    const str = data.key_skills;
    let temp = str.split(",");
    setChip([...temp]);
    setFrom(From);
    setTo(To);
  };
  if (loading === false) {
    return (
      <div className="main-div">
        <Dialog TransitionComponent={Transition} open={declined}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Declined alert !
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {
                "Your profile is declined by admin so please contact contactus@usglobalsolutions.com."
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeclined(false);
                localStorage.removeItem("user");
                navigate(`${ROUTINGDATA.LAND}`);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="arrow-link">
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "40%",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            <Tooltip
              arrow
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.EMPLOYERUPDATE,
                  {
                    state: userDetails.emailId,
                  }
                );
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>

            <div>
              <Typography id="postHeading">Posted Jobs</Typography>
            </div>
          </div>
        </div>

        <Box sx={{ bgcolor: "#a8cfdf", margin: "10px" }}>
          <AppBar sx={{ bgcolor: "background.paper" }} position="static">
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="secondary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label={
                  <span style={{ color: "black" }}>
                    ALL POSTED JOBS ({jobDetails.length})
                  </span>
                }
                // label={`ALL JOBS (${jobDetails.length})`}
                onClick={() => {
                  filteredJobs(0, jobDetails);
                  setPostedJobPagination(10);
                }}
              />
              <Tab
                label={
                  <span style={{ color: "black" }}>
                    ACTIVE POSTED JOBS ({count("Active")})
                  </span>
                }
                // label={`ACTIVE JOBS (${count("Active")})`}
                onClick={() => {
                  filteredJobs(1, jobDetails);
                  setPostedJobPagination(10);
                }}
              />
              <Tab
                label={
                  <span style={{ color: "black" }}>
                    EXPIRED POSTED JOBS ({count("InActive")})
                  </span>
                }
                //label={`EXPIRY JOBS (${count("InActive")})`}
                onClick={() => {
                  filteredJobs(2, jobDetails);
                  setPostedJobPagination(10);
                }}
              />
            </Tabs>
          </AppBar>
          <div className="cards">
            {filterJobDetails &&
              filterJobDetails
                .slice(0, postedJobPagination)
                .map((data, index) => (
                  // <TabPanel
                  //   sx={{ bgcolor: "background.paper", width: "100%" }}
                  //   value={value}
                  //   index={indexTab}
                  //   dir={theme.direction}
                  // >
                  <Card className="car" key={data._id}>
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: 600, marginTop: 3, flexGrow: 1 }}
                      >
                        {data.job_title}
                      </Typography>
                      {data.job_status === "InActive" ? (
                        <Chip
                          icon={<UpdateIcon />}
                          sx={{ ml: 1, marginTop: 1 }}
                          label="Expired"
                          size="small"
                          color="error"
                          variant="contained"
                        ></Chip>
                      ) : null}
                      <Tooltip title="Edit Job" arrow>
                        <IconButton
                          aria-label="Example"
                          sx={{ justifyContent: "end" }}
                          onClick={() => {
                            postDataEdit(data, data._id);
                            setGetId(data._id);
                          }}
                        >
                          <EditIcon sx={{ color: "#20bce7" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="More Details" arrow>
                        <IconButton
                          id="demo-positioned-button"
                          aria-controls={
                            open ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            handleClick(e, data);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <CardContent>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Company Name:</span>
                          <div>{data.company_name}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Experience:</span>
                          {data.experience_type === "Freshers Only" ? (
                            data.experience_type
                          ) : (
                            <div>
                              {data.experience_min} - {data.experience_max}
                            </div>
                          )}
                        </div>
                      </Typography>
                      {data.salary_type !== "None" && (
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Salary Range:</span>
                            <div>
                              {data.salary_range_min}- {data.salary_range_max}{" "}
                              {data.salary_type === "Month" ? (
                                <>Month</>
                              ) : data.salary_type === "Year" ? (
                                <>LPA</>
                              ) : null}
                            </div>
                          </div>
                        </Typography>
                      )}

                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Education Details:</span>
                          <div>{data.education_details}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Skills:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.key_skills}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Industry:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.industry}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Number of Vaccancy:</span>
                          <div>{data.number_of_positions} </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Date Range:</span>
                          <div>
                            {data.expiry_date_from} to {data.expiry_date_to}
                          </div>
                        </div>
                      </Typography>

                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Contact Email Id:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.contact_email_id}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Contact Number:</span>
                          <div>+91- {data.phone_number}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Location:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.job_location}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">District:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.district}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Pincode/Area:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.pincode}/{data.area}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">job Description:</span>
                          <div>
                            <Button
                              color="primary"
                              size="small"
                              sx={{ padding: 0 }}
                              onClick={() => {
                                moreDetails(data.job_description);
                              }}
                            >
                              View Description
                            </Button>
                          </div>
                        </div>
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ alignItems: "end" }}>
                      {/* <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                      setPostJopPopup(true);
                      getOneData(data._id);
                      setGetId(data._id);
                    }}
                  >
                    Delete
                  </Button> */}
                      {/* <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      moreDetails(data.job_description);
                    }}
                  >
                    More Details
                  </Button> */}
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                          navigate(
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.SEEKERSAPPLIED,
                            {
                              state: { id: data._id, title: data.job_title },
                            }
                          );
                        }}
                      >
                        Applicants
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => {
                          navigate(
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.AUDITING,
                            {
                              state: { id: data._id, title: data.job_title },
                            }
                          );
                        }}
                      >
                        Auditing
                      </Button>
                      {/* {data.job_status === "InActive" ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        CopyJob(data);
                      }}
                    >
                      Copy Job
                    </Button>
                  ) : null} */}
                    </CardActions>
                  </Card>
                  // </TabPanel>
                ))}
          </div>
          <div className="pagination">
            {postedJobPagination > 10 && (
              <Button variant="outlined" onClick={allLoadLess}>
                Load Less
              </Button>
            )}
            {postedJobPagination < filterJobDetails.length && (
              <Button variant="contained" onClick={allLoadMore}>
                Load More
              </Button>
            )}
          </div>
        </Box>

        <Dialog
          open={openMoreDetails}
          jobDescription={jobDescription}
          sx={{ "& .MuiPaper-root": { width: "500px" },zIndex:"99999" }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: jobDescription.job_description,
              }}
            ></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenMoreDetails(false)}>Ok</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postJopEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="alert-dialog-title">{"Job Portal"}</DialogTitle>
            <DialogContent>
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    disabled
                    id="job_title"
                    label="Job Title"
                    name="job_title"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (e) {
                        const value = e.target.value.replace(
                          /[^a-z#+/. ]/gi,
                          ""
                        );
                        formik.setFieldValue("job_title", value);
                      }
                    }}
                    value={formik.values.job_title}
                  />
                  {formik.touched.job_title && formik.errors.job_title ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.job_title}
                    </div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ marginY: 1 }}>
                    <b>Experience(years)</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="experience_type"
                    onChange={(e) => {
                      formik.setFieldValue("experience_min", "");
                      formik.setFieldValue("experience_max", "");
                      setExperienceType(e.target.value);
                      formik.setFieldValue("experience_type", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.experience_type}
                  >
                    <FormControlLabel
                      value="Freshers Only"
                      control={<Radio />}
                      label="Freshers Only"
                    />
                    <FormControlLabel
                      value="Experience"
                      control={<Radio />}
                      label="Experience"
                    />
                  </RadioGroup>
                </Grid>
                {formik.values.experience_type === "Experience" ? (
                  <>
                    {" "}
                    <Grid item xs={6} sx={{ marginBottom: 1.5 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Min
                        </InputLabel>

                        <Select
                          MenuProps={MenuProps}
                          labelId="demo-simple-select-label"
                          id="experience_min"
                          label="Min"
                          name="experience_min"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "experience_min",
                              e.target.value
                            );
                          }}
                          value={formik.values.experience_min}
                        >
                          {experience.map((data, index) => (
                            <MenuItem value={data} keys={index}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.experience_min &&
                      formik.errors.experience_min ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.experience_min}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Max
                        </InputLabel>

                        <Select
                          MenuProps={MenuProps}
                          labelId="demo-simple-select-label"
                          id="experience_max"
                          label="Max"
                          name="experience_max"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "experience_max",
                              e.target.value
                            );
                          }}
                          value={formik.values.experience_max}
                        >
                          {experience.map((data, index) => (
                            <MenuItem value={data} keys={index}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.experience_max &&
                      formik.errors.experience_max ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.experience_max}
                        </div>
                      ) : null}
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <Typography sx={{ marginY: 1 }}>
                    <b>Salary Range</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    defaultValue={formik.values.salary_type}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="salary_type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.salary_type}
                  >
                    <FormControlLabel
                      value="Month"
                      control={<Radio />}
                      label="Month"
                    />
                    <FormControlLabel
                      value="Year"
                      control={<Radio />}
                      label="Year"
                    />
                    {/* <FormControlLabel
                      value="None"
                      control={<Radio />}
                      label="None"
                    /> */}
                  </RadioGroup>
                </Grid>
                {formik.values.salary_type !== "None" && (
                  <>
                    {" "}
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Min
                        </InputLabel>
                        <OutlinedInput
                          margin="normal"
                          fullWidth
                          type="number"
                          id="salary_range_min"
                          label="Min"
                          variant="outlined"
                          name="salary_range_min"
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.salary_range_min}
                        />
                      </FormControl>
                      {formik.touched.salary_range_min &&
                      formik.errors.salary_range_min ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.salary_range_min}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Max
                        </InputLabel>
                        <OutlinedInput
                          margin="normal"
                          fullWidth
                          type="number"
                          id="salary_range_max"
                          label="Max"
                          variant="outlined"
                          name="salary_range_max"
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.salary_range_max}
                        />
                      </FormControl>
                      {formik.touched.salary_range_max &&
                      formik.errors.salary_range_max ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.salary_range_max}
                        </div>
                      ) : null}
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Educations Details
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="education_details"
                      label="Education Details"
                      name="education_details"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.education_details}
                    >
                      {eductionType.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.education_details &&
                  formik.errors.education_details ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.education_details}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <Box>
                    {chip.map((element, index) => {
                      if (element !== "") {
                        return (
                          <Chip
                            value={formik.values.key_skills}
                            onChange={formik.handleChange}
                            key={index}
                            label={element}
                            onDelete={() => handleDelete(index)}
                          />
                        );
                      }
                    })}
                  </Box>
                </Grid>

                <Grid item xs={8} sx={{ marginTop: 1 }}>
                  <TextField
                    fullWidth
                    id="Key Skills"
                    label="Key Skills"
                    name="key_skills"
                    value={skill}
                    onChange={handleSkill}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ p: 1.8, borderRadius: 5 }}
                    disabled={!skill}
                    onClick={() => {
                      addSkill(jobDetails);
                    }}
                  >
                    Add
                  </Button>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="job_location"
                    label="Job Location"
                    name="job_location"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (e) {
                        const value = e.target.value.replace(
                          /[^a-zA-Z ]/gi,
                          ""
                        );
                        formik.setFieldValue("job_location", value);
                      }
                    }}
                    value={formik.values.job_location}
                  />
                  {formik.touched.job_location && formik.errors.job_location ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.job_location}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1.5 }}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      disableClearable={formik.values.district ? false : true}
                      fullWidth
                      filterOptions={filterOptions}
                      name="district"
                      id="district"
                      value={formik.values.district}
                      onChange={(event, value) => {
                        formik.setFieldValue("district", value);
                        searchobj(value);
                        formik.setFieldValue("pincode", "");
                      }}
                      onBlur={formik.handleBlur}
                      options={districtData}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="District"
                          //onClick={() => params.inputProps.onInputClick()}
                          InputLabelProps={{
                            style: { marginLeft: "7px" },
                          }}
                        />
                      )}
                      onOpen={(event) => event.preventDefault()}
                      PopperComponent={({ children, ...popperProps }) => (
                        <Popper
                          {...popperProps}
                          placement="bottom-start"
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                          }}
                          PopperProps={{
                            placement: "bottom-start", // Placement set to "bottom-start"
                          }}
                        >
                          <Paper>{children}</Paper>
                        </Popper>
                      )}
                      ListboxProps={{
                        style: { maxHeight: "180px", overflowY: "auto" },
                      }}
                    />
                    {formik.touched.district && formik.errors.district ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.district}
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip
                    title={
                      formik.values.district ? "" : "please Select District"
                    }
                  >
                    <FormControl fullWidth required sx={{ marginY: 2 }}>
                      <Autocomplete
                        disableClearable={formik.values.pincode ? false : true}
                        disablePortal
                        freeSolo
                        id="pincode"
                        name="pincode"
                        disabled={
                          formik.values.district && !pinLoad ? false : true
                        }
                        options={comdata}
                        value={formik.values.pincode}
                        onChange={(event, value) => {
                          formik.setFieldValue("pincode", value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            required
                            // onChange={formik.handleChange}
                            {...params}
                            label="Pincode"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {pinLoad ? (
                                    <CircularProgress
                                      // size={24}
                                      style={{
                                        width: "1.4rem",
                                        height: "1.4rem",
                                        color: "#4991dd",
                                      }}
                                    />
                                  ) : (
                                    <ShareLocationRoundedIcon
                                      sx={{
                                        color: formik.values.district
                                          ? "#414141"
                                          : "#bdbdbd",
                                      }}
                                    />
                                  )}{" "}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />

                      {/* <TextField
                        margin="normal"
                        fullWidth
                        name="pincode"
                        label={t("Pincode")}
                        type="Number"
                        id="pincode"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlurCapture={() => {
                          setFocusPin(true);
                        }}
                        placeholder={t("Enter_Pincode(Optional)")}
                        onBlur={formik.handleBlur}
                        onChange={handleChangePincode}
                        value={formik.values.pincode}
                      /> */}
                      {formik.touched.pincode && formik.errors.pincode ? (
                        <div style={{ color: "red", fontSize: "15px" }}>
                          {formik.errors.pincode}
                        </div>
                      ) : null}
                    </FormControl>
                  </Tooltip>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    name="pincode"
                    label="Pincode"
                    type="Number"
                    id="pincode"
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onBlurCapture={() => {
                      setFocusPin(true);
                    }}
                    onBlur={formik.handleBlur}
                    onChange={handleChange1}
                    value={formik.values.pincode}
                  />
                  {formik.touched.pincode &&
                  formik.errors.pincode &&
                  focusPin ? (
                    <div style={{ color: "red" }}>{formik.errors.pincode}</div>
                  ) : null}
                </Grid> */}
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  {industryArray && (
                    <FormControl sx={{ mt: 1, mb: 1 }} fullWidth required>
                      <Autocomplete
                        disabled
                        disableClearable={formik.values.industry ? false : true}
                        filterOptions={filterOptions}
                        id="industry"
                        name="industry"
                        options={industryArray}
                        onBlur={formik.handleBlur}
                        onChange={(event, value) => {
                          setSelectedIndustry(value);
                          formik.setFieldValue("industry", value);
                        }}
                        value={formik.values.industry}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            id="industry"
                            label="Preffered Industry"
                            name="industry"
                            // onClick={() => params.inputProps.onInputClick()}
                          />
                        )}
                        onOpen={(event) => event.preventDefault()}
                        PopperComponent={({ children, ...popperProps }) => (
                          <Popper
                            {...popperProps}
                            placement="bottom-start"
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent",
                              },
                            }}
                            PopperProps={{
                              placement: "bottom-start", // Placement set to "bottom-start"
                            }}
                          >
                            <Paper>{children}</Paper>
                          </Popper>
                        )}
                        ListboxProps={{
                          style: { maxHeight: "180px", overflowY: "auto" },
                        }}
                      />
                    </FormControl>
                  )}
                  {formik.touched.industry && formik.errors.industry ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.industry}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 3 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Number of Positions
                    </InputLabel>
                    <OutlinedInput
                      disabled
                      margin="normal"
                      fullWidth
                      type="number"
                      id="number_of_positions"
                      label="Number of Positions"
                      variant="outlined"
                      name="number_of_positions"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.number_of_positions}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                    />
                  </FormControl>
                  {formik.touched.number_of_positions &&
                  formik.errors.number_of_positions ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.number_of_positions}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
                    <b>Date Range</b>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled
                      className="ddd"
                      label="From"
                      name="cals"
                      format="DD/MM/YYYY"
                      value={from}
                      onChange={(e) => {
                        handleFromDateChange(e);
                      }}
                    />
                  </LocalizationProvider>
                  {from >= to ? (
                    <Typography sx={{ color: "red" }}>
                      From date must be before To date
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled
                      className="ddd"
                      label="To"
                      name="cals"
                      format="DD/MM/YYYY"
                      value={to}
                      onChange={(e) => {
                        handleEndDateChange(e);
                      }}
                    />
                  </LocalizationProvider>
                  {to < today ? (
                    <Typography sx={{ color: "red" }}>
                      This is past date
                    </Typography>
                  ) : from >= to ? (
                    <Typography sx={{ color: "red" }}>
                      To date must be after From date
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="contact_email_id"
                    label="Contact Email Id"
                    name="contact_email_id"
                    onBlurCapture={() => {
                      setfocus(true);
                    }}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      setfocus(false);
                      const value = e.target.value.replace(/[^a-z0-9@.]/gi, "");
                      formik.setFieldValue("contact_email_id", value);
                    }}
                    value={formik.values.contact_email_id}
                  />
                  {formik.touched.contact_email_id &&
                  formik.errors.contact_email_id &&
                  focus ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.contact_email_id}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1.5 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    type="number"
                    id="phone_number"
                    label="Phone Number"
                    name="phone_number"
                    onBlur={formik.handleBlur}
                    onChange={handleChange}
                    value={formik.values.phone_number}
                    placeholder="Enter mobile number"
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onBlurCapture={() => {
                      setFocusNo(true);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <b>+91</b>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.phone_number &&
                  formik.errors.phone_number &&
                  focusNo ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1 }}>
                  <ReactQuill
                    name="job_description"
                    value={formik.values.job_description}
                    onChange={(v) => formik.setFieldValue("job_description", v)}
                    // onBlur={formik.handleBlur}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ["clean"],
                      ],
                    }}
                    placeholder="Write something amazing..."
                    className="scrollable-container-postjob"
                  />
                  {formik.touched.job_description &&
                  formik.errors.job_description ? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {formik.errors.job_description}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  formik.handleReset("");
                  setPostJopEditPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  !isSubmitting && from < to && to > today ? false : true
                }
              >
                Update
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={postJopPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Job deletion confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              Are you sure, you want to delete the data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPostJopPopup(false)}>No</Button>
            <Button onClick={() => deleteUser(getId)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postJopPopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Job closing confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              By clicking 'Yes' this job post will expire. Are you sure you want
              to close it?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPostJopPopupClose(false)}>No</Button>
            <Button onClick={() => closeJob(getId)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postJob}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          sx={{zIndex:"99999"}}
        >
          <DialogTitle
            id="alert-dialog-title"
            // bgcolor="rgb(16 143 178)"
            sx={{ color: "black", fontWeight: 600, textAlign: "center" }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <img style={{ paddingLeft: "25px" }} src={img} alt=""></img>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "center", color: "black", fontSize: "16px" }}
            >
              No job posted yet
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTJOB,
                  {
                    state: userDetails.userId,
                  }
                );
                setPostJob(false);
              }}
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          copyData={copyData}
          open={copyDialogBox}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Typography variant="h5">Date Only Changed</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {/* <Grid container> */}
              <Grid item xs={12}>
                <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
                  <b>Date Range</b>
                </Typography>
              </Grid>
              <Grid item sx={{ marginBottom: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disablePast
                    className="ddd"
                    label="From"
                    name="cals"
                    format="DD/MM/YYYY"
                    value={from}
                    onChange={(e) => {
                      handleFromDateChange(e);
                    }}
                  />
                </LocalizationProvider>
                {from >= to ? (
                  <Typography sx={{ color: "red" }}>
                    From date must be before To date
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disablePast
                    className="ddd"
                    label="To"
                    name="cals"
                    format="DD/MM/YYYY"
                    value={to}
                    onChange={(e) => {
                      handleEndDateChange(e);
                    }}
                  />
                </LocalizationProvider>
                {to < today ? (
                  <Typography sx={{ color: "red" }}>
                    This is past date
                  </Typography>
                ) : from >= to ? (
                  <Typography sx={{ color: "red" }}>
                    To date must be after From date
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              {/* </Grid> */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                formik.handleReset("");
                setCopyDialogBox(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              size="small"
              disabled={from < to && to > today ? false : true}
              onClick={() => {
                handleCopyMethod(copyData);
              }}
            >
              Paste
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postedJobSubcription}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Subscribe
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              You want proceed job post , please Subscribe
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPostedJobSubcription(false);
                formik.handleReset();
                setFrom(moment());
                setTo();
                setSkill("");
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.SUBSCRIPTIONS,
                  {
                    state: userDetails.userId,
                  }
                );
              }}
              autoFocus
              size="small"
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>

        <Menu
          elevation={3}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top :",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Tooltip title="Delete Job" arrow>
            <MenuItem
              onClick={() => {
                handleClose();
                setPostJopPopup(true);
                getOneData(Tempdata._id);
                setGetId(Tempdata._id);
              }}
            >
              Delete
            </MenuItem>
          </Tooltip>
          {Tempdata.job_status !== "InActive" ? (
            <Tooltip title="Close Job" arrow>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setPostJopPopupClose(true);
                  getOneData(Tempdata._id);
                  setGetId(Tempdata._id);
                }}
              >
                Close
              </MenuItem>
            </Tooltip>
          ) : null}
          {Tempdata.job_status === "InActive" ? (
            <MenuItem
              onClick={() => {
                handleClose();
                CopyJob(Tempdata);
              }}
            >
              Copy Job
            </MenuItem>
          ) : null}
        </Menu>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default PostedJob;
