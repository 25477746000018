import React from "react";
import {
  Box,
  Card,
  Grid,
  styled,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import CountUp from "react-countup";
import {
  HourglassFullRounded as HourglassFullRoundedIcon,
  AddTaskRounded as AddTaskRoundedIcon,
} from "@mui/icons-material";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "18px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: ".25rem",

    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    transition: "0.2s",
  },
  "&.MuiCard-root:hover": {
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.4) !important",
  },
}));

function StatCardForNeedSupport({ state, ind }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const MProute =
    ROUTINGDATA.LAND + ROUTINGDATA.MPConstituency + ROUTINGDATA.MPDASHBOARD;
  const MLAroute =
    ROUTINGDATA.LAND + ROUTINGDATA.MLAconstituency + ROUTINGDATA.MLAdashboard;
  const pendingList = state.needSupport.filter((item) => item.shortList === "");

  const supportedList = 0;

  const [total, pending, shortlist, supported] = [
    state.needSupport,
    pendingList,
    state.shortListed,
    state.supported,
  ];

  const cardList = [
    {
      name: "Total Need Support",
      count: total.length,
      bgColor: "#1976D2",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#1976D2",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#1976D2",
              color: "#ffffff",
            },
          }}
        >
          <HourglassFullRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: "all",
    },

    {
      name: "Review pending",
      count: pending.length,
      bgColor: "#FFB300",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#FFB300",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#FFB300",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: "Pending",
    },
    {
      name: "Shortlisted Data",
      count: shortlist.length,
      bgColor: "#6a1b9a",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#6a1b9a",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#6a1b9a",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue:
        currentUrl == MProute ? "MP" : currentUrl == MLAroute ? "MLA" : "",
    },
    {
      name: "Supported Data",
      count: supported.length,
      bgColor: "#2e7d32",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#2e7d32",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#2e7d32",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue:
        currentUrl == MProute ? "MP" : currentUrl == MLAroute ? "MLA" : "",
    },
  ];
  const handleNeedAllClick = (event) => {
    sessionStorage.setItem("mpneedall_active", event);
    sessionStorage.removeItem("mpneedshort_activ");
    sessionStorage.removeItem("mpall_active");
  };
  const handleClick = (event) => {
    if (currentUrl == MLAroute) {
      sessionStorage.setItem("active", event);
      sessionStorage.removeItem("mpmlaneedshort_active");
      sessionStorage.removeItem("mpmlaneedall_active");
      sessionStorage.removeItem("activeneed");
    } else {
      sessionStorage.setItem("mp_active", event);

      if (event === "dash") {
        sessionStorage.removeItem("mpneed_active");
        sessionStorage.removeItem("mpall_active");
        sessionStorage.removeItem("mpneedshort_active");
        sessionStorage.removeItem("mpneedall_active");
      }
    }
  };
  const handleNeedClick = (event) => {
    sessionStorage.setItem("mpneed_active", event);
    sessionStorage.removeItem("mpall_active");
  };
  const handleClickLog = (event) => {
    sessionStorage.setItem("activeneed", event);
    sessionStorage.removeItem("active");
  };

  const handleAllClick = (event, ind) => {
    if (ind === 3) {
      sessionStorage.setItem("mpmlaneedsupport_active", event);
      sessionStorage.removeItem("mpmlaneedshort_active");
      sessionStorage.removeItem("mpmlaneedall_active");
      sessionStorage.removeItem("active");
    } else {
      sessionStorage.setItem("mpmlaneedall_active", event);
      sessionStorage.removeItem("mpmlaneedshort_activ");
      sessionStorage.removeItem("active");
      sessionStorage.removeItem("mpmlaneedsupport_activ");
    }
  };
  const handleSupportClick = (event) => {
    sessionStorage.setItem("mpmlaneedsupport_activ", event);
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
    sessionStorage.removeItem("mpmlaneedshort_activ");
  };
  const handleShortClick = (event) => {
    sessionStorage.setItem("mpmlaneedshort_active", event);
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
    sessionStorage.removeItem("mpmlaneedsupport_active");
  };
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Tooltip
              title={
                currentUrl == MProute || currentUrl == MLAroute
                  ? "Go to this filter"
                  : ""
              }
              arrow
            >
              <StyledCard
                sx={{ background: "#ffffff", cursor: "pointer" }}
                elevation={6}
                onClick={() => {
                  if (currentUrl == MProute) {
                    // sessionStorage.setItem(`mpmlaneedradio${ind}`, null);
                    // sessionStorage.setItem(
                    //   `shortradio${ind}`,
                    //   item.filterValue
                    // );
                    if (index === 3) {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MPConstituency +
                          "/" +
                          ROUTINGDATA.MP_MLASupported,
                        {
                          state: {
                            mla: state,
                          },
                        }
                      );
                      handleSupportClick(`mpneedsupport${ind}`);
                      handleClick(`mp${ind}`);
                      handleNeedClick(`mpneed${ind}`);
                    } else if (index === 2) {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MPConstituency +
                          "/" +
                          ROUTINGDATA.MP_MLAShortlisted,
                        {
                          state: {
                            mla: state,
                          },
                        }
                      );
                    } else {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MPConstituency +
                          "/" +
                          ROUTINGDATA.MP_MLAneedSupport,
                        {
                          state: {
                            mla: state,
                            [item.filterValue === "all"
                              ? "typesOfSupportFilterValue"
                              : "needSupportFilterValue"]: item.filterValue,
                            index: ind,
                          },
                        }
                      );
                      handleNeedAllClick(`mpneedall${ind}`);
                      handleClick(`mp${ind}`);
                      handleNeedClick(`mpneed${ind}`);
                    }
                  } else if (currentUrl == MLAroute) {
                    // sessionStorage.setItem(`shortradio`, item.filterValue);
                    if (index == 3) {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MLAconstituency +
                          "/" +
                          ROUTINGDATA.MLASupported
                      );
                      handleClickLog("need");
                      handleAllClick(`mpmlasupported`, index);
                    } else if (index === 2) {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MLAconstituency +
                          "/" +
                          ROUTINGDATA.MLAShortlisted
                      );
                      handleClickLog("need");
                      handleShortClick("mpmlashort");
                    } else {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MLAconstituency +
                          "/" +
                          ROUTINGDATA.MLAneedSupport,
                        {
                          state: {
                            needSupportFilterValue: item.filterValue,
                            typesOfSupportFilterValue: null,
                          },
                        }
                      );
                      handleClickLog("need");
                      handleAllClick(`mpMLAall`);
                    }
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ color: "white" }}
                    display={"flex"}
                    alignItems={"center"}
                    // bgcolor={item.bgColor}
                  >
                    {item.svg}
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "black",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Poppins-SemiBold", color: "#00000" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatCardForNeedSupport;
