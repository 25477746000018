import Footer from "./skeleton/Footer";
import { Container, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import "../components/Main.css";
import Header from "./skeleton/Header";

function Main(props) {
  const currentScrollPosition =
    document.documentElement.scrollTop || document.body.scrollTop;

  return (
    <div className="App">
      <div className="header">
        <Header setlanguagetranslator={props.setlanguagetranslator} />
      </div>
      <div className="contected">
        <Container className="container" fixed sx={{ height: "100%" }}>
          <Outlet />
        </Container>
      </div>

      {/* <div className="footer">
        <Footer />
      </div> */}
    </div>
  );
}

export default Main;
