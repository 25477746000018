import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import useAxios from "../../../api/useAxios";
import TextField from "@mui/material/TextField";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import logo from "../../../img/logo_for-landingpage-3.png";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";

import { ReactComponent as TwitterX } from "../../../img/twitter-x-seeklogo.com-4.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

const LandingpageContactus = () => {
  const [open, setopen] = useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [focusEmail, setFocusEmail] = useState(false);
  const [focusNo, setFocusNo] = useState(false);

  const handleClick = (newState) => () => {
    setopen(true);
    setState({ ...newState });
  };

  const handleClose = () => {
    setopen(false);
    setState({ ...state });
  };
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      full_name: "",
      email_id: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("FullName_must_be_Required")),
      mobile_number: Yup.number()
        .min(1000000000, t("Not_Valid_Mobile_Number!"))
        .max(9999999999, t("Not_Valid_Mobile_Number!"))
        .required(t("Mobile_Number_must_be_Required")),
      email_id: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          t("Invalid_email_format")
        )
        .required(t("Email_is_must_be_Required")),
      subject: Yup.string().required(t("subject_must_be_Required")),
      message: Yup.string().required(t("message_requried")),
    }),
    onSubmit: async (values) => {
      const response = await axiosData.post("General/contactus", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === 200) {
        setopen(true);
        handleClick({ vertical: "bottom", horizontal: "center" });
      }
      formik.resetForm();
    },
  });

  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div>
        <div className="herotextimg-1">
          <div className="maintextforhero-1">
            <div className="Contactuspage-center">
              <h2>
                {t("Call-To-Action")}
                {t("us")}
              </h2>
            </div>
          </div>
        </div>
        <div className="contactusbackpage">
          <div className="contactusbackpagefonts">
            <span
              className="backbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#135c82" }}
            >
              {t("Home")}
            </span>
            /{" "}
            <span style={{ color: "black" }}>
              {t("Call-To-Action")}
              {t("us")}
            </span>
          </div>
        </div>

        <div className="contactpage-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6143837830923!2d80.19310057398044!3d13.060199912931836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266b6e31bec61%3A0x3279e4cbf4f17af3!2sUS%20Global%20Solutions!5e0!3m2!1sen!2sin!4v1688541507709!5m2!1sen!2sin"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="contactusform">
            <div className="contactusformdetails">
              <div className="detailscontact">
                <div className="contacticonbox">
                  <div>
                    <LocationOnIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("Location")}:</h4>
                    <p>{t("address")}</p>
                  </div>
                </div>
                <div className="contacticonbox">
                  <div>
                    <EmailIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("Email")}:</h4>
                    <p>contactus@usglobalsolutions.com</p>
                  </div>
                </div>
                <div className="contacticonbox">
                  <div>
                    <SmartphoneIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("call")}:</h4>
                    <p>+91 9080400330</p>
                  </div>
                </div>
                <div className="contacticonbox">
                  <div>
                    <AccessTimeIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("open_hours")}:</h4>
                    <p>{t("Mon_Sat")}</p>
                  </div>
                </div>
              </div>
              <div className="inputsforcontactdetails">
                <div className="centerpace">
                  <div className="spacet0p">
                    {" "}
                    <TextField
                      id="outlined-basic"
                      label={t("your_name")}
                      variant="outlined"
                      name="full_name"
                      error={
                        formik.touched.full_name && formik.errors.full_name
                      }
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const name = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("full_name", name);
                        }
                      }}
                      value={formik.values.full_name}
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                          ? formik.errors.full_name
                          : null
                      }
                      fullWidth
                    />
                    <TextField
                      id="filled-basic"
                      label={t("Email")}
                      variant="outlined"
                      error={
                        formik.touched.email_id &&
                        formik.errors.email_id &&
                        focusEmail
                      }
                      name="email_id"
                      onBlur={formik.handleBlur}
                      onBlurCapture={() => {
                        setFocusEmail(true);
                      }}
                      onChange={(e) => {
                        setFocusEmail(false);
                        const value = e.target.value.replace(
                          /[^a-z0-9@.]/gi,
                          ""
                        );
                        formik.setFieldValue("email_id", value);
                      }}
                      value={formik.values.email_id}
                      helperText={
                        formik.touched.email_id &&
                        formik.errors.email_id &&
                        focusEmail
                          ? formik.errors.email_id
                          : null
                      }
                      fullWidth
                    />
                  </div>
                  <div className="spaced-1-1">
                    <TextField
                      id="filled-basic"
                      label={t("phone_number")}
                      variant="outlined"
                      type="number"
                      error={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number &&
                        focusNo
                      }
                      name="mobile_number"
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onBlurCapture={() => {
                        setFocusNo(true);
                      }}
                      onBlur={formik.handleBlur}
                      onChange={handleChange}
                      value={formik.values.mobile_number}
                      helperText={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number &&
                        focusNo
                          ? formik.errors.mobile_number
                          : null
                      }
                      fullWidth
                    />
                  </div>
                  <div className="space-1">
                    <TextField
                      id="filled-basic"
                      label={t("subject")}
                      variant="outlined"
                      error={formik.touched.subject && formik.errors.subject}
                      name="subject"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.subject}
                      helperText={
                        formik.touched.subject && formik.errors.subject
                          ? formik.errors.subject
                          : null
                      }
                      style={{
                        color:
                          formik.touched.subject && formik.errors.subject
                            ? "red"
                            : "inherit",
                      }}
                      fullWidth
                    />
                  </div>
                  <div className="space-2">
                    <TextField
                      id="outlined-multiline-static"
                      label={t("message")}
                      multiline
                      error={formik.touched.message && formik.errors.message}
                      name="message"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      fullWidth
                      helperText={
                        formik.touched.message && formik.errors.message
                          ? formik.errors.message
                          : null
                      }
                      style={{
                        color:
                          formik.touched.message && formik.errors.message
                            ? "red"
                            : "inherit",
                      }}
                      rows={5}
                    />
                  </div>
                  <div className="space-3">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={formik.isValid && formik.dirty ? false : true}
                    >
                      {t("send_message")}
                    </Button>
                  </div>
                  <div
                    className={
                      open ? "backsideofcenterpace" : "backsideofcenterpace-1"
                    }
                  >
                    <div class="backsideofcenterpace-text">
                      <text
                        style={{
                          padding: "10px",
                          fontFamily: "Barlow-Bold",
                          fontWeight: "600",
                          color: "#0675a2",
                          fontSize: "25px",
                          textAlign: "center  ",
                        }}
                      >
                        {t("Thanks_for_contacting_us")}
                        <br></br>
                        {t("We_will_be_in_touch_with_you_shortly")}
                      </text>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setopen(false);
                        }}
                      >
                        {t("ok")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div id="footer" className="footer-landing">
          <div className="footer-contectbox">
            <div className="footersidecontect">
              <div className="footerlogo footerbox">
                <div className="headerlogoimg-footer">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND);
                    }}
                    src={logo}
                    alt="img"
                  />
                </div>
                <div>
                  <p>{t("Sub_Title")}</p>
                </div>
                <div className="footermediaicons">
                  <div className="connectwithus">{t("Connect_with_us")}</div>
                  <div className="footer-landing-icon">
                    <div className="footer-icon">
                      <a
                        href={process.env.REACT_APP_LINKEDIN_URL}
                        target="_blank"
                      >
                        <LinkedInIcon
                          className="footer-icon-hover-5"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#0077b5",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_YOUTUBE_URL}
                        target="_blank"
                      >
                        <YouTubeIcon
                          className="footer-icon-hover-2"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ff0000",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        target="_blank"
                      >
                        <FacebookIcon
                          className="footer-icon-hover-1"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#1877f2",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a href={process.env.REACT_APP_TWITTER_URL} target="_blank">
                        <TwitterX
                          className="footer-icon-hover-3"
                          style={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "white",
                            fontSize: "25px",
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_INSTAGRAM_URL}
                        target="_blank"
                      >
                        <InstagramIcon
                          className="footer-icon-hover-4"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ed1067",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h3>{t("legal")}</h3>
                    </div>
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      className="legalFooter"
                    >
                     {t("terms_conditions")}
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                       {t("privacy_policy")} 
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PurchasePolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      {t("purchase_policy")}
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.RefundPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                     {t("refund_policy")}  
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.DeliveryPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                     {t("delivery_policy")}  
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PricingPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      {t("pricing_policy")} 
                    </text>
                  </div>
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("quick_links")}</h5>
                </div>
                <div className="footerlinks">
                  <text
                    onClick={() => {
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`,
                        {}
                      );
                    }}
                  >
                    <ArrowRightIcon />
                    {t("JOB_SEEKER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("EMPLOYER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.VOLUNTEER}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("VOLUNTEER")}
                  </text>
                  {/* <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTOR}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("MENTOR")}
                  </text> */}
                  <text
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAPARTNER);
                    }}
                  >
                    <ArrowRightIcon />
                    {t("become_partner")}
                  </text>
                  <text
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAINVESTOR);
                    }}
                  >
                    <ArrowRightIcon />
                    {t("become_investor")} 
                  </text>
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("Call-To-Action-1")}</h5>
                </div>
                <div className="footerlinks-1">
                  <text>{t("address")}</text>
                  <text>
                    {" "}
                    {t("phone")}:<br></br>+91 9080400330
                  </text>
                  <text> {t("Email")}: contactus@usglobalsolutions.com</text>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>
              &copy;{new Date().getFullYear()}
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: "400",
                  color: "#0976d3",
                }}
              >
                <a
                  style={{
                    textDecoration: "underline",
                    fontWeight: "400",
                    color: "#0976d3",
                    marginLeft: "5px",
                  }}
                  href="https://usglobalsolutions.com/"
                  target="blank"
                >
                  US Global Solutions
                </a>
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  marginLeft: "5px",
                  marginRight: "3px",
                }}
              >
                |
              </span>{" "}
              {t("All_Rights_Reserved")}
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default LandingpageContactus;
