import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Chip,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  Skeleton,
} from "@mui/material";
import useAxios from "../../../api/useAxios";

import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  VisibilityRounded as VisibilityRoundedIcon,
} from "@mui/icons-material/";

import { saveAs } from "file-saver";
import defaultImage from "../../../img/mentorImage.png";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import MentorDialog from "./DialogBoxes/MentorDialog";
import moment from "moment/moment";

import StatCards from "./StatCards/StatCards";

import "jspdf-autotable";
import Breadcrumb from "../../Dashboards/BreadCrumb";
import SkeletonMlaCard from "./StatCards/SkeletonMlaCard";
import ExportCS from "./ExportComponents/ExportCS";
import ExportPDF from "./ExportComponents/ExportPDF";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";
import AutoApproveSnack from "./SnackBars/AutoApproveSnack";

function MentorAdminData() {
  const navigate = useNavigate();
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [decide, setDecide] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });

  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const snack = JSON.parse(sessionStorage.getItem("mentor-setting"));

  const [openSnack, setOpenSnack] = useState(snack);
  // const setGe
  const { getAdminSetting } = useContext(CurrentStateContext);
  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [searchdata, setsearchdata] = useState("");
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [dateenable, setdateenable] = useState(false);
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [isFocused, setIsFocused] = useState({
    startDate: false,
    endDate: false,
  });
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  // <!---DATEINPUTSORT--->
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const [searchicon, setsearchicon] = useState(false);

  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const sessiondate = sessionStorage.getItem("admin_mentor_date");
  const sessionstatus = sessionStorage.getItem("admin_mentor_status");
  const adminSeekerSearch = sessionStorage.getItem("admin_mentor_search");
  const pagenumbe = sessionStorage.getItem("admin_mentor_page");
  const pagenumber = parseInt(pagenumbe);
  const selectedApiForExcel = useApi.map((i) => {
    const htmlContent = i.Experience;
    const parser = new DOMParser();

    // Parse the HTML content
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Extract the text content from the parsed document
    const plainText = doc.body.textContent.trim();
    return {
      "Full Name": i.MentorName,
      Email: i.email_id,
      Mobile: i.mobileNumber,
      "Educational Qualification": i.educationQualifications,
      Specialization: i.Specialisation,
      "Official Address": i.officialAddress,
      "Area Of Expertise": i.areaOfExpertise,
      LinkedIn: i.linkedInLink,
      "Other Specification": i.OtherSpecify,
      "Years Of Experience": i.yearsOfExperience,
      "Current Employer": i.currentEmployer,
      Designation: i.Designation,
      "Membership in Professional": i.membershipInProfessional,
      "Professional Certification": i.professionalCertifications,
      Experience: plainText,
      "Created Date": i.createdDate,
    };
  });
  const headers = [
    [
      "Full Name",
      "Email",
      "Mobile",

      "Qualification",
      "Specialization",
      "Years of Experience",
      "Area of Expertise",

      "Created Date",
      // "Current Employer",
      // "Designation",
    ],
  ];
  const datas = useApi.map((user) => {
    return [
      user.MentorName,
      user.email_id,
      user.mobileNumber,

      user.educationQualifications,
      user.Specialisation,
      user.yearsOfExperience,
      user.areaOfExpertise,
      user.createdDate,
      // user.currentEmployer,
      // user.Designation,
    ];
  });
  const handleChange = () => {
    setOpen(!open);
    setGetOne({});
    setLoading({ ...loading, dialogLoad: true });
  };
  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->

  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("mentor");
      const { data } = useAxiosData;
      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        data.reverse();
        let bool = false;
        if (adminSeekerSearch !== null) {
          bool = adminSeekerSearch.length > 0;
        }
        if (true === bool) {
          searchdatas(adminSeekerSearch, data);
          setGetApi(data);
          setsearchicon(true);
        } else {
          setGetApi(data);
          getFilterValue(
            sessionstatus ? sessionstatus : filterName.status,
            sessiondate ? sessiondate : filterName.date,
            data
          );
        }
      }

      // setGetApi(data);

      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleStartFocusChange = () => {
    setIsFocused({ ...isFocused, startDate: true });
  };
  const handleEndFocusChange = () => {
    setIsFocused({ ...isFocused, endDate: true });
  };
  const handleStartDateBlurChange = () => {
    setIsFocused({ ...isFocused, startDate: false });
    if (!startDate) {
      const today = moment();

      setStartDate(today);
    }
  };
  const handleEndDateBlurChange = () => {
    setIsFocused({ ...isFocused, endDate: true });
    if (!endDate) {
      const today = moment();
      setEndDate(today);
    }
  };
  // <!-----API FUNCTIONS---->
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`mentor/email/${id}/users`);
      const { data } = getOneAxios;

      setGetOne(data);
      setLoading({ ...loading, dialogLoad: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const minDate = moment("01-06-2023", "DD-MM-YYYY");
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  // <!----FILE-DOWNLOAD-->
  const downloadResume = (id) => {
    saveAs(`${baseUrl}mentor/get/cv/${id}`, id + "'s CV");
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      // return valueB - valueA;
      // Sort the rows with the same value in the primary column before others
      if (valueA === valueB) {
        return -1;
      }
      // Sort the rows based on the selected column
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (statusvalue, value, alldatas) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      statusForAll(statusvalue, yes);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.createdDate, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas);
    }
  };
  // const n=["\"]
  const statusForAll = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    } else if (
      statusvalue === "A" ||
      statusvalue === "P" ||
      statusvalue === "D"
    ) {
      const today = get.filter((i) => i.status === statusvalue);
      const data = today;
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    }
  };
  // const handleStartDateChange = (event) => {
  //   // const newStartDate = moment(event);

  //   // const yearOfStart = newStartDate.year();
  //   // setStartDate(newStartDate);
  //   // const isStartDateBeforeEndDate = newStartDate.isBefore(endDate);
  //   // const isStartDateAfterEndDate = newStartDate.isAfter(endDate);
  //   // if (
  //   //   isStartDateBeforeEndDate === true &&
  //   //   isStartDateAfterEndDate === false &&
  //   //   yearOfStart > 2022
  //   // ) {
  //   //   setStartDate(newStartDate);
  //   //   setdisabled(false);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: " ",
  //   //     openStartTool: false,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === false &&
  //   //   newStartDate === endDate &&
  //   //   newStartDate !== endDate &&
  //   //   yearOfStart > 2022
  //   // ) {
  //   //   setStartDate(newStartDate);
  //   //   setdisabled(false);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: " ",
  //   //     openStartTool: false,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === false
  //   // ) {
  //   //   setStartDate(newStartDate);
  //   //   setdisabled(false);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: " ",
  //   //     openStartTool: false,
  //   //     openTooltip: false,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === true &&
  //   //   isStartDateAfterEndDate === false &&
  //   //   yearOfStart < 2000
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: "Start date is too old",
  //   //     openStartTool: true,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === true &&
  //   //   newStartDate !== endDate &&
  //   //   yearOfStart > 2022
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: "Start date must be before the end date",
  //   //     openStartTool: true,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === true &&
  //   //   newStartDate !== endDate &&
  //   //   yearOfStart > 2022
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     startDate: "",
  //   //     openStartTool: true,
  //   //   });
  //   // }
  // };
  // const handleEndDateChange = (event) => {
  //   // const newEndDate = moment(event);
  //   // const todayDate = moment();
  //   // const yearOfEnd = newEndDate.year();
  //   // setEndDate(newEndDate);

  //   // const isStartDateBeforeEndDate = newEndDate.isBefore(startDate);
  //   // const isStartDateAfterEndDate = newEndDate.isAfter(startDate);
  //   // const istodayDateAfterEndDate = newEndDate.isAfter(todayDate);
  //   // if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === true &&
  //   //   istodayDateAfterEndDate === true
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     endDate: "End date must be before today date",
  //   //     openTooltip: true,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === true &&
  //   //   isStartDateAfterEndDate === false
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     endDate: "End date must be  after start date ",
  //   //     openTooltip: true,
  //   //   });
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === true &&
  //   //   yearOfEnd > 2022
  //   // ) {
  //   //   setEndDate(newEndDate);
  //   //   setdisabled(false);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     openTooltip: false,
  //   //     endDate: "",
  //   //   });
  //   //   handleToolClose();
  //   // } else if (
  //   //   isStartDateBeforeEndDate === false &&
  //   //   isStartDateAfterEndDate === true &&
  //   //   yearOfEnd < 2000
  //   // ) {
  //   //   setdisabled(true);
  //   //   setDateRange({
  //   //     ...dateRange,
  //   //     endDate: "End date too old ",
  //   //     openTooltip: true,
  //   //   });
  //   // }
  // };
  const handleStartDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_mentor_search", "");
    }
    if (startDate === null) {
      setstartdisabled(false);
    }
    setStartDate(date);
    setstartError(null);
  };
  const handleKeyDownChange = (e) => {
    ["ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault();
  };
  const handleEndDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_mentor_search", "");
    }
    if (endDate === null) {
      setenddisabled(false);
    }
    setEndDate(date);
    setendError(null);
  };
  const handleClickFilter = (statusvalue) => {
    const filteredData = getApi.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate).subtract(1, "days");
      return date.isBetween(start, endDate, null, "[]");
    });
    statusForAll(statusvalue, filteredData);
  };

  const handleClickRefresh = () => {
    setsearchdata("");
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });
    sessionStorage.setItem("admin_mentor_date", "");
    sessionStorage.setItem("admin_mentor_status", "");
    sessionStorage.setItem("admin_mentor_search", "");
    setstartdisabled(false);
    setenddisabled(false);
    setstartError(null);
    setendError(null);
    getFilterValue("all", "today", getApi);
  };

  const closeFunctionCall = () => {
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });

    getFilterValue("all", "today", getApi);
  };

  useEffect(() => {
    fetchApi();
    setPage(pagenumber ? pagenumber : 0);

    handleSort(orderBy);
  }, []);
  const searchdatas = (searchdata, getApi) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = getApi.filter((item) => {
      var valuess =
        item.MentorName.match(searchtext) || item.email_id.match(searchtext2);
      // item.votingDistrict.match(searchtext2) ||
      // item.mlaConstituency.match(searchtext2) ||
      // item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = () => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : !dateRange.openStartTool
          ? handleToolClose()
          : handleToolClose();
      };
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);
  //   import React, { useState } from 'react';
  // import { DatePicker, TextField } from '@mui/lab';

  // const App = () => {
  //   const [date, setDate] = useState(null);
  //   const [isFocused, setIsFocused] = useState(false);

  //   const handleDateChange = (value) => {
  //     setDate(value);
  //   };

  //   const handleFocus = () =  setIsFocused(true);> {
  //
  //   };

  //   const handleBlur = () => {
  //     setIsFocused(false);
  //     if (!date) {
  //       const today = new Date();
  //       setDate(today);
  //     }
  //   };

  //   return (
  //     <div>
  //       <DatePicker
  //         value={date}
  //         onChange={handleDateChange}
  //         renderInput={(params) => (
  //           <TextField
  //             {...params}
  //             onFocus={handleFocus}
  //             onBlur={handleBlur}
  //             placeholder="Select date"
  //           />
  //         )}
  //       />
  //       {isFocused && !date && <p>Today's date will be shown when you click outside the input field.</p>}
  //     </div>
  //   );
  // };

  // export default App;

  if (!loading.isLoading) {
    return (
      <>
        {getAdminSetting.mentorApproval === true && openSnack === true ? (
          <>
            <AutoApproveSnack
              openSnack={openSnack}
              setOpenSnack={setOpenSnack}
              full_name={"Mentor"}
              snackOption={"mentor-setting"}
            />
          </>
        ) : null}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Breadcrumb />
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h3 className="seeker-text">Mentors Data</h3>
            </Box>
          </Card>
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <StatCards getApi={getApi} />
            </Box>
          </Card>

          <Card sx={{ padding: "20px", mb: 8 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={1.7}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel>Filter By Status</InputLabel>
                      <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          sessionstatus ? sessionstatus : filterName.status
                        }
                        onChange={(e) => {
                          if (searchicon === true) {
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("admin_mentor_search", "");
                          }
                          if (dateenable) {
                            handleClickFilter(e.target.value);
                          } else {
                            sessionStorage.setItem(
                              "admin_mentor_status",
                              e.target.value
                            );
                            getFilterValue(
                              e.target.value,
                              sessiondate ? sessiondate : filterName.date,
                              getApi
                            );
                          }
                          setFilterName({
                            ...filterName,
                            status: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value={"A"}>Approved</MenuItem>
                        <MenuItem value={"P"}>Pending</MenuItem>
                        <MenuItem value={"D"}>Decline</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {dateenable === false ? (
                    <Grid item xs={12} sm={6} md={1.7}>
                      {" "}
                      <FormControl
                        sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                      >
                        <InputLabel>Filter By Date</InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sessiondate ? sessiondate : filterName.date}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem("admin_mentor_search", "");
                            }
                            sessionStorage.setItem(
                              "admin_mentor_date",
                              e.target.value
                            );
                            setFilterName({
                              ...filterName,
                              date: e.target.value,
                            });
                            getFilterValue(
                              sessionstatus ? sessionstatus : filterName.status,
                              e.target.value,
                              getApi
                            );
                          }}
                        >
                          <MenuItem value="Total">Total</MenuItem>
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="yesterday">Yesterday</MenuItem>
                          <MenuItem value="week">Last Week</MenuItem>
                          <MenuItem value="month">Last month</MenuItem>
                          <MenuItem value="daterange">Select By Date</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {dateenable ? (
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%", sm: "100%" },
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            // inputRef={}
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            // minDate={minDate}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                        {/* </Tooltip>
                        </ClickAwayListener> */}
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  {dateenable ? (
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Box>
                        {/* </Tooltip>
                        </ClickAwayListener> */}
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={1.7} sm={4} alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {dateenable ? (
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(filterName.status);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Close" placement="bottom">
                            <Fab
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                setdateenable(false);
                                handleClickRefresh();
                              }}
                            >
                              <CloseRoundedIcon />
                            </Fab>
                          </Tooltip>
                        </>
                      ) : null}

                      <Tooltip title="Refresh" placement="bottom">
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#339fcd",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#217396" },
                          }}
                          onClick={handleClickRefresh}
                        >
                          <RefreshSharpIcon />
                        </Fab>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={8} md={1.7} sm={4}>
                    {searchicon ? (
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : adminSeekerSearch}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setsearchdata(e.currentTarget.value);
                          sessionStorage.setItem(
                            "admin_mentor_search",
                            e.currentTarget.value
                          );
                          if (e.currentTarget.value.length === 0) {
                            setUseApi(getApi);
                            setTotalRows(getApi.length);
                            setPage(0);
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(e.currentTarget.value, getApi);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <Box display="flex">
                          {adminDetails_CEO?.emailId ===
                          process.env
                            .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                            <Tooltip title="Export As Excel" placement="bottom">
                              <ExportCS
                                selectedApiForExcel={selectedApiForExcel}
                                fileName={"Mentor-Data"}
                              />
                            </Tooltip>
                          )}
                          {adminDetails_CEO?.emailId ===
                          process.env
                            .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                            <Tooltip title="Export As PDF" placement="bottom">
                              <ExportPDF
                                headers={headers}
                                datas={datas}
                                fileName={"Mentor"}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={1.7} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      {searchicon ? (
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#FF0000",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#cc0000" },
                          }}
                          onClick={() => {
                            closeFunctionCall();
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("admin_mentor_search", "");
                          }}
                        >
                          <CloseIcon />
                        </Fab>
                      ) : (
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#339fcd",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#217396" },
                          }}
                          onClick={() => setsearchicon(true)}
                        >
                          <SearchRoundedIcon />
                        </Fab>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell> Profile</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Created Date</TableCell>

                    <TableCell> Mobile</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      >
                        Specialisation
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "status"}
                        direction={orderBy === "status" ? order : "asc"}
                        onClick={() => handleSort("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell>
                              {" "}
                              {
                                <img
                                  src={
                                    item?.mentorProfile
                                      ? `${baseUrl}mentor/get/profile/${item.email_id}`
                                      : ""
                                  }
                                  onError={(e) => {
                                    e.target.src = defaultImage;
                                  }}
                                  alt="img"
                                  height={"50"}
                                  width="50"
                                  style={{ clipPath: "circle(50%)" }}
                                />
                              }{" "}
                            </TableCell>
                            <TableCell>{item.MentorName}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {item.email_id}{" "}
                            </TableCell>
                            <TableCell>{item.createdDate}</TableCell>

                            <TableCell>{item.mobileNumber}</TableCell>
                            <TableCell>{item.Specialisation}</TableCell>
                            <TableCell>
                              <Tooltip
                                title={
                                  item.status === "A"
                                    ? "Approved"
                                    : item.status === "D"
                                    ? "Declined"
                                    : "Pending"
                                }
                                placement="right"
                              >
                                <Chip
                                  label={item.status}
                                  color={
                                    item.status === "P"
                                      ? "warning"
                                      : item.status === "A"
                                      ? "success"
                                      : "error"
                                  }
                                  variant="contained"
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <Box display="flex" gap={"10px"}>
                                <Tooltip title="Full Details" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      // navigate(
                                      //   ROUTINGDATA.LAND +
                                      //     ROUTINGDATA.ADMINPANEL +
                                      //     "/" +
                                      //     ROUTINGDATA.ADMINMENTORABOUTUS
                                      // );
                                      getOneData(item.email_id);
                                      handleChange();
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View more" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "admin_mentor_page",
                                        page
                                      );
                                      navigate(
                                        ROUTINGDATA.LAND +
                                          ROUTINGDATA.ADMINPANEL +
                                          "/" +
                                          ROUTINGDATA.ADMINMENTORABOUTUS,
                                        { state: { email_id: item.email_id } }
                                      );
                                    }}
                                    // disabled={!item.videoUpload}
                                  >
                                    <VisibilityRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                {/* <Tooltip
                                    title="Profile Video"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="icon-btn"
                                      size="Medium"
                                      onClick={() => {
                                        getOneData(item._id);
                                        setDecide(true);
                                      }}
                                      disabled={!item.videoUpload}
                                    >
                                      <VideocamRoundedIcon />
                                    </IconButton>
                                  </Tooltip> */}

                                {adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                                  <Tooltip title="Download CV" placement="top">
                                    <IconButton
                                      className="icon-btn"
                                      onClick={() =>
                                        downloadResume(item.email_id)
                                      }
                                      size="medium"
                                      disabled={!item.CVUpload}
                                    >
                                      <FileDownloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {/* {!item.linkedIn ? null : (
                                    <Tooltip
                                      title="LinkedIn Profile"
                                      placement="top"
                                    >
                                      <a
                                        href={item.linkedIn}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <IconButton
                                          className="icon-btn-link"
                                          sx={{
                                            transition: "all 0.3s",
                                            // visibility: `${
                                            //   !item.linkedIn
                                            //     ? "hidden"
                                            //     : "visible"
                                            // }`,
                                          }}
                                          // onClick={() => downloadResume(item.mobile_number)}
                                          size="medium"
                                          // disabled={!item.linkedIn}
                                        >
                                          <LinkedInIcon />
                                        </IconButton>
                                      </a>
                                    </Tooltip>
                                  )} */}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <MentorDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
            filterName={filterName}
            setFilterName={setFilterName}
            fetchApi={fetchApi}
            dialogLoad={loading.dialogLoad}
            setGetOne={setGetOne}
          />

          {/* <!----VIDEO_DIALOG */}

          <Dialog
            open={decide}
            onClose={() => setDecide(false)}
            fullWidth
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#339fcd",
                borderRadius: "20px",
                height: "50%",
              },
            }}
          >
            <DialogTitle>
              <Box textAlign="right">
                <IconButton onClick={() => setDecide(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <video
                className="VideoInput_video"
                width="100%"
                height="100%"
                controls
                src={
                  getOne?.videoUpload
                    ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                    : ""
                }
              />
            </DialogContent>
          </Dialog>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default MentorAdminData;
