import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  TablePagination,
  Grid,
  Paper,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import defaultimg from "../../../../img/mentorImage.png";
import SeekerDialog from "../../../admin-panel/admin-components/DialogBoxes/SeekerDialog";
import StatMPcards from "../../../admin-panel/admin-components/StatCards/StatMPcards";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import xls from "../../../../img/admin-panel/icons8-xls-48 (1).png";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS";
function MLAconstituency1() {
  const location = useLocation();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const [getOne, setGetOne] = useState({});
  const [open, setOpen] = useState(false);
  const state = location.state.mla;
  const seekers = location.state.seekers;
  const load = location.state.load;
  const ind = location.state.index;
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const [mpmlaseeker, setmpmlaseeker] = useState([]);
  const mpmlaalldatasearch = sessionStorage.getItem(`mpmlaalldatasearch${ind}`);
  const handleChange = () => {
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/email/${id}`);
      const { data } = getOneAxios;

      setGetOne(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const selectedApiForExcel = seekers.map((i) => {
    return {
      "Full Name": i.full_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      "Current Location": i.currentLocation,
      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
    };
  });

  const closeFunctionCall = () => {
    setmpmlaseeker(location.state.seekers);
    setTotalRows(location.state.seekers.length);
    setPage(0);
  };
  const searchdatas = (searchdata) => {
    const datas = state.seekers.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      var valuess =
        item.full_name.match(searchtext) || item.email_id.match(searchtext);

      if (valuess != null) {
        return item;
      }
    });
    setmpmlaseeker(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    let bool = false;
    if (mpmlaalldatasearch !== null) {
      bool = mpmlaalldatasearch.length > 0;
    }
    if (true === bool) {
      searchdatas(mpmlaalldatasearch);
      setsearchicon(true);
    } else {
      setmpmlaseeker(seekers);
      setTotalRows(seekers.length);
      setPage(0);
    }
  }, [location.state]);
  const needsupport = state.needSupport;

  const totalFreshers = state.totalFresher;

  const totalRegistered = state.seekers;

  if (load === false) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px", borderRadius: "20px" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {state.mla_constituency}'s All Data
              </Typography>
            </Card>
          </Box>

          <StatMPcards
            registered={totalRegistered.length}
            freshers={totalFreshers.length}
            need={needsupport.length}
          />
          <Card sx={{ boxShadow: 0 }}>
            <Paper sx={{ padding: "20px " }}>
              <Grid container justifyContent="space-between">
                <Grid item md={5}>
                  <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    value={searchdata ? searchdata : mpmlaalldatasearch}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            visibility: `${searchicon ? "visible" : "hidden"}`,
                          }}
                          onClick={() => {
                            closeFunctionCall();
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem(
                              `mpmlaalldatasearch${ind}`,
                              ""
                            );
                          }}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      ),
                    }}
                    onKeyDown={(e) =>
                      ["(", ")", "^", "[", "]", "*", "<", ">", "\\"].includes(
                        e.key
                      ) && e.preventDefault()
                    }
                    onChange={(e) => {
                      setsearchdata(e.currentTarget.value);
                      sessionStorage.setItem(
                        `mpmlaalldatasearch${ind}`,
                        e.currentTarget.value
                      );
                      if (e.currentTarget.value.length === 0) {
                        setmpmlaseeker(seekers);
                        setTotalRows(seekers.length);
                        setPage(0);
                      }
                      if (e.currentTarget.value.length >= 3) {
                        searchdatas(e.currentTarget.value);
                      }
                      setsearchicon(true);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Tooltip title="Export As Excel" placement="bottom">
                    <ExportCS
                      selectedApiForExcel={selectedApiForExcel}
                      fileName={"Seeker-data"}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Experience</TableCell>
                    <TableCell>Industry</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mpmlaseeker.length > 0 ? (
                    <>
                      {mpmlaseeker

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow className="tablecell">
                            <TableCell>
                              <img
                                src={
                                  item?.profileUpload
                                    ? `${baseUrl}seekers/profile/${item.mobile_number}`
                                    : ""
                                }
                                onError={(e) => {
                                  e.target.src = defaultimg;
                                }}
                                height={"50"}
                                width="50"
                                style={{ clipPath: "circle(50%)" }}
                                alt="img"
                              />
                            </TableCell>
                            <TableCell>{item.full_name}</TableCell>
                            <TableCell>{item.email_id}</TableCell>
                            <TableCell>{item.mobile_number}</TableCell>
                            <TableCell>{item.work_status}</TableCell>
                            <TableCell>{item.industry}</TableCell>
                            <TableCell>
                              <Tooltip title="Full Details" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="Medium"
                                  onClick={() => {
                                    getOneData(item.email_id);
                                    setOpen(true);
                                  }}
                                >
                                  <OpenInNewRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>

        <SeekerDialog
          open={open}
          handleChange={handleChange}
          getOne={getOne}
          setOpen={setOpen}
        />
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default MLAconstituency1;
