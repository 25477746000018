import React from "react";
import { Box, Card, Grid, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "16px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
  },
}));

function StatForVolunteer2({ seeker, family, volunteer }) {
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));
  const seekerDistrict = seeker.filter((item) => {
    return item.votingDistrict === volunteer.district;
  });
  const familyDistrict = family.filter((item) => {
    return item.votingDistrict === volunteer.district;
  });
  const shortList = family.filter((item) => {
    return item.shortList === `Volunteer_${verifierVolunteer}`;
  });
  const shortDis = shortList.filter((item) => {
    return item.votingDistrict === volunteer.district;
  });
  const [need, ALL, short] = [
    familyDistrict.length,
    seekerDistrict.length,
    shortDis.length,
  ];

  const cardList = [
    {
      name: `Total Seekers Registration in ${volunteer.district}`,
      count: ALL,
      bgColor: "#2838a6",
      svg: <HourglassFullRoundedIcon fontSize="large" />,
    },
    {
      name: `Total need support in ${volunteer.district}`,
      count: need,
      bgColor: "#28a745",
      svg: <AddTaskRoundedIcon fontSize="large" />,
    },
    {
      name: `Total Shortlisted in ${volunteer.district}`,
      count: short,
      bgColor: "#FF0000",
      svg: <DoDisturbOnRoundedIcon fontSize="large" />,
    },
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <StyledCard
              sx={{ backgroundColor: `${item.bgColor}` }}
              elevation={6}
            >
              <Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Box sx={{ color: "white" }}>{item.svg}</Box>
                  <CountUp
                    style={{
                      fontSize: "26px",
                      color: "#f2f2f2",
                    }}
                    start={0}
                    end={item.count}
                    duration={1}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                >
                  {item.name}
                </Typography>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatForVolunteer2;
