import React, { useState,useEffect } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  IconButton,
  DialogContentText,
  Tooltip,
  Skeleton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ReactQuill from "react-quill";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material/";
import useAxios from "../../../api/useAxios";
import defaultimg from "../../../img/mentorImage.png";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { saveAs } from "file-saver";
function DatabaseSeekerDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  filterseeker,
  dialogLoad,
  setGetOne,
  empData,
  trialView,
  empDataAccess,
  downloadResumeSeekers,
  resumeDownloadedSeekersArray,
  setResumeDownloadedSeekersArray,
  selectProfilePopup,
  handleChangeNotes
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [titleJob, setTitleJob] = useState("");
  const [emailType, setEmailType] = useState("No");
  const [notesError, setNotesError] = useState(false);
  const [titleJobError, settitleJobError] = useState(false);
  const [getPass, setGetPass] = useState("");
 let userDetails = JSON.parse(localStorage.getItem("user"));
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const location = useLocation();
  const currentUrl = location.pathname;
  const downloadResume = async(id,datasView2) => {

    const viewedData = datasView2.length > 0 ?datasView2.join(","):datasView2;
    try {
      const response = await axiosData.put(
        `databaseaccess/${empDataAccess._id}`,
        {resume_downloaded_seekers: viewedData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        //filterseeker(empDataAccess);
      } else if (response.status === 403) {
      }
    } catch (err) {}
    saveAs(`${baseUrl}seekers/resume/${id}`, id + "Resume"); 
  };
  const Apply = async (job) => {
    let res;
    try {
      res = await axiosData.post(
        `jobapply/`,
        {
          job_id: empDataAccess._id,
          employer_id: empData.user_id,
          seeker_id: getOne._id,
          seeker_name: getOne.full_name,
          seeker_email: getOne.email_id,
          seeker_mobile_number: getOne.mobile_number,
          contact_email_id:empData.email_id,
          job_title: titleJob,
          contact_mobile_number:empData.contact_mobile_number,
          mobile_number: getOne.mobile_number,
          job_status:"A",
          source_from:`Database-${empDataAccess._id}`
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
        const commentsAppr = notes;
    var strippedHtml = commentsAppr.replace(/<[^>]+>/g, "");
    const id = res.data._id;
    const totalData = {
      id: id,
      status: "S",
      seeker_name: res.data.seeker_name,
      company_name: userDetails.company_name,
      seeker_email: res.data.seeker_email,
      job_title: res.data.job_title,
      notes: strippedHtml,
      job_status: res.data.job_status,
      emailType:emailType,
      source_from:res.data.source_from,
    };

    try {
      await axiosData.patch("jobapply/updatestatus", totalData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleChangeNotes()
      handleChange();
      setNotes("");
      setEmailType("No")
      setTitleJob("")
      setNotesError(false)
      settitleJobError(false)
      filterseeker(empDataAccess);
    } catch (err) {
      console.log(err);
    }
      }
    } catch (error) {
      console.log("Error", error);
    }
  
  };
  useEffect(() => {
    //getOneData(getOne._id);
  }, []);
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
          zIndex:"99999"
        }}
        open={open}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          <Box id="main-box">
            <Box>
              {" "}
              <div id className="align-img">
                <img
                  alt="img"
                  id="profileImage"
                  src={`${baseUrl}seekers/profile/${getOne.mobile_number}`}
                  onError={(e) => {
                    e.target.src = defaultimg;
                  }}
                  className="updateProfileImage"
                />
              </div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.full_name}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {getOne.work_status}
                  </div>
                </div>
              </Typography>
            </Box>

           {trialView === "trialView" ?null: ( <Box>
              <Tooltip title="Download Resume" placement="top">
                <IconButton
                  className="icon-btn-dbox  "
                  onClick={() => {
                    const stringRepresentation = String(getOne._id);
                    downloadResumeSeekers.push(stringRepresentation);
                    const datasView2 = [];
                    if(resumeDownloadedSeekersArray?.length > 0 ){
                      datasView2.push(
                        ...resumeDownloadedSeekersArray
                      );
                      datasView2.push(stringRepresentation);
                    }else{
                      datasView2.push(stringRepresentation);
                    }
                    setResumeDownloadedSeekersArray(datasView2);
                    downloadResume(getOne.mobile_number,datasView2)}}
                  size="medium"
                  disabled={!getOne.fileUpload}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>)} 
          
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px" }}>
            <Grid container md={12} columnSpacing={1} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Personal Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins-Regular" }}>
                  <div className="align-text">
                    <span className="full-details"> Full Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.full_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Gender:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.gender
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mobile_number
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Professional Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Experience:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.work_status
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                {getOne.work_status === "Experienced" && (
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">
                        {" "}
                        Years of Experience:
                      </span>
                      <div>
                        {dialogLoad ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        ) : (
                          getOne.Experience
                        )}
                      </div>
                    </div>
                  </Typography>
                )}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Industry:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.industry
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Current Location:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.currentLocation
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Education Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Course:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.course
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Education:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.Education
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Institute:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.Institute
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Passed Out Year:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.courseDuration
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Percentage:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.grade
                      )}
                      {getOne.Education?.grade && "%"}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Key Skills:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        `${getOne.Keyskill}${"   "}`
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Profile Summary
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  {getOne.ProfileSummary}
                </Typography>
              </Box>
              <Box sx={{ padding: "20px" }}>
                <Divider
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#055779",
                    },
                    color: "#055779 ",
                  }}
                  textAlign="left"
                >
                  Voting Details
                </Divider>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Voting District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.votingDistrict
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MP Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mpConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MLA Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mlaConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Pin-code:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.pincode
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  {getOne.videoUpload === undefined ||
                  getOne.videoUpload === "" ? (
                    <div className="align-text">
                      <span className="full-details">Video Profile:</span>
                      <div>No Video Uploaded</div>
                    </div>
                  ) : (
                    <div>
                      <div className="align-text">
                        <span
                          className="full-details"
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          Video Profile:
                        </span>
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <video
                          className="VideoInput_video"
                          width="100%"
                          height="200px"
                          controls
                          src={
                            getOne.videoUpload !== undefined
                              ? getOne.videoUpload !== ""
                                ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                                : ""
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                </Typography>
                {currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.ADMINPANEL +
                  "/" +
                  ROUTINGDATA.ADMINSEEKERS ? (
                  <Typography textAlign="center" variant="h6">
                    <div className="align-text">
                      <span
                        className="full-details"
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                        Status:
                      </span>
                      <div style={{ textAlign: "left" }}>
                        {dialogLoad ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={40}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        ) : (
                          <Chip
                            label={getOne.status}
                            color={
                              getOne.status === "P"
                                ? "warning"
                                : getOne.status === "A"
                                ? "success"
                                : "error"
                            }
                            variant="contained"
                            sx={{ textAlign: "left" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>
                ) : null}
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} variant="contained" onClick={handleChange}>
           {trialView === "trialView"?"Ok":"Close"} 
          </Button>
              {trialView === "trialView"?null:(<Button
                className="action-btn"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);
                  setGetPass(getOne.password);
                  handleChangeNotes();
                }}
                variant="contained"
                color="success"
                //disabled={getShortlistSeeker === undefined||getShortlistSeeker === null||getShortlistSeeker === ""? false : true}
              >
                Shortlist
              </Button>)}
              {" "}
        </DialogActions>
      </Dialog>
      <Dialog
            open={selectProfilePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" } , zIndex:"99999"}}
          >
              <DialogTitle
                id="alert-dialog-title"
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                {"Confirmation"}
              </DialogTitle>

              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText id="alert-dialog-description">
                  <Typography sx={{ mb: 2, color: "black", fontSize: "17px" }}>
                    <b>
                      {getOne.full_name} Selected for Shortlist
                        </b>
                  </Typography>
                </DialogContentText>

                <Grid item xs={12} sx={{ marginTop: 1 }}>

                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="job_title"
                    label="Enter Job Title"
                    name="job_title"
                    onChange={(e) => {
                      if (e) {
                        const value = e.target.value.replace(
                          /[^a-z#+/. ]/gi,
                          ""
                        );
                        setTitleJob(value)
                        if(value === ""){
                          settitleJobError(true)
                      }else{
                        settitleJobError(false)
                      }
                      }
                    }}
                    value={titleJob}
                  />
                  { titleJobError === true? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      Job Title must be required
                    </div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ marginY: 1 }}>
                    <b>Do you want to send email to seeker ?</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="email_type"
                    onChange={(e) => {
                      setEmailType(e.target.value)
                    }}
                   
                    value={emailType}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="YES"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="NO"
                    />
                  </RadioGroup>
                </Grid>
                  <Typography sx={{ marginY: 1 }}><b>Reason for Shortlist:</b></Typography>
                  <ReactQuill
                    name="notes"
                    value={notes}
                    onChange={(e) => {
                      setNotes(e);
                      var strippedHtml = e.replace(/<[^>]+>/g, "");
                      if(strippedHtml === ""){
                          setNotesError(true)
                      }else{
                        setNotesError(false)
                      }
                    }}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ["link", "image"],
                        ["clean"],
                        [{}],
                      ],
                    }}
                    multiline
                    rows={3}
                    placeholder="Write reason for shortlist..."
                    className="scrollable-container-postjob"
                  />
                  {notesError === true? (
                    <div style={{ color: "red" }}>Reason must be required</div>
                  ) : null}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                  handleChangeNotes()
                  setNotes("")
                  setTitleJob("")
                  setEmailType("No")
                  setNotesError(false)
                  settitleJobError(false)
                  filterseeker(empDataAccess);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={notes && !notesError && !titleJobError? false : true}
                  onClick={()=>{
                    Apply(getOne);
                   }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
          </Dialog>
    </>
  );
}

export default DatabaseSeekerDialog;
