import { Box } from "@mui/material";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Switch,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Container,
  Grid,
} from "@mui/material";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  WorkRounded as WorkRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  VolunteerActivism as VolunteerActivismIcon,
  PeopleAltRounded as PeopleAltRoundedIcon,
  VerifiedUser as VerifiedUserIcon,
  ContactSupport as ContactSupportIcon,
  EngineeringRounded as EngineeringRoundedIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import useAxios from "../../../../api/useAxios";
import { useContext } from "react";
import { CurrentStateContext } from "../../../../App";

function AdminApproval() {
  const axiosData = useAxios();
  const [getAppData, setGetAppData] = useState({});
  const { getFunction, getAdminSetting, checked } =
    useContext(CurrentStateContext);
  // const [checked, setChecked] = useState({
  //   checked0: null,
  //   checked1: null,
  //   checked2: null,
  //   checked3: null,
  //   checked4: null,
  //   checked5: null,
  //   checked6: null,
  // });
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const [checkedDisable, setCheckedDisable] = useState({
    checked0Disable: false,
    checkedDisable1: false,
    checkedDisable2: false,
    checkedDisable3: false,
    checkedDisable4: false,
    checkedDisable5: false,
    checkedDisable6: false,
  });

  // const getAdminSettings = async () => {
  //   try {
  //     const app = await axiosData.get("application");
  //     const { data } = app;
  //     setGetAppData(data[0]);
  //     setChecked({
  //       ...checked,
  //       checked0: data[0].jobSeekerApproval,
  //       checked1: data[0].employerApproval,
  //       checked2: data[0].volunteerApproval,
  //       checked3: data[0].mentorApproval,
  //       checked4: data[0].needSupportApproval,
  //       checked5: data[0].verifierApproval,
  //       checked6: data[0].servicesProviderApproval,
  //     });

  //   } catch (err) {}
  // };
  const updateSettings = async (index, checked, id) => {
    setCheckedDisable({ ...checkedDisable, [`checkedDisable${index}`]: true });
    try {
      const cond =
        index === 0
          ? "jobSeekerApproval"
          : index === 1
          ? "employerApproval"
          : index === 2
          ? "volunteerApproval"
          : index === 3
          ? "mentorApproval"
          : index === 4
          ? "needSupportApproval"
          : index === 5
          ? "verifierApproval"
          : index === 6
          ? "servicesProviderApproval"
          : "none";
      await axiosData.put(`application/${id}`, { [cond]: checked });
      // getAdminSettings();
      getFunction.myFunction();
      setCheckedDisable({
        ...checkedDisable,
        [`checkedDisable${index}`]: false,
      });
    } catch (err) {}
  };
  // useEffect(() => {
  //   getAdminSettings();
  // }, []);

  const switchProps = [
    { name: "Job Seeker", icon: <WorkRoundedIcon fontSize="large" /> },
    { name: "Employer", icon: <PersonSearchRoundedIcon fontSize="large" /> },
    { name: "Volunteer", icon: <VolunteerActivismIcon fontSize="large" /> },
    { name: "Mentor", icon: <PeopleAltRoundedIcon fontSize="large" /> },
    { name: "Need Support", icon: <ContactSupportIcon fontSize="large" /> },
    { name: "Verifier", icon: <VerifiedUserIcon fontSize="large" /> },
    {
      name: "Local Service Provider",
      icon: <EngineeringRoundedIcon fontSize="large" />,
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} lg={6} xs={12}>
            <Card
              sx={{
                // width: "50%",
                boxShadow: "3px 3px 10px 3px #ddd",
                borderRadius: "6px",
              }}
            >
              <Container fixed>
                {/* <CardHeader
              title={
                <Typography variant="h5" sx={{ fontFamily: "Barlow-Bold" }}>
                  Auto Approval
                </Typography>
              }
              subheader={
                <Typography
                  variant="caption"
                  sx={{ fontFamily: "Barlow-Medium", mt: 5 }}
                >
                  Turning On will Approve Users automatically when they
                  registered{" "}
                </Typography>
              }
            /> */}

                <CardContent>
                  <Box display="flex" flexDirection={"column"} gap={"20px"}>
                    <Box display="flex" flexDirection={"column"} gap={"5px"}>
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "Poppins-SemiBold" }}
                      >
                        Auto Approval
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "Barlow-Medium" }}
                      >
                        Turning On will Approve Users automatically when they
                        registered{" "}
                      </Typography>
                    </Box>
                    {switchProps.map((item, index) => {
                      return (
                        <>
                          <Box display="flex" justifyContent={"space-between"}>
                            {" "}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText>
                              <Typography
                                sx={{ fontFamily: "Poppins-Regular" }}
                              >
                                {" "}
                                {item.name}
                              </Typography>
                            </ListItemText>{" "}
                            <Switch
                              disabled={
                                adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                  ? true
                                  : false
                              }
                              checked={checked[`checked${index}`]}
                              onChange={(e) => {
                                updateSettings(
                                  index,
                                  e.target.checked,
                                  getAdminSetting._id
                                );
                                // setChecked({
                                //   ...checked,
                                //   [`checked${index}`]: e.target.checked,
                                // });
                              }}
                            />{" "}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </CardContent>
              </Container>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AdminApproval;
