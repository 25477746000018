import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import context from "./EmpContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function ContactPersonDetails({ setput }) {
  const axiosData = useAxios();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focus, setfocus] = useState(false);
  const [focusNo, setFocusNo] = useState(false);

  const handleClickOpen = (context) => {
    formik.setFieldValue("contact_person", context.contact_person);
    formik.setFieldValue("designation", context.designation);
    formik.setFieldValue("contact_email_id", context.contact_email_id);
    formik.setFieldValue("mobile_number", context.mobile_number);
    setOpen(true);
  };

  const handleClose = () => {
    formik.handleReset("");
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      contact_person: "",
      designation: "",
      contact_email_id: "",
      mobile_number: "",
    },

    validationSchema: Yup.object({
      contact_person: Yup.string().max(50, "Must be 50 characters or less"),
      designation: Yup.string().max(50, "Must be 50 characters or less"),
      contact_email_id: Yup.string().matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid Email"
      ),
      mobile_number: Yup.number()
        .min(6000000000, "Not Valid Phone Number!")
        .max(9999999999, "Not Valid Phone Number!"),
    }),

    onSubmit: async (values) => {
      setIsSubmitting(true);
      await axiosData.put(`employers/email/${location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      formik.handleReset("");
      setput(true);

      setOpen(handleClose);
      setIsSubmitting(false);
    },
  });
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  return (
    <context.Consumer>
      {(context) => {
        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              sx={{zIndex:"99999"}}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">
                    <b>Contact Details</b>
                  </Typography>

                  <Grid item xs={12} sm={12} md={8} square>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="contact_person"
                            label="Contact Person Name"
                            name="contact_person"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e) {
                                const value = e.target.value.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                );
                                formik.setFieldValue("contact_person", value);
                              }
                            }}
                            value={formik.values.contact_person}
                          />
                          {formik.touched.contact_person &&
                          formik.errors.contact_person ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.contact_person}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="designation"
                            label="Designation"
                            variant="outlined"
                            name="designation"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e) {
                                const value = e.target.value.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                );
                                formik.setFieldValue("designation", value);
                              }
                            }}
                            value={formik.values.designation}
                          />
                          {formik.touched.designation &&
                          formik.errors.designation ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="contact_email_id"
                            label="Email Id"
                            variant="outlined"
                            name="contact_email_id"
                            onBlurCapture={() => {
                              setfocus(true);
                            }}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              setfocus(false);
                              formik.setFieldValue(
                                "contact_email_id",
                                e.target.value
                              );
                            }}
                            value={formik.values.contact_email_id.replace(
                              /[^a-z0-9@.]/gi,
                              ""
                            )}
                          />
                          {formik.touched.contact_email_id &&
                          formik.errors.contact_email_id &&
                          focus ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.contact_email_id}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            fullWidth
                            placeholder="Enter Your Mobile Number"
                            name="mobile_number"
                            label="Mobile Number"
                            type="number"
                            id="mobile_number"
                            onBlurCapture={() => {
                              setFocusNo(true);
                            }}
                            onBlur={formik.handleBlur}
                            onChange={handleChange}
                            value={formik.values.mobile_number}
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <b>+91</b>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {formik.touched.mobile_number &&
                          formik.errors.mobile_number &&
                          focusNo ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.mobile_number}
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </DialogTitle>

                <DialogActions sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <Grid>
              <Box
                id="ContactDetails"
                sx={{ fontSize: "20px", textTransform: "uppercase", mb: 1 }}
              >
                <b>Contact Details</b>
                <Tooltip title="Edit Contact Details" arrow>
                  <IconButton
                    sx={{ ml: 1, mb: 1 }}
                    color="primary"
                    onClick={() => {
                      handleClickOpen(context);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <div className="keypair">
                  <Typography
                    sx={{ fontWeight: 600, marginRight: 3, marginTop: 1 }}
                  >
                    Contact Person <span id="none">:</span>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ fontWeight: 600, marginTop: 1 }}
                  >
                    {context.contact_person}
                  </Typography>
                </div>
                <div className="keypair">
                  <Typography
                    sx={{ fontWeight: 600, marginRight: 7, marginTop: 1 }}
                  >
                    Designation
                    <span id="none">:</span>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ fontWeight: 600, marginTop: 1 }}
                  >
                    {context.designation}
                  </Typography>
                </div>
                <div className="keypair">
                  <Typography
                    sx={{ fontWeight: 600, marginRight: 10, marginTop: 1 }}
                  >
                    Email ID <span id="none">:</span>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ fontWeight: 600, marginTop: 1 }}
                  >
                    {context.contact_email_id}
                  </Typography>
                </div>
                <div className="keypair">
                  <Typography
                    sx={{ fontWeight: 600, marginRight: 3.5, marginTop: 1 }}
                  >
                    Mobile Number <span id="none">:</span>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ fontWeight: 600, marginTop: 1 }}
                  >
                    {context.mobile_number}
                  </Typography>
                </div>
              </Box>
            </Grid>
          </div>
        );
      }}
    </context.Consumer>
  );
}

export default ContactPersonDetails;
