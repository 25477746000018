import React, { useEffect, useRef } from "react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useLocation } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText, CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import defaultimg from "../../../img/logo_for-landingpage-3.png";
import { ReactComponent as Warning } from "../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Slide, Slider, Tooltip, Typography } from "@mui/material";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import "../Advertisement/Advertisement.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import center_frist_banner from "../../../img/bsnner-img/center_frist_banner.png";
import center_secound_banner from "../../../img/bsnner-img/center_secound_banner.png";
import center_3rd_banner from "../../../img/bsnner-img/center_3rd_banner.png";
import side_frist_banner from "../../../img/bsnner-img/sidebanner-1.png";
import side_secound_banner from "../../../img/bsnner-img/sidebanner-2.png";
import side_3rd_banner from "../../../img/bsnner-img/sidebanner-3.png";
import popup_banner_one from "../../../img/bsnner-img/popbanner-1.png";
import popup_secound_banner from "../../../img/bsnner-img/popbanner-2.png";
import popup_3rd_banner from "../../../img/bsnner-img/popbanner-3..png";
import flash_banner_one from "../../../img/bsnner-img/flash-banner-1.png";
import flash_secound_banner from "../../../img/bsnner-img/flash-banner-2.png";
import flash_3rd_banner from "../../../img/bsnner-img/flash-banner-3.png";
import Topbanner_1 from "../../../img/bsnner-img/top-banner-1.png";
import Topbanner_2 from "../../../img/bsnner-img/top-banner-2.png";
import Topbanner_3 from "../../../img/bsnner-img/top-banner-3.png";
import bottomfooter_1 from "../../../img/bsnner-img/bottom-banner.png";
import bottomfooter_2 from "../../../img/bsnner-img/bottom-banner-2.png";
import bottomfooter_3 from "../../../img/bsnner-img/bottom-banner-3.png";
import istagram_post_1 from "../../../img/bsnner-img/advertisement-for-ista.png";
import instagramimg from "../../../img/bsnner-img/instagram-1.png";
import instagramimg2 from "../../../img/bsnner-img/instagram-2.png";
import facebookpost_1 from "../../../img/bsnner-img/fcabookpostbanner.png";
import facebookimg from "../../../img/bsnner-img/facebookcover-2.png";
import facebookimg3 from "../../../img/bsnner-img/facebookcover-3.png";
import poppupfacebook_1 from "../../../img/bsnner-img/samplepopupfacebookimg.png";

import TextField from "@mui/material/TextField";
import { color, display, width } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Advertisement = () => {
  const [opensubscribe, setOpensubscribe] = useState(false);
  const [confirmAdDialog, setConfirmAdDialog] = useState(false);
  const [contactDialog, setContactDialog] = useState(false);
  const [successPostAd, setSuccessPostAd] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notfilledDialog, setNotFilledDialog] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openLogo, setOpenLogo] = useState(false);
  const [postion, setPostion] = useState("");
  const [daysvalid, setDaysvalid] = useState("");
  const [templates, setTemplates] = useState("Template_1");
  const [imgget, setimgget] = useState();
  const [radio, setradio] = useState("create_your_add");
  const [adPaymentType, setAdPaymentType] = useState("ad_based");
  const location = useLocation();
  const axiosData = useAxios();
  const fileInputField = useRef(null);
  const fileInputField2 = useRef(null);
  const [imageerror, setimgeerror] = useState("");
  const [addGetList, setAddGetList] = useState([]);
  const [addGetPayment, setAddGetPayment] = useState([]);
  const [addGetPaymentDistrict, setAddGetPaymentDistrict] = useState([]);
  const [widthSize, setWidthSize] = useState("1092px");
  const [heightSize, setheightSize] = useState("275px");
  const [uploadedImages, setUploadedImages] = useState([]);

  const [toShowImage, setToShowImage] = useState([]);
  const [toShowImageLogo, setToShowImageLogo] = useState([]);
  const [uploadedImagesLogo, setUploadedImagesLogo] = useState([]);
  const [payAmount, setpayAmount] = useState(0);
  const [selectAllpayAmount, setselectAllPayAmount] = useState(0);
  const [adPersonalDetails, setAdPersonalDetails] = useState(false);
  const [getPersonalEmail, setGetPersonalEmail] = useState("");
  const [getPersonalMobile, setGetPersonalMobile] = useState("");
  const [getPersonalName, setGetPersonalName] = useState("");
  const [errorEmail, setEmailError] = useState("");
  const [errormobile, setMobileError] = useState("");
  const [errorName, setNameError] = useState("");
  const [dataMPError, setDataMPError] = useState("");
  const [data, setData] = useState("");
  const [uploadEnable, setUploadEnable] = useState(false);
  const [logoUpload, setLogoUpload] = useState("");
  const [adDistrict, setAdDistrict] = useState("");
  const [adPincode, setAdPincode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isValidUpload, setIsValidUpload] = useState(true);
  const [videoUpload, setVideoUpload] = useState(false);
  const [source, setSource] = useState();
  const [videoAd, setVideoAd] = useState("");
  const [size, setSize] = useState();
  const [refresh, setrefresh] = useState(false);
  const [focusb, setfocusb] = useState();
  const [focusout, setfocus] = useState();
  const MAX_FILE_SIZE_VIDEO =
    1048576 * parseInt(process.env.REACT_APP_VIDEO_SIZE_FOR_AD);
  const navigate = useNavigate();
  const [header, setheader] = useState({
    name: "HEADER TEXT ",
    name2: "BUSINESS WORK",
    name3: "CREATIVE",
    name4: "We are the best solution for your business",
    name5: "**********",
    name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
    name7: "100%",
    name8: "LEARN MORE",
    name9: "Content",
  });
  var editor = "";
  const [pictureLogo, setPictureLogo] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const [picture2, setPicture2] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const handleClose = () => {
    setOpen2(false);
  };
  const handleClickOpen = () => {
    setOpen2(true);
  };
  const handleClickOpenLogo = () => {
    setOpenLogo(true);
  };
  const handleSlider = (event, value) => {
    setPicture2({
      ...picture2,
      zoom: value,
    });
  };
  const handleSliderLogo = (event, value) => {
    setPictureLogo({
      ...pictureLogo,
      zoom: value,
    });
  };
  const handleCancel = () => {
    setPicture2({
      ...picture2,
      cropperOpen: false,
    });
  };
  const handleCancelLogo = () => {
    setPictureLogo({
      ...pictureLogo,
      cropperOpen: false,
    });
  };
  const setEditorRef = (ed) => {
    editor = ed;
  };
  // useEffect(() => {
  //   fileInputField2.current?.load();
  // }, [videoAd]);
  const [districtData, setDistrictData] = useState([]);
  const [postOfficeData, setPostOfficeData] = useState([]);
  const [district, setDistrict] = useState([]);
  const [comdata, setComdata] = useState([]);
  const [adpicdata, setadpicdata] = useState({
    company_url: "",
    image_position: "",
    days_conut: "",
    Ad_district: "",
    Ad_pincode: "",
    addUpload: "",
    ad_email_id: "",
    ad_mobile_number: "",
    ad_person_name: "",
  });
  const [pincodeData, setPincodeData] = useState([]);
  const [pincode, setPincode] = useState([]);
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        const temp = getDistrict.reverse();
        temp.push("Select All");
        temp.reverse();
        setDistrictData(temp);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const [imageSize, setImageSize] = useState(false);
  const handleFileChangeLogo = (e) => {
    let imgFile = e.target.files[0];
    setPictureLogo({
      ...pictureLogo,
      img: imgFile,
      cropperOpen: true,
    });
    if (!imgFile) {
      return;
    } else {
      handleClickOpenLogo();
      fileInputField.current.value = "";
    }
    const maxSize = 2 * 1024 * 1024;
    if (imgFile.size > maxSize) {
      setImageSize(true);
      fileInputField.current.value = "";
      return;
    }
  };
  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];
    setPicture2({
      ...picture2,
      img: imgFile,
      cropperOpen: true,
    });
    if (imgFile) {
      handleClickOpen();
      fileInputField.current.value = "";
    } else {
      return;
    }
    const maxSize =
      2 * parseInt(stickyDivStyle.width) * parseInt(stickyDivStyle.height);
    if (imgFile.size > maxSize) {
      setImageSize(true);
      fileInputField.current.value = "";
      return;
    }
  };
  const handleFileChangeVideo = async (e) => {
    setVideoAd("");
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size;
    setSize(fileSize);
    const url = URL.createObjectURL(file);
    setSource(url);
    const values = { videoUpload: "" };
    if (!file) return;
    if (e.target.files[0]) {
      values.videoUpload = e.target.files[0];
    }
    if (e.target.files[0].size < MAX_FILE_SIZE_VIDEO) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let newSelectedAttachment = {};
        newSelectedAttachment.file = file;
        newSelectedAttachment.blobData = e.target.result;
        if (file.type.includes("video")) {
          setVideoAd(newSelectedAttachment.blobData);
        }
      };
      reader.readAsDataURL(file);
      fileInputField2.current.value = "";
      setrefresh(!refresh);
      setTimeout(() => {}, 5000);
    } else {
      setVideoUpload(true);
      fileInputField2.current.value = "";
    }
  };
  const handleSaveLogo = () => {
    setToShowImageLogo("");
    setUploadedImagesLogo("");
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      if (croppedImg) {
        setPictureLogo({
          ...pictureLogo,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
        var block = croppedImg.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 512;
        const img = b64toBlob(realData, contentType, sliceSize);
        setToShowImageLogo((prevImages) => [...prevImages, croppedImg]);
        setUploadedImagesLogo((prevImages) => [...prevImages, img]);
      } else {
      }
    }
  };
  const handleSave = () => {
    setToShowImage("");
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      if (croppedImg) {
        setPicture2({
          ...picture2,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
        var block = croppedImg.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 2000;
        const img = b64toBlob(realData, contentType, sliceSize);
        setToShowImage((prevImages) => [...prevImages, croppedImg]);
        setUploadedImages((prevImages) => [...prevImages, img]);
      } else {
      }
    }
  };
  const getpaymentforallpincode = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `/General/adPaymentForSelectAllDistrict`
      );
      const data = getOneAxios.data.collections;
      if (data) {
        setAddGetPaymentDistrict(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
    try {
      const getOneAxios = await axiosData.get(
        `/General/adPaymentForSelectAllPincode`
      );
      const data = getOneAxios.data.collections;
      if (data) {
        setAddGetPayment(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getAddDetails = async (getDetails) => {
    try {
      const response = await axiosData.get(`addsdetails/OneAd/${getDetails}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const week = response.data;
        setAddGetList(week);
        setWidthSize(week.width);
        setheightSize(week.height);
      }
      if (response.status === 204 || response.data.length === 0) {
        //setLoading(false);
        //setAddGetList("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const stickyDivStyle = {
    width: `${
      radio === "upload_your_add" ? widthSize.split("px")[0] : widthSize
    }`,
    height: `${
      radio === "upload_your_add" ? heightSize.split("px")[0] : heightSize
    }`,
    borderRadius: `${postion === "topimage" ? "0px" : "15px"}`,
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const confirmaddvideo = async () => {
    setIsSubmitting(true);
    var block = videoAd.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    var sliceSize = 2000;
    const videobase = b64toBlob(realData, contentType, sliceSize);
    const addUpload = videobase;
    if (
      pictureLogo.croppedImg === "" ||
      pictureLogo.croppedImg === undefined ||
      pictureLogo.croppedImg === null
    ) {
      const videoThumbnail = "";
      if (addUpload) {
        const url_company = "";
        postAd(
          url_company,
          postion,
          daysvalid,
          adDistrict,
          adPincode,
          addUpload,
          payAmount,
          adPaymentType,
          videoThumbnail,
          getPersonalEmail,
          parseInt(getPersonalMobile),
          getPersonalName
        );
      }
    } else {
      var block1 = pictureLogo.croppedImg.split(";");
      var contentType1 = block1[0].split(":")[1];
      var realData1 = block1[1].split(",")[1];
      var sliceSize1 = 512;
      const img = b64toBlob(realData1, contentType1, sliceSize1);
      const videoThumbnail = img;
      if (addUpload) {
        const url_company = "";
        postAd(
          url_company,
          postion,
          daysvalid,
          adDistrict,
          adPincode,
          addUpload,
          payAmount,
          adPaymentType,
          videoThumbnail,
          getPersonalEmail,
          parseInt(getPersonalMobile),
          getPersonalName
        );
      }
    }
  };

  const confirmadd = async () => {
    setIsSubmitting(true);
    const add = imgget;
    //setadpicdata(add)
    setPicture({
      ...picture,
      img: null,
      cropperOpen: false,
      croppedImg: add,
    });
    var block = add.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    var sliceSize = 2000;
    const imgbase = b64toBlob(realData, contentType, sliceSize);
    const addUpload = imgbase;
    const videoThumbnail = "";
    if (addUpload) {
      setadpicdata({
        ...adpicdata,
        company_url: header.name6,
        image_position: postion,
        days_conut: daysvalid,
        Ad_district: adDistrict,
        Ad_pincode: adPincode,
        addUpload: addUpload,
        pay_amount: payAmount,
        ad_payment_type: adPaymentType,
        ad_email_id: getPersonalEmail,
        ad_mobile_number: parseInt(getPersonalMobile),
        ad_person_name: getPersonalName,
      });
      const url_company = header.name6;
      postAd(
        url_company,
        postion,
        daysvalid,
        adDistrict,
        adPincode,
        addUpload,
        payAmount,
        adPaymentType,
        videoThumbnail,
        getPersonalEmail,
        parseInt(getPersonalMobile),
        getPersonalName
      );
    }
  };
  const postAd = async (
    url_company1,
    postion1,
    daysvalid1,
    adDistrict1,
    adPincode1,
    addUpload1,
    payAmount1,
    adPaymentType1,
    videoThumbnail1,
    getPersonalEmail1,
    getPersonalMobile1,
    getPersonalName1
  ) => {
    try {
      // const getOneAxios = await axiosData.post(
      //   `/advertisement`,
      //   {
      //     company_url: url_company1,
      //     image_position: postion1,
      //     days_conut: daysvalid1,
      //     Ad_district: adDistrict1,
      //     Ad_pincode: adPincode1,
      //     addUpload: addUpload1,
      //     videoThumbnailImage: videoThumbnail1,
      //     total_amount: payAmount1,
      //     ad_payment_type: adPaymentType1,
      //     ad_email_id: getPersonalEmail1,
      //     ad_mobile_number: getPersonalMobile1,
      //     ad_person_name: getPersonalName1,
      //   },
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      navigate(
        ROUTINGDATA.LAND +
          ROUTINGDATA.MAIN +
          ROUTINGDATA.AdvertisePayment,
        {
          state: {
            addata:{
              company_url: url_company1,
              image_position: postion1,
              days_conut: daysvalid1,
              Ad_district: adDistrict1,
              Ad_pincode: adPincode1,
              addUpload: addUpload1,
              videoThumbnailImage: videoThumbnail1,
              total_amount: payAmount1,
              ad_payment_type:adPaymentType1,
              ad_email_id: getPersonalEmail1,
              ad_mobile_number: getPersonalMobile1,
              ad_person_name: getPersonalName1,
            },
            pay_amount: payAmount,
            daysvalid: daysvalid,
            image_position: postion,
            adpicdata: adpicdata,
          },
        }
      );
      // const data = getOneAxios.data;
      // if (data) {
      //   setIsSubmitting(false);
      //   setConfirmAdDialog(false);
      //   //setSuccessPostAd(true);
      //   setContactDialog(true);
      //   setadpicdata({
      //     ...adpicdata,
      //     company_url: "",
      //     image_position: "",
      //     days_conut: "",
      //     Ad_district: "",
      //     Ad_pincode: "",
      //     addUpload: "",
      //     ad_payment_type: "",
      //     ad_email_id: "",
      //     ad_mobile_number: "",
      //     ad_person_name: "",
      //   });
      // }
      return data;
    } catch (err) {
      console.log("Error", err);
    }

   
  };
  const nodeRef = useRef(null);
  // const dowloadimg = () => {
  //   htmlToImage
  //     .toPng(document.getElementById("cpimg"))
  //     .then(function (dataUrl) {
  //       download(dataUrl, "cpimg.png");
  //     });
  // };
  const searchobj = async (values) => {
    setAdDistrict(values);
    try {
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        setPincodeData(getpin[0]);
        setPostOfficeData(getpost[0]);
        setPincode(datapin);
        const temp = getPostFinal.reverse();
        temp.push("Select All");
        temp.reverse();
        setComdata([...temp]);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleEmailBlur = () => {
    if (!getPersonalEmail) {
      setfocus(true);
      setEmailError("Please Enter Your Email ID");
    } else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(getPersonalEmail)) {
      setfocus(false);
      setEmailError("Invalid Email ID Format");
    } else {
      setEmailError("");
    }
  };
  const handleEmailChange =  (event) => {
    setGetPersonalEmail("")
    setfocus(false);
    const email_id = event.target.value.replace(/[^a-z0-9@.]/gi, "")
    setGetPersonalEmail(email_id);
    if (!email_id) {
      setEmailError("Please Enter Your Email ID");
      setfocus(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)
    ) {
      setEmailError("Invalid Email ID Format");
      setfocus(true);
    }else if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)
    ) {
      setfocus(false);
    } else {
      setEmailError("");
      setfocus(false);
    }
  };
  const handleChange = (e) => {
    setGetPersonalMobile("");
    setfocusb(false);
 const mobile = e.target.value.replace(/[^0-9]/gi, "");
 setGetPersonalMobile(mobile)
      if (!mobile) {
        setMobileError("Please Enter Your Mobile Number");
        setfocusb(true);
      } else if (mobile.length > 0 && mobile.length < 10) {
        setMobileError("Mobile number must be 10 digits");
        setfocusb(true);
      } else if (/^\d{10}$/.test(mobile)) {
        setMobileError("");
        setfocusb(false);
      }
  };
  useEffect(() => {
    vodingDistrict();
    // if (location.state?.subscribe === "Yes") {
    //   postAd(location.state.adpicdata);
    // } else {
    setAdDistrict("");
    setAdPincode("");
    setPostOfficeData("");
    getpaymentforallpincode();
    // }
  }, []);
  useEffect(() => {
    setimgeerror("");
    var node = document.getElementById("cpimg");
    // Set the desired width and height for the image
    // const imageWidth = 1152; // Change this value to your desired width
    //const imageHeight = (node.clientHeight / node.clientWidth) * imageWidth;
    htmlToImage
      .toPng(node, { style: { stickyDivStyle } })
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        setimgget(img.src);
        setimgeerror("");
      })
      .catch(function (error) {
        setimgeerror("oops, something went wrong!");
      });
  });
  return (
    <div>
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radio}
            onChange={(e) => {
              setToShowImageLogo("");
              setVideoUpload("");
              setVideoAd("");
              setimgget();
              setradio(e.target.value);
              setDaysvalid("");
              setPostion("");
              setpayAmount(0);
              setselectAllPayAmount(0);
              if(e.target.value === "upload_your_add_video"){
                setAdPaymentType("ad_based")
                setpayAmount(0);
                setselectAllPayAmount(0);
              }else
              if (adPaymentType === "click_based") {
                setpayAmount(
                  parseInt(process.env.REACT_APP_ADVANCE_AMOUNT_FOR_CLICK_AD)
                );
              } else {
                if (daysvalid === "" || postion === "") {
                  setpayAmount(0);
                  setselectAllPayAmount(0);
                } else {
                  const days = String(daysvalid);
                  const getdays = addGetList[days];
                  const paymentAmount = getdays;
                  setpayAmount(paymentAmount);
                }
              }
            }}
          >
            <FormControlLabel
              sx={{
                fontFamily: "Barlow-Bold",
                color: "#108fb2",
                textTransform: "uppercase",
              }}
              control={<Radio value={"create_your_add"} />}
              label="create your ad"
            />
            <FormControlLabel
              sx={{
                fontFamily: "Barlow-Bold",
                color: "#108fb2",
                textTransform: "uppercase",
              }}
              control={<Radio value={"upload_your_add"} />}
              label="upload your ad"
            />
            <Tooltip
              title={
                postion === "popupimage" || postion === "bottomimage"
                  ? null
                  : "Video ad only for popup & flash position"
              }
              arrow
            >
              <FormControlLabel
                sx={{
                  fontFamily: "Barlow-Bold",
                  color: "#108fb2",
                  textTransform: "uppercase",
                }}
                control={<Radio value={"upload_your_add_video"} />}
                label="upload your ad video"
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">
            Select Image Position
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={postion}
            label="Select Image Position"
            defaultValue="image"
            onChange={(e) => {
              setAddGetList("");
              setDaysvalid("");
              setAdDistrict("");
              setAdPincode("");
              setPostion(e.target.value);
              getAddDetails(e.target.value);
              setpayAmount(0);
              setToShowImageLogo("");
              setVideoUpload("");
              setVideoAd("");
              if (
                e.target.value === "centerimage" &&
                templates === "Template_1"
              ) {
                setheader({
                  ...header,
                  name: "HEADER TEXT ",
                  name3: "CREATIVE",
                  name2: "BUSINESS WORK",
                  name4: "We are the best solution for your business",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                e.target.value === "centerimage" &&
                templates === "Template_2"
              ) {
                setheader({
                  ...header,
                  name: "Header Text",
                  name2: "Your Business Grow",
                  name4: "We are the best solution for your business",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                e.target.value === "centerimage" &&
                templates === "Template_3"
              ) {
                setheader({
                  ...header,
                  name3: "We Are",
                  name2: "CREATIVE BUSINESS WORK",
                  name4: "We are the best solution for your business",
                  name8: "JOB OFFERS",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_1" &&
                (e.target.value === "sideimageleft" ||
                  e.target.value === "sideimageright")
              ) {
                setheader({
                  ...header,
                  name: "make your Business",
                  name2: "more advanced and Growing",
                  name3: "We Are",
                  name4: "creative",
                  name8: "business",
                  name7: "100%",
                  name9:
                    "small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_2" &&
                (e.target.value === "sideimageleft" ||
                  e.target.value === "sideimageright")
              ) {
                setheader({
                  ...header,
                  name: "make your Business",
                  name2: "more advanced and Growing",
                  name3: "We Are",
                  name4: "creative",
                  name8: "business",
                  name7: "100%",
                  name9:
                    "small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_3" &&
                (e.target.value === "sideimageleft" ||
                  e.target.value === "sideimageright")
              ) {
                setheader({
                  ...header,
                  name: "make your Business",
                  name2: "more advanced and Growing",
                  name3: "We Are",
                  name4: "creative",
                  name8: "business",
                  name7: "100%",
                  name9:
                    "small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_1" &&
                e.target.value === "popupimage"
              ) {
                setheader({
                  ...header,
                  name: "Enter Your Company Name",
                  name2: "Company Based On?",
                  name3: "Enter Your Email",
                  name5: "**********",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_2" &&
                e.target.value === "popupimage"
              ) {
                setheader({
                  ...header,
                  name: "GROW YOUR",
                  name2: "BUSINESS",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_3" &&
                e.target.value === "popupimage"
              ) {
                setheader({
                  ...header,
                  name: "We Help",
                  name2: "Your Business Grow",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                  name8: "Join now",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_1" &&
                e.target.value === "bottomimage"
              ) {
                setheader({
                  ...header,
                  name: "Header",
                  name2: "business",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect`,
                  name3: "Learn more",
                  name8: "Branding",
                  name7: "Marketing",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_2" &&
                e.target.value === "bottomimage"
              ) {
                setheader({
                  ...header,
                  name: "Business",
                  name2: "advertising services",
                  name3: "join now",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_3" &&
                e.target.value === "bottomimage"
              ) {
                setheader({
                  ...header,
                  name: "join our best team",
                  name2: "hiring!",
                  name3: "We are",
                  name4:
                  `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                  name8: "apply now",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                  name7: "call us:",
                });
              } else if (
                templates === "Template_1" &&
                e.target.value === "topimage"
              ) {
                setheader({
                  ...header,
                  name2: "BUSINESS WORK",
                  name3: "CREATIVE",
                  name4: "We are the best solution for your business",
                  name8: "LEARN MORE",
                  name5: "xxxxxxxx90",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                  name7: "100%",
                });
              } else if (
                templates === "Template_2" &&
                e.target.value === "topimage"
              ) {
                setheader({
                  ...header,
                  name2: "BUSINESS WORK",
                  name3: "CREATIVE",
                  name4: "We are the best solution for your business",
                  name8: "VIEW MORE",
                  name5: "xxxxxxxx90",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_3" &&
                e.target.value === "topimage"
              ) {
                setheader({
                  ...header,
                  name2: "access to cash in just one tap",
                  name3: "Try it now",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else if (
                templates === "Template_1" &&
                e.target.value === "bottomimagefooter"
              ) {
                setheader({
                  ...header,
                  name2: "BUSINESS WORK",
                  name3: "CREATIVE",
                  name4: "We are the best solution for your business",
                  name8: "LEARN MORE",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                  name7: "100%",
                });
              } else if (
                templates === "Template_2" &&
                e.target.value === "bottomimagefooter"
              ) {
                setheader({
                  ...header,
                  name4: "We are the best solution for your business",
                  name8: "LEARN MORE",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                  name7: "100%",
                });
              } else if (
                templates === "Template_3" &&
                e.target.value === "bottomimagefooter"
              ) {
                setheader({
                  ...header,
                  name2: "BUSINESS WORK",
                  name3: "CREATIVE",
                  name4: "We are the best solution for your business",
                  name5: "**********",
                  name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                });
              } else {
                setheader({
                  ...header,
                  name: "",
                });
              }
            }}
          >
            <MenuItem
              value={"topimage"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Top Image(565px X 100px)
            </MenuItem>
            <MenuItem value={"popupimage"}>
              {" "}
              {radio === "upload_your_add_video"
                ? "Popup Video"
                : "Popup Image(670px X 500px)"}
            </MenuItem>
            <MenuItem
              value={"popupfacebookimage"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Popup Image Facebook post size(1080px X 1080px)
            </MenuItem>
            <MenuItem
              value={"sideimageright"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Side Image Right(120px X 600px)
            </MenuItem>
            <MenuItem
              value={"sideimageleft"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Side Image Left(120px X 600px)
            </MenuItem>
            <MenuItem
              value={"centerimage"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Center Image(1092px X 275px)
            </MenuItem>
            <MenuItem value={"bottomimage"}>
              {radio === "upload_your_add_video"
                ? "Flash Video"
                : "Flash Image(250px X 250px)"}
            </MenuItem>
            <MenuItem
              value={"bottomimagefooter"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Bottom Image(800px X 100px)
            </MenuItem>
            <MenuItem
              value={"instagrampostsize"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Center Instagram story size(1080px X 1920px)
            </MenuItem>
            <MenuItem
              value={"facebookpostsize"}
              sx={{
                display: radio === "upload_your_add_video" ? "none" : "",
              }}
            >
              Center Facebook post size (1080px X 1080px)
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">
            Select No of Days
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={daysvalid}
            label="Select No of Days"
            defaultValue="image"
            onChange={(e) => {
              setDaysvalid("");
              if (adPincode === "Select All" || adDistrict === "Select All") {
                if(e.target.values === ""){
                  setselectAllPayAmount(0);
                }else{
                const days = String(e.target.value);
                const getdays =
                  adDistrict === "Select All"
                    ? addGetPaymentDistrict[days]
                    : addGetPayment[days];
                const paymentAmount = getdays;
                setselectAllPayAmount(paymentAmount);
                }
              } else {
                setselectAllPayAmount(0);
              }
              setDaysvalid(e.target.value);
              if (adPaymentType === "click_based") {
                setpayAmount(
                  parseInt(process.env.REACT_APP_ADVANCE_AMOUNT_FOR_CLICK_AD)
                );
              } else {
                const days = String(e.target.value);
                const getdays = addGetList[days];
                const paymentAmount = getdays;
                setpayAmount(paymentAmount);
              }
            }}
          >
            <MenuItem value={"oneweek"}>1 Week</MenuItem>
            <MenuItem value={"twoweek"}>2 Weeks</MenuItem>
            <MenuItem value={"threeweek"}>3 Weeks</MenuItem>
            <MenuItem value={"fourweek"}>4 Weeks</MenuItem>
            <MenuItem value={"fifteendays"}>15 Days</MenuItem>
            <MenuItem value={"thirtydays"}>30 Days</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginLeft: "30px",
          display: "flex",
          gap: "20px",
        }}
      >
        <FormControl>
          <Typography sx={{ marginTop: 2 }}>
            <b>Ad Payment Type</b>
          </Typography>
          <RadioGroup
            sx={{ marginTop: "5px" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={adPaymentType}
            onChange={(e) => {
              setAdPaymentType("")
              setToShowImageLogo("");
              setVideoUpload("");
              setVideoAd("");
              setimgget();
              setAdPaymentType(e.target.value);
              if(radio === "upload_your_add_video"){
                setAdPaymentType("ad_based");
              }
              if (e.target.value === "click_based") {
                setpayAmount(
                  parseInt(process.env.REACT_APP_ADVANCE_AMOUNT_FOR_CLICK_AD)
                );
              } else { if (daysvalid === "" || postion === "") {
                setpayAmount(0);
                setselectAllPayAmount(0);
              } else{
                const days = String(daysvalid);
                const getdays = addGetList[days];
                const paymentAmount = getdays;
                setpayAmount(paymentAmount);
              }
              }
            }}
          >
            <FormControlLabel
              sx={{
                fontFamily: "Barlow-Bold",
                color: "#108fb2",
                textTransform: "uppercase",
              }}
              control={<Radio value={"ad_based"} />}
              label="Ad Based Payment"
            />
            <FormControlLabel
             disabled={
              radio === "upload_your_add_video"
                ? "true"
                : false
            }
              sx={{
                // display:
                // radio === "upload_your_add_video"
                //   ? "none"
                //   : "block",
                fontFamily: "Barlow-Bold",
                color: "#108fb2",
                textTransform: "uppercase",
              }}
              control={<Radio value={"click_based"} />}
              label="Click Based Payment"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {adPaymentType === "click_based" ? (
        <div className="box-for-priceing">
          <div>
            <p>
              Based on ad payment type the advance ad price is{" "}
              <span>
                {" "}
                <CurrencyRupeeIcon
                  sx={{
                    fontSize: "15px",
                    color: "#038fc7",
                  }}
                />
                {parseInt(process.env.REACT_APP_ADVANCE_AMOUNT_FOR_CLICK_AD)}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="box-for-priceing">
          <div>
            <p>
              Based on ad position and days validity the ad price is{" "}
              <span>
                {" "}
                <CurrencyRupeeIcon
                  sx={{
                    fontSize: "15px",
                    color: "#038fc7",
                  }}
                />
                {payAmount}
              </span>
            </p>
          </div>
          {radio === "create_your_add" || radio === "upload_your_add_video" ? (
            <div>
              <p style={{ color: "red" }}>
              An additional amount of{" "} <CurrencyRupeeIcon
                  sx={{
                    width: "15px",
                    height: "13px",
                    marginRight: "-6px",
                    fontSize: "17px",
                    color: "#ff0000",
                  }}
                />{" "}
                {radio === "upload_your_add_video"
                  ? `${parseInt(process.env.REACT_APP_AMOUNT_FOR_VIDEO_AD)}`
                  : `${parseInt(process.env.REACT_APP_AMOUNT_FOR_CREATE_AD)}`} is added for{" "} 
                {radio === "upload_your_add_video" ? "video" : "create"}{" "}ad
              ,Total amount is{" "}
                <span>
                  {" "}
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "15px",
                      color: "#038fc7",
                    }}
                  />
                  {radio === "upload_your_add_video"
                    ? payAmount +
                      parseInt(process.env.REACT_APP_AMOUNT_FOR_VIDEO_AD)
                    : payAmount +
                      parseInt(process.env.REACT_APP_AMOUNT_FOR_CREATE_AD)}
                </span>
              </p>
            </div>
          ) : null}
          {adPincode === "Select All" || adDistrict === "Select All" ? (
            <div>
              <p style={{ color: "red" }}>
              An additional amount of  <CurrencyRupeeIcon
                  sx={{
                    width: "15px",
                    height: "13px",
                    marginRight: "-6px",
                    fontSize: "17px",
                    color: "#ff0000",
                  }}
                />{" "}
                {adDistrict === "Select All" ?selectAllpayAmount * 2:selectAllpayAmount}{" "}
                is added for selected all{" "}{adDistrict === "Select All" ?"district":"pincodes"}
               ,{adDistrict === "Select All"?"After 50% discount for all district amount is":""} {adDistrict === "Select All"?(<CurrencyRupeeIcon
                  sx={{
                    width: "15px",
                    height: "13px",
                    marginRight: "-6px",
                    fontSize: "17px",
                    color: "#ff0000",
                  }}
                />):null}{" "}{adDistrict === "Select All"?`${selectAllpayAmount},`:""}
                <p>Finally Your Total amount is{" "}
                <span>
                  {" "}
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "15px",
                      color: "#038fc7",
                    }}
                  />
                  {radio === "create_your_add"
                    ? payAmount +
                      parseInt(process.env.REACT_APP_AMOUNT_FOR_CREATE_AD) +
                      selectAllpayAmount
                    : radio === "upload_your_add_video"
                    ? payAmount +
                      parseInt(process.env.REACT_APP_AMOUNT_FOR_VIDEO_AD) +
                      selectAllpayAmount
                    : payAmount + selectAllpayAmount}
                </span>
                </p>
              </p>
            </div>
          ) : null}
        </div>
      )}

      <div className="topsapce-banner">
        {radio === "create_your_add" ? (
          <div className="banner-creation">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Autocomplete
                disableClearable={adDistrict ? false : true}
                disablePortal
                id="district_name"
                name="district_name"
                options={districtData}
                value={adDistrict}
                onChange={(event, value) => {
                  if (value === "Select All") {
                    if(daysvalid === ""){
                      setselectAllPayAmount(0);
                      setAdPincode("Select All");
                    }else{
                    const days = String(daysvalid);
                    const getdays = addGetPaymentDistrict[days];
                    const paymentAmount = getdays;
                    setselectAllPayAmount(paymentAmount);
                    setAdPincode("Select All");
                    }
                  } else {
                    searchobj(value);
                    setselectAllPayAmount(0);
                    setAdPincode("");
                  }
                  setAdDistrict(value);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => {
                      setAdDistrict(e.target.value);
                    }}
                    {...params}
                    label={"Select District Name"}
                  />
                )}
              />
              <Tooltip
                title={adDistrict === "" ? "Select district first" : null}
                arrow
              >
                <Autocomplete
                  disableClearable={adPincode ? false : true}
                  disabled={
                    adDistrict === null ||
                    adDistrict === undefined ||
                    adDistrict === "" ||
                    adDistrict === "Select All"
                      ? "true"
                      : false
                  }
                  disablePortal
                  id="pincode"
                  name="pincode"
                  options={comdata}
                  value={adPincode}
                  onChange={(event, value) => {
                    setAdPincode(value);
                    if (value === "Select All" || adDistrict === "Select All") {
                      if(daysvalid === ""){
                        setselectAllPayAmount(0);
                      }else{
                      const days = String(daysvalid);
                      const getdays =
                        adDistrict === "Select All"
                          ? addGetPaymentDistrict[days]
                          : addGetPayment[days];
                      const paymentAmount = getdays;
                      setselectAllPayAmount(paymentAmount);
                      }
                    } else {
                      setselectAllPayAmount(0);
                    }
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      onChange={(e) => {
                        setAdPincode(e.target.value);
                      }}
                      {...params}
                      label={"Select Pincode"}
                    />
                  )}
                />
              </Tooltip>
            </div>
            <Divider sx={{ padding: "10px", marginBottom: "25px" }} />

            <div className="space-between">
              <div class="section-title-add">
                <h2>Banner creation</h2>
                {/* <p>Local Jobs</p> */}
              </div>
              <div>
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Templates
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={templates}
                    label="No of Days"
                    defaultValue="image"
                    onChange={(e) => {
                      setTemplates(e.target.value);
                      if (
                        e.target.value === "Template_1" &&
                        postion === "centerimage"
                      ) {
                        setheader({
                          ...header,
                          name: "HEADER TEXT ",
                          name3: "CREATIVE",
                          name2: "BUSINESS WORK",
                          name4: "We are the best solution for your business",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        postion === "centerimage" &&
                        e.target.value === "Template_2"
                      ) {
                        setheader({
                          ...header,
                          name: "Header Text",
                          name2: "Your Business Grow",
                          name4: "We are the best solution for your business",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        postion === "centerimage" &&
                        e.target.value === "Template_3"
                      ) {
                        setheader({
                          ...header,
                          name3: "We Are",
                          name2: "CREATIVE BUSINESS WORK",
                          name4: "We are the best solution for your business",
                          name8: "JOB OFFERS",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        (postion === "sideimageleft" ||
                          postion === "sideimageright")
                      ) {
                        setheader({
                          ...header,
                          name: "make your Business",
                          name2: "more advanced and Growing",
                          name3: "We Are",
                          name4: "creative",
                          name8: "business",
                          name7: "100%",
                          name9:
                            "Small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_2" &&
                        (postion === "sideimageleft" ||
                          postion === "sideimageright")
                      ) {
                        setheader({
                          ...header,
                          name: "make your Business",
                          name2: "more advanced and Growing",
                          name3: "We Are",
                          name4: "creative",
                          name8: "business",
                          name7: "100%",
                          name9:
                            "Small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_3" &&
                        (postion === "sideimageleft" ||
                          postion === "sideimageright")
                      ) {
                        setheader({
                          ...header,
                          name: "make your Business",
                          name2: "more advanced and Growing",
                          name3: "We Are",
                          name4: "creative",
                          name8: "business",
                          name7: "100%",
                          name9:
                            "Small content MAKE YOUR BUSINESS MORE ADVANCED AND GROWING",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "popupimage"
                      ) {
                        setheader({
                          ...header,
                          name: "Enter Your Company Name",
                          name2: "Company Based On?",
                          name3: "Enter Your Email",
                          name5: "**********",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_2" &&
                        postion === "popupimage"
                      ) {
                        setheader({
                          ...header,
                          name: "GROW YOUR",
                          name2: "BUSINESS",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_3" &&
                        postion === "popupimage"
                      ) {
                        setheader({
                          ...header,
                          name: "We Help",
                          name2: "Your Business Grow",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                          name8: "Join now",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "bottomimage"
                      ) {
                        setheader({
                          ...header,
                          name: "Header",
                          name2: "business",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect`,
                          name3: "Learn more",
                          name8: "Branding",
                          name7: "Marketing",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_2" &&
                        postion === "bottomimage"
                      ) {
                        setheader({
                          ...header,
                          name: "Business",
                          name2: "advertising services",
                          name3: "join now",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_3" &&
                        postion === "bottomimage"
                      ) {
                        setheader({
                          ...header,
                          name: "join our best team",
                          name2: "hiring!",
                          name3: "We are",
                          name4:
                          `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect, share knowledge and share job opportunities helping in overall development community.`,
                          name8: "apply now",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                          name7: "call us:",
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "topimage"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "xxxxxxxx90",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                          name7: "100%",
                        });
                      } else if (
                        e.target.value === "Template_2" &&
                        postion === "topimage"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "VIEW MORE",
                          name5: "xxxxxxxx90",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_3" &&
                        postion === "topimage"
                      ) {
                        setheader({
                          ...header,
                          name2: "access to cash in just one tap",
                          name3: "Try it now",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "bottomimagefooter"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                          name7: "100%",
                        });
                      } else if (
                        e.target.value === "Template_2" &&
                        postion === "bottomimagefooter"
                      ) {
                        setheader({
                          ...header,
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                          name7: "100%",
                        });
                      } else if (
                        e.target.value === "Template_3" &&
                        postion === "bottomimagefooter"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name5: "**********",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "instagrampostsize"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "xxxxxxxx90",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "facebookpostsize"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "xxxxxxxx90",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else if (
                        e.target.value === "Template_1" &&
                        postion === "popupfacebookimage"
                      ) {
                        setheader({
                          ...header,
                          name2: "BUSINESS WORK",
                          name3: "CREATIVE",
                          name4: "We are the best solution for your business",
                          name8: "LEARN MORE",
                          name5: "xxxxxxxx90",
                          name6: process.env.REACT_APP_DEFAULT_URL_FOR_AD,
                        });
                      } else {
                        setheader({
                          ...header,
                          name: "",
                        });
                      }
                    }}
                  >
                    <MenuItem value={"Template_1"}>Template 1</MenuItem>
                    <MenuItem
                      value={"Template_2"}
                      sx={{
                        display:
                          postion === "instagrampostsize" ||
                          postion === "facebookpostsize" ||
                          postion === "popupfacebookimage"
                            ? "none"
                            : "block",
                      }}
                    >
                      Template 2
                    </MenuItem>
                    <MenuItem
                      value={"Template_3"}
                      sx={{
                        display:
                          postion === "instagrampostsize" ||
                          postion === "facebookpostsize" ||
                          postion === "popupfacebookimage"
                            ? "none"
                            : "block",
                      }}
                    >
                      Template 3
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ display: "grid", placeItems: "center" }}>
              <div
                ref={nodeRef}
                id="cpimg"
                className="banner-div"
                style={{
                  stickyDivStyle,
                  borderRadius: postion === "topimage" ? "0px" : "15px",
                }}
              >
                {templates === "Template_1" ? (
                  <div className="image-box-banner" style={stickyDivStyle}>
                    <img
                      src={
                        postion === "centerimage"
                          ? center_frist_banner
                          : postion === "sideimageleft" ||
                            postion === "sideimageright"
                          ? side_frist_banner
                          : postion === "popupimage"
                          ? popup_banner_one
                          : postion === "bottomimage"
                          ? flash_banner_one
                          : postion === "topimage"
                          ? Topbanner_1
                          : postion === "bottomimagefooter"
                          ? bottomfooter_1
                          : postion === "instagrampostsize"
                          ? istagram_post_1
                          : postion === "facebookpostsize"
                          ? facebookpost_1
                          : postion === "popupfacebookimage"
                          ? poppupfacebook_1
                          : center_frist_banner
                      }
                      style={stickyDivStyle}
                      alt="img"
                    />
                    {postion === "centerimage" ? (
                      <>
                        <div className="ab-banner center_frist_banner-header">
                          {header.name !== "" && (
                            <text style={{ display: "block" }}>
                              {header.name}
                            </text>
                          )}
                          {header.name3 !== "" && (
                            <text
                              style={{ display: "block" }}
                              className="uniqueword"
                            >
                              {header.name3}
                            </text>
                          )}
                          {header.name2 !== "" && (
                            <text style={{ display: "block" }}>
                              {header.name2}{" "}
                            </text>
                          )}
                        </div>
                        <div className="ab-banner center_frist_banner-sententens">
                          {header.name4 !== "" && <p>"{header.name4}"</p>}
                        </div>
                        <div className="ab-banner center_frist_banner-contactus">
                          <div className="ali-banner">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon sx={{ color: "#0675a2" }} />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon sx={{ color: "#0675a2" }} />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner center_frist_banner-sidecontect">
                          {header.name8 !== "" && <text>{header.name8}</text>}
                        </div>
                        <div className="ab-banner center_frist_banner-offers">
                          {header.name7 !== "" && <text>{header.name7}</text>}
                        </div>
                        <div className="ab-banner center_frist_banner-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "sideimageleft" ||
                      postion === "sideimageright" ? (
                      <>
                        <div className="ab-banner sideimage-banner-1-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-1-centercontect">
                          <text>{header.name3}</text>
                          <text>{header.name4}</text>
                          <text>{header.name8}</text>
                          <p>{header.name9}</p>
                        </div>
                        <div className="ab-banner sideimage-banner-1-contactus">
                          <text>Call us</text>
                        </div>
                        <div className="ab-banner sideimage-banner-1-contactus-2">
                          <text>{header.name5}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-1-offers">
                          <text>{header.name7}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "popupimage" ? (
                      <>
                        <div className="ab-banner popup-banner-1-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner popup-banner-1-contact">
                          <text>Contact Us</text>
                        </div>
                        <div className="ab-banner popup-banner-1-contact-detalis">
                          <text>{header.name3}</text>
                          <text>{header.name5}</text>
                          <text>{header.name6}</text>
                        </div>
                        <div className="ab-banner popup-banner-1-aboutus">
                          <text>About Company</text>
                        </div>
                        <div className="ab-banner popup-banner-1-aboutus-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner popup-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimage" ? (
                      <>
                        <div className="ab-banner flash-banner-1-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner flash-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner flash-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner flash-banner-1-title">
                          <text>{header.name8}</text>
                          <text>{header.name7}</text>
                        </div>
                        <div className="ab-banner flash-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner flash-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "topimage" ? (
                      <>
                        <div className="ab-banner top-banner-1-header">
                          <text>{}</text>
                          <text>{}</text>
                        </div>
                        <div className="ab-banner top-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner top-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner top-banner-1-title">
                          <text>{header.name8}</text>
                          <text>{header.name7}</text>
                        </div>
                        <div className="ab-banner top-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner top-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimagefooter" ? (
                      <>
                        <div className="ab-banner bottomfooter-banner-1-header">
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-title">
                          <text>{header.name8}</text>
                          <text>{header.name7}</text>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "instagrampostsize" ? (
                      <>
                        <div className="ab-banner instagram-banner-1-header">
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner instagram-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner instagram-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner instagram-banner-1-title">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner instagram-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#000000", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#000000", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner instagram-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "facebookpostsize" ? (
                      <>
                        <div className="ab-banner facebook-banner-1-header">
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner facebook-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner facebook-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner facebook-banner-1-title">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner facebook-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  backgroundColor: "yellow",
                                  borderRadius: "50%",
                                  padding: "1px",
                                }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  backgroundColor: "yellow",
                                  borderRadius: "50%",
                                  padding: "1px",
                                }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner facebook-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "popupfacebookimage" ? (
                      <>
                        <div className="ab-banner facebookpoppup-banner-1-header">
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner facebookpoppup-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner facebookpoppup-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner facebookpoppup-banner-1-title">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner facebookpoppup-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  backgroundColor: "yellow",
                                  borderRadius: "50%",
                                  padding: "1px",
                                }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  backgroundColor: "yellow",
                                  borderRadius: "50%",
                                  padding: "1px",
                                }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner facebookpoppup-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : templates === "Template_2" ? (
                  <div className="image-box-banner" style={stickyDivStyle}>
                    <img
                      src={
                        postion === "centerimage"
                          ? center_secound_banner
                          : postion === "sideimageleft" ||
                            postion === "sideimageright"
                          ? side_secound_banner
                          : postion === "popupimage"
                          ? popup_secound_banner
                          : postion === "bottomimage"
                          ? flash_secound_banner
                          : postion === "topimage"
                          ? Topbanner_2
                          : postion === "bottomimagefooter"
                          ? bottomfooter_2
                          : postion === "instagrampostsize"
                          ? instagramimg
                          : postion === "facebookpostsize"
                          ? facebookimg
                          : center_secound_banner
                      }
                      style={stickyDivStyle}
                      alt="img"
                    />
                    {postion === "centerimage" ? (
                      <>
                        <div className="ab-banner center_secound_banner-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner center_secound_banner-sidecontect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner center_secound_banner-contactus">
                          <div className="ali-banner-2">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon sx={{ color: "#0675a2" }} />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-2">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon sx={{ color: "#0675a2" }} />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner center_secound_banner-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "sideimageleft" ||
                      postion === "sideimageright" ? (
                      <>
                        <div className="ab-banner sideimage-banner-2-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-2-centercontect">
                          <text>{header.name3}</text>
                          <text>{header.name4}</text>
                          <text>{header.name8}</text>
                          <p>{header.name9}</p>
                        </div>
                        <div className="ab-banner sideimage-banner-2-contactus">
                          <text>Call Us</text>
                        </div>
                        <div className="ab-banner sideimage-banner-2-contactus-2">
                          <text>{header.name5}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-2-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "popupimage" ? (
                      <>
                        <div className="ab-banner popup-banner-2-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner popup-banner-2-aboutus-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner popup-banner-2-aboutus-contactus">
                          <div className="ali-banner" style={{ gap: "10px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon sx={{ color: "#0675a2" }} />
                            </div>
                            <div style={{ flexGrow: "1" }}>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner" style={{ gap: "10px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon sx={{ color: "#0675a2" }} />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner popup-banner-2-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimage" ? (
                      <>
                        <div className="ab-banner flash-banner-2-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner flash-banner-2-aboutus">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner flash-banner-2-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner flash-banner-2-contactus">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#038fc7", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#038fc7", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner flash-banner-2-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "topimage" ? (
                      <>
                        <div className="ab-banner top-banner-2-header">
                          <text>{}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner top-banner-2-contect">
                          <p>"{header.name4}"</p>
                        </div>
                        <div className="ab-banner top-banner-2-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner top-banner-2-title">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner top-banner-2-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#194176", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#194176", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner top-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimagefooter" ? (
                      <>
                        <div className="ab-banner bottomfooter-banner-1-contect">
                          <p>{header.name4}</p>
                        </div>

                        <div className="ab-banner bottomfooter-banner-2-title">
                          <text>{header.name8}</text>
                          <text>{header.name7}</text>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="image-box-banner" style={stickyDivStyle}>
                    {" "}
                    <img
                      src={
                        postion === "centerimage"
                          ? center_3rd_banner
                          : postion === "sideimageleft" ||
                            postion === "sideimageright"
                          ? side_3rd_banner
                          : postion === "popupimage"
                          ? popup_3rd_banner
                          : postion === "bottomimage"
                          ? flash_3rd_banner
                          : postion === "topimage"
                          ? Topbanner_3
                          : postion === "bottomimagefooter"
                          ? bottomfooter_3
                          : postion === "instagrampostsize"
                          ? instagramimg2
                          : postion === "facebookpostsize"
                          ? facebookimg3
                          : center_frist_banner
                      }
                      style={stickyDivStyle}
                      alt="img"
                    />
                    {postion === "centerimage" ? (
                      <>
                        <div className="ab-banner center_3rd_banner-header">
                          <text>{header.name3}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner center_3rd_banner-sidecontect">
                          <text>{header.name4}</text>
                        </div>
                        <div className="ab-banner center_3rd_banner-contactus">
                          <div className="ali-banner-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon sx={{ color: "#0675a2" }} />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon sx={{ color: "#0675a2" }} />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner center_3rd_banner-offers">
                          {header.name7 !== "" && <text>{header.name7}</text>}
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner center_3rd_banner-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "sideimageleft" ||
                      postion === "sideimageright" ? (
                      <>
                        <div className="ab-banner sideimage-banner-3-centercontect">
                          <text>{header.name3}</text>
                          <text>{header.name4}</text>
                          <text>{header.name8}</text>
                          <p>{header.name9}</p>
                        </div>
                        <div className="ab-banner sideimage-banner-3-contactus">
                          <text>Call us</text>
                        </div>
                        <div className="ab-banner sideimage-banner-3-contactus-2">
                          <text>{header.name5}</text>
                        </div>
                        <div className="ab-banner sideimage-banner-3-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "popupimage" ? (
                      <>
                        <div className="ab-banner popup-banner-3-header">
                          <text>{header.name}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner popup-banner-3-btn">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner popup-banner-3-aboutus">
                          <text>About Us</text>
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner popup-banner-3-contactus">
                          <div className="ali-banner-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#0675a2", fontSize: "18px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#0675a2", fontSize: "18px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner popup-banner-3-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimage" ? (
                      <>
                        <div className="ab-banner flash-banner-3-header">
                          <text>{header.name3}</text>
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner flash-banner-3-box-text">
                          <text>{header.name}</text>
                        </div>
                        <div className="ab-banner flash-banner-3-aboutus">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner flash-banner-3-btn">
                          <text>{header.name8}</text>
                        </div>
                        <div className="ab-banner flash-banner-3-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#038fc7", fontSize: "15px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner flash-banner-3-callus">
                          <text>{header.name7}</text>
                          <text>{header.name5}</text>
                        </div>
                        <div className="ab-banner flash-banner-3-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "topimage" ? (
                      <>
                        <div className="ab-banner top-banner-3-header">
                          <text>{header.name2}</text>
                        </div>

                        <div className="ab-banner top-banner-3-btn">
                          <text>{header.name3}</text>
                        </div>
                        <div className="ab-banner top-banner-3-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : postion === "bottomimagefooter" ? (
                      <>
                        <div className="ab-banner bottomfooter-banner-1-header">
                          <text>{header.name2}</text>
                        </div>
                        <div className="ab-banner bottomfooter-banner-3-contect">
                          <p>{header.name4}</p>
                        </div>
                        <div className="ab-banner bottomfooter-banner-1-btn">
                          <text>{header.name3}</text>
                        </div>

                        <div className="ab-banner bottomfooter-banner-3-contact">
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PhoneIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>
                            <div>
                              {header.name5 !== "" && (
                                <text>{header.name5}</text>
                              )}
                            </div>
                          </div>
                          <div className="ali-banner-flash">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LanguageIcon
                                sx={{ color: "#ffff", fontSize: "10px" }}
                              />
                            </div>{" "}
                            <div>
                              {header.name6 !== "" && (
                                <text>{header.name6}</text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ab-banner bottomfooter-banner-3-logo">
                          <img
                            src={
                              toShowImageLogo === ""
                                ? defaultimg
                                : toShowImageLogo
                            }
                            alt="Companylogo"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="banner-inputs">
              <div className="inuputsgapsforbanner">
                <Tooltip title="upload logo" arrow>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={uploadEnable}
                    onClick={() => {
                      setToShowImageLogo("");
                      fileInputField.current.click();
                    }}
                  >
                    Upload Your Logo
                  </Button>
                </Tooltip>
                {logoUpload ? (
                  <div style={{ color: "red" }}> {dataMPError}</div>
                ) : null}
                <input
                  ref={fileInputField}
                  style={{ visibility: "hidden" }}
                  type="file"
                  accept="image/*,.jpg,.jpeg,.png"
                  onChange={handleFileChangeLogo}
                />
              </div>
              <div className="inuputsgapsforbanner">
                <TextField
                  id="outlined-basic"
                  sx={{
                    display:
                      (postion === "centerimage" &&
                        templates === "Template_3") ||
                      postion === "topimage" ||
                      postion === "bottomimagefooter" ||
                      postion === "instagrampostsize" ||
                      postion === "facebookpostsize" ||
                      postion === "popupfacebookimage"
                        ? "none"
                        : "",
                  }}
                  label={
                    postion === "sideimageleft" || postion === "sideimageright"
                      ? "make your Business"
                      : templates === "Template_3" && postion === "popupimage"
                      ? "We Help"
                      : templates === "Template_2" && postion === "popupimage"
                      ? "GROW YOUR"
                      : templates === "Template_1" && postion === "popupimage"
                      ? "Enter Your Company Name"
                      : templates === "Template_2" && postion === "bottomimage"
                      ? "Business"
                      : templates === "Template_3" && postion === "bottomimage"
                      ? "join our best team"
                      : "Header"
                  }
                  variant="outlined"
                  fullWidth
                  // defaultValue={"header"}
                  value={header.name}
                  onChange={(e) => {
                    setheader({ ...header, name: e.target.value });
                  }}
                />
                <TextField
                  sx={{
                    display:
                      templates === "Template_2" &&
                      postion === "bottomimagefooter"
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    postion === "centerimage" && templates === "Template_2"
                      ? "Your Business Grow"
                      : templates === "Template_3" && postion === "popupimage"
                      ? "Your Business Grow"
                      : templates === "Template_2" && postion === "popupimage"
                      ? "BUSINESS"
                      : templates === "Template_1" && postion === "popupimage"
                      ? "Company Based On?"
                      : templates === "Template_1" && postion === "bottomimage"
                      ? "business"
                      : postion === "sideimageleft" ||
                        postion === "sideimageright"
                      ? "more advanced and Growing"
                      : postion === "centerimage" && templates === "Template_3"
                      ? "CREATIVE BUSINESS WORK"
                      : templates === "Template_2" && postion === "bottomimage"
                      ? "advertising services"
                      : templates === "Template_3" && postion === "bottomimage"
                      ? "hiring!"
                      : templates === "Template_3" && postion === "topimage"
                      ? "Access to cash in just one tap"
                      : (templates === "Template_1" &&
                          postion === "bottomimagefooter") ||
                        (templates === "Template_3" &&
                          postion === "bottomimagefooter")
                      ? "BUSINESS WORK"
                      : "Business work"
                  }
                  variant="outlined"
                  fullWidth
                  value={header.name2}
                  onChange={(e) => {
                    setheader({ ...header, name2: e.target.value });
                  }}
                />
              </div>
              <div className="inuputsgapsforbanner">
                <TextField
                  sx={{
                    display:
                      (postion === "centerimage" &&
                        templates === "Template_2") ||
                      (postion === "popupimage" &&
                        templates === "Template_2") ||
                      (postion === "popupimage" &&
                        templates === "Template_3") ||
                      (templates === "Template_2" &&
                        postion === "bottomimagefooter")
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    postion === "centerimage" && templates === "Template_1"
                      ? "Creative"
                      : templates === "Template_1" && postion === "popupimage"
                      ? "Email"
                      : templates === "Template_2" && postion === "bottomimage"
                      ? "join now"
                      : (templates === "Template_1" ||
                          templates === "Template_2") &&
                        postion === "topimage"
                      ? "CREATIVE"
                      : templates === "Template_3" && postion === "topimage"
                      ? "Try it now"
                      : (templates === "Template_1" &&
                          postion === "bottomimagefooter") ||
                        (templates === "Template_3" &&
                          postion === "bottomimagefooter")
                      ? "CREATIVE"
                      : "We Are"
                  }
                  variant="outlined"
                  value={header.name3}
                  onChange={(e) => {
                    setheader({ ...header, name3: e.target.value });
                  }}
                  fullWidth
                />
                <TextField
                  sx={{
                    display:
                      templates === "Template_3" && postion === "topimage"
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    postion === "centerimage"
                      ? "We are the best solution for your business"
                      : postion === "sideimageleft" ||
                        postion === "sideimageright"
                      ? "creative"
                      : (templates === "Template_1" ||
                          templates === "Template_2") &&
                        postion === "topimage"
                      ? "We are the best solution for your business"
                      : postion === "bottomimagefooter"
                      ? "We are the best solution for your business"
                      : `${process.env.REACT_APP_WEBSITE_NAME} is a safe platform where people can connect`
                  }
                  variant="outlined"
                  value={header.name4}
                  onChange={(e) => {
                    setheader({ ...header, name4: e.target.value });
                  }}
                  fullWidth
                />
              </div>
              <div className="inuputsgapsforbanner">
                <TextField
                  sx={{
                    display:
                      templates === "Template_3" && postion === "topimage"
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label="Contact Number"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  value={header.name5}
                  onChange={(e) => {
                    if (e) {
                      const value = e.target.value.replace(/[^0-9]/gi, "");
                      setheader({ ...header, name5: value });
                    }
                  }}
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  label={`Enter your company URL(Ex:${process.env.REACT_APP_DEFAULT_URL_FOR_AD})`}
                  variant="outlined"
                  value={header.name6}
                  onChange={(e) => {
                    const inputUrl = e.target.value;
                    setheader({ ...header, name6: inputUrl });
                    const urlPattern =
                      /^(ftp|http|https):\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]+.*/;
                    setIsValid(urlPattern.test(inputUrl));
                  }}
                  fullWidth
                />
              </div>
              {isValid ? null : (
                <div className="inuputsgapsforbannererror">
                  <p style={{ color: "red" }}>Invalid URL</p>
                </div>
              )}
              <div className="inuputsgapsforbanner">
                <TextField
                  sx={{
                    display:
                      (postion === "centerimage" &&
                        templates === "Template_2") ||
                      postion === "popupimage" ||
                      (templates === "Template_2" &&
                        postion === "bottomimage") ||
                      (templates === "Template_2" && postion === "topimage") ||
                      (templates === "Template_3" && postion === "topimage") ||
                      (templates === "Template_3" &&
                        postion === "bottomimagefooter") ||
                      (templates === "Template_1" &&
                        postion === "instagrampostsize") ||
                      postion === "facebookpostsize"
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    templates === "Template_3" && postion === "bottomimage"
                      ? "Call Us"
                      : templates === "Template_1" && postion === "bottomimage"
                      ? "Marketing"
                      : (templates === "Template_1" &&
                          postion === "bottomimagefooter") ||
                        (templates === "Template_2" &&
                          postion === "bottomimagefooter")
                      ? "%"
                      : "%"
                  }
                  variant="outlined"
                  value={header.name7}
                  onChange={(e) => {
                    setheader({ ...header, name7: e.target.value });
                  }}
                  fullWidth
                />
                <TextField
                  sx={{
                    display:
                      (postion === "centerimage" &&
                        templates === "Template_2") ||
                      (postion === "popupimage" &&
                        templates === "Template_1") ||
                      (postion === "popupimage" &&
                        templates === "Template_2") ||
                      (templates === "Template_2" &&
                        postion === "bottomimage") ||
                      (templates === "Template_3" && postion === "topimage") ||
                      (templates === "Template_3" &&
                        postion === "bottomimagefooter")
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    postion === "centerimage" && templates === "Template_3"
                      ? "JOB OFFERS"
                      : templates === "Template_1" && postion === "bottomimage"
                      ? "Branding"
                      : postion === "sideimageleft" ||
                        postion === "sideimageright"
                      ? "business"
                      : templates === "Template_3" && postion === "popupimage"
                      ? "Join now"
                      : templates === "Template_3" && postion === "bottomimage"
                      ? "apply now"
                      : templates === "Template_2" && postion === "topimage"
                      ? "VIEW MORE"
                      : (templates === "Template_1" &&
                          postion === "bottomimagefooter") ||
                        (templates === "Template_2" &&
                          postion === "bottomimagefooter")
                      ? "LEARN MORE"
                      : "LEARN MORE"
                  }
                  variant="outlined"
                  value={header.name8}
                  onChange={(e) => {
                    setheader({ ...header, name8: e.target.value });
                  }}
                  fullWidth
                />
              </div>
              <div className="inuputsgapsforbanner">
                <TextField
                  sx={{
                    display:
                      postion === "centerimage" ||
                      postion === "popupimage" ||
                      postion === "bottomimage" ||
                      postion === "topimage" ||
                      postion === "instagrampostsize" ||
                      postion === "facebookpostsize" ||
                      postion === "bottomimagefooter" ||
                      postion === "popupfacebookimage"
                        ? "none"
                        : "",
                  }}
                  id="outlined-basic"
                  label={
                    postion === "sideimageleft" || postion === "sideimageright"
                      ? "Small Content"
                      : "Content"
                  }
                  variant="outlined"
                  value={header.name9}
                  onChange={(e) => {
                    setheader({ ...header, name9: e.target.value });
                  }}
                  fullWidth
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="banner-creation">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Autocomplete
                disableClearable={adDistrict ? false : true}
                disablePortal
                id="district_name"
                name="district_name"
                options={districtData}
                onChange={(event, value) => {
                  if (value === "Select All") {
                    if(daysvalid === ""){
                      setselectAllPayAmount(0);
                      setAdPincode("Select All");
                    }else{
                    const days = String(daysvalid);
                    const getdays = addGetPaymentDistrict[days];
                    const paymentAmount = getdays;
                    setselectAllPayAmount(paymentAmount);
                    setAdPincode("Select All");
                    }
                  } else {
                    setselectAllPayAmount(0);
                    setAdPincode("");
                    searchobj(value);
                  }
                  setAdDistrict(value);
                }}
                value={adDistrict}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => {
                      setAdDistrict(e.target.value);
                    }}
                    {...params}
                    label={"Select District Name"}
                    id="district_name"
                    name="district_name"
                  />
                )}
              />

              <Autocomplete
                disableClearable={adPincode ? false : true}
                disabled={
                  adDistrict === null ||
                  adDistrict === undefined ||
                  adDistrict === "" ||
                  adDistrict === "Select All"
                    ? "true"
                    : false
                }
                disablePortal
                id="pincode"
                name="pincode"
                options={comdata}
                value={adPincode}
                onChange={(event, value) => {
                  setAdPincode(value);
                  if (value === "Select All") {
                    if(daysvalid === ""){
                      setselectAllPayAmount(0);
                    }else{
                    const days = String(daysvalid);
                    const getdays =
                      adDistrict === "Select All"
                        ? addGetPaymentDistrict[days]
                        : addGetPayment[days];
                    const paymentAmount = getdays;
                    setselectAllPayAmount(paymentAmount);
                    }
                  }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => {
                      setAdPincode(e.target.value);
                    }}
                    {...params}
                    label={"Select Pincode"}
                  />
                )}
              />
            </div>
            <Divider sx={{ padding: "10px", marginBottom: "25px" }} />
            <div className="space-between">
              <div class="section-title-add">
                <h2>
                  {radio === "upload_your_add_video"
                    ? "upload video"
                    : "upload Banner"}{" "}
                </h2>
                {/* <p>Local Jobs</p> */}
              </div>
              <div
                className={
                  radio === "create_your_add"
                    ? "btncenter-for-banner"
                    : "btncenter-for-banner-2"
                }
              >
                <Button
                  onClick={() => {
                    if (radio === "upload_your_add_video") {
                      fileInputField2.current.click();
                    } else {
                      fileInputField.current.click();
                    }
                  }}
                  variant="text"
                  className="upload-btn-add"
                >
                  <text>
                    {radio === "upload_your_add_video"
                      ? "upload your ad video"
                      : "upload your ad"}
                  </text>
                </Button>
              </div>
            </div>
            <input
              className="VideoInput_input"
              ref={fileInputField2}
              style={{ visibility: "hidden" }}
              type="file"
              onChange={handleFileChangeVideo}
              accept=".mov,.mp4"
            />
            <input
              ref={fileInputField}
              style={{ visibility: "hidden" }}
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
            <div style={{ display: "grid", placeItems: "center" }}>
              <div
                id="cpimg"
                className="banner-div"
                style={{ width: widthSize, height: heightSize }}
              >
                {radio === "upload_your_add_video" ? (
                  <video
                    poster={toShowImageLogo}
                    className="VideoInput_video"
                    style={{ width: "100%", height: "100%" }}
                    controls
                    src={
                      videoAd === undefined ||
                      videoAd === "" ||
                      videoAd === null
                        ? ""
                        : videoAd
                    }
                  />
                ) : (
                  <img
                    src={toShowImage}
                    style={{ width: widthSize, height: heightSize }}
                    alt=""
                  />
                )}
              </div>
            </div>
            {radio === "upload_your_add_video" ? (
              <div className="inuputsgapsforbanner">
                <Tooltip title="Upload Your Video Thumbnail" arrow>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={uploadEnable}
                    onClick={() => {
                      fileInputField.current.click();
                    }}
                  >
                    Upload Your Video Thumbnail
                  </Button>
                </Tooltip>
                {logoUpload ? (
                  <div style={{ color: "red" }}> {dataMPError}</div>
                ) : null}
                <input
                  ref={fileInputField}
                  style={{ visibility: "hidden" }}
                  type="file"
                  accept="image/*,.jpg,.jpeg,.png"
                  onChange={handleFileChangeLogo}
                />
              </div>
            ) : null}
            {radio === "upload_your_add_video" ? null : (
              <div style={{ marginTop: "30px" }}>
                <TextField
                  id="outlined-basic"
                  label={`Enter your company URL(Ex:${process.env.REACT_APP_DEFAULT_URL_FOR_AD})`}
                  variant="outlined"
                  fullWidth
                  value={header.name6}
                  onChange={(e) => {
                    const inputUrl = e.target.value;
                    setheader({ ...header, name6: inputUrl });
                    const urlPattern =
                      /^(ftp|http|https):\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]+.*/;
                    setIsValidUpload(urlPattern.test(inputUrl));
                  }}
                />
              </div>
            )}
            {isValidUpload || radio === "upload_your_add_video" ? null : (
              <div className="inuputsgapsforbannererror2">
                <p style={{ color: "red" }}>Invalid URL</p>
              </div>
            )}
          </div>
        )}

        <div className="topsapce-banner">
          <div className="banner-creation">
            <div className="space-between">
              <div class="section-title-add">
                <h2>Banner preview</h2>
                {/* <p>Local Jobs</p> */}
              </div>

              <div className="btncenter-for-banner-conformadd">
                <Button
                  onClick={() => {
                    const days = String(daysvalid);
                    const getdays = addGetList[days];
                    if (adPaymentType === "click_based") {
                      setpayAmount(
                        parseInt(
                          process.env.REACT_APP_ADVANCE_AMOUNT_FOR_CLICK_AD
                        )
                      );
                    } else if (
                      adPincode === "Select All" ||
                      adDistrict === "Select All"
                    ) {
                      const paymentAmount =
                        radio === "upload_your_add"
                          ? getdays + selectAllpayAmount
                          : radio === "upload_your_add_video"
                          ? getdays +
                            parseInt(
                              process.env.REACT_APP_AMOUNT_FOR_VIDEO_AD
                            ) +
                            selectAllpayAmount
                          : getdays +
                            parseInt(
                              process.env.REACT_APP_AMOUNT_FOR_CREATE_AD
                            ) +
                            selectAllpayAmount;
                      setpayAmount(paymentAmount);
                    } else {
                      const paymentAmount =
                        radio === "upload_your_add"
                          ? getdays
                          : radio === "upload_your_add_video"
                          ? getdays +
                            parseInt(process.env.REACT_APP_AMOUNT_FOR_VIDEO_AD)
                          : getdays +
                            parseInt(
                              process.env.REACT_APP_AMOUNT_FOR_CREATE_AD
                            );
                      setpayAmount(paymentAmount);
                    }
                    if (
                      adDistrict === "" ||
                      adPincode === "" ||
                      postion === "" ||
                      daysvalid === "" ||
                      (radio === "upload_your_add" &&
                        (imgget === undefined ||
                          imgget === null ||
                          imgget === "")) ||
                      (radio === "upload_your_add_video" && videoAd === "") ||
                      (radio === "upload_your_add" &&
                        header.name6 ===
                          process.env.REACT_APP_DEFAULT_URL_FOR_AD)
                    ) {
                      setNotFilledDialog(true);
                    } else {
                      setAdPersonalDetails(true);
                    }
                  }}
                  variant="text"
                  className="upload-btn-add"
                >
                  <text>confirm advertise</text>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#ffffff",

                        marginLeft: 12,
                      }}
                    />
                  )}
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              {radio === "upload_your_add_video" ? (
                <video
                  poster={toShowImageLogo}
                  style={{ width: widthSize, height: heightSize }}
                  controls
                  src={
                    videoAd === undefined || videoAd === "" || videoAd === null
                      ? ""
                      : videoAd
                  }
                />
              ) : (
                <img
                  style={{ width: widthSize, height: heightSize }}
                  src={imgget}
                  alt="img"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog TransitionComponent={Transition} open={videoUpload}>
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          Video Upload
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            {`Video size is too large upload < ${process.env.REACT_APP_VIDEO_SIZE_FOR_AD}MB file.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setVideoUpload(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "20px",
          },
        }}
        open={confirmAdDialog}
        fullWidth
      >
        <Box>
          <Box>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "space-around",
                  height: "100%",
                }}
              >
                <Box sx={{ paddingTop: "10px" }}>
                  <Warning height={100} width={100} />
                </Box>
                <Typography
                  variant="h4"
                  sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                >
                  {" "}
                  Are you sure?
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "10px",
                    fontFamily: "Poppins-Medium",
                    color: "white",
                  }}
                >
                  Once you Submitted,You can't modify your advertisement
                </Typography>

                <Box
                  sx={{
                    paddingTop: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => {
                      setConfirmAdDialog(false);
                      setEmailError("");
                      setMobileError("");
                      setNameError("");
                      setGetPersonalEmail("");
                      setGetPersonalMobile("");
                      setGetPersonalName("");
                    }}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#f2f2f2",
                      textTransform: "lowercase",

                      color: "black",
                      borderColor: "white",
                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#f2f2f2",
                        backgroundColor: "#339fcd",
                        borderColor: "#f2f2f2",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "lowercase",

                      color: "white",
                      backgroundColor: "#ed1067",
                      borderColor: "#ed1067",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      "&:hover": {
                        textTransform: "lowercase",

                        color: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    onClick={() => {
                      setConfirmAdDialog(false);
                      if (radio === "upload_your_add_video") {
                        confirmaddvideo();
                      } else {
                        confirmadd();
                      }

                      //setOpensubscribe(true);
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={notfilledDialog}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgb(16 143 178)",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          Warning
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
            {adDistrict === ""
              ? "Please select your District"
              : adPincode === ""
              ? "Please select your Pincode"
              : postion === ""
              ? "Please select the Position of your advertisement"
              : daysvalid === ""
              ? "Please select the Number of Days your advertisement want to display"
              : radio === "upload_your_add" &&
                (imgget === undefined || imgget === null || imgget === "")
              ? "Please Upload Your advertisement"
              : radio === "upload_your_add_video" &&
                (videoAd === undefined || videoAd === null || videoAd === "")
              ? "Please Upload Your advertisement video"
              : radio === "upload_your_add" &&
                header.name6 === process.env.REACT_APP_DEFAULT_URL_FOR_AD
              ? `Are you sure you want to post your Advertisement with ${process.env.REACT_APP_DEFAULT_URL_FOR_AD} URL?`
              : "Please provide a company website URL"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {adDistrict === "" ? null : adPincode === "" ? null : postion ===
            "" ? null : daysvalid === "" ? null : (radio ===
              "upload_your_add" &&
              (imgget === undefined || imgget === null || imgget === "")) ||
            (radio === "upload_your_add_video" &&
              (videoAd === undefined ||
                videoAd === null ||
                videoAd === "")) ? null : (radio === "create_your_add" ||
              radio === "upload_your_add") &&
            header.name6 === process.env.REACT_APP_DEFAULT_URL_FOR_AD ? (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setNotFilledDialog(false);
              }}
            >
              Cancel
            </Button>
          ) : null}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              if (adDistrict === "") {
                setNotFilledDialog(false);
              } else if (adPincode === "") {
                setNotFilledDialog(false);
              } else if (postion === "") {
                setNotFilledDialog(false);
              } else if (daysvalid === "") {
                setNotFilledDialog(false);
              } else if (
                (radio === "upload_your_add" &&
                  (imgget === undefined || imgget === null || imgget === "")) ||
                (radio === "upload_your_add_video" &&
                  (videoAd === undefined || videoAd === null || videoAd === ""))
              ) {
                setNotFilledDialog(false);
              } else if (
                (radio === "create_your_add" || radio === "upload_your_add") &&
                header.name6 === process.env.REACT_APP_DEFAULT_URL_FOR_AD
              ) {
                setNotFilledDialog(false);
                setAdPersonalDetails(true);
              } else {
                setNotFilledDialog(false);
                setAdPersonalDetails(true);
              }
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={contactDialog}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgb(16 143 178)",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          Confirmation
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
            If you would like to display your ad on our website, please contact
            us at +919080400330
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setContactDialog(false);
              navigate(`${ROUTINGDATA.LAND}`);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={successPostAd}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgb(16 143 178)",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          Confirmation
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
            Successfully this advertisement Posted!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setSuccessPostAd(false);
              navigate(`${ROUTINGDATA.LAND}`);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={opensubscribe}
        onClose={() => setOpensubscribe(false)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgb(16 143 178)",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          {"Payment Alert"}
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
            If you want to post your advertisement then you have to make a
            payment !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            autoFocus
            onClick={() => {
              setOpensubscribe(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            autoFocus
            onClick={() => {
              //setConfirmAdDialog(false);
              //confirmadd();

              navigate(
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.AdvertisePayment,
                {
                  state: {
                    pay_amount: payAmount,
                    daysvalid: daysvalid,
                    image_position: postion,
                    adpicdata: adpicdata,
                  },
                }
              );

              setOpensubscribe(false);
            }}
          >
            Subcribe
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLogo}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {pictureLogo.cropperOpen && (
              <Box display="block">
                <AvatarEditor
                  ref={setEditorRef}
                  image={pictureLogo.img}
                  width={
                    radio === "upload_your_add_video"
                      ? parseInt(stickyDivStyle.width)
                      : 200
                  }
                  height={
                    radio === "upload_your_add_video"
                      ? parseInt(stickyDivStyle.height)
                      : 200
                  }
                  border={20}
                  color={[255, 255, 255, 0.6]}
                  rotate={0}
                  scale={pictureLogo.zoom}
                />
                <Slider
                  aria-label="raceSlider"
                  value={pictureLogo.zoom}
                  min={1}
                  max={10}
                  step={0.1}
                  onChange={handleSliderLogo}
                />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancelLogo();
              setOpenLogo(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSaveLogo();
              setOpenLogo(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {picture2.cropperOpen && (
              <Box display="block">
                <AvatarEditor
                  ref={setEditorRef}
                  image={picture2.img}
                  width={parseInt(stickyDivStyle.width)}
                  height={parseInt(stickyDivStyle.height)}
                  border={20}
                  color={[255, 255, 255, 0.6]}
                  rotate={0}
                  scale={picture2.zoom}
                />
                <Slider
                  aria-label="raceSlider"
                  value={picture2.zoom}
                  min={1}
                  max={10}
                  step={0.1}
                  onChange={handleSlider}
                />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancel();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={adPersonalDetails}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
        >
          Contact Details
        </DialogTitle>
        <DialogContent sx={{ margin: "20px" }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Enter Your Contact Details
          </DialogContentText>
          <TextField
          required
            fullWidth
            sx={{ marginTop: "20px" }}
            onChange={(e) => {
              setGetPersonalName("");
              setGetPersonalName(e.target.value);
              if (e.target.value.length > 50) {
                setNameError("Name must be 50 characters or less");
              } else if (e.target.value.length <= 0) {
                setNameError("Please Enter Your Name");
              } else {
                setNameError("");
              }
            }}
            value={getPersonalName.replace(/[^A-Za-z'. ]/gi, "")}
            variant="outlined"
            id="outlined-basic"
            name="Enter_name"
            label="Name"
          />
          {errorName ? <div style={{ color: "red" }}>{errorName}</div> : null}
          <TextField
          required
            fullWidth
            sx={{ marginTop: "20px" }}
            onBlurCapture={() => {
              setfocus(true);
            }}
            onBlur={handleEmailBlur}
            onChange={handleEmailChange}
            value={getPersonalEmail}
            variant="outlined"
            id="outlined-basic"
            name="Enter_emailid"
            label="Email ID"
          />
          {errorEmail && focusout ? <div style={{ color: "red" }}>{errorEmail}</div> : null}
          <TextField
          required
            fullWidth
            inputProps={{ maxLength: 10 }}
            sx={{ marginTop: "20px" }}
            onBlurCapture={() => {
              setfocusb(true);
            }}
            onChange={handleChange}
            value={getPersonalMobile}
            variant="outlined"
            id="outlined-basic"
            name="Enter_mobileNumber"
            label="Mobile Number"
          />
          {errormobile && focusb? (
            <div style={{ color: "red" }}>{errormobile}</div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setEmailError("");
              setMobileError("");
              setNameError("");
              setAdPersonalDetails(false);
              setGetPersonalEmail("");
              setGetPersonalMobile("");
              setGetPersonalName("");
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              errorEmail ||
              errorName ||
              errormobile ||
              getPersonalName === "" ||
              getPersonalMobile === "" ||
              getPersonalEmail === ""
                ? true
                : false
            }
            color="secondary"
            variant="contained"
            onClick={() => {
              if (
                getPersonalEmail === "" ||
                getPersonalEmail === undefined ||
                getPersonalEmail === null
              ) {
                setEmailError("Please Enter Your Email ID");
              } else if (
                getPersonalMobile === "" ||
                getPersonalMobile === undefined ||
                getPersonalMobile === null
              ) {
                setMobileError("Please Enter Your Mobile Number");
              } else if (
                getPersonalName === "" ||
                getPersonalName === undefined ||
                getPersonalName === null
              ) {
                setNameError("Please Enter Your Name");
              } else {
                setEmailError("");
                setAdPersonalDetails(false);
                setConfirmAdDialog(true);
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Advertisement;
