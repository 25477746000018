import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  DialogContentText,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveAs } from "file-saver";
import context from "./EmpContext";
import useAxios from "../../../api/useAxios";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

function CompanyProof({ setput }) {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [uploadEnable, setUploadEnable] = useState(true);

  const formik = useFormik({
    initialValues: {
      proof_name: "",
      proofUpload: "",
    },
    validationSchema: Yup.object({
      proof_name: Yup.string().required("Select Proof Type"),
      proofUpload: Yup.mixed().required("File is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      await axiosData.put(`employers/email/${Location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      formik.handleReset("");
      setput(true);
      setIsSubmitting(false);
      setOpen(false);
    },
  });

  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [dataMPError, setDataMPError] = useState("");
  const MAX_FILE_SIZE = 1048576 * 2;

  const fileInputField = useRef(null);
  const Location = useLocation();
  const handleClickOpen = (context) => {
    formik.setFieldValue("proof_name", context.proof_name);
    formik.setFieldValue("proofUpload", context.proofUpload);
    setOpen(true);
  };
  const handleClose = () => {
    formik.handleReset("");
    setOpen(false);
    setUploadEnable(true);
  };

  const deleteFile = () => {
    setData("");
    fileInputField.current.value = "";
    formik.setFieldValue("proofUpload", "");
    setUploadEnable(false);
  };

  const proofDelete = (context) => {
    setDeleteDialogOpen(true);
    setDeleteData(context);
  };
  const proofDataDalete = async (context) => {
    setIsSubmitting(true);
    await axiosData.delete(`employers/proof/${context._id}`);
    await axiosData.put(`employers/email/${context.email_id}`, {
      proof_name: "",
    });
    setput(true);
    setData("");
    setIsSubmitting(false);
    setDeleteDialogOpen(false);
  };

  return (
    <context.Consumer>
      {(context) => {
        let proofData = "";
        if (context.proofUpload) {
          proofData = context.proofUpload;
        }
        return (
          <div>
            <Dialog
              open={open}
              sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">
                    <b>Government Proof</b>
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid className="sideGrid" item xs={12} sm={12} md={0}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          p: "10px 24px 20px 24px",
                          width: "100%",
                          height: "120px",
                        }}
                      >
                        <FormControl fullWidth sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Select Proof
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=" Select Proof"
                            value={formik.values.proof_name}
                            name="proof_name"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "proof_name",
                                e.target.value
                              );
                              setUploadEnable(false);
                            }}
                            onBlur={formik.handleBlur}
                            disabled={data === "" ? false : true}
                          >
                            <MenuItem value="GST">GST</MenuItem>
                            <MenuItem value="PAN">PAN</MenuItem>
                            <MenuItem value="UDYAM">UDYAM</MenuItem>
                          </Select>
                        </FormControl>
                        {formik.touched.proof_name &&
                        formik.errors.proof_name ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.proof_name}
                          </div>
                        ) : null}

                        <Typography>
                          Upload Your Proof
                          <input
                            type="file"
                            name="proofUpload"
                            onBlur={formik.handleBlur}
                            ref={fileInputField}
                            style={{ display: "none" }}
                            accept=".pdf,.jpg,.jpeg,.png"
                            onChange={(e) => {
                              if (e.target.files[0].size > MAX_FILE_SIZE) {
                                setDataMPError("Proof must be less than 2 MB");
                                setData("");
                                fileInputField.current.value = "";
                                console.log("eror", dataMPError);
                              } else {
                                setDataMPError("");
                                formik.setFieldValue(
                                  "proofUpload",
                                  e.target.files[0]
                                );
                                setData(e.target.files[0]);
                                setUploadEnable(true);
                              }
                            }}
                          />
                          <Tooltip title="upload proof" arrow>
                            <IconButton
                              color="success"
                              disabled={uploadEnable}
                              onClick={() => {
                                fileInputField.current.click();
                              }}
                            >
                              <FileUploadOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          {formik.touched.proofUpload &&
                          formik.errors.proofUpload ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.proofUpload}
                            </div>
                          ) : (
                            <div style={{ color: "red" }}> {dataMPError}</div>
                          )}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {data ? (
                            <>
                              {data.name ? (
                                <>
                                  <Typography sx={{ color: "green", mt: 1 }}>
                                    {data.name}{" "}
                                    <span
                                      style={
                                        data.name
                                          ? {
                                              color: "green",
                                              fontSize: "12px",
                                              marginBottom: "20px",
                                            }
                                          : { color: "red", fontSize: "12px" }
                                      }
                                    >
                                      {data.size
                                        ? `${data / 1024}` >= 1024
                                          ? `-(${parseFloat(
                                              data.size / 1024 / 1024
                                            ).toFixed(3)}Mb)`
                                          : `-(${parseFloat(
                                              data.size / 1024
                                            ).toFixed(3)}Kb)`
                                        : null}
                                    </span>
                                  </Typography>
                                  <IconButton aria-label="delete">
                                    <CloseIcon
                                      sx={{ color: "red" }}
                                      onClick={() => {
                                        deleteFile();
                                      }}
                                    />
                                  </IconButton>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleClose();
                      setData("");
                      setDataMPError("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={
                      formik.isValid && formik.dirty && isSubmitting === false
                        ? false
                        : true
                    }
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Grid>
              <Box id="CompanyProof" sx={{ fontSize: "20px", mb: 1 }}>
                <b>GOVERNMENT PROOF OF COMPANY</b>
                {proofData === "" ? (
                  <>
                    <div>
                      <Typography sx={{ textAlign: "center", marginTop: 2 }}>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ borderRadius: 10 }}
                          onClick={() => {
                            handleClickOpen(context);
                          }}
                        >
                          Upload Proof
                        </Button>
                      </Typography>
                      <Typography sx={{ textAlign: "center", marginTop: 1 }}>
                        Supported files
                      </Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        .pdf/.jpg/.jpeg/.png
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <Box sx={{ mt: 2 }} aria-labelledby="Proof">
                      <Typography>
                        <b>PROOF TYPE :</b> {context.proof_name}
                      </Typography>
                      <Typography>
                        <Typography
                          gutterBottom
                          sx={{
                            wordWrap: "break-word",
                          }}
                        >
                          <b>PROOF NAME:</b> :{proofData.name}
                          {proofData === "" ? (
                            ""
                          ) : (
                            <>
                              <Tooltip title="Download Proof" arrow>
                                <IconButton>
                                  <FileDownloadOutlinedIcon
                                    onClick={() => {
                                      saveAs(
                                        `${baseUrl}/employers/proof/${context.mobile_number}`,
                                        context.proofUpload.name
                                      );
                                    }}
                                    size="medium"
                                    disabled={!context.proofUpload}
                                    sx={{
                                      color: "green",
                                      border: "1px solid green",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Proof" arrow>
                                <IconButton>
                                  <DeleteIcon
                                    disabled={!context.proofUpload}
                                    sx={{
                                      color: "rgb(243 81 81)",
                                      border: "1px solid rgb(243 81 81)",
                                      borderRadius: "50%",
                                    }}
                                    onClick={() => proofDelete(context)}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Dialog
              open={deleteDialogOpen}
              keepMounted
              deleteData={deleteData}
              aria-describedby="alert-dialog-slide-description"
              sx={{ "& .MuiPaper-root": { width: "500px" } }}
            >
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                {"Confirmation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
                  Are you sure, you want to delete the Company Proof?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setDeleteDialogOpen(false);
                  }}
                >
                  NO
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginRight: 2 }}
                  onClick={() => {
                    proofDataDalete(deleteData);
                  }}
                  disabled={isSubmitting}
                >
                  YES
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }}
    </context.Consumer>
  );
}

export default CompanyProof;
