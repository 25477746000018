import React, { useEffect } from "react";
import { useState } from "react";

import {
  Tooltip,
  Popper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Autocomplete,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { Formik } from "formik";
import * as Yup from "yup";

import useAxios from "../../../../api/useAxios";
import "../../jobseeker/JobSeekerRegForm.css";

import {
  PersonPinCircleRounded as PersonPinCircleRoundedIcon,
  HomeWorkRounded as HomeWorkRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  ContactPageRounded as ContactPageRoundedIcon,
  TtyRounded as TtyRoundedIcon,
  ShoppingCartRounded as ShoppingCartRoundedIcon,
  FmdGoodRounded as FmdGoodRoundedIcon,
  WorkRounded as WorkRoundedIcon,
  CurrencyRupee as CurrencyRupeeIcon,
  PersonAdd as PersonAddIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import logo from "../../../../img/logo_for-landingpage-3.png";

import { useNavigate, Outlet } from "react-router";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalJobImgUpload from "./LocalJobImgUpload";

import CustomErrorMessage from "./CustomErrorMessage ";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function LocalJobRegForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  //  translation
  const { t, ready } = useTranslation();
  //
  const axiosData = useAxios();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [isFocused, setIsFocused] = useState({
    pincode: false,
    village_town_name: false,
    area_street_name: false,
    shop_name: false,
    shop_address: false,
    landline_number: false,
    contact_person_name: false,
    contact_number: false,
    remark: false,
    job_title: false,
    nature_of_job: false,
    number_of_persons: false,
    salary_range_min: false,
    salary_range_max: false,
    expiry_date_from: false,
    expiry_date_to: false,
  });
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [captureBlur, setCaptureBlur] = useState({
    contact_number: false,
    landline_number: false,
  });
  const [selectedOptions, setSelectedOptions] = useState({
    pincode: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const [serviceName, setServiceName] = useState([]);

  const getJobList = async () => {
    try {
      const getOneAxios = await axiosData.get(`/localjobslists`);
      const data = getOneAxios.data;
      if (data) {
        const GetVillageName = data.map((i) => {
          return i.service_name;
        });
        setServiceName(GetVillageName);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);

    setSuggestionArrayl([]);
    // setSuggestionArrayl([]);
  };
  const lang = sessionStorage.getItem("lang");

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };
  const handleInputChange = (event, newValue) => {
    if (newValue.length < 7) {
      setSearchQuery(newValue);
    }
  };
  const handleSuggestionsPincode = async (event) => {
    const query = event.target.value;
    searchobj(event.target.value, "pincode");
    // setSearchQuery(query);
    if (query.length === 6) {
      setpinLoad(true);

      try {
        const getOneAxios = await axiosData.get(
          `/General/district/pincode/${query}`
        );
        const data = getOneAxios.data;
        if (getOneAxios.data) {
          const pincodeArray = data.map((item) => {
            return item.pincode;
          });
          const uniquePincodes = new Set(pincodeArray);
          const uniquePincodesArray = [...uniquePincodes];
          if (pincodeArray === "") {
            setSuggestionArrayl([]);
          } else {
            setSuggestionArrayl(uniquePincodesArray);
          }
          const getPincode = data.map((i) => {
            return i.officeName;
          });
          getPincode.sort();
          setPincode(getPincode);
          setpinLoad(false);
        } else {
          setSuggestionArrayl([]);
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  const initialValues = {
    pincode: "",
    village_town_name: "",
    area_street_name: "",
    shop_name: "",
    shop_address: "",
    landline_number: "",
    contact_number: "",
    contact_person_name: "",
    job_title: "",
    nature_of_job: "",
    number_of_persons: "",
    salary_range_min: null,
    salary_range_max: null,
    expiry_date_from: moment(),
    expiry_date_to: null,
    status: "",
    localProfileUpload: null,
    job_need_type: "Normal",
  };
  const yesterday = moment().subtract(1, "day");
  const validationSchema = Yup.object().shape({
    pincode: Yup.number()
      .required(t("Pin_code_is_Required")),
    village_town_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required("Village/Town Name must be Required"),
    area_street_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Area/Street_Name_must_be_Required")),
    shop_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Shop_Name_must_be_Required")),
    shop_address: Yup.string()
      .max(200, t("Must_be_200_characters_or_less"))
      .required(t("Shop_address_must_be_Required")),
    contact_person_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Contact_Person_Name_must_be_Required")),
    contact_number: Yup.number()
      .min(1000000000, t("Not_Valid_Mobile_Number!"))
      .max(9999999999, t("Not_Valid_Mobile_Number!"))
      .required(t("Mobile_Number_must_be_Required")),
    // starRating: Yup.number()
    // .min(1000000000, t("Not_Valid_Mobile_Number!"))
    // .max(9999999999, t("Not_Valid_Mobile_Number!"))
    // .required(t("Mobile_Number_must_be_Required")),
    landline_number: Yup.number()
      // .matches(/^\d{7,10}$/, t("Invalid_landline_number"))
      .min(1000000, t("Landline_number_must_be_at_least_7_digits"))
      .max(9999999999, t("Landline_number_must_be_at_most_10_digits")),

    job_title: Yup.string().required(t("Select_Job_Title")),
    nature_of_job: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Nature_of_job_must_be_required")),
    number_of_persons: Yup.number().required(
      t("Number_of_vacancy_is_Required")
    ),
    salary_range_min: Yup.number()
      .required(t("Minimum_salary_must_be_required"))
      .max(
        Yup.ref("salary_range_max"),
        t("Minimum_salary_should_be_smaller_then_Maximum_salary")
      ),
    salary_range_max: Yup.number()
      .required(t("Maximum_salary_must_be_required"))
      .min(
        Yup.ref("salary_range_min"),
        t("Maximum_salary_must_be_equal_to_or_greater_than_the_Minimum_salary")
      ),
    expiry_date_from: Yup.date()
      .required(t("From_date_is_required"))
      .min(yesterday, t("From_Date_should_be_start_From_Today"))
      .max(
        Yup.ref("expiry_date_to"),
        t("From_date_must_be_equal_to_or_smaller_than_the_to_date")
      )
      .test(
        "from-date-test",
        t("From_date_cannot_be_greater_than_the_to_date"),
        function (value) {
          const toDate = this.resolve(Yup.ref("expiry_date_to"));
          if (value && toDate && moment(value).isAfter(toDate)) {
            return false;
          }
          return true;
        }
      ),
    expiry_date_to: Yup.date()
      .required(t("To_date_is_required"))
      .min(
        Yup.ref("expiry_date_from"),
        t("To_date_must_be_equal_to_or_greater_than_the_from_date")
      ),
  });

  const handleFormikSubmit = async (value, { resetForm, setFieldValue }) => {
    setIsSubmitting(true);
    const expFrom = { ...value.expiry_date_from };

    const momentData = moment(expFrom);
    const formattedData = momentData.format("DD-MM-YYYY");
    const expTo = { ...value.expiry_date_to };

    const momentDataTo = moment(expTo);
    const formattedDataTo = momentDataTo.format("DD-MM-YYYY");
    try {
      const response = await axiosData.post(
        "/localjobs",
        {
          pincode: value.pincode,
          village_town_name: value.village_town_name,
          area_street_name: value.area_street_name,
          shop_name: value.shop_name,
          shop_address: value.shop_address,
          landline_number: value.landline_number,
          contact_number: value.contact_number,
          contact_person_name: value.contact_person_name,
          job_title: value.job_title,
          nature_of_job: value.nature_of_job,
          number_of_persons: value.number_of_persons,
          salary_range_min: value.salary_range_min,
          salary_range_max: value.salary_range_max,
          expiry_date_from: formattedData,
          expiry_date_to: formattedDataTo,
          localProfileUpload1: uploadedImages[0],
          localProfileUpload2: uploadedImages[1],
          job_need_type: value.job_need_type,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data) {
        if (value.job_need_type === "Urgent") {
          try {
            await axiosData.post(
              "/urgentlocaljobs",
              {
                pincode: value.pincode,
                village_town_name: value.village_town_name,
                area_street_name: value.area_street_name,
                user_id: response.data.user_id,
                job_id: response.data._id,
                shop_name: value.shop_name,
                shop_address: value.shop_address,
                landline_number: value.landline_number,
                contact_number: value.contact_number,
                contact_person_name: value.contact_person_name,
                job_title: value.job_title,
                nature_of_job: value.nature_of_job,
                number_of_persons: value.number_of_persons,
                salary_range_min: value.salary_range_min,
                salary_range_max: value.salary_range_max,
                expiry_date_from: formattedData,
                expiry_date_to: formattedDataTo,
                localProfileUpload1: uploadedImages[0],
                localProfileUpload2: uploadedImages[1],
                job_need_type: value.job_need_type,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (err) {
            console.log(err);
          }
        }
      }
      resetForm();
      setIsFocused({
        ...isFocused,
        pincode: false,
        village_town_name: false,
        area_street_name: false,
        shop_name: false,
        shop_address: false,
        landline_number: false,
        contact_person_name: false,
        contact_number: false,
        remark: false,
        job_title: false,
        nature_of_job: false,
        number_of_persons: false,
        salary_range_min: false,
        salary_range_max: false,
        expiry_date_from: false,
        expiry_date_to: false,
      });
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYouForLocal}`
      );
    } catch (err) {
      console.error(err);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    getJobList();
  }, []);

  if (ready) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Email_Id_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Mobile_number_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Mobile_number_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="mainGrid"
        >
          <CssBaseline />
          <Grid className="imgGrid" item xs={false} sm={false} md={4}>
            <Box>
              <Box className="sideReg">
                <div className="logo1">
                  <img src={logo} alt="logo" style={{ width: "75%" }} />
                </div>
                <Typography
                  variant="h5"
                  component="div"
                  className={lang === "tamil" ? "side-tamil-1" : "side-english"}
                  gutterBottom
                  sx={{ fontWeight: "bold", fontFamily: "Barlow-Bold" }}
                >
                  {t("By_Providing_Local_Jobs")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon
                    sx={{ mb: -0.6, mr: 0.5, color: "green" }}
                    color="green"
                  />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Introduce_Your_Company")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                    gutterBottom
                  >
                    {t("Spell_Out_The_Top_Benefits")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Best_Candidates_Reach_Out_To_You_Directly")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            className="secondGrid"
            item
            xs={12}
            sm={12}
            md={8}
            component={Paper}
            elevation={7}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormikSubmit}
            >
              {(formikProps) => {
                const disableCondition =
                  formikProps.isValid &&
                  formikProps.dirty &&
                  isSubmitting === false
                    ? false
                    : true;
                return (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      my={7}
                      alignItems={"center"}
                    >
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        sx={{
                          border: "2px solid rgb(237 30 112)",
                          mt: -3,
                          mb: 2,
                          cursor: "default",
                        }}
                      >
                        <PersonPinCircleRoundedIcon
                          sx={{ fontSize: 50, color: "rgb(237 30 112)" }}
                        />
                      </IconButton>

                      <Typography
                        sx={{
                          color: "rgb(19 92 130)",
                          fontWeight: "bold",
                          fontFamily: "Poppins-Regular",
                          textTransform: "capitalize   ",
                        }}
                        className="example"
                        // component="h1"
                        variant="h5"
                      >
                        {t("LOCAL_JOBS")}
                        {/* {t("JobSeeker_Registration")} */}
                      </Typography>
                      <Box
                        sx={{ mt: 3 }}
                        display={"flex"}
                        flexDirection={"column"}
                        //   my={8}
                        alignItems={"center"}
                        width="100%"
                        gap={"20px"}
                      >
                        <fieldset
                          style={{ width: "100%" }}
                          className="importantBox_for_local"
                        >
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              {t("Address-1")}
                            </Typography>{" "}
                          </legend>
                          <Container fixed>
                            <Box
                              display="flex"
                              flexDirection={"column"}
                              gap="10px"
                            >
                              <FormControl fullWidth>
                                <FormLabel
                                  // focused={isFocused.pin}

                                  style={{
                                    color:
                                      isFocused.pincode === true &&
                                      // formikProps.touched.pincode === false &&
                                      (formikProps.errors.pincode ===
                                        undefined ||
                                        formikProps.touched.pincode ===
                                          undefined)
                                        ? "#1976d5"
                                        : formikProps.errors.pincode !==
                                            undefined &&
                                          formikProps.touched.pincode === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {t("Pincode")}{" "}
                                </FormLabel>
                                <Autocomplete
                                  // onInputChange={}
                                  disableClearable={
                                    formikProps.values.pincode ? false : true
                                  }
                                  disablePortal
                                  id="pincode"
                                  name="pincode"
                                  options={suggestionArrayl}
                                  inputValue={searchQuery}
                                  onInputChange={handleInputChange}
                                  onChange={(event, value, reason) => {
                                    searchobj(value, event, "pincode");
                                    formikProps.setFieldValue("pincode", value);
                                    if (reason === "clear") {
                                      formikProps.setFieldValue(
                                        "village_town_name",
                                        ""
                                      );
                                      formikProps.setFieldValue(
                                        "area_street_name",
                                        ""
                                      );
                                    }
                                  }}
                                  value={formikProps.values.pincode}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      inputProps={{
                                        onFocus: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            pincode: true,
                                          });
                                        },
                                        onBlur: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            pincode: false,
                                          });
                                        },
                                      }}
                                      labelId="demo-simple-select-label"
                                      name="pincode"
                                      // label={t("Pincode")}
                                      type="number"
                                      id="pincode"
                                      value={searchQuery}
                                      onWheel={(e) => {
                                        e.preventDefault();
                                        e.target.blur();
                                      }}
                                      onKeyDown={(e) =>
                                        exceptThisSymbols.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      placeholder={t("Enter_Pincode(Optional)")}
                                      onBlur={formikProps.handleBlur}
                                      onChange={(e) => {
                                        if (e.target.value.length < 7) {
                                          handleSuggestionsPincode(e);
                                          if (
                                            formikProps.values.village_town_name
                                          ) {
                                            formikProps.setFieldValue(
                                              "village_town_name",
                                              ""
                                            );
                                            formikProps.setFieldValue(
                                              "area_street_name",
                                              ""
                                            );
                                          }
                                        }
                                        // } else {
                                        //   alert("Enter only 6 digit");
                                        // }
                                      }}
                                      {...params}
                                      error={
                                        formikProps.touched.pincode &&
                                        Boolean(formikProps.errors.pincode)
                                      }
                                      helperText={
                                        formikProps.touched.pincode &&
                                        formikProps.errors.pincode
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            {pinLoad ? (
                                              <CircularProgress
                                                // size={24}
                                                style={{
                                                  width: "1.7rem",
                                                  height: "1.7rem",
                                                  color:
                                                    isFocused.pincode ===
                                                      true &&
                                                    // formikProps.touched.pincode === false &&
                                                    (formikProps.errors
                                                      .pincode === undefined ||
                                                      formikProps.touched
                                                        .pincode === undefined)
                                                      ? "#1976d5"
                                                      : formikProps.errors
                                                          .pincode !==
                                                          undefined &&
                                                        formikProps.touched
                                                          .pincode === true
                                                      ? "#de6363"
                                                      : "#666666",
                                                }}
                                              />
                                            ) : (
                                              <ShareLocationRoundedIcon
                                                sx={{
                                                  color:
                                                    isFocused.pincode ===
                                                      true &&
                                                    // formikProps.touched.pincode === false &&
                                                    (formikProps.errors
                                                      .pincode === undefined ||
                                                      formikProps.touched
                                                        .pincode === undefined)
                                                      ? "#1976d5"
                                                      : formikProps.errors
                                                          .pincode !==
                                                          undefined &&
                                                        formikProps.touched
                                                          .pincode === true
                                                      ? "#de6363"
                                                      : "#666666",
                                                }}
                                              />
                                            )}{" "}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <Tooltip
                                title={
                                  formikProps.values.pincode
                                    ? ""
                                    : t("Enter_Pincode_First")
                                }
                              >
                                <FormControl
                                  fullWidth
                                  disabled={
                                    formikProps.values.pincode ? false : true
                                  }
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.village_town_name === true &&
                                        (formikProps.errors
                                          .village_town_name === undefined ||
                                          formikProps.touched
                                            .village_town_name === undefined)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .village_town_name !==
                                              undefined &&
                                            formikProps.touched
                                              .village_town_name === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    {t("Select_Post_Office")}
                                  </FormLabel>
                                  <Select
                                    fullWidth
                                    name="village_town_name"
                                    labelId="demo-simple-select-label"
                                    id="village_town_name"
                                    required
                                    placeholder={t(
                                      "Enter_Village_or_Town_Name"
                                    )}
                                    sx={{ margin: "0" }}
                                    onBlur={formikProps.handleBlur}
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.village_town_name}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      style: { color: "black" },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <HomeWorkRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.village_town_name ===
                                                  true &&
                                                (formikProps.errors
                                                  .village_town_name ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .village_town_name ===
                                                    undefined)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .village_town_name !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .village_town_name ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />{" "}
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.village_town_name &&
                                      Boolean(
                                        formikProps.errors.village_town_name
                                      )
                                    }
                                    helperText={
                                      formikProps.touched.village_town_name &&
                                      formikProps.errors.village_town_name
                                    }
                                    displayEmpty
                                    MenuProps={MenuProps}
                                  >
                                    {" "}
                                    <MenuItem
                                      value=""
                                      disabled
                                      disableRipple
                                      disableTouchRipple
                                    >
                                      {t("Select_Post_Office_Name")}
                                    </MenuItem>
                                    {pincode &&
                                      pincode.map((element, index) => {
                                        return (
                                          <MenuItem key={index} value={element}>
                                            {element}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                              <Tooltip
                                title={
                                  formikProps.values.village_town_name
                                    ? ""
                                    : t("Select_Post_Office_Name")
                                }
                              >
                                <FormControl fullWidth>
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    disabled={
                                      formikProps.values.village_town_name
                                        ? false
                                        : true
                                    }
                                    style={{
                                      color:
                                        isFocused.area_street_name === true &&
                                        (formikProps.errors.area_street_name ===
                                          undefined ||
                                          formikProps.touched
                                            .area_street_name === undefined)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .area_street_name !== undefined &&
                                            formikProps.touched
                                              .area_street_name === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    {t("Area/Street_Name")}
                                  </FormLabel>
                                  <TextField
                                    disabled={
                                      formikProps.values.village_town_name
                                        ? false
                                        : true
                                    }
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          area_street_name: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          area_street_name: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={t("Enter_Area_or_Street_Name")}
                                    id="area_street_name"
                                    // label={t("Full_Name")}
                                    name="area_street_name"
                                    sx={{ margin: "0" }}
                                    onBlur={formikProps.handleBlur}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      const shopValue = value.replace(
                                        /[^a-zA-Z0-9., ]/g,
                                        ""
                                      );
                                      formikProps.setFieldValue(
                                        "area_street_name",
                                        shopValue
                                      );
                                    }}
                                    value={formikProps.values.area_street_name}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      style: { color: "black" },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            style={{
                                              width: "1.7rem",
                                              height: "1.7rem",
                                              // color: "#757575",
                                            }}
                                            src={`https://img.icons8.com/ios-filled/50/${
                                              isFocused.area_street_name ===
                                                true &&
                                              (formikProps.errors
                                                .area_street_name ===
                                                undefined ||
                                                formikProps.touched
                                                  .area_street_name ===
                                                  undefined)
                                                ? "1976d5"
                                                : formikProps.errors
                                                    .area_street_name !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .area_street_name === true
                                                ? "de6363"
                                                : "666666"
                                            }/street-view.png`}
                                            alt="street-view"
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.area_street_name &&
                                      Boolean(
                                        formikProps.errors.area_street_name
                                      )
                                    }
                                    helperText={
                                      formikProps.touched.area_street_name &&
                                      formikProps.errors.area_street_name
                                    }
                                  />
                                </FormControl>
                              </Tooltip>
                            </Box>
                          </Container>
                        </fieldset>
                        <fieldset
                          className="importantBox_for_local"
                          id="ContactDetailsFieldset"
                        >
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              {t("Contact_Details")}
                            </Typography>
                          </legend>

                          <Box className="ContactDetailsContainer">
                            <FormControl
                              className="ContactDetailsFormControl"
                              fullWidth
                            >
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_name === true &&
                                    (formikProps.errors.shop_name ===
                                      undefined ||
                                      formikProps.touched.shop_name ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_name !==
                                          undefined &&
                                        formikProps.touched.shop_name === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Name")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_name")}
                                id="shop_name"
                                // label={t("Full_Name")}
                                name="shop_name"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-z'. ]/gi,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_name",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_name}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ShoppingCartRoundedIcon
                                        style={{
                                          color:
                                            isFocused.shop_name === true &&
                                            (formikProps.errors.shop_name ===
                                              undefined ||
                                              formikProps.touched.shop_name ===
                                                undefined)
                                              ? "#1976d5"
                                              : formikProps.errors.shop_name !==
                                                  undefined &&
                                                formikProps.touched
                                                  .shop_name === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.shop_name &&
                                  Boolean(formikProps.errors.shop_name)
                                }
                                helperText={
                                  formikProps.touched.shop_name &&
                                  formikProps.errors.shop_name
                                }
                              />
                            </FormControl>
                            <FormControl
                              className="ContactDetailsFormControl"
                              fullWidth
                            >
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_address === true &&
                                    (formikProps.errors.shop_address ===
                                      undefined ||
                                      formikProps.touched.shop_address ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_address !==
                                          undefined &&
                                        formikProps.touched.shop_address ===
                                          true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Address")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                variant="outlined"
                                multiline={true}
                                maxRows={3}
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_address")}
                                id="shop_address"
                                // label={t("Full_Name")}
                                name="shop_address"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-zA-Z0-9./, #]/g,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_address",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_address}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FmdGoodRoundedIcon
                                        style={{
                                          color:
                                            isFocused.shop_address === true &&
                                            (formikProps.errors.shop_address ===
                                              undefined ||
                                              formikProps.touched
                                                .shop_address === undefined)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .shop_address !== undefined &&
                                                formikProps.touched
                                                  .shop_address === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                  // multiline: true,
                                  // maxRows: 4,
                                }}
                                error={
                                  formikProps.touched.shop_address &&
                                  Boolean(formikProps.errors.shop_address)
                                }
                                helperText={
                                  formikProps.touched.shop_address &&
                                  formikProps.errors.shop_address
                                }
                              />
                            </FormControl>
                            <FormControl
                              className="ContactDetailsFormControl"
                              fullWidth
                            >
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.landline_number === true &&
                                    (formikProps.errors.landline_number ===
                                      undefined ||
                                      formikProps.touched.landline_number ===
                                        undefined ||
                                      captureBlur.landline_number === false)
                                      ? "#1976d5"
                                      : formikProps.errors.landline_number !==
                                          undefined &&
                                        formikProps.touched.landline_number ===
                                          true &&
                                        captureBlur.landline_number === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Landline_Number")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      landline_number: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      landline_number: false,
                                    });
                                  },
                                }}
                                onBlurCapture={() =>
                                  setCaptureBlur({
                                    ...captureBlur,
                                    landline_number: true,
                                  })
                                }
                                fullWidth
                                required
                                placeholder={t("Enter_Your_Landline_Number")}
                                name="landline_number"
                                // label={t("Mobile_Number")}
                                type="number"
                                onWheel={(e) => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                id="mobile_number"
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  setCaptureBlur({
                                    ...captureBlur,
                                    landline_number: false,
                                  });
                                  const { value } = e.target;
                                  if (value.length <= 10) {
                                    formikProps.handleChange(e);
                                  }
                                }}
                                value={formikProps.values.landline_number}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  maxLength: 10,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <TtyRoundedIcon
                                        sx={{
                                          color:
                                            isFocused.landline_number ===
                                              true &&
                                            (formikProps.errors
                                              .landline_number === undefined ||
                                              formikProps.touched
                                                .landline_number ===
                                                undefined ||
                                              captureBlur.landline_number ===
                                                false)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .landline_number !==
                                                  undefined &&
                                                formikProps.touched
                                                  .landline_number === true &&
                                                captureBlur.landline_number ===
                                                  true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.landline_number &&
                                  Boolean(formikProps.errors.landline_number) &&
                                  captureBlur.landline_number
                                }
                                helperText={
                                  formikProps.touched.landline_number &&
                                  captureBlur.landline_number &&
                                  formikProps.errors.landline_number
                                }
                              />
                            </FormControl>
                            <FormControl
                              className="ContactDetailsFormControl"
                              fullWidth
                            >
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.contact_person_name === true &&
                                    (formikProps.errors.contact_person_name ===
                                      undefined ||
                                      formikProps.touched
                                        .contact_person_name === undefined)
                                      ? "#1976d5"
                                      : formikProps.errors
                                          .contact_person_name !== undefined &&
                                        formikProps.touched
                                          .contact_person_name === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Contact_Person_Name")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      contact_person_name: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      contact_person_name: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Your_Name")}
                                id="Name"
                                // label={t("Full_Name")}
                                name="contact_person_name"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-z'. ]/gi,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "contact_person_name",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.contact_person_name}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ContactPageRoundedIcon
                                        sx={{
                                          color:
                                            isFocused.contact_person_name ===
                                              true &&
                                            (formikProps.errors
                                              .contact_person_name ===
                                              undefined ||
                                              formikProps.touched
                                                .contact_person_name ===
                                                undefined)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .contact_person_name !==
                                                  undefined &&
                                                formikProps.touched
                                                  .contact_person_name === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.contact_person_name &&
                                  Boolean(
                                    formikProps.errors.contact_person_name
                                  )
                                }
                                helperText={
                                  formikProps.touched.contact_person_name &&
                                  formikProps.errors.contact_person_name
                                }
                              />
                            </FormControl>

                            <FormControl
                              className="ContactDetailsFormControl"
                              fullWidth
                            >
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.contact_number === true &&
                                    (formikProps.errors.contact_number ===
                                      undefined ||
                                      formikProps.touched.contact_number ===
                                        undefined ||
                                      captureBlur.contact_number === false)
                                      ? "#1976d5"
                                      : formikProps.errors.contact_number !==
                                          undefined &&
                                        formikProps.touched.contact_number ===
                                          true &&
                                        captureBlur.contact_number === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Contact_Number")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      contact_number: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      contact_number: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Your_Mobile_Number")}
                                name="contact_number"
                                // label={t("Mobile_Number")}
                                type="number"
                                onWheel={(e) => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                id="contact_number"
                                onBlur={formikProps.handleBlur}
                                onBlurCapture={() =>
                                  setCaptureBlur({
                                    ...captureBlur,
                                    contact_number: true,
                                  })
                                }
                                onChange={(e) => {
                                  setCaptureBlur({
                                    ...captureBlur,
                                    contact_number: false,
                                  });
                                  // setIsFocused({
                                  //   ...isFocused,
                                  //   contact_number: false,
                                  // });
                                  const { value } = e.target;
                                  if (value.length <= 10) {
                                    formikProps.handleChange(e);
                                  }
                                }}
                                value={formikProps.values.contact_number}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  maxLength: 10,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <b
                                        style={{
                                          color:
                                            isFocused.contact_number === true &&
                                            (formikProps.errors
                                              .contact_number === undefined ||
                                              formikProps.touched
                                                .contact_number === undefined ||
                                              captureBlur.contact_number ===
                                                false)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .contact_number !==
                                                  undefined &&
                                                formikProps.touched
                                                  .contact_number === true &&
                                                captureBlur.contact_number ===
                                                  true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      >
                                        +91
                                      </b>
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.contact_number &&
                                  Boolean(formikProps.errors.contact_number) &&
                                  captureBlur.contact_number
                                }
                                helperText={
                                  formikProps.touched.contact_number &&
                                  captureBlur.contact_number &&
                                  formikProps.errors.contact_number
                                }
                              />
                            </FormControl>
                          </Box>
                          <LocalJobImgUpload
                            formikProps={formikProps}
                            uploadedImages={uploadedImages}
                            setUploadedImages={setUploadedImages}
                          />
                        </fieldset>

                        <Tooltip
                          title={
                            formikProps.values.votingDistrict
                              ? null
                              : t("Select_Service_Name_That_You_are_offering")
                          }
                          arrow
                        >
                          <FormControl
                            // disabled={
                            //   formikProps.values.votingDistrict ? false : true
                            // }
                            // sx={{ mt: 2 }}
                            fullWidth
                            required
                          >
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.job_title === true &&
                                  (formikProps.errors.job_title === undefined ||
                                    formikProps.touched.job_title === undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.job_title !==
                                        undefined &&
                                      formikProps.touched.job_title === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Job_Title")}
                            </FormLabel>
                            <Autocomplete
                              disableClearable={
                                formikProps.values.job_title ? false : true
                              }
                              filterOptions={filterOptions}
                              id="job_title"
                              name="job_title"
                              options={serviceName}
                              onBlur={formikProps.handleBlur}
                              onChange={(event, value) => {
                                formikProps.setFieldValue("job_title", value);
                              }}
                              xs
                              value={formikProps.values.job_title}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img
                                          style={{
                                            width: "1.7rem",
                                            height: "1.7rem",
                                            // color: "#757575",
                                          }}
                                          src={`https://img.icons8.com/glyph-neue/64/${
                                            isFocused.job_title === true &&
                                            (formikProps.errors.job_title ===
                                              undefined ||
                                              formikProps.touched.job_title ===
                                                undefined)
                                              ? "1976d5"
                                              : formikProps.errors.job_title !==
                                                  undefined &&
                                                formikProps.touched
                                                  .job_title === true
                                              ? "de6363"
                                              : "666666"
                                          }/service.png`}
                                          alt="service"
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  id="job_title"
                                  // label={t("Preffered_Industry")}
                                  name="job_title"
                                  // onClick={() =>
                                  //   params.inputProps.onInputClick()
                                  // }
                                  placeholder={t("Enter_Job_Title")}
                                  error={
                                    formikProps.touched.job_title &&
                                    Boolean(formikProps.errors.job_title)
                                  }
                                  helperText={
                                    formikProps.touched.job_title &&
                                    formikProps.errors.job_title
                                  }
                                  onFocus={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      job_title: true,
                                    })
                                  }
                                  onBlur={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      job_title: false,
                                    })
                                  }
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start", // Placement set to "bottom-start"
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl>
                        </Tooltip>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.nature_of_job === true &&
                                (formikProps.errors.nature_of_job ===
                                  undefined ||
                                  formikProps.touched.nature_of_job ===
                                    undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.nature_of_job !==
                                      undefined &&
                                    formikProps.touched.nature_of_job === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Nature_of_job")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  nature_of_job: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  nature_of_job: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_Nature_of_job")}
                            id="nature_of_job"
                            // label={t("Full_Name")}
                            name="nature_of_job"
                            sx={{ margin: "0" }}
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z'. ,]/gi,
                                ""
                              );
                              formikProps.setFieldValue(
                                "nature_of_job",
                                shopValue
                              );
                            }}
                            value={formikProps.values.nature_of_job}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <WorkRoundedIcon
                                    style={{
                                      color:
                                        isFocused.nature_of_job === true &&
                                        (formikProps.errors.nature_of_job ===
                                          undefined ||
                                          formikProps.touched.nature_of_job ===
                                            undefined)
                                          ? "1976d5"
                                          : formikProps.errors.nature_of_job !==
                                              undefined &&
                                            formikProps.touched
                                              .nature_of_job === true
                                          ? "de6363"
                                          : "666666",
                                    }}
                                    alt="street-view"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.nature_of_job &&
                              Boolean(formikProps.errors.nature_of_job)
                            }
                            helperText={
                              formikProps.touched.nature_of_job &&
                              formikProps.errors.nature_of_job
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.number_of_persons === true &&
                                (formikProps.errors.number_of_persons ===
                                  undefined ||
                                  formikProps.touched.number_of_persons ===
                                    undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.number_of_persons !==
                                      undefined &&
                                    formikProps.touched.number_of_persons ===
                                      true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Vacancy")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  number_of_persons: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  number_of_persons: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_No._of_Vacancies_you_need")}
                            name="number_of_persons"
                            // label={t("Mobile_Number")}
                            type="Number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            id="mobile_number"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.number_of_persons}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              maxLength: 10,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <b
                                    style={{
                                      color:
                                        isFocused.number_of_persons === true &&
                                        (formikProps.errors
                                          .number_of_persons === undefined ||
                                          formikProps.touched
                                            .number_of_persons === undefined)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .number_of_persons !==
                                              undefined &&
                                            formikProps.touched
                                              .number_of_persons === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    <PersonAddIcon />
                                  </b>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.number_of_persons &&
                              Boolean(formikProps.errors.number_of_persons)
                            }
                            helperText={
                              formikProps.touched.number_of_persons &&
                              formikProps.errors.number_of_persons
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("Job_Need_Type")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="job_need_type"
                            value={formikProps.values.job_need_type}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                          >
                            <FormControlLabel
                              value="Normal"
                              control={<Radio />}
                              label={t("Normal")}
                            />
                            <FormControlLabel
                              value="Urgent"
                              control={<Radio />}
                              label={t("Urgent")}
                            />
                            {/* <FormControlLabel
                              value="Later"
                              control={<Radio />}
                              label="Later"
                            /> */}
                          </RadioGroup>
                        </FormControl>
                        <fieldset
                          style={{ width: "100%" }}
                          className="importantBox_for_local"
                        >
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              {t("Salary_Range")}
                            </Typography>{" "}
                          </legend>
                          <Container fixed>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              gap={"40px"}
                            >
                              <FormControl>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.salary_range_min === true &&
                                      (formikProps.errors.salary_range_min ===
                                        undefined ||
                                        formikProps.touched.salary_range_min ===
                                          undefined)
                                        ? "#1976d5"
                                        : formikProps.errors
                                            .salary_range_min !== undefined &&
                                          formikProps.touched
                                            .salary_range_min === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  {t("Minimum_Salary")}
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        salary_range_min: true,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        salary_range_min: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={t("Enter_your_minimum_salary")}
                                  name="salary_range_min"
                                  type="number"
                                  onWheel={(e) => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  onKeyDown={(e) =>
                                    exceptThisSymbols.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  id="salary_range_min"
                                  onBlur={formikProps.handleBlur}
                                  onChange={formikProps.handleChange}
                                  value={formikProps.values.salary_range_min}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    maxLength: 10,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <b
                                          style={{
                                            color:
                                              isFocused.salary_range_min ===
                                                true &&
                                              (formikProps.errors
                                                .salary_range_min ===
                                                undefined ||
                                                formikProps.touched
                                                  .salary_range_min ===
                                                  undefined)
                                                ? "#1976d5"
                                                : formikProps.errors
                                                    .salary_range_min !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .salary_range_min === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        >
                                          <CurrencyRupeeIcon />
                                        </b>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.salary_range_min &&
                                    Boolean(formikProps.errors.salary_range_min)
                                  }
                                  helperText={
                                    formikProps.touched.salary_range_min &&
                                    formikProps.errors.salary_range_min
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.salary_range_max === true &&
                                      (formikProps.errors.salary_range_max ===
                                        undefined ||
                                        formikProps.touched.salary_range_max ===
                                          undefined)
                                        ? "#1976d5"
                                        : formikProps.errors
                                            .salary_range_max !== undefined &&
                                          formikProps.touched
                                            .salary_range_max === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  {t("Maximum_Salary")}
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        salary_range_max: true,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        salary_range_max: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={t("Enter_your_Maximum_salary")}
                                  name="salary_range_max"
                                  type="Number"
                                  onWheel={(e) => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  onKeyDown={(e) =>
                                    exceptThisSymbols.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  id="salary_range_max"
                                  onBlur={formikProps.handleBlur}
                                  onChange={formikProps.handleChange}
                                  value={formikProps.values.salary_range_max}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    maxLength: 10,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <b
                                          style={{
                                            color:
                                              isFocused.salary_range_max ===
                                                true &&
                                              (formikProps.errors
                                                .salary_range_max ===
                                                undefined ||
                                                formikProps.touched
                                                  .salary_range_max ===
                                                  undefined)
                                                ? "#1976d5"
                                                : formikProps.errors
                                                    .salary_range_max !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .salary_range_max === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        >
                                          <CurrencyRupeeIcon />
                                        </b>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.salary_range_max &&
                                    Boolean(formikProps.errors.salary_range_max)
                                  }
                                  helperText={
                                    formikProps.touched.salary_range_max &&
                                    formikProps.errors.salary_range_max
                                  }
                                />
                              </FormControl>
                            </Box>
                          </Container>
                        </fieldset>
                        <fieldset
                          style={{ width: "100%" }}
                          className="importantBox_for_local"
                        >
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              {t("Expiry_Date_Range")}
                            </Typography>{" "}
                          </legend>
                          <Container fixed>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              gap={"40px"}
                            >
                              <FormControl>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.expiry_date_from === true &&
                                      (formikProps.errors.expiry_date_from ===
                                        undefined ||
                                        formikProps.touched.expiry_date_from ===
                                          undefined)
                                        ? "#1976d5"
                                        : formikProps.errors
                                            .expiry_date_from !== undefined &&
                                          formikProps.touched
                                            .expiry_date_from === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  {t("From")}
                                </FormLabel>
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DatePicker
                                    name="expiry_date_from"
                                    value={formikProps.values.expiry_date_from}
                                    format="DD-MM-YYYY"
                                    onChange={(val) => {
                                      formikProps.setFieldValue(
                                        "expiry_date_from",
                                        val
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        helperText: formikProps.touched
                                          .expiry_date_from && (
                                          <CustomErrorMessage
                                            name="expiry_date_from"
                                            formikName="from"
                                          />
                                        ),
                                        error:
                                          formikProps.touched
                                            .expiry_date_from &&
                                          Boolean(
                                            formikProps.errors.expiry_date_from
                                          ),
                                        onFocus: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            expiry_date_from: true,
                                          });
                                        },
                                        onBlur: (even) => {
                                          setIsFocused({
                                            ...isFocused,
                                            expiry_date_from: false,
                                          });
                                          formikProps.setFieldTouched(
                                            "expiry_date_from"
                                          );
                                        },
                                      },
                                    }}
                                    disablePast
                                    minDate={yesterday}
                                    // maxDate={
                                    //   formikProps.values.expiry_date_to ||
                                    //   maxDate
                                    // }
                                  />
                                </LocalizationProvider>
                                {/* {formikProps.touched.expiry_date_from &&
                                formikProps.errors.expiry_date_from ? (
                                  <FormHelperText sx={{ display: "none" }}>
                                    none
                                  </FormHelperText>
                                ) : (
                                  <FormHelperText sx={{ color: "#de6363" }}>
                                    {" "}
                                    {formikProps.errors.expiry_date_from}
                                  </FormHelperText>
                                )} */}
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.expiry_date_to === true &&
                                      (formikProps.errors.expiry_date_to ===
                                        undefined ||
                                        formikProps.touched.expiry_date_to ===
                                          undefined)
                                        ? "#1976d5"
                                        : formikProps.errors.expiry_date_to !==
                                            undefined &&
                                          formikProps.touched.expiry_date_to ===
                                            true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  {t("to")}
                                </FormLabel>
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DatePicker
                                    // label="End Date"
                                    name="expiry_date_to"
                                    value={formikProps.values.expiry_date_to}
                                    format="DD-MM-YYYY"
                                    onChange={(val) => {
                                      formikProps.setFieldValue(
                                        "expiry_date_to",
                                        val
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        helperText: formikProps.touched
                                          .expiry_date_to && (
                                          <CustomErrorMessage
                                            name="expiry_date_to"
                                            formikName="to"
                                          />
                                        ),
                                        error:
                                          formikProps.touched.expiry_date_to &&
                                          Boolean(
                                            formikProps.errors.expiry_date_to
                                          ),
                                        onFocus: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            expiry_date_to: true,
                                          });
                                        },
                                        onBlur: (even) => {
                                          setIsFocused({
                                            ...isFocused,
                                            expiry_date_to: false,
                                          });
                                          formikProps.setFieldTouched(
                                            "expiry_date_to"
                                          );
                                        },
                                      },
                                    }}
                                    // minDate={minDate}

                                    minDate={
                                      formikProps.values.expiry_date_from
                                    }
                                  />
                                </LocalizationProvider>
                                {/* {formikProps.touched.expiry_date_to &&
                                formikProps.errors.expiry_date_to ? (
                                  <FormHelperText sx={{ display: "none" }}>
                                    none
                                  </FormHelperText>
                                ) : (
                                  <FormHelperText sx={{ color: "#de6363" }}>
                                    {" "}
                                    {formikProps.errors.expiry_date_to}
                                  </FormHelperText>
                                )} */}
                              </FormControl>
                            </Box>
                          </Container>
                        </fieldset>
                        <Tooltip
                          arrow
                          title={
                            disableCondition
                              ? t("Please_Fill_all_the_fields")
                              : ""
                          }
                          sx={{ width: "100%" }}
                        >
                          <Box width="100%">
                            <Button
                              type="submit"
                              disabled={disableCondition}
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 3,
                                mb: 2,
                                textTransform: "capitalize",
                                backgroundColor: "#135c82",
                                transition: "0.3s all",
                                "&:hover": { backgroundColor: "#022638" },
                              }}
                              onClick={formikProps.handleSubmit}
                            >
                              {t("Submit")}
                              {isSubmitting && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#ffffff",

                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Outlet />
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default LocalJobRegForm;
