import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Chip,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
} from "@mui/material";
import useAxios from "../../../../api/useAxios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
  SwapHoriz as SwapHorizIcon,
} from "@mui/icons-material/";
import DeleteIcon from '@mui/icons-material/Delete';

import Breadcrumb from "../../BreadCrumb";

import boxcard_yellow from "../../../../img/dasboard-box-img/yellow.png";
import boxcard_green from "../../../../img/dasboard-box-img/green.png";
import boxcard_blue from "../../../../img/dasboard-box-img/blue.png";
import boxcard_skyblue from "../../../../img/dasboard-box-img/skyblue.png";
import boxcard_drakgreen from "../../../../img/dasboard-box-img/drakgreen.png";

import filterimg from "../../../../img/icon-investor-cardimg/hourglass 2.png";
import personfloder from "../../../../img/icon-investor-cardimg/user-folder 1.png";
import documentimg from "../../../../img/icon-investor-cardimg/documents 1.png";
import hand from "../../../../img/icon-investor-cardimg/handshake 1.png";
import doneimg from "../../../../img/icon-investor-cardimg/verified-person 1.png";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

const InvestorMasterMyLeads = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [Onepincode, setOnePincode] = useState("");
  const [useApiForLS, setUseApiForLS] = useState([]);
  const [getApiForLS, setGetApiForLS] = useState([]);
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [selectedseverice, setselectedservice] = useState("Employer");
  const [severicepersons, setservicepersons] = useState([
    "Employer",
    "Mentor",
    "Service Provider",
    "Local Service",
    "Training Partner",
  ]);
  const location = useLocation();
  const investor_email = JSON.parse(localStorage.getItem("investor"));
  const navigate = useNavigate();
  // const email = location.state?.email_id;
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (pincode) => {
    setOpen(!open);

    setOnePincode(pincode);
  };
  const axiosData = useAxios();
  const fetchApi = async (service) => {
    try {
      let alldata;
      if (service === "Employer") {
        const useAxiosData = await axiosData.post(`employers/getcustomer`, {
          registertype: "District Wise",
          registerby: investor_email,
        });
        const { data } = useAxiosData;
        alldata = data;
      } else if (service === "Mentor") {
        const useAxiosData = await axiosData.post(`mentor/getcustomer`, {
          registertype: "District Wise",
          registerby: investor_email,
        });
        const { data } = useAxiosData;
        alldata = data;
      } else if (service === "Service Provider") {
        const useAxiosData = await axiosData.post(
          `localserviceprovider/getcustomer`,
          {
            registertype: "District Wise",
            registerby: investor_email,
          }
        );
        const { data } = useAxiosData;
        alldata = data;
      } else if (service === "Local Service") {
        const useAxiosData = await axiosData.post(`localservices/getcustomer`, {
          registertype: "District Wise",
          registerby: investor_email,
        });
        const { data } = useAxiosData;
        alldata = data;
      } else if (service === "Training Partner") {
        const useAxiosData = await axiosData.post(
          `trainingpartners/getcustomer`,
          {
            registertype: "District Wise",
            registerby: investor_email,
          }
        );
        const { data } = useAxiosData;
        alldata = data;
      }

      alldata.sort((a, b) => {
        return a.pincode - b.pincode;
      });
      setGetApi(alldata);
      setUseApi(alldata);
      setTotalRows(alldata.length);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchApi("Employer");
  }, [investor_email]);

  return (
    <div>
      <div className="breadcrumb-desgin" style={{ margin: "0px" }}>
        <Breadcrumb />
      </div>
      <div className="space-between-sor-table">
        <div className="forms-investor-button">
          <h3>Forms</h3>
          <div className="formsbtn">
            <button
              className="btn-form"
              onClick={() => {
                navigate(
                  `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`,
                  {
                    state: {
                      registertype: "District Wise",
                      registerby: investor_email,
                    },
                  }
                );
              }}
            >
              Employer
            </button>
            <button
              className="btn-form"
              onClick={() => {
                navigate(
                  `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTOR}`,
                  {
                    state: {
                      registertype: "District Wise",
                      registerby: investor_email,
                    },
                  }
                );
              }}
            >
              Mentor
            </button>
            <button
              className="btn-form"
              onClick={() => {
                navigate(
                  `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.LOCAL_SERVICE_PROVIDER}`,
                  {
                    state: {
                      registertype: "District Wise",
                      registerby: investor_email,
                    },
                  }
                );
              }}
            >
              Service Provoider
            </button>
            <button
              className="btn-form"
              onClick={() => {
                navigate(
                  `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.LOCAL_SERVICES}`,
                  {
                    state: {
                      registertype: "District Wise",
                      registerby: investor_email,
                    },
                  }
                );
              }}
            >
              Local Service
            </button>
            <button
              className="btn-form"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.BECOMEAPARTNER}`, {
                  state: {
                    registertype: "District Wise",
                    registerby: investor_email,
                  },
                });
              }}
            >
              Training Partner
            </button>
          </div>
        </div>
        <div className="Professiona-header">
          <h3>Select your Professional</h3>
          <div>
            <Autocomplete
              disablePortal
              size="small"
              // value={selectedseverice}
              id="combo-box-demo"
              options={severicepersons}
              onChange={(e, value) => {
                if (value === null) {
                  setselectedservice("Employer");
                } else {
                  setselectedservice(value);
                }
                fetchApi(value);
              }}
              sx={{
                width: 300,
                boxShadow: "0px 0px 4px 0px rgba(16, 143, 178, 0.20)",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Professional"
                  className="autocomplete-inputs-for-investor"
                />
              )}
            />
          </div>
        </div>
        <div className="cards-process">
          <div style={{ textAlign: "center" }}>
            <h3>{selectedseverice} Data</h3>
          </div>
          <div className="cardbox-content">
            <div className="box-1-card designpatten">
              <div className="icon-imgs-card">
                <img src={filterimg} />
              </div>
              <div className="contentstatus">
                <div>856</div>
                <text>Pending</text>
              </div>

              <div className="img-ab-card">
                <img src={boxcard_yellow} />
              </div>
            </div>
            <div className="box-2-card designpatten">
              <div className="icon-imgs-card">
                <img src={personfloder} />
              </div>
              <div className="contentstatus">
                <div>162</div>
                <text>In-Progress</text>
              </div>
              <div className="img-ab-card">
                <img src={boxcard_green} />
              </div>
            </div>
            <div className="box-3-card designpatten">
              <div className="icon-imgs-card">
                <img src={documentimg} />
              </div>
              <div className="contentstatus">
                <div>234</div>
                <text>Quotation</text>
              </div>
              <div className="img-ab-card">
                <img src={boxcard_blue} />
              </div>
            </div>
            <div className="box-4-card designpatten">
              <div className="icon-imgs-card">
                <img src={hand} />
              </div>
              <div className="contentstatus">
                <div>871</div>
                <text>Negotiation</text>
              </div>
              <div className="img-ab-card">
                <img src={boxcard_skyblue} />
              </div>
            </div>
            <div className="box-5-card designpatten">
              <div className="icon-imgs-card">
                <img src={doneimg} />
              </div>
              <div className="contentstatus">
                <div>231</div>
                <text>Deal</text>
              </div>
              <div className="img-ab-card">
                <img src={boxcard_drakgreen} />
              </div>
            </div>
          </div>
        </div>

        <div className="tablecardinvestor">
          <div className="inverstor-table-header">
            <h3>Pending Details</h3>
          </div>
          <div className="input-search-inverstor">
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
              }}
              className="inputsserachtextfield"
              placeholder="Search your data"
              InputProps={{
                // ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Card sx={{ padding: "20px" , mb: 8}}>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              {selectedseverice === "Employer" ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-row investortablerow">
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center"> Company Name</TableCell>
                      <TableCell align="center">Pincode</TableCell>
                      <TableCell align="center">Industry</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useApi.length > 0 ? (
                      <>
                        {useApi.map((item) => (
                          <TableRow
                            // key={item._id}
                            hover
                            className="tablecell "
                          >
                            <TableCell className="investortablecell">
                              {item.contact_person}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.email_id}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.mobile_number}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.company_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.pincode}
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.industry}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-1"
                                >
                                  <OpenInNewRoundedIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-2"
                                >
                                  <SwapHorizIcon />
                                </Button>
                                <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : selectedseverice === "Mentor" ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-row investortablerow">
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center"> Company Name</TableCell>
                      <TableCell align="center">Experience</TableCell>
                      <TableCell align="center">Designation</TableCell>
                      <TableCell align="center">Specialisation</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useApi.length > 0 ? (
                      <>
                        {useApi.map((item) => (
                          <TableRow
                            // key={item._id}
                            hover
                            className="tablecell "
                          >
                            <TableCell className="investortablecell">
                              {item.MentorName}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.email_id}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.mobileNumber}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.currentEmployer}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.yearsOfExperience}
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.Designation}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.Specialisation}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-1"
                                >
                                  <OpenInNewRoundedIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-2"
                                >
                                  <SwapHorizIcon />
                                </Button>
                                <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : selectedseverice === "Service Provider" ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-row investortablerow">
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center">Company Name</TableCell>
                      <TableCell align="center">Service Name</TableCell>
                      <TableCell align="center">Service Offered By</TableCell>
                      <TableCell align="center">pincode</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useApi.length > 0 ? (
                      <>
                        {useApi.map((item) => (
                          <TableRow
                            // key={item._id}
                            hover
                            className="tablecell "
                          >
                            <TableCell className="investortablecell">
                              {item.contact_person_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.email_id}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.contact_number}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.shop_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.service_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.service_offered_by}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">{item.pincode}</div>
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-1"
                                >
                                  <OpenInNewRoundedIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-2"
                                >
                                  <SwapHorizIcon />
                                </Button>
                                <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : selectedseverice === "Local Service" ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-row investortablerow">
                      <TableCell>Name</TableCell>

                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center">Company Name</TableCell>
                      <TableCell align="center">type of service</TableCell>
                      <TableCell align="center">Place of Service</TableCell>
                      <TableCell align="center">pincode</TableCell>
                      <TableCell align="center">Need type</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useApi.length > 0 ? (
                      <>
                        {useApi.map((item) => (
                          <TableRow
                            // key={item._id}
                            hover
                            className="tablecell "
                          >
                            <TableCell className="investortablecell">
                              {item.contact_person_name}
                            </TableCell>

                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.contact_number}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.shop_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.type_of_service}
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.place_of_service}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">{item.pincode}</div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.need_type}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-1"
                                >
                                  <OpenInNewRoundedIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-2"
                                >
                                  <SwapHorizIcon />
                                </Button>
                                <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : selectedseverice === "Training Partner" ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-row investortablerow">
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center">Company Name</TableCell>
                      <TableCell align="center">Industry</TableCell>
                      <TableCell align="center">Investment range</TableCell>
                      <TableCell align="center">pincode</TableCell>
                      <TableCell align="center">Designation</TableCell>
                      <TableCell align="center">District Name</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useApi.length > 0 ? (
                      <>
                        {useApi.map((item) => (
                          <TableRow
                            // key={item._id}
                            hover
                            className="tablecell "
                          >
                            <TableCell className="investortablecell">
                              {item.trainingPartner_name}
                            </TableCell>

                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.email_id}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.mobileNumber}
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              {item.business_name}
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.industry}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.investment_range}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">{item.pincode}</div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.designation}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell "
                              align="center"
                            >
                              <div className="industryinvs">
                                {item.district_name}
                              </div>
                            </TableCell>
                            <TableCell
                              className="investortablecell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-1"
                                >
                                  <OpenInNewRoundedIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="btn-action-investor-2"
                                >
                                  <SwapHorizIcon />
                                </Button>
                                <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : (
                <></>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InvestorMasterMyLeads;
