import React from "react";
import { Box, Paper } from "@mui/material";
import "./PostJob.css";

// const circleContainer = {
// //   position: "relative",
// //   width: "180px",
// //   height: "180px",
// //   borderRadius: "50%",
// //   margin: "auto",
// //   marginTop: "50px",
// };

// const stepContainer = {
//   width: "500px",
//   height: "500px",
//   //   // Set the width and height of the square card
//   //   textAlign: "center",
//   //   backgroundColor: "#f0f0f0", // Customize the card background color
//   //   borderRadius: "8px", // Customize the border radius of the card
//   //   fontWeight: "bold",
//   //   color: "#333",
//   //   fontSize: "16px",
// };

const AuditingData = ({ Auditing, diff }) => {
  const steps = [
    { Heading: "Applied Date", data: Auditing.apply_date, color: "#483d8b" },
    {
      Heading: "Shortlist Date",
      data: Auditing.shortlist_date,
      color: "#ff8c00",
    },
    {
      Heading: "Interview Schedule Date",
      data: Auditing.interview_schedule_date,
      color: "#1976d2",
    },
    {
      Heading: "Interview Date",
      data: Auditing.interview_date,
      color: "#e57bf1",
    },
    { Heading: "Select Date", data: Auditing.selected_date, color: "#008000" },
    { Heading: "Offered Date", data: Auditing.offered_date, color: "#ee1e70" },
  ];
  const getCirclePosition = (index, total) => {
    const angle = ((360 / total) * index - 90) * (Math.PI / 180);
    const radius = 150;
    const x = 100 + radius * Math.cos(angle);
    const y = 100 + radius * Math.sin(angle);
    return { x, y };
  };

  return (
    <div style={{ height: "460px", width: "100%" }}>
      <div className="squreCard">
        {steps.map((step, index) => {
          const { x, y } = getCirclePosition(index, steps.length);
          return (
            <React.Fragment key={index}>
              <Box
                className="circle"
                style={{
                  backgroundColor: step.color,
                  top: `${y}px`,
                  left: `${x}px`,
                  display: "flex",
                  flexDirection: "column",
                }}
                elevation={3}
              >
                {/* {step.Heading}
              {step.data} */}
                <p
                  style={{
                    color: "#ffff",
                    textAlign: "center",
                    margin: 0,
                    fontSize: "15px",
                  }}
                >
                  <b>{step.Heading}</b>
                </p>
                <p style={{ color: "#ffff", margin: 0 }}>
                  <b>{step.data}</b>
                </p>
              </Box>
            </React.Fragment>
          );
        })}
        {/* Center the square card */}

        <div
          style={{
            border: "2px solid black",
            padding: "15px",
            color: "black",
            borderRadius: "20px",
          }}
        >
          <p style={{ margin: 0 }}>
            <b> Total</b>
          </p>
          <p style={{ margin: 0 }}>
            <b>
              {Auditing && diff(Auditing.apply_date, Auditing.offered_date)}{" "}
              Days
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuditingData;
