import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Chip,
  Skeleton,
  FormControl,
  FormLabel,
  Autocomplete,
  Popper,
  TextField,
  Paper,
  Checkbox,
  FormControlLabel,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton,
  RadioGroup,
  Radio,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { Drawer, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import {
  InfoOutlined as InfoOutlinedIcon,
  Close as CloseIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import useAxios from "../../../../api/useAxios";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { saveAs } from "file-saver";
import axios from "axios";
import PartnerInvestorConfirm from "./PartnerInvestorConfirm";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function PincodeAllocateDialog({
  allocatePinDialog,
  setAllocatePinDialog,
  partnerN,
  getId,
  updateAllocatePincode,
  pinLoad,
  setPinLoad,
  getPincode,
  setGetPincode,
  district_name,
  setDistrict_name,
  openConfirm,
  setOpenConfirm,
  namePI,
  setPinSnack,
  pincodeByDistrict,
  setPincodeByDistrict,
  areaNameByDistrict,
  pincodeAreaData,
  setPincodeAreaData,
  setAreaNameByDistrict,
}) {
  const axiosData = useAxios();
  const [selectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [allPincode, setAllPincode] = useState([]);
  const [selectpinOpt, setSelectPinOpt] = useState("single");
  const [selectByPin, setSelectByPin] = useState([]);
  const [dataByArea, setDataByArea] = useState([]);
  const [allP, setAllP] = useState([]);
  const [forselectByArea, setForSelectByArea] = useState([]);
  const [districtByData, setDistrictByData] = useState([]);
  const [singleOpen, setSingleOpen] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = (value) => {
    setSingleOpen(value);
    setOpen(!open);
  };

  const handleCheckAll = (all, bool) => {
    const allcheck = [...forselectByArea];
    const checkedarea = allcheck.map((i) => {
      if (i.pincode === all) {
        i.selectAll.checked = bool;
        i.village_name.map((d) => {
          d.checked = bool;
        });
      }
      return i;
    });
    setForSelectByArea([...checkedarea]);
  };

  const handleCheck = (pin, area) => {
    const allcheck = [...forselectByArea];
    const checkedarea = allcheck.map((i) => {
      if (i.pincode === pin) {
        i.village_name.map((d) => {
          if (d.area === area) {
            d.checked = !d.checked;
          }
        });
        const boolenvalue = i.village_name.every((d) => {
          return d.checked;
        });
        if (i.selectAll.checked !== boolenvalue) {
          i.selectAll.checked = boolenvalue;
        }
      }
      return i;
    });
    setForSelectByArea([...checkedarea]);
  };

  const [areaName, setareaName] = useState([]);
  const [pincodeallocate, setpincodeallocate] = useState([]);

  const getAreaName = async () => {
    try {
      const res = await axiosData.get(`/partnerandinvestorpincode`);
      setAllPincode([...res.data]);
    } catch (err) {}
  };
  const sataGetForSelectByArea = async () => {
    if (district_name) {
      setLoading(true);
      let getData;
      if (namePI === "partner") {
        getData = await axiosData.get(
          `/partnerandinvestorpincode/partner/${district_name}`
        );
      } else {
        getData = await axiosData.get(
          `/partnerandinvestorpincode/investor/${district_name}`
        );
      }
      const { data } = getData;
      const tempdata = data.map((item) => ({ ...item }));
      setDistrictByData([...data]);
      const fulldata = tempdata.map((i) => {
        const d = i.village_name.map((val) => {
          return { area: val, checked: false };
        });
        i.village_name = d;
        i.selectAll = { area: "Select all", checked: false };
        return i;
      });
      fulldata.sort((a, b) => {
        return a.pincode - b.pincode;
      });
      setForSelectByArea([...fulldata]);
      setLoading(false);
    }
  };
  const selectByPinSelectAll = async () => {
    let arr = [];
    if (district_name) {
      let getData;
      if (namePI === "partner") {
        getData = await axiosData.get(
          `/partnerandinvestorpincode/partner/${district_name}`
        );
      } else {
        getData = await axiosData.get(
          `/partnerandinvestorpincode/investor/${district_name}`
        );
      }
      const { data } = getData;
      if (selectpinOpt === "single") {
        data.map((i) => {
          const inx = pincodeAreaData.find((v) => {
            return v === i.pincode;
          });
          if (inx === i.pincode) {
            const pinArea = {
              pincode: i.pincode,
              village_name: i.village_name,
            };
            arr.push(pinArea);
          }
        });
        arr.sort((a, b) => {
          return a.pincode - b.pincode;
        });
        setSelectByPin([...arr]);
      } else {
        setDataByArea(data);
      }
    }
  };
  const getall = selectpinOpt === "single";

  useEffect(() => {
    setPincodeByDistrict("");
    getAreaName();

    setareaName("");
    setAreaNameByDistrict("");
  }, []);
  useEffect(() => {
    sataGetForSelectByArea();
  }, [district_name, getall]);
  const handlegetvalue = (value, pinselectvalue) => {
    let arrObj = {};

    if (allP.length === 0) {
      if (value === "Select all") {
        const filtered_village = districtByData.find((i) => {
          return i.pincode === pinselectvalue;
        });
        arrObj = {
          pincode: pinselectvalue,
          village_name: [...filtered_village.village_name],
        };
        setAllP([...allP, arrObj]);
      } else {
        arrObj = {
          pincode: pinselectvalue,
          village_name: [value],
        };

        setAllP([...allP, arrObj]);
      }
    } else {
      const inx = allP.findIndex((i) => {
        return i.pincode === pinselectvalue;
      });
      if (inx === -1) {
        if (value === "Select all") {
          const filtered_village = districtByData.find((i) => {
            return i.pincode === pinselectvalue;
          });

          arrObj = {
            pincode: pinselectvalue,
            village_name: [...filtered_village.village_name],
          };

          setAllP([...allP, arrObj]);
        } else {
          arrObj = {
            pincode: pinselectvalue,
            village_name: [value],
          };

          setAllP([...allP, arrObj]);
        }
      } else {
        const deleteAllArea = [...allP];
        const filtered_checking = deleteAllArea.find((i) => {
          return i.pincode === pinselectvalue;
        });
        const filtered_check = districtByData.find((i) => {
          return i.pincode === pinselectvalue;
        });
        if (value === "Select all") {
          if (
            filtered_checking.village_name.length ===
            filtered_check.village_name.length
          ) {
            const filtered_village = deleteAllArea.filter((i) => {
              return i.pincode !== pinselectvalue;
            });
            setAllP([...filtered_village]);
          } else {
            const filtered_village2 = districtByData.find((i) => {
              return i.pincode === pinselectvalue;
            });

            const filtered_village1 = deleteAllArea.filter((i) => {
              if (i.pincode === pinselectvalue) {
                i.village_name = [...filtered_village2.village_name];
                return i;
              } else {
                return i;
              }
            });

            setAllP([...filtered_village1]);
          }
        } else {
          if (
            filtered_checking.village_name.length ===
            filtered_check.village_name.length
          ) {
            if (filtered_checking.village_name.length === 1) {
              const filtered_village = deleteAllArea.filter((i) => {
                return i.pincode !== pinselectvalue;
              });

              setAllP([...filtered_village]);
            } else {
              const filtered_village = deleteAllArea.filter((i) => {
                if (i.pincode === pinselectvalue) {
                  const deleteone = i.village_name.filter((del) => {
                    return del !== value;
                  });
                  i.village_name = [...deleteone];
                  return i;
                } else {
                  return i;
                }
              });

              setAllP([...filtered_village]);
            }
          } else {
            const filtered = deleteAllArea.filter((v) => {
              if (v.pincode === pinselectvalue) {
                const ind = v.village_name.findIndex((m) => {
                  return m === value;
                });

                if (ind === -1) {
                  v.village_name = [...v.village_name, value];
                } else {
                  if (filtered_checking.village_name.length === 1) {
                    return false;
                  }
                  const del = v.village_name.filter((m) => {
                    if (m === value) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                  v.village_name = [...del];
                }
                return v;
              } else {
                return v;
              }
            });
            setAllP([...filtered]);
          }
        }
      }
    }
  };

  const getAreaNameByPincode = async (value) => {
    try {
      const axiosDatas = await axiosData.post(
        `partnerandinvestorpincode/GetPin/pincode`,
        { pincode: value, district: district_name }
      );
      const { data } = axiosDatas;

      setSelectByPin([
        ...selectByPin,
        { pincode: value, village_name: data.village_name },
      ]);

      setareaName([...areaName, ...data.village_name]);
    } catch (err) {}
  };

  return (
    <>
      <Dialog
        open={allocatePinDialog}
        fullWidth
        sx={{ "& .MuiPaper-root": { width: "900px" }, width: "100%" }}
      >
        <DialogTitle>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h5" sx={{ verticalAlign: "center" }}>
              Allocate Pincode
            </Typography>
            <IconButton
              onClick={() => {
                setGetPincode([]);
                setAllocatePinDialog(false);
                setForSelectByArea([]);
                setSelectPinOpt("single");
                setSelectByPin([]);
                setOpenConfirm(false);
                setSelectAll(false);
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "20px",
            }}
          >
            {" "}
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select Option
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectpinOpt}
                onChange={(e) => {
                  setSelectPinOpt(e.target.value);
                  setGetPincode([]);
                  setareaName([]);
                  setSelectByPin([]);
                  setpincodeallocate("");
                  setPincodeByDistrict("");
                  setOpen(false);
                  // setareaName("");
                  setAreaNameByDistrict("");
                  setSelectAll(false);
                  setAllP([]);
                  setForSelectByArea([]);
                }}
              >
                <FormControlLabel
                  value="single"
                  control={<Radio />}
                  label="Select by Pincode"
                />
                <FormControlLabel
                  value="multi"
                  control={<Radio />}
                  label="Select by Area name"
                />
              </RadioGroup>
            </FormControl>
            {selectpinOpt === "single" ? (
              <>
                {" "}
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <FormControl fullWidth>
                    <FormLabel sx={{ fontWeight: "bold" }}>Pincode</FormLabel>
                    <Autocomplete
                      button
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      multiple
                      disabled={district_name ? false : true}
                      disableClearable={getPincode ? false : true}
                      id="pincode"
                      name="pincode"
                      options={pincodeAreaData}
                      onChange={(event, value, reason) => {
                        if (pincodeAreaData.length === value.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                        if (reason === "clear") {
                          setGetPincode([]);
                          setSelectByPin([]);
                        }
                        setGetPincode(value);
                        if (reason === "selectOption") {
                          const valLength = value.length;

                          getAreaNameByPincode(value[valLength - 1]);
                        }
                        if (reason === "removeOption") {
                          const filteredPinArea = selectByPin.filter(
                            (i, ind) => {
                              const inx = value.findIndex((item) => {
                                return item === i.pincode;
                              });
                              if (inx === -1) {
                                return false;
                              } else {
                                return true;
                              }
                            }
                          );

                          setSelectByPin(filteredPinArea);
                        }
                      }}
                      value={getPincode}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          labelId="demo-simple-select-label"
                          name="pincode"
                          // label={t("Pincode")}
                          type="number"
                          id="pincode"
                          value={getPincode}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          error={district_name === "" ? true : false}
                          helperText={
                            district_name === "" ? (
                              <>
                                <Box display="flex">
                                  <Box display="flex" alignItems={"center"}>
                                    <InfoOutlinedIcon fontSize="small" />
                                  </Box>

                                  <Box display="flex" alignItems={"center"}>
                                    <Typography variant="caption">
                                      Please Select District name Before
                                      allocating pincode!
                                    </Typography>
                                  </Box>
                                </Box>
                              </>
                            ) : null
                          }
                          // inputProps={{onError}}
                          // onKeyDown={(e) =>
                          //   exceptThisSymbols.includes(e.key) &&
                          //   e.preventDefault()
                          // }

                          {...params}
                        />
                      )}
                    />
                    <FormControlLabel
                      sx={{ width: "25%" }}
                      checked={selectAll}
                      onChange={(e) => {
                        setSelectAll(e.target.checked);
                        if (e.target.checked === true) {
                          setSelectByPin([]);
                          setGetPincode([...pincodeAreaData]);
                          selectByPinSelectAll();
                        } else if (e.target.checked === false) {
                          setGetPincode([]);
                          setSelectByPin([]);
                        }
                      }}
                      control={<Checkbox />}
                      label="Select all"
                    />
                  </FormControl>
                  {/* <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  // setDistrict_name("");
                  setGetPincode([]);
                  setAllocatePinDialog(false);
                }}
              >
                Cancel
              </Button> */}
                </Box>
                <Box>
                  {/* <TableContainer component={Paper} sx={{ width: "50%" }}> */}
                  <Table sx={{ width: "550px" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl.no</TableCell>
                        <TableCell>Pincode</TableCell>
                        {/* <TableCell>PostOffice Name</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectByPin.length >= 1 ? (
                        selectByPin.map((i, ind) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{i.pincode}</TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            Please select atleast one pincode
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </>
            ) : (
              <>
                {loading === true ? (
                  <CircularProgress
                    size={50}
                    style={{
                      color: "#1976d2",
                      marginLeft: 12,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "55% 55%",
                      width: "80%",
                      gap: "20px",
                    }}
                  >
                    {forselectByArea.map((i) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        component="nav"
                      >
                        <ListItemButton
                          className="PartnerPincode"
                          onClick={() => {
                            handleClick(i.pincode);
                          }}
                        >
                          <ListItemText primary={i.pincode} />
                          {i.pincode === singleOpen && open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={i.pincode === singleOpen ? open : false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton
                            onClick={() => {
                              handlegetvalue(i.selectAll.area, i.pincode);
                              handleCheckAll(i.pincode, !i.selectAll.checked);
                            }}
                          >
                            <Checkbox checked={i.selectAll.checked} />
                            <ListItemText primary={i.selectAll.area} />
                          </ListItemButton>
                          <List component="div" disablePadding>
                            {i.village_name.map((item) => (
                              <ListItemButton
                                onClick={() => {
                                  handleCheck(i.pincode, item.area);
                                  handlegetvalue(item.area, i.pincode);
                                }}
                                key={item.id}
                              >
                                <Checkbox checked={item.checked} />
                                <ListItemText primary={item.area} />
                              </ListItemButton>
                            ))}
                          </List>
                        </Collapse>
                      </List>
                    ))}
                  </Box>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <Box padding={"0 20px"} width={"100%"}>
          <Button
            fullWidth
            disabled={getPincode.length > 0 || allP.length > 0 ? false : true}
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => setOpenConfirm(true)}
          >
            Submit
          </Button>
        </Box>
        <DialogActions></DialogActions>
      </Dialog>
      {openConfirm && (
        <PartnerInvestorConfirm
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          partnerN={partnerN}
          getId={getId}
          updateAllocatePincode={updateAllocatePincode}
          pinLoad={pinLoad}
          namePI={namePI}
          selectpinOpt={selectpinOpt}
          setSelectPinOpt={setSelectPinOpt}
          allP={allP}
          setAllP={setAllP}
          setForSelectByArea={setForSelectByArea}
          selectByPin={selectByPin}
        />
      )}
    </>
  );
}

export default PincodeAllocateDialog;
