import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Chip,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import Context from "../Context";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import useAxios from "../../../../api/useAxios";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function KeySkill({ setput }) {
  const [open, setOpen] = useState(false);
  const [skill, setSkill] = useState();
  const [chip, setChip] = useState([]);

  const handleSkill = (e) => {
    setSkill(e.target.value);
  };
  const addSkill = (Context) => {
    if (skill !== "") {
      chip.push(skill);
      setChip([...chip]);
      const uniqueArray = Array.from(new Set(chip));
      setChip(uniqueArray);
      setSkill("");
    }
  };
  const handleDelete = (index) => {
    chip.splice(index, 1);
    setChip([...chip]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const axiosData = useAxios();

  const Location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      Keyskill: "",
    },
    validationSchema: Yup.object().shape({
      Keyskill: Yup.string().max(100, "Must be 20 characters or less"),
    }),
    onSubmit: async (values) => {
      if (chip !== []) {
        if (chip[0] === "") {
          formik.values.Keyskill = chip.slice(1, chip.length).toString();
        } else {
          formik.values.Keyskill = chip.toString();
        }
      }
      if (chip[0] === undefined) {
        formik.values.Keyskill = "";
      }

      setIsSubmitting(true);
      await axiosData.put(
        `/seeker/email/${Location.state}`,
        { Keyskill: values.Keyskill },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setChip([]);
      setput(true);
      setIsSubmitting(false);
      handleClose();
      formik.handleReset("");
    },
  });

  return (
    <Context.Consumer>
      {(Context) => {
        let skills = "";
        if (Context.profile.Keyskill) {
          let temp = Context.profile.Keyskill;
          skills = temp.split(",");
        }
        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              sx={{zIndex:"99999"}}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  {" "}
                  <Typography variant="h5">
                    <b> Key Skills</b>
                  </Typography>
                  <Typography>
                    Tell recruiters what you know or what you are known for e.g.
                    Direct Marketing, Oracle, Java etc. We will send you job
                    recommendations based on these skills.
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Grid item xs={12} sm={12} md={8} square>
                      <Box>
                        <Box>
                          {chip.map((element, index) => {
                            if (element !== "") {
                              return (
                                <Chip
                                  value={formik.values.Keyskill}
                                  onChange={formik.handleChange}
                                  key={index}
                                  label={element}
                                  onDelete={() => handleDelete(index)}
                                />
                              );
                            }
                          })}
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <Grid container>
                            <Grid xs={7}>
                              <TextField
                                fullWidth
                                id="Key Skills"
                                label="Key Skills"
                                name="Keyskill"
                                value={skill}
                                onChange={handleSkill}
                              />
                            </Grid>
                            <Grid xs={4}>
                              <Button
                                variant="outlined"
                                sx={{ p: 1.8, ml: 0.5 }}
                                disabled={!skill}
                                onClick={() => {
                                  addSkill(Context);
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={chip === [] || isSubmitting === true}
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "#ffffff",

                          marginLeft: 12,
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <Grid xs={12} md={12} lg={12}>
              <Box
                id="KeySkill"
                sx={{ fontSize: "18px", textTransform: "uppercase" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <b style={{color:"rgb(16 143 178)"}}>Key Skills *</b>
                  <Button
                    sx={{ mt: -1 }}
                    onClick={() => {
                      const str = Context.profile.Keyskill;

                      let temp = str.split(",");
                      if (temp !== "") {
                        setChip([...temp]);
                      }
                      handleClickOpen();
                    }}
                  >
                   <Tooltip title="Edit" arrow>
                    <EditIcon />
                    </Tooltip>
                  </Button>
                </Box>
                <hr/>
                <Box className="scrollable-container">
                  <List>
                    {skills ? (
                      skills.map((value) => (
                        <ListItem key={value} sx={{ m: 0, p: 0 }}>
                          <Typography
                            sx={{ fontSize: 14, fontWeight: 600 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {`${value}`}
                          </Typography>
                        </ListItem>
                      ))
                    ) : (
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 600, mt: 1 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Mention your key skills.
                      </Typography>
                    )}
                  </List>
                </Box>
              </Box>
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default KeySkill;
