import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  Tooltip,
  Popper,
  Paper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import { Data } from "../employer/Data";
import { useFormik } from "formik";
import * as Yup from "yup";
import context from "./EmpContext";
import { useLocation } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ShareLocationRounded as ShareLocationRoundedIcon } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
function CompanyDetails({ setput }) {
  const axiosData = useAxios();
  const Location = useLocation();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [focusLandNo, setFocusLandNo] = useState();
  const [focusEyear, setFocusEyear] = useState();
  const [focusCpyUrl, setFocusCpyUrl] = useState();
  const [districtData, setDistrictData] = useState([]);
  const [comdata, setComdata] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);

  const formik = useFormik({
    initialValues: {
      district: "",
      company_address: "",
      city: "",
      pincode: "",
      company_url: "",
      landline_number: "",
      established_year: "",
    },
    validationSchema: Yup.object({
      district: Yup.string().required("District Must Required "),
      pincode: Yup.string().required("pincode is required"),
      company_url: Yup.string().matches(
        /^(ftp|http|https):\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]+.*/,
        "Invalid URL",
        () => {
            setFocusCpyUrl(false);
        }
    ),
      city: Yup.string().matches(
        /^[A-Za-z. ]+$/,
        "City can only contain alphabetic characters"
      ),
      landline_number: Yup.string()
        .matches(
          /^([0-9]{2,5})[-. ]?([0-9]{6,8})$/,
          "Invalid landline number format. Please enter a valid landline number."
        )
        .min(
          10,
          "Invalid landline number format. Please enter a valid landline number."
        ),
      established_year: Yup.string()
        .min(4, "Provide Valid Year!")
        .max(4, "Provide Valid Year!"),
    }),

    onSubmit: async (values) => {
      setIsSubmitting(true);
      const pincodeSplit = values.pincode.split("/");
      const [a, b] = pincodeSplit;
      values.pincode = a;
      values.area = b;
      await axiosData.put(`employers/email/${Location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      formik.handleReset("");
      setput(true);

      setOpen(handleClose);
      setIsSubmitting(false);
    },
  });
  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleChangeEyear = (e) => {
    setFocusEyear(false);
    const { value } = e.target;
    if (value.length <= 4) {
      formik.handleChange(e);
    }
  };
  const handleChangeLandNo = (e) => {
    setFocusLandNo(false);
    const { value } = e.target;
    if (value.length <= 13) {
      formik.handleChange(e);
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const handleClickOpen = (context) => {
    const value = `${context.pincode}/${context.area}`;
    context.pincode = value;
    formik.setFieldValue("district", context.district);
    formik.setFieldValue("company_address", context.company_address);
    formik.setFieldValue("city", context.city);
    formik.setFieldValue("pincode", context.pincode);
    formik.setFieldValue("established_year", context.established_year);
    formik.setFieldValue("company_url", context.company_url);
    formik.setFieldValue("landline_number", context.landline_number);
    setOpen(true);
  };

  const handleClose = () => {
    formik.handleReset("");
    setOpen(false);
    setput(true);
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    vodingDistrict();
  }, []);

  return (
    <context.Consumer>
      {(context) => {
        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              sx={{zIndex:"99999"}}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5" sx={{ marginBottom: 3 }}>
                    <b>Company Details</b>
                  </Typography>

                  <Grid item xs={12} sm={12} md={8} square>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Grid container columnSpacing={3}>
                        <Grid item xs={12}>
                          <FormControl fullWidth required>
                            <Autocomplete
                              disableClearable={
                                formik.values.district ? false : true
                              }
                              fullWidth
                              filterOptions={filterOptions}
                              name="district"
                              id="district"
                              value={formik.values.district}
                              onChange={(event, value) => {
                                formik.setFieldValue("district", value);
                                searchobj(value);
                                formik.setFieldValue("pincode", "");
                              }}
                              onBlur={formik.handleBlur}
                              options={districtData}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label="District"

                                  // onClick={() =>
                                  //   params.inputProps.onInputClick()
                                  // }
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start", // Placement set to "bottom-start"
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                            {formik.touched.district &&
                            formik.errors.district ? (
                              <div style={{ color: "red", fontSize: "15px" }}>
                                {formik.errors.district}
                              </div>
                            ) : null}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: 1 }}>
                          <TextField
                            margin="normal"
                            fullWidth
                            placeholder="Enter your company address"
                            id="company_address"
                            label="Company address"
                            name="company_address"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e) {
                                const value = e.target.value.replace(
                                  /[^a-z0-9():@ /,.$#_-]/gi,
                                  ""
                                );
                                formik.setFieldValue("company_address", value);
                              }
                            }}
                            value={formik.values.company_address}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            fullWidth
                            placeholder="Enter your city"
                            name="city"
                            label="City"
                            id="city"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e) {
                                const value = e.target.value.replace(
                                  /[^a-z. ]/gi,
                                  ""
                                );
                                formik.setFieldValue("city", value);
                              }
                            }}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <Tooltip
                            title={
                              formik.values.district
                                ? ""
                                : "please select District"
                            }
                          >
                            <FormControl fullWidth required sx={{ marginY: 2 }}>
                              <Autocomplete
                                disableClearable={
                                  formik.values.pincode ? false : true
                                }
                                disablePortal
                                freeSolo
                                disabled={
                                  formik.values.district && !pinLoad
                                    ? false
                                    : true
                                }
                                id="pincode"
                                name="pincode"
                                options={comdata}
                                value={formik.values.pincode}
                                onChange={(event, value) => {
                                  formik.setFieldValue("pincode", value);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    // onChange={formik.handleChange}
                                    {...params}
                                    label="Pincode"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {pinLoad ? (
                                            <CircularProgress
                                              // size={24}
                                              style={{
                                                width: "1.4rem",
                                                height: "1.4rem",
                                                color: "#4991dd",
                                              }}
                                            />
                                          ) : (
                                            <ShareLocationRoundedIcon
                                              sx={{
                                                color: formik.values.district
                                                  ? "#414141"
                                                  : "#bdbdbd",
                                              }}
                                            />
                                          )}{" "}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />

                              {/* <TextField
                        margin="normal"
                        fullWidth
                        name="pincode"
                        label={t("Pincode")}
                        type="Number"
                        id="pincode"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlurCapture={() => {
                          setFocusPin(true);
                        }}
                        placeholder={t("Enter_Pincode(Optional)")}
                        onBlur={formik.handleBlur}
                        onChange={handleChangePincode}
                        value={formik.values.pincode}
                      /> */}
                              {formik.touched.pincode &&
                              formik.errors.pincode ? (
                                <div style={{ color: "red", fontSize: "15px" }}>
                                  {formik.errors.pincode}
                                </div>
                              ) : null}
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            placeholder="Enter your Company url"
                            id="company_url"
                            label={`Company URL(Ex:${process.env.REACT_APP_DEFAULT_URL_FOR_AD})`}
                            name="company_url"
                            onBlurCapture={() => {
                              setFocusCpyUrl(true);
                            }}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              setFocusCpyUrl(false);
                              formik.setFieldValue(
                                "company_url",
                                e.target.value
                              );
                            }}
                            value={formik.values.company_url}
                          />
                          {formik.touched.company_url &&
                          formik.errors.company_url &&
                          focusCpyUrl ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.company_url}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            placeholder="Enter your landline number"
                            id="landline_number"
                            type="number"
                            label="Landline number"
                            name="landline_number"
                            onBlurCapture={() => {
                              setFocusLandNo(true);
                            }}
                            onBlur={formik.handleBlur}
                            onChange={handleChangeLandNo}
                            value={formik.values.landline_number}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                          />
                          {formik.touched.landline_number &&
                          formik.errors.landline_number &&
                          focusLandNo ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.landline_number}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            type="Number"
                            placeholder="Enter company established year"
                            id="established_year"
                            label="Established year"
                            name="established_year"
                            onBlur={formik.handleBlur}
                            onChange={handleChangeEyear}
                            value={formik.values.established_year}
                            onBlurCapture={() => {
                              setFocusEyear(true);
                            }}
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          {formik.touched.established_year &&
                          formik.errors.established_year &&
                          focusEyear ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.established_year}
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </DialogTitle>
                <DialogActions sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <Grid>
              <Box
                id="CompanyDetails"
                sx={{ fontSize: "20px", textTransform: "uppercase", mb: 1 }}
              >
                <b>Company Details</b>
                <Tooltip title="Edit Company Details" arrow>
                  <IconButton
                    sx={{ ml: 1, mb: 1 }}
                    color="primary"
                    onClick={() => {
                      handleClickOpen(context);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <div className="keypair">
                <Typography id="companykey">
                  District <span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.district}
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  Company Address <span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.company_address}
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  City <span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.city}
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  Pincode/Area<span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.pincode}/{context.area}
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  Company URL <span id="none">:</span>
                </Typography>
                <Typography id="companyvalue">
                  <Link
                    href={context.company_url}
                    underline="hover"
                    target="blank"
                  >
                    {context.company_url}
                  </Link>
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  Landline Number <span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.landline_number}
                </Typography>
              </div>
              <div className="keypair">
                <Typography id="companykey">
                  Established Year <span id="none">:</span>
                </Typography>
                <Typography color="text.secondary" id="companyvalue">
                  {context.established_year}
                </Typography>
              </div>
            </Grid>
          </div>
        );
      }}
    </context.Consumer>
  );
}

export default CompanyDetails;
