import React, { useState } from "react";
import {
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Autocomplete,
  FormLabel,
  IconButton,
  InputAdornment,
  Tooltip,
  Popper,
  CircularProgress,
  Button,
  Divider,
  Container,
  Chip,
  Select,
  Link,
  Checkbox,
  Avatar,
  Card,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { ReactComponent as VVlogo } from "../../../img/partner/Logo_as_svg.svg";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import {
  ContactPageRounded as ContactPageRoundedIcon,
  HomeWorkRounded as HomeWorkRoundedIcon,
  LockOutlined as LockOutlinedIcon,
  HandshakeRounded as HandshakeRoundedIcon,
  pincodeOnRounded as pincodeOnRoundedIcon,
  BusinessRounded as BusinessRoundedIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  EmailRounded as EmailRoundedIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
} from "@mui/icons-material";

import { Formik } from "formik";
import * as Yup from "yup";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";

import { useEffect } from "react";
import useAxios from "../../../api/useAxios";
import { ReactComponent as PartnerSvg } from "../../../img/partner/undraw_agreement_re_d4dv.svg";
import "./partner_investor.css";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useLocation } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function BecomeAPartner() {
  const theme = useTheme();
  const axiosData = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getStatusCode, setGetStatusCode] = useState(0);
  const [trainingSkill, setTrainingSkill] = useState("");
  const [trainingSkillChip, setTrainingSkillChip] = useState([]);
  const [isFocused, setIsFocused] = useState({
    trainingPartner_name: false,
    email_id: false,
    mobileNumber: false,
    district_name: false,
    industry: false,
    investment_range: false,
    pincode: false,
    yearsOfExperience: false,
    business_name: false,
    students_trained: false,
    placed_students: false,
    Training_skills: false,
    designation: false,
  });
  const [captureBlur, setCaptureBlur] = useState({
    trainingPartner_name: false,
    email_id: false,
    mobileNumber: false,
    district_name: false,
    industry: false,
    investment_range: false,
    pincode: false,
    yearsOfExperience: false,
    business_name: false,
    students_trained: false,
    placed_students: false,
    Training_skills: false,
    designation: false,
  });
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const initialValues = {
    trainingPartner_name: "",
    email_id: "",
    mobileNumber: "",
    district_name: "",
    industry: "",
    investment_range: "",
    pincode: "",
    yearsOfExperience: "",
    business_name: "",
    students_trained: null,
    placed_students: null,
    Training_skills: "",
    designation: "",
  };
  const validationSchema = Yup.object().shape({
    trainingPartner_name: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Name must be Required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email must be Required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .test("is-valid-email", "Invalid Email Address", function (value) {
        const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          value
        );
        if (!value) {
          return false;
        } else if (!isValidEmail) {
          return false;
        } else if (isValidEmail) {
          if (getStatusCode === 200) {
            return this.createError({
              message: `Email already exist in ${process.env.REACT_APP_WEBSITE_NAME}${process.env.REACT_APP_WEBSITE_NAME_TYPE}`,
              path: "email_id",
            });
          } else if (getStatusCode === 204) {
            return true;
          }
        } else {
          return true;
        }
        return true;
      }),
    mobileNumber: Yup.number()
      .min(1000000000, "Not Valid Mobile Number!")
      .max(9999999999, "Not Valid Mobile Number!")
      .required("Mobile Number must be Required"),
    district_name: Yup.string().required("District name must be required"),
    industry: Yup.string().required("Industry must be required"),
    investment_range: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Investment Range must be Required"),
    pincode: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("pincode must be Required"),
    yearsOfExperience: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Years of Experience must be Required"),
    business_name: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Business Name must be Required"),
    students_trained: Yup.number().required(
      "No. of Students Trained must be Required"
    ),
    placed_students: Yup.number().required(
      "No. of Students Placed must be Required"
    ),
    designation: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Designation must be Required"),
  });
  const handleFormikSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    try {
      await axiosData.post(`trainingpartners`, {
        trainingPartner_name: values.trainingPartner_name,
        email_id: values.email_id,
        mobileNumber: values.mobileNumber,
        industry: values.industry,
        district_name: values.district_name,
        investment_range: values.investment_range,
        pincode: values.pincode,
        yearsOfExperience: values.yearsOfExperience,
        business_name: values.business_name,
        students_trained: values.students_trained,
        placed_students: values.placed_students,
        Training_skills: values.Training_skills,
        designation: values.designation,
        registertype: location.state.registertype,
        registerby: location.state.registerby,
      });
      resetForm();
      setCaptureBlur({
        ...captureBlur,
        trainingPartner_name: false,
        email_id: false,
        mobileNumber: false,
        district_name: false,
        industry: false,
        investment_range: false,
        pincode: false,
        yearsOfExperience: false,
        business_name: false,
        students_trained: false,
        placed_students: false,
        Training_skills: false,
        designation: false,
      });
      setIsSubmitting(false);
      if (location.state.registertype === "home") {
        navigate(
          `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYouForLocal}`
        );
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const [districtData, setDistrictData] = useState([]);
  const [industryArray, setIndustryArray] = useState();
  const [comdata, setComdata] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);
  const [district, setDistrict] = useState([]);
  const votingDistrict = async (district) => {
    try {
      const getOneAxios = await axiosData.get(`/General/countries/${district}`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.districtName;
        });
        data.sort();
        setDistrictData(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };
  const yearsOfExperience = [
    "0-5 Years",
    "6-10 years",
    "11-15 Years",
    "More than 15 Years",
  ];
  const investmentRange = ["1 lakh", "2 lakhs", "3 lakhs"];
  useEffect(() => {
   // votingDistrict();
    IndustryData();
  }, []);

  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/General/${values}/pincode`
      );
      setpinLoad(false);
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.pincode;
        });
        const getpost = datapin.map((i) => {
          return i.officeName;
        });
        const getpost1 = getpost;
        const result = getpin.reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
          padding: theme.spacing(2),
          backgroundColor: "#005e82",
        }}
      >
        {" "}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: "whitesmoke",
              transition: "0.3s all",
              "&:hover": { backgroundColor: "whitesmoke" },
              // width: "200px",
            }}
            onClick={() => {
              if (location.state.registertype === "home") {
                navigate(ROUTINGDATA.LAND);
              } else {
                navigate(-1);
              }
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        </Box>
        <Card
          sx={{
            width: isMobile ? "100%" : "90%",
            display: "flex",
            flexDirection: "row",
            borderRadius: 0,
            overflow: "hidden",
            height: isMobile ? "100%" : isDeviceDown ? "80%" : "100%",
            backgroundColor: "#005e82",
            boxShadow: 0,
          }}
        >
          {isMobile ? null : (
            <Box
              sx={{
                width: "35%",
                position: "relative",
                height: "100%",
                backgroundColor: "#005e82",
              }}
            >
              <Box sx={{ my: -1, width: "100%", height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <Box
                    sx={{
                      // height: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingRight: "70px",
                    }}
                  >
                    <HandshakeRoundedIcon
                      sx={{ fontSize: "10vw", color: "#ffffff" }}
                    />
                  </Box>
                  <Box>
                    {" "}
                    <Typography
                      width={"100%"}
                      variant="h5"
                      sx={{
                        fontFamily:
                          isDevice || isDeviceDown
                            ? "Poppins-Bold"
                            : "Poppins-SemiBold",
                        // fontWeight: "bold",
                        fontSize: isDevice
                          ? "25px"
                          : isDeviceDown
                          ? "3vw"
                          : "1vw",
                        // wordSpacing: "5px",
                        color: "#ffffff",
                        paddingRight: "70px",
                      }}
                    >
                      Join Us
                    </Typography>
                  </Box>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily:
                        isDevice || isDeviceDown
                          ? "Poppins-Bold"
                          : "Poppins-SemiBold",
                      // fontWeight: "bold",
                      fontSize: isDevice
                        ? "20px"
                        : isDeviceDown
                        ? "3vw"
                        : "1vw",
                      // wordSpacing: "5px",
                      color: "#ffffff",
                      paddingLeft: "50px",
                    }}
                  >
                    Training Partner Focus Areas:
                  </Typography>
                  <Box width={"90%"}>
                    <ul>
                      <li
                        width={"100%"}
                        variant="h5"
                        style={{
                          fontFamily:
                            isDevice || isDeviceDown
                              ? "Poppins-SemiBold"
                              : "Poppins-SemiBold",
                          fontWeight: "bold",
                          fontSize: isDevice
                            ? "15px"
                            : isDeviceDown
                            ? "1vw"
                            : "0.25vw",
                          wordSpacing: "3px",
                          color: "#ffffff",
                        }}
                      >
                        {" "}
                        One-on-One Career Guidance
                      </li>

                      <li
                        width={"100%"}
                        variant="h5"
                        style={{
                          fontFamily:
                            isDevice || isDeviceDown
                              ? "Poppins-SemiBold"
                              : "Poppins-SemiBold",
                          fontWeight: "bold",
                          fontSize: isDevice
                            ? "15px"
                            : isDeviceDown
                            ? "1vw"
                            : "0.25vw",
                          wordSpacing: "3px",
                          color: "#ffffff",
                          padding: "10px 0px 0px 0px",
                        }}
                      >
                        Explain 100 Days Career Roadmap Program
                      </li>
                      <li
                        width={"100%"}
                        variant="h5"
                        style={{
                          fontFamily:
                            isDevice || isDeviceDown
                              ? "Poppins-SemiBold"
                              : "Poppins-SemiBold",
                          fontWeight: "bold",
                          fontSize: isDevice
                            ? "15px"
                            : isDeviceDown
                            ? "1vw"
                            : "0.25vw",
                          wordSpacing: "3px",
                          color: "#ffffff",
                          padding: "10px 0px 0px 0px",
                        }}
                      >
                        Job-Oriented Training
                      </li>
                      <li
                        width={"100%"}
                        variant="h5"
                        style={{
                          fontFamily:
                            isDevice || isDeviceDown
                              ? "Poppins-SemiBold"
                              : "Poppins-SemiBold",
                          fontWeight: "bold",
                          fontSize: isDevice
                            ? "15px"
                            : isDeviceDown
                            ? "1vw"
                            : "0.25vw",
                          wordSpacing: "3px",
                          color: "#ffffff",
                          padding: "10px 0px 0px 0px",
                        }}
                      >
                        Mentorship Support
                      </li>
                      <li
                        width={"100%"}
                        variant="h5"
                        style={{
                          fontFamily:
                            isDevice || isDeviceDown
                              ? "Poppins-SemiBold"
                              : "Poppins-SemiBold",
                          fontWeight: "bold",
                          fontSize: isDevice
                            ? "15px"
                            : isDeviceDown
                            ? "1vw"
                            : "0.25vw",
                          wordSpacing: "3px",
                          color: "#ffffff",
                          padding: "10px 0px 0px 0px",
                        }}
                      >
                        Interview Support
                      </li>
                    </ul>
                  </Box>
                  {/* <Box width="100%" display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#ffffff",
                        borderRadius: "1.5rem",
                        width: isMobile
                          ? "14vw"
                          : isDeviceDown
                          ? "12vw"
                          : "10vw",
                        fontSize: isMobile
                          ? "0.6vw"
                          : isDeviceDown
                          ? "0.8vw"
                          : "1vw",
                        color: "#000000",
                        transition: "0.3s all",
                        boxShadow: 0,
                        fontFamily: "Poppins-Regular",
                        border: "2px solid #ffffff",
                        fontWeight: 600,
                        "&:hover": {
                          backgroundColor: "#005e82",
                          color: "#ffffff",
                          boxShadow: 0,
                          border: "2px solid #ffffff",
                        },
                      }}
                      onClick={() =>
                        navigate(
                          ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS
                        )
                      }
                    >
                      Contact Us
                    </Button>
                  </Box> */}
                  {/* <Box>
                    <PartnerSvg color="#ed1970" height={"50%"} width="50%" />
                  </Box> */}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: isMobile ? "100%" : "65%",
              padding: theme.spacing(3),
              overflowY: "auto",
              borderTopLeftRadius: isMobile ? "0 " : "10% 50%",
              borderBottomLeftRadius: isMobile ? "0" : "10% 50%",
              backgroundColor: "#ffffff",
              border: "0.20rem double #005e82",
            }}
            className="form-box"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                my: 1,
                // height: "100vh",
              }}
            >
              <Box display="flex" justifyContent={"center"}>
                <VVlogo
                  style={{
                    width: isDevice || isDeviceDown ? "40%" : "20wv",
                    height: "30%",
                  }}
                />
              </Box>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
              >
                {(formikProps) => {
                  const disableCondition =
                    formikProps.isValid &&
                    formikProps.dirty &&
                    isSubmitting === false
                      ? false
                      : true;
                  const handleEmailChange = async (value, validateField) => {
                    const emailValidate =
                      validationSchema.fields.email_id.isValid(value);

                    if (emailValidate) {
                      try {
                        const response = await axiosData.post(`users/email`, {
                          email_id: value,
                        });
                        setGetStatusCode(response.status);
                      } catch (error) {
                        console.error("error", error);
                      }
                    }
                  };
                  return (
                    <>
                      <Box sx={{ mt: 3, width: "100%" }}>
                        <fieldset
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              Training Partner Details
                            </Typography>{" "}
                          </legend>
                          <Container fixed>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              my={"20px"}
                              alignItems={"center"}
                              gap={"20px"}
                            >
                              <Box width="100%" textAlign={"center"}>
                                {/* <Typography variant="h5">Registration</Typography> */}
                              </Box>

                              <FormControl fullWidth>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.business_name === true &&
                                      (formikProps.errors.business_name ===
                                        undefined ||
                                        formikProps.touched.business_name ===
                                          undefined)
                                        ? "#005e82"
                                        : formikProps.errors.business_name !==
                                            undefined &&
                                          formikProps.touched.business_name ===
                                            true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Business Name
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        industry: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: true,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        industry: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter Your Business Name"}
                                  id="Name"
                                  // label={t("Full_Name")}
                                  name="business_name"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.business_name === true &&
                                          (formikProps.errors.business_name ===
                                            undefined ||
                                            formikProps.touched
                                              .business_name === undefined)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^a-z0-9'. ]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "business_name",
                                      shopValue
                                    );
                                  }}
                                  value={formikProps.values.business_name}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    style: { color: "black" },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <BusinessRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.business_name ===
                                                true &&
                                              (formikProps.errors
                                                .business_name === undefined ||
                                                formikProps.touched
                                                  .business_name === undefined)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .business_name !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .business_name === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.business_name &&
                                    Boolean(formikProps.errors.business_name)
                                  }
                                  helperText={
                                    formikProps.touched.business_name &&
                                    formikProps.errors.business_name
                                  }
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.district_name === true &&
                                      (formikProps.errors.district_name ===
                                        undefined ||
                                        formikProps.touched.district_name ===
                                          undefined)
                                        ? "#005e82"
                                        : formikProps.errors.district_name !==
                                            undefined &&
                                          formikProps.touched.district_name ===
                                            true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  District Name
                                </FormLabel>
                                <Autocomplete
                                  disableClearable={
                                    formikProps.values.district_name
                                      ? false
                                      : true
                                  }
                                  filterOptions={filterOptions}
                                  id="district_name"
                                  name="district_name"
                                  getOptionLabel={(option) => option}
                                  options={districtData}
                                  onBlur={formikProps.handleBlur}
                                  onChange={(event, value) => {
                                    searchobj(value);
                                    formikProps.setFieldValue("pincode", "");
                                    formikProps.setFieldValue(
                                      "district_name",
                                      value
                                    );
                                  }}
                                  value={formikProps.values.district_name}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      id="district_name"
                                      // label={t("Preffered_Industry")}
                                      name="district_name"
                                      onChange={(e) =>{
                                        if(e.target.value.length >=3){
                                          votingDistrict(e.target.value);
                                        }
                                        }}
                                      // onClick={() => params.inputProps.onInputClick()}
                                      placeholder={"Select District Name"}
                                      error={
                                        formikProps.touched.district_name &&
                                        Boolean(
                                          formikProps.errors.district_name
                                        )
                                      }
                                      helperText={
                                        formikProps.touched.district_name &&
                                        formikProps.errors.district_name
                                      }
                                      onFocus={() =>
                                        setIsFocused({
                                          ...isFocused,
                                          trainingPartner_name: false,
                                          email_id: false,
                                          mobileNumber: false,
                                          district_name: true,
                                          investment_range: false,
                                          pincode: false,
                                          yearsOfExperience: false,
                                          business_name: false,
                                          students_trained: false,
                                          placed_students: false,
                                          Training_skills: false,
                                          designation: false,
                                        })
                                      }
                                      onBlur={() =>
                                        setIsFocused({
                                          ...isFocused,
                                          trainingPartner_name: false,
                                          email_id: false,
                                          mobileNumber: false,
                                          district_name: false,
                                          industry: false,
                                          investment_range: false,
                                          pincode: false,
                                          yearsOfExperience: false,
                                          business_name: false,
                                          students_trained: false,
                                          placed_students: false,
                                          Training_skills: false,
                                          designation: false,
                                        })
                                      }
                                    />
                                  )}
                                  onOpen={(event) => event.preventDefault()}
                                  PopperComponent={({
                                    children,
                                    ...popperProps
                                  }) => (
                                    <Popper
                                      {...popperProps}
                                      placement="bottom-start"
                                      modifiers={{
                                        flip: {
                                          enabled: false,
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          boundariesElement: "scrollParent",
                                        },
                                      }}
                                      PopperProps={{
                                        placement: "bottom-start", // Placement set to "bottom-start"
                                      }}
                                    >
                                      <Paper>{children}</Paper>
                                    </Popper>
                                  )}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "180px",
                                      overflowY: "auto",
                                    },
                                  }}
                                />
                              </FormControl>
                              <Tooltip
                                title={
                                  formikProps.values.district_name
                                    ? ""
                                    : "please select District Name"
                                }
                              >
                                <FormControl fullWidth>
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.pincode === true &&
                                        (formikProps.errors.pincode ===
                                          undefined ||
                                          formikProps.touched.pincode ===
                                            undefined)
                                          ? "#005e82"
                                          : formikProps.errors.pincode !==
                                              undefined &&
                                            formikProps.touched.pincode === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Pincode
                                  </FormLabel>
                                  <Autocomplete
                                    disableClearable={
                                      formikProps.values.pincode ? false : true
                                    }
                                    disabled={
                                      formikProps.values.district_name &&
                                      !pinLoad
                                        ? false
                                        : true
                                    }
                                    filterOptions={filterOptions}
                                    id="pincode"
                                    name="pincode"
                                    getOptionLabel={(option) => option}
                                    options={comdata}
                                    onBlur={formikProps.handleBlur}
                                    onChange={(event, value) => {
                                      formikProps.setFieldValue(
                                        "pincode",
                                        value
                                      );
                                    }}
                                    value={formikProps.values.pincode}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        id="pincode"
                                        // label={t("Preffered_Industry")}
                                        name="pincode"
                                        // onClick={() => params.inputProps.onInputClick()}
                                        placeholder={"Select Pincode"}
                                        error={
                                          formikProps.touched.pincode &&
                                          Boolean(formikProps.errors.pincode)
                                        }
                                        helperText={
                                          formikProps.touched.pincode &&
                                          formikProps.errors.pincode
                                        }
                                        onFocus={() =>
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            investment_range: false,
                                            pincode: true,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: false,
                                            designation: false,
                                          })
                                        }
                                        onBlur={() =>
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            industry: false,
                                            investment_range: false,
                                            pincode: false,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: false,
                                            designation: false,
                                          })
                                        }
                                        InputProps={{
                                          ...params.InputProps,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {pinLoad ? (
                                                <CircularProgress
                                                  // size={24}
                                                  style={{
                                                    width: "1.4rem",
                                                    height: "1.4rem",
                                                    color: "#4991dd",
                                                  }}
                                                />
                                              ) : (
                                                <ShareLocationRoundedIcon
                                                  sx={{
                                                    color: formikProps.values
                                                      .district_name
                                                      ? "#414141"
                                                      : "#bdbdbd",
                                                  }}
                                                />
                                              )}{" "}
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    onOpen={(event) => event.preventDefault()}
                                    PopperComponent={({
                                      children,
                                      ...popperProps
                                    }) => (
                                      <Popper
                                        {...popperProps}
                                        placement="bottom-start"
                                        modifiers={{
                                          flip: {
                                            enabled: false,
                                          },
                                          preventOverflow: {
                                            enabled: true,
                                            boundariesElement: "scrollParent",
                                          },
                                        }}
                                        PopperProps={{
                                          placement: "bottom-start", // Placement set to "bottom-start"
                                        }}
                                      >
                                        <Paper>{children}</Paper>
                                      </Popper>
                                    )}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: "180px",
                                        overflowY: "auto",
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Tooltip>

                              {industryArray && (
                                <FormControl fullWidth>
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.industry === true &&
                                        (formikProps.errors.industry ===
                                          undefined ||
                                          formikProps.touched.industry ===
                                            undefined)
                                          ? "#005e82"
                                          : formikProps.errors.industry !==
                                              undefined &&
                                            formikProps.touched.industry ===
                                              true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Industry
                                  </FormLabel>
                                  <Autocomplete
                                    disableClearable={
                                      formikProps.values.industry ? false : true
                                    }
                                    filterOptions={filterOptions}
                                    id="industry"
                                    name="industry"
                                    getOptionLabel={(option) => option}
                                    options={industryArray}
                                    value={formikProps.values.industry}
                                    onBlur={formikProps.handleBlur}
                                    onChange={(event, value) => {
                                      // setSelectedIndustry(value);
                                      formikProps.setFieldValue(
                                        "industry",
                                        value
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        id="industry"
                                        // label={t("Preffered_Industry")}
                                        name="industry"
                                        // onClick={() =>
                                        //   params.inputProps.onInputClick()
                                        // }
                                        placeholder={"Select Industry"}
                                        error={
                                          formikProps.touched.industry &&
                                          Boolean(formikProps.errors.industry)
                                        }
                                        helperText={
                                          formikProps.touched.industry &&
                                          formikProps.errors.industry
                                        }
                                        onFocus={() =>
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            industry: true,
                                            investment_range: false,
                                            pincode: false,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: false,
                                            designation: false,
                                          })
                                        }
                                        onBlur={() =>
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            industry: false,
                                            investment_range: false,
                                            pincode: false,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: false,
                                            designation: false,
                                          })
                                        }
                                      />
                                    )}
                                    onOpen={(event) => event.preventDefault()}
                                    PopperComponent={({
                                      children,
                                      ...popperProps
                                    }) => (
                                      <Popper
                                        {...popperProps}
                                        placement="bottom-start"
                                        modifiers={{
                                          flip: {
                                            enabled: false,
                                          },
                                          preventOverflow: {
                                            enabled: true,
                                            boundariesElement: "scrollParent",
                                          },
                                        }}
                                        PopperProps={{
                                          placement: "bottom-start", // Placement set to "bottom-start"
                                        }}
                                      >
                                        <Paper>{children}</Paper>
                                      </Popper>
                                    )}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: "180px",
                                        overflowY: "auto",
                                      },
                                    }}
                                  />
                                </FormControl>
                              )}

                              <FormControl
                                fullWidth
                                //   disabled={formikProps.values.pincode ? false : true}
                              >
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.yearsOfExperience === true &&
                                      (formikProps.errors.yearsOfExperience ===
                                        undefined ||
                                        formikProps.touched
                                          .yearsOfExperience === undefined)
                                        ? "#005e82"
                                        : formikProps.errors
                                            .yearsOfExperience !== undefined &&
                                          formikProps.touched
                                            .yearsOfExperience === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Years of experience
                                </FormLabel>
                                <Select
                                  fullWidth
                                  name="yearsOfExperience"
                                  onFocus={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      trainingPartner_name: false,
                                      email_id: false,
                                      mobileNumber: false,
                                      district_name: false,
                                      investment_range: false,
                                      pincode: false,
                                      yearsOfExperience: true,
                                      business_name: false,
                                      students_trained: false,
                                      placed_students: false,
                                      Training_skills: false,
                                      designation: false,
                                    })
                                  }
                                  labelId="demo-simple-select-label"
                                  id="yearsOfExperience"
                                  required
                                  // placeholder={"Select Investment range"}
                                  sx={{
                                    margin: "0",
                                    "& .MuiSelect-Outlined-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.yearsOfExperience ===
                                            true &&
                                          (formikProps.errors
                                            .yearsOfExperience === undefined ||
                                            formikProps.touched
                                              .yearsOfExperience === undefined)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={(e) => {
                                    setIsFocused({
                                      ...isFocused,
                                      trainingPartner_name: false,
                                      email_id: false,
                                      mobileNumber: false,
                                      district_name: false,
                                      investment_range: false,
                                      pincode: false,
                                      yearsOfExperience: false,
                                      business_name: false,
                                      students_trained: false,
                                      placed_students: false,
                                      Training_skills: false,
                                      designation: false,
                                    });
                                    formikProps.handleBlur(e);
                                  }}
                                  onChange={formikProps.handleChange}
                                  value={formikProps.values.yearsOfExperience}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    style: { color: "black" },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <HomeWorkRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.yearsOfExperience ===
                                                true &&
                                              (formikProps.errors
                                                .yearsOfExperience ===
                                                undefined ||
                                                formikProps.touched
                                                  .yearsOfExperience ===
                                                  undefined)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .yearsOfExperience !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .yearsOfExperience === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.yearsOfExperience &&
                                    Boolean(
                                      formikProps.errors.yearsOfExperience
                                    )
                                  }
                                  helperText={
                                    formikProps.touched.yearsOfExperience &&
                                    formikProps.errors.yearsOfExperience
                                  }
                                  displayEmpty
                                  MenuProps={MenuProps}
                                >
                                  {" "}
                                  <MenuItem
                                    value=""
                                    disabled
                                    disableRipple
                                    disableTouchRipple
                                  >
                                    {"Select Your Years of experience"}
                                  </MenuItem>
                                  {yearsOfExperience &&
                                    yearsOfExperience.map((element, index) => {
                                      return (
                                        <MenuItem key={index} value={element}>
                                          {element}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>{" "}
                                {formikProps.touched.yearsOfExperience &&
                                  formikProps.errors.yearsOfExperience && (
                                    <FormHelperText sx={{ color: "#de6363" }}>
                                      {formikProps.errors.yearsOfExperience}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                              <FormControl fullWidth>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.students_trained === true &&
                                      (formikProps.errors.students_trained ===
                                        undefined ||
                                        formikProps.touched.students_trained ===
                                          undefined ||
                                        captureBlur.students_trained === false)
                                        ? "#005e82"
                                        : formikProps.errors
                                            .students_trained !== undefined &&
                                          formikProps.touched
                                            .students_trained === true &&
                                          captureBlur.students_trained === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  No. of Students Trained
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: true,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter No. of Students Trained"}
                                  id="students_trained"
                                  // label={t("Full_Name")}
                                  name="students_trained"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.students_trained === true &&
                                          (formikProps.errors
                                            .students_trained === undefined ||
                                            formikProps.touched
                                              .students_trained === undefined ||
                                            captureBlur.students_trained ===
                                              false)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onBlurCapture={() =>
                                    setCaptureBlur({
                                      ...captureBlur,
                                      students_trained: true,
                                    })
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^0-9'. ]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "students_trained",
                                      shopValue
                                    );
                                  }}
                                  value={formikProps.values.students_trained}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  error={
                                    formikProps.touched.students_trained &&
                                    Boolean(
                                      formikProps.errors.students_trained
                                    ) &&
                                    captureBlur.students_trained
                                  }
                                  helperText={
                                    formikProps.touched.students_trained &&
                                    captureBlur.students_trained &&
                                    formikProps.errors.students_trained
                                  }
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.placed_students === true &&
                                      (formikProps.errors.placed_students ===
                                        undefined ||
                                        formikProps.touched.placed_students ===
                                          undefined ||
                                        captureBlur.placed_students === false)
                                        ? "#005e82"
                                        : formikProps.errors.placed_students !==
                                            undefined &&
                                          formikProps.touched
                                            .placed_students === true &&
                                          captureBlur.placed_students === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  No. of Students Placed
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: true,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter No. of Students Placed"}
                                  id="placed_students"
                                  // label={t("Full_Name")}
                                  name="placed_students"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.placed_students === true &&
                                          (formikProps.errors
                                            .placed_students === undefined ||
                                            formikProps.touched
                                              .placed_students === undefined ||
                                            captureBlur.placed_students ===
                                              false)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onBlurCapture={() =>
                                    setCaptureBlur({
                                      ...captureBlur,
                                      placed_students: true,
                                    })
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^0-9'. ]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "placed_students",
                                      shopValue
                                    );
                                  }}
                                  value={formikProps.values.placed_students}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  error={
                                    formikProps.touched.placed_students &&
                                    Boolean(
                                      formikProps.errors.placed_students
                                    ) &&
                                    captureBlur.placed_students
                                  }
                                  helperText={
                                    formikProps.touched.placed_students &&
                                    captureBlur.placed_students &&
                                    formikProps.errors.placed_students
                                  }
                                />
                              </FormControl>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Box>
                                    {trainingSkillChip.map((element, index) => {
                                      if (element !== "") {
                                        return (
                                          <Chip
                                            key={index}
                                            label={element}
                                            onDelete={() => {
                                              trainingSkillChip.splice(
                                                index,
                                                1
                                              );
                                              setTrainingSkillChip([
                                                ...trainingSkillChip,
                                              ]);
                                              const temp =
                                                trainingSkillChip.toString();
                                              formikProps.setFieldValue(
                                                "Training_skills",
                                                temp
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    })}
                                  </Box>
                                </Grid>
                                <Grid item xs={6} md={10}>
                                  <FormControl fullWidth>
                                    <FormLabel
                                      sx={{ fontWeight: "bold" }}
                                      style={{
                                        color:
                                          isFocused.Training_skills &&
                                          trainingSkillChip?.length === 0
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    >
                                      Training Skill set
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      id="Training_skills"
                                      placeholder="Enter Training Skill set"
                                      name="Training_skills"
                                      value={trainingSkill}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        const regex = /^[A-Za-z#+/.0-9 ]*$/;
                                        if (regex.test(value)) {
                                          setTrainingSkill(value);
                                        }
                                      }}
                                      inputProps={{
                                        onFocus: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            investment_range: false,
                                            pincode: false,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: false,
                                            designation: false,
                                            industry: false,
                                          });
                                        },
                                        onBlur: () => {
                                          setIsFocused({
                                            ...isFocused,
                                            trainingPartner_name: false,
                                            email_id: false,
                                            mobileNumber: false,
                                            district_name: false,
                                            investment_range: false,
                                            pincode: false,
                                            yearsOfExperience: false,
                                            business_name: false,
                                            students_trained: false,
                                            placed_students: false,
                                            Training_skills: trainingSkill
                                              ? false
                                              : true,
                                            designation: false,
                                            industry: false,
                                          });
                                        },
                                      }}
                                      error={
                                        isFocused.Training_skills &&
                                        trainingSkillChip?.length === 0
                                      }
                                      helperText={
                                        isFocused.Training_skills &&
                                        trainingSkillChip?.length === 0
                                          ? "Add your Training Skills"
                                          : ""
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} md={2} sx={{ marginTop: 4 }}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!trainingSkill}
                                    onClick={() => {
                                      const lowercaseSkill =
                                        trainingSkill.toLowerCase();
                                      const lowercaseChip =
                                        trainingSkillChip.map((item) =>
                                          item.toLowerCase()
                                        );

                                      if (
                                        lowercaseChip.includes(lowercaseSkill)
                                      ) {
                                        setTrainingSkill("");
                                        return;
                                      }

                                      trainingSkillChip.push(trainingSkill);
                                      setTrainingSkillChip([
                                        ...trainingSkillChip,
                                      ]);

                                      const uniqueArray = Array.from(
                                        new Set(
                                          trainingSkillChip.map((item) => item)
                                        )
                                      );
                                      setTrainingSkillChip(uniqueArray);

                                      const temp = uniqueArray.join(", ");
                                      if (temp) {
                                        formikProps.setFieldValue(
                                          "Training_skills",
                                          temp
                                        );
                                      }

                                      setTrainingSkill("");
                                    }}
                                  >
                                    ADD
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Container>
                        </fieldset>

                        <fieldset style={{ margin: "20px 30px 0px 30px" }}>
                          <legend>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1.13rem",
                                color: "#4d4d4d",
                              }}
                            >
                              Contact Details
                            </Typography>{" "}
                          </legend>
                          <Container fixed>
                            {" "}
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              my={"20px"}
                              alignItems={"center"}
                              gap={"20px"}
                            >
                              <Box width="100%" textAlign={"center"}>
                                {/* <Typography variant="h5">Registration</Typography> */}
                              </Box>

                              <FormControl sx={{ width: "100%" }}>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.trainingPartner_name === true &&
                                      (formikProps.errors
                                        .trainingPartner_name === undefined ||
                                        formikProps.touched
                                          .trainingPartner_name === undefined)
                                        ? "#005e82"
                                        : formikProps.errors
                                            .trainingPartner_name !==
                                            undefined &&
                                          formikProps.touched
                                            .trainingPartner_name === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Full Name
                                </FormLabel>
                                <TextField
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: true,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter Your Full Name"}
                                  id="Name"
                                  // label={t("Full_Name")}
                                  name="trainingPartner_name"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.trainingPartner_name ===
                                            true &&
                                          (formikProps.errors
                                            .trainingPartner_name ===
                                            undefined ||
                                            formikProps.touched
                                              .trainingPartner_name ===
                                              undefined)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^a-z'. ]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "trainingPartner_name",
                                      shopValue
                                    );
                                  }}
                                  value={
                                    formikProps.values.trainingPartner_name
                                  }
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    style: { color: "black" },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <ContactPageRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.trainingPartner_name ===
                                                true &&
                                              (formikProps.errors
                                                .trainingPartner_name ===
                                                undefined ||
                                                formikProps.touched
                                                  .trainingPartner_name ===
                                                  undefined)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .trainingPartner_name !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .trainingPartner_name ===
                                                    true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.trainingPartner_name &&
                                    Boolean(
                                      formikProps.errors.trainingPartner_name
                                    )
                                  }
                                  helperText={
                                    formikProps.touched.trainingPartner_name &&
                                    formikProps.errors.trainingPartner_name
                                  }
                                />
                              </FormControl>
                              <FormControl sx={{ width: "100%" }}>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.designation === true &&
                                      (formikProps.errors.designation ===
                                        undefined ||
                                        formikProps.touched.designation ===
                                          undefined)
                                        ? "#005e82"
                                        : formikProps.errors.designation !==
                                            undefined &&
                                          formikProps.touched.designation ===
                                            true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Designation
                                </FormLabel>
                                <TextField
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        industry: false,
                                        designation: true,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        industry: false,
                                        designation: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter Designation"}
                                  id="designation"
                                  // label={t("Full_Name")}
                                  name="designation"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.designation === true &&
                                          (formikProps.errors.designation ===
                                            undefined ||
                                            formikProps.touched.designation ===
                                              undefined)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^a-z'. ]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "designation",
                                      shopValue
                                    );
                                  }}
                                  value={formikProps.values.designation}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  error={
                                    formikProps.touched.designation &&
                                    Boolean(formikProps.errors.designation)
                                  }
                                  helperText={
                                    formikProps.touched.designation &&
                                    formikProps.errors.designation
                                  }
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.email_id === true &&
                                      (formikProps.errors.email_id ===
                                        undefined ||
                                        formikProps.touched.email_id ===
                                          undefined ||
                                        captureBlur.email_id === false)
                                        ? "#005e82"
                                        : formikProps.errors.email_id !==
                                            undefined &&
                                          formikProps.touched.email_id ===
                                            true &&
                                          captureBlur.email_id === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  {"Email"}
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: true,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                  }}
                                  fullWidth
                                  required
                                  placeholder={"Enter Your Email"}
                                  id="Email"
                                  // label={t("Full_Name")}
                                  name="email_id"
                                  sx={{
                                    margin: "0",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.email_id === true &&
                                          (formikProps.errors.email_id ===
                                            undefined ||
                                            formikProps.touched.email_id ===
                                              undefined ||
                                            captureBlur.email_id === false)
                                            ? "#005e82"
                                            : "#de6363",
                                      },
                                    },
                                  }}
                                  onBlur={formikProps.handleBlur}
                                  onBlurCapture={() =>
                                    setCaptureBlur({
                                      ...captureBlur,
                                      email_id: true,
                                    })
                                  }
                                  onChange={(e) => {
                                    setCaptureBlur({
                                      ...captureBlur,
                                      email_id: false,
                                    });
                                    const { value } = e.target;
                                    const shopValue = value.replace(
                                      /[^a-z0-9@.]/gi,
                                      ""
                                    );
                                    formikProps.setFieldValue(
                                      "email_id",
                                      shopValue
                                    );

                                    handleEmailChange(
                                      shopValue,
                                      formikProps.validateField
                                    );
                                  }}
                                  value={formikProps.values.email_id}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    style: { color: "black" },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <EmailRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.email_id === true &&
                                              (formikProps.errors.email_id ===
                                                undefined ||
                                                formikProps.touched.email_id ===
                                                  undefined ||
                                                captureBlur.email_id === false)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .email_id !== undefined &&
                                                  formikProps.touched
                                                    .email_id === true &&
                                                  captureBlur.email_id === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.email_id &&
                                    Boolean(formikProps.errors.email_id) &&
                                    captureBlur.email_id
                                  }
                                  helperText={
                                    formikProps.touched.email_id &&
                                    captureBlur.email_id &&
                                    formikProps.errors.email_id
                                  }
                                />
                              </FormControl>
                              <FormControl
                                className="ContactDetailsFormControl"
                                fullWidth
                              >
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.mobileNumber === true &&
                                      (formikProps.errors.mobileNumber ===
                                        undefined ||
                                        formikProps.touched.mobileNumber ===
                                          undefined ||
                                        captureBlur.mobileNumber === false)
                                        ? "#005e82"
                                        : formikProps.errors.mobileNumber !==
                                            undefined &&
                                          formikProps.touched.mobileNumber ===
                                            true &&
                                          captureBlur.mobileNumber === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Mobile Number
                                </FormLabel>
                                <TextField
                                  // margin="normal"
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: true,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        trainingPartner_name: false,
                                        email_id: false,
                                        mobileNumber: false,
                                        district_name: false,
                                        investment_range: false,
                                        pincode: false,
                                        yearsOfExperience: false,
                                        business_name: false,
                                        students_trained: false,
                                        placed_students: false,
                                        Training_skills: false,
                                        designation: false,
                                        industry: false,
                                      });
                                    },
                                  }}
                                  required
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          isFocused.mobileNumber === true &&
                                          (formikProps.errors.mobileNumber ===
                                            undefined ||
                                            formikProps.touched.mobileNumber ===
                                              undefined ||
                                            captureBlur.mobileNumber === false)
                                            ? "#005e82"
                                            : formikProps.errors
                                                .mobileNumber !== undefined &&
                                              formikProps.touched
                                                .mobileNumber === true &&
                                              captureBlur.mobileNumber === true
                                            ? "#de6363"
                                            : "#666666",
                                      },
                                    },
                                  }}
                                  placeholder={"Enter Your Mobile Number"}
                                  name="mobileNumber"
                                  // label={t("Mobile_Number")}
                                  type="number"
                                  onWheel={(e) => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  onKeyDown={(e) =>
                                    exceptThisSymbols.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  id="mobile_number"
                                  onBlur={formikProps.handleBlur}
                                  onBlurCapture={() =>
                                    setCaptureBlur({
                                      ...captureBlur,
                                      mobileNumber: true,
                                    })
                                  }
                                  onChange={(e) => {
                                    setCaptureBlur({
                                      ...captureBlur,
                                      mobileNumber: false,
                                    });
                                    // setIsFocused({
                                    //   ...isFocused,
                                    //   mobileNumber: false,
                                    // });
                                    const { value } = e.target;
                                    if (value.length <= 10) {
                                      formikProps.handleChange(e);
                                    }
                                  }}
                                  value={formikProps.values.mobileNumber}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    maxLength: 10,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <b
                                          style={{
                                            color:
                                              isFocused.mobileNumber === true &&
                                              (formikProps.errors
                                                .mobileNumber === undefined ||
                                                formikProps.touched
                                                  .mobileNumber === undefined ||
                                                captureBlur.mobileNumber ===
                                                  false)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .mobileNumber !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .mobileNumber === true &&
                                                  captureBlur.mobileNumber ===
                                                    true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        >
                                          +91
                                        </b>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.mobileNumber &&
                                    Boolean(formikProps.errors.mobileNumber) &&
                                    captureBlur.mobileNumber === true
                                  }
                                  helperText={
                                    formikProps.touched.mobileNumber &&
                                    captureBlur.mobileNumber === true &&
                                    formikProps.errors.mobileNumber
                                  }
                                />
                              </FormControl>

                              <FormControl
                                fullWidth
                                //   disabled={formikProps.values.pincode ? false : true}
                              >
                                <FormLabel
                                  sx={{ fontWeight: "bold" }}
                                  style={{
                                    color:
                                      isFocused.investment_range === true &&
                                      (formikProps.errors.investment_range ===
                                        undefined ||
                                        formikProps.touched.investment_range ===
                                          undefined)
                                        ? "#005e82"
                                        : formikProps.errors
                                            .investment_range !== undefined &&
                                          formikProps.touched
                                            .investment_range === true
                                        ? "#de6363"
                                        : "#666666",
                                  }}
                                >
                                  Investment range (Lakhs)
                                </FormLabel>
                                <Select
                                  fullWidth
                                  name="investment_range"
                                  onFocus={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      trainingPartner_name: false,
                                      email_id: false,
                                      mobileNumber: false,
                                      district_name: false,
                                      investment_range: true,
                                      pincode: false,
                                      yearsOfExperience: false,
                                      business_name: false,
                                      students_trained: false,
                                      placed_students: false,
                                      Training_skills: false,
                                      designation: false,
                                      industry: false,
                                    })
                                  }
                                  labelId="demo-simple-select-label"
                                  id="investment_range"
                                  required
                                  placeholder={"Select Investment range"}
                                  sx={{ margin: "0" }}
                                  onBlur={(e) => {
                                    setIsFocused({
                                      ...isFocused,
                                      trainingPartner_name: false,
                                      email_id: false,
                                      mobileNumber: false,
                                      district_name: false,
                                      investment_range: false,
                                      pincode: false,
                                      yearsOfExperience: false,
                                      business_name: false,
                                      students_trained: false,
                                      placed_students: false,
                                      Training_skills: false,
                                      designation: false,
                                      industry: false,
                                    });
                                    formikProps.handleBlur(e);
                                  }}
                                  onChange={formikProps.handleChange}
                                  value={formikProps.values.investment_range}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    style: { color: "black" },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <HomeWorkRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.investment_range ===
                                                true &&
                                              (formikProps.errors
                                                .investment_range ===
                                                undefined ||
                                                formikProps.touched
                                                  .investment_range ===
                                                  undefined)
                                                ? "#005e82"
                                                : formikProps.errors
                                                    .investment_range !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .investment_range === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    formikProps.touched.investment_range &&
                                    Boolean(formikProps.errors.investment_range)
                                  }
                                  helperText={
                                    formikProps.touched.investment_range &&
                                    formikProps.errors.investment_range
                                  }
                                  displayEmpty
                                  MenuProps={MenuProps}
                                >
                                  {" "}
                                  <MenuItem
                                    value=""
                                    disabled
                                    disableRipple
                                    disableTouchRipple
                                    sx={{ color: "#9f9f9f" }}
                                  >
                                    {"Select Investment range (Lakhs)"}
                                  </MenuItem>
                                  {investmentRange &&
                                    investmentRange.map((element, index) => {
                                      return (
                                        <MenuItem key={index} value={element}>
                                          {element}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {formikProps.touched.investment_range &&
                                  formikProps.errors.investment_range && (
                                    <FormHelperText sx={{ color: "#de6363" }}>
                                      {formikProps.errors.investment_range}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Box>
                          </Container>
                        </fieldset>
                        <Tooltip
                          arrow
                          title={
                            disableCondition
                              ? "Please fill out all the fields"
                              : ""
                          }
                          sx={{ width: "100%" }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              marginX: 4,
                            }}
                          >
                            <Button
                              type="submit"
                              disabled={disableCondition}
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 3,
                                mb: 2,
                                textTransform: "capitalize",
                                backgroundColor: "#005e82",
                                transition: "0.3s all",
                                "&:hover": { backgroundColor: "#005e82" },
                              }}
                              onClick={formikProps.handleSubmit}
                            >
                              Submit
                              {isSubmitting && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#ffffff",

                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
    // </ThemeProvider>
  );
}
