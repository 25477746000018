/* eslint-disable eqeqeq */
import React from "react";
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import useAxios from "../../../../api/useAxios";
import { ReactComponent as Warning } from "../../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useFormik } from "formik";
import * as Yup from "yup";
function ApproveDialog({
  approveOpen,
  setApproveOpen,
  updateApprove,
  filterName,
  getPutId,
  getEmail,
  getPass,
  action,
  userEmail,
  fetchFamilyApi,
  openState,
  setOpenState,
  mlaEmail,
  MLA,
  email,
  isLoading,
  updateAdminVerify,
}) {
  const axiosData = useAxios();
  const location = useLocation();
  const currentUrl = location.pathname;
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));

  const volunteerValue = `Volunteer_${verifierVolunteer}`;
  const adminRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.ADMINPANEL +
    "/" +
    ROUTINGDATA.ADMINSeekerFullFamilyDetails;
  const MLAroute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLAneedSupport;
  const MProute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAneedSupport;
  const volunteerRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    "/" +
    ROUTINGDATA.volunteerNeedsupport;
  const MPneedRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_NEEDSUPPORT;

  const MLAFullroute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLAfulDetails;
  const MPFullroute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAfullDetais;
  const volunteerFullRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    "/" +
    ROUTINGDATA.volunteerFullDetails;
  const shortListValueCondition =
    currentUrl == volunteerFullRoute || currentUrl == volunteerRoute
      ? volunteerValue
      : currentUrl == MLAFullroute || currentUrl == MLAroute
      ? "MLA"
      : currentUrl == MPFullroute ||
        currentUrl == MProute ||
        currentUrl == MPneedRoute
      ? "MP"
      : MLA;

  const updateFinalVerify = async (action) => {
    setOpenState({ ...openState, isLoading: true });
    try {
      await axiosData.put(`family/admin/${email}`, {
        finalVerification: action,
      });
      setApproveOpen(false);
      fetchFamilyApi();
      setOpenState({ ...openState, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    validationSchema: Yup.object().shape({
      notes: Yup.string().required("Notes is required"),
    }),

    onSubmit: async (values) => {
      formik.handleReset("");
    },
  });
  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "20px",
          },zIndex:"99999"
        }}
        open={approveOpen}
        fullWidth
      >
        <Box>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    Once you Submitted,You can't modify
                  </Typography>

                  {currentUrl === volunteerRoute ||
                  currentUrl === MLAroute ||
                  currentUrl === MProute ||
                  currentUrl == MPneedRoute ||
                  currentUrl === volunteerFullRoute ||
                  currentUrl === MLAFullroute ||
                  currentUrl === MPFullroute ? (
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        pt: 1.5,
                        borderRadius: 2,
                        pb: 1.5,
                        pr: 0.5,
                        pl: 1,
                        overflowX: "scroll",
                        maxWidth: "100%",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        scrollbarWidth: "none",
                      }}
                    >
                      <ReactQuill
                        className="scrollable-container-volunteer"
                        name="notes"
                        value={formik.values.notes}
                        onChange={(value) => {
                          if (value === "<p><br></p>") {
                            formik.setFieldValue("notes", "");
                            formik.handleReset();
                          } else {
                            formik.setFieldValue("notes", value);
                          }
                        }}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ["clean"],
                          ],
                        }}
                        placeholder="Write notes.."
                      />{" "}
                      {formik.touched.notes && formik.errors.notes ? (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.notes}
                        </div>
                      ) : null}
                    </Box>
                  ) : null}

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setApproveOpen(false);
                        formik.setFieldValue("notes", "");
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",

                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#f2f2f2",
                          backgroundColor: "#339fcd",
                          borderColor: "#f2f2f2",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      disabled={
                        currentUrl == adminRoute
                          ? isLoading
                          : currentUrl == MLAroute ||
                            currentUrl == MProute ||
                            currentUrl == volunteerRoute ||
                            currentUrl == MPneedRoute ||
                            currentUrl === volunteerFullRoute ||
                            currentUrl === MLAFullroute ||
                            currentUrl === MPFullroute
                          ? formik.isValid && formik.dirty
                            ? false
                            : isLoading
                            ? true
                            : true
                          : filterName.isLoading
                      }
                      sx={{
                        textTransform: "lowercase",

                        color: "white",
                        backgroundColor: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        "&:hover": {
                          textTransform: "lowercase",

                          color: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        currentUrl == adminRoute
                          ? updateFinalVerify(action.api)
                          : currentUrl == MLAroute ||
                            currentUrl == MProute ||
                            currentUrl == volunteerRoute ||
                            currentUrl == MPneedRoute ||
                            currentUrl === volunteerFullRoute ||
                            currentUrl === MLAFullroute ||
                            currentUrl === MPFullroute
                          ? updateAdminVerify(
                              mlaEmail,
                              shortListValueCondition,
                              formik.values.notes
                            )
                          : updateApprove(getPutId, getEmail, getPass);
                      }}
                    >
                      {currentUrl == adminRoute
                        ? action.button
                        : currentUrl == MLAroute ||
                          currentUrl == MProute ||
                          currentUrl == volunteerRoute ||
                          currentUrl == MPneedRoute ||
                          currentUrl === volunteerFullRoute ||
                          currentUrl === MLAFullroute ||
                          currentUrl === MPFullroute
                        ? "Shortlist"
                        : "Approve"}
                      {currentUrl == adminRoute ||
                      currentUrl == MLAroute ||
                      currentUrl == MProute ||
                      currentUrl == volunteerRoute ||
                      currentUrl == MPneedRoute ||
                      currentUrl === volunteerFullRoute ||
                      currentUrl === MLAFullroute ||
                      currentUrl === MPFullroute
                        ? isLoading && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )
                        : filterName.isLoading && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </form>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ApproveDialog;
