import React from "react";
import { Snackbar, Typography, Alert } from "@mui/material";
function ContactSupportSnack({
  openSupportSnack,
  setOpenSupportSnack,
  contactName,
  seekName,
}) {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSupportSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSupportSnack(false)}
      >
        <Alert
          severity={"success"}
          variant="filled"
          onClose={() => setOpenSupportSnack(false)}
        >
          <Typography variant="body1">
            Contact Person "{<b>{contactName}</b>}" Details was Updated! . (
            Details Will sent to {<b>{seekName}</b>}'s seeker profile and inform
            to contact person "{<b>{contactName}</b>}" by email )
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactSupportSnack;
