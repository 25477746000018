import React, { useState, useEffect, useSyncExternalStore } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Divider,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Grid,
  Typography,
  Paper,
  Chip,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useAxios from "../../../../api/useAxios";

import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material/";

import { useNavigate } from "react-router-dom";
import defaultimg from "../../../../img/mentorImage.png";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import Breadcrumb from "../../../Dashboards/BreadCrumb";
import ApproveDialog from "../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";
import EnterContactSupportDialog from "../../../admin-panel/admin-components/DialogBoxes/EnterContactSupportDialog";
import ContactSupportSnack from "../../../admin-panel/admin-components/SnackBars/ContactSupportSnack";

function VolunteerShortList() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [familyApi, setFamilyApi] = useState([]);
  const [approveMail, setApproveMail] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [getOne, setGetOne] = useState({});
  const volunteerPageNumbe = sessionStorage.getItem(
    "volunteer_shortlist_page_number"
  );
  const volunteerPageNumber = parseInt(volunteerPageNumbe);
  const setvolunteerPageNumber = volunteerPageNumber ? volunteerPageNumber : 0;
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [verifierApi, setVerifierApi] = useState([]);
  const [shortlistedData, setShortListedData] = useState([]);
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));
  const [openFormikContactDialog, setOpenFormikContactDialog] = useState(false);
  const [contactSeekDetails, setContactSeekDetails] = useState({
    name: "",
    email_id: "",
    contactName: "",
    mobile: 0,
  });
  const [openSupportSnack, setOpenSupportSnack] = useState(false);
  const navigate = useNavigate();
  const openApproveDialog = () => {
    setApproveOpen(true);
  };
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const volunteerSupport = sessionStorage.getItem("volunter_supportradio");
  const VolunteerFilterValue = `Volunteer_${verifierVolunteer}`;

  // <!----GET--->

  const fetchVerifierData = async () => {
    try {
      const axiosVerifierData = await axiosData.get("verifierdetails");
      const { data } = axiosVerifierData;
      const value = [...data];
      value.unshift({ email_id: "All" });
      setVerifierApi(value);
    } catch (err) {
      console.log("Error", err);
    }
  };
  // <!-----API FUNCTIONS---->

  const fetchFamilyApi = async () => {
    try {
      const axiosFamilyData = await axiosData.get("family/needsupport");
      const { data } = axiosFamilyData;
      const reverseData = data.reverse();
      const shortlistedData = reverseData.filter((item) => {
        return item.shortList === VolunteerFilterValue;
      });

      setGetApi(shortlistedData);
      shortlistBy(volunteerSupport ? volunteerSupport : "all", shortlistedData);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // <!---APPROVE-->

  // <!----FILE-DOWNLOAD-->

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA === valueB) {
        return -1;
      }

      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };
  const shortlistBy = (shortvalue, total) => {
    if (shortvalue === "all") {
      const allData = [...total];
      setShortListedData(allData);
      setTotalRows(allData.length);
      setPage(setvolunteerPageNumber);
    } else if (shortvalue === "ByMe") {
      const fill = total.filter((d) => {
        return d.supported === `Volunteer_${verifierVolunteer}`;
      });
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setvolunteerPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.supported === "");
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setvolunteerPageNumber);
    }
  };

  const updateAdminVerify = async (email_id, decide) => {
    setIsLoadingPage(true);
    try {
      await axiosData.put(`family/admin/${email_id}`, { shortList: decide });
      fetchFamilyApi();
      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }

    setIsLoadingPage(false);
  };
  useEffect(() => {
    fetchFamilyApi();
    fetchVerifierData();
    setPage(setvolunteerPageNumber);
    handleSort(orderBy);
  }, []);

  return (
    <>
      <Divider textAlign="left">
        <span className="seeker-text">Shortlisted Data</span>
      </Divider>

      <Card sx={{ padding: "20px", mb: 8 }}>
        <Paper>
          <Box
            sx={{
              padding: "20px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Grid container>
              <Grid item md={8}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Filter By Support
                  </FormLabel>
                  <RadioGroup
                    row
                    id="radio-btn"
                    value={volunteerSupport ? volunteerSupport : "all"}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginRight: "18px" }}
                    onChange={(e) => {
                      sessionStorage.setItem(
                        "volunter_supportradio",
                        e.target.value
                      );

                      shortlistBy(e.target.value, getApi);
                    }}
                  >
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value="all" />}
                      label="All"
                    ></FormControlLabel>
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value="Pending" />}
                      label="Pending"
                    ></FormControlLabel>
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value={"ByMe"} />}
                      label="Completed "
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* <Grid item md={2}>
                    <Tooltip title="Export As Excel" placement="bottom">
                      <ExportCS
                        selectedApiForExcel={selectedApiForExcel}
                        fileName={"Seeker-NeedSupport-Data"}
                      />
                    </Tooltip>
                  </Grid> */}
            </Grid>
          </Box>
        </Paper>
        <TableContainer
          component={Paper}
          sx={{ marginTop: "10px", boxShadow: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow className="table-row">
                <TableCell>Profile</TableCell>
                <TableCell>Name</TableCell>

                <TableCell>Email</TableCell>
                <TableCell>Family Income</TableCell>
                <TableCell>MLA Constituency</TableCell>
                <TableCell>Supported Status</TableCell>
                <TableCell> Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shortlistedData.length > 0 ? (
                shortlistedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    const supportedValue =
                      item.supported === VolunteerFilterValue
                        ? "Supported"
                        : "Pending";
                    return (
                      <TableRow className="tablecell">
                        <TableCell>
                          <img
                            src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                            height={"50"}
                            width="50"
                            onError={(e) => {
                              e.target.src = defaultimg;
                            }}
                            style={{ clipPath: "circle(50%)" }}
                            alt="img"
                          />
                        </TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell>{item.email_id}</TableCell>
                        <TableCell>{item.FamilyIncome}</TableCell>
                        <TableCell>{item.mlaConstituency}</TableCell>
                        <TableCell>
                          <Chip
                            label={supportedValue}
                            variant="filled"
                            color={
                              supportedValue === "Pending" ? "warning" : "info"
                            }
                            sx={{
                              color: "#ffffff",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Full Details" placement="top">
                            <IconButton
                              className="icon-btn"
                              size="Medium"
                              onClick={() => {
                                navigate(
                                  `${ROUTINGDATA.LAND}${ROUTINGDATA.volunteerPanel}/${ROUTINGDATA.volunteerShortlisted_fullDetails}`,
                                  {
                                    state: item.email_id,
                                  }
                                );
                                sessionStorage.setItem(
                                  "volunteer_shortlist_page_number",
                                  page
                                );
                              }}
                            >
                              <OpenInNewRoundedIcon />
                            </IconButton>
                          </Tooltip>
                          {item.supported === "" ? (
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize ",
                                boxShadow: 0,
                                borderRadius: "16px",
                              }}
                              startIcon={<FavoriteRoundedIcon />}
                              onClick={() => {
                                setContactSeekDetails({
                                  ...contactSeekDetails,
                                  name: item.Name,
                                  email_id: item.email_id,
                                  mobile: item.mobile_number,
                                });
                                setOpenFormikContactDialog(true);
                              }}
                            >
                              Support
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Barlow-Bold" }}
                    >
                      NO Data Available
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <EnterContactSupportDialog
          openFormikContactDialog={openFormikContactDialog}
          setOpenFormikContactDialog={setOpenFormikContactDialog}
          contactSeekDetails={contactSeekDetails}
          setContactSeekDetails={setContactSeekDetails}
          getFunction={fetchFamilyApi}
          setOpenSupportSnack={setOpenSupportSnack}
        />
        <ContactSupportSnack
          contactName={contactSeekDetails.contactName}
          seekName={contactSeekDetails.name}
          openSupportSnack={openSupportSnack}
          setOpenSupportSnack={setOpenSupportSnack}
        />
      </Card>
    </>
  );
}

export default VolunteerShortList;
