import { Button, Box, Slider, Tooltip, CircularProgress } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLocation } from "react-router";
import Context from "./EmpContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import React from "react";
import useAxios from "../../../api/useAxios";
import "../EmployerUpdateProfile/EmployerUpdate.css";
import employerProfile from "../../../img/employerProfile/employerUpdate-logo.png";
import { FlashlightOffOutlined } from "@mui/icons-material";
import { Buffer } from "buffer";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileUpdate = ({ setput, number, setBuyJobDetailTooltip }) => {
  const axiosData = useAxios();

  const [image, setimage] = useState();
  const [open, setOpen] = useState(false);
  const [ref, setr] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Location = useLocation();
  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleSave = async (e, Context) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      if (croppedImg) {
        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
      }

      // Split the base64 string in data and contentType
      var block = croppedImg.split(";");
      // Get the content type
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."
      const img = b64toBlob(realData, contentType);

      const values = { logoUpload: "" };

      if (picture) {
        try {
          const response = await axiosData.delete(
            `employers/logo/${number}`,
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response) {
          }
        } catch (err) {
          console.log(err);
        }
        fileInputField.current.value = "";

        values.logoUpload = img;
        setIsSubmitting(true);
        try {
          const response = await axiosData.put(
            `employers/email/${Location.state}`,
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response) {
            setput(true);
            setimage(null);
            setOpen(false);
            setr(true);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  const fileInputField = useRef(null);

  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];

    handleClickOpen();
    setPicture({
      ...picture,
      img: imgFile,
      cropperOpen: true,
    });
  };
  const fun = async () => {
    try {
      const response = await axiosData.get(
        `employers/email/${Location.state}/users`
      );

      if (response.data) {
        try {
          const response1 = await axiosData.get(
            `employers/logo/email/${response.data.email_id}/users`,
            {
              responseType: "arraybuffer",
            }
          );

          const docBuffer = Buffer.from(response1.data, "binary");
          const docDataUrl = URL.createObjectURL(new Blob([docBuffer]));

          setimage(docDataUrl);
        } catch (error) {
          console.error(error);
        }
        // setimage(response.data.email_id);
        setr(false);
      }
      setput(true);
      setIsSubmitting(false);
      setBuyJobDetailTooltip(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setIsSubmitting(true);
    setimage(null);
    setTimeout(() => {
      if (Location.state !== undefined) {
        fun();
      } else {
        setr(FlashlightOffOutlined);
      }
    }, 6000);
    setr(false);
  }, [ref]);
  return (
    <Context.Consumer>
      {(Context) => {
        const id = Context;

        return (
          <div>
            <Box display="flex">
              <Box width="35%">
                <div style={{ position: "relative" }}>
                  <img
                    style={{ display: "block" }}
                    alt=""
                    id="employerProfile"
                    src={
                      image !== undefined
                        ? image
                          ? Context.logoUpload !== undefined &&
                            Context.logoUpload !== ""
                            ? image
                            : employerProfile
                          : employerProfile
                        : employerProfile
                    }
                    onError={(e) => {
                      e.target.src = employerProfile;
                    }}
                    className="emplogo"
                  />
                  {isSubmitting === true ? (
                    <CircularProgress
                      className="CircularProgress"
                      size={26}
                      style={{
                        color: "rgba(25,118,210,1)",
                        position: "absolute",
                      }}
                    />
                  ) : (
                    <Tooltip title="Upload Profile" placement="top" arrow>
                      <CameraAltIcon
                        className="logoIcon"
                        onClick={() => {
                          fileInputField.current.click();
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <input
                  ref={fileInputField}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Box>

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {picture.cropperOpen && (
                      <Box display="block">
                        <AvatarEditor
                          ref={setEditorRef}
                          image={picture.img}
                          width={200}
                          height={200}
                          border={50}
                          color={[255, 255, 255, 0.6]} // RGBA
                          rotate={0}
                          scale={picture.zoom}
                        />
                        <Slider
                          aria-label="raceSlider"
                          value={picture.zoom}
                          min={1}
                          max={10}
                          step={0.1}
                          onChange={handleSlider}
                        ></Slider>
                      </Box>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleCancel();
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSave(Context);
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </div>
        );
      }}
    </Context.Consumer>
  );
};

export default ProfileUpdate;
