import React, { useEffect } from "react";
import { useState, useRef } from "react";
import {
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Tooltip,
  Popper,
  CircularProgress,
  Button,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { Select, Chip } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import useAxios from "../../../../api/useAxios";
import "../../jobseeker/JobSeekerRegForm.css";

import {
  HomeWorkRounded as HomeWorkRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  ContactPageRounded as ContactPageRoundedIcon,
  VolunteerActivismRounded as VolunteerActivismRoundedIcon,
  EmailRounded as EmailRoundedIcon,
  ShoppingCartRounded as ShoppingCartRoundedIcon,
  FmdGoodRounded as FmdGoodRoundedIcon,
  EngineeringRounded as EngineeringRoundedIcon,
} from "@mui/icons-material";
import logo from "../../../../img/logo_for-landingpage-3.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate, Outlet } from "react-router";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let response = "";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function LocalServiceProviderForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  //  translation
  const { t, i18n, ready } = useTranslation();
  //
  const axiosData = useAxios();

  const [valid, setvalid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [focusb, setfocusb] = useState();
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [mentorExperience, setMentorExperience] = useState("");
  const [labour, setLabour] = useState("");
  const [captureBlur, setCaptureBlur] = useState({
    contact_number: false,
    landline_number: false,
    email_id: false,
  });

  const [pinLoad, setPinLoad] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    pincode: "",
  });
  const location = useLocation();
  const [isFocused, setIsFocused] = useState({
    pin: false,
    village: false,
    area: false,
    CPname: false,
    Cnumber: false,
    remark: false,
    service: false,
    email_id: false,
    service_offered_by: false,
    shop_name: false,
    shop_address: false,
    job_need_type: false,
    no_of_Labour: false,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleInputChange = (event, newValue) => {
    if (newValue.length < 7) {
      setSearchQuery(newValue);
    }
  };
  const handleSuggestionsPincode = async (event) => {
    const query = event.target.value;
    searchobj(event.target.value, "pincode");
    // if (query.length <= 6) {
    //   setSearchQuery(query);
    // }
    if (query.length === 6) {
      setPinLoad(true);
      try {
        const getOneAxios = await axiosData.get(
          `/General/district/pincode/${query}`
        );
        const data = getOneAxios.data;
        if (getOneAxios.data) {
          const pincodeArray = data.map((item) => {
            return item.pincode;
          });
          const uniquePincodes = new Set(pincodeArray);
          const uniquePincodesArray = [...uniquePincodes];
          if (pincodeArray === "") {
            setSuggestionArrayl([]);
          } else {
            setSuggestionArrayl(uniquePincodesArray);
          }
          const getPincode = data.map((i) => {
            return i.officeName;
          });
          getPincode.sort();
          setPincode(getPincode);
          setPinLoad(false);
        } else {
          setSuggestionArrayl([]);
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  };
  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);

    setSuggestionArrayl([]);
    setSuggestionArrayl([]);
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const MAX_FILE_SIZE = 1048576 * 10;
  const errors = {};
  const error = () => {
    errors.email_id = `email is exist `;
  };
  const [alertm, setalert] = useState(false);
  const [alerte, setalerte] = useState(false);
  useEffect(() => {
    if (alertm !== false) {
      setMobilePopUp(true);
    }
    setalert(false);
  }, [alertm]);
  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
    }
    setalerte(false);
  }, [alerte]);

  const fileInputField = useRef(null);
  const reader = new FileReader();
  const [selectedIndustry, setSelectedIndustry] = useState();
  var [mlaConstituency, setMLA] = useState([]);
  const [active, setActive] = useState("8");
  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const [serviceName, setServiceName] = useState([]);
  const [jobList, setJobList] = useState([]);
  const getJobList = async () => {
    try {
      const getOneAxios = await axiosData.get(`/localjobslists`);
      const data = getOneAxios.data;
      if (data) {
        const GetVillageName = data.map((i) => {
          return i.service_name;
        });
        setServiceName(GetVillageName);
        setJobList(data);

        // GetVillageName.sort();
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const lang = sessionStorage.getItem("lang");

  const initialValues = {
    pincode: "",
    village: "",
    area: "",
    serviceOffer: [],
    CPname: "",
    CNumber: "",
    starRating: null,
    remarks: "",
    email_id: "",
    service_offered_by: "Company",
    shop_name: "",
    shop_address: "",
    no_of_Labour: "",
  };
  const validationSchema = Yup.object().shape({
    pincode: Yup.number()
      .required(t("Pin_code_is_Required")),
    village: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required("Village/Town Name must be Required"),
    area: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Area/Street_Name_must_be_Required")),
    serviceOffer: Yup.array().min(1, "Please select at least one service."),
    CPname: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Contact_Person_Name_must_be_Required")),
    CNumber: Yup.number()
      .min(1000000000, t("Not_Valid_Mobile_Number!"))
      .max(9999999999, t("Not_Valid_Mobile_Number!"))
      .required(t("Mobile_Number_must_be_Required")),
    // starRating: Yup.number()
    // .min(1000000000, t("Not_Valid_Mobile_Number!"))
    // .max(9999999999, t("Not_Valid_Mobile_Number!"))
    // .required(t("Mobile_Number_must_be_Required")),
    remarks: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Remarks must be required"),
    email_id:
      labour === "Labour"
        ? Yup.string()
        : Yup.string()
            .email(t("Invalid_email_address"))
            .required(t("EmailId_must_be_Required"))
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              t("Invalid_email_address")
            ),
    service_offered_by:
      labour === "Labour"
        ? Yup.string().required(t("service_offered_by_must_be_required"))
        : Yup.string(),
    shop_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Shop_Name_must_be_Required")),
    shop_address: Yup.string()
      .max(200, t("Must_be_200_characters_or_less"))
      .required(t("Shop_address_must_be_Required")),
    no_of_Labour:
      labour === "Labour"
        ? Yup.string().required(t("Labour_must_be_Required"))
        : Yup.string(),
  });
  const handleFormikSubmit = async (value, { resetForm }) => {
    setIsSubmitting(true);
    const serviceOfferValue = value.serviceOffer.join(",");

    try {
      await axiosData.post("/localserviceprovider", {
        pincode: value.pincode,
        village_town_name: value.village,
        area_street_name: value.area,
        service_name: serviceOfferValue,
        contact_person_name: value.CPname,
        contact_number: value.CNumber,
        remarks: value.remarks,
        star_rating: value.starRating,
        email_id: value.email_id,
        password: value.CNumber,
        usertype: "serviceProvider",
        uniqueId: value.CNumber,
        service_offered_by: value.service_offered_by,
        shop_name: value.shop_name,
        shop_address: value.shop_address,
        no_of_Labour: value.no_of_Labour,
        registertype: location.state.registertype,
        registerby: location.state.registerby,
      });
      resetForm();
      setIsFocused({
        ...isFocused,
        pin: false,
        village: false,
        area: false,
        CPname: false,
        Cnumber: false,
        remark: false,
        service: false,
        email_id: false,
        service_offered_by: false,
        shop_name: false,
        shop_address: false,
        no_of_Labour: false,
      });
      if (location.state.registertype === "home") {
        navigate(
          `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYouForLocal}`
        );
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.error(err);
    }
    setIsSubmitting(false);
  };
  const pincodeList = async (pincodes) => {
    try {
      const getOneAxios = await axiosData.get(
        `/pincodearea/getArea/${pincodes}`
      );
      const data = getOneAxios.data;
      if (data) {
        const getPincode = data.map((i) => {
          return i.village_name;
        });
        getPincode.sort();
        setPincode(getPincode);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getJobList();
  }, []);

  if (ready) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Email_Id_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Mobile_number_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Mobile_number_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="mainGrid"
        >
          <CssBaseline />
          <Grid className="imgGrid" item xs={false} sm={false} md={4}>
            <Box>
              <Box className="sideReg">
                <div className="logo1">
                  <img src={logo} alt="logo" style={{ width: "75%" }} />
                </div>
                <Typography
                  variant="h5"
                  component="div"
                  className={lang === "tamil" ? "side-tamil-1" : "side-english"}
                  gutterBottom
                  sx={{ fontWeight: "bold", fontFamily: "Barlow-Bold" }}
                >
                  {t("By_Providing_Local_Services")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon
                    sx={{ mb: -0.6, mr: 0.5, color: "green" }}
                    color="green"
                  />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Introduce_Your_Company")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                    gutterBottom
                  >
                    {t("Spell_Out_The_Top_Benefits")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Best_Candidates_Reach_Out_To_You_Directly")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            className="secondGrid"
            item
            xs={12}
            sm={12}
            md={8}
            component={Paper}
            elevation={7}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormikSubmit}
            >
              {(formikProps) => {
                const disableCondition =
                  formikProps.isValid &&
                  formikProps.dirty &&
                  (mentorExperience === "<p><br></p>") === false &&
                  isSubmitting === false
                    ? false
                    : true;

                const handleChange = (e) => {
                  setfocusb(false);
                  const { value } = e.target;
                  if (value.length <= 10) {
                    formikProps.handleChange(e);
                  }
                };
                const validate = async (values) => {
                  const errors = {};

                  if (!values) {
                    errors.CNumber = t("Mobile_Number_must_be_Required");
                    setCaptureBlur({ ...captureBlur, contact_number: true });
                    setvalid(true);
                  } else if (/^\d{9}$/.test(values)) {
                    // setCaptureBlur({ ...captureBlur, contact_number: true });
                    setvalid(true);
                  } else if (/^\d{10}$/.test(values)) {
                    if (valid) {
                      const controller = new AbortController();
                      try {
                        const response = await axiosData.get(
                          `users/Mobile/${values}`
                        );

                        if (response.status === 200) {
                          setalert(true);
                          formikProps.setFieldValue("CNumber", "");
                        } else if (response.status === 204) {
                          setalert(false);
                        }

                        setvalid(false);

                        return () => controller.abort();
                      } catch (error) {}
                    }
                  }

                  return errors;
                };
                const handleEmailChange = async (value, validateField) => {
                  const emailValidate =
                    validationSchema.fields.email_id.isValidSync(value);

                  if (emailValidate) {
                    try {
                      const response = await axiosData.post(`users/email`, {
                        email_id: value,
                      });

                      if (response.status === 200) {
                        setalerte(true);
                        formikProps.setFieldValue("email_id", "");
                      } else if (response.status === 204) {
                        formikProps.setFieldValue("email_id", value);

                        setFormEmailError("");
                      }
                    } catch (error) {
                      formikProps.setFieldValue("email_id", value);

                      setFormEmailError("");
                    }
                  }
                };
                return (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      my={7}
                      alignItems={"center"}
                    >
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        sx={{
                          border: "2px solid rgb(237 30 112)",
                          mt: -3,
                          mb: 2,
                          cursor: "default",
                        }}
                      >
                        <EngineeringRoundedIcon
                          sx={{ fontSize: 50, color: "rgb(237 30 112)" }}
                        />
                      </IconButton>

                      <Typography
                        sx={{
                          color: "rgb(19 92 130)",
                          fontWeight: "bold",
                          fontFamily: "Poppins-Regular",
                        }}
                        className="example"
                        // component="h1"
                        variant="h5"
                      >
                        {t("Local_Service_Provider")}
                        {/* {t("JobSeeker_Registration")} */}
                      </Typography>
                      <Box
                        sx={{ mt: 3 }}
                        display={"flex"}
                        flexDirection={"column"}
                        //   my={8}
                        alignItems={"center"}
                        width="100%"
                        gap={"20px"}
                      >
                        <FormControl fullWidth>
                          <FormLabel
                            // focused={isFocused.pin}
                            style={{
                              color:
                                isFocused.pin === true &&
                                // formikProps.touched.pincode === false &&
                                (formikProps.errors.pincode === undefined ||
                                  formikProps.touched.pincode === undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.pincode !== undefined &&
                                    formikProps.touched.pincode === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("Pincode")}
                          </FormLabel>
                          <Autocomplete
                            disableClearable={
                              formikProps.values.pincode ? false : true
                            }
                            disablePortal
                            id="pincode"
                            name="pincode"
                            options={suggestionArrayl}
                            onChange={(event, value, reason) => {
                              searchobj(value, "pincode");

                              formikProps.setFieldValue("pincode", value);
                              if (reason === "clear") {
                                formikProps.setFieldValue("village", "");
                                formikProps.setFieldValue("area", "");
                              }
                            }}
                            value={formikProps.values.pincode}
                            inputValue={searchQuery}
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      pin: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      pin: false,
                                    });
                                  },
                                }}
                                labelId="demo-simple-select-label"
                                name="pincode"
                                // label={t("Pincode")}
                                type="number"
                                id="pincode"
                                value={searchQuery}
                                onWheel={(e) => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder={t("Enter_Pincode(Optional)")}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  if (e.target.value.length < 7) {
                                    handleSuggestionsPincode(e);
                                    if (formikProps.values.village) {
                                      formikProps.setFieldValue("village", "");
                                      formikProps.setFieldValue("area", "");
                                    }
                                  }
                                  // } else {
                                  //   alert("Enter only 6 digit");
                                  // }
                                }}
                                {...params}
                                error={
                                  formikProps.touched.pincode &&
                                  Boolean(formikProps.errors.pincode)
                                }
                                helperText={
                                  formikProps.touched.pincode &&
                                  formikProps.errors.pincode
                                }
                                //  InputLabelProps={{
                                //    style: { color: "black", marginLeft: "7px" },
                                //  }}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {pinLoad ? (
                                        <CircularProgress
                                          // size={24}
                                          style={{
                                            width: "1.7rem",
                                            height: "1.7rem",
                                            color:
                                              isFocused.pincode === true &&
                                              // formikProps.touched.pincode === false &&
                                              (formikProps.errors.pincode ===
                                                undefined ||
                                                formikProps.touched.pincode ===
                                                  undefined)
                                                ? "#1976d5"
                                                : formikProps.errors.pincode !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .pincode === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />
                                      ) : (
                                        <ShareLocationRoundedIcon
                                          sx={{
                                            color:
                                              isFocused.pincode === true &&
                                              // formikProps.touched.pincode === false &&
                                              (formikProps.errors.pincode ===
                                                undefined ||
                                                formikProps.touched.pincode ===
                                                  undefined)
                                                ? "#1976d5"
                                                : formikProps.errors.pincode !==
                                                    undefined &&
                                                  formikProps.touched
                                                    .pincode === true
                                                ? "#de6363"
                                                : "#666666",
                                          }}
                                        />
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <Tooltip
                          title={
                            formikProps.values.pincode
                              ? ""
                              : t("Enter_Pincode_First")
                          }
                        >
                          <FormControl
                            fullWidth
                            disabled={formikProps.values.pincode ? false : true}
                          >
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.village === true &&
                                  (formikProps.errors.village === undefined ||
                                    formikProps.touched.village === undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.village !==
                                        undefined &&
                                      formikProps.touched.village === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Select_Post_Office")}
                            </FormLabel>
                            <Select
                              fullWidth
                              name="village"
                              labelId="demo-simple-select-label"
                              id="village"
                              label="village"
                              required
                              // inputProps={{
                              //   onFocus: () => {
                              //     setIsFocused({
                              //       ...isFocused,
                              //       village: true,
                              //     });
                              //   },
                              //   onBlur: () => {
                              //     setIsFocused({
                              //       ...isFocused,
                              //       village: false,
                              //     });
                              //   },
                              // }}
                              sx={{ margin: "0" }}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange}
                              value={formikProps.values.village}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <HomeWorkRoundedIcon
                                      sx={{
                                        color:
                                          isFocused.village === true &&
                                          (formikProps.errors.village ===
                                            undefined ||
                                            formikProps.touched.village ===
                                              undefined)
                                            ? "#1976d5"
                                            : formikProps.errors.village !==
                                                undefined &&
                                              formikProps.touched.village ===
                                                true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    />{" "}
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.village &&
                                Boolean(formikProps.errors.village)
                              }
                              helperText={
                                formikProps.touched.village &&
                                formikProps.errors.village
                              }
                              displayEmpty
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                value=""
                                disabled
                                disableRipple
                                disableTouchRipple
                              >
                                {t("Select_Post_Office_Name")}
                              </MenuItem>
                              {pincode &&
                                pincode.map((element, index) => {
                                  return (
                                    <MenuItem key={index} value={element}>
                                      {element}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {/* <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  village: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  village: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={"Enter Village or Town Name"}
                            id="village"
                            // label={t("Full_Name")}
                            name="village"
                            sx={{ margin: "0" }}
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.village.replace(
                              /[^a-z'. ]/gi,
                              ""
                            )}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HomeWorkRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.village === true &&
                                        (formikProps.errors.village ===
                                          undefined ||
                                          formikProps.touched.village ===
                                            undefined)
                                          ? "#1976d5"
                                          : formikProps.errors.village !==
                                              undefined &&
                                            formikProps.touched.village === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.village &&
                              Boolean(formikProps.errors.village)
                            }
                            helperText={
                              formikProps.touched.village &&
                              formikProps.errors.village
                            }
                          /> */}
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title={
                            formikProps.values.village
                              ? ""
                              : t("Select_Post_Office_Name")
                          }
                        >
                          <FormControl fullWidth>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.area === true &&
                                  (formikProps.errors.area === undefined ||
                                    formikProps.touched.area === undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.area !== undefined &&
                                      formikProps.touched.area === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Area/Street_Name")}
                            </FormLabel>
                            <TextField
                              disabled={
                                formikProps.values.village ? false : true
                              }
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    area: true,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    area: false,
                                  });
                                },
                              }}
                              fullWidth
                              required
                              placeholder={t("Enter_Area_or_Street_Name")}
                              id="area"
                              // label={t("Full_Name")}
                              name="area"
                              sx={{ margin: "0" }}
                              onBlur={formikProps.handleBlur}
                              onChange={(e) => {
                                const { value } = e.target;
                                const shopValue = value.replace(
                                  /[^a-zA-Z0-9., ]/g,
                                  ""
                                );
                                formikProps.setFieldValue("area", shopValue);
                              }}
                              value={formikProps.values.area}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      style={{
                                        width: "1.7rem",
                                        height: "1.7rem",
                                        // color: "#757575",
                                      }}
                                      src={`https://img.icons8.com/ios-filled/50/${
                                        isFocused.area === true &&
                                        (formikProps.errors.area ===
                                          undefined ||
                                          formikProps.touched.area ===
                                            undefined)
                                          ? "1976d5"
                                          : formikProps.errors.area !==
                                              undefined &&
                                            formikProps.touched.area === true
                                          ? "de6363"
                                          : "666666"
                                      }/street-view.png`}
                                      alt="street-view"
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.area &&
                                Boolean(formikProps.errors.area)
                              }
                              helperText={
                                formikProps.touched.area &&
                                formikProps.errors.area
                              }
                            />
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title={
                            formikProps.values.votingDistrict
                              ? null
                              : t("Select_Service_Name_That_You_are_offering")
                          }
                          arrow
                        >
                          <FormControl sx={{ mt: 2 }} fullWidth required>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.service === true &&
                                  (formikProps.errors.serviceOffer ===
                                    undefined ||
                                    formikProps.touched.serviceOffer ===
                                      undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.serviceOffer !==
                                        undefined &&
                                      formikProps.touched.serviceOffer === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Service_Offered")}
                              {/* {t("MP_Constituency")} */}
                            </FormLabel>
                            <Autocomplete
                              multiple
                              disableClearable={
                                formikProps.values.serviceOffer ? false : true
                              }
                              filterOptions={filterOptions}
                              id="serviceOffer"
                              name="serviceOffer"
                              options={serviceName}
                              onBlur={formikProps.handleBlur}
                              onChange={(event, value) => {
                                setSelectedIndustry(value);
                                formikProps.setFieldValue(
                                  "serviceOffer",
                                  value
                                );
                                // chip.push(value);
                                // setChip([...chip]);
                                // const uniqueArray = Array.from(new Set(chip));
                                // setChip(uniqueArray);
                              }}
                              value={formikProps.values.serviceOffer}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={option}
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  id="serviceOffer"
                                  // label={t("Preffered_Industry")}
                                  name="serviceOffer"
                                  // onClick={() =>
                                  //   params.inputProps.onInputClick()
                                  // }
                                  placeholder={t("Service_Offered")}
                                  error={
                                    formikProps.touched.serviceOffer &&
                                    Boolean(formikProps.errors.serviceOffer)
                                  }
                                  helperText={
                                    formikProps.touched.serviceOffer &&
                                    formikProps.errors.serviceOffer
                                  }
                                  onFocus={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      service: true,
                                    })
                                  }
                                  onBlur={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      service: false,
                                    })
                                  }
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start", // Placement set to "bottom-start"
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl>
                        </Tooltip>
                        <FormControl fullWidth>
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("Service_Offered_By")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="service_offered_by"
                            value={formikProps.values.service_offered_by}
                            onChange={(e) => {
                              setLabour(e.target.value);
                              formikProps.setFieldValue(
                                "service_offered_by",
                                e.target.value
                              );
                              if (e.target.value === "Individual") {
                                formikProps.setFieldValue("no_of_Labour", "");
                                formikProps.setFieldValue("shop_name", "null");
                                formikProps.setFieldValue(
                                  "shop_address",
                                  "null"
                                );
                              }
                              if (e.target.value === "Company") {
                                formikProps.setFieldValue("no_of_Labour", "");
                                formikProps.setFieldValue("shop_name", "");
                                formikProps.setFieldValue("shop_address", "");
                              }
                              if (e.target.value === "Labour") {
                                formikProps.setFieldValue("shop_name", "null");
                                formikProps.setFieldValue(
                                  "shop_address",
                                  "null"
                                );
                              }
                              // formikProps.handleChange(e)
                            }}
                            onBlur={formikProps.handleBlur}
                          >
                            <FormControlLabel
                              value="Company"
                              control={<Radio />}
                              label={t("Company")}
                            />
                            <FormControlLabel
                              value="Individual"
                              control={<Radio />}
                              label={t("Individual")}
                            />
                            <FormControlLabel
                              value="Labour"
                              control={<Radio />}
                              label={t("labour")}
                            />
                          </RadioGroup>
                        </FormControl>
                        {formikProps.values.service_offered_by === "Company" ? (
                          <>
                            {" "}
                            <FormControl fullWidth>
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_name === true &&
                                    (formikProps.errors.shop_name ===
                                      undefined ||
                                      formikProps.touched.shop_name ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_name !==
                                          undefined &&
                                        formikProps.touched.shop_name === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Name")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_name")}
                                id="Name"
                                // label={t("Full_Name")}
                                name="shop_name"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-z'. ]/gi,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_name",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_name}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ShoppingCartRoundedIcon
                                        sx={{
                                          color:
                                            isFocused.shop_name === true &&
                                            (formikProps.errors.shop_name ===
                                              undefined ||
                                              formikProps.touched.shop_name ===
                                                undefined)
                                              ? "#1976d5"
                                              : formikProps.errors.shop_name !==
                                                  undefined &&
                                                formikProps.touched
                                                  .shop_name === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.shop_name &&
                                  Boolean(formikProps.errors.shop_name)
                                }
                                helperText={
                                  formikProps.touched.shop_name &&
                                  formikProps.errors.shop_name
                                }
                              />
                            </FormControl>{" "}
                            <FormControl fullWidth>
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_address === true &&
                                    (formikProps.errors.shop_address ===
                                      undefined ||
                                      formikProps.touched.shop_address ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_address !==
                                          undefined &&
                                        formikProps.touched.shop_address ===
                                          true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Address")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_address")}
                                id="Name"
                                // label={t("Full_Name")}
                                name="shop_address"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-zA-Z0-9./,: #]/g,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_address",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_address}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FmdGoodRoundedIcon
                                        style={{
                                          color:
                                            isFocused.shop_address === true &&
                                            (formikProps.errors.shop_address ===
                                              undefined ||
                                              formikProps.touched
                                                .shop_address === undefined)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .shop_address !== undefined &&
                                                formikProps.touched
                                                  .shop_address === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.shop_address &&
                                  Boolean(formikProps.errors.shop_address)
                                }
                                helperText={
                                  formikProps.touched.shop_address &&
                                  formikProps.errors.shop_address
                                }
                              />
                            </FormControl>
                          </>
                        ) : null}
                        {formikProps.values.service_offered_by === "Labour" ? (
                          <FormControl fullWidth>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.no_of_Labour === true &&
                                  (formikProps.errors.no_of_Labour ===
                                    undefined ||
                                    formikProps.touched.no_of_Labour ===
                                      undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.no_of_Labour !==
                                        undefined &&
                                      formikProps.touched.no_of_Labour === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("no_of_Labour")}
                            </FormLabel>
                            <TextField
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    no_of_Labour: true,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    no_of_Labour: false,
                                  });
                                },
                              }}
                              fullWidth
                              required
                              placeholder={t("Enter_no_of_Labour")}
                              id="Name"
                              // label={t("Full_Name")}
                              name="no_of_Labour"
                              sx={{ margin: "0" }}
                              onBlur={formikProps.handleBlur}
                              onChange={(e) => {
                                const { value } = e.target;
                                const shopValue = value.replace(
                                  /[^0-9 ]/gi,
                                  ""
                                );
                                formikProps.setFieldValue(
                                  "no_of_Labour",
                                  shopValue
                                );
                              }}
                              value={formikProps.values.no_of_Labour}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <ContactPageRoundedIcon
                                      sx={{
                                        color:
                                          isFocused.no_of_Labour === true &&
                                          (formikProps.errors.no_of_Labour ===
                                            undefined ||
                                            formikProps.touched.no_of_Labour ===
                                              undefined)
                                            ? "#1976d5"
                                            : formikProps.errors
                                                .no_of_Labour !== undefined &&
                                              formikProps.touched
                                                .no_of_Labour === true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    />{" "}
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.no_of_Labour &&
                                Boolean(formikProps.errors.no_of_Labour)
                              }
                              helperText={
                                formikProps.touched.no_of_Labour &&
                                formikProps.errors.no_of_Labour
                              }
                            />
                          </FormControl>
                        ) : null}
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.CPname === true &&
                                (formikProps.errors.CPname === undefined ||
                                  formikProps.touched.CPname === undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.CPname !== undefined &&
                                    formikProps.touched.CPname === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Contact_Person_Name")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  CPname: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  CPname: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_Your_Name")}
                            id="Name"
                            // label={t("Full_Name")}
                            name="CPname"
                            sx={{ margin: "0" }}
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z'. ]/gi,
                                ""
                              );
                              formikProps.setFieldValue("CPname", shopValue);
                            }}
                            value={formikProps.values.CPname}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <ContactPageRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.CPname === true &&
                                        (formikProps.errors.CPname ===
                                          undefined ||
                                          formikProps.touched.CPname ===
                                            undefined)
                                          ? "#1976d5"
                                          : formikProps.errors.CPname !==
                                              undefined &&
                                            formikProps.touched.CPname === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.CPname &&
                              Boolean(formikProps.errors.CPname)
                            }
                            helperText={
                              formikProps.touched.CPname &&
                              formikProps.errors.CPname
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.Cnumber === true &&
                                (formikProps.errors.CNumber === undefined ||
                                  formikProps.touched.CNumber === undefined ||
                                  captureBlur.contact_number === false)
                                  ? "#1976d5"
                                  : formikProps.errors.CNumber !== undefined &&
                                    formikProps.touched.CNumber === true &&
                                    captureBlur.contact_number === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Contact_Number")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  Cnumber: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  Cnumber: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_Your_Mobile_Number")}
                            name="CNumber"
                            // label={t("Mobile_Number")}
                            type="number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            id="mobile_number"
                            onBlur={formikProps.handleBlur}
                            onBlurCapture={() =>
                              setCaptureBlur({
                                ...captureBlur,
                                contact_number: true,
                              })
                            }
                            onChange={(e) => {
                              setCaptureBlur({
                                ...captureBlur,
                                contact_number: false,
                              });
                              const { value } = e.target;
                              if (value.length < 11) {
                                formikProps.handleChange(e);
                                handleChange(e);
                                validate(e.target.value);
                              }
                            }}
                            value={formikProps.values.CNumber}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              maxLength: 10,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <b
                                    style={{
                                      color:
                                        isFocused.Cnumber === true &&
                                        (formikProps.errors.CNumber ===
                                          undefined ||
                                          formikProps.touched.CNumber ===
                                            undefined ||
                                          captureBlur.contact_number === false)
                                          ? "#1976d5"
                                          : formikProps.errors.CNumber !==
                                              undefined &&
                                            formikProps.touched.CNumber ===
                                              true &&
                                            captureBlur.contact_number === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    +91
                                  </b>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.CNumber &&
                              Boolean(formikProps.errors.CNumber) &&
                              captureBlur.contact_number
                            }
                            helperText={
                              formikProps.touched.CNumber &&
                              captureBlur.contact_number &&
                              formikProps.errors.CNumber
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.email_id === true &&
                                (formikProps.errors.email_id === undefined ||
                                  formikProps.touched.email_id === undefined ||
                                  captureBlur.email_id === false)
                                  ? "#1976d5"
                                  : formikProps.errors.email_id !== undefined &&
                                    formikProps.touched.email_id === true &&
                                    captureBlur.email_id === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Contact_Email")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  email_id: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  email_id: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_Your_Email_ID")}
                            id="Email"
                            // label={t("Full_Name")}
                            name="email_id"
                            sx={{ margin: "0" }}
                            onBlur={formikProps.handleBlur}
                            onBlurCapture={() =>
                              setCaptureBlur({ ...captureBlur, email_id: true })
                            }
                            onChange={(e) => {
                              setCaptureBlur({
                                ...captureBlur,
                                email_id: false,
                              });
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z0-9@.]/gi,
                                ""
                              );
                              formikProps.setFieldValue("email_id", shopValue);

                              handleEmailChange(
                                shopValue,
                                formikProps.validateField
                              );
                            }}
                            value={formikProps.values.email_id}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.email_id === true &&
                                        (formikProps.errors.email_id ===
                                          undefined ||
                                          formikProps.touched.email_id ===
                                            undefined ||
                                          captureBlur.email_id === false)
                                          ? "#1976d5"
                                          : formikProps.errors.email_id !==
                                              undefined &&
                                            formikProps.touched.email_id ===
                                              true &&
                                            captureBlur.email_id === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.email_id &&
                              Boolean(formikProps.errors.email_id) &&
                              captureBlur.email_id
                            }
                            helperText={
                              formikProps.touched.email_id &&
                              captureBlur.email_id &&
                              formikProps.errors.email_id
                              // formEmailError
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.remark === true &&
                                (formikProps.errors.remarks === undefined ||
                                  formikProps.touched.remarks === undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.remarks !== undefined &&
                                    formikProps.touched.remarks === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Remarks")}
                          </FormLabel>
                          <ReactQuill
                            value={formikProps.values.remarks}
                            onFocus={() => {
                              setIsFocused({
                                ...isFocused,
                                remark: true,
                              });
                            }}
                            onBlur={() => {
                              setIsFocused({
                                ...isFocused,
                                remark: false,
                              });
                            }}
                            onChange={(value) => {
                              formikProps.setFieldValue("remarks", value);
                              setMentorExperience(value);
                            }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                            placeholder={t("Enter_Notes_you_want_to_add")}
                            className="scrollable-container-postjob"
                          />
                        </FormControl>
                        <Tooltip
                          arrow
                          title={
                            disableCondition
                              ? t("Please_Fill_all_the_fields")
                              : ""
                          }
                          sx={{ width: "100%" }}
                        >
                          <Box width="100%">
                            <Button
                              type="submit"
                              disabled={disableCondition}
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 3,
                                mb: 2,
                                textTransform: "capitalize",
                                backgroundColor: "#135c82",
                                transition: "0.3s all",
                                "&:hover": { backgroundColor: "#022638" },
                              }}
                              onClick={() => {
                                formikProps.handleSubmit();
                              }}
                            >
                              {t("Submit")}
                              {isSubmitting && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#ffffff",

                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Outlet />
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default LocalServiceProviderForm;
