/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import MoreIcon from "@mui/icons-material/MoreVert";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DashboardRounded as DashboardRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useMediaQuery } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LogOutDialog from "../../admin-panel/admin-components/DialogBoxes/LogOutDialog";
import logo from "../../../img/logo_for-landingpage-3.png";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      [theme.breakpoints.down("sm")]: { marginLeft: 0 },
      marginLeft: `${drawerWidth + 50}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [[theme.breakpoints.up("md")] || [theme.breakpoints.down("md")]]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth + 50}px)`,

      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("sm")]: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function ProviderPanel() {
  const [active, setActive] = useState("dash");
  const theme = useTheme();
  const [openLog, setOpenLog] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen((e) => !e);
  };
  const logOutFunc = () => {
    setOpenLog(true);
  };
  const location = useLocation();
  const verifierName = JSON.parse(localStorage.getItem("service-provider"));

  useEffect(() => {
    if (verifierName === null || verifierName === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      let currentUrl = location.pathname;

      if (currentUrl === ROUTINGDATA.ADMINDASHBOARD) {
        setActive("dash");
      } else if (currentUrl === ROUTINGDATA.ADMINSEEKERS) {
        setActive("seeker");
      } else if (currentUrl === ROUTINGDATA.ADMINEMPLOYER) {
        setActive("employer");
      } else if (currentUrl === ROUTINGDATA.USER) {
        setActive("user");
      } else if (currentUrl === ROUTINGDATA.ADMINNeedSupport) {
        setActive("need");
      }
    }
  }, [location.pathname]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>{verifierName}</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: " #055779" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h6" noWrap component="div">
                Local Service Provider Panel
              </Typography>
            </Box>

            <Tooltip
              title={
                <div>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      textTransform: "uppercase",
                      letterSpacing: "2px",
                    }}
                  >
                     {process.env.REACT_APP_WEBSITE_NAME} account
                  </span>
                  <p
                    style={{
                      margin: "3px 0px",
                      fontSize: "11px",
                      textTransform: "uppercase",
                    }}
                  >
                    Local Service Provider
                  </p>
                  <p style={{ fontSize: "12px", margin: "0" }}>
                    {verifierName}
                  </p>
                </div>
              }
              placement="bottom-end"
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              // anchorOrigin={{
              //   vertical: "top",
              //   horizontal: "right",
              // }}
              // keepMounted
              // transformOrigin={{
              //   vertical: "top",
              //   horizontal: "right",
              // }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    // mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      // ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <MenuItem
              onClick={() => {
                // handleClose();
                // navigate(ROUTINGDATA.ADMINSETTINGS);
                // sessionStorage.setItem("active", "");
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenLog(true);
                }}
              >
                <ListItemIcon>
                  <LogoutRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
            <Box
              sx={{ display: { xs: "flex", md: "none" } }}
              className="more-icon"
            >
              <IconButton
                size="small"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth + 50,
            boxSizing: "border-box",
            backgroundColor: "#339fcd",
          },
          [theme.breakpoints.down("sm")]: {
            "& .MuiDrawer-paper": {
              backgroundColor: "#339fcd",
              width: drawerWidth,
              flexShrink: 0,
            },
          },
        }}
        variant={
          isMobile
            ? "temporary"
            : isDevice || isDeviceDown
            ? "persistent"
            : null
        }
        anchor="left"
        open={open}
      >
        <DrawerHeader className="header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
             <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img className="image"  src={logo} alt="" />
            </div>
            <div
              style={{
                display: "flex",

                alignItems: "center",
              }}
            >
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </Box>
        </DrawerHeader>
        <List>
          <ListItemText>
            <span className="sidehead">Navigation</span>
          </ListItemText>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={active === "dash" ? "active" : "link-active"}
              onClick={(e) => {
                navigate(ROUTINGDATA.LOCALPROVIDERDASHBOARD);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <DashboardRoundedIcon className="text" />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className="font">Dashboard</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem className="link-active" sx={{ padding: "1px 10px" }}>
            <ListItemButton sx={{ borderRadius: "10px" }} onClick={logOutFunc}>
              <ListItemIcon>
                <LogoutRoundedIcon className="text" sx={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{ color: "#fff", fontWeight: 800 }}
              >
                <span className="font">Log Out</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open} className="main">
        <DrawerHeader />
        <Outlet />
      </Main>
      <LogOutDialog
        openLog={openLog}
        setOpenLog={setOpenLog}
        verifier={"verifier"}
      />
    </Box>
  );
}
export default ProviderPanel;
