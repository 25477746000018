import React, { useEffect, useState } from "react";
import "./NeedSupport.css";
import { useFormik } from "formik";
import {
  IconButton,
  TextField,
  Box,
  Typography,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import gpay from "../../../../img/gpay.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function BankDetails() {
  const [loading, setLoading] = useState(true);
  const axiosData = useAxios();
  const Location = useLocation();
  const navigate = useNavigate();
  const [upi, setUpi] = useState(false);
  const [bank, setBank] = useState(false);
  const [family, setFamily] = useState();
  const [IsSubmitting, setIsSubmitting] = useState(false);
  let [help, setHelp] = useState();
  const seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const formik = useFormik({
    initialValues: upi
      ? {
          UpiNumber: "",
        }
      : {
          AccountNumber: "",
          Re_Ender_AccountNumber: "",
          IfscCode: "",
          AccHolderName: "",
        },
    validationSchema: upi
      ? Yup.object().shape({
          UpiNumber: upi
            ? Yup.string()
                .max(10, "max 10 numbers")
                .min(10, "min 10 numbers")
                .required("GPay number required")
            : Yup.string(),
        })
      : Yup.object().shape({
          AccountNumber: bank
            ? Yup.string()
                .required("Account number required")
                .max(18, "max 18 numbers")
                .min(9, "min 9 numbers")
            : Yup.string().required("Account number required"),
          Re_Ender_AccountNumber: bank
            ? Yup.string()
                .required("Re enter account number required")
                .oneOf(
                  [Yup.ref("AccountNumber"), null],
                  "Account Number must match"
                )
            : Yup.string(),
          IfscCode: bank
            ? Yup.string()
                .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code")
                .required("IFSC Code required")
            : Yup.string(),
          AccHolderName: bank
            ? Yup.string()
                .matches(
                  /^[a-zA-Z_. ']+$/,
                  "Special Characters and numbers other than space, dot, singleQuote are not allowed"
                )
                .max(50, "Must be 50 characters or less")
                .required("Account holder name required")
            : Yup.string(),
        }),

    onSubmit: async (values) => {
      setIsSubmitting(true);

      if (upi) {
        const putFamily = await axiosData.put(
          `/family/admin/${Location.state.email_id}`,
          { UpiNumber: values.UpiNumber }
        );

        if (putFamily.status === 201) {
          setUpi(false);
          setBank(false);
          setIsSubmitting(false);
          navigate("/main/seekerProfile", {
            state: Location.state.email_id,
          });
        } else {
          setUpi(false);
          setBank(false);
          setIsSubmitting(false);
        }
      }
      if (bank) {
        const putFamily = await axiosData.put(
          `/family/admin/${Location.state.email_id}`,
          {
            BankDetails: {
              AccountNumber: values.AccountNumber,
              Re_Ender_AccountNumber: values.Re_Ender_AccountNumber,
              IfscCode: values.IfscCode,
              AccHolderName: values.AccHolderName,
            },
          }
        );

        if (putFamily.status === 201) {
          setUpi(false);
          setBank(false);
          setIsSubmitting(false);
          navigate("/main/seekerProfile", {
            state: Location.state.email_id,
          });
        } else {
          setUpi(false);
          setBank(false);
          setIsSubmitting(false);
        }
      }
      setIsSubmitting(false);
      formik.handleReset("");
    },
  });

  const handleClose2 = () => {
    formik.resetForm();
    setBank(false);
  };
  const handleClose1 = () => {
    formik.resetForm();
    formik.handleReset();
    formik.setFieldValue("UpiNumber", "");
    setUpi(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };


  const FamilyDetail = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `/family/${Location.state.email_id}`
      );

      const { data } = getOneAxios;
      setFamily({ ...family, data });

      await axiosData
        .get(`/seekers/email/${Location.state.email_id}`)
        .then((data) => {
          help = data.data;
          setHelp({ ...help });
        });

      if (data) {
        formik.setFieldValue("UpiNumber", data.UpiNumber);
        formik.setFieldValue("AccountNumber", data.BankDetails.AccountNumber);
        formik.setFieldValue(
          "Re_Ender_AccountNumber",
          data.BankDetails.Re_Ender_AccountNumber
        );
        formik.setFieldValue("IfscCode", data.BankDetails.IfscCode);
        formik.setFieldValue("AccHolderName", data.BankDetails.AccHolderName);
      }

      return family;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (seekerDetails === null || seekerDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      FamilyDetail();
    }
  }, []);

  return (
    <div>
      <Dialog
        open={upi}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography sx={{ ml: 2.2 }} variant="h5">
              <b>GPay Number</b>
            </Typography>
            <Grid item xs={12} sm={12} md={8} square>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <TextField
                    margin="normal"
                    className="Educationfieldsize"
                    type="number"
                    placeholder="Enter GPay Number"
                    value={formik.values.UpiNumber}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    id="UpiNumber"
                    label="GPay Number"
                    name="UpiNumber"
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    InputProps={{
                      maxLength: 10,
                      startAdornment: (
                        <InputAdornment position="start">
                          <b>+91</b>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {formik.touched.UpiNumber && formik.errors.UpiNumber && (
                    <Typography sx={{ color: "red", fontSize: "13px" }}>
                      {formik.errors.UpiNumber}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </DialogTitle>

          <DialogActions sx={{ mr: 2 }}>
            <Button variant="outlined" color="error" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              disabled={
                formik.isValid && formik.dirty && IsSubmitting === false
                  ? false
                  : true
              }
              variant="contained"
              type="submit"
            >
              Submit{" "}
              {IsSubmitting && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",

                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={bank}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ "& .MuiPaper-root": { width: "500px" },zIndex:"99999" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography sx={{ ml: 2.2 }} variant="h5">
              <b>Bank Details</b>
            </Typography>
            <Grid item xs={12} sm={12} md={8} square>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ p: 2, pl: 3.5 }}>
                  <TextField
                    margin="normal"
                    className="Educationfieldsize"
                    type="number"
                    placeholder="Enter account number"
                    value={formik.values.AccountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="AccountNumber"
                    label="Account number"
                    name="AccountNumber"
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />

                  {formik.touched.AccountNumber &&
                    formik.errors.AccountNumber && (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.AccountNumber}
                      </Typography>
                    )}

                  <TextField
                    margin="normal"
                    className="Educationfieldsize"
                    type="number"
                    placeholder="Re-enter account number"
                    value={formik.values.Re_Ender_AccountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="Re_Ender_AccountNumber"
                    label="Re-enter account number"
                    name="Re_Ender_AccountNumber"
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />

                  {formik.touched.Re_Ender_AccountNumber &&
                    formik.errors.Re_Ender_AccountNumber && (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.Re_Ender_AccountNumber}
                      </Typography>
                    )}

                  <TextField
                    margin="normal"
                    className="Educationfieldsize"
                    placeholder="Enter IFSC code"
                    name="IfscCode"
                    label="IFSC Code"
                    value={formik.values.IfscCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="IfscCodeIfscCode"
                  />
                  {formik.touched.IfscCode && formik.errors.IfscCode && (
                    <Typography sx={{ color: "red", fontSize: "13px" }}>
                      {formik.errors.IfscCode}
                    </Typography>
                  )}

                  <TextField
                    margin="normal"
                    className="Educationfieldsize"
                    placeholder="Enter account holder name"
                    name="AccHolderName"
                    label="Account Holder Name"
                    value={formik.values.AccHolderName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="AccHolderName"
                  />
                  {formik.touched.AccHolderName &&
                    formik.errors.AccHolderName && (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.AccHolderName}
                      </Typography>
                    )}
                </Box>
              </Box>
            </Grid>
          </DialogTitle>

          <DialogActions sx={{ mr: 2 }}>
            <Button variant="outlined" color="error" onClick={handleClose2}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                formik.isValid && formik.dirty && IsSubmitting === false
                  ? false
                  : true
              }
            >
              Submit{" "}
              {IsSubmitting && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",

                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Typography
          sx={{
            fontSize: 30,
            fontWeight: 700,
            width: "100%",
            alignContent: "space-between",
          }}
        >
          Bank Details
        </Typography>
        <Tooltip
          arrow
          onClick={() => {
            navigate("/main/seekerProfile", {
              state: Location.state.email_id,
            });
          }}
          className="GoBackTooltip"
          sx={{ backgroundColor: "#339fcd" }}
          title="Go Back"
        >
          <IconButton
            className="GoBackTooltip"
            sx={{ backgroundColor: "#339fcd" }}
          >
            <ArrowBackIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography
        sx={{
          fontSize: 16,
          width: "100%",
          alignContent: "space-between",
          mt: 1,
        }}
      >
        Fill GPay number or Bank details for further support.
      </Typography>

      <Grid container>
        <Grid sx={{ p: 4 }} xs={12} sm={12} md={5} lg={5}>
          <Card
            onClick={() => {
              setUpi(true);
              FamilyDetail();
              setBank(false);
            }}
            sx={{
              minWidth: 200,
              boxShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.4), -2px -2px 4px rgba(0, 0, 0, 0.4)",
              cursor: "pointer",
              borderRadius: 4,
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex" }}>
                <IconButton sx={{ margin: "auto" }}>
                  {/* <CurrencyRupeeIcon sx={{ fontSize: 70, color: "#0a814e" }} /> */}
                  <img
                    src={gpay}
                    alt="gpay"
                    style={{ height: "75px", width: "75px" }}
                  />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                GPay number.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={2} lg={2}>
          <Box sx={{ display: "flex" }}>
            <Typography className="ORTypo" sx={{ margin: "auto" }}>
              (OR)
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ p: 4 }} xs={12} sm={12} md={5} lg={5}>
          <Card
            onClick={() => {
              setBank(true);
              FamilyDetail();
              setUpi(false);
            }}
            sx={{
              minWidth: 200,
              boxShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.4), -2px -2px 4px rgba(0, 0, 0, 0.4)",
              cursor: "pointer",
              borderRadius: 5,
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex" }}>
                <IconButton sx={{ margin: "auto" }}>
                  <AccountBalanceIcon sx={{ fontSize: 70, color: "#f06d04" }} />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                Bank details.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default BankDetails;
