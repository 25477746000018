import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../api/useAxios";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import "./TermsAndCondition.css";
import { ReactComponent as TwitterX } from "../../img/twitter-x-seeklogo.com-4.svg";
import PrivacyFooter from "./PrivacyFooter";

const RefundPolicy = () => {
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div>
        <div className="herotextimg-2">
          <div className="maintextforhero-2">
            <div className="TermsAndCondition-center">
              <h2>Cancellation & Refund Policy</h2>
            </div>
          </div>
        </div>
        <div className="TermsAndConditionBackpage">
          <div className="TermsAndConditionBackpagefonts">
            <span
              className="Termsbackbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#135c82" }}
            >
              {t("Home")}
            </span>
            /{" "}
            <span style={{ color: "black" }}>Cancellation & Refund Policy</span>
          </div>
        </div>
        <Container>
          <div>
            <h2>Cancellation & Refund Policy</h2>
            <p>
              US GLOBAL SOLUTIONS believes in helping its customers as far as
              possible, and has therefore a liberal cancellation policy. Under
              this policy:
            </p>
            <ul>
              <li>
                Cancellations will be considered only if the request is made
                immediately after placing the order. However, the cancellation
                request may not be entertained if the orders have been
                communicated to the vendors/merchants and they have initiated
                the process of shipping them.
              </li>
              <li>
                US GLOBAL SOLUTIONS does not accept cancellation requests for
                perishable items like flowers, eatables etc. However,
                refund/replacement can be made if the customer establishes that
                the quality of product delivered is not good.
              </li>
              <li>
                In case of receipt of damaged or defective items please report
                the same to our Customer Service team. The request will,
                however, be entertained once the merchant has checked and
                determined the same at his own end. This should be reported
                within 7 days of receipt of the products.
              </li>
              <li>
                In case you feel that the product received is not as shown on
                the site or as per your expectations, you must bring it to the
                notice of our customer service within 7 days of receiving the
                product. The Customer Service Team after looking into your
                complaint will take an appropriate decision.
              </li>
              <li>
                In case of complaints regarding products that come with a
                warranty from manufacturers, please refer the issue to them.
              </li>
              <li>
                In case of any Refunds approved by the US GLOBAL SOLUTIONS,
                it’ll take 16-30 days for the refund to be processed to the end
                customer.
              </li>
            </ul>
          </div>
        </Container>

        <PrivacyFooter />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default RefundPolicy;
