import React from "react";
import { Grid, Skeleton } from "@mui/material";

function SkeletonSeekCard() {
  const cardList = [
    {
      name: "Total Registered",
    },

    {
      name: "Total Freshers",
    },
    {
      name: "Total Need Support",
    },
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Skeleton
              variant="rounded"
              //   width={280}
              //   height={130}
              sx={{ borderRadius: "15px", padding: "16px", height: "130px" }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default SkeletonSeekCard;
