import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import Popper from "@mui/material/Popper";
import "./MentorRegistration.css";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import { FormControl, Grid, FormLabel } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function MentorUpdateForm() {
  const [focusout, setfocus] = useState();
  const axiosData = useAxios();
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n, ready } = useTranslation();
  const [formEmail, setFormEmail] = useState("");
  const [valid, setvalid] = useState(true);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [alerte, setalerte] = useState(false);
  const [industryArray, setIndustryArray] = useState();
  const fileInputField = useRef(null);
  const [data, setData] = useState(false);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");
  const [mentorExperience, setMentorExperience] = useState("");
  const Location = useLocation();

  const MAX_FILE_SIZE = 1048576 * 10;
  

  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
      formik.setFieldValue("email_id", "");
    }
    setalerte(false);
  }, [alerte]);
  const errors = {};

  const validate = async (values) => {
    const errors = {};
    if (!values.mobileNumber) {
      errors.mobileNumber = t("Mobile_Number_must_be_Required");
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobileNumber)) {
      setvalid(true);
    }

    return errors;
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

 
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      MentorName: "",
      email_id: "",
      educationQualifications: "",
      Specialisation: "",
      officialAddress: "",
      mobileNumber: "",
      linkedInLink: "",
      areaOfExpertise: "",
      currentLocation: "",
      yearsOfExperience: "",
      currentEmployer: "",
      Designation: "",
      membershipInProfessional: "",
      professionalCertifications: "",
      Experience: "",
    },
    validate,
    validationSchema: Yup.object({
      mobileNumber: Yup.number()
        .min(6000000000, t("Not_Valid_Mobile_Number4!"))
        .max(9999999999, t("Not_Valid_Mobile_Number4!"))
        .required(t("Mobile_Number_must_be_Required")),

      educationQualifications: Yup.string().required(
        t("educationQualifications_Required")
      ),
      Designation: Yup.string()
        .required(t("Designation_must_be_Required4"))
        .max(50, t("Must_be_50_characters_or_less4")),
      Specialisation: Yup.string().required(t("Specialisation_Required")),
      officialAddress: Yup.string().required(t("official_Address_Required")),
      linkedInLink: Yup.string()
        .required(t("linkedIn_Link_Required"))
        .matches(
          /https:\/\/www.linkedin.com\/.*$/,
          t("Invalid_LinkedIn_URL_format")
        ),
      areaOfExpertise: Yup.string().required(t("area_Of_Expertise_Required")),
      currentLocation: Yup.string().required(t("Other_Specify_Required")),
      yearsOfExperience: Yup.string().required(
        t("years_Of_Experience_Required")
      ),
      currentEmployer: Yup.string().required(t("current_Employer_Required")),
      membershipInProfessional: Yup.string().required(
        t("Membership_in_Professional_Societies")
      ),
      professionalCertifications: Yup.string().required(
        t("Professional_Certifications")
      ),
      Experience: Yup.string().required(t("years_Of_Experience_Required")),
    }),

    onSubmit: async (values) => {
      setIsSubmitting(true);
      await axiosData.put(`mentor/email/${Location.state.email_id}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsSubmitting(false);
      navigate(-1).catch((error) => {
        console.error("There was an error!", error);
      });
    },
  });
  const [focusb, setfocusb] = useState();
  const handleChange = (e) => {
    setfocusb(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const theme = useTheme();

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const [GetOneMentor, setGetOneMentor] = useState();
  const getOneMentorData = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `/mentor/email/get/${Location.state.email_id}`
      );
      const data = getOneAxios.data;
      if (data) {
        setGetOneMentor(data);
        setMentorExperience(data.Experience);
      }

      if (data) {
        formik.setFieldValue("MentorName", data.MentorName);
        formik.setFieldValue("email_id", data.email_id);
        formik.setFieldValue(
          "educationQualifications",
          data.educationQualifications
        );
        formik.setFieldValue("Specialisation", data.Specialisation);
        formik.setFieldValue("officialAddress", data.officialAddress);
        formik.setFieldValue("mobileNumber", data.mobileNumber);
        formik.setFieldValue("linkedInLink", data.linkedInLink);
        formik.setFieldValue("areaOfExpertise", data.areaOfExpertise);
        formik.setFieldValue("currentLocation", data.currentLocation);
        formik.setFieldValue("yearsOfExperience", data.yearsOfExperience);
        formik.setFieldValue("currentEmployer", data.currentEmployer);
        formik.setFieldValue("Designation", data.Designation);
        formik.setFieldValue(
          "membershipInProfessional",
          data.membershipInProfessional
        );
        formik.setFieldValue(
          "professionalCertifications",
          data.professionalCertifications
        );
        formik.setFieldValue("Experience", data.Experience);
        // formik.setFieldValue("CVUpload", data.CVUpload);
        // formik.setFieldValue("mentorProfile", data.mentorProfile);
      }

      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    let mentorDetails = JSON.parse(localStorage.getItem("mentor"));
    if (mentorDetails === null || mentorDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      IndustryData();
      getOneMentorData();
      experienceData()
    }
  }, []);

  const [experience, setExperience] = useState([]);
  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;
      if (data) {
        setExperience(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  if (ready && GetOneMentor) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
            {t("This_Email_Id_already_exist1")}{process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}{t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
          <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="main-grid"
          >
            <CssBaseline />

            <Grid
              className="second-grid"
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={4}
              square
            >
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    my: 3,
                    mx: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="backbuttonformentor">
                    <Tooltip title="Go back" arrow>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ backgroundColor: "#339fcd" }}
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="backbuttonformentorUpdate"
                      >
                        <ArrowBackIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <PeopleAltRoundedIcon
                    sx={{ mb: 1, width: 56, height: 56, color: "#ed1e70" }}
                  />

                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ color: "#135c82" }}
                  >
                    {t("Mentor_Update")}
                  </Typography>

                  <Box sx={{ mt: 6 }}>
                    <Grid container rowSpacing={2}>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ mb: 2 }}
                      >
                        <Grid item xs={12} md={6} lg={6}>
                          <Tooltip title="Can't editable" arrow>
                            <FormControl disabled fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Mentor_Name")}
                              </FormLabel>
                              <TextField
                              disabled
                                required
                                fullWidth
                                placeholder={t("Enter_Your_Name")}
                                id="MentorName"
                                name="MentorName"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z.,' ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue("MentorName", value);
                                  }
                                }}
                                value={formik.values.MentorName.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                )}
                                error={
                                  formik.touched.MentorName &&
                                  Boolean(formik.errors.MentorName)
                                }
                                helperText={
                                  formik.touched.MentorName &&
                                  formik.errors.MentorName
                                }
                              />
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Tooltip title="Can't editable" arrow>
                            <FormControl disabled fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Email_Id")}
                              </FormLabel>
                              <TextField
                                disabled
                                required
                                fullWidth
                                placeholder={t("Enter_Your_Email_ID")}
                                id="email_id"
                                name="email_id"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                value={formik.values.email_id.replace(
                                  /[^a-z0-9@.]/gi,
                                  ""
                                )}
                                onBlurCapture={() => {
                                  setfocus(true);
                                }}
                                error={
                                  formik.touched.email_id &&
                                  focusout &&
                                  Boolean(formik.errors.email_id)
                                }
                                helperText={
                                  formik.touched.email_id &&
                                  focusout &&
                                  formik.errors.email_id
                                }
                              />
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Mobile_Number")}
                            </FormLabel>
                            <TextField
                              type="number"
                              required
                              fullWidth
                              placeholder={t("Enter_Your_Mobile_Number")}
                              id="mobileNumber"
                              name="mobileNumber"
                              onBlur={formik.handleBlur}
                              onChange={handleChange}
                              value={formik.values.mobileNumber}
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              onBlurCapture={() => {
                                setfocusb(true);
                              }}
                              error={
                                formik.touched.mobileNumber &&
                                focusb &&
                                Boolean(formik.errors.mobileNumber)
                              }
                              helperText={
                                formik.touched.mobileNumber &&
                                focusb &&
                                formik.errors.mobileNumber
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("LinkedIn_Profile_Link")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_LinkedIn_Profile_Link")}
                              id="linkedInLink"
                              name="linkedInLink"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.linkedInLink}
                              error={
                                formik.touched.linkedInLink &&
                                Boolean(formik.errors.linkedInLink)
                              }
                              helperText={
                                formik.touched.linkedInLink &&
                                formik.errors.linkedInLink
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Education_qualifications")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_Education_qualifications")}
                              id="Education qualifications"
                              name="educationQualifications"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,/& ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "educationQualifications",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.educationQualifications}
                              error={
                                formik.touched.educationQualifications &&
                                Boolean(formik.errors.educationQualifications)
                              }
                              helperText={
                                formik.touched.educationQualifications &&
                                formik.errors.educationQualifications
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          {industryArray && (
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Specialisation/domain")}
                              </FormLabel>
                              <Autocomplete
                                disableClearable={
                                  formik.values.Specialisation ? false : true
                                }
                                filterOptions={filterOptions}
                                id="industry"
                                name="Specialisation"
                                options={industryArray}
                                onBlur={formik.handleBlur}
                                onChange={(event, value) => {
                                  setSelectedIndustry(value);
                                  formik.setFieldValue("Specialisation", value);
                                }}
                                value={formik.values.Specialisation}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    id="Specialisation"
                                    placeholder={t(
                                      "Enter_Specialisation/domain"
                                    )}
                                    name="Specialisation"
                                    onClick={() =>
                                      params.inputProps.onInputClick()
                                    }
                                    error={
                                      formik.touched.Specialisation &&
                                      Boolean(formik.errors.Specialisation)
                                    }
                                    helperText={
                                      formik.touched.Specialisation &&
                                      formik.errors.Specialisation
                                    }
                                  />
                                )}
                                onOpen={(event) => event.preventDefault()}
                                PopperComponent={({
                                  children,
                                  ...popperProps
                                }) => (
                                  <Popper
                                    {...popperProps}
                                    placement="bottom-start"
                                    modifiers={{
                                      flip: {
                                        enabled: false,
                                      },
                                      preventOverflow: {
                                        enabled: true,
                                        boundariesElement: "scrollParent",
                                      },
                                    }}
                                    PopperProps={{
                                      placement: "bottom-start", 
                                    }}
                                  >
                                    <Paper>{children}</Paper>
                                  </Popper>
                                )}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "180px",
                                    overflowY: "auto",
                                  },
                                }}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Area_of_expertise")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_Area_of_expertise")}
                              id="areaOfExpertise"
                              name="areaOfExpertise"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'., ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "areaOfExpertise",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.areaOfExpertise}
                              error={
                                formik.touched.areaOfExpertise &&
                                Boolean(formik.errors.areaOfExpertise)
                              }
                              helperText={
                                formik.touched.areaOfExpertise &&
                                formik.errors.areaOfExpertise
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                         { experience ? <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Years_of_experience_in_this_area")}
                            </FormLabel>
                            <Autocomplete
                              fullWidth
                              disableClearable={
                                formik.values.yearsOfExperience ? false : true
                              }
                              id="yearsOfExperience"
                              name="yearsOfExperience"
                              value={formik.values.yearsOfExperience}
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "yearsOfExperience",
                                  newValue
                                );
                              }}
                              onBlur={formik.handleBlur}
                              options={experience}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  name="yearsOfExperience"
                                  placeholder={t(
                                    "Enter_Years_of_experience_in_this_area"
                                  )}
                                  error={
                                    formik.touched.yearsOfExperience &&
                                    Boolean(formik.errors.yearsOfExperience)
                                  }
                                  helperText={
                                    formik.touched.yearsOfExperience &&
                                    formik.errors.yearsOfExperience
                                  }
                                />
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl> : null}
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Current_Location1")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_Current_Location1")}
                              id="currentLocation"
                              name="currentLocation"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'., ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "currentLocation",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.currentLocation}
                              error={
                                formik.touched.currentLocation &&
                                Boolean(formik.errors.currentLocation)
                              }
                              helperText={
                                formik.touched.currentLocation &&
                                formik.errors.currentLocation
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Address-1")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_Your_address")}
                              id="officialAddress"
                              name="officialAddress"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,/#&: ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "officialAddress",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.officialAddress}
                              error={
                                formik.touched.officialAddress &&
                                Boolean(formik.errors.officialAddress)
                              }
                              helperText={
                                formik.touched.officialAddress &&
                                formik.errors.officialAddress
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Current_employer")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t("Enter_Current_employer")}
                              id="currentEmployer"
                              name="currentEmployer"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,&/ ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "currentEmployer",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.currentEmployer}
                              error={
                                formik.touched.currentEmployer &&
                                Boolean(formik.errors.currentEmployer)
                              }
                              helperText={
                                formik.touched.currentEmployer &&
                                formik.errors.currentEmployer
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t("Designation(Current employer)")}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t(
                                "Enter_Designation(Current employer)"
                              )}
                              id="Designation"
                              name="Designation"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,&/ ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue("Designation", value);
                                }
                              }}
                              value={formik.values.Designation}
                              error={
                                formik.touched.Designation &&
                                Boolean(formik.errors.Designation)
                              }
                              helperText={
                                formik.touched.Designation &&
                                formik.errors.Designation
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t(
                                "Membership_in_Professional_Societies/Associations"
                              )}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t(
                                "Enter_Membership_in_Professional_Societies/Associations"
                              )}
                              id="membershipInProfessional"
                              name="membershipInProfessional"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,&/ ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "membershipInProfessional",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.membershipInProfessional}
                              error={
                                formik.touched.membershipInProfessional &&
                                Boolean(formik.errors.membershipInProfessional)
                              }
                              helperText={
                                formik.touched.membershipInProfessional &&
                                formik.errors.membershipInProfessional
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                              {t(
                                "Other_Professional_Certifications_or_Trainings"
                              )}
                            </FormLabel>
                            <TextField
                              required
                              fullWidth
                              placeholder={t(
                                "Enter_Other_Professional_Certifications_or_Trainings"
                              )}
                              id="professionalCertifications"
                              name="professionalCertifications"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                if (e) {
                                  const value = e.target.value.replace(
                                    /[^a-z0-9'.,&/ ]/gi,
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "professionalCertifications",
                                    value
                                  );
                                }
                              }}
                              value={formik.values.professionalCertifications}
                              error={
                                formik.touched.professionalCertifications &&
                                Boolean(
                                  formik.errors.professionalCertifications
                                )
                              }
                              helperText={
                                formik.touched.professionalCertifications &&
                                formik.errors.professionalCertifications
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      
                      <FormControl
                        fullWidth
                        sx={{ mt: 3 }}
                        className="MentorReactQuill"
                      >
                        <FormLabel sx={{ fontWeight: "bold" }}>
                          {t("Experience_in_providing_guidance")}
                        </FormLabel>
                      </FormControl>
                      <Grid item xs={12} md={12} lg={12}>
                        <ReactQuill
                          value={mentorExperience}
                          onChange={(value) => {
                            formik.setFieldValue("Experience", value);
                            setMentorExperience(value);
                          }}
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline", "strike"],
                              ["blockquote", "code-block"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [{ color: [] }, { background: [] }],
                              [{ font: [] }],
                              [{ align: [] }],
                              ["clean"],
                            ],
                          }}
                          error={
                            formik.touched.Experience &&
                            Boolean(formik.errors.Experience)
                          }
                          helperText={
                            formik.touched.Experience &&
                            formik.errors.Experience
                          }
                          placeholder={t(
                            "Experience_in_providing_guidance_placeholder"
                          )}
                          className="scrollable-container-Mentor"
                        />
                      </Grid>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={
                          formik.isValid &&
                          formik.dirty &&
                          isSubmitting === false &&
                          (mentorExperience === "<p><br></p>") === false
                            ? false
                            : true
                        }
                      >
                        {t("Update4")}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default MentorUpdateForm;
