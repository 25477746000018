import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../../api/useAxios";
import axios from "axios";
import "./JobSeekerRegForm.css";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { FormHelperText, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import Popper from "@mui/material/Popper";
import { useLocationFind } from "../LocationFind";
import PersonIcon from "@mui/icons-material/Person";
import leftimg from "../../../img/Left.png";
import EmailIcon from "@mui/icons-material/Email";
import Stack from "@mui/material/Stack";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import svgmale from "../../../img/male 1.png";
import svggirl from "../../../img/Group 1.png";
import Student from "../../../img/student-reading 1.png";
import Graduate from "../../../img/graduate 2.png";
import Industry from "../../../img/economy 2.png";
import Location from "../../../img/location (1) 1.png";
import pincodeimg from "../../../img/navigation-2.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import svgmaleActive from "../../../img/male-Active.png";
import svggirlActive from "../../../img/femaleActive.png";
import StudentActive from "../../../img/student-reading-active.png";
import GraduateActive from "../../../img/graduate-active.png";
import Graduateworksatus from "../../../img/graduate-work-status.png";
import workexstatus from "../../../img/work-ex-status.png";
import workactive from "../../../img/work-active.png";
import fresher from "../../../img/graduate-2-active.png";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import districtActive from "../../../img/location-active.png";
import leftTamil from "../../../img/Left-tamil.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sussesfullimg from "../../../img/Registered-img.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let response = "";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function JobSeekerRegForm() {
  const ref = React.createRef(null);
  const navigate = useNavigate();
  // const theme = useTheme();
  //  translation
  const { t, i18n, ready } = useTranslation();
  const { locationData, locatioError } = useLocationFind();
  const axiosData = useAxios();
  const [WorkType, SetWorktype] = useState("Fresher");
  const [active, setActive] = useState(null);
  const [graduationType, SetgraduationType] = useState("Student");
  const [data, setData] = useState(false);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");
  const [open, setOpen] = useState(false);
  let [mobileError, setmobileError] = useState("");
  let [emailError, setEmailError] = useState(true);
  const [valid, setvalid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusout, setfocus] = useState();
  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [focusb, setfocusb] = useState();
  const [focusPin, setFocusPin] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [industryArray, setIndustryArray] = useState();

  const storedArea = localStorage.getItem("currentLocationArea");
  const storedPincode = localStorage.getItem("currentLocationPincode");
  const storedState = localStorage.getItem("currentLocationState");
  const storedCountry = localStorage.getItem("currentLocationCountry");

  const handleClick1 = () => {
    setOpen(true);
  };
  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [experience, setExperience] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const MAX_FILE_SIZE = 1048576 * 10;
  const errors = {};
  const error = () => {
    errors.email_id = `email is exist `;
  };
  const [alertm, setalert] = useState(false);
  const [alerte, setalerte] = useState(false);
  useEffect(() => {
    if (alertm !== false) {
      setMobilePopUp(true);
      formik.setFieldValue("mobile_number", "");
    }
    setalert(false);
  }, [alertm]);
  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
      formik.setFieldValue("email_id", "");
    }
    setalerte(false);
  }, [alerte]);
  const validate = async (values) => {
    const errors = {};

    if (!values.mobile_number) {
      errors.mobile_number = t("Mobile_Number_must_be_Required");
      setfocusb(true);
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setvalid(true);
    } else if (/^\d{10}$/.test(values.mobile_number)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `users/Mobile/${values.mobile_number}`
          );

          if (response.status === 200) {
            setalert(true);
          } else if (response.status === 204) {
            setalert(false);
          }

          setvalid(false);

          return () => controller.abort();
        } catch (error) {}
      }
    }

    return errors;
  };
  const fileInputField = useRef(null);
  const reader = new FileReader();
  const [selectedIndustry, setSelectedIndustry] = useState();
  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
      setResume(false);
    } else {
      setResume(e.target.files[0]);
      setFile(e.target.files[0].name);
    }
    setData(e.target.files[0].size);
  };
  const deleteFile = () => {
    fileInputField.current.value = "";
    setFile(null);
    setData(null);
    setResume(null);
    formik.setFieldValue("fileUpload", null);
  };
  const formik = useFormik({
    initialValues: {
      full_name: "",
      gender: "Male",
      email_id: "",
      mobile_number: "",
      industry: "",
      Experience: "",
      work_status: "Fresher",
      currentLocation: "",
      votingDistrict: "",
      mpConstituency: "",
      mlaConstituency: "",
      pincode: "",
      fileUpload: null,
      graduation: "Student",
      area_name: "",
      pincode_num: "",
      state: "Tamil Nadu",
      country: "",
    },
    validate,
    validationSchema: Yup.object({
      full_name: Yup.string()
        .min(3, t("Must_be_3"))
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("FullName_must_be_Required"))
        .matches(/^[^'].*[^']$/, t("Invalid_format"))
        .test(t("no_repeated_single_quotes"), t("String_cannot"), (value) => {
          return !/^'{2,}(?:\s*'{1,}[^']*')*'$/.test(value);
        })
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "' ' ' '";
          }
        )
        .test(
          t("no_repeated_single_quotes"),
          t("String_must_contain"),
          (value) => {
            return value !== "''";
          }
        ),
      gender: Yup.string().required("gender must be Required"),
      mobile_number: Yup.number().required(t("Mobile_Number_must_be_Required")),
      industry: Yup.string().required(t("Select_Industry")),
      currentLocation: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Current_Location_must_be_Required")),
      votingDistrict: Yup.string().required(t("Select_District")),
      mpConstituency: Yup.string().required(t("Select_MP_Constituency")),
      mlaConstituency: Yup.string().required(t("Select_MLA_Constituency")),
      pincode: Yup.string().required(t("pincode_is_Required")),
      Experience:
        WorkType === "Experienced"
          ? Yup.string().required(t("Experience_is_required"))
          : Yup.string(),
      graduation: Yup.string().required("graduation must be Required"),
      state: Yup.string().required("state must be Required"),
    }),
    onSubmit: (values) => {},
  });
  const submitForm = (values) => {
    let mla = values.mlaConstituency;
    let mla2 = mla.replace(/[-\s.,]+/g, "");
    values.mlaConstituency = mla2;

    if (resume) {
      values.fileUpload = resume;
      values.email_id = formEmail;
    }
    if (graduationType) {
      values.work_status = WorkType;
    }
    if (pincodeNumData) {
      values.pincode_num = pincodeNumData[0];
      values.area_name = pincodeNumData[1];
    }
    if (storedCountry) {
      values.country = storedCountry;
    }
    setIsSubmitting(true);
    axiosData
      .post("seekers", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        axiosData.post(
          "users",
          {
            usertype: "seeker",
            status: "InActive",
            uniqueId: values.mobile_number,
            password: values.mobile_number,
            email_id: values.email_id,
            user_id: res.data.user_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setIsSubmitting(false);
        formik.handleReset("");
        deleteFile();
        // navigate(
        //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
        // );
        handleClickOpened();
        setFile(false);
        handleClick1();
      })
      .catch((err) => console.log(err));
  };
  const handleEmailBlur = () => {
    if (!formEmail) {
      setFormEmailError(t("EmailId_must_be_Required"));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formEmail)) {
      setFormEmailError(t("Invalid_email_format"));
    } else {
      setFormEmailError("");
    }
  };
  const handleChange = (e) => {
    setfocusb(false);
    const { value } = e.target;
    if (value) {
      formik.handleChange(e);
    }
  };

  const handleChange1 = (e) => {
    setFocusPin(false);
    const { value } = e.target;
    if (value.length <= 6) {
      formik.handleChange(e);
    }
  };

  const handleEmailChange = async (event) => {
    const email_id = event.target.value
      .replace(/[^a-z0-9@.]/gi, "")
      .toLowerCase();
    setFormEmail(email_id);
    if (!email_id) {
      setfocus(true);
      setFormEmailError(t("EmailId_must_be_Required"));
      errors.email_id = t("EmailId_must_be_Required");
      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setfocus(false);
      setFormEmailError(t("Invalid_email_format"));
      errors.email_id = t("Invalid_email_format");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setEmailError(true);
      if (emailError) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });

          if (response.status === 200) {
            setalerte(true);
          } else if (response.status === 204) {
            formik.setFieldValue("email_id", event.target.value);

            setFormEmailError("");
          }
        } catch (error) {
          formik.setFieldValue("email_id", event.target.value);

          setFormEmailError("");
        }
      }
    }
  };
  const [mpConstituency, setMP] = useState({
    code: 4,
    District: "",
    ParlimentConstituency: [],
  });
  var [mlaConstituency, setMLA] = useState([]);

  const [districtData, setDistrictData] = useState([]);
  const [district, setDistrict] = useState([]);
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const votingDistrictOthers = async (state) => {
    const stateValue = state === "" ? "all" : state;
    try {
      const getOneAxios = await axiosData.get(
        `/statewiseDistrict/${stateValue}`
      );
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const PuducherryState = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/Puducherry`);
      const data = getOneAxios?.data?.collections?.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });

        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  // useEffect(() => {
  //   if (formik.values.state === "Tamil Nadu") {
  //     vodingDistrict();
  //   }
  //   if (formik.values.state === "Puducherry") {
  //     PuducherryState();
  //   }
  // }, [formik.values.state]);

  const mlaSelection = (value) => {
    formik.setFieldValue("mpConstituency", "");
    formik.setFieldValue("mlaConstituency", "");
    if (district.length > 0) {
      district.map((element) => {
        if (element.District === value) {
          setMLA([]);
          setMP({ ...element });
        }
      });
    } else {
    }
  };
  const lang = sessionStorage.getItem("lang");

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;
      if (data) {
        const datasexp = data.filter((values) => values != "0");
        setExperience(datasexp);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const searchobj = async (values) => {
    // setAdDistrict(values);

    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(`/General/${values}/pincode`);
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.pincode;
        });
        const getpost = datapin.map((i) => {
          return i.officeName;
        });
        const getpost1 = getpost;
        const result = getpin.reduce((accumulator, currentPincode, index) => {
          return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
        }, []);
        const getPostFinal = result.map((i) => {
          return i;
        });
        setPincodeData(getpin);
        setPostOfficeData(getpost);
        setPincode(datapin);
        setComdata([...getPostFinal]);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const [adDistrict, setAdDistrict] = useState([]);
  const [PincodeData, setPincodeData] = useState([]);
  const [PostOfficeData, setPostOfficeData] = useState([]);
  const [newPincode, setPincode] = useState([]);
  const [comdata, setComdata] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);

  const areaPincode = async (values) => {
    setAdDistrict(values);
    try {
      setpinLoad(true);
      let getOneAxiospin = [];

      if (formik.values.state === "Tamil Nadu") {
        let getOneAxiospin1 = await axiosData.get(
          `/distritarea/getArea/${values}`
        );
        getOneAxiospin = getOneAxiospin1.data;
      } else {
        const getOnepinAxios = await axiosData.get(
          `/General/PuducherryPostoffice`
        );
        const data = getOnepinAxios?.data?.collections?.data;

        if (data) {
          const getDistrictPin = data.filter((i) => {
            return i.District === values;
          });
          getOneAxiospin = [...getDistrictPin];
        }
      }
      setpinLoad(false);
      const datapin = getOneAxiospin;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        setPincodeData(getpin[0]);
        setPostOfficeData(getpost[0]);
        setPincode(datapin);
        setComdata([...getPostFinal]);
      }

      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (
      (storedArea === "" || storedArea === null || storedArea === undefined) &&
      (storedPincode === "" ||
        storedPincode === null ||
        storedPincode === undefined) &&
      (storedCountry === "" ||
        storedCountry === null ||
        storedCountry === undefined) &&
      (storedState === "" || storedState === null || storedState === undefined)
    ) {
      formik.setFieldValue("currentLocation", "");
    } else {
      //formik.setFieldValue("currentLocation", `${storedArea},${storedPincode}`);
    }
  }, [locationData]);

  useEffect(() => {
    if (storedState === "Tamil Nadu") {
      vodingDistrict();
    } else if (storedState === "Puducherry") {
      PuducherryState();
    } else {
      votingDistrictOthers(storedState);
    }
    IndustryData();
    experienceData();
  }, []);
  const fetchCountryData = async (pincodeValue) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincodeValue}`
      );
      if (response.data && response.data[0].PostOffice) {
        setpinLoad(true);
        const datapin = response.data[0].PostOffice;
        const postalCodes = datapin.filter(
          (postOffice) =>
            postOffice.District.toLowerCase() ===
            formik.values.votingDistrict.toLowerCase()
        );
        if (postalCodes.length > 0) {
          if (datapin) {
            const getpin = datapin.map((i) => {
              return i.Pincode;
            });
            const getpost = datapin.map((i) => {
              return i.Name;
            });
            const getpost1 = getpost;
            const result = getpin.reduce(
              (accumulator, currentPincode, index) => {
                return accumulator.concat(
                  `${currentPincode}/${getpost1[index]}`
                );
              },
              []
            );
            const getPostFinal = result.map((i) => {
              return i;
            });
            setPincodeData(getpin);
            setPostOfficeData(getpost);
            setPincode(datapin);
            setComdata([...getPostFinal]);
            setpinLoad(false);
          }
        } else {
          setPincodeData([]);
          setPostOfficeData([]);
          setPincode([]);
          setComdata([]);
          setpinLoad(false);
        }
      } else {
        console.log("No postal codes found for the specified district.");
      }
    } catch (error) {
      console.error("Error fetching postal codes:", error.message);
    }
  };
  const handleClickWorkType = (e, id) => {
    e.preventDefault();
    setActive(id);
    if (id === "6") {
      SetWorktype("Fresher");
      formik.setFieldValue("Experience", "");
    } else if (id === "1") {
      SetWorktype("Experienced");
    }
  };

  useEffect(() => {
    if (formik.values.graduation === "Student") {
      SetWorktype("Fresher");
      formik.setFieldValue("Experience", "");
      setActive("6");
    }
    SetgraduationType(formik.values.graduation);
  }, [formik.values.graduation]);

  const handleStudentClick = () => {
    SetWorktype("Fresher");
    formik.setFieldValue("Experience", "");
    setActive("6");
  };

  const [pincodeNumData, setPincodeNumData] = useState([]);
  const [pincodeValue, setPincodeValue] = useState("");

  useEffect(() => {
    if (pincodeValue) {
      const parts = pincodeValue.split("/");
      if (parts.length === 2) {
        setPincodeNumData(parts);
      }
    }
  }, [formik.values.pincode]);

  const themeds = useTheme();
  const [openok, setOpenok] = React.useState(false);
  const fullScreen = useMediaQuery(themeds.breakpoints.down("md"));

  const handleClickOpened = () => {
    setOpenok(true);
  };

  const handleCloseed = () => {
    setOpenok(false);
    navigate(ROUTINGDATA.LAND);
  };

  if (ready) {
    return (
      <>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Email_Id_already_exist1")}
              {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}
              {t("This_Email_Id_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Mobile_number_already_exist1")}
              {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}
              {t("This_Mobile_number_already_exist2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="overall-jobseeker-form">
          <div className="left-jobseekers-img">
            <div style={{ visibility: "hidden" }}>fsdfsd</div>
            <div className="leftimg-box">
              <img src={lang === "tamil" ? leftTamil : leftimg} alt="img" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="jobseeker-form-box">
              <div className="jobseeker-form-header">
                <h2>
                  <span>{t("JobSeeker")}</span> {t("Registration")}
                </h2>
              </div>
              <div className="jobseeker-box-reg-form">
                <div className="jobseeker-box-inputs">
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Full_Name")}
                      <text>*</text>
                    </label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="full_name"
                      required
                      variant="outlined"
                      size="small"
                      name="full_name"
                      placeholder={t("Enter_Your_Full_Name")}
                      onBlur={formik.handleBlur}
                      fullWidth
                      onChange={(e) => {
                        if (e) {
                          const name = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("full_name", name);
                        }
                      }}
                      value={formik.values.full_name}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.full_name && formik.errors.full_name ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.full_name}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ visibility: "hidden", fontSize: "13px" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Email_Id")}
                      <text>*</text>
                    </label>
                    {/* <Tooltip title="Enter your Email" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="email_id"
                      variant="outlined"
                      size="small"
                      required
                      ref={ref}
                      onBlurCapture={() => {
                        setfocus(true);
                      }}
                      name="formEmail"
                      placeholder={t("Enter_Your_Email_ID")}
                      onBlur={handleEmailBlur}
                      onChange={handleEmailChange}
                      value={formEmail}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formEmailError && focusout ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formEmailError}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Mobile_Number")}
                      <text>*</text>
                    </label>
                    {/* <Tooltip title="Enter Your Mobile Number" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      required
                      id="mobile_number"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="mobile_number"
                      placeholder={t("Enter_Your_Mobile_Number")}
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onBlurCapture={() => {
                        setfocusb(true);
                      }}
                      onBlur={formik.handleBlur}
                      onChange={handleChange}
                      value={formik.values.mobile_number}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "medium",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              +91
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {mobileError && focusb ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {mobileError}
                        </Typography>
                      ) : formik.touched.mobile_number &&
                        formik.errors.mobile_number &&
                        focusb ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.mobile_number}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Gender")}
                      <text>*</text>
                    </label>
                    <div className="jobseeker-btn-img">
                      <Stack direction="row" className="mobile-for-gender">
                        <Button
                          className={
                            formik.values.gender === "Male"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("gender", "Male");
                          }}
                        >
                          {formik.values.gender === "Male" ? (
                            <img src={svgmaleActive} />
                          ) : (
                            <img src={svgmale} />
                          )}
                          <span
                            className={
                              formik.values.gender === "Male"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Male")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.gender === "Female"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("gender", "Female");
                          }}
                        >
                          {formik.values.gender === "Female" ? (
                            <img className="girlimg" src={svggirlActive} />
                          ) : (
                            <img className="girlimg" src={svggirl} />
                          )}
                          <span
                            className={
                              formik.values.gender === "Female"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Female")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.gender === "Other"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          startIcon={
                            formik.values.gender === "Other" ? (
                              <DoNotDisturbIcon className="gendericon-Active" />
                            ) : (
                              <DoNotDisturbIcon className="gendericon" />
                            )
                          }
                          onClick={(e) => {
                            formik.setFieldValue("gender", "Other");
                          }}
                        >
                          <span
                            className={
                              formik.values.gender === "Other"
                                ? "btn-space-name-1-active"
                                : "btn-space-name-1"
                            }
                          >
                            {t("Other")}
                          </span>
                        </Button>
                      </Stack>
                    </div>
                    <FormHelperText>
                      <div style={{ visibility: "hidden" }}>wsdwdqw</div>
                    </FormHelperText>
                  </div>
                  <div
                    className="jobseeker-textfield"
                    style={{ marginBottom: "10px" }}
                  >
                    <label>
                      {t("Graduation")}
                      <text>*</text>
                    </label>
                    <div className="jobseeker-btn-img">
                      <Stack direction="row" spacing={2}>
                        <Button
                          className={
                            formik.values.graduation === "Student"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("graduation", "Student");
                            formik.setFieldValue("work_status", "Fresher");
                          }}
                        >
                          {formik.values.graduation === "Student" ? (
                            <img src={StudentActive} />
                          ) : (
                            <img src={Student} />
                          )}
                          <span
                            className={
                              formik.values.graduation === "Student"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Student")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.graduation === "Graduate"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            formik.setFieldValue("graduation", "Graduate");
                            formik.setFieldValue("work_status", "Fresher");
                          }}
                        >
                          {formik.values.graduation === "Graduate" ? (
                            <img src={GraduateActive} />
                          ) : (
                            <img src={Graduate} />
                          )}
                          <span
                            className={
                              formik.values.graduation === "Graduate"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Graduate")}
                          </span>
                        </Button>
                      </Stack>
                    </div>
                    {/* <FormHelperText>
                      {mobileError && focusb ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          {mobileError}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          {formik.errors.mobile_number}
                        </Typography>
                      )}
                    </FormHelperText> */}
                    <FormHelperText>
                      <div style={{ visibility: "hidden" }}>wsdwdqw</div>
                    </FormHelperText>
                  </div>{" "}
                  {formik.values.graduation === "Graduate" ? (
                    <div
                      className="jobseeker-btn-img"
                      style={{ marginBottom: "20px", marginTop: "-13px" }}
                    >
                      <label className="Work_Status">
                        {t("Work_Status")}
                        <text>*</text>
                      </label>
                      <Stack
                        direction="row"
                        sx={{ marginTop: "5px" }}
                        spacing={2}
                        className="mobile-for-gender"
                      >
                        <Button
                          size="small"
                          className={
                            formik.values.work_status === "Fresher"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            handleClickWorkType(e, "6");
                            formik.setFieldValue("work_status", "Fresher");
                          }}
                        >
                          {formik.values.work_status === "Fresher" ? (
                            <img src={fresher} />
                          ) : (
                            <img src={Graduateworksatus} />
                          )}

                          <span
                            className={
                              formik.values.work_status === "Fresher"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("I'm_a_Fresher")}
                          </span>
                        </Button>

                        <Button
                          size="small"
                          className={
                            formik.values.work_status === "Experienced"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(e) => {
                            handleClickWorkType(e, "1");
                            formik.setFieldValue("work_status", "Experienced");
                          }}
                        >
                          {formik.values.work_status === "Experienced" ? (
                            <img src={workactive} />
                          ) : (
                            <img src={workexstatus} />
                          )}

                          <span
                            className={
                              formik.values.work_status === "Experienced"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {" "}
                            {t("I'm_Experienced")}
                          </span>
                        </Button>
                      </Stack>
                    </div>
                  ) : null}
                  {formik.values.graduation === "Graduate" &&
                  WorkType === "Experienced" ? (
                    <div className="jobseeker-textfield">
                      <Autocomplete
                        disableClearable={
                          formik.values.Experience ? false : true
                        }
                        size="small"
                        name="Experience"
                        id="Experience"
                        sx={{ backgroundColor: "#ffff" }}
                        options={experience}
                        value={formik.values.Experience}
                        onChange={(event, newValue) => {
                          formik.setFieldValue("Experience", newValue);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("Work_Experience")}
                          />
                        )}
                      />
                      <FormHelperText>
                        {formik.touched.Experience &&
                        formik.errors.Experience ? (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors.Experience}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "13px",
                              visibility: "hidden",
                            }}
                          >
                            none
                          </Typography>
                        )}
                      </FormHelperText>
                    </div>
                  ) : null}
                  {industryArray && (
                    <div className="jobseeker-textfield">
                      <label>
                        {t("Select_Industry")}
                        <text>*</text>
                      </label>
                      {industryArray && (
                        <>
                          <Autocomplete
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            disableClearable={
                              formik.values.industry ? false : true
                            }
                            filterOptions={filterOptions}
                            size="small"
                            id="industry"
                            name="industry"
                            options={industryArray}
                            onBlur={formik.handleBlur}
                            onChange={(event, value) => {
                              setSelectedIndustry(value);
                              formik.setFieldValue("industry", value);
                            }}
                            value={formik.values.industry}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                placeholder={t("Preffered_Industry")}
                                id="industry"
                                name="industry"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        className="Industryimg"
                                        src={Industry}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                // onClick={() => params.inputProps.onInputClick()}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: { maxHeight: "180px", overflowY: "auto" },
                            }}
                          />
                          <FormHelperText>
                            {formik.touched.industry &&
                            formik.errors.industry ? (
                              <Typography
                                sx={{ color: "red", fontSize: "13px" }}
                              >
                                {formik.errors.industry}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  color: "red",
                                  fontSize: "13px",
                                  visibility: "hidden",
                                }}
                              >
                                none
                              </Typography>
                            )}
                          </FormHelperText>
                        </>
                      )}
                    </div>
                  )}
                  <div className="jobseeker-textfield">
                    <label>
                      {t("Current_Location")}
                      <text>*</text>
                    </label>
                    {/* <Tooltip title="Enter your Current Location" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="email_id"
                      variant="outlined"
                      size="small"
                      name="currentLocation"
                      placeholder={t("Mention_the_city_you_live_in")}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value;
                          formik.setFieldValue("currentLocation", value);
                        }
                      }}
                      value={formik.values.currentLocation}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <MyLocationIcon sx={{ color: "#5C6BF4" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formik.touched.currentLocation &&
                      formik.errors.currentLocation ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.currentLocation}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  {storedCountry === "India" ||  (storedCountry === "" ||
        storedCountry === null ||
        storedCountry === undefined) ? (
                    <div className="jobseeker-box-voitingdetails">
                      <label className="header-voting">
                        {t("Voting_Details")}
                        <text>*</text>
                      </label>
                      {/* <div className="jobseeker-textfield">
                      <label>{t("select_state")}</label>
                      <div className="jobseeker-btn-img">
                        <Stack
                          direction="row"
                          className="mobile-for-Work-Status"
                        >
                          <Button
                            className={
                              formik.values.state === "Tamil Nadu"
                                ? "jobseeker-btn-gender-active"
                                : "jobseeker-btn-gender"
                            }
                            variant="outlined"
                            onClick={(event) => {
                              const value = "Tamil Nadu";
                              formik.setFieldValue("state", value);
                              formik.setFieldValue("votingDistrict", "");
                              formik.setFieldValue("mpConstituency", "");
                              formik.setFieldValue("mlaConstituency", "");
                              formik.setFieldValue("pincode", "");
                            }}
                          >
                            {formik.values.state === "Tamil Nadu" ? (
                              <img src={districtActive} />
                            ) : (
                              <img src={Location} />
                            )}{" "}
                            <span
                              className={
                                formik.values.state === "Tamil Nadu"
                                  ? "btn-space-name-active"
                                  : "btn-space-name"
                              }
                            >
                              {t("Tamil_Nadu")}
                            </span>
                          </Button>
                          <Button
                            className={
                              formik.values.state === "Puducherry"
                                ? "jobseeker-btn-gender-active"
                                : "jobseeker-btn-gender"
                            }
                            variant="outlined"
                            onClick={(event) => {
                              const value = "Puducherry";
                              formik.setFieldValue("state", value);
                              formik.setFieldValue("votingDistrict", "");
                              formik.setFieldValue("mpConstituency", "");
                              formik.setFieldValue("mlaConstituency", "");
                              formik.setFieldValue("pincode", "");
                            }}
                          >
                            {formik.values.state === "Puducherry" ? (
                              <img src={districtActive} />
                            ) : (
                              <img src={Location} />
                            )}{" "}
                            <span
                              className={
                                formik.values.state === "Puducherry"
                                  ? "btn-space-name-active"
                                  : "btn-space-name"
                              }
                            >
                              {t("Puducherry")}{" "}
                            </span>
                          </Button>
                        </Stack>
                        <FormHelperText>
                          {formik.touched.state && formik.errors.state ? (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.state}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "13px",
                                visibility: "hidden",
                              }}
                            >
                              none
                            </Typography>
                          )}
                        </FormHelperText>
                      </div>
                    </div> */}

                      <div className="jobseeker-textfield">
                        <label>
                          {t("District")}
                          <text>*</text>
                        </label>

                        <Autocomplete
                          size="small"
                          required
                          sx={{ marginTop: "8px", backgroundColor: "white" }}
                          disableClearable={!formik.values.votingDistrict}
                          filterOptions={filterOptions}
                          name="votingDistrict"
                          id="votingDistrict"
                          value={formik.values.votingDistrict}
                          onChange={(e, newValue, reason) => {
                            if (reason === "clear" || newValue === "") {
                              formik.setFieldValue("votingDistrict", "");
                              formik.setFieldValue("mpConstituency", "");
                              formik.setFieldValue("mlaConstituency", "");
                              formik.setFieldValue("pincode", "");
                            } else {
                              //searchobj(newValue);
                              formik.setFieldValue("votingDistrict", newValue);
                              formik.setFieldValue("mpConstituency", "");
                              formik.setFieldValue("mlaConstituency", "");
                              mlaSelection(newValue);
                              // areaPincode(newValue);
                              formik.setFieldValue("pincode", "");
                              formik.setFieldError("votingDistrict", " ");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={districtData}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              placeholder={t("Select_District")}
                              name="votingDistrict"
                            />
                          )}
                          onOpen={(event) => event.preventDefault()}
                          PopperComponent={({ children, ...popperProps }) => (
                            <Popper
                              {...popperProps}
                              placement="bottom-start"
                              modifiers={{
                                flip: {
                                  enabled: false,
                                },
                                preventOverflow: {
                                  enabled: true,
                                  boundariesElement: "scrollParent",
                                },
                              }}
                              PopperProps={{
                                placement: "bottom-start",
                              }}
                            >
                              <Paper>{children}</Paper>
                            </Popper>
                          )}
                          ListboxProps={{
                            style: { maxHeight: "180px", overflowY: "auto" },
                          }}
                        />
                        <FormHelperText>
                          {formik.touched.votingDistrict &&
                          formik.errors.votingDistrict &&
                          !formik.values.votingDistrict ? (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.votingDistrict}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "13px",
                                visibility: "hidden",
                              }}
                            >
                              none
                            </Typography>
                          )}
                        </FormHelperText>
                      </div>

                      <div className="jobseeker-textfield">
                        <label>
                          {t("MP_Constituency")}
                          <text>*</text>
                        </label>

                        <Autocomplete
                          required
                          disableClearable={!formik.values.mpConstituency}
                          disabled={formik.values.votingDistrict ? false : true}
                          size="small"
                          name="mpConstituency"
                          id="mpConstituency"
                          sx={{ marginTop: "8px", backgroundColor: "white" }}
                          filterOptions={filterOptions}
                          onBlur={formik.handleBlur}
                          options={mpConstituency?.ParlimentConstituency?.sort()}
                          onChange={(e, newValue, reason) => {
                            if (reason === "clear" || newValue === "") {
                              formik.setFieldValue("mpConstituency", "");
                              formik.setFieldValue("mlaConstituency", "");
                            } else {
                              mlaConstituency = [];
                              formik.setFieldValue("mpConstituency", newValue);
                              formik.setFieldError("mpConstituency", " ");
                              mpConstituency[newValue].map((element) => {
                                mlaConstituency.push(element);
                                setMLA([...mlaConstituency]);
                                formik.setFieldValue("mlaConstituency", "");
                              });
                            }
                          }}
                          value={formik.values.mpConstituency}
                          renderInput={(params) => (
                            <TextField
                              required
                              name="mpConstituency"
                              {...params}
                              placeholder={t("Select_MP_Constituency")}
                            />
                          )}
                        />
                        <FormHelperText>
                          {formik.touched.mpConstituency &&
                          formik.errors.mpConstituency &&
                          !formik.values.mpConstituency ? (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.mpConstituency}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "13px",
                                visibility: "hidden",
                              }}
                            >
                              none
                            </Typography>
                          )}
                        </FormHelperText>
                      </div>

                      <div className="jobseeker-textfield">
                        <label>
                          {t("MLA_Constituency")}
                          <text>*</text>
                        </label>

                        <Autocomplete
                          required
                          name="mlaConstituency"
                          size="small"
                          disableClearable={!formik.values.mlaConstituency}
                          disabled={formik.values.mpConstituency ? false : true}
                          sx={{ marginTop: "8px", backgroundColor: "white" }}
                          filterOptions={filterOptions}
                          options={mlaConstituency?.sort()}
                          id="mlaConstituency"
                          label="mlaConstituency"
                          onBlur={formik.handleBlur}
                          onChange={(e, newValue, reason) => {
                            if (reason === "clear" || newValue === "") {
                              formik.setFieldValue("mlaConstituency", "");
                            } else {
                              formik.setFieldValue("mlaConstituency", newValue);
                              formik.setFieldError("mlaConstituency", "");
                            }
                          }}
                          value={formik.values.mlaConstituency}
                          renderInput={(params) => (
                            <TextField
                              required
                              name="mlaConstituency"
                              {...params}
                              placeholder={t("Select_MLA_Constituency")}
                            />
                          )}
                        />
                        <FormHelperText>
                          {formik.touched.mlaConstituency &&
                          formik.errors.mlaConstituency &&
                          !formik.values.mlaConstituency ? (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.mlaConstituency}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "13px",
                                visibility: "hidden",
                              }}
                            >
                              none
                            </Typography>
                          )}
                        </FormHelperText>
                      </div>

                      <div className="jobseeker-textfield">
                        <label>
                          {t("Pincode")}
                          <text>*</text>
                        </label>

                        <Autocomplete
                          size="small"
                          disabled={
                            formik.values.votingDistrict && !pinLoad
                              ? false
                              : true
                          }
                          required
                          disableClearable={
                            formik.values.pincode ? false : true
                          }
                          sx={{ marginTop: "8px", backgroundColor: "white" }}
                          id="pincode"
                          name="pincode"
                          filterOptions={filterOptions}
                          options={comdata?.sort()}
                          onBlur={formik.handleBlur}
                          onChange={(event, value) => {
                            formik.setFieldValue("pincode", value);
                            setPincodeValue(value);
                          }}
                          value={formik.values.pincode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              name="pincode"
                              onChange={(e) => {
                                if (e.target.value.length >= 5) {
                                  fetchCountryData(e.target.value);
                                }
                              }}
                              placeholder={t("Select_Your_Pin_Code")}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {pinLoad ? (
                                      <CircularProgress
                                        style={{
                                          width: "1.4rem",
                                          height: "1.4rem",
                                          color: "#4991dd",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="Industryimg"
                                        src={pincodeimg}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <FormHelperText>
                          {formik.touched.pincode && formik.errors.pincode ? (
                            <Typography sx={{ color: "red", fontSize: "13px" }}>
                              {formik.errors.pincode}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "13px",
                                visibility: "hidden",
                              }}
                            >
                              none
                            </Typography>
                          )}
                        </FormHelperText>
                      </div>
                    </div>
                  ) : null}
                  {file ? (
                    <div className="uploadfilenames-and-removefilebtn">
                      <div
                        className={
                          resume
                            ? "filename-uploadresume"
                            : "filename-uploadresume-error"
                        }
                      >{`${file}`}</div>
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          className="removefile-btn"
                          onClick={() => {
                            deleteFile();
                          }}
                          endIcon={<CloseIcon sx={{ color: "#FF3434" }} />}
                        >
                          {t("Remove_Resume")}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <input
                      className="resumefile"
                      id="file-upload"
                      type="file"
                      style={{ visibility: "hidden" }}
                      accept=".docx,.doc,.pdf,"
                      onChange={handleFileChange}
                      ref={fileInputField}
                      required
                    />
                    {file ? null : (
                      <div className="uploade-note-1">{t("File_must_be")}</div>
                    )}
                    <Button
                      disabled={file ? true : false}
                      htmlFor="file"
                      component="label"
                      variant="contained"
                      fullWidth
                      className="upload-jobseeker-regform"
                      startIcon={<CloudUploadIcon />}
                      onClick={() => {
                        fileInputField.current.click();
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fileUpload}
                    >
                      {t("Upload_Resume")}
                      {/* <VisuallyHiddenInput type="file" /> */}
                    </Button>

                    <div className="uploade-note">
                      {t("Candidates_who_have_an_impressive_resume")}
                    </div>
                    <div className="uploade-note" style={{ marginTop: "4px" }}>
                      {t("You_accept_the")}{" "}
                      <text
                        className="terms-and-conditions"
                        onClick={() => {
                          navigate(
                            `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {t("Terms_and_conditions")}{" "}
                      </text>{" "}
                      {t("and")}{" "}
                      <text
                        className="terms-and-conditions"
                        onClick={() => {
                          navigate(
                            `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {t("Privacy_policy")}{" "}
                      </text>{" "}
                      {t("of")}{" "}
                      <text className="terms-and-conditions-1">
                        {" "}
                        {process.env.REACT_APP_WEBSITE_NAME}
                        {process.env.REACT_APP_WEBSITE_NAME_TYPE}{" "}
                      </text>{" "}
                      {t("by_clicking_the")}
                    </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <Button
                      disabled={
                        !formEmailError &&
                        resume &&
                        formik.isValid &&
                        formik.dirty &&
                        isSubmitting === false
                          ? false
                          : true
                      }
                      component="label"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        submitForm(formik.values);
                      }}
                    >
                      {t("Submit")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",

                            marginLeft: 12,
                          }}
                        />
                      )}
                      {/* <VisuallyHiddenInput type="file" /> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={openok}
          aria-labelledby="responsive-dialog-title"
          maxWidth={600}
          className="dailog"
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            <p className="Successfully-text">{t("Thank_you_registration")}</p>
            <text className="Check-text">{t("Check_your_mail")}</text>
          </DialogTitle>
          <DialogContent className="ahjahg">
            <DialogContentText>
              <div className="set-widthfordailogbox">
                <div className="img-complete">
                  <img src={sussesfullimg} />
                </div>
                <div>
                  <p className="apply-your-text">{t("dream_jobs")}</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="btn-successfully" onClick={handleCloseed}>
                    {t("Home_Page")}
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default JobSeekerRegForm;
