import React from "react";
import { Box, Card, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
// import GroupsIcon from '@mui/icons-material/Groups';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import WorkIcon from '@mui/icons-material/Work';
//import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';

const StyledCard = styled(Card)(({ theme }) => ({
    "&.MuiCard-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "16px !important",
        flexDirection: "row",
        gap: "10px",
      },
  
      padding: "25px",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      alignContent: "space-between",
      boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    },
  }));
function StatCardsDatabaseIndustry({ getApi,industry}) {
      const fillStatForIndustryDatabase = getApi.filter((i) => {
        return industry.includes(i.industry);
      });
      const [IndustryDatabase] = [
        fillStatForIndustryDatabase.length
      ];
    
      const cardList = [
        {
          name: `${industry} Seekers`,
          count: IndustryDatabase,
          bgColor: "#28C76F",
          svg: <LocalLibraryIcon fontSize="large" />,
        },
      ];
    return (
        <>      
               {cardList.map((item)=>( 
               <StyledCard
                  sx={{ backgroundColor: `${item.bgColor}` }}
                  elevation={6}
                >
                  <Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                    >
                      <Box sx={{ color: "white" }}>{item.svg}</Box>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "#f2f2f2",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                  </Box>
    
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </StyledCard>
                ))}
             
        </>
      );
}

export default StatCardsDatabaseIndustry