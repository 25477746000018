import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Divider,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Grid,
  Typography,
  Paper,
  Chip,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Button,
} from "@mui/material";
import useAxios from "../../../../api/useAxios";

import { OpenInNewRounded as OpenInNewRoundedIcon } from "@mui/icons-material/";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import Breadcrumb from "../../../Dashboards/BreadCrumb";
import { cleanString } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import AutoApproveSnack from "../SnackBars/AutoApproveSnack";
import { useContext } from "react";
import { CurrentStateContext } from "../../../../App";
import SkeletonMlaCard from "../StatCards/SkeletonMlaCard";
import { ReactComponent as Warning } from "../../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import ExportPDF from "../ExportComponents/ExportPDF";
import ExportCS from "../ExportComponents/ExportCS";

function SeekerNeedSupport() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [familyApi, setFamilyApi] = useState([]);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [verifierApi, setVerifierApi] = useState([]);
  const [selectedday, setselectedday] = useState("");
  const adminPage = sessionStorage.getItem("admin_page_number");
  // const [getAdminSetting, setGetAdminSetting] = useState({});
  const { getAdminSetting } = useContext(CurrentStateContext);
  const snack = JSON.parse(sessionStorage.getItem("need-setting"));
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(snack);
  const [getone, setGetone] = useState([]);
  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const adminPageNumber = parseInt(adminPage);
  const setPageNumber = adminPageNumber ? adminPageNumber : 0;
  const navigate = useNavigate();
  const Moment = require("moment");
  const todayDate = Moment().format("DD-MM-YYYY");
  const yesterdayDate = Moment().subtract(1, "day").format("DD-MM-YYYY");
  const todate = Moment();
  const axiosData = useAxios();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const selectbyday = sessionStorage.getItem("selectionbydate");

  const radiovalue = sessionStorage.getItem("radiovalue");
  const selection = sessionStorage.getItem("selectionvalue");
  const finalvalue = sessionStorage.getItem("finalradio");
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  // <!----GET--->

  const fetchVerifierData = async () => {
    try {
      const axiosVerifierData = await axiosData.get("verifierdetails");
      const { data } = axiosVerifierData;
      const value = [...data];
      value.unshift({ email_id: "All" });
      setVerifierApi(value);
    } catch (err) {
      console.log("Error", err);
    }
  };
  // <!-----API FUNCTIONS---->

  const fetchFamilyApi = async () => {
    setLoading(true);
    try {
      const axiosFamilyData = await axiosData.get("family/needsupport");
      const { data } = axiosFamilyData;

      const ans = [...data];

      const sortbydate = ans.sort((a, b) => {
        return (
          new Moment(a.familyCreatedDate.split("-").join()).format("YYYYMMDD") -
          new Moment(b.familyCreatedDate.split("-").join()).format("YYYYMMDD")
        );
      });

      sortbydate.reverse();

      if (
        radiovalue === "Not Assigned" ||
        radiovalue === "Pending" ||
        radiovalue === "Completed" ||
        radiovalue === "all" ||
        radiovalue === "Admin" ||
        (radiovalue === null && selection === null && finalvalue === null)
      ) {
        // getFilterValue(
        //   radiovalue ? radiovalue : "all",
        //   selection ? selection : "All",
        //   sortbydate
        // );
        combineallfilters(
          selectbyday ? selectbyday : "today",
          radiovalue ? radiovalue : "all",
          null,
          selection ? selection : "All",
          sortbydate
        );
      } else if (
        finalvalue === "Pending" ||
        finalvalue === "Completed" ||
        finalvalue === "Hold" ||
        finalvalue === "Declined"
      ) {
        //finalverifier(finalvalue, sortbydate);
        combineallfilters(
          selectbyday ? selectbyday : "today",
          null,
          finalvalue,
          null,
          sortbydate
        );
      }
      setGetApi(sortbydate);
    } catch (err) {
      console.log("Error", err);
    }
    setLoading(false);
  };

  // <!---APPROVE-->

  // <!----FILE-DOWNLOAD-->

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA === valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (action, verifer_email, alldata) => {
    if (action === "Admin") {
      const adminverify = alldata.filter(
        (i) => i.fieldVerification === "Admin"
      );
      setFamilyApi([...adminverify]);
      setTotalRows([...adminverify].length);
      setPage(setPageNumber);
    } else if (action === "Not Assigned") {
      sessionStorage.setItem("selectionvalue", "All");
      const notAssined = alldata.filter((i) => i.VerifierName === "");
      setFamilyApi([...notAssined]);
      setTotalRows([...notAssined].length);
      setPage(setPageNumber);
    } else if (verifer_email === "All") {
      filtervalue(action, alldata);
    } else {
      const singleverifier = alldata.filter(
        (i) => i.VerifierEmail === verifer_email
      );
      filtervalue(action, singleverifier);
    }
  };
  const filtervalue = (value, filterdata) => {
    if (value === "all") {
      setFamilyApi([...filterdata]);
      setTotalRows([...filterdata].length);
      setPage(setPageNumber);
    } else if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Admin"
    ) {
      if (value === "Completed") {
        const fill = filterdata.filter(
          (d) =>
            d.fieldVerification === value || d.fieldVerification === "Admin"
        );
        setFamilyApi(fill);
        setTotalRows(fill.length);
        setPage(setPageNumber);
      } else {
        const fill = filterdata.filter((d) => d.fieldVerification === value);
        setFamilyApi(fill);
        setTotalRows(fill.length);
        setPage(setPageNumber);
      }
    }
  };
  const functioncsllchoose = (field, final, veriferselect, get) => {
    if (
      field === "Not Assigned" ||
      field === "Pending" ||
      field === "Completed" ||
      field === "all" ||
      field === "Admin"
    ) {
      getFilterValue(field, veriferselect, get);
    } else if (
      final === "Pending" ||
      final === "Completed" ||
      final === "Hold" ||
      final === "Declined"
    ) {
      finalverifier(final, get);
    }
  };
  const combineallfilters = (value, field, final, veriferselect, alldatas) => {
    if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      functioncsllchoose(field, final, veriferselect, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      functioncsllchoose(field, final, veriferselect, yes);
    } else if (value === "week") {
      const startWeek = Moment().subtract(1, "weeks").startOf("week");
      const endWeek = Moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(Moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      functioncsllchoose(field, final, veriferselect, week);
    } else if (value === "month") {
      var currentMonth = Moment(todate).format("MM");
      var currentyear = Moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = Moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          Moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        functioncsllchoose(field, final, veriferselect, mon);
      } else {
        const endOfMonth = Moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = Moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = Moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = Moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = Moment(item.createdDate, "DD-MM-YYYY");
          return Moment(date).isBetween(date1, date2, null, "[]");
        });
        functioncsllchoose(field, final, veriferselect, mon);
      }
    } else if (value === "Total") {
      functioncsllchoose(field, final, veriferselect, alldatas);
    }
  };
  const finalverifier = (data, alldata) => {
    const value = data ? data : "Pending";
    if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Hold" ||
      value === "Declined"
    ) {
      const fill = alldata.filter(
        (d) =>
          d.finalVerification === value &&
          (d.fieldVerification === "Completed" ||
            d.fieldVerification === "Admin")
      );
      setFamilyApi(fill);
      setTotalRows(fill.length);
      setPage(setPageNumber);
    }
  };
  const getAdminSettingData = async () => {
    try {
      const adminSet = await axiosData.get("application");
      const { data } = adminSet;
      // setGetAdminSetting(data[0]);
    } catch (err) {
      console.error(err);
    }
  };

  const headers = [
    [
      "Name",
      "Email",
      "Mobile number",
      "District",
      "Industry",
      "Type of support",
      "Others",
      "Gpay number",
      "Amount",
      "Account holder name",
      "Account number",
      "Ifsc code"
    ],
  ];

  const datas = familyApi.map((i) => {
    return [
      i.Name,
      i.email_id,
      i.mobile_number,
      i.votingDistrict,
      i.industry,
      i.TypeOfSupport,
      i.Others,
      i.UpiNumber,
      i.amount,
      i.BankDetails?.AccHolderName,
      i.BankDetails?.AccountNumber,
      i.BankDetails?.IfscCode
    ];
  });

  const selectedApiForExcel = familyApi.map((i) => {
    return {
      Name: i.Name,
      Email: i.email_id,
      "Mobile number": i.mobile_number,
      District: i.votingDistrict,
      Industry: i.industry,
      "Type of support": i.TypeOfSupport,
      Others: i.Others,
      "Gpay number": i.UpiNumber,
      Amount: i.amount,
      "Account holder name":i.BankDetails?.AccHolderName,
      "Account number":i.BankDetails?.AccountNumber,
      "Ifsc code":i.BankDetails?.IfscCode
    };
  });
  useEffect(() => {
    setselectedday(selectbyday ? selectbyday : "today");
    // getAdminSettingData();
    fetchFamilyApi();
    fetchVerifierData();
    handleSort(orderBy);
    sessionStorage.removeItem("admin_page_number");
  }, []);

  const handleClickDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const deleteSupport = async (getEmail) => {
    const email_id = getEmail;
    await axiosData.delete(`family/admin/${email_id}`);
    handleCloseDelete(false);
    fetchFamilyApi();
  };

  const getOneData = async (email) => {
    const getOneAxios = await axiosData.get(`family/${email}`);
    setGetone(getOneAxios.data);
    handleClickDelete();
  };

  if (!loading) {
    return (
      <>
        {getAdminSetting.needSupportApproval === true && openSnack === true ? (
          <AutoApproveSnack
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            full_name={"Need Support"}
            snackOption={"need-setting"}
          />
        ) : null}
        <Divider textAlign="left">
          <span className="seeker-text">Seekers Family Details</span>
        </Divider>
        <Breadcrumb />
        <Card sx={{ padding: "20px", mb: 8 }}>
          <Paper>
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid container>
                <Grid item md={8}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Field Verification Type
                    </FormLabel>
                    <RadioGroup
                      row
                      id="radio-btn"
                      value={radiovalue ? radiovalue : "all"}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ marginRight: "18px" }}
                      onChange={(e) => {
                        sessionStorage.setItem("finalradio", null);
                        sessionStorage.setItem("radiovalue", e.target.value);
                        // getFilterValue(
                        //   e.target.value,
                        //   selection ? selection : "All",
                        //   getApi
                        // );
                        combineallfilters(
                          selectedday,
                          e.target.value,
                          null,
                          selection ? selection : "All",
                          getApi
                        );
                        setPage(0);
                      }}
                    >
                      <FormControlLabel
                        name="usertype"
                        id="all"
                        control={<Radio value={"all"} />}
                        label="All"
                      />
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Pending"} />}
                        label="Pending"
                      ></FormControlLabel>
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Completed"} />}
                        label="Completed"
                      />
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Not Assigned"} />}
                        label="Not Assigned"
                      />
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Admin"} />}
                        label="Admin"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid md={4} xs={12}>
                  <FormControl
                    sx={{
                      width: { md: "100%", xs: "100%", sm: "100%" },
                      marginTop: "15px",
                    }}
                  >
                    <InputLabel>Filter By Verifier</InputLabel>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selection ? selection : "All"}
                      onChange={(e) => {
                        sessionStorage.setItem("finalradio", null);
                        sessionStorage.setItem(
                          "selectionvalue",
                          e.target.value
                        );
                        // getFilterValue(
                        //   radiovalue ? radiovalue : "all",
                        //   e.target.value,
                        //   getApi
                        // );
                        combineallfilters(
                          selectedday,
                          radiovalue ? radiovalue : "all",
                          null,
                          e.target.value,
                          getApi
                        );
                      }}
                    >
                      {verifierApi.map((i) => (
                        <MenuItem key={i.email_id} value={i.email_id}>
                          {i.full_name}
                          {i.full_name === "All" ? null : `(${i.email_id})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ marginTop: "20px" }}>
                {" "}
                <FormControl
                  sx={{ width: { md: "35%", xs: "100%", sm: "100%" } }}
                >
                  <InputLabel>Filter By Date</InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedday ? selectedday : "today"}
                    onChange={(e) => {
                      sessionStorage.setItem("selectionbydate", e.target.value);
                      setselectedday(e.target.value);
                      combineallfilters(
                        e.target.value,
                        radiovalue ? radiovalue : finalvalue ? null : "all",
                        finalvalue,
                        selection ? selection : "All",
                        getApi
                      );
                      //   setFilterName({
                      //     ...filterName,
                      //     date: e.target.value,
                      //   });
                      // getFilterValue(
                      //   filterName.status,
                      //   e.target.value,
                      //   getApi
                      // );
                    }}
                  >
                    <MenuItem value="Total">Total</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="week">Last Week</MenuItem>
                    <MenuItem value="month">Last month</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Box>
          </Paper>
          <Paper>
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid container>
                <Grid item md={8}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Final Approval Type
                    </FormLabel>
                    <RadioGroup
                      row
                      id="radio-btn"
                      value={finalvalue}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ marginRight: "18px" }}
                      onChange={(e) => {
                        sessionStorage.setItem("finalradio", e.target.value);
                        sessionStorage.setItem("radiovalue", null);
                        sessionStorage.setItem("selectionvalue", "All");
                        // finalverifier(e.target.value, getApi);
                        combineallfilters(
                          selectedday,
                          null,
                          e.target.value,
                          null,
                          getApi
                        );
                      }}
                    >
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Pending"} />}
                        label="Pending"
                      ></FormControlLabel>
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Completed"} />}
                        label="Completed"
                      />
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Hold"} />}
                        label="Hold"
                      />
                      <FormControlLabel
                        name="usertype"
                        control={<Radio value={"Declined"} />}
                        label="Declined"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid>
                  <Box display="flex">
                    {adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                      <Tooltip title="Export As Excel" placement="bottom">
                        <ExportCS
                          selectedApiForExcel={selectedApiForExcel}
                          fileName={"Need support data"}
                        />
                        {/* <IconButton size="small" onClick={exportToCSV}>
                              <img src={xls} alt="" />
                            </IconButton> */}
                      </Tooltip>
                    )}
                    {adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                      <Tooltip title="Export As PDF" placement="bottom">
                        <ExportPDF
                          headers={headers}
                          datas={datas}
                          fileName={"Need support data"}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "10px", boxShadow: 0 }}
          >
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell>Name </TableCell>
                  <TableCell>Email </TableCell>

                  <TableCell>Family Income</TableCell>

                  <TableCell> Field Verify </TableCell>
                  <TableCell>Final Approval</TableCell>
                  <TableCell> Verifier Name </TableCell>
                  <TableCell> Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {familyApi.length > 0 ? (
                  <>
                    {familyApi
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow key={item._id} hover className="tablecell">
                          <TableCell>{item.Name}</TableCell>
                          <TableCell>{item.email_id}</TableCell>

                          <TableCell>{item.FamilyIncome}</TableCell>
                          <TableCell>
                            {" "}
                            <Chip
                              label={
                                item.fieldVerification === "Admin"
                                  ? "Completed By Admin"
                                  : item.fieldVerification
                              }
                              variant="filled"
                              color={
                                item.fieldVerification === "Pending" ||
                                item.fieldVerification === "pending"
                                  ? "warning"
                                  : item.fieldVerification === "completed" ||
                                    item.fieldVerification === "Completed"
                                  ? "success"
                                  : item.fieldVerification === "Admin"
                                  ? "info"
                                  : "error"
                              }
                              sx={{
                                color: "#ffffff",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={item.finalVerification}
                              variant="filled"
                              color={
                                item.finalVerification === "Pending" ||
                                item.finalVerification === "pending"
                                  ? "warning"
                                  : item.finalVerification === "completed" ||
                                    item.finalVerification === "Completed"
                                  ? "success"
                                  : item.finalVerification === "Hold"
                                  ? "info"
                                  : "error"
                              }
                              sx={{
                                color: "#ffffff",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            {item.VerifierName
                              ? item.VerifierName
                              : item.fieldVerification === "Admin"
                              ? "Admin"
                              : "Not Assigned"}{" "}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Full Details" placement="top">
                              <IconButton
                                className="icon-btn"
                                size="Medium"
                                onClick={() => {
                                  navigate(
                                    `${ROUTINGDATA.LAND}${ROUTINGDATA.ADMINPANEL}/${ROUTINGDATA.ADMINSeekerFullFamilyDetails}`,
                                    {
                                      state: {
                                        email_id: item.email_id,
                                        ID: item._id,
                                        adminSetting:
                                          getAdminSetting.needSupportApproval,
                                      },
                                    }
                                  );
                                  sessionStorage.setItem(
                                    "admin_page_number",
                                    page
                                  );
                                }}
                              >
                                <OpenInNewRoundedIcon />
                              </IconButton>
                            </Tooltip>
                            {adminDetails_CEO?.emailId ===
                            process.env
                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                              <Tooltip title="Delete" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="medium"
                                  onClick={() => {
                                    getOneData(item.email_id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Barlow-Bold" }}
                      >
                        NO Data Available
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={openDelete}
          fullWidth
          onClose={() => handleCloseDelete(false)}
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure want to delete this record and
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    child records?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    You can't undo this action
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleCloseDelete();
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",
                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={filterName.isLoading}
                      sx={{
                        textTransform: "lowercase",
                        color: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "white",
                          backgroundColor: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        deleteSupport(getone.email_id);
                      }}
                    >
                      Delete{" "}
                      {filterName.isLoading && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",
                            marginLeft: 12,
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default SeekerNeedSupport;
