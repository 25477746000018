import React, { useEffect, useState } from "react";

import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination,
  Card,
  Chip,
  FormControl,
  Paper,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Button,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import LocalServiceHeader from "./LocalServiceHeader";

const Moment = require("moment");
function LocalServicePanel() {
  const axiosData = useAxios();
  const navigate = useNavigate();

  const [verifierData, setVerifier] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const verifierName = JSON.parse(localStorage.getItem("verifier"));
  const [getProviderName, setGetProviderName] = useState("");
  const [getApi, setGetApi] = useState([]);
  const [searchicon, setsearchicon] = useState(false);
  const [openExpDialog, setOpenExpDialog] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const verifiervalue = sessionStorage.getItem("verifierradio");
  const verifierPageNumbe = sessionStorage.getItem("verifier_page_number");
  const verifierPageNumber = parseInt(verifierPageNumbe);
  const setVerifierPageNumber = verifierPageNumber ? verifierPageNumber : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getServiceNum = 9787000000;

  const getVerifierEmailId = async () => {
    const verifier = await axiosData.get(
      `localservices/mobile/${getServiceNum}`
    );
    const { data } = verifier;
    const ans = [...data];
    const getservice = ans.filter((item) => {
      return item.contact_number === getServiceNum;
    });
    // const sortbydate = ans.sort((a, b) => {
    //   return (
    //     new Moment(b.modifiedDate.split("-").reverse().join()).format(
    //       "YYYYMMDD"
    //     ) -
    //     new Moment(a.modifiedDate.split("-").reverse().join()).format(
    //       "YYYYMMDD"
    //     )
    //   );
    // });

    setGetApi(getservice);
    getFilterValue(verifiervalue ? verifiervalue : "all", getservice);
  };
  const getFilterValue = (value, getapi) => {
    if (value === "all") {
      setVerifier([...getapi]);
      setTotalRows([...getapi].length);
      setPage(setVerifierPageNumber);
    } else if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Declined"
    ) {
      const fill = getapi.filter((d) => d.fieldVerification === value);
      setVerifier(fill);
      setTotalRows(fill.length);
      setPage(setVerifierPageNumber);
    } else {
      const ver = getapi.filter((i) => i.VerifierEmail === value);

      setVerifier(ver);
      setTotalRows(ver.length);
      setPage(setVerifierPageNumber);
    }
  };
  const closeFunctionCall = () => {
    setVerifier(getApi);
    setTotalRows(getApi.length);
    setPage(0);
  };
  const searchdatas = (searchdata) => {
    const datas = getApi.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      var valuess =
        item.Name.match(searchtext) || item.email_id.match(searchtext);

      if (valuess != null) {
        return item;
      }
    });
    setVerifier(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    getVerifierEmailId();
    setPage(setVerifierPageNumber);
    sessionStorage.removeItem("verifier_page_number");
  }, []);
  return (
    <>
      <LocalServiceHeader />
      <Dialog open={openExpDialog} fullWidth>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            display="flex"
            flexDirection={"column"}
            alignContent={"center"}
            justifyItems={"center"}
            width="100%"
            height={"100%"}
            flexWrap={"wrap !important"}
            gap={1.5}
          >
            <div style={{ width: "100%" }}>
              <IconButton
                sx={{ float: "right" }}
                onClick={() => setOpenExpDialog(false)}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Barlow-Bold",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Rate Your Experience
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              {" "}
              How was you experience with {""}?{" "}
            </Typography>
            <Box textAlign={"center"}>
              <Rating size="large" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenExpDialog(false)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ boxShadow: 0 }}>
        <Paper>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Grid container>
              {/* <Grid item md={8}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Field Verification Type
                  </FormLabel>
                  <RadioGroup
                    row
                    id="radio-btn"
                    value={verifiervalue ? verifiervalue : "all"}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginRight: "18px" }}
                    onChange={(e) => {
                      setsearchicon(false);
                      setsearchdata("");
                      sessionStorage.setItem("verifierradio", e.target.value);
                      getFilterValue(e.target.value, getApi);
                      setPage(0);
                    }}
                  >
                    <FormControlLabel
                      name="usertype"
                      id="all"
                      control={<Radio value={"all"} />}
                      label="All"
                    />
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value={"Pending"} />}
                      label="Pending"
                    ></FormControlLabel>
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value={"Completed"} />}
                      label="Completed"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  value={searchdata}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: `${searchicon ? "visible" : "hidden"}`,
                        }}
                        onClick={() => {
                          closeFunctionCall();
                          setsearchicon(false);
                          setsearchdata("");
                          getFilterValue(
                            verifiervalue ? verifiervalue : "all",
                            getApi
                          );
                        }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    sessionStorage.setItem("verifierradio", "all");
                    getFilterValue("all", getApi);
                    setsearchdata(e.currentTarget.value);
                    if (e.currentTarget.value.length >= 3) {
                      searchdatas(e.currentTarget.value);
                    }
                    setsearchicon(true);
                    setPage(0);
                  }}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Paper>
        <TableContainer
          component={Paper}
          sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-row">
                <TableCell>
                  {/* <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      > */}
                  Pincode
                  {/* </TableSortLabel> */}
                </TableCell>
                <TableCell> Village/Town</TableCell>
                <TableCell> Area/Street</TableCell>

                <TableCell> Types of service</TableCell>
                <TableCell>
                  {" "}
                  {/* <TableSortLabel
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      > */}
                  Need Type
                  {/* </TableSortLabel> */}
                </TableCell>

                <TableCell>Status</TableCell>
                <TableCell align="center"> Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getApi.length > 0 ? (
                <>
                  {getApi

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow key={item._id} hover className="tablecell">
                        <TableCell>{item.pincode}</TableCell>
                        <TableCell align="left">
                          {" "}
                          {item.village_town_name}{" "}
                        </TableCell>
                        <TableCell>{item.area_street_name}</TableCell>

                        <TableCell>{item.type_of_service}</TableCell>
                        <TableCell>{item.need_type}</TableCell>
                        <TableCell>
                          <Chip
                            label={
                              item.status === "Close" ? "Closed" : item.status
                            }
                            color={
                              item.status === "Open"
                                ? "warning"
                                : item.status === "Assigned"
                                ? "success"
                                : "error"
                            }
                            variant="contained"
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            gap={"10px"}
                          >
                            <Tooltip title="Full Details" placement="top">
                              <IconButton
                                className="icon-btn"
                                size="Medium"
                                onClick={() => {
                                  setOpenExpDialog(true);
                                }}
                              >
                                <OpenInNewRoundedIcon />
                              </IconButton>
                            </Tooltip>
                            {/* {item.status !== "Close" && (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "20px",
                                      fontFamily: "Poppins-Regular",
                                      boxShadow: 0,
                                    }}
                                    onClick={() => {
                                      setOpenCloseDialog(true);
                                      setGetEmail(item._id);
                                    }}
                                    startIcon={<CloseRoundedIcon />}
                                  >
                                    Close
                                  </Button>
                                )} */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                    <TableCell>
                      {" "}
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Barlow-Bold" }}
                      >
                        NO Data Available
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

export default LocalServicePanel;
