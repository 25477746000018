import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  Skeleton,
} from "@mui/material";

import useAxios from "../../../../api/useAxios";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { saveAs } from "file-saver";
function VolunteerDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  dialogLoad,
  setGetOne,
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [value, setValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);

  const axiosData = useAxios();
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const location = useLocation();
  const currentUrl = location.pathname;
  const updateApprove = async (id, email_id, password) => {
    setFilterName({ ...filterName, isLoading: true });
    try {
      await axiosData.put(`volunteer/${id}`, { status: "A" });
      await axiosData.put(`users/${email_id}`, {
        password: password,
        status: "Active",
        usertype: "volunteer",
      });
      setGetPutId(0);
      setGetEmail("");
      setGetPass("");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption("success");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };

  const updateDecline = async (id, email_id) => {
    setFilterName({ ...filterName, isLoading: true });

    try {
      await axiosData.put(`volunteer/${id}`, { status: "D", reason: value });
      axiosData.put(
        `users/${email_id}`,
        { status: "InActive", usertype: "volunteer", reason: value },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setGetPutId(0);
      setGetEmail("");
      setValue("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        open={open}
        // onClose={handleChange}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          <Box id="main-box">
            <Box>
              {" "}
              <div id className="align-img"></div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.full_name}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {getOne.work_status}
                  </div>
                </div>
              </Typography>
            </Box>

            <Box></Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px", width: "100%" }}>
            <Grid container md={12} columnSpacing={1} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Personal Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins-Regular" }}>
                  <div className="align-text">
                    <span className="full-details"> Full Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.full_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mobile_number
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Professional Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Industry:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.industry
                      )}
                    </div>
                  </div>
                </Typography>

                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Designation:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.designation
                      )}
                    </div>
                  </div>
                </Typography>

                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.district
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Pincode:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.pincode
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Area:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.area
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              {currentUrl ==
              ROUTINGDATA.LAND +
                ROUTINGDATA.ADMINPANEL +
                "/" +
                ROUTINGDATA.ADMINVOLUNTEER ? (
                <Typography textAlign="center" variant="h6">
                  <div className="align-text">
                    <span
                      className="full-details"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Status:
                    </span>
                    <div style={{ textAlign: "left" }}>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <Chip
                          label={getOne.status}
                          color={
                            getOne.status === "P"
                              ? "warning"
                              : getOne.status === "A"
                              ? "success"
                              : "error"
                          }
                          variant="contained"
                          sx={{ textAlign: "left" }}
                        />
                      )}
                    </div>
                  </div>
                </Typography>
              ) : null}
              {getOne.status === "D" ? (
                <>
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details"> Reason:</span>
                      <div>{getOne.reason}</div>
                    </div>
                  </Typography>
                </>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleChange}>
            Close
          </Button>
          {currentUrl ==
          ROUTINGDATA.LAND +
            ROUTINGDATA.ADMINPANEL +
            "/" +
            ROUTINGDATA.ADMINVOLUNTEER ? (
            adminDetails_CEO?.emailId ===
            process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
              <>
                <Button
                  className="action-btn"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setGetPutId(getOne._id);
                    setGetEmail(getOne.email_id);
                    setGetPass(getOne.password);
                    setApproveOpen(true);
                  }}
                  variant="contained"
                  color="success"
                  disabled={getOne.status === "A" ? true : false}
                >
                  Approve
                </Button>{" "}
                <Button
                  className="action-btn"
                  sx={{ textTransform: "capitalize" }}
                  color="error"
                  onClick={() => {
                    setGetPutId(getOne._id);
                    setGetEmail(getOne.email_id);

                    setDeclineOpen(open);
                  }}
                  variant="contained"
                  disabled={getOne.status === "D" ? true : false}
                >
                  Decline
                </Button>
              </>
            )
          ) : null}
        </DialogActions>
      </Dialog>
      {currentUrl ==
      ROUTINGDATA.LAND +
        ROUTINGDATA.ADMINPANEL +
        "/" +
        ROUTINGDATA.ADMINVOLUNTEER ? (
        <>
          <ApproveDialog
            approveOpen={approveOpen}
            setApproveOpen={setApproveOpen}
            updateApprove={updateApprove}
            filterName={filterName}
            getPutId={getPutId}
            getEmail={getEmail}
            getPass={getPass}
          />
          <DeclineDialog
            declineOpen={declineOpen}
            setDeclineOpen={setDeclineOpen}
            value={value}
            setValue={setValue}
            filterName={filterName}
            updateDecline={updateDecline}
            getPutId={getPutId}
            getEmail={getEmail}
          />
          <AdminSnackBar
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            snackOption={snackOption}
            full_name={getOne.full_name}
          />
        </>
      ) : null}
    </>
  );
}

export default VolunteerDialog;
