import React, { useRef, useState } from "react";
import "./UploadPdf.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Context from "../Context";
import { useLocation } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadPdf = () => {
  const Location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(false);
  const axiosData = useAxios();

  const [resume, setResume] = useState("");

  const MAX_FILE_SIZE = 1048576 * 10; //10Mb
  const cvInputField = useRef(null);

  const handleFileChange = async (e, context) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setResumeUpload(true);
      setResume("");
      cvInputField.current.value = "";
    } else {
      setResume({ fileUpload: e.target.files[0] });

      const values = { fileUpload: "" };
      if (e.target.files[0]) {
        values.fileUpload = e.target.files[0];
      }
      setIsSubmitting(true);
      await axiosData.put(`/seeker/email/${Location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      context.setput(true);
      setIsSubmitting(false);
      cvInputField.current.value = "";
    }
  };

  return (
    <Context.Consumer>
      {(Context) => {
        return (
          <>
            <Dialog TransitionComponent={Transition} open={resumeUpload}>
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                Resume Upload
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText sx={{ fontWeight: 600 }}>
                  {"File size is too large upload < 10MB file."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setResumeUpload(false)}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <div className="file-card1">
              <input
                className="resumefile"
                id="file-upload"
                type="file"
                accept=".docx,.doc,.pdf,"
                onChange={(e) => {
                  handleFileChange(e, Context);
                }}
                ref={cvInputField}
                required
              />
              <div className="fileuploadcenter1">
                <Button
                  disabled={Context.profile.fileUpload ? true : false}
                  htmlFor="file"
                  variant="contained"
                  size="medium"
                  color="error"
                  className="resumeUpload"
                  sx={{ borderRadius: 10 }}
                  name="fileUpload"
                  onClick={() => {
                    cvInputField.current.click();
                  }}
                >
                  <AddCircleOutlineIcon className="resumeIcon" />
                  Upload Resume
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#ffffff",
                        marginLeft: 12,
                      }}
                    />
                  )}
                </Button>
              </div>

              <p className="main1">Supported files</p>
              <p className="info1">
                {".pdf/.doc/.docx only and File must be < 10Mb"}
              </p>
            </div>
          </>
        );
      }}
    </Context.Consumer>
  );
};

export default UploadPdf;
