import React from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { HomeRounded as HomeRoundedIcon } from "@mui/icons-material";
import { ROUTINGDATA } from "../ROUTINGDATA";

function Breadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

        const isLast = index === pathnames.length - 1;
        if (index === 0) {
          return (
            <Link
              color="inherit"
              to={
                ROUTINGDATA.LAND +
                ROUTINGDATA.ADMINPANEL +
                "/" +
                ROUTINGDATA.ADMINDASHBOARD
              }
              key="Dashboard"
              onClick={() => sessionStorage.setItem("active", "dash")}
            >
              <div style={{ display: "flex", alignItems: "center" ,fontFamily:"Poppins-Regular" }}>
                <HomeRoundedIcon sx={{marginRight:"10px",fontSize:26}} /> <p>Dashboard</p>
              </div>
            </Link>
          );
        }
        return isLast ? (
          <Typography color="text.primary" key={routeTo} sx={{fontFamily:"Poppins-Regular"}} >
            {name}
          </Typography>
        ) : (
          <Link color="inherit" to={routeTo} key={routeTo}>
            <div style={{ display: "flex", alignItems: "center",fontFamily:"Poppins-Regular" }}>
              {" "}
              <HomeRoundedIcon sx={{marginRight:"10px",fontSize:26}}/> <p>{name}</p>
            </div>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
