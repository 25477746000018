import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
  Typography,
  Paper,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Fab,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
  Chip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../PostJob/PostJob.css";
import useAxios from "../../../api/useAxios";
import {
  CheckRounded as CheckRoundedIcon,
  RefreshSharp as RefreshSharpIcon,
  CloseRounded as CloseRoundedIcon,
  ManageSearchRounded as ManageSearchRoundedIcon,
} from "@mui/icons-material/";
import defaultimg from "../../../img/mentorImage.png";
import WestIcon from "@mui/icons-material/West";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import "jspdf-autotable";
import SkeletonMlaCard from "../../admin-panel/admin-components/StatCards/SkeletonMlaCard";
import { useLocation } from "react-router-dom";
import DatabaseSeekerDialog from "./DatabaseSeekerDialog";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import StatCardsDatabaseIndustry from "./StatCardsDatabaseIndustry";
import "./DatabaseAccess.css";
function TrialViewDatabaseAccess() {
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const Location = useLocation();
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [trialExpiry, setTrialExpiry] = useState("");
  const [pinLoad, setpinLoad] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });
  const [advSearch, setAdvSearch] = useState(false);
  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [districtData, setDistrictData] = useState([]);
  const [district_name, setDistrict_name] = useState([]);
  const [pincodeByDistrict, setPincodeByDistrict] = useState([]);
  const [getPincode, setGetPincode] = useState([]);
  const [industryArray, setIndustryArray] = useState([]);
  const [searchdisplay, setsearchdisplay] = useState(false);
  const [industryget, setindustryget] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [trialDays, setTrialDays] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectProfilePopup, setSelectProfilePopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [getOneID, setGetOneID] = useState("");
  const [searchdata, setsearchdata] = useState("");
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [dateenable, setdateenable] = useState(false);
  let [empData, setempData] = useState({});
  let [empDataAccess, setempDataAccess] = useState({});
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [coreOptions, setCoreOptions] = useState([
    "Fresher",
    "Experienced",
    "Both",
  ]);
  const [coreValue, setCoreValue] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [searchicon, setsearchicon] = useState(false);
  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [getShortlistSeeker, setGetShortlistSeeker] = useState({});
  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const adminSeekerSearch = sessionStorage.getItem("admin_seeker_search");
  const handleChange = () => {
    setOpen(!open);
    setLoading({ ...loading, dialogLoad: true });
  };
  const handleChangeNotes = () => {
    setSelectProfilePopup(!selectProfilePopup);
    setLoading({ ...loading, dialogLoad: true });
  };
  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->
  const getPostJobSettings = async () => {
    try {
      const postJobData = await axiosData.get("employers/Getsettings/postjob");
      const data = postJobData.data;
      setTrialDays(data.no_of_days_trial_view);
    } catch (err) {
      console.log("er", err);
    }
  };
  const fetchApi = async () => {
    setLoading({ ...loading, isLoading: true });
    IndustryData();
    try {
      const getOneAxiosSeekers = await axiosData.get(
        `seekers/trialView/AllSeekers`
      );
      if (getOneAxiosSeekers.status === 204) {
        setGetApi([]);
      } else {
        axiosData
          .get(`employers/email/${userDetails.emailId}/users`)
          .then(async (data) => {
            const employerData = data.data;
            if (employerData.email_id === userDetails.emailId) {
              const startDate = moment().format("DD-MM-YYYY");
              const expiryDate = employerData?.createdDate;
              const start_date = moment(startDate, "DD-MM-YYYY");
              const expiry_date = moment(expiryDate, "DD-MM-YYYY");
              const daysDifference = start_date.diff(expiry_date, "days");
              if (daysDifference > trialDays) {
                setTrialExpiry("");
              } else {
                setTrialExpiry(daysDifference);
              }
              // setTrialExpiry(daysDifference);
            }
          });
        const dataSeekers = getOneAxiosSeekers.data;
        const datefromseeker = dataSeekers[0].createdDate;
        const date = moment(datefromseeker, "DD-MM-YYYY");
        setStartDate(date);
        dataSeekers.reverse();
        let bool = false;
        if (adminSeekerSearch !== null) {
          bool = adminSeekerSearch.length > 0;
        }
        if (true === bool) {
          searchdatas(adminSeekerSearch, dataSeekers);
          setGetApi(dataSeekers);
          setsearchicon(true);
        } else if (advSearch === true) {
          advanceSearch(dataSeekers);
        } else {
          setGetApi(dataSeekers);
          getFilterValue(filterName.status, "Total", dataSeekers);
        }
      }
      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterseeker = async (pincode, district, industry, workStatus) => {
    setLoading({ ...loading, isLoading: true });
    setEndDate(moment());
    try {
      const useAxiosData2 = await axiosData.post(
        `seekers/databaseaccess/trialAccess`,
        {
          data_accessType_by_industry: industry.join(","),
          data_accessType_by_work_status: workStatus,
          pincode: pincode.join(","),
          votingDistrict: district.join(","),
        }
      );
      if (useAxiosData2.status === 204) {
        setUseApi([]);
      } else {
        setUseApi(useAxiosData2.data);
        setTotalRows(useAxiosData2.data.length);
        setPage(0);
      }
      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA === valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (statusvalue, value, alldatas) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      statusForAll(statusvalue, yes);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.createdDate, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas);
    }
  };
  const statusForAll = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    } else if (
      statusvalue === "A" ||
      statusvalue === "P" ||
      statusvalue === "D"
    ) {
      const today = get.filter((i) => i.status === statusvalue);
      const data = today;
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    }
  };
  const handleStartDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
    }
    if (startDate === null) {
      setstartdisabled(false);
    }
    setStartDate(date);
    setstartError(null);
  };
  const handleEndDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
    }
    if (endDate === null) {
      setenddisabled(false);
    }
    setEndDate(date);
    setendError(null);
  };
  const handleClickFilter = (statusvalue) => {
    const filteredData = getApi.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate);
      return date.isBetween(start, endDate, null, "[]");
    });
    statusForAll(statusvalue, filteredData);
  };

  const handleClickRefresh = () => {
    fetchApi();
    setsearchdata("");
    setstartdisabled(false);
    setenddisabled(false);
    setstartError(null);
    setendError(null);
  };

  const closeFunctionCall = () => {
    getFilterValue("all", "Total", getApi);
  };

  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      setGetShortlistSeeker({});
      fetchApi();
      getPostJobSettings();
      setsearchdata("");
      vodingDistrict();
      handleSort(orderBy);
    }
  }, []);
  const searchdatas = (searchdata, getApi) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = getApi.filter((item) => {
      var valuess =
        item.full_name.match(searchtext) ||
        item.email_id.match(searchtext2) ||
        item.votingDistrict.match(searchtext2) ||
        item.mlaConstituency.match(searchtext2) ||
        item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getPincodeByDistrict = async (value) => {
    setpinLoad(true);
    try {
      const pin = await axiosData.get(`distritarea/trialAccess/${value}`);
      const data = pin.data;
      if (data) {
        const datapin = data;
        let pinarray = [];
        let postarray = [];
        if (datapin) {
          const getpin = datapin.map((i) => {
            const iMap = i.Pincode.map((d) => {
              pinarray.push(d);
              return d;
            });
          });
          const getpost = datapin.map((i) => {
            const iMap2 = i.Post_Office.map((d) => {
              postarray.push(d);
              return d;
            });
          });
          const getpost1 = postarray;
          const result = pinarray.reduce(
            (accumulator, currentPincode, index) => {
              return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
            },
            []
          );
          const getPostFinal = result.map((i) => {
            return i;
          });
          setpinLoad(false);
          setPincodeByDistrict([...getPostFinal]);
        }
      }
    } catch (err) {}
  };

  const advanceSearch = (datavalue) => {
    const searchtindustry = new RegExp(industryget, "i");
    const searchdistrict = new RegExp(district_name, "i");
    let data = [];

    if (
      industryget === "" ||
      industryget === null ||
      industryget === undefined
    ) {
      data = datavalue;
    } else {
      data = datavalue.filter((item) => {
        return item.industry.match(searchtindustry);
      });
    }
    if (district_name !== "" || district_name !== null) {
      data = data.filter((item) => {
        return item.votingDistrict.match(searchdistrict);
      });
    }
    if (getPincode) {
      data = data.filter((item) => {
        return item.pincode == getPincode;
      });
    }
    setUseApi(data);
    setTotalRows(data.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = () => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : !dateRange.openStartTool
          ? handleToolClose()
          : handleToolClose();
      };
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);
  if (!loading.isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            <Tooltip
              arrow
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.EMPLOYERUPDATE,
                  {
                    state: userDetails.emailId,
                  }
                );
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                id="backIcon"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <div style={{ flexGrow: 1, textAlign: "center" }}>
              <Typography id="headingSeekers">{"Job Seekers Data"}</Typography>
            </div>
          </div>
          <div>
            <Grid item xs={12} sm={6} md={1.7}>
              <div style={{ textAlign: "right" }}>
                <Typography style={{ color: "#1976d2", fontWeight: 600 }}>
                  Note:Your job seeker search view{" "}
                  {trialExpiry === "" ? "is" : "ends in "}
                  <span style={{ color: "red" }}>
                    {trialExpiry === "" ? " Closed" : trialExpiry}{" "}
                    {trialExpiry === ""
                      ? null
                      : trialExpiry === 1
                      ? " Day"
                      : " Days"}
                  </span>
                </Typography>
              </div>
            </Grid>
          </div>
          {/* <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <StatCardsDatabase
                getApi={getApi}
                empDataAccess={empDataAccess}
                empData={empData}
              />
            </Box>
          </Card> */}
          {/* {industryAccessArray?.length > 1 ? (
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box>
                  <Divider textAlign="left" sx={{ width: "100%" }}>
                    <span className="seeker-text">
                      Industry Based Total Seekers
                    </span>
                  </Divider>
                </Box>
                <Grid
                  container
                  direction="row"
                  spacing={5}
                  md={12}
                  sx={{ mb: "24px", width: "100%" }}
                >
                  {industryAccessArray?.map((i, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <StatCardsDatabaseIndustry getApi={getApi} industry={i} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
          ) : null} */}

          <Card sx={{ padding: "20px" }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {advSearch ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold", marginBottom: "10px" }}
                          >
                            Filter By Experience Type
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="experience_type"
                            onChange={(e) => {
                              setCoreValue(e.target.value);
                            }}
                            value={coreValue}
                          >
                            {coreOptions?.map((item) => (
                              <FormControlLabel
                                value={item}
                                control={<Radio />}
                                label={item}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        {industryArray && (
                          <FormControl fullWidth>
                            <FormLabel
                              sx={{ fontWeight: "bold", marginBottom: "10px" }}
                            >
                              Filter By Industry
                            </FormLabel>
                            <Autocomplete
                              multiple
                              disableClearable={industryget ? false : true}
                              filterOptions={filterOptions}
                              id="industry"
                              name="industry"
                              options={industryArray}
                              onChange={(event, value) => {
                                setindustryget(value);
                              }}
                              value={industryget}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={option}
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  id="industry"
                                  name="industry"
                                  placeholder={"Select Industry"}
                                  // InputProps={{
                                  //   ...params.InputProps,
                                  //   startAdornment: (
                                  //     <InputAdornment position="start">
                                  //       {pinLoad ? (
                                  //         <CircularProgress
                                  //           style={{
                                  //             width: "1.4rem",
                                  //             height: "1.4rem",
                                  //             color: "#4991dd",
                                  //           }}
                                  //         />
                                  //       ) : null}
                                  //     </InputAdornment>
                                  //   ),
                                  // }}
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start",
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            Filter By District Name
                          </FormLabel>
                          <Autocomplete
                            multiple
                            disableClearable={district_name ? false : true}
                            filterOptions={filterOptions}
                            id="votingDistrict"
                            name="district_name"
                            options={districtData}
                            onChange={(event, value) => {
                              setDistrict_name(value);
                            }}
                            value={district_name}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={option}
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="votingDistrict"
                                name="district_name"
                                placeholder={"Select District"}
                                // InputProps={{
                                //   ...params.InputProps,
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       {pinLoad ? (
                                //         <CircularProgress
                                //           style={{
                                //             width: "1.4rem",
                                //             height: "1.4rem",
                                //             color: "#4991dd",
                                //           }}
                                //         />
                                //       ) : null}
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "180px",
                                overflowY: "auto",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <FormControl
                          fullWidth
                          required
                          disabled={pinLoad ? true : false}
                        >
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            Filter By Pincode
                          </FormLabel>
                          <Autocomplete
                            multiple
                            disableClearable={getPincode ? false : true}
                            filterOptions={filterOptions}
                            id="pincode"
                            name="pincode"
                            options={pincodeByDistrict}
                            onChange={(event, value) => {
                              setGetPincode(value);
                            }}
                            value={getPincode}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={index}
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="pincode"
                                name="pincode"
                                onChange={(e) => {
                                  if (e.target.value.length >= 5) {
                                    getPincodeByDistrict(e.target.value);
                                  }
                                }}
                                placeholder={"Select Pincode"}
                                // InputProps={{
                                //   ...params.InputProps,
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       {pinLoad ? (
                                //         <CircularProgress
                                //           style={{
                                //             width: "1.4rem",
                                //             height: "1.4rem",
                                //             color: "#4991dd",
                                //           }}
                                //         />
                                //       ) : null}
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "180px",
                                overflowY: "auto",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "grid", placeItems: "end" }}
                      >
                        <Box
                          width={"40%"}
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"end"}
                          gap={"50px"}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              background: "#d30a5a",
                              color: "#fff",
                              ":hover": {
                                backgroundColor: "#d30a5a",
                              },
                            }}
                            onClick={() => {
                              setGetPincode("");
                              setDistrict_name("");
                              setindustryget("");
                              setsearchdisplay(false);
                              closeFunctionCall();
                              setAdvSearch(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              background: "#0675a2",
                              color: "#fff",
                              ":hover": {
                                backgroundColor: "#0675a2",
                              },
                            }}
                            onClick={() => {
                              //advanceSearch(getApi);
                              filterseeker(
                                getPincode,
                                district_name,
                                industryget,
                                coreValue
                              );
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%", sm: "100%" },
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={2} sm={4} alignItems="center">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(filterName.status);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                        </>

                        <Tooltip title="Refresh" placement="bottom">
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={handleClickRefresh}
                          >
                            <RefreshSharpIcon />
                          </Fab>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} sm={4}>
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : adminSeekerSearch}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setsearchdata(e.currentTarget.value);

                          if (e.currentTarget.value.length === 0) {
                            setUseApi(getApi);
                            setTotalRows(getApi.length);
                            setPage(0);
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(e.currentTarget.value, getApi);
                          }
                        }}
                      />

                      {/* <Box display="flex">
                            <Tooltip title="Export As Excel" placement="bottom">
                              <ExportCS
                                selectedApiForExcel={selectedApiForExcel}
                                fileName={"Seeker-Data"}
                              />
                            </Tooltip>
                            <Tooltip title="Export As PDF" placement="bottom">
                              <ExportPDF
                                headers={headers}
                                datas={datas}
                                fileName={"Job-Seeker"}
                              />
                            </Tooltip>
                          </Box> */}
                    </Grid>
                    {/* <Grid item xs={4} md={1.5} sm={2}>
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        {searchicon ? (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#FF0000",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#cc0000" },
                            }}
                            onClick={() => {
                              closeFunctionCall();
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem("admin_seeker_search", "");
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                        ) : (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={() => setsearchicon(true)}
                          >
                            <SearchRoundedIcon />
                          </Fab>
                        )}
                      </Box>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={4}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      <div style={{ display: "grid", placeItems: "center" }}>
                        <Box>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              borderRadius: "16px",
                            }}
                            startIcon={<ManageSearchRoundedIcon />}
                            onClick={() => {
                              setAdvSearch(true);
                            }}
                          >
                            Advanced Search
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
            <Typography
              style={{
                color: "#1976d2",
                margin: "10px",
                fontWeight: "bold",
                fontFamily: "Barlow-Bold"
              }}
              variant="h6"
            >
              <span style={{ color: "red" }}>{useApi.length}</span> total number
              of resumes founded
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell align="center"> Profile</TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Industry</TableCell>
                    <TableCell align="center">
                      {" "}
                      <TableSortLabel
                        align="center"
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      >
                        Experience
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Keyskill</TableCell>
                    <TableCell align="center"> District</TableCell>
                    <TableCell align="center"> Pincode & Area</TableCell>
                    <TableCell align="center"> Created Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell align="center">
                              {" "}
                              {
                                <img
                                  src={
                                    item?.profileUpload
                                      ? `${baseUrl}seekers/profile/${item.mobile_number}`
                                      : ""
                                  }
                                  onError={(e) => {
                                    e.target.src = defaultimg;
                                  }}
                                  alt="img"
                                  height={"50"}
                                  width="50"
                                  style={{ clipPath: "circle(50%)" }}
                                />
                              }{" "}
                            </TableCell>
                            <TableCell align="center">
                              {item.full_name}
                            </TableCell>
                            <TableCell align="center">
                              {item.industry}
                            </TableCell>
                            <TableCell align="center">
                              {item.work_status}
                            </TableCell>
                            <TableCell align="center">
                              {item.Keyskill ? item.Keyskill : "Not Updated"}
                            </TableCell>
                            <TableCell align="center">
                              {item.votingDistrict}
                            </TableCell>
                            <TableCell align="center">{item.pincode}</TableCell>
                            <TableCell align="center">
                              {item.createdDate}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <DatabaseSeekerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
            filterName={filterName}
            setFilterName={setFilterName}
            fetchApi={fetchApi}
            dialogLoad={loading.dialogLoad}
            setGetOne={setGetOne}
            empDataAccess={empDataAccess}
            empData={empData}
            trialView={"trialView"}
            getShortlistSeeker={getShortlistSeeker}
            selectProfilePopup={selectProfilePopup}
            handleChangeNotes={handleChangeNotes}
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default TrialViewDatabaseAccess;
