import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CardActions,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CloseIcon from "@mui/icons-material/Close";
import { Buffer } from "buffer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { saveAs } from "file-saver";
import ReactQuill from "react-quill";
import WestIcon from "@mui/icons-material/West";
import React, { useEffect, useRef, useState } from "react";
import "./PostJob.css";
import useAxios from "../../../api/useAxios";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useFormik } from "formik";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BackHandIcon from "@mui/icons-material/BackHand";
import EventNoteSharpIcon from "@mui/icons-material/EventNoteSharp";
import * as Yup from "yup";
import SeekerDialog from "../../../components/admin-panel/admin-components/DialogBoxes/SeekerDialog";
import { Box, display } from "@mui/system";
import { Schedule, TextFields } from "@mui/icons-material";
import { use } from "i18next";
import moment from "moment";
const styles = {
  "&.MuiButton-contained": {
    backgroundColor: "#ffbe00",
    marginRight: "4px",
  },
};
const color = {
  "&.MuiButton-contained": {
    backgroundColor: "#b00020",
  },
};
function AppliedSeekers() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [applyDetails, setApplyDetails] = useState([]);
  const [focusNo, setFocusNo] = useState(false);
  const [focus, setFocus] = useState(false);
  const [datas, setDatas] = useState([]);
  const [postJob, setPostJob] = useState(false);
  const [getOneSeeker, setGetOneSeeker] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [approvePopup, setApprovePopup] = useState(false);
  const [pendingMsg, setPendingMsg] = useState();
  const [afPendingMsg, setAfPendingMsg] = useState({});
  const [declineMsg, setDeclineMsg] = useState();
  const [approveMsg, setApproveMsg] = useState();
  const [afApproveMsg, setAfApproveMsg] = useState({});
  const [afDeclineMsg, setAfDeclineMsg] = useState({});
  const [afApproveDetails, setAfApproveDetails] = useState([]);
  const [afPendingDetails, setAfPendingDetails] = useState([]);
  const [afRejectedDetails, setAfRejectedDetails] = useState([]);
  const [seekersPagination, setSeekersPagination] = useState(10);
  const [rejectedPopup, setRejectedPopup] = useState(false);
  const [activePaper, setActivePaper] = useState("applicants");
  const [offerLetter, setOfferLetter] = useState(false);
  const fileInputField = useRef(null);
  const [viewComments, setViewComments] = useState("");
  const [viewCommentsPopup, setViewCommentsPopup] = useState(false);
  const [file, setfile] = useState();
  const [holdpopup, setholdpopup] = useState(false);
  const [filterName, setFilterName] = useState({
    date: "allProfiles",
    isLoading: false,
  });
  const [pendingJob, setPendingJob] = useState(false);
  const [offerLetterErr, setOfferLetterErr] = useState("");
  const [selectProfilePopup, setSelectProfilePopup] = useState(false);
  const [checked, setChecked] = useState(false);
  let location = useLocation();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const MAX_FILE_SIZE = 1048576 * 10;
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const formik = useFormik({
    initialValues: {
      notes: "",
      interviewer_name: "",
      interviewer_phone_number: "",
      interviewer_email: "",
      date: moment(),
      time: null,
    },
    validationSchema: Yup.object({
      notes: Yup.string().required("Comments must be required"),
      interviewer_name:
        activePaper !== "applicants"
          ? Yup.string()
              .max(50, "Must be 50 characters or less")
              .required("Interviewer name is required")
          : null,
      interviewer_phone_number:
        activePaper !== "applicants"
          ? Yup.string()
              .matches(/^[0-9]{10}$/, "Invalid phone number")
              .required("Interviewer phone number is required")
          : null,
      interviewer_email:
        activePaper !== "applicants"
          ? Yup.string()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                "Invalid Email"
              )
              .required("Email is must be Required")
          : null,

      time:
        activePaper !== "applicants"
          ? Yup.date().required("Interview Time is required")
          : null,
      date:
        activePaper !== "applicants"
          ? Yup.date().required("Interview date is required")
          : null,
    }),
    onSubmit: (values) => {
      if (activePaper === "applicants") {
        approvePendingCall(afPendingDetails);
        getFilterValue("allProfiles", datas);

        formik.handleReset("");
      } else if (activePaper === "profile-shortlisted") {
        interview(afPendingDetails, values);
        formik.handleReset("");
      } else if (activePaper === "hold" || activePaper === "rejected") {
        interview(afPendingDetails, values);
        formik.handleReset("");
      }
      setSelectProfilePopup(false);
    },
  });

  const handleChange = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  const getAppliedSeekers = async () => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyjobid/${location.state.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data === "") {
        setLoading(false);
        setPostJob(true);
      } else {
        const datasset = response.data;
        setLoading(false);
        if (location.state.source_from?.includes("Database")) {
          setActivePaper("profile-shortlisted");
          const datasset2 = response.data.filter(
            (d) => d.job_title === location.state.job_title
          );
          setDatas(datasset2);
          setApplyDetails(datasset2);
          getFilterValue("profile-shortlisted", datasset2);
        } else {
          setDatas(response.data);
          setApplyDetails(response.data);
          getFilterValue("allProfiles", datasset);
        }
      }
      getFilterValue(activePaper, response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const offerdPopupMethod = (data) => {
    setAfApproveDetails(data);
    setOfferLetter(true);
  };
  const approvePopupMethod = (data) => {
    setApprovePopup(true);
    setAfApproveDetails(data);
  };
  const rejectedPopupMethod = (data) => {
    setRejectedPopup(true);
    setAfRejectedDetails(data);
  };
  const holdPopupMethod = (data) => {
    setholdpopup(true);
    setAfRejectedDetails(data);
  };
  const selectProfile = (data) => {
    setSelectProfilePopup(true);
    setAfPendingDetails(data);
    pendingProfilePutcall(data);
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getAppliedSeekers();
    }
  }, []);
  const deleteFile = () => {
    setfile("");
    fileInputField.current.value = "";
  };
  const ShortlistedProfile = async (data, resData) => {
    const id = data._id;
    const noteProfile = resData;
    var strippedHtml = noteProfile.replace(/<[^>]+>/g, "");
    try {
      await axiosData.post(
        "jobapply/email",
        {
          _id: id,
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          notes: strippedHtml,
          job_title: data.job_title,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };
  const viewProfile = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/${id}`);
      const { data } = getOneAxios;
      setGetOneSeeker(data);
    } catch (err) {
      console.log(err);
    }
  };
  const approvePutCall = async (data) => {
    setAfApproveMsg({ approve_reason: approveMsg });
    const id = data._id;
    const commentsAppr = afApproveMsg;
    var strippedHtml = commentsAppr.replace(/<[^>]+>/g, "");
    setApprovePopup(false);
    try {
      const res = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "A",
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          job_title: data.job_title,
          notes: strippedHtml,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        try {
          const response = await axiosData.get(
            `jobapply/getbyjobid/${location.state.id}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data === "") {
            setLoading(false);
            setPostJob(true);
          } else {
            setDatas(response.data);
            setApplyDetails(response.data);
            const datasset = response.data;
            setLoading(false);
            getFilterValue("allProfiles", datasset);
          }
          getFilterValue(activePaper, response.data);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const approvePendingCall = async (data) => {
    setSelectProfilePopup(false);
    const commentsAppr = formik.values.notes;
    var strippedHtml = commentsAppr.replace(/<[^>]+>/g, "");
    const id = data._id;
    const totalData = {
      id: id,
      status: "S",
      source_from: "",
      seeker_name: data.seeker_name,
      company_name: userDetails.company_name,
      seeker_email: data.seeker_email,
      job_title: data.job_title,
      notes: strippedHtml,
    };

    try {
      await axiosData.patch("jobapply/updatestatus", totalData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
    formik.handleReset();
  };
  const OfferedProfile = async (data) => {
    const id = afApproveDetails._id;

    if (!file) {
      setOfferLetterErr("Required");
    } else {
      try {
        const res = await axiosData.patch(
          "jobapply/updatestatus",
          {
            id: id,
            seeker_name: afApproveDetails.seeker_name,
            company_name: userDetails.company_name,
            seeker_email: afApproveDetails.seeker_email,
            job_title: afApproveDetails.job_title,
            status: "O",
            offerLetter: file,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setOfferLetter(false);
        if (res) {
          try {
            const response = await axiosData.get(
              `jobapply/getbyjobid/${location.state.id}`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data === "") {
              setLoading(false);
              setPostJob(true);
            } else {
              setDatas(response.data);
              setApplyDetails(response.data);
              const datasset = response.data;
              setLoading(false);
              getFilterValue("allProfiles", datasset);
            }
            getFilterValue(activePaper, response.data);
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const pendingProfilePutcall = (data) => {
    const id = data._id;
  };
  const rejectPutCall = async (data) => {
    setAfDeclineMsg({ decline_reason: declineMsg });
    const id = data._id;
    const commentsDec = afDeclineMsg;
    var strippedHtml = commentsDec.replace(/<[^>]+>/g, "");
    setRejectedPopup(false);
    try {
      const res = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "R",
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          job_title: data.job_title,
          notes: strippedHtml,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        try {
          const response = await axiosData.get(
            `jobapply/getbyjobid/${location.state.id}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data === "") {
            setLoading(false);
            setPostJob(true);
          } else {
            setDatas(response.data);
            setApplyDetails(response.data);
            const datasset = response.data;
            setLoading(false);
            getFilterValue("allProfiles", datasset);
          }
          getFilterValue(activePaper, response.data);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownload = (data) => {
    // Replace 'your_api_endpoint' with the actual API endpoint for file download.
    axiosData
      .get(`jobapply/offerletter/${data._id}`, {
        responseType: "blob", // This tells axios to treat the response as a binary blob
      })
      .then((response) => {
        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        // Replace 'file_name.extension' with the desired filename and extension for the downloaded file
        link.setAttribute("download", data.offerLetter.name);

        // Append the link to the DOM and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Clean up after download
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  const viewOfferLetter = async (data) => {
    try {
      const response = await axiosData.get(`jobapply/offerletter/${data._id}`, {
        responseType: "arraybuffer",
      });
      const docBuffer = Buffer.from(response.data, "binary");
      const docDataUrl = new Blob([docBuffer]);
      window.open(new Blob([docBuffer]), "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const interview = async (data, values) => {
    setAfDeclineMsg({ Hold_reason: declineMsg });
    const id = data._id;
    const commentsDec = formik.values.notes;
    var strippedHtml = commentsDec.replace(/<[^>]+>/g, "");
    const interviewDateee = formik.values.date;
    const inDate = interviewDateee.format("DD/MM/YYYY");
    const interviewTime = formik.values.time;
    const inTime = interviewTime.format("hh:mm a");
    values.date = inDate;
    values.time = inTime;
    values.notes = strippedHtml;
    const datas = {
      id: id,
      status: "I",
      seeker_name: data.seeker_name,
      company_name: userDetails.company_name,
      seeker_email: data.seeker_email,
      job_title: data.job_title,
      seeker_mobile_number: data.seeker_mobile_number,
    };
    const totalData = { ...datas, ...values };

    formik.handleReset();
    setRejectedPopup(false);
    try {
      const res = await axiosData.patch("jobapply/updatestatus", totalData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res) {
        try {
          const response = await axiosData.get(
            `jobapply/getbyjobid/${location.state.id}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data === "") {
            setLoading(false);
            setPostJob(true);
          } else {
            setDatas(response.data);
            setApplyDetails(response.data);
            const datasset = response.data;
            setLoading(false);
          }

          getFilterValue(activePaper, response.data);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const holdPutCall = async (data) => {
    setAfDeclineMsg({ Hold_reason: declineMsg });
    const id = data._id;
    const commentsDec = afDeclineMsg;
    var strippedHtml = commentsDec.replace(/<[^>]+>/g, "");
    setRejectedPopup(false);
    try {
      const res = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "H",
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          job_title: data.job_title,
          notes: strippedHtml,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        try {
          const response = await axiosData.get(
            `jobapply/getbyjobid/${location.state.id}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data === "") {
            setLoading(false);
            setPostJob(true);
          } else {
            setDatas(response.data);
            setApplyDetails(response.data);
            const datasset = response.data;
            setLoading(false);
            getFilterValue("allProfiles", datasset);
          }
          getFilterValue(activePaper, response.data);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const RejectedProfile = async (data, afDeclineMsg) => {
    const id = data._id;
    const noteProfile = afDeclineMsg;
    try {
      const res = await axiosData.post(
        "jobapply/RejectEmail",
        {
          _id: id,
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          notes: noteProfile,
          job_title: data.job_title,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        try {
          const response = await axiosData.get(
            `jobapply/getbyjobid/${location.state.id}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data === "") {
            setLoading(false);
            setPostJob(true);
          } else {
            setDatas(response.data);
            setApplyDetails(response.data);
            const datasset = response.data;
            setLoading(false);
            getFilterValue("allProfiles", datasset);
          }
          getFilterValue(activePaper, response.data);
        } catch (err) {
          console.log(err);
        }
      }
      setDeclineMsg("");
    } catch (err) {
      console.log(err);
    }
  };
  const getFilterValue = (value, alldatas) => {
    if (value === "allProfiles") {
      const get = alldatas;
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "pending") {
      const get = alldatas.filter((i) => i.status === "");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "profile-shortlisted") {
      const get = alldatas.filter((i) => i.status === "S");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "interview-scheduled") {
      const get = alldatas.filter((i) => i.status === "I");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "offered") {
      const get = alldatas.filter((i) => i.status === "A" || i.status === "O");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "pending-Selected") {
      const get = alldatas.filter((i) => i.status === "A");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "rejected") {
      const get = alldatas.filter((i) => i.status === "R");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    } else if (value === "hold") {
      const get = alldatas.filter((i) => i.status === "H");
      if (get.length === 0) {
        setApplyDetails("");
        setPendingJob(true);
      } else {
        setApplyDetails(get.reverse());
      }
    }
  };

  const allLoadMore = () => {
    setSeekersPagination(seekersPagination + 10);
  };

  const allLoadLess = () => {
    setSeekersPagination(seekersPagination - 10);
  };
  const count = (status) => {
    const data = [];
    datas.map((element, index) => {
      if (element.status === status) {
        data.push(element);
      }
    });

    return data.length;
  };
  const countS = (status, status1) => {
    const data = [];
    datas.map((element, index) => {
      if (element.status === status || element.status === status1) {
        data.push(element);
      }
    });

    return data.length;
  };
  const handleChangeNo = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };
  if (loading === false) {
    return (
      <div>
        <div className="arrow-link">
          <div className="headingPart">
            <Tooltip
              arrow
              onClick={() => {
                if (location.state.source_from?.includes("Database")) {
                  navigate(
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.EMPLOYERUPDATE,
                    {
                      state: userDetails.emailId,
                    }
                  );
                } else {
                  navigate(
                    ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB,
                    {
                      state: userDetails.userId,
                    }
                  );
                }
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                id="backIcon"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Typography id="headingSeekers">
              {location.state.source_from?.includes("Database")
                ? `Shortlisted Seekers Data From Resume Access`
                : `Applied Seekers For ${location.state.title}`}
            </Typography>
            <div className="filter">
              {false && (
                <Grid item xs={12} sm={6} md={1.7}>
                  <FormControl sx={{ width: 200, marginRight: "20px" }}>
                    <InputLabel sx={{ fontSize: "22px", color: "green" }}>
                      Filter By Profile Status
                    </InputLabel>
                    <Select
                      sx={{ marginTop: "20px", marginRight: "20px" }}
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterName.date}
                      onChange={(e) => {
                        setFilterName({ ...filterName, date: e.target.value });
                        getFilterValue(e.target.value, datas);
                      }}
                    >
                      <MenuItem value="allProfiles">All Profiles</MenuItem>
                      <MenuItem value="pending">Pending Profiles</MenuItem>
                      <MenuItem value="process">Processing profiles</MenuItem>
                      <MenuItem value="approve">Approved Profiles</MenuItem>
                      <MenuItem value="decline">Decline Profiles</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </div>
          </div>

          <div
            className={
              location.state.source_from?.includes("Database")
                ? "appliedMainCard1"
                : "appliedMainCard"
            }
          >
            {location.state.source_from?.includes("Database") ? null : (
              <Box
                sx={{ background: "#483d8b", color: "#ffffff" }}
                className={
                  activePaper === "applicants"
                    ? "activeAppliedDashboard"
                    : "AppliedDashboard"
                }
                onClick={() => {
                  // setChecked((prevChecked) => !prevChecked);
                  setChecked(false);
                  setFilterName({ ...filterName, date: "allProfiles" });
                  getFilterValue("allProfiles", datas);
                  setActivePaper("applicants");
                  setSeekersPagination(10);
                }}
              >
                <Typography id="heading">
                  <span id="headingSpan">
                    <PersonAddAltRoundedIcon className="Appliedseekericonsize" />
                  </span>
                  {datas.length}
                </Typography>
                <Typography id="heading1">Applicants</Typography>
              </Box>
            )}
            <Box
              className={
                activePaper === "profile-shortlisted"
                  ? "activeAppliedDashboard"
                  : "AppliedDashboard"
              }
              onClick={() => {
                setActivePaper("profile-shortlisted");
                setFilterName({ ...filterName, date: "profile-shortlisted" });
                getFilterValue("profile-shortlisted", datas);
                setSeekersPagination(10);
              }}
              sx={{ background: "#ff8c00", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <EventNoteSharpIcon className="Appliedseekericonsize" />
                </span>
                {count("S")}
                {/* {datas.map((element, index) => {  
                  let count = 0;
                  if (element.status === "S") {
                    count++;
                  }
                  if (index + 1 === datas.length) {
                    return count;
                  }
                })} */}
              </Typography>
              <Typography id="heading1">Profile Shortlisted</Typography>
            </Box>
            <Box
              className={
                activePaper === "interview-scheduled"
                  ? "activeAppliedDashboard"
                  : "AppliedDashboard"
              }
              onClick={() => {
                setActivePaper("interview-scheduled");
                setFilterName({ ...filterName, date: "interview-scheduled" });
                getFilterValue("interview-scheduled", datas);
                setSeekersPagination(10);
              }}
              // onClick={() => {
              //   if (approveDatas.length === 0) {
              //     setNoProfile(true);
              //   }
              //   handleClick("offered-jobs");
              //   getFilterValue("Total");
              //   setFilterName({ ...filterName, date: "Total" });
              // }}
              sx={{ background: "#1976d2", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <PendingActionsIcon className="Appliedseekericonsize" />
                </span>
                {count("I")}
              </Typography>
              <Typography id="heading1">Interview Scheduled</Typography>
            </Box>

            <Box
              className={
                activePaper === "offered"
                  ? "activeAppliedDashboard"
                  : "AppliedDashboard"
              }
              onClick={() => {
                setChecked(false);
                setActivePaper("offered");
                setFilterName({ ...filterName, date: "offered" });
                getFilterValue("offered", datas);
                setSeekersPagination(10);
              }}
              // onClick={() => {
              //   if (rejectedDatas.length === 0) {
              //     setNoProfile(true);
              //   }
              //   handleClick("rejected-profiles");
              //   getFilterValue("Total");
              //   setFilterName({ ...filterName, date: "Total" });
              // }}
              sx={{ background: "#008000", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <HowToRegIcon className="Appliedseekericonsize" />
                </span>
                {countS("A", "O")}
              </Typography>
              <Typography id="heading1">Selected</Typography>
            </Box>
            <Box
              className={
                activePaper === "hold"
                  ? "activeAppliedDashboard"
                  : "AppliedDashboard"
              }
              onClick={() => {
                setActivePaper("hold");
                setFilterName({ ...filterName, date: "hold" });
                getFilterValue("hold", datas);
                setSeekersPagination(10);
              }}
              // onClick={() => {
              //   if (rejectedDatas.length === 0) {
              //     setNoProfile(true);
              //   }
              //   handleClick("rejected-profiles");
              //   getFilterValue("Total");
              //   setFilterName({ ...filterName, date: "Total" });
              // }}
              sx={{ background: "#ffbe00", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <BackHandIcon className="Appliedseekericonsize" />
                </span>
                {count("H")}
              </Typography>
              <Typography id="heading1">Hold</Typography>
            </Box>
            <Box
              className={
                activePaper === "rejected"
                  ? "activeAppliedDashboard"
                  : "AppliedDashboard"
              }
              onClick={() => {
                setActivePaper("rejected");
                setFilterName({ ...filterName, date: "rejected" });
                getFilterValue("rejected", datas);
                setSeekersPagination(10);
              }}
              // onClick={() => {
              //   if (rejectedDatas.length === 0) {
              //     setNoProfile(true);
              //   }
              //   handleClick("rejected-profiles");
              //   getFilterValue("Total");
              //   setFilterName({ ...filterName, date: "Total" });
              // }}
              sx={{ background: "#ff0000", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <DangerousIcon className="Appliedseekericonsize" />
                </span>
                {count("R")}
              </Typography>
              <Typography id="heading1">Rejected</Typography>
            </Box>
          </div>
        </div>

        <div className="main-div">
          {false && (
            <div
              style={{
                width: "100%",
                textAlign: "end",
              }}
            >
              <Button
                sx={{ marginTop: 2, marginRight: 4 }}
                variant="contained"
                onClick={() => {
                  setActivePaper("applicants");
                  setFilterName({ ...filterName, date: "pending" });
                  getFilterValue("pending", datas);
                }}
              >
                Pending Shortlist
              </Button>
            </div>
          )}
          {activePaper === "applicants" && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Typography sx={{ margin: "0px 10px 7px 0px" }}>
                <b>Pending For Shortlist</b>
              </Typography>
              <Tooltip title={checked ? "Off" : "On"} arrow>
                <FormControlLabel
                  sx={{ marginTop: 2 }}
                  control={
                    <Switch
                      checked={checked}
                      onChange={() => {
                        setChecked((prevChecked) => !prevChecked);
                        if (checked === false) {
                          setActivePaper("applicants");
                          setFilterName({ ...filterName, date: "pending" });
                          getFilterValue("pending", datas);
                          setSeekersPagination(10);
                        } else {
                          setFilterName({ ...filterName, date: "allProfiles" });
                          getFilterValue("allProfiles", datas);
                          setActivePaper("applicants");
                          setSeekersPagination(10);
                        }
                      }}
                    />
                  }
                />
              </Tooltip>
            </div>
          )}
          {activePaper === "offered" && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Typography sx={{ margin: "0px 10px 7px 0px" }}>
                <b>Pending For Offer</b>
              </Typography>
              <Tooltip title={checked ? "Off" : "On"} arrow>
                <FormControlLabel
                  sx={{ marginTop: 2 }}
                  control={
                    <Switch
                      checked={checked}
                      onChange={() => {
                        setChecked((prevChecked) => !prevChecked);
                        if (checked === false) {
                          setActivePaper("offered");
                          setFilterName({
                            ...filterName,
                            date: "pending-Selected",
                          });
                          getFilterValue("pending-Selected", datas);
                          setSeekersPagination(10);
                        } else {
                          setActivePaper("offered");
                          setFilterName({ ...filterName, date: "offered" });
                          getFilterValue("offered", datas);
                          setSeekersPagination(10);
                        }
                      }}
                    />
                  }
                />
              </Tooltip>
            </div>
          )}

          <div className="cards">
            {applyDetails &&
              applyDetails.slice(0, seekersPagination).map((data) => (
                <>
                  <Card className="car" key={data._id}>
                    <CardContent>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Name:</span>
                          <div>{data.seeker_name}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Email ID:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.seeker_email}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Mobile Number:</span>
                          <div>{data.seeker_mobile_number}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Job Title:</span>
                          <div>{data.job_title}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">
                            {location.state.source_from?.includes("Database")
                              ? "Shortlisted Date:"
                              : "Apply Date:"}
                          </span>
                          <div>{data.apply_date}</div>
                        </div>
                      </Typography>
                      <Typography
                        sx={{
                          marginY: 0.5,
                          display:
                            data.notes === "" || data.notes === undefined
                              ? "none"
                              : "block",
                        }}
                      >
                        <div className="jobDetails">
                          <span className="font">Comments:</span>

                          <div>
                            <Button
                              size="small"
                              onClick={() => {
                                setViewCommentsPopup(true);
                                setViewComments(data.notes);
                              }}
                            >
                              View Comments
                            </Button>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Status:</span>
                          <div>
                            {data.status === "" ? (
                              "Processing"
                            ) : data.status === "S" ? (
                              <span style={{ color: "#ff8c00" }}>
                                Shortlisted
                              </span>
                            ) : data.status === "I" ? (
                              <span style={{ color: "#1976d2" }}>
                                Take an Interview
                              </span>
                            ) : data.status === "A" ? (
                              <span style={{ color: "#008000" }}>Selected</span>
                            ) : data.status === "H" ? (
                              <span style={{ color: "#f3b80c" }}>Hold</span>
                            ) : data.status === "O" ? (
                              <span style={{ color: "#ed1e70" }}>Offered</span>
                            ) : (
                              <span style={{ color: "#ff0000" }}>
                                {" "}
                                Rejected
                              </span>
                            )}
                          </div>
                        </div>
                      </Typography>
                    </CardContent>

                    <CardActions
                      sx={{
                        alignItems: "end",
                      }}
                    >
                      <Button
                        sx={{
                          marginRight: "5px",
                        }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          viewProfile(data.seeker_id);
                          handleChange();
                        }}
                      >
                        View Profile
                      </Button>

                      {activePaper === "offered" && data.status === "A" ? (
                        <Button
                          // disabled={data.status === "O" ? true : false}
                          sx={{ marginLeft: 2 }}
                          variant="contained"
                          size="small"
                          color="info"
                          onClick={() => {
                            offerdPopupMethod(data);
                          }}
                        >
                          Upload Offer letter
                        </Button>
                      ) : activePaper === "offered" && data.status === "O" ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={color}
                          onClick={() => {
                            //handleDownload(data);
                            //viewOfferLetter(data);
                            saveAs(
                              `${baseUrl}/jobapply/offerletter/${data._id}`,
                              data.offerLetter.name
                            );
                          }}
                        >
                          View Offer Letter
                        </Button>
                      ) : (
                        ""
                      )}
                      {activePaper === "applicants" && (
                        <Button
                          sx={{
                            marginRight: "5px",
                          }}
                          disabled={data.status === "" ? false : true}
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => selectProfile(data)}
                        >
                          Shortlist
                        </Button>
                      )}
                      {activePaper === "profile-shortlisted" && (
                        <Button
                          sx={{
                            marginRight: "5px",
                          }}
                          disabled={data.status !== "S"}
                          color="primary"
                          // disabled={data.status === "I" ? true : false}
                          variant="contained"
                          size="small"
                          onClick={() => selectProfile(data)}
                        >
                          schedule an interview
                        </Button>
                      )}

                      {(activePaper === "interview-scheduled" ||
                        activePaper === "hold") && (
                        <>
                          <Button
                            sx={{
                              marginRight: "5px",
                            }}
                            // disabled={data.status === "A" ? true : false}
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => approvePopupMethod(data)}
                          >
                            Selected
                          </Button>
                        </>
                      )}
                      {(activePaper === "interview-scheduled" ||
                        activePaper === "hold") && (
                        <Button
                          sx={{
                            marginRight: "5px",
                          }}
                          // disabled={data.status === "R" ? true : false}
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => rejectedPopupMethod(data)}
                        >
                          Rejected
                        </Button>
                      )}
                      {(activePaper === "hold" ||
                        activePaper === "rejected") && (
                        <Button
                          sx={{
                            marginRight: "5px",
                          }}
                          // disabled={data.status === "A" ? true : false}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => selectProfile(data)}
                        >
                          Reschedule
                        </Button>
                      )}
                      {activePaper === "interview-scheduled" && (
                        <Button
                          // sx={{
                          //
                          // }}
                          sx={styles}
                          variant="contained"
                          size="small"
                          onClick={() => holdPopupMethod(data)}
                        >
                          Hold
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </>
              ))}
          </div>
          <div className="pagination">
            {seekersPagination > 10 && (
              <Button variant="outlined" onClick={allLoadLess}>
                Load Less
              </Button>
            )}
            {seekersPagination < applyDetails.length && (
              <Button variant="contained" onClick={allLoadMore}>
                Load More
              </Button>
            )}
          </div>
          <SeekerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOneSeeker}
            setOpen={setOpen}
          />
          <Dialog
            open={postJob}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" } }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
                {location.state.source_from?.includes("Database")
                  ? `No Seekers shortlisted from resume access!`
                  : `No One Applied for this Job Post!`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (
                    location.state.source_from?.includes(
                      `Database-${location.state.id}`
                    )
                  ) {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.EMPLOYERUPDATE,
                      {
                        state: userDetails.emailId,
                      }
                    );
                  } else {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.POSTEDJOB,
                      {
                        state: userDetails.userId,
                      }
                    );
                  }

                  setPostJob(false);
                }}
                autoFocus
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={selectProfilePopup}
            afApproveDetails={afApproveDetails}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <DialogTitle
                id="alert-dialog-title"
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                {"Confirmation"}
              </DialogTitle>

              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText id="alert-dialog-description">
                  <Typography sx={{ mb: 2, color: "black", fontSize: "17px" }}>
                    <b>
                      {afPendingDetails.seeker_name} Selected for
                      {activePaper === "applicants"
                        ? " Shortlisted"
                        : " Interview"}
                    </b>
                  </Typography>
                  {activePaper !== "applicants" && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Interviewer Name"
                          name="interviewer_name"
                          value={formik.values.interviewer_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                        {formik.touched.interviewer_name &&
                        formik.errors.interviewer_name ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.interviewer_name}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Interviewer Email"
                          name="interviewer_email"
                          value={formik.values.interviewer_email}
                          onChange={(e) => {
                            setFocus(false);
                            formik.setFieldValue(
                              "interviewer_email",
                              e.target.value
                            );
                          }}
                          onBlurCapture={() => {
                            setFocus(true);
                          }}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                        {formik.touched.interviewer_email &&
                        formik.errors.interviewer_email &&
                        focus ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.interviewer_email}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label=" Interviewer Phone number"
                          name="interviewer_phone_number"
                          value={formik.values.interviewer_phone_number}
                          type="number"
                          onBlurCapture={() => {
                            setFocusNo(true);
                          }}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={handleChangeNo}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                        {formik.touched.interviewer_phone_number &&
                        formik.errors.interviewer_phone_number &&
                        focusNo ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.interviewer_phone_number}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            disablePast
                            label="Interview Date"
                            name="date" // Make sure the name matches the field name in initialValues
                            format="DD/MM/YYYY"
                            value={formik.values.date}
                            onChange={(date) =>
                              formik.setFieldValue("date", date)
                            }
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  formik.touched.date &&
                                  Boolean(formik.errors.date)
                                }
                                helperText={
                                  formik.touched.date && formik.errors.date
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {formik.touched.date && formik.errors.date ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.date}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            label="Interview Time"
                            value={formik.values.time}
                            onChange={(newValue) => {
                              formik.setFieldValue("time", newValue);
                            }}
                            onBlur={formik.handleBlur("time")}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        {formik.touched.time && formik.errors.time && (
                          <div style={{ color: "red" }}>
                            {formik.errors.time}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </DialogContentText>

                <Grid item xs={12} sx={{ marginTop: 1 }}>
                  <Typography>Comments:</Typography>
                  <ReactQuill
                    name="notes"
                    value={formik.values.notes}
                    onChange={(e) => {
                      formik.setFieldValue("notes", e);
                    }}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ["link", "image"],
                        ["clean"],
                        [{}],
                      ],
                    }}
                    multiline
                    rows={3}
                    placeholder="Write something amazing..."
                    className="scrollable-container-postjob"
                  />
                  {formik.touched.notes && formik.errors.notes ? (
                    <div style={{ color: "red" }}>{formik.errors.notes}</div>
                  ) : null}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setSelectProfilePopup(false);
                    formik.handleReset();
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={formik.isValid && formik.dirty ? false : true}
                  type="submit"
                  // onClick={() => {
                  //   if (activePaper === "applicants") {
                  //     approvePendingCall(afPendingDetails);
                  //     getFilterValue("allProfiles", datas);
                  //     formik.handleReset("");
                  //   } else if (activePaper === "profile-shortlisted") {
                  //     interview(afPendingDetails);
                  //     getFilterValue("profile-shortlisted", datas);
                  //   } else if (
                  //     activePaper === "hold" ||
                  //     activePaper === "rejected"
                  //   ) {
                  //     interview(afPendingDetails);
                  //   }
                  //   setSelectProfilePopup(false);
                  // }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
            open={rejectedPopup}
            afRejectedDetails={afRejectedDetails}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
              <DialogContentText id="alert-dialog-description">
                <b style={{ color: "black" }}>Are you sure want to Reject ?</b>
              </DialogContentText>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Typography>Comments:</Typography>
                <ReactQuill
                  defaultValue={"Reason of Rejected your Profile is "}
                  name="notes"
                  onChange={(e) => {
                    setAfDeclineMsg(e);
                  }}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{}],
                    ],
                  }}
                  multiline
                  rows={3}
                  placeholder="Write something amazing..."
                  className="scrollable-container-postjob"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setRejectedPopup(false);
                  formik.handleReset("");
                }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  rejectPutCall(afRejectedDetails);
                  getFilterValue("allProfiles", datas);
                  setFilterName({ ...filterName, date: "allProfiles" });
                  formik.handleReset("");
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={holdpopup}
            afRejectedDetails={afRejectedDetails}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
              <DialogContentText id="alert-dialog-description">
                <b style={{ color: "black" }}>Are you sure want to Hold ?</b>
              </DialogContentText>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Typography>Comments:</Typography>
                <ReactQuill
                  defaultValue={"Reason of hold your Profile is "}
                  name="notes"
                  onChange={(e) => {
                    setAfDeclineMsg(e);
                  }}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{}],
                    ],
                  }}
                  multiline
                  rows={3}
                  placeholder="Write something amazing..."
                  className="scrollable-container-postjob"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setholdpopup(false);
                  formik.handleReset("");
                }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  holdPutCall(afRejectedDetails);

                  formik.handleReset("");
                  setholdpopup(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={approvePopup}
            afApproveDetails={afApproveDetails}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
              <DialogContentText id="alert-dialog-description">
                <b style={{ color: "black" }}>Are you sure want to Select ?</b>
              </DialogContentText>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Typography>Comments:</Typography>
                <ReactQuill
                  name="notes"
                  defaultValue={"Reason of selected Your profile is"}
                  onChange={(e) => {
                    setAfApproveMsg(e);
                  }}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{}],
                    ],
                  }}
                  multiline
                  rows={3}
                  placeholder="Write something amazing..."
                  className="scrollable-container-postjob"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setApprovePopup(false);
                  formik.handleReset("");
                }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  approvePutCall(afApproveDetails);
                  getFilterValue("allProfiles", datas);

                  setFilterName({ ...filterName, date: "allProfiles" });
                  formik.handleReset("");
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={pendingJob}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" } }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
                No Seekers Available
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (location.state.source_from?.includes("Database")) {
                    const get = datas.filter((i) => i.status === "S");
                    if (get.length === 0) {
                      const get2 = datas.filter((i) => i.status === "I");
                      if (get2.length === 0) {
                        const get3 = datas.filter((i) => i.status === "A");
                        if (get3.length === 0) {
                          const get4 = datas.filter((i) => i.status === "H");
                          if (get4.length === 0) {
                            const get5 = datas.filter((i) => i.status === "R");
                            if (get5.length === 0) {
                              setPendingJob(false);
                              setChecked(false);
                            } else {
                              setPendingJob(false);
                              setActivePaper("rejected");
                              setFilterName({
                                ...filterName,
                                date: "rejected",
                              });
                              getFilterValue("rejected", datas);
                              setChecked(false);
                            }
                          } else {
                            setPendingJob(false);
                            setActivePaper("hold");
                            setFilterName({ ...filterName, date: "hold" });
                            getFilterValue("hold", datas);
                            setChecked(false);
                          }
                        } else {
                          setPendingJob(false);
                          setActivePaper("offered");
                          setFilterName({ ...filterName, date: "offered" });
                          getFilterValue("offered", datas);
                          setChecked(false);
                        }
                      } else {
                        setPendingJob(false);
                        setActivePaper("interview-scheduled");
                        setFilterName({
                          ...filterName,
                          date: "interview-scheduled",
                        });
                        getFilterValue("interview-scheduled", datas);
                        setChecked(false);
                      }
                    } else {
                      setPendingJob(false);
                      setActivePaper("profile-shortlisted");
                      setFilterName({
                        ...filterName,
                        date: "profile-shortlisted",
                      });
                      getFilterValue("profile-shortlisted", datas);
                      setChecked(false);
                    }
                  } else {
                    setPendingJob(false);
                    getFilterValue("allProfiles", datas);
                    setFilterName({ ...filterName, date: "allProfiles" });
                    setActivePaper("applicants");
                    setChecked(false);
                  }
                }}
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={offerLetter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "400px" }, zIndex: "99999" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              Offer Letter
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container>
                  {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Typography>Comments:</Typography>
                    <ReactQuill
                      name="notes"
                      defaultValue={"Reason of selected Your profile is"}
                      onChange={(e) => {
                        setAfApproveMsg(e);
                      }}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ color: [] }, { background: [] }],
                          [{ font: [] }],
                          [{ align: [] }],
                          ["link", "image"],
                          ["clean"],
                          [{}],
                        ],
                      }}
                      multiline
                      rows={3}
                      placeholder="Write something amazing..."
                      className="scrollable-container-postjob"
                    />
                  </Grid> */}
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Typography sx={{ color: "black", fontSize: "18px" }}>
                      Upload Offer Letter
                      <input
                        type="file"
                        name="proofUpload"
                        onBlur={formik.handleBlur}
                        ref={fileInputField}
                        style={{ display: "none" }}
                        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,"
                        onChange={(e) => {
                          if (e.target.files[0].size > MAX_FILE_SIZE) {
                            setOfferLetterErr(
                              "Offer Letter must be less than 10 MB"
                            );
                            setfile("");
                            fileInputField.current.value = "";
                          } else {
                            setOfferLetterErr("");
                            setfile(e.target.files[0]);
                          }
                        }}
                      />
                      <Tooltip title="Upload file" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            fileInputField.current.click();
                          }}
                        >
                          <img
                            width="25"
                            height="25"
                            src="https://img.icons8.com/ios-filled/50/228BE6/upload-document.png"
                            alt="upload-document"
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <div style={{ color: "red" }}>{offerLetterErr}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {file ? (
                        <>
                          {file.name ? (
                            <>
                              <Typography sx={{ color: "green", mt: 1 }}>
                                {file.name}{" "}
                                <span
                                  style={
                                    file.name
                                      ? {
                                          color: "green",
                                          fontSize: "12px",
                                          marginBottom: "20px",
                                        }
                                      : { color: "red", fontSize: "12px" }
                                  }
                                >
                                  {file.size
                                    ? `${file / 1024}` >= 1024
                                      ? `-(${parseFloat(
                                          file.size / 1024 / 1024
                                        ).toFixed(3)}Mb)`
                                      : `-(${parseFloat(
                                          file.size / 1024
                                        ).toFixed(3)}Kb)`
                                    : null}
                                </span>
                              </Typography>
                              <IconButton aria-label="delete">
                                <CloseIcon
                                  sx={{ color: "red" }}
                                  onClick={() => {
                                    deleteFile();
                                  }}
                                />
                              </IconButton>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                    <Typography>Supported Files</Typography>
                    <Typography>.pdf/.doc/.docx/.jpg/.jpeg/.png/</Typography>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOfferLetter(false);
                  setOfferLetterErr("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  OfferedProfile();
                }}
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={viewCommentsPopup}
            viewComments={viewComments}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "99999" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Comments"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                {viewComments}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setViewCommentsPopup(false);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default AppliedSeekers;
