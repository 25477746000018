import {
  Button,
  Box,
  Slider,
  Tooltip,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLocation } from "react-router";
import Context from "./Context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import React from "react";
import useAxios from "../../../api/useAxios";
import profileImg from "./profileimg/img.webp";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Buffer } from "buffer";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileChange = ({ setput, number }) => {
  const [image, setimage] = useState();
  const [open, setOpen] = useState(false);
  const [ref, setr] = useState(false);
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Location = useLocation();
  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: " ",
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (e, Context) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      if (croppedImg) {
        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
      }

      
      var block = croppedImg.split(";");

     
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1]; 
      const img = b64toBlob(realData, contentType);

      const values = { profileUpload: "" };
      if (picture) {
        fileInputField.current.value = "";
        values.profileUpload = img;
        try {
          const response = await axiosData.delete(
            `seekers/ProfileImage/${number}`,
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

        } catch (err) {
          console.log(err);
        }

        setIsLoading(true);

        const response = await axiosData.put(
          `seeker/email/${Location.state}`,
          values,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response) {
          setput(true);
          setimage(null);
          setOpen(false);
          setr(true);
        }
      }
    }
  };
  const fileInputField = useRef(null);

  const [imageSize,setImageSize] = useState(false)
  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];
  
    // Check if a file is selected
    if (!imgFile) {
      return;
    }
  
    // Check if the file size is less than 2MB
    const maxSize = 5 * 1024 * 1024; // 2MB in bytes
    if (imgFile.size > maxSize) {
      // Handle the case when the image exceeds the size limit
      setImageSize(true)
      fileInputField.current.value = "";
      return;
    }
  
    // If the file is valid, proceed with setting the state
    handleClickOpen();
    setPicture({
      ...picture,
      img: imgFile,
      cropperOpen: true,
    });
  };

  const fun = async () => {
    const response = await axiosData.get(
      `/seekers/email/${Location.state}`
    );

    if (response.data) {
      try {
        const response1 = await axiosData.get(`seekers/profile/${response.data._id}`, {
          responseType: "arraybuffer",
        });

        const docBuffer = Buffer.from(response1.data, 'binary');
        const docDataUrl = URL.createObjectURL(new Blob([docBuffer]));

        setimage(docDataUrl);
      } catch (error) {
        console.error(error);
      }
    //  setimage(response.data.mobile_number);
      setr(false);
    }
    setIsLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setimage(null);
    setTimeout(() => {
      if (Location.state !== undefined) {
        fun();
      } else {
        setr(true);
      }
    }, 6000);

    setr(false);
  }, [ref]);

  return (
    <Context.Consumer>
      {(Context) => {
        const id = Context.profile;

        return (
          <div>
            <Dialog
          TransitionComponent={Transition}
          open={imageSize}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
           <WarningAmberOutlinedIcon sx={{mb:-0.5}}/> Photo Upload Limit
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ fontWeight: 600, mt:2 }}>
            Note: Image file size exceeds the allowed limit of 2MB. Please
              choose a smaller size image.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setImageSize(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
            <Box display="flex">
              <Box width="25%">
                <div style={{ position: "relative" }}>
                  <img
                    style={{ display: "block" }}
                    alt=""
                    id="profileImage"
                    src={
                      image !== undefined
                        ? image
                          ? Context.profile.profileUpload !== undefined &&
                            Context.profile.profileUpload !== ""
                            ? image
                            : profileImg
                          : profileImg
                        : profileImg
                    }
                    onError={(e) => {
                      e.target.src =profileImg ;
                    }}
                    className="updateProfileImage"
                  />

                  {isLoading === true ? (
                    <CircularProgress
                      className="CircularProgressIcon"
                      size={26}
                      style={{
                        color: "rgba(25,118,210,1)",
                        position: "absolute",
                      }}
                    />
                  ) : (
                    <Tooltip title="Upload Profile" placement="top" arrow>
                      <CameraAltIcon
                        onClick={() => {
                          fileInputField.current.click();
                        }}
                        className="cameraicon"
                        sx={{
                          color: "rgba(25,118,210,1)",
                          cursor: "pointer",
                          position: "absolute",
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <input
                  ref={fileInputField}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Box>

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {picture.cropperOpen && (
                      <Box display="block">
                        <AvatarEditor
                          ref={setEditorRef}
                          image={picture.img}
                          width={200}
                          height={200}
                          border={20}
                          color={[255, 255, 255, 0.6]}
                          rotate={0}
                          scale={picture.zoom}
                        />
                        <Slider
                          aria-label="raceSlider"
                          value={picture.zoom}
                          min={1}
                          max={10}
                          step={0.1}
                          onChange={handleSlider}
                        ></Slider>
                      </Box>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleCancel();
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSave(Context);
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </div>
        );
      }}
    </Context.Consumer>
  );
};

export default ProfileChange;
