import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DashboardRounded as DashboardRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
  WorkRounded as WorkRoundedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  DataUsageSharp as DataUsageSharpIcon,
  ContactSupportSharp as ContactSupportSharpIcon,
  PostAdd as PostAddIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";

import { useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import useAxios from "../../../api/useAxios";
import LogOutDialog from "../../admin-panel/admin-components/DialogBoxes/LogOutDialog";
import logo from "../../../img/logo_for-landingpage-3.png";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      [theme.breakpoints.down("sm")]: { marginLeft: 0 },
      marginLeft: `${drawerWidth + 50}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [[theme.breakpoints.up("md")] || [theme.breakpoints.down("md")]]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth + 50}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("sm")]: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MPconsitiuencyPanel() {
  const theme = useTheme();
  let act = sessionStorage.getItem("mp_active");
  let actall = sessionStorage.getItem("mpall_active");
  let actneed = sessionStorage.getItem("mpneed_active");
  let actneedall = sessionStorage.getItem("mpneedall_active");
  let actneedshort = sessionStorage.getItem("mpneedshort_activ");
  let actneedsupport = sessionStorage.getItem("mpneedsupport_activ");
  const navigate = useNavigate();
  const axiosData = useAxios();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);
  const [openLog, setOpenLog] = useState(false);
  const [MPName, setMPName] = useState(null);
  const [mlaConstituencyList, setMlaConstituencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [collapseOpenNeed, setCollapseOpenNeed] = useState({
    mp1: false,
    mp2: false,
    mp3: false,
    mp4: false,
    mp5: false,
    mp6: false,
  });
  const [mlaShort, setMlaShort] = useState({
    mlaS0: [],
    mlaS1: [],
    mlaS2: [],
    mlaS3: [],
    mlaS4: [],
    mlaS5: [],
  });
  const [mlaC, setMlaC] = useState({
    mlaC0: [],
    mlaC1: [],
    mlaC2: [],
    mlaC3: [],
    mlaC4: [],
    mlaC5: [],
    mlaN0: [],
    mlaN1: [],
    mlaN2: [],
    mlaN3: [],
    mlaN4: [],
    mlaN5: [],
  });
  const [collapseOpen, setCollapseOpen] = useState({
    mp1: false,
    mp2: false,
    mp3: false,
    mp4: false,
    mp5: false,
    mp6: false,
  });
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mp = JSON.parse(localStorage.getItem("mp"));
  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mpconstituencyseeker", {
        email_id: mp,
      });
      const { data } = MpData;

      setMPName(data[0].mp_constituency);
      setMlaConstituencyList(data[0].mla_constituency_list);
      const [mc0, mc1, mc2, mc3, mc4, mc5] = [
        data[0].mla_constituency_list[0].seekers,
        data[0].mla_constituency_list[1].seekers,
        data[0].mla_constituency_list[2].seekers,
        data[0].mla_constituency_list[3].seekers,
        data[0].mla_constituency_list[4].seekers,
        data[0].mla_constituency_list[5].seekers,
      ];

      const [mcn0, mcn1, mcn2, mcn3, mcn4, mcn5] = [
        data[0].mla_constituency_list[0].needSupport,
        data[0].mla_constituency_list[1].needSupport,
        data[0].mla_constituency_list[2].needSupport,
        data[0].mla_constituency_list[3].needSupport,
        data[0].mla_constituency_list[4].needSupport,
        data[0].mla_constituency_list[5].needSupport,
      ];
      const [mcs0, mcs1, mcs2, mcs3, mcs4, mcs5] = [
        data[0].mla_constituency_list[0].shortListed,
        data[0].mla_constituency_list[1].shortListed,
        data[0].mla_constituency_list[2].shortListed,
        data[0].mla_constituency_list[3].shortListed,
        data[0].mla_constituency_list[4].shortListed,
        data[0].mla_constituency_list[5].shortListed,
      ];
      setMlaC({
        ...mlaC,
        mlaC0: mc0.reverse(),
        mlaC1: mc1.reverse(),
        mlaC2: mc2.reverse(),
        mlaC3: mc3.reverse(),
        mlaC4: mc4.reverse(),
        mlaC5: mc5.reverse(),
        mlaN0: mcn0.reverse(),
        mlaN1: mcn1.reverse(),
        mlaN2: mcn2.reverse(),
        mlaN3: mcn3.reverse(),
        mlaN4: mcn4.reverse(),
        mlaN5: mcn5.reverse(),
      });
      setMlaShort({
        ...mlaShort,
        mlaS0: mcs0.reverse(),
        mlaS1: mcs1.reverse(),
        mlaS2: mcs2.reverse(),
        mlaS3: mcs3.reverse(),
        mlaS4: mcs4.reverse(),
        mlaS5: mcs5.reverse(),
      });
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoading(false);
  };
  const handleNavigateSeek = (index) => {
    let navigateSeek = [];
    if (0 === index) {
      navigateSeek = mlaC.mlaC0;
    } else if (1 === index) {
      navigateSeek = mlaC.mlaC1;
    } else if (2 === index) {
      navigateSeek = mlaC.mlaC2;
    } else if (3 === index) {
      navigateSeek = mlaC.mlaC3;
    } else if (4 === index) {
      navigateSeek = mlaC.mlaC4;
    } else if (5 === index) {
      navigateSeek = mlaC.mlaC5;
    }
    navigate(ROUTINGDATA.MP_MLAComponent, {
      state: {
        mla: mlaConstituencyList[index],
        seekers: navigateSeek,
        load: isLoading,
      },
    });
  };
  const handleNavigateNeed = (index) => {
    let navigateNeed = [];
    if (0 === index) {
      navigateNeed = mlaC.mlaN0;
    } else if (1 === index) {
      navigateNeed = mlaC.mlaN1;
    } else if (2 === index) {
      navigateNeed = mlaC.mlaN2;
    } else if (3 === index) {
      navigateNeed = mlaC.mlaN3;
    } else if (4 === index) {
      navigateNeed = mlaC.mlaN4;
    } else if (5 === index) {
      navigateNeed = mlaC.mlaN5;
    }
    navigate(ROUTINGDATA.MP_MLAneedSupport, {
      state: {
        mla: mlaConstituencyList[index],
        needSupport: navigateNeed,
        load: isLoading,
        index: index,
      },
    });
  };
  const handleNavigateShort = (index) => {
    let navigateShort = [];
    if (0 === index) {
      navigateShort = mlaShort.mlaS0;
    } else if (1 === index) {
      navigateShort = mlaShort.mlaS1;
    } else if (2 === index) {
      navigateShort = mlaShort.mlaS2;
    } else if (3 === index) {
      navigateShort = mlaShort.mlaS3;
    } else if (4 === index) {
      navigateShort = mlaShort.mlaS4;
    } else if (5 === index) {
      navigateShort = mlaShort.mlaS5;
    }
    navigate(ROUTINGDATA.MP_MLAShortlisted, {
      state: {
        mla: mlaConstituencyList[index],
        shortListed: navigateShort,
        load: isLoading,
        index: index,
      },
    });
  };

  const handleClick = (event) => {
    sessionStorage.setItem("mp_active", event);

    if (event === "dash") {
      sessionStorage.removeItem("mpneed_active");
      sessionStorage.removeItem("mpall_active");
      sessionStorage.removeItem("mpneedshort_active");
      sessionStorage.removeItem("mpneedall_active");
      sessionStorage.removeItem("mpneedsupport_activ");
      sessionStorage.removeItem(`mpmla_shortradio`);
      for (let i = 0; i < mlaConstituencyList.length; i++) {
        sessionStorage.removeItem(`mpmlaneedradio${i}`);
        sessionStorage.removeItem(`shortradio${i}`);
      }
      sessionStorage.removeItem(`mp_shortradio`);
      sessionStorage.removeItem("mp_typeofsupport");
    }
  };
  const handleAllClick = (event) => {
    sessionStorage.setItem("mpall_active", event);
    sessionStorage.removeItem("mpneed_active");
  };
  const handleNeedClick = (event) => {
    sessionStorage.setItem("mpneed_active", event);
    sessionStorage.removeItem("mpall_active");
  };
  const handleNeedAllClick = (event) => {
    sessionStorage.setItem("mpneedall_active", event);
    sessionStorage.removeItem("mpneedshort_activ");
    sessionStorage.removeItem("mpall_active");
    sessionStorage.removeItem("mpneedsupport_activ");
  };
  const handleNeedShortClick = (event) => {
    sessionStorage.setItem("mpneedshort_activ", event);
    sessionStorage.removeItem("mpneedall_active");
    sessionStorage.removeItem("mpall_active");
    sessionStorage.removeItem("mpneedsupport_activ");
  };
  const handleNeedSupportClick = (event) => {
    sessionStorage.setItem("mpneedsupport_activ", event);
    sessionStorage.removeItem("mpneedall_active");
    sessionStorage.removeItem("mpall_active");
    sessionStorage.removeItem("mpneedshort_activ");
  };
  useEffect(() => {
    if (mp === null || mp === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getMpEmailId();
    }
  }, []);
  if (isLoading === false) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ backgroundColor: " #055779" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ textTransform: "capitalize" }}
            >
              {MPName} Constituency
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth + 50,
              boxSizing: "border-box",
              backgroundColor: "#339fcd",
            },
            [theme.breakpoints.down("sm")]: {
              "& .MuiDrawer-paper": {
                backgroundColor: "#339fcd",
                width: drawerWidth,
                flexShrink: 0,
              },
            },
          }}
          variant={
            isMobile
              ? "temporary"
              : isDevice || isDeviceDown
              ? "persistent"
              : null
          }
          anchor="left"
          open={open}
        >
          <DrawerHeader className="header">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
               <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img className="image"  src={logo} alt="" />
            </div>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </Box>
          </DrawerHeader>
          <List>
            <ListItemText>
              <span className="sidehead">Master</span>
            </ListItemText>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "dash" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MPDASHBOARD);
                  handleClick("dash");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <DashboardRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Dashboard</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "mp_seek" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MP_ALL_DATA);
                  handleClick("mp_seek");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <DataUsageSharpIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">All Seeker Data</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "mp_need" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MP_NEEDSUPPORT);
                  handleClick("mp_need");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <ContactSupportSharpIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Need Support</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "mp_short" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MP_SHORTLISTED);
                  handleClick("mp_short");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <PostAddIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Shortlisted</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "mp_supported" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MP_Supported);
                  handleClick("mp_supported");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <FavoriteRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Supported</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItemText>
              <span className="sidehead">MLA Constituencies</span>
            </ListItemText>
            {mlaConstituencyList.map((item, index) => (
              <>
                {" "}
                <ListItem sx={{ padding: "1px 10px" }}>
                  <ListItemButton
                    sx={{ borderRadius: "10px" }}
                    className={act === `mp${index}` ? "active" : "link-active"}
                    onClick={() => {
                      setCollapseOpen({
                        ...collapseOpen,
                        [`mp${index}`]: !collapseOpen[`mp${index}`],
                      });
                      if (collapseOpenNeed[`mp${index}`] === true) {
                        setCollapseOpenNeed({
                          ...collapseOpenNeed,
                          [`mp${index}`]: false,
                        });
                      }

                      handleClick(`mp${index}`);
                    }}
                  >
                    <ListItemIcon>
                      <WorkRoundedIcon className="text" />
                    </ListItemIcon>
                    <ListItemText
                      className="text"
                      sx={{
                        color: "#fff",
                      }}
                    >
                      <span className="font">{item.mla_constituency}</span>{" "}
                    </ListItemText>
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      {collapseOpen[`mp${index}`] ? (
                        <ExpandLessIcon sx={{ color: "white" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: "white" }} />
                      )}
                    </span>
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={collapseOpen[`mp${index}`]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem>
                      <ListItemButton
                        sx={{ borderRadius: "10px", pl: 4 }}
                        className={
                          actall === `mpall${index}` ? "active" : "link-active"
                        }
                        onClick={() => {
                          handleNavigateSeek(index);
                          setCollapseOpen(false);
                          setCollapseOpenNeed(false);
                          handleAllClick(`mpall${index}`);
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <DataUsageSharpIcon className="text" />
                        </ListItemIcon>
                        <ListItemText className="text" sx={{ color: "#fff" }}>
                          <span className="font">All Job Seekers Data</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        sx={{ borderRadius: "10px", pl: 4 }}
                        className={
                          actneed === `mpneed${index}`
                            ? "active"
                            : "link-active"
                        }
                        onClick={() => {
                          handleNeedClick(`mpneed${index}`);

                          setCollapseOpenNeed({
                            ...collapseOpenNeed,
                            [`mp${index}`]: !collapseOpenNeed[`mp${index}`],
                          });
                        }}
                      >
                        <ListItemIcon>
                          <ContactSupportSharpIcon className="text" />
                        </ListItemIcon>
                        <ListItemText className="text" sx={{ color: "#fff" }}>
                          <span className="font">Need Support</span>
                        </ListItemText>{" "}
                        <span
                          style={{
                            display: "flex",
                          }}
                        >
                          {collapseOpenNeed[`mp${index}`] ? (
                            <ExpandLessIcon sx={{ color: "white" }} />
                          ) : (
                            <ExpandMoreIcon sx={{ color: "white" }} />
                          )}
                        </span>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
                <Collapse
                  in={collapseOpenNeed[`mp${index}`]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ borderRadius: "10px", pl: 4 }}
                      className={
                        actneedall === `mpneedall${index}`
                          ? "active"
                          : "link-active"
                      }
                      onClick={() => {
                        handleNeedAllClick(`mpneedall${index}`);
                        handleNavigateNeed(index);
                        handleDrawerClose();
                        setCollapseOpenNeed(false);
                        setCollapseOpen(false);
                      }}
                    >
                      <ListItemIcon>
                        <DataUsageSharpIcon className="text" />
                      </ListItemIcon>
                      <ListItemText className="text" sx={{ color: "#fff" }}>
                        <span className="font">All Data</span>
                      </ListItemText>
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: "10px", pl: 4 }}
                      className={
                        actneedshort === `mpneedshort${index}`
                          ? "active"
                          : "link-active"
                      }
                      onClick={() => {
                        handleNavigateShort(index);

                        handleNeedShortClick(`mpneedshort${index}`);
                        setCollapseOpenNeed(false);
                        setCollapseOpen(false);
                        handleDrawerClose();
                      }}
                    >
                      <ListItemIcon>
                        <PostAddIcon className="text" />
                      </ListItemIcon>
                      <ListItemText className="text" sx={{ color: "#fff" }}>
                        <span className="font">Shortlisted Data</span>
                      </ListItemText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: "10px", pl: 4 }}
                      className={
                        actneedsupport === `mpneedsupport${index}`
                          ? "active"
                          : "link-active"
                      }
                      onClick={() => {
                        navigate(ROUTINGDATA.MP_MLASupported, {
                          state: {
                            mla: mlaConstituencyList[index],

                            load: isLoading,
                            index: index,
                          },
                        });
                        handleNeedSupportClick(`mpneedsupport${index}`);
                        setCollapseOpenNeed(false);
                        setCollapseOpen(false);
                        handleDrawerClose();
                      }}
                    >
                      <ListItemIcon>
                        <FavoriteRoundedIcon className="text" />
                      </ListItemIcon>
                      <ListItemText className="text" sx={{ color: "#fff" }}>
                        <span className="font">Supported Data</span>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            ))}

            <ListItem className="link-active" sx={{ padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                onClick={() => setOpenLog(true)}
              >
                <ListItemIcon>
                  <LogoutRoundedIcon className="text" sx={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  className="text"
                  sx={{ color: "#fff", fontWeight: 800 }}
                >
                  <span className="font">Log Out</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open} className="main">
          <DrawerHeader />
          <Outlet />
        </Main>
        <LogOutDialog
          openLog={openLog}
          setOpenLog={setOpenLog}
          verifier={"mp"}
          act={"mp_active"}
        />
      </Box>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
