export const ROUTINGDATA = {
  LAND: "/",
  LANDPAGECONTECT: "",
  LANDPAGECONTACTUS: "landpagecontactus",
  MAIN: "main/",
  HOME: "home",
  JOBSEEK: "jobseeker/",
  USER: "user",
  JOBPROVIDER: "jobprovider",
  VOLUNTEER: "volunteer",
  MENTOR: "mentor",
  MENTORABOUTUS: "MentorAboutUs",
  MENTORProfile: "MentorProfile",
  MENTORUPDATE: "mentorUpdate",
  LOGIN: "login",
  ForgotPW: "forgotpassword",
  OtpforPassword: "OtpforPassword",
  ForgotPwOtp: "ResetPassword",
  ChangePassword: "ChangePassword",
  EMPLOYERUPDATE: "employerProfile",
  seekerProfile: "seekerProfile",
  ThankYou: "thankYou",
  ThankYouForLocal: "thank_You",
  PageNotFound: "pagenotfound",
  Searchpage: "searchpage/:id",
  Searchpageforlocalservices: "searchpageforlocalservices",
  AppliedJobs: "AppliedJobs",
  BankDetails: "BankDetails",
  // DATABASEACCESS
  DataBaseThanksCard: "ResumeAccessThanksCard/:id",
  BUYDATABASEACCESS: "buy-Resume-access",
  VIEWDATABASE: "view-resumes",
  TRIALVIEWACCESS: "view-seeker-search",
  // SUBSCRIPTIONS
  SUBSCRIPTIONS: "Subcriptions",
  SERVICESUBSCRIPTIONS: "serviceSubcriptions",
  PAYMENTSUCCESS: "payment/:id",

  // ----LOCAL_JOBS_SERVICES_PROVIDER

  LOCALJOB: "local_jobs",
  LOCAL_SERVICE_PROVIDER: "local_service_provider_form",
  LOCAL_SERVICES: "local_services",
  LOCAL_SERVICE_LOGIN: "service-login",
  LOCAL_SERVICE_PANEL: "local-service-panel",
  LOCAL_SERVICE_DASHBOARD: "local-service-dashboard",

  // ----ADVERTISMENT---//

  ADVERTISEMENT: "advertisement",
  AdvertisePayment: "AdvertisePayment",

  // ---ADMIN PANEL----
  ADMINPANEL: "admin",
  ADMINSETTINGS: "settings",
  ADMINAPPROVAL: "approval",
  ADMINEMPLOYERSETTING: "employer-settings",
  ADMINIMAGEUPLOADSETTING: "Image-Upload-settings",
  AdminImageUpload: "AdminImageUpload",
  ADVERTISEMENTSETTING: "advertisement-settings",
  PAYMENTVENDORS: "payment-vendors",
  ADPAYMENTSETTING: "ad-payment-settings",
  ADMINDASHBOARD: "dashboard",
  ADMINSEEKERS: "seeker",
  ADMINEMPLOYER: "employer",
  ADMINVOLUNTEER: "admin_volunteer",
  ADMINMENTOR: "admin_mentor",
  ADMINMENTORABOUTUS: "admin_mentor_fulldetails",
  ADMINSERVICEPROVIDER: "admin-serviceprovider",
  ADMINNeedSupport: "admin-need-support/",
  SEEKERNEEDSUPPORT: "needSupport",
  ADMININVESTOR: "admin-investor",
  ADMINPARTNER: "admin-partner",
  ADMINSeekerFullFamilyDetails: "admin-need-support/full-details",
  ADMINSeekerVelaivendumSupport: "seeker/velaivendum-support",
  POSTJOB: "post-job",
  POSTEDJOB: "posted-job/",

  APPLIEDSEEKERS: "applied-seeker",
  SEEKERSAPPLIED: "seekers-applied",
  AUDITING: "auditing",
  RecommendedJob: "RecommendedJob",
  fieldVerifiyCompleted: "field-verify-complete",
  fieldVerifiyCompletedFullDetails: "field-verify-complete/field-full-details",
  // ---MPCONSTITUENCY----//
  MPConstituency: "mp",
  MPDASHBOARD: "",
  MP_ALL_DATA: "mp_all_seekers",
  MP_NEEDSUPPORT: "mp_need_support",
  MP_SHORTLISTED: "mp_need_support/mp_shortlisted",
  MP_Shortlisted_fullDetails: "mp_need_support/shorlisted/full_details",
  MP_Supported: "mp_mla_need_support/supported",
  MP_Supported_fullDetails: "mp_mla_need_support/supported/full_details",
  MP_MLAComponent: "mp_mla",
  MP_MLAneedSupport: "mp_mla_needSupport/all_data",
  MP_MLAfullDetais: "mp_mla_needSupport/mp_mla_fulldetails",
  MP_MLAShortlisted: "mp_mla_need_support/shorlisted",
  MP_MLAShortlisted_fullDetails: "mp_mla_need_support/shorlisted/full_details",
  MP_MLASupported: "mp_mla_need_support/mpmlasupported",
  MP_MLASupported_fullDetails:
    "mp_mla_need_support/mpmlasupported/full_details",
  // --MLA

  MLAconstituency: "mla",
  MLAdashboard: "",
  MLASeeker: "mlaseekers",
  MLAneedSupport: "mla_need_support/all_data",
  MLAfulDetails: "mla_need_support/mla_fulldetails",
  MLAShortlisted: "mla_need_support/shorlisted",
  MLAShortlisted_fullDetails: "mla_need_support/shorlisted/full_details",
  MLASupported: "mla_need_support/supported",
  MLASupported_fullDetails: "mla_need_support/supported/full_details",

  //---Verifier

  verifier: "verifier",
  verifierDash: "verifier-dashboard",
  verifierFullDetails: "full-details",

  // ___VOLUNTEER____
  volunteerPanel: "volunteer",
  volunteerDashboard: "",
  volunteerAllData: "volunteer-all-data",
  volunteerNeedsupport: "volunteer-need-support/all_data",
  volunteerShortlisted: "volunteer-need-support/shortlist",
  volunteerFullDetails: "volunteer-need-support/full_details",
  volunteerShortlisted_fullDetails:
    "volunteer_need_support/shorlisted/full_details",
  volunteerSupported: "volunteer-need-support/supported",
  volunteerSupported_fullDetails:
    "volunteer-need-support/supported/full_details",
  // -----LOCALPROVIDER-----//

  LOCALPROVIDERPANEL: "local-provider",
  LOCALPROVIDERDASHBOARD: "provider-dashboard",

  // PARTNER_PANEL//
  PARTNERPANEL: "partner",
  PARTNERDASH: "partner-dashboard",

  //INVESTOR_PANEL//
  INVESTORPANEL: "investor",
  INVESTORDASH: "",
  INVESTORALLOCATEDPINCODES: "Allocated-Pincodes",
  INVESTORCREATEPINCODEFRANCHISER: "Create_Pincode_Franchiser",
  INVESTORPINCODEWISEPAYMENT: "Pincodewise_Payment",
  INVESTORTODAYCOLLECTIONREPORT: "Today_Collection_Report",
  INVESTORMASTERMYCOUSTOMER: "MasterMyCoustomer",
  INVESTORMASTERMYLEADS: "MasterMyLeads",
  INVESTORMASTERMYEMPLOYER: "MasterMyEmployer",

  // ------PARTNER-INVESTOR------//
  BECOMEAPARTNER: "partner-form",
  BECOMEAINVESTOR: "investor-form",

  //------TermsAndCondition-----//
  TERMSANDCONDITION: "TermsAndCondition",
  PrivacyPolicy: "PrivacyPolicy",
  PurchasePolicy: "PurchasePolicy",
  RefundPolicy: "RefundPolicy",
  DeliveryPolicy: "DeliveryPolicy",
  PricingPolicy: "PricingPolicy",
  //------Apiroutes-----//
  IMAGE: "image",
  PAYMENT: "payment/phonepe",
};
