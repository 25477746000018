import React from "react";
import { Snackbar, Typography, Alert } from "@mui/material";
function AutoApproveSnack({ openSnack, setOpenSnack, snackOption, full_name }) {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnack}
        // autoHideDuration={3000}
        // onClose={() => setOpenSnack(false)}
      >
        <Alert
          severity={"warning"}
          variant="filled"
          onClose={() => {
            setOpenSnack(false);
            sessionStorage.setItem(`${snackOption}`, false);
          }}
        >
          <Typography variant="body1">
            Warning!&nbsp;
            <span>{full_name}</span>&nbsp;'s Auto Approve is on
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default AutoApproveSnack;
