import { Box } from "@mui/material";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Switch,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Container,
} from "@mui/material";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  WorkRounded as WorkRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  VolunteerActivism as VolunteerActivismIcon,
  PeopleAltRounded as PeopleAltRoundedIcon,
  VerifiedUser as VerifiedUserIcon,
  ContactSupport as ContactSupportIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import useAxios from "../../../api/useAxios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";

function AdminSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const currentUrl = location.pathname;
  const approvalRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.ADMINPANEL +
    "/" +
    ROUTINGDATA.ADMINSETTINGS +
    "/" +
    ROUTINGDATA.ADMINAPPROVAL;
  const employerRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.ADMINPANEL +
    "/" +
    ROUTINGDATA.ADMINSETTINGS +
    "/" +
    ROUTINGDATA.ADMINEMPLOYERSETTING;
  const imageUploadRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.ADMINPANEL +
    "/" +
    ROUTINGDATA.ADMINSETTINGS +
    "/" +
    ROUTINGDATA.ADMINIMAGEUPLOADSETTING;
  // const adPaymentRoute =
  // ROUTINGDATA.LAND +
  // ROUTINGDATA.ADMINPANEL +
  // "/" +
  // ROUTINGDATA.ADMINSETTINGS +
  // "/" +
  // ROUTINGDATA.ADPAYMENTSETTING;

  const settingsButtonData = [
    {
      name: "Approval",
      startIcon: <CheckCircleOutlineIcon />,
      navigateTo: ROUTINGDATA.ADMINAPPROVAL,
      condition: currentUrl == approvalRoute ? "contained" : "text",
    },
    {
      name: "Employer Settings",
      startIcon: <CheckCircleOutlineIcon />,
      navigateTo: ROUTINGDATA.ADMINEMPLOYERSETTING,
      condition: currentUrl == employerRoute ? "contained" : "text",
    },
    {
      name: "File Upload Settings",
      startIcon: <CheckCircleOutlineIcon />,
      navigateTo: ROUTINGDATA.ADMINIMAGEUPLOADSETTING,
      condition: currentUrl == imageUploadRoute ? "contained" : "text",
    },
    // {
    //   name: "Ad Payment Settings",
    //   startIcon: <CheckCircleOutlineIcon />,
    //   navigateTo: ROUTINGDATA.ADPAYMENTSETTING,
    //   condition: currentUrl == adPaymentRoute ? "contained" : "text",
    // },
  ];
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
        <Box display={"flex"} gap={"10px"}>
          {settingsButtonData.map((item) => {
            return (
              <Button
                disabled={
                  adminDetails_CEO?.emailId ===
                  process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                    ? item.name === "File Upload Settings"
                      ? true
                      : false
                    : false
                }
                disableElevation
                disableFocusRipple
                disableRipple
                variant={item.condition}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: 0,
                  fontFamily: "Poppins-Medium",
                  padding: "5px 20px",
                }}
                startIcon={item.startIcon}
                onClick={() => {
                  navigate(item.navigateTo);
                }}
              >
                {item.name}
              </Button>
            );
          })}
        </Box>
        <Box>
          <Outlet />
          {/* <Card
            sx={{
              width: "50%",
              boxShadow: "3px 3px 10px 3px #ddd",
              borderRadius: "6px",
            }}
          >
            <Container fixed>
              <CardHeader
                title={
                  <Typography variant="h5" sx={{ fontFamily: "Barlow-Bold" }}>
                    Auto Approval
                  </Typography>
                }
                subheader="Turning On will Approve Users automatically when they registered "
              />

              <CardContent>
                <Box display="flex" flexDirection={"column"} gap={"20px"}>
                  {switchProps.map((item, index) => {
                   
                    return (
                      <>
                        <Box display="flex" justifyContent={"space-between"}>
                          {" "}
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText>
                            <Typography sx={{ fontFamily: "Poppins-Regular" }}>
                              {" "}
                              {item.name}
                            </Typography>
                          </ListItemText>{" "}
                          <Switch
                            // disabled={`checkedDisabled${index}`}
                            checked={checked[`checked${index}`]}
                            onChange={(e) => {
                              updateSettings(
                                index,
                                e.target.checked,
                                getAppData._id
                              );
                              // setChecked({
                              //   ...checked,
                              //   [`checked${index}`]: e.target.checked,
                              // });
                            }}
                          />{" "}
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </CardContent>
            </Container>
          </Card> */}
        </Box>
      </Box>
    </>
  );
}

export default AdminSettings;
