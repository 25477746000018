import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  Skeleton,
  TextField,
  Paper,
  Grid,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
import defaultimg from "../../../../../img/mentorImage.png";
import useAxios from "../../../../../api/useAxios";
import SeekerDialog from "../../../../admin-panel/admin-components/DialogBoxes/SeekerDialog";

import SkeletonSeekCard from "../../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import ExportCS from "../../../../admin-panel/admin-components/ExportComponents/ExportCS";

function MPAllData() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [MLAseeker, setMLAseeker] = useState([]);
  const [freshers, setfreshers] = useState([]);
  const [need, setneed] = useState([]);
  const [registered, setRegistered] = useState([]);
  const [mpConstituency, setMpConstituency] = useState([]);
  const [allData, setAllData] = useState([]);
  const [getOne, setGetOne] = useState({});
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const mp = JSON.parse(localStorage.getItem("mp"));

  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mpconstituencyseeker", {
        email_id: mp,
      });
      const { data } = MpData;
      setAllData(data[0]);
      const seek = data[0].mla_constituency_list.flatMap((i) => {
        return i.seekers;
      });
      const totalSeekers = seek.reverse();
      setMpConstituency(totalSeekers);
      let bool = false;
      if (mlaSearch !== null) {
        bool = mlaSearch.length > 0;
      }
      if (true === bool) {
        searchdatas(mlaSearch, totalSeekers);
        setsearchicon(true);
      } else {
        setMLAseeker(seek);
      }
      setIsLoading(false);

      setTotalRows(seek.length);
      setPage(0);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const mlaSearch = sessionStorage.getItem("mla_search");
  const handleChange = () => {
    setOpen(!open);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/email/${id}`);
      const { data } = getOneAxios;

      setGetOne(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const selectedApiForExcel = MLAseeker.map((i) => {
    return {
      "Full Name": i.full_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      gender: i.gender,
      "Current Location": i.currentLocation,
      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
    };
  });
  const closeFunctionCall = () => {
    setMLAseeker(mpConstituency);
    setTotalRows(mpConstituency.length);
    setPage(0);
  };
  const searchdatas = (searchdata, searchValue) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = searchValue.filter((item) => {
      var valuess =
        item.full_name.match(searchtext) ||
        item.email_id.match(searchtext2) ||
        item.votingDistrict.match(searchtext2) ||
        item.mlaConstituency.match(searchtext2) ||
        item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setMLAseeker(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    getMpEmailId();
  }, []);
  if (!isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px", mb: 8 }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {allData.mp_constituency}'s All Data
              </Typography>
            </Card>
          </Box>
          {/* <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
               <StatMPcards
                registered={registered.length}
                freshers={freshers.length}
                need={need.length}
              /> 
            </Box>
          </Card> */}
          <Card sx={{ boxShadow: 0 }}>
            <Paper sx={{ padding: "20px " }}>
              <Grid container justifyContent="space-between">
                <Grid item md={5}>
                  <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    value={searchdata ? searchdata : mlaSearch}
                    onKeyDown={(e) =>
                      ["(", ")", "^", "[", "]", "*", "<", ">", "\\"].includes(
                        e.key
                      ) && e.preventDefault()
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            visibility: `${searchicon ? "visible" : "hidden"}`,
                          }}
                          onClick={() => {
                            closeFunctionCall();
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("mla_search", "");
                          }}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      ),
                    }}
                    onChange={(e) => {
                      setsearchdata(e.currentTarget.value);
                      sessionStorage.setItem(
                        "mla_search",
                        e.currentTarget.value
                      );
                      if (e.currentTarget.value.length === 0) {
                        setMLAseeker(mpConstituency);
                        setTotalRows(mpConstituency.length);
                        setPage(0);
                      }
                      if (e.currentTarget.value.length >= 3) {
                        searchdatas(e.currentTarget.value, mpConstituency);
                      }
                      setsearchicon(true);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Tooltip title="Export As Excel" placement="bottom">
                    <ExportCS
                      selectedApiForExcel={selectedApiForExcel}
                      fileName={"Seeker-Data"}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Experience</TableCell>
                    <TableCell>Industry</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {MLAseeker.length > 0 ? (
                    <>
                      {" "}
                      {MLAseeker.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((item) => (
                        <TableRow className="tablecell">
                          <TableCell>
                            <img
                              src={`${baseUrl}seekers/profile/${item.mobile_number}`}
                              height={"50"}
                              width="50"
                              onError={(e) => {
                                e.target.src = defaultimg;
                              }}
                              style={{ clipPath: "circle(50%)" }}
                              alt="img"
                            />
                          </TableCell>
                          <TableCell>{item.full_name}</TableCell>
                          <TableCell>{item.email_id}</TableCell>
                          <TableCell>{item.mobile_number}</TableCell>
                          <TableCell>{item.work_status}</TableCell>
                          <TableCell>{item.industry}</TableCell>
                          <TableCell>
                            <Tooltip title="Full Details" placement="top">
                              <IconButton
                                className="icon-btn"
                                size="Medium"
                                onClick={() => {
                                  getOneData(item.email_id);
                                  handleChange();
                                }}
                              >
                                <OpenInNewRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          No Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <SeekerDialog
          open={open}
          handleChange={handleChange}
          getOne={getOne}
          setOpen={setOpen}
        />
      </>
    );
  } else {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <SkeletonSeekCard />
              </Box>
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default MPAllData;
