/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import MoreIcon from "@mui/icons-material/MoreVert";
import PersonIcon from '@mui/icons-material/Person';
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DashboardRounded as DashboardRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
} from "@mui/icons-material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useMediaQuery } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LogOutDialog from "../../admin-panel/admin-components/DialogBoxes/LogOutDialog";
import logo from "../../../img/logo_for-landingpage-3.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PeopleIcon from '@mui/icons-material/People';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      [theme.breakpoints.down("sm")]: { marginLeft: 0 },
      marginLeft: `${drawerWidth + 50}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [[theme.breakpoints.up("md")] || [theme.breakpoints.down("md")]]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth + 50}px)`,

      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("sm")]: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function InvestorPanel() {
  // const [active, setActive] = useState("dash");
  const theme = useTheme();
  const [openLog, setOpenLog] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  let investoract = sessionStorage.getItem("investoractive");

  function handleClick(event) {
    sessionStorage.setItem("investoractive", event);
    //setActive(act);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen((e) => !e);
  };
  const logOutFunc = () => {
    setOpenLog(true);
  };
  const location = useLocation();
  const email = location.state?.email_id;
  const PartnerName = JSON.parse(localStorage.getItem("investor"));

  useEffect(() => {
    if (PartnerName === null || PartnerName === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      let currentUrl = location.pathname;
      if (currentUrl === ROUTINGDATA.INVESTORDASH) {
        sessionStorage.setItem("investoractive", "dash");
      } else if (currentUrl === ROUTINGDATA.INVESTORALLOCATEDPINCODES) {
        sessionStorage.setItem("investoractive", "Allocated_Pincodes");
      } else if (currentUrl === ROUTINGDATA.INVESTORCREATEPINCODEFRANCHISER) {
        sessionStorage.setItem("investoractive", "Pincode_Franchiser");
      } else if (currentUrl === ROUTINGDATA.INVESTORPINCODEWISEPAYMENT) {
        sessionStorage.setItem("investoractive", "Pincodewise_Payment");
      } else if (currentUrl === ROUTINGDATA.INVESTORTODAYCOLLECTIONREPORT) {
        sessionStorage.setItem("investoractive", "Collections_Reports");
      } else if (currentUrl === ROUTINGDATA.INVESTORMASTERMYCOUSTOMER) {
        sessionStorage.setItem("investoractive", "MasterMyCoustomer");
      } else if (currentUrl === ROUTINGDATA.INVESTORMASTERMYLEADS) {
        sessionStorage.setItem("investoractive", "MasterMyLeads");
      } else if (currentUrl === ROUTINGDATA.INVESTORMASTERMYEMPLOYER) {
        sessionStorage.setItem("investoractive", "MasterMyEmployer");
      }
    }
  }, [location.pathname, location.state]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>{PartnerName}</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className="appbarboxshadow">
        <Toolbar sx={{ backgroundColor: " #ffff" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{color:"#0077b5"}} />
          </IconButton>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6" noWrap component="div" className="header-inverstorpannel">
              Investor Panel
            </Typography>

            <Typography
              variant="h6"
              noWrap
              className="header-inverstorpannel"
              component="div"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <div className="iconnameid">{PartnerName} <IconButton><PersonIcon sx={{color:"#108FB2"}}/></IconButton> </div>
            </Typography>
            <Box
              sx={{ display: { xs: "flex", md: "none" } }}
              className="more-icon"
            >
              <IconButton
                size="small"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth + 50,
            boxSizing: "border-box",
            backgroundColor: "#ffff",
          },
          [theme.breakpoints.down("sm")]: {
            "& .MuiDrawer-paper": {
              backgroundColor: "#ffff",
              width: drawerWidth,
              flexShrink: 0,
            },
          },
        }}
        variant={
          isMobile
            ? "temporary"
            : isDevice || isDeviceDown
            ? "persistent"
            : null
        }
        anchor="left"
        open={open}
      >
        <DrawerHeader className="header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image" src={logo} alt="" />
            </div>
            <div
              style={{
                display: "flex",

                alignItems: "center",
              }}
            >
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon sx={{color:"#0077b5"}} />
              </IconButton>
            </div>
          </Box>
        </DrawerHeader>
        <List>
          <ListItemText>
            <span className="sidehead">Navigation</span>
          </ListItemText>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={investoract === "dash" ? "ivestor-active" : "investor-link-active"}
              onClick={(e) => {
                handleClick("dash");
                navigate(ROUTINGDATA.INVESTORDASH);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <DashboardRoundedIcon
                  className={
                    investoract === "dash" ? "text-active" : "text-deactive"
                  }
                />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "dash" ? "investorpannelfonts-1" : "investorpannelfonts"}>My Dashboard</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                 investoract === "MasterMyEmployer" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("MasterMyEmployer");
               navigate(ROUTINGDATA.INVESTORMASTERMYEMPLOYER);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <ContactEmergencyIcon className={investoract === "MasterMyEmployer" ? "text-active" : "text-deactive"} />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "MasterMyEmployer" ? "investorpannelfonts-1" : "investorpannelfonts"}> My Employers</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                 investoract === "MasterMyLeads" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("MasterMyLeads");
               navigate(ROUTINGDATA.INVESTORMASTERMYLEADS);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <FilterAltIcon className={investoract === "MasterMyLeads" ? "text-active" : "text-deactive"} />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "MasterMyLeads" ? "investorpannelfonts-1" : "investorpannelfonts"}> My Leads</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                investoract === "MasterMyCoustomer" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("MasterMyCoustomer");
                navigate(ROUTINGDATA.INVESTORMASTERMYCOUSTOMER);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <PeopleIcon className={investoract === "MasterMyCoustomer" ? "text-active" : "text-deactive"} />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "MasterMyCoustomer" ? "investorpannelfonts-1" : "investorpannelfonts"}> My Customers</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                investoract === "Allocated_Pincodes" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("Allocated_Pincodes");
                navigate(ROUTINGDATA.INVESTORALLOCATEDPINCODES, {
                  state: {
                    email_id: email,
                  },
                });
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <PersonPinCircleIcon
                  className={
                    investoract === "Allocated_Pincodes"
                      ? "text-active"
                      : "text-deactive"
                  }
                />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "Allocated_Pincodes" ? "investorpannelfonts-1" : "investorpannelfonts"}>Allocated Pincodes</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                investoract === "Pincodewise_Payment" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("Pincodewise_Payment");
                navigate(ROUTINGDATA.INVESTORPINCODEWISEPAYMENT);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <CurrencyRupeeIcon
                  className={
                    investoract === "Pincodewise_Payment"
                      ? "text-active"
                      : "text-deactive"
                  }
                />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "Pincodewise_Payment" ? "investorpannelfonts-1" : "investorpannelfonts"}> My Payment</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                investoract === "Collections_Reports" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("Collections_Reports");
                navigate(ROUTINGDATA.INVESTORTODAYCOLLECTIONREPORT);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <AssessmentIcon
                  className={
                    investoract === "Collections_Reports"
                      ? "text-active"
                      : "text-deactive"
                  }
                />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "Collections_Reports" ? "investorpannelfonts-1" : "investorpannelfonts"}>Collection Reports </span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={
                investoract === "Pincode_Franchiser" ? "ivestor-active" : "investor-link-active"
              }
              onClick={(e) => {
                handleClick("Pincode_Franchiser");
                navigate(ROUTINGDATA.INVESTORCREATEPINCODEFRANCHISER);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <NoteAddIcon
                  className={
                    investoract === "Pincode_Franchiser"
                      ? "text-active"
                      : "text-deactive"
                  }
                />
              </ListItemIcon>
              <ListItemText className="text" sx={{ color: "#fff" }}>
                <span className={investoract === "Pincode_Franchiser" ? "investorpannelfonts-1" : "investorpannelfonts"}>CreatePincode Franchiser</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem className="link-active" sx={{ padding: "1px 10px" }}>
            <ListItemButton sx={{ borderRadius: "10px" }} onClick={logOutFunc}>
              <ListItemIcon>
                <LogoutRoundedIcon className="text-deactive"  />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{ color: "#fff", fontWeight: 800 }}
              >
                <span className="investorpannelfonts" >Log Out</span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open} className="main">
        <DrawerHeader />
        <Outlet />
      </Main>
      <LogOutDialog
        openLog={openLog}
        setOpenLog={setOpenLog}
        verifier={"verifier"}
      />
    </Box>
  );
}
export default InvestorPanel;
