import { Box } from "@mui/material";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Switch,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Container,
  FormControl,
  FormLabel,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import {
  PercentRounded as PercentRoundedIcon,
  CurrencyRupeeRounded as CurrencyRupeeRoundedIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import React, { useEffect, useState } from "react";
import useAxios from "../../../../api/useAxios";
import { Formik } from "formik";
import * as Yup from "yup";
function AdminEmployerSettings() {
  const axiosData = useAxios();
  const [getPostJobData, setGetPostData] = useState({});
  const [getPostJobPermanentData, setGetPostJobPermanentData] = useState({});
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFocused, setIsFocused] = useState({
    no_of_free_posting: false,
    no_of_days_trial_view: false,
    premimum_price: false,
    standard_price: false,
    discount: false,
    discount_valid: false,
  });

  const [captureBlur, setCaptureBlur] = useState({
    no_of_free_posting: false,
    no_of_days_trial_view: false,
    premimum_price: false,
    standard_price: false,
    discount: false,
    discount_valid: false,
  });
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const initialValues = {
    no_of_free_posting: getPostJobData.no_of_free_posting,
    no_of_days_trial_view: getPostJobData.no_of_days_trial_view,
    premimum_price: getPostJobData?.premimum_price,
    standard_price: getPostJobData?.standard_price,
    discount: getPostJobData?.discount,
    discount_valid: getPostJobData?.discount_valid,
  };
  const validationSchema = Yup.object().shape({
    standard_price: Yup.number().required("Standard Price Required"),
    premimum_price: Yup.number().required("Premium Price Required").nullable(),
    discount: Yup.number()
      .max(100, "Maximum limit 100 ")
      .required("Discount Percent Required"),
    discount_valid: Yup.number()
      .max(100, "Maximum limit 100 ")
      .required("Trigger Discount Required"),
      no_of_days_trial_view: Yup.number().required("No of Days Trail View Required"),
      no_of_free_posting: Yup.number().required("No of free Post Required"),
  });
  const handleSubmitChange = async (values) => {
    setIsSubmitting(true);
    setIsLoading(true);
    try {
      await axiosData.put(`employers/settings/postjob`, {
        no_of_free_posting: values.no_of_free_posting,
        no_of_days_trial_view:values.no_of_days_trial_view,
        premimum_price: values.premimum_price,
        standard_price: values.standard_price,
        discount: values.discount,
        discount_valid: values.discount_valid,
      });
      getPostJobSettings();
      setIsSubmitting(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (err) {}
  };
  const handleClear = (formikProps, value) => {
    formikProps.setFieldValue("no_of_free_posting", "");
    formikProps.setFieldValue("no_of_days_trial_view", "");
    formikProps.setFieldValue("premimum_price", "");
    formikProps.setFieldValue("standard_price", "");
    formikProps.setFieldValue("discount", "");
    formikProps.setFieldValue("discount_valid", "");

    setTimeout(() => {
      formikProps.setFieldValue("discount_valid", "");
    }, 100);
  };
  const handleReset = (formikProps, value) => {
    formikProps.setFieldTouched("no_of_free_posting", false);
    formikProps.setFieldTouched("no_of_days_trial_view", false);
    formikProps.setFieldTouched("premimum_price", false);
    formikProps.setFieldTouched("standard_price", false);
    formikProps.setFieldTouched("discount", false);
    formikProps.setFieldTouched("discount_valid", false);
    setTimeout(() => {
      formikProps.setFieldValue("no_of_free_posting", value.no_of_free_posting);
      formikProps.setFieldValue("no_of_days_trial_view", value.no_of_days_trial_view);
      formikProps.setFieldValue("premimum_price", value.premimum_price);
      formikProps.setFieldValue("standard_price", value.standard_price);
      formikProps.setFieldValue("discount", value.discount);
      formikProps.setFieldValue("discount_valid", value.discount_valid);

      setTimeout(() => {
        formikProps.setFieldValue("discount_valid", value.discount_valid);
      }, 100);
    }, 500);
  };
  const getPostJobSettings = async () => {
    try {
      const postJobData = await axiosData.get("employers/Getsettings/postjob");
      const { data } = postJobData;
      setGetPostJobPermanentData({ ...data });
      setGetPostData(data);

      setLoader(true);
    } catch (err) {
      console.log("er", err);
    }
  };
  useEffect(() => {
    getPostJobSettings();
  }, []);

  if (loader) {
    return (
      <>
        {/* <Dialog open={openCloseDialog} fullWidth>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>Are You sure you want to close?</DialogContent>
          <DialogActions>
            {" "}
            <Button
              onClick={() => {
                setOpenCloseDialog(false);
              }}
              variant="outlined"
              sx={{
                backgroundColor: "#f2f2f2",
                textTransform: "lowercase",

                color: "black",
                borderColor: "white",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "#f2f2f2",
                  backgroundColor: "#339fcd",
                  borderColor: "#f2f2f2",
                  fontFamily: "Poppins-Medium",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outlined"
              disabled={isLoading}
              sx={{
                textTransform: "lowercase",

                color: "white",
                backgroundColor: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                transition: "all 0.3s",
                "&:hover": {
                  textTransform: "lowercase",

                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                // updateClose(getEmail);
              }}
            >
              Close
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog> */}
        <Box>
          <Card
            sx={{
              width: "100%",
              boxShadow: "3px 3px 10px 3px #ddd",
              borderRadius: "6px",
            }}
          >
            <Container fixed>
              <CardContent>
                <Box display="flex" flexDirection={"column"} gap={"20px"}>
                  <Box display="flex" flexDirection={"column"} gap={"5px"}>
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Poppins-SemiBold" }}
                    >
                      Post Job Settings
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontFamily: "Barlow-Medium" }}
                    ></Typography>
                  </Box>
                  <Box>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmitChange}
                    >
                      {(formikProps) => {
                        const disableCondition =
                          formikProps.isValid &&
                          formikProps.dirty &&
                          isSubmitting === false &&
                          (formikProps.values.discount !=
                            getPostJobData.discount ||
                            formikProps.values.discount_valid !=
                              getPostJobData.discount_valid ||
                            formikProps.values.no_of_free_posting !=
                              getPostJobData.no_of_free_posting ||
                              formikProps.values.no_of_days_trial_view !=
                              getPostJobData.no_of_days_trial_view ||
                            formikProps.values.premimum_price !=
                              getPostJobData.premimum_price ||
                            formikProps.values.standard_price !=
                              getPostJobData.standard_price)
                            ? false
                            : true;

                        return (
                          <>
                            <Box
                              display="flex"
                              flexDirection={"column"}
                              gap={"20px"}
                            >
                              <Box
                                display="flex"
                                justifyContent={"space-between"}
                                gap={"10px"}
                              >
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.standard_price === true &&
                                        (formikProps.errors.standard_price ===
                                          undefined ||
                                          formikProps.touched.standard_price ===
                                            undefined ||
                                          captureBlur.standard_price === false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .standard_price !== undefined &&
                                            formikProps.touched
                                              .standard_price === true &&
                                            captureBlur.standard_price === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Standard Price
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          standard_price: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          standard_price: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={"Enter Standard Price"}
                                    name="standard_price"
                                    // label={t("Mobile_Number")}
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="standard_price"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        standard_price: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      setCaptureBlur({
                                        ...captureBlur,
                                        standard_price: false,
                                      });
                                      // setIsFocused({
                                      //   ...isFocused,
                                      //   standard_price: false,
                                      // });
                                      const { value } = e.target;
                                      if (value.length <= 10) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={formikProps.values.standard_price}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CurrencyRupeeRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.standard_price ===
                                                  true &&
                                                (formikProps.errors
                                                  .standard_price ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .standard_price ===
                                                    undefined ||
                                                  captureBlur.standard_price ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .standard_price !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .standard_price ===
                                                      true &&
                                                    captureBlur.standard_price ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.standard_price &&
                                      Boolean(
                                        formikProps.errors.standard_price
                                      ) &&
                                      captureBlur.standard_price
                                    }
                                    helperText={
                                      formikProps.touched.standard_price &&
                                      captureBlur.standard_price &&
                                      formikProps.errors.standard_price
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.premimum_price === true &&
                                        (formikProps.errors.premimum_price ===
                                          undefined ||
                                          formikProps.touched.premimum_price ===
                                            undefined ||
                                          captureBlur.premimum_price === false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .premimum_price !== undefined &&
                                            formikProps.touched
                                              .premimum_price === true &&
                                            captureBlur.premimum_price === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Premium Price
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          premimum_price: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          premimum_price: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={"Enter Premium Price"}
                                    name="premimum_price"
                                    // label={t("Mobile_Number")}
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="premimum_price"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        premimum_price: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      setCaptureBlur({
                                        ...captureBlur,
                                        premimum_price: false,
                                      });
                                      // setIsFocused({
                                      //   ...isFocused,
                                      //   premimum_price: false,
                                      // });
                                      const { value } = e.target;
                                      if (value.length <= 10) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={formikProps.values.premimum_price}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CurrencyRupeeRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.premimum_price ===
                                                  true &&
                                                (formikProps.errors
                                                  .premimum_price ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .premimum_price ===
                                                    undefined ||
                                                  captureBlur.premimum_price ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .premimum_price !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .premimum_price ===
                                                      true &&
                                                    captureBlur.premimum_price ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.premimum_price &&
                                      Boolean(
                                        formikProps.errors.premimum_price
                                      ) &&
                                      captureBlur.premimum_price
                                    }
                                    helperText={
                                      formikProps.touched.premimum_price &&
                                      captureBlur.premimum_price &&
                                      formikProps.errors.premimum_price
                                    }
                                  />
                                </FormControl>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent={"space-between"}
                                gap={"10px"}
                              >
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.discount === true &&
                                        (formikProps.errors.discount ===
                                          undefined ||
                                          formikProps.touched.discount ===
                                            undefined ||
                                          captureBlur.discount === false)
                                          ? "#1976d5"
                                          : formikProps.errors.discount !==
                                              undefined &&
                                            formikProps.touched.discount ===
                                              true &&
                                            captureBlur.discount === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Discount
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          discount: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          discount: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={"Enter Discount range"}
                                    name="discount"
                                    // label={t("Mobile_Number")}
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="discount"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        discount: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      setCaptureBlur({
                                        ...captureBlur,
                                        discount: false,
                                      });
                                      // setIsFocused({
                                      //   ...isFocused,
                                      //   discount: false,
                                      // });
                                      const { value } = e.target;
                                      if (value.length <= 3) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={formikProps.values.discount}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PercentRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.discount === true &&
                                                (formikProps.errors.discount ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .discount === undefined ||
                                                  captureBlur.discount ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .discount !== undefined &&
                                                    formikProps.touched
                                                      .discount === true &&
                                                    captureBlur.discount ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.discount &&
                                      Boolean(formikProps.errors.discount) &&
                                      captureBlur.discount
                                    }
                                    helperText={
                                      formikProps.touched.discount &&
                                      captureBlur.discount &&
                                      formikProps.errors.discount
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.discount_valid === true &&
                                        (formikProps.errors.discount_valid ===
                                          undefined ||
                                          formikProps.touched.discount_valid ===
                                            undefined ||
                                          captureBlur.discount_valid === false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .discount_valid !== undefined &&
                                            formikProps.touched
                                              .discount_valid === true &&
                                            captureBlur.discount_valid === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    Trigger Discount
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          discount_valid: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          discount_valid: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={
                                      "Enter Trigger Discount Percentage"
                                    }
                                    name="discount_valid"
                                    // label={t("Mobile_Number")}
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="discount_valid"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        discount_valid: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      setCaptureBlur({
                                        ...captureBlur,
                                        discount_valid: false,
                                      });
                                      // setIsFocused({
                                      //   ...isFocused,
                                      //   discount_valid: false,
                                      // });
                                      const { value } = e.target;
                                      if (value.length <= 3) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={formikProps.values.discount_valid}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PercentRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.discount_valid ===
                                                  true &&
                                                (formikProps.errors
                                                  .discount_valid ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .discount_valid ===
                                                    undefined ||
                                                  captureBlur.discount_valid ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .discount_valid !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .discount_valid ===
                                                      true &&
                                                    captureBlur.discount_valid ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.discount_valid &&
                                      Boolean(
                                        formikProps.errors.discount_valid
                                      ) &&
                                      captureBlur.discount_valid
                                    }
                                    helperText={
                                      formikProps.touched.discount_valid &&
                                      captureBlur.discount_valid &&
                                      formikProps.errors.discount_valid
                                    }
                                  />
                                </FormControl>
                              </Box>
                              <Box  display="flex"
                                justifyContent={"space-between"}
                                gap={"10px"}>
                                {" "}
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.no_of_free_posting === true &&
                                        (formikProps.errors
                                          .no_of_free_posting === undefined ||
                                          formikProps.touched
                                            .no_of_free_posting === undefined ||
                                          captureBlur.no_of_free_posting ===
                                            false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .no_of_free_posting !==
                                              undefined &&
                                            formikProps.touched
                                              .no_of_free_posting === true &&
                                            captureBlur.no_of_free_posting ===
                                              true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    No. of Free Post
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          no_of_free_posting: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          no_of_free_posting: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={"Enter No of Free Post"}
                                    name="no_of_free_posting"
                                    // label={t("Mobile_Number")}
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="no_of_free_posting"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        no_of_free_posting: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setCaptureBlur({
                                        ...captureBlur,
                                        no_of_free_posting: false,
                                      });
                                      // if (value.length === 0) {
                                      //   console.log(
                                      //     "ture aiduchi",
                                      //     isFocused.no_of_free_posting
                                      //   );

                                      //   setCaptureBlur({
                                      //     ...captureBlur,
                                      //     no_of_free_posting: true,
                                      //   });
                                      //   setIsFocused({
                                      //     ...isFocused,
                                      //     no_of_free_posting: false,
                                      //   });
                                      // }
                                      if (value.length <= 10) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={
                                      formikProps.values.no_of_free_posting
                                    }
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PostAddIcon
                                            sx={{
                                              color:
                                                isFocused.no_of_free_posting ===
                                                  true &&
                                                (formikProps.errors
                                                  .no_of_free_posting ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .no_of_free_posting ===
                                                    undefined ||
                                                  captureBlur.no_of_free_posting ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .no_of_free_posting !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .no_of_free_posting ===
                                                      true &&
                                                    captureBlur.no_of_free_posting ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.no_of_free_posting &&
                                      Boolean(
                                        formikProps.errors.no_of_free_posting
                                      ) &&
                                      captureBlur.no_of_free_posting
                                    }
                                    helperText={
                                      formikProps.touched.no_of_free_posting &&
                                      captureBlur.no_of_free_posting &&
                                      formikProps.errors.no_of_free_posting
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  className="ContactDetailsFormControl"
                                  fullWidth
                                >
                                  <FormLabel
                                    sx={{ fontWeight: "bold" }}
                                    style={{
                                      color:
                                        isFocused.no_of_days_trial_view === true &&
                                        (formikProps.errors
                                          .no_of_days_trial_view === undefined ||
                                          formikProps.touched
                                            .no_of_days_trial_view === undefined ||
                                          captureBlur.no_of_days_trial_view ===
                                            false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .no_of_days_trial_view !==
                                              undefined &&
                                            formikProps.touched
                                              .no_of_days_trial_view === true &&
                                            captureBlur.no_of_days_trial_view ===
                                              true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    No. of Days Trial Job Seekers Search View 
                                  </FormLabel>
                                  <TextField
                                    // margin="normal"
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                      onFocus: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          no_of_days_trial_view: true,
                                        });
                                      },
                                      onBlur: () => {
                                        setIsFocused({
                                          ...isFocused,
                                          no_of_days_trial_view: false,
                                        });
                                      },
                                    }}
                                    fullWidth
                                    required
                                    placeholder={"Enter No of Days Trial Access"}
                                    name="no_of_days_trial_view"
                                    type="number"
                                    onWheel={(e) => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    id="no_of_days_trial_view"
                                    onBlur={formikProps.handleBlur}
                                    onBlurCapture={() =>
                                      setCaptureBlur({
                                        ...captureBlur,
                                        no_of_days_trial_view: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setCaptureBlur({
                                        ...captureBlur,
                                        no_of_days_trial_view: false,
                                      });
                                      
                                      if (value.length <= 10) {
                                        formikProps.handleChange(e);
                                      }
                                    }}
                                    value={
                                      formikProps.values.no_of_days_trial_view
                                    }
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    InputProps={{
                                      maxLength: 10,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CalendarTodayIcon
                                            sx={{
                                              color:
                                                isFocused.no_of_days_trial_view ===
                                                  true &&
                                                (formikProps.errors
                                                  .no_of_days_trial_view ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .no_of_days_trial_view ===
                                                    undefined ||
                                                  captureBlur.no_of_days_trial_view ===
                                                    false)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .no_of_days_trial_view !==
                                                      undefined &&
                                                    formikProps.touched
                                                      .no_of_days_trial_view ===
                                                      true &&
                                                    captureBlur.no_of_days_trial_view ===
                                                      true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      formikProps.touched.no_of_days_trial_view &&
                                      Boolean(
                                        formikProps.errors.no_of_days_trial_view
                                      ) &&
                                      captureBlur.no_of_days_trial_view
                                    }
                                    helperText={
                                      formikProps.touched.no_of_days_trial_view &&
                                      captureBlur.no_of_days_trial_view &&
                                      formikProps.errors.no_of_days_trial_view
                                    }
                                  />
                                </FormControl>
                              </Box>
                              <Box display="flex" gap="20px">
                                <Button
                                  variant="contained"
                                  disabled={
                                    disableCondition ||
                                    adminDetails_CEO?.emailId ===
                                      process.env
                                        .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                      ? true
                                      : false
                                  }
                                  type="submit"
                                  sx={{
                                    textTransform: "capitalize",
                                    boxShadow: 0,
                                    fontFamily: "Poppins-Medium",
                                    padding: "5px 20px",
                                  }}
                                  // startIcon={item.startIcon}
                                  onClick={formikProps.handleSubmit}
                                >
                                  Save Changes{" "}
                                  {isSubmitting && (
                                    <CircularProgress
                                      size={24}
                                      style={{
                                        color: "#ffffff",

                                        marginLeft: 12,
                                      }}
                                    />
                                  )}
                                </Button>
                                <Button
                                  variant="contained"
                                  disabled={
                                    !formikProps.isValid ||
                                    adminDetails_CEO?.emailId ===
                                      process.env
                                        .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                      ? true
                                      : false
                                  }
                                  color="error"
                                  sx={{
                                    textTransform: "capitalize",
                                    boxShadow: 0,
                                    fontFamily: "Poppins-Medium",
                                    // padding: "5px 20px",
                                  }}
                                  onClick={
                                    () => handleClear(formikProps, "")
                                    // handleClear()
                                  }
                                >
                                  Clear all
                                </Button>{" "}
                                <Button
                                  variant="contained"
                                  disabled={
                                    formikProps.isValid ||
                                    adminDetails_CEO?.emailId ===
                                      process.env
                                        .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                      ? true
                                      : false
                                  }
                                  color="error"
                                  sx={{
                                    textTransform: "capitalize",
                                    boxShadow: 0,
                                    fontFamily: "Poppins-Medium",
                                    // padding: "5px 20px",
                                  }}
                                  onClick={() => {
                                    setCaptureBlur({
                                      ...captureBlur,
                                      no_of_free_posting: false,
                                      no_of_days_trial_view: false,
                                      premimum_price: false,
                                      standard_price: false,
                                      discount: false,
                                      discount_valid: false,
                                    });
                                    setIsFocused({
                                      ...isFocused,
                                      no_of_free_posting: false,
                                      no_of_days_trial_view: false,
                                      premimum_price: false,
                                      standard_price: false,
                                      discount: false,
                                      discount_valid: false,
                                    });

                                    handleReset(
                                      formikProps,
                                      getPostJobPermanentData
                                    );
                                  }}
                                >
                                  reset
                                </Button>
                              </Box>
                            </Box>
                          </>
                        );
                      }}
                    </Formik>
                  </Box>
                </Box>
              </CardContent>
            </Container>
          </Card>
        </Box>
      </>
    );
  } else {
    return <b>Loading....</b>;
  }
}

export default AdminEmployerSettings;
