import React, { useState } from "react";
import "./Login.css";
import bgImg from "../../img/login image/job-search.jpg";
import logo from "../../img/login image/logo.png";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import logobackbutton from "../../img/logo_for-landingpage-3.png";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Box,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import useAxios from "../../api/useAxios";
import { ROUTINGDATA } from "../ROUTINGDATA";

import Divider from "@mui/material/Divider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import loginpageimg from "../../img/login image/loginpageimg.jpg";
import logoimglogin from "../../img/loginpagelogo.png";

export default function Login({ forgotPW }) {
  const [showPassword, setShowPassword] = useState(false);
  const [errordis, seterrordis] = useState("");
  const [open, setOpen] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const axiosData = useAxios();
  const [datas, setdatas] = useState();
  const handleOpenPass = (datas) => {
    setdatas(datas);
    setOpenPass(true);
  };

  const handleClosePass = () => {
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.ChangePassword}`, {
      state: { Password: datas.Password, email_id: datas.email_id },
    });
    setOpenPass(false);
  };
  const handleOpenChange = (datas) => {
    setdatas(datas);
    setOpenChange(true);
  };

  const handleCloseChange = () => {
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.ChangePassword}`, {
      state: { Password: datas.Password, email_id: datas.email_id },
    });
    setOpenChange(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email_id: "",
      password: "",
    },
    validationSchema: Yup.object({
      email_id: Yup.string()
        .email("Must be a valid email")
        .max(50)
        .required("Email is required"),
      password: Yup.string().required("Password Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      joseekerloginapi(values);
      resetForm({ values: "" });
    },
  });
  const joseekerloginapi = async (datas) => {
    try {
      const logindata = await axiosData.post("users/login", datas, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (logindata.data === "") {
        setOpen(true);
        seterrordis("User does not exist!");
      } else if (logindata.data.status === "InActive") {
        setOpen(true);
        seterrordis("Your profile is under review for Approval");
      } else if (logindata.data.usertype === "admin") {
        navigate(
          ROUTINGDATA.LAND +
            ROUTINGDATA.ADMINPANEL +
            "/" +
            ROUTINGDATA.ADMINDASHBOARD
        );
        sessionStorage.setItem("active", "dash");
        sessionStorage.setItem("seeker-setting", true);
        sessionStorage.setItem("employer-setting", true);
        sessionStorage.setItem("volunteer-setting", true);
        sessionStorage.setItem("mentor-setting", true);
        sessionStorage.setItem("need-setting", true);
        sessionStorage.setItem("provider-setting", true);
        const emailId = logindata.data.email_id;
        localStorage.setItem("admin", JSON.stringify({ emailId }));
      } else if (logindata.data.usertype === "admin_dev") {
        navigate(
          ROUTINGDATA.LAND +
            ROUTINGDATA.ADMINPANEL +
            "/" +
            ROUTINGDATA.ADMINDASHBOARD
        );
        sessionStorage.setItem("active", "dash");
        sessionStorage.setItem("seeker-setting", true);
        sessionStorage.setItem("employer-setting", true);
        sessionStorage.setItem("volunteer-setting", true);
        sessionStorage.setItem("mentor-setting", true);
        sessionStorage.setItem("need-setting", true);
        sessionStorage.setItem("provider-setting", true);
        const emailId = logindata.data.email_id;
        localStorage.setItem("admin_dev", JSON.stringify({ emailId }));
      } else if (logindata.data.usertype === "mp") {
        navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.MPConstituency}`);
        localStorage.setItem("mp", JSON.stringify(logindata.data.email_id));
        sessionStorage.setItem("mp_active", "dash");
      } else if (logindata.data.message === "change password") {
        handleOpenChange(datas);
      } else if (logindata.data.message === "Password Expiry") {
        handleOpenPass(datas);
      } else {
        if (logindata.data.usertype === "seeker") {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.seekerProfile}`,
            { state: logindata.data.email_id }
          );
          const emailId = logindata.data.email_id;
          const token = logindata.data.token;
          localStorage.setItem("seeker", JSON.stringify({ emailId }));
          localStorage.setItem("token", token);
        } else if (logindata.data.usertype === "provider") {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.EMPLOYERUPDATE}`,
            { state: logindata.data.email_id }
          );
          const emailId = logindata.data.email_id;
          localStorage.setItem("user", JSON.stringify({ emailId }));
        } else if (logindata.data.usertype === "verifier") {
          navigate(
            ROUTINGDATA.LAND +
              ROUTINGDATA.verifier +
              "/" +
              ROUTINGDATA.verifierDash,
            {
              state: logindata.data.email_id,
            }
          );
          localStorage.setItem(
            "verifier",
            JSON.stringify(logindata.data.email_id)
          );
        } else if (logindata.data.usertype === "mla") {
          navigate(
            ROUTINGDATA.LAND +
              ROUTINGDATA.MLAconstituency +
              ROUTINGDATA.MLAdashboard,
            {
              state: logindata.data.email_id,
            }
          );
          localStorage.setItem("mla", JSON.stringify(logindata.data.email_id));
          sessionStorage.setItem("active", "dash");
        } else if (logindata.data.usertype === "volunteer") {
          navigate(ROUTINGDATA.LAND + ROUTINGDATA.volunteerPanel, {
            state: logindata.data.email_id,
          });
          localStorage.setItem(
            "volunteer",
            JSON.stringify(logindata.data.email_id)
          );
          sessionStorage.setItem("vol_act", "dash");
        } else if (logindata.data.usertype === "mentor") {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTORABOUTUS}`,
            {
              state: {
                email_id: logindata.data.email_id,
                usertype: logindata.data.usertype,
              },
            }
          );
          const email_id = logindata.data.email_id;
          localStorage.setItem(
            "mentor",
            JSON.stringify({ email_id: email_id })
          );
          // sessionStorage.setItem("vol_act", "dash");
        } else if (logindata.data.usertype === "serviceProvider") {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.LOCALPROVIDERPANEL}/${ROUTINGDATA.LOCALPROVIDERDASHBOARD}`,
            {
              state: {
                email_id: logindata.data.email_id,
                usertype: logindata.data.usertype,
              },
            }
          );
          const email_id = logindata.data.email_id;
          localStorage.setItem(
            "service-provider",
            JSON.stringify(logindata.data.email_id)
          );
          // sessionStorage.setItem("vol_act", "dash");
        } else if (logindata.data.usertype === "investor") {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.INVESTORPANEL}/${ROUTINGDATA.INVESTORDASH}`,
            {
              state: {
                email_id: logindata.data.email_id,
                usertype: logindata.data.usertype,
              },
            }
          );
          const email_id = logindata.data.email_id;
          localStorage.setItem(
            "investor",
            JSON.stringify(logindata.data.email_id)
          );
          sessionStorage.setItem("investoractive", "dash");
        }
      }
    } catch (e) {
      if (e.message === "Network Error") {
        setOpen(true);
        seterrordis("Server is not acceptable please contact administration!");
      } else {
        setOpen(true);
        seterrordis(e.response.data.message);
      }
    }
  };
  return (
    <div id="loginpage" className="loginpage">
      <div className="loginbox">
        <div className="loginside-img">
          <img src={loginpageimg} />
          <div className="overlay-login">
            <div className="overlay-text">
              <p>
                <span>
                  <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>
                  {process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>{" "}
                is a secure and inclusive platform that facilitates connections
                among job seekers, employers, and mentors. It encourages the
                exchange of knowledge and the sharing of job opportunities,
                thereby nurturing the comprehensive development of the
                community."
              </p>
            </div>
          </div>
          <div className="overlay-login-1">
            <div className="overlay-text">
              <img src={logoimglogin} />
            </div>
          </div>
        </div>
        <div className="login-inputs">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                  <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>
                  {process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Login</h2>
              </div>
              <div className="login-textfield">
                <div className="login-textfield-1">
                  <label>Email ID</label>
                  <Tooltip title="Enter your Email" arrow>
                    <TextField
                      id="email_id"
                      variant="outlined"
                      size="small"
                      name="email_id"
                      onChange={formik.handleChange}
                      value={formik.values.email_id}
                      onBlur={formik.handleBlur}
                      fullWidth
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.email_id && formik.errors.email_id ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.email_id}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </div>
                <div className="login-textfield-2">
                  <label>Password</label>
                  <Tooltip title="Enter Your Private Password" arrow>
                    <OutlinedInput
                      size="small"
                      fullWidth
                      onChange={formik.handleChange}
                      name="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Tooltip title="hide">
                                <VisibilityOff fontSize="small" />
                              </Tooltip>
                            ) : null}
                            {!showPassword ? (
                              <Tooltip title="show">
                                <Visibility fontSize="small" />
                              </Tooltip>
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.password && formik.errors.password ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.password}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </div>
                <div className="login-forgotPassword">
                  <span
                    onClick={() =>
                      navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.ForgotPW}`)
                    }
                  >
                    Forgot Password?
                  </span>
                </div>
                <div className="loginbtn">
                  <Tooltip title="Enter your details after then login Successfully">
                    <Button variant="contained" fullWidth type="submit">
                      Login
                    </Button>
                  </Tooltip>
                </div>
                <div className="login-or">
                  <Divider className="login-or-style">OR</Divider>
                </div>
                <div className="loginbtn-signup">
                  <Button
                    className="btn-color-for-signup"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      navigate("/main/jobseeker/");
                    }}
                  >
                    sign up
                  </Button>
                </div>
                <div className="back-to-landing">
                  <ArrowBackIosIcon fontSize="13px" sx={{ color: "#2b7695" }} />{" "}
                  <text
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND);
                    }}
                  >
                    Back to Home
                  </text>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={open}
        className="dialogbox-for-login"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div
              style={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
            >
              <ErrorSharpIcon sx={{ color: "#ed143d" }} fontSize="large" />
              <Typography variant="h5">Alert</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              {errordis}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openPass}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div
              style={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
            >
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Alert
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Your Password is Expired,Please Change!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClosePass();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openChange}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div
              style={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
            >
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Alert
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              You are using a default password,Please Change!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleCloseChange();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
