import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
    Button,
    Grid,
    Typography,
    Box,
    Divider,
    Chip,
    IconButton,
    Tooltip,
    Skeleton,
  } from "@mui/material";

const InvestorMyEmployerDailogBox = ({
  open,
  handleChangeinvstor,
  getitem,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleChangeinvstor}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle >
         
        </DialogTitle>
        <DialogContent >
         
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvestorMyEmployerDailogBox;
