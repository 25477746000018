import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  FormControl,
  InputAdornment,
  FormLabel,
  FormHelperText,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  SendRounded as SendRoundedIcon,
  LockClockRounded as LockClockRoundedIcon,
} from "@mui/icons-material";
import logo from "../../../../img/VVLogo_updated.png";
import { height } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
function LocalServiceLogin() {
  const theme = useTheme();
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const axiosData = useAxios();
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const [timeRemaining, setTimeRemaining] = useState(90); // 90 seconds

  const [isFocused, setIsFocused] = useState({
    contact_number: false,
    otp: false,
  });
  const [captureBlur, setCaptureBlur] = useState({
    contact_number: false,
    otp: false,
  });
  const [otpPage, setOtpPage] = useState(false);
  const [getStatusCode, setGetStatusCode] = useState(0);
  const handleMobileValidation = async (value) => {
    const isValidPhoneNumber = /^\d{10}$/i.test(value);
    if (!isValidPhoneNumber || !value) {
      return false;
    } else if (/^\d{10}$/.test(value)) {
      if (isValidPhoneNumber) {
        // const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `localservices/mobileexist/${value}`
          );
          setGetStatusCode(response.status);
        } catch (err) {
          console.log("Error", err);
        }
      }
    } else {
      return true; // For empty value, we don't show any error message
    }
    return true;
  };
  const initialValues = { contact_number: "", otp: "" };
  const validationSchema = Yup.object({
    contact_number: Yup.number()
      .min(1000000000, "Not a Valid Mobile number")
      .max(9999999999, "Not a Valid Mobile number")
      .test(
        "is-valid-mobile-number",
        "Invalid Mobile Number",
        function (value) {
          const isValidPhoneNumber = /^\d{10}$/i.test(value);
          if (!value) {
            return false;
          } else if (!isValidPhoneNumber && value.length === 10) {
            return false;
          } else if (/^\d{9}$/.test(value)) {
            return true;
          } else if (/^\d{10}$/.test(value)) {
            if (isValidPhoneNumber) {
              if (getStatusCode === 200) {
                return true;
              } else if (getStatusCode === 204) {
                return this.createError({
                  message: "Mobile number does not exists in the database",
                  path: "contact_number",
                });
              }
              // try {
              //   const response = await axiosData.get(
              //     `localservices/mobileexist/${value}`
              //   );
              //   if (
              //     response.status === 200 &&
              //     captureBlur.contact_number === false
              //   ) {
              //     // setOtpPage(true)
              //     return true;
              //   } else if (response.status === 204) {
              //     return this.createError({
              //       message: "Mobile number does not exists in the database",
              //       path: "contact_number",
              //     }); // Mobile number is valid and does not exist in the database
              //   }
              // } catch (err) {
              //   console.log("Error", err);
              // }
            }
          } else {
            return true; // For empty value, we don't show any error message
          }
          return true;
        }
      ),
    otp: Yup.string()
      .matches(/^\d{4}$/, "OTP Must be only 4 digits number")
      .required("OTP Required"),
  });
  const handleFormikSubmit = async () => {};

  useEffect(() => {
    if (otpPage) {
      let countdown;
      if (timeRemaining > 0) {
        countdown = setInterval(() => {
          setTimeRemaining((prevTime) => prevTime - 1);
        }, 1000);
      }
      return () => clearInterval(countdown);
    }
  }, [timeRemaining, otpPage]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <>
      <Box height={"100vh"} display="flex" justifyContent={"center"}>
        <Box width="50%" height={"100%"}>
          <Box display={"flex"} flexDirection={"column"} height={"70vh"}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormikSubmit}
            >
              {(formikProps) => {
                const contactValid =
                  validationSchema.fields.contact_number.isValidSync(
                    formikProps.values.contact_number
                  );
                const otpValid = validationSchema.fields.otp.isValidSync(
                  formikProps.values.otp
                );
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center ",
                      }}
                    >
                      <img
                        src={logo}
                        style={{ width: isDevice ? "50%" : "50vw" }}
                        alt="alt"
                      />
                    </div>
                    <div
                      style={{
                        flexGrow: 3,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {otpPage ? (
                          <Box textAlign={"left"}>
                            <IconButton onClick={() => setOtpPage(false)}>
                              <ArrowBackIcon />
                            </IconButton>
                          </Box>
                        ) : null}
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Barlow-Black",
                            fontWeight: "bold",
                            fontSize: isDevice ? "30px" : "5vw",
                          }}
                        >
                          {otpPage ? "OTP Verification" : "Login"}
                        </Typography>
                        {otpPage ? (
                          <>
                            {" "}
                            <Typography
                              variant="body1"
                              sx={{ fontSize: isDevice ? "14px" : "2vw" }}
                            >
                              Enter the OTP received to
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Barlow-Black",
                                fontWeight: "bold",
                                fontSize: isDevice ? "20px" : "2.8vw",
                              }}
                            >
                              +91 {formikProps.values.contact_number}
                            </Typography>
                            <FormControl fullWidth>
                              <FormLabel
                                sx={{
                                  fontWeight: "bold",
                                  fontFamily: "Barlow-Medium",
                                }}
                                style={{
                                  color:
                                    isFocused.otp === true &&
                                    (formikProps.errors.otp === undefined ||
                                      formikProps.touched.otp === undefined ||
                                      captureBlur.otp === false)
                                      ? "#1976d5"
                                      : formikProps.errors.otp !== undefined &&
                                        formikProps.touched.otp === true &&
                                        captureBlur.otp === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                Enter OTP
                              </FormLabel>
                              <TextField
                                className="OtpInput"
                                fullWidth
                                inputProps={{
                                  maxLength: 4,
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      otp: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      otp: false,
                                    });
                                  },
                                }}
                                onBlurCapture={() =>
                                  setCaptureBlur({
                                    ...captureBlur,
                                    otp: true,
                                  })
                                }
                                type="number"
                                id="otp"
                                // label="Enter OTP"
                                placeholder="- - - -"
                                variant="outlined"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                }}
                                onChange={(e) => {
                                  setCaptureBlur({
                                    ...captureBlur,
                                    otp: false,
                                  });
                                  formikProps.handleChange(e);
                                }}
                                name="otp"
                                value={formikProps.values.otp}
                                onBlur={formikProps.handleBlur}
                                InputProps={{
                                  maxLength: 10,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockClockRoundedIcon
                                        sx={{
                                          color:
                                            isFocused.otp === true &&
                                            (formikProps.errors.otp ===
                                              undefined ||
                                              formikProps.touched.otp ===
                                                undefined ||
                                              captureBlur.otp === false)
                                              ? "#1976d5"
                                              : formikProps.errors.otp !==
                                                  undefined &&
                                                formikProps.touched.otp ===
                                                  true &&
                                                captureBlur.otp === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.otp &&
                                  Boolean(formikProps.errors.otp) &&
                                  captureBlur.otp
                                }
                                helperText={
                                  formikProps.touched.otp &&
                                  captureBlur.otp &&
                                  formikProps.errors.otp
                                }
                              />
                            </FormControl>
                          </>
                        ) : (
                          <FormControl fullWidth>
                            <FormLabel
                              sx={{
                                fontWeight: "bold",
                                fontFamily: "Barlow-Medium",
                              }}
                              style={{
                                color:
                                  isFocused.contact_number === true &&
                                  (formikProps.errors.contact_number ===
                                    undefined ||
                                    formikProps.touched.contact_number ===
                                      undefined ||
                                    captureBlur.contact_number === false)
                                    ? "#1976d5"
                                    : formikProps.errors.contact_number !==
                                        undefined &&
                                      formikProps.touched.contact_number ===
                                        true &&
                                      captureBlur.contact_number === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              Mobile Number
                            </FormLabel>
                            <TextField
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    contact_number: true,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    contact_number: false,
                                  });
                                },
                              }}
                              onBlurCapture={() =>
                                setCaptureBlur({
                                  ...captureBlur,
                                  contact_number: true,
                                })
                              }
                              fullWidth
                              required
                              placeholder={"Enter Your Mobile Number"}
                              name="contact_number"
                              // label={t("Mobile_Number")}
                              type="Number"
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              id="mobile_number"
                              onBlur={formikProps.handleBlur}
                              onChange={(e) => {
                                setCaptureBlur({
                                  ...captureBlur,
                                  contact_number: false,
                                });
                                const { value } = e.target;
                                if (value.length <= 10) {
                                  formikProps.handleChange(e);
                                  handleMobileValidation(e.target.value);
                                }
                              }}
                              value={formikProps.values.contact_number}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                maxLength: 10,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <b
                                      style={{
                                        color:
                                          isFocused.contact_number === true &&
                                          (formikProps.errors.contact_number ===
                                            undefined ||
                                            formikProps.touched
                                              .contact_number === undefined ||
                                            captureBlur.contact_number ===
                                              false)
                                            ? "#1976d5"
                                            : formikProps.errors
                                                .contact_number !== undefined &&
                                              formikProps.touched
                                                .contact_number === true &&
                                              captureBlur.contact_number ===
                                                true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    >
                                      +91
                                    </b>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.contact_number &&
                                Boolean(formikProps.errors.contact_number) &&
                                captureBlur.contact_number
                              }
                              helperText={
                                formikProps.touched.contact_number &&
                                captureBlur.contact_number &&
                                formikProps.errors.contact_number
                              }
                            />
                          </FormControl>
                        )}

                        <Box display="flex" justifyContent={"space-between"}>
                          <FormHelperText
                            sx={{
                              fontFamily: "Barlow-Medium",
                              fontWeight: "bold",
                              textAlign: "left",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {otpPage
                              ? `Time Remaining:${
                                  timeRemaining === 0
                                    ? "Expired"
                                    : formatTime(timeRemaining)
                                }`
                              : "A 4 digit OTP will be sent via SMS to Verify your mobile number!"}
                          </FormHelperText>
                          <Box>
                            {otpPage && (
                              <Button
                                // disableElevation
                                // disableRipple
                                disableFocusRipple
                                variant="text"
                                endIcon={<SendRoundedIcon />}
                                disabled={timeRemaining === 0 ? false : true}
                                onClick={() => setTimeRemaining(90)}
                                sx={{
                                  float: "right",
                                  textTransform: "capitalize",
                                  "&.MuiButton-root:hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              >
                                Resend Otp
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1, width: "100%" }}>
                      <Button
                        variant="contained"
                        disabled={
                          otpPage
                            ? otpValid && timeRemaining !== 0
                              ? false
                              : true
                            : contactValid
                            ? false
                            : true
                        }
                        type={otpPage ? "submit" : "button"}
                        onClick={() => {
                          if (otpPage) {
                            alert("true bro");
                          } else {
                            setOtpPage(true);
                          }
                        }}
                        fullWidth
                      >
                        {otpPage ? "Submit" : "Next"}
                      </Button>
                    </div>
                  </>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      {/* <div style={{ height: "100vh", display: "flex",justifyContent:"center",alignItems:"center" }}>
        <div style={{ width: "50%", border: "1px solid" }}>xcvz</div>
      </div> */}
    </>
  );
}

export default LocalServiceLogin;
