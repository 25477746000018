import React from "react";
import { Box, Card, Grid, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";
import PeopleIcon from "@mui/icons-material/People";
const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "16px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
  },
}));

function StatCardsUser({ getApi }) {
  const fillStatForadmin = getApi.filter((i) => {
    return i.usertype.includes("admin");
  });
  const fillStatForseeker = getApi.filter((i) => {
    return i.usertype === "seeker";
  });
  const filStatForprovider = getApi.filter((i) => {
    return i.usertype === "provider";
  });
  const filStatFormp = getApi.filter((i) => {
    return i.usertype === "mp";
  });
  const filStatFormla = getApi.filter((i) => {
    return i.usertype === "mla";
  });
  const filStatForverifier = getApi.filter((i) => {
    return i.usertype === "verifier";
  });
  const filStatForvolunteer = getApi.filter((i) => {
    return i.usertype === "volunteer";
  });
  const filStatForMentor = getApi.filter((i) => {
    return i.usertype === "mentor";
  });
  const filStatForServiceProvider = getApi.filter((i) => {
    return i.usertype === "serviceProvider";
  });
  const filStatForInvestor = getApi.filter((i) => {
    return i.usertype === "investor";
  });
  const filStatForPartner = getApi.filter((i) => {
    return i.usertype === "partner";
  });
  const [
    admin,
    seeker,
    provider,
    mp,
    mla,
    allusers,
    verifier,
    volunteer,
    mentor,
    serviceProvider,
    investor,
    partner
  ] = [
    fillStatForadmin.length,
    fillStatForseeker.length,
    filStatForprovider.length,
    filStatFormp.length,
    filStatFormla.length,
    getApi.length,
    filStatForverifier.length,
    filStatForvolunteer.length,
    filStatForMentor.length,
    filStatForServiceProvider.length,
    filStatForInvestor.length,
    filStatForPartner.length,
  ];
  const cardList = [
    {
      name: "Total User",
      count: allusers,
      bgColor: "#1b2850",
      svg: <VerifiedUserIcon fontSize="large" />,
    },
    {
      name: "Total Admin",
      count: admin,
      bgColor: "#28C76F",
      svg: <ManageAccountsIcon fontSize="large" />,
    },
    {
      name: "Total Seekers",
      count: seeker,
      bgColor: "#28c7c7",
      svg: <AddTaskRoundedIcon fontSize="large" />,
    },
    {
      name: "Total Providers",
      count: provider,
      bgColor: "#ea5455",
      svg: <AddTaskRoundedIcon fontSize="large" />,
    },
    {
      name: "Total Mp's",
      count: mp,
      bgColor: "#fd7e14",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Mla's",
      count: mla,
      bgColor: "#286fc7",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Verifiers",
      count: verifier,
      bgColor: "#6428c7cc",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Volunteers",
      count: volunteer,
      bgColor: "#e84786",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Mentors",
      count: mentor,
      bgColor: "#339fcd",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Service Providers",
      count: serviceProvider,
      bgColor: "#eecf02",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Investors",
      count: investor,
      bgColor: "#055779",
      svg: <PeopleIcon fontSize="large" />,
    },
    {
      name: "Total Partners",
      count: partner,
      bgColor: "#908383",
      svg: <PeopleIcon fontSize="large" />,
    },
  ];
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <StyledCard
              sx={{ backgroundColor: `${item.bgColor}` }}
              elevation={6}
            >
              <Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Box sx={{ color: "white" }}>{item.svg}</Box>
                  <CountUp
                    style={{
                      fontSize: "26px",
                      color: "#f2f2f2",
                    }}
                    start={0}
                    end={item.count}
                    duration={1}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                >
                  {item.name}
                </Typography>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatCardsUser;
