import React, { useState, useEffect } from "react";
import Breadcrumb from "../../BreadCrumb";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Chip,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import DialogContentText from "@mui/material/DialogContentText";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";

import { ROUTINGDATA } from "../../../ROUTINGDATA";

import { t } from "i18next";
import { useLocationFind } from "../../../skeleton/LocationFind";

import leftimg from "../../../../img/Left-employer.png";
import lefttamilimg from "../../../../img/Left-tamil-employer.png";
import { FormHelperText } from "@mui/material";

import Industry from "../../../../img/economy 2.png";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import Designation from "../../../../img/cv 1.png";
import { useNavigate } from "react-router-dom";

import { OpenInNewRounded as OpenInNewRoundedIcon } from "@mui/icons-material/";
import districtActive from "../../../../img/location-active.png";
import Location from "../../../../img/location (1) 1.png";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import DeleteIcon from '@mui/icons-material/Delete';
import InvestorMyEmployerDailogBox from "./Dailogboxforinvestor/InvestorMyEmployerDailogBox";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

const InvestorMyEmployer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n, ready } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const investor_email = JSON.parse(localStorage.getItem("investor"));

  const [focusout, setfocus] = useState();
  const ref = React.createRef(null);
  const [valid, setvalid] = useState(true);
  const [alertm, setalert] = useState(false);

  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [comdata, setComdata] = useState([]);
  const [focusNo, setFocusNo] = useState();
  const [emailErr, setEmailError] = useState(true);
  const [pinLoad, setpinLoad] = useState(false);
  const [alerte, setalerte] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [district, setDistrict] = useState([]);
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);

  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [investordailogopen, setInvestordailogopen] = useState(false); 
  const [getitem, setGetitem] = useState({});
  const handleChangeinvstor = (item) => {
    setInvestordailogopen(!investordailogopen);
    setGetitem(item);
    // setLoading({ ...loading, dialogLoad: true });
  };

  // const getOneData = async (id) => {
  //   try {
  //     const getOneAxios = await axiosData.get(`mentor/email/${id}/users`);
  //     const { data } = getOneAxios;

  //     setGetOne(data);
  //     setLoading({ ...loading, dialogLoad: false });
  //   } catch (err) {
  //     console.log("Error", err);
  //   }
  // };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const axiosData = useAxios();
  const errors = {};
  const validate = async (values) => {
    if (!values.mobile_number) {
      errors.mobile_number = t("Mobile_Number_must_be_Required");
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setvalid(true);
    } else if (/^\d{10}$/.test(values.mobile_number)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `users/Mobile/${values.mobile_number}`
          );

          if (response.status === 200) {
            setalert(true);
          } else if (response.status === 204) {
            setalert(false);
          }

          setvalid(false);

          return () => controller.abort();
        } catch (error) {}
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullname: "",
      email_id: "",
      mobile_number: "",
      state: "Tamil Nadu",
      designation: "",
      currentLocation: "",
      district: "",
      pincode: "",
    },
    validate,
    validationSchema: Yup.object({
      district: Yup.string().required(t("Select_District2")),
      currentLocation: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Current_Location_must_be_Required")),
      fullname: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Contact_person_name_is_required")),
      designation: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("Designation_must_be_Required")),
      mobile_number: Yup.number()
        .min(6000000000, t("Not_Valid_Mobile_Number!"))
        .max(9999999999, t("Not_Valid_Mobile_Number!"))
        .required(t("Mobile_Number_must_be_Required2")),
      pincode: Yup.string().required(t("pincode_is_Required")),
      state: Yup.string().required("state must be Required"),
    }),

    onSubmit: (values) => {
      // const pincodeSplit = values.pincode.split("/");
      // const [a, b] = pincodeSplit;
      // values.pincode = a;
      // values.area = b;
      // if (storedCountry) {
      //   values.state = storedState;
      // }
      // if (storedCountry) {
      //   values.country = storedCountry;
      // }
      // values.email_id = formEmail;
      // setIsSubmitting(true);
      // Apifetch(values);
    },
  });
  const submitForm = async (values) => {
    const useAxiosData = await axiosData.post(`employee`, {
      name: values.fullname,
      email: formEmail,
      pincode: values.pincode,
      mobile: values.mobile_number,
      designation: values.designation,
      state: values.state,
      currentlocation: values.currentLocation,
      district: values.district,
      registerby: investor_email,
    });
  };
  const fetchApi = async () => {
    const useAxiosData = await axiosData.get(`employee/${investor_email}`);
    const { data } = useAxiosData;
    setGetApi(data);
    setUseApi(data);
    setTotalRows(data.length);
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  useEffect(() => {
    if (formik.values.state === "Tamil Nadu") {
      vodingDistrict();
    }
    if (formik.values.state === "Puducherry") {
      PuducherryState();
    }
    fetchApi();
  }, [formik.values.state]);

  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const PuducherryState = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/Puducherry`);
      const data = getOneAxios?.data?.collections?.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const areaPincode = async (values) => {
    // setAdDistrict(values);

    try {
      setpinLoad(true);
      let getOneAxiospin = [];

      if (formik.values.state === "Tamil Nadu") {
        let getOneAxiospin1 = await axiosData.get(
          `/distritarea/getArea/${values}`
        );
        getOneAxiospin = getOneAxiospin1.data;
      } else {
        const getOnepinAxios = await axiosData.get(
          `/General/PuducherryPostoffice`
        );
        const data = getOnepinAxios?.data?.collections?.data;

        if (data) {
          const getDistrictPin = data.filter((i) => {
            return i.District === values;
          });
          getOneAxiospin = [...getDistrictPin];
        }
      }
      setpinLoad(false);
      const datapin = getOneAxiospin;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const searchdatas = (searchdata, getApi) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = getApi.filter((item) => {
      var valuess =
        item.empname.match(searchtext) ||
        item.emailid.match(searchtext2) ||
        item.designation.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };
  const handleChangeforswitch = async (email, status) => {
    const getOnepinAxios = await axiosData.put(`employee/status/${email}`, {
      status: status,
    });
    const { data } = getOnepinAxios;
    fetchApi();
  };
  const handleEmailBlur = () => {
    if (!formEmail) {
      setFormEmailError(t("EmailId_must_be_Required"));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formEmail)) {
      setFormEmailError(t("Invalid_email_format"));
    } else {
      setFormEmailError("");
    }
  };

  const handleEmailChange = async (event) => {
    const email_id = event.target.value.replace(/[^a-z0-9@.]/gi, "");
    setFormEmail(email_id);
    if (!email_id) {
      setfocus(true);
      setFormEmailError(t("EmailId_must_be_Required"));
      errors.email_id = t("EmailId_must_be_Required");
      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setfocus(false);
      setFormEmailError(t("Invalid_email_address"));
      errors.email_id = t("Invalid_email_address");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setEmailError(true);
      setFormEmailError("");
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
            formik.setFieldValue("email_id", event.target.value);
          }
        } catch (error) {}
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      errors.email_id = "Invalid email address";
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      setEmailError(true);
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  if (ready) {
    return (
      <div>
        <div className="breadcrumb-desgin" style={{ margin: "0px" }}>
          <Breadcrumb />
        </div>
        <div className="btn-myemployer-investor">
          <Button
            className="btn-em"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Employee
          </Button>
        </div>
        <div className="inverstor-table-header" style={{ marginTop: "0px" }}>
          <h3>Employee Details</h3>
        </div>
        <div className="input-search-inverstor">
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{
              "& fieldset": { border: "none" },
            }}
            className="inputsserachtextfield"
            placeholder="Search your data"
            onChange={(e) => {
              if (e.currentTarget.value.length === 0) {
                setUseApi(getApi);
                setTotalRows(getApi.length);
                setPage(0);
              }
              searchdatas(e.currentTarget.value, getApi);
            }}
            InputProps={{
              // ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Card sx={{ padding: "20px", mb: 8 }}>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow className="table-row investortablerow">
                  {/* <TableCell> Profile</TableCell> */}
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Phone Number</TableCell>
                  <TableCell align="center">Pincode</TableCell>
                  <TableCell align="center">Area</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {useApi.length > 0 ? (
                  <>
                    {useApi.map((item) => (
                      <TableRow
                        // key={item._id}
                        hover
                        className="tablecell "
                      >
                        {/* {item.pincode_franchiser ? (
                          <Typography>
                            {item.pincode_franchiser.pincode_franchiser_email}
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                              handleChange(item.pincode);
                            }}
                          >
                            Assign Pincode
                          </Button>
                        )} */}
                        <TableCell className="investortablecell">
                          {item.empname}
                        </TableCell>
                        <TableCell className="investortablecell" align="center">
                          {item.emailid}
                        </TableCell>
                        <TableCell className="investortablecell" align="center">
                          {item.mobileno}
                        </TableCell>
                        <TableCell
                          className="investortablecell "
                          align="center"
                        >
                          {item.pincode}
                        </TableCell>
                        <TableCell
                          className="investortablecell "
                          align="center"
                        >
                          {item.location}
                        </TableCell>
                        <TableCell className="investortablecell" align="center">
                          {/* <div
                              style={{
                                display: "flex",
                                gap: 5,
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                className="btn-action-investor-1"
                              >
                                <OpenInNewRoundedIcon />
                              </Button>
                              <Button
                                variant="contained"
                                className="btn-action-investor-2"
                              >
                                <SwapHorizIcon />
                              </Button>
                            </div> */}
                          <div
                            style={{
                              display: "flex",
                              gap: 5,
                              justifyContent: "center",
                            }}
                          >
                           
                            <Switch
                              checked={
                                item.status === "InActive" ? false : true
                              }
                              onChange={() => {
                                handleChangeforswitch(
                                  item.emailid,
                                  item.status === "InActive"
                                    ? "Active"
                                    : "InActive"
                                );
                              }}
                            />
                             <Button
                              variant="contained"
                              className="btn-action-investor-1"
                              onClick={()=>{
                                //  getOneData(item.email_id);
                                handleChangeinvstor(item)
                              }}
                            >
                              <OpenInNewRoundedIcon />
                            </Button>
                            <Button
                              variant="contained"
                              className="deletebtn-action-investor-1"
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell>
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>

                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <InvestorMyEmployerDailogBox 
        open={investordailogopen}
        getitem={getitem}
        handleChangeinvstor={handleChangeinvstor}
        />

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className="investordailog"
        >
          <DialogContent
            className="investorsdailog"
            sx={{ padding: "30px 40px" }}
          >
            <DialogContentText>
              <div className="overall-jobseeker-form-1">
                <form onSubmit={formik.handleSubmit}>
                  <div className="jobseeker-textfield">
                    <label>{t("Full_Name")}</label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="fullname"
                      required
                      variant="outlined"
                      size="small"
                      name="fullname"
                      placeholder={t("Enter_Your_Full_Name")}
                      onBlur={formik.handleBlur}
                      fullWidth
                      onChange={(e) => {
                        if (e) {
                          const fullname = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("fullname", fullname);
                        }
                      }}
                      value={formik.values.fullname}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.fullname && formik.errors.fullname ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.fullname}
                        </Typography>
                      ) : (
                        <Typography sx={{ visibility: "hidden" }}>
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Email_Id")}</label>
                    {/* <Tooltip title="Enter your Email" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="email_id"
                      variant="outlined"
                      size="small"
                      name="formEmail"
                      required
                      ref={ref}
                      onBlurCapture={() => {
                        setfocus(true);
                      }}
                      placeholder={t("Enter_Your_Email_ID")}
                      onBlur={handleEmailBlur}
                      onChange={handleEmailChange}
                      value={formEmail}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formEmailError && focusout ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formEmailError}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Mobile_Number")}</label>
                    {/* <Tooltip title="Enter Your Mobile Number" arrow> */}
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      required
                      id="mobile_number"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="mobile_number"
                      onBlur={formik.handleBlur}
                      onChange={handleChange}
                      onBlurCapture={() => {
                        setFocusNo(true);
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      value={formik.values.mobile_number}
                      placeholder={t("Enter_Your_Mobile_Number")}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "medium",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              +91
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* </Tooltip> */}
                    <FormHelperText>
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo ? (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.mobile_number}
                        </div>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>

                  <div className="jobseeker-textfield">
                    <label>{t("Designation")}</label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="designation"
                      required
                      variant="outlined"
                      size="small"
                      name="designation"
                      placeholder={t("Enter_Your_Designation")}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("designation", value);
                        }
                      }}
                      value={formik.values.designation}
                      fullWidth
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className="Industryimg"
                              style={{ marginLeft: "0px" }}
                              src={Designation}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.designation &&
                      formik.errors.designation ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.designation}
                        </Typography>
                      ) : (
                        <Typography sx={{ visibility: "hidden" }}>
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("select_state")}</label>
                    <div className="jobseeker-btn-img">
                      <Stack direction="row" className="mobile-for-Work-Status">
                        <Button
                          sx={{ marginLeft: "0px" }}
                          className={
                            formik.values.state === "Tamil Nadu"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(event) => {
                            const value = "Tamil Nadu";
                            formik.setFieldValue("state", value);
                            formik.setFieldValue("pincode", "");
                          }}
                        >
                          {formik.values.state === "Tamil Nadu" ? (
                            <img src={districtActive} />
                          ) : (
                            <img src={Location} />
                          )}
                          <span
                            className={
                              formik.values.state === "Tamil Nadu"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Tamil_Nadu")}
                          </span>
                        </Button>
                        <Button
                          className={
                            formik.values.state === "Puducherry"
                              ? "jobseeker-btn-gender-active"
                              : "jobseeker-btn-gender"
                          }
                          variant="outlined"
                          onClick={(event) => {
                            const value = "Puducherry";
                            formik.setFieldValue("state", value);
                            formik.setFieldValue("pincode", "");
                          }}
                        >
                          {formik.values.state === "Puducherry" ? (
                            <img src={districtActive} />
                          ) : (
                            <img src={Location} />
                          )}
                          <span
                            className={
                              formik.values.state === "Puducherry"
                                ? "btn-space-name-active"
                                : "btn-space-name"
                            }
                          >
                            {t("Puducherry")}{" "}
                          </span>
                        </Button>
                      </Stack>
                      <FormHelperText>
                        {formik.touched.state && formik.errors.state ? (
                          <Typography sx={{ color: "red", fontSize: "13px" }}>
                            {formik.errors.state}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "13px",
                              visibility: "hidden",
                            }}
                          >
                            none
                          </Typography>
                        )}
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="jobseeker-textfield">
                    <label>{t("Current_Location")}</label>
                    <TextField
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="currentLocation"
                      variant="outlined"
                      size="small"
                      name="currentLocation"
                      placeholder={t("Locate_Me")}
                      onChange={(e) => {
                        if (e) {
                          const value = e.target.value;
                          formik.setFieldValue("currentLocation", value);
                        }
                      }}
                      value={formik.values.currentLocation}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        // ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <MyLocationIcon sx={{ color: "#5C6BF4" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.currentLocation &&
                      formik.errors.currentLocation ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.currentLocation}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("District")}</label>
                    {/* <Tooltip title="Select Your District" arrow> */}
                    <Autocomplete
                      size="small"
                      required
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      disableClearable={formik.values.district ? false : true}
                      filterOptions={filterOptions}
                      name="district"
                      id="district"
                      value={formik.values.district}
                      onChange={(event, value) => {
                        formik.setFieldValue("district", value);
                        areaPincode(value);
                        formik.setFieldValue("pincode", "");
                      }}
                      onBlur={formik.handleBlur}
                      options={districtData}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          placeholder={t("Select_District")}
                          name="district"
                        />
                      )}
                      onOpen={(event) => event.preventDefault()}
                      PopperComponent={({ children, ...popperProps }) => (
                        <Popper
                          {...popperProps}
                          placement="bottom-start"
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                          }}
                          PopperProps={{
                            placement: "bottom-start",
                          }}
                        >
                          <Paper>{children}</Paper>
                        </Popper>
                      )}
                      ListboxProps={{
                        style: { maxHeight: "180px", overflowY: "auto" },
                      }}
                    />
                    <FormHelperText>
                      {formik.touched.district && formik.errors.district ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.district}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                    {/* </Tooltip> */}
                  </div>
                  <div className="jobseeker-textfield">
                    <label>{t("Pincode")}</label>
                    {/* <Tooltip title="Select Your Pin Code" arrow> */}
                    <Autocomplete
                      size="small"
                      disableClearable={formik.values.pincode ? false : true}
                      disablePortal
                      freeSolo
                      disabled={
                        formik.values.currentLocation && !pinLoad ? false : true
                      }
                      required
                      sx={{ marginTop: "8px", backgroundColor: "white" }}
                      id="pincode"
                      name="pincode"
                      options={comdata}
                      value={formik.values.pincode}
                      onChange={(event, value) => {
                        formik.setFieldValue("pincode", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          name="pincode"
                          placeholder={t("pincodeEnter")}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {/* {pinLoad ? (
                                  <CircularProgress
                                    // size={24}
                                    style={{
                                      width: "1.4rem",
                                      height: "1.4rem",
                                      color: "#4991dd",
                                    }}
                                  />
                                ) : ( */}
                                <ShareLocationIcon
                                  sx={{
                                    color: formik.values.currentLocation
                                      ? "#414141"
                                      : "#bdbdbd",
                                  }}
                                />
                                {/* )}  */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <FormHelperText>
                      {formik.touched.pincode && formik.errors.pincode ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.pincode}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "13px",
                            visibility: "hidden",
                          }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>

                  <div>
                    <Button
                      // type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2, mb: 2 }}
                      disabled={
                        !formEmailError &&
                        formik.isValid &&
                        formik.dirty &&
                        isSubmitting === false
                          ? false
                          : true
                      }
                      component="label"
                      onClick={() => {
                        submitForm(formik.values);
                      }}
                    >
                      {t("Submit")}
                      {/* {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",

                            marginLeft: 12,
                          }}
                        />
                      )} */}
                      {/* <VisuallyHiddenInput type="file" /> */}
                    </Button>
                  </div>
                </form>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};
export default InvestorMyEmployer;
