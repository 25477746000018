import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Chip,
  Button,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  PostAdd as PostAddIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";
import defaultimg from "../../../../../img/mentorImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../../api/useAxios";
import { ROUTINGDATA } from "../../../../ROUTINGDATA";
import StatMLAcards from "../../../../admin-panel/admin-components/StatCards/StatMLAcards";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import SkeletonSeekCard from "../../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import ApproveDialog from "../../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";
import ExportCS from "../../../../admin-panel/admin-components/ExportComponents/ExportCS";
import EnterContactSupportDialog from "../../../../admin-panel/admin-components/DialogBoxes/EnterContactSupportDialog";
import ContactSupportSnack from "../../../../admin-panel/admin-components/SnackBars/ContactSupportSnack";
function MPShortList() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_SHORTLISTED;

  const navigate = useNavigate();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [MLAneed, setMLAneed] = useState([]);
  const [allData, setAllData] = useState([]);

  const [approveOpen, setApproveOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [openFormikContactDialog, setOpenFormikContactDialog] = useState(false);
  const [contactSeekDetails, setContactSeekDetails] = useState({
    name: "",
    email_id: "",
    contactName: "",
    mobile: 0,
  });
  const [openSupportSnack, setOpenSupportSnack] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRowsShort, setTotalRowshort] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [allMLAneedSupport, setAllMLAneedSupport] = useState([]);
  const [searchicon, setsearchicon] = useState(false);
  const [MLAEmail, setMLAEmail] = useState("");
  const [shortlistedData, setShortListedData] = useState([]);
  const [getapi, setgetapi] = useState([]);
  const MLAPageNumbe = sessionStorage.getItem("mpshortlist_page_number");
  const MLAPageNumber = parseInt(MLAPageNumbe);
  const setMLAPageNumber = MLAPageNumber ? MLAPageNumber : 0;
  const mp = JSON.parse(localStorage.getItem("mp"));

  const shortlisted = sessionStorage.getItem(`mp_shortedradio`);

  const openApproveDialog = () => {
    setApproveOpen(true);
  };

  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mpconstituencyseeker", {
        email_id: mp,
      });
      const { data } = MpData;

      setAllData(data[0]);
      const mp_mlaAllData = data[0].mla_constituency_list.flatMap((i) => {
        return i.needSupport;
      });
      setAllMLAneedSupport(mp_mlaAllData);
      const mp_mlaShorlist = data[0].mla_constituency_list.flatMap((i) => {
        return i.shortListed;
      });
      setgetapi(mp_mlaShorlist);
      shortlistBy(shortlisted ? shortlisted : "all", mp_mlaShorlist);
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const selectedApiForExcel = shortlistedData.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
    };
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const shortlistBy = (shortvalue, total) => {
    if (shortvalue === "all") {
      setShortListedData(total);
      setTotalRows(total.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "MP") {
      const fill = total.filter((d) => {
        return d.supported === "MP";
      });
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.supported === "");
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setMLAPageNumber);
    }
  };
  const updateAdminVerify = async (email_id, decide, notes) => {
    setIsLoadingPage(true);
    try {
      await axiosData.put(
        `/seeker/email/${email_id}`,
        { notes: notes },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axiosData.put(`family/admin/${email_id}`, { shortList: decide });
      getMpEmailId();
      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoadingPage(false);
  };

  useEffect(() => {
    getMpEmailId();
    setPage(setMLAPageNumber);

    sessionStorage.removeItem("mla_page_number");
  }, [currentUrl, location.state]);
  if (!isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px", mb: 8 }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {allData.mp_constituency}'s Shortlisted Data
              </Typography>
            </Card>
          </Box>

          <Card sx={{ boxShadow: 0 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item md={8}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Filter By Support
                      </FormLabel>
                      <RadioGroup
                        row
                        id="radio-btn"
                        value={shortlisted ? shortlisted : "all"}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ marginRight: "18px" }}
                        onChange={(e) => {
                          sessionStorage.setItem(
                            `mp_shortedradio`,
                            e.target.value
                          );

                          shortlistBy(e.target.value, getapi);
                        }}
                      >
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value="all" />}
                          label="All"
                        ></FormControlLabel>
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value="Pending" />}
                          label="Pending"
                        ></FormControlLabel>
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value={"MP"} />}
                          label="Completed "
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <Tooltip title="Export As Excel" placement="bottom">
                      <ExportCS
                        selectedApiForExcel={selectedApiForExcel}
                        fileName={"Seeker-ShortListed-Data"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Family Income</TableCell>
                    <TableCell>MLA Constituency</TableCell>
                    <TableCell>Supported Status</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {shortlistedData.length > 0 ? (
                      shortlistedData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => {
                          const supportedStat =
                            item.supported === "" ? "pending" : "Supported";
                          return (
                            <TableRow className="tablecell">
                              <TableCell>
                                <img
                                  src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                  height={"50"}
                                  width="50"
                                  onError={(e) => {
                                    e.target.src = defaultimg;
                                  }}
                                  style={{ clipPath: "circle(50%)" }}
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell>{item.Name}</TableCell>
                              <TableCell>{item.email_id}</TableCell>
                              <TableCell>{item.FamilyIncome}</TableCell>
                              <TableCell>{item.mlaConstituency}</TableCell>
                              <TableCell>
                                {" "}
                                <Chip
                                  label={supportedStat}
                                  variant="filled"
                                  color={
                                    item.supported === "" ? "warning" : "info"
                                  }
                                  sx={{
                                    color: "#ffffff",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Full Details" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "mpshortlist_page_number",
                                        page
                                      );
                                      navigate(
                                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MPConstituency}/${ROUTINGDATA.MP_Shortlisted_fullDetails}`,
                                        {
                                          state: item.email_id,
                                        }
                                      );
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>

                                {item.supported === "" ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize ",
                                      boxShadow: 0,
                                      borderRadius: "16px",
                                    }}
                                    startIcon={<FavoriteRoundedIcon />}
                                    onClick={() => {
                                      setContactSeekDetails({
                                        ...contactSeekDetails,
                                        name: item.Name,
                                        email_id: item.email_id,
                                        mobile: item.mobile_number,
                                      });
                                      setOpenFormikContactDialog(true);
                                    }}
                                  >
                                    Support
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>

        <EnterContactSupportDialog
          openFormikContactDialog={openFormikContactDialog}
          setOpenFormikContactDialog={setOpenFormikContactDialog}
          contactSeekDetails={contactSeekDetails}
          setContactSeekDetails={setContactSeekDetails}
          getFunction={getMpEmailId}
          setOpenSupportSnack={setOpenSupportSnack}
        />
        <ContactSupportSnack
          contactName={contactSeekDetails.contactName}
          seekName={contactSeekDetails.name}
          openSupportSnack={openSupportSnack}
          setOpenSupportSnack={setOpenSupportSnack}
        />
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <SkeletonSeekCard />
              </Box>
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default MPShortList;
