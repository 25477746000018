import React, { useEffect, useState } from "react";
import { Box, Card, Grid, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import useAxios from "../../../../api/useAxios";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "18px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: ".25rem",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)!important",
    transition: "0.2s",
  },
  "&.MuiCard-root:hover": {
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.4) !important",
  },
}));

function StatCardsForServiceProvider({ getApi, serviceName }) {
  const [validdata, setvaliddata] = useState([]);
  const getservicename = () => {
    const arr = serviceName.map((item) => {
      const value = getApi.filter((data) => {
        return data.type_of_service === item;
      });
      return {
        count: value.length,
        name: item,
        bgColor: "#2838a6",
        svg: <HourglassFullRoundedIcon fontSize="large" />,
      };
    });
    const arr1 = getApi.filter((item) => {
      const value = serviceName.filter((data) => {
        return item.type_of_service === data;
      });
      if (value.length === 0) {
        return item;
      }
    });
    arr.push({
      count: arr1.length,
      name: "other",
      bgColor: "#2838a6",
      svg: <HourglassFullRoundedIcon fontSize="large" />,
    });
    setvaliddata([...arr]);
  };

  useEffect(() => {
    getservicename();
  }, [serviceName, getApi]);

  const cardList = {
    name: `Total Leads`,
    count: getApi.length,
    bgColor: "#2838a6",
    svg: <HourglassFullRoundedIcon fontSize="large" />,
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        <Grid item xs={12} md={4}>
          <StyledCard sx={{ backgroundColor: `white` }}>
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Poppins-SemiBold" }}
                >
                  {cardList.name}
                </Typography>

                <CountUp
                  style={{
                    fontSize: "26px",
                  }}
                  start={0}
                  end={cardList.count}
                  duration={1}
                />
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems={"center"} height={"100%"}>
                {cardList.svg}
              </Box>
            </Box>
          </StyledCard>
        </Grid>
        {validdata.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <StyledCard sx={{ backgroundColor: `white` }}>
              <Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Poppins-SemiBold" }}
                  >
                    {item.name}
                  </Typography>

                  <CountUp
                    style={{
                      fontSize: "26px",
                    }}
                    start={0}
                    end={item.count}
                    duration={1}
                  />
                </Box>
              </Box>
              <Box>
                <Box display="flex" alignItems={"center"} height={"100%"}>
                  {item.svg}
                </Box>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatCardsForServiceProvider;
