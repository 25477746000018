import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Autocomplete,
  Box,
  Skeleton,
  Divider,
} from "@mui/material";
import { useEffect } from "react";
import useAxios from "../../../../api/useAxios";
import { useState } from "react";

function CreatePincodeFranchiserDialog({
  investor_email,
  open,
  setOpen,
  Onepincode,
}) {
  const axiosData = useAxios();
  const [conformOpen, setConformOpen] = useState(false);
  const [investorList, setInvestorList] = useState([]);
  const [fulldetail, setFulldetail] = useState([]);
  const [selectedInvestor, setSelectInvestor] = useState();
  const getpincodeWisedata = async () => {
    const axiosDatas = await axiosData.get(
      `investors/getpincodefranchiser/${investor_email}`
    );
    const { data } = axiosDatas;
    setFulldetail(data);
    const investoremail = data.map((i) => {
      return i.email_id;
    });

    setInvestorList(investoremail);
  };
  const uploadPincodeAllocation = async () => {
    const axiosDatas = await axiosData.patch(
      `investors/pincodefranchiser/${investor_email}`,
      {
        pincode: Onepincode,
        franchiserdistrict: selectedInvestor.district_name,
        franchisername: selectedInvestor.investor_name,
        franchiseremail: selectedInvestor.email_id,
        franchisernumber: selectedInvestor.mobileNumber,
      }
    );
    setConformOpen(!conformOpen);
    setSelectInvestor();
    setOpen(!open);
  };
  useEffect(() => {
    getpincodeWisedata();
  }, [investor_email]);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        // onClose={handleChange}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          {" "}
          Assign pincode to franchiser
        </DialogTitle>
        <DialogContent
          dividers="paper"
          sx={{ backgroundColor: "#f2f2f2", height: "420px" }}
        >
          <Box sx={{ padding: "20px", width: "100%" }}>
            <Grid container spacing={3} md={12} direction="column">
              <Grid item>
                <FormControl fullWidth>
                  {/* <FormLabel sx={{ fontWeight: "bold" }}>Email</FormLabel> */}
                  <Autocomplete
                    fullWidth
                    //disablePortal
                    id="combo-box-demo"
                    options={investorList}
                    onChange={(e, value) => {
                      const filterdata = fulldetail.find((i) => {
                        return i.email_id === value;
                      });
                      setSelectInvestor(filterdata);
                    }}
                    // getOptionLabel={(option) => option.email_id}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Email" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Divider
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#055779",
                    },
                    color: "#055779 ",
                  }}
                  textAlign="left"
                >
                  Pincode Franchiser Details
                </Divider>
                <Box sx={{ padding: "20px" }}>
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">Name:</span>
                      <div>
                        {selectedInvestor ? (
                          selectedInvestor?.investor_name
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">Email:</span>
                      <div>
                        {selectedInvestor ? (
                          selectedInvestor?.email_id
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">District:</span>
                      <div>
                        {selectedInvestor ? (
                          selectedInvestor?.district_name
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>{" "}
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">Mobile Number:</span>
                      <div>
                        {selectedInvestor ? (
                          selectedInvestor?.mobileNumber
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpen(!open);
              setSelectInvestor();
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setConformOpen(!conformOpen);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={conformOpen}
        onClose={conformOpen}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          bgcolor="#055779"
          color="white"
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Subscribe
        </DialogTitle>
        <DialogContent sx={{ marginTop: "20px" }}>
          <DialogContentText>
            Are you confrom to Allocate this pincode to Pincode Franchiser
          </DialogContentText>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              setConformOpen(!conformOpen);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              uploadPincodeAllocation();
            }}
          >
            Confrom
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreatePincodeFranchiserDialog;
