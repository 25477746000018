import React, { useState } from "react";
import { Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import Resume from "../FileUpload/UploadPdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

import Context from "../Context";
import useAxios from "../../../../api/useAxios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ResumeCard() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL
  const [isDelete, setIsDelete] = useState(false);
  const [deleteResume, setDeleteResume] = useState(false);

  return (
    <Context.Consumer>
      {(Context) => {
        let resume = "";
        if (Context.profile.fileUpload) {
          resume = Context.profile.fileUpload;
        }
        return (
          <div>
            <Dialog
              TransitionComponent={Transition}
              open={deleteResume}
              sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
            >
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
              >
                Delete resume
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText sx={{ fontWeight: 600 }}>
                  Are you sure, you want to delete resume
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setDeleteResume(false);
                  }}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isDelete}
                  onClick={async () => {
                    setIsDelete(true);
                    await axiosData.delete(
                      `/seekers/resume/${Context.profile.mobile_number}`
                    );

                    Context.setput(true);
                    setIsDelete(false);
                    setDeleteResume(false);
                  }}
                >
                  Yes{" "}
                  {isDelete && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#ffffff",
                        marginLeft: "4px",
                        marginBottom: "-2px",
                      }}
                    />
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Grid xs={12} sm={12} md={8} square>
              <Box>
                <Box sx={{ mt: 1 }}>
                  <Grid xs={12} md={12} lg={12}>
                    <Box
                      id="Resume"
                      sx={{ fontSize: "18px", textTransform: "uppercase" }}
                    >
                      <b style={{color:"rgb(16 143 178)"}}>Resume</b>
                      <hr/>
                      <Typography
                        sx={{ fontSize: 14, mt: 1, fontWeight: 600 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Resume is the most important document recruiters look
                        for. Recruiters generally do not look at profiles
                        without resumes.
                      </Typography>
                    </Box>
                    <Box sx={{ mb: 1 }} aria-labelledby="Resume">
                      <Grid container>
                        <Grid lg={1} md={1} sm={2.1} xs={3.9}>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: "14px" }}
                          >
                            <b>Resume-</b>
                          </Typography>
                        </Grid>
                        {resume === "" ? (
                          <Typography className="ResumeName">
                            File not uploaded
                          </Typography>
                        ) : (
                          <Grid lg={9} md={9} sm={9} xs={8.1}>
                            <Typography
                              className="ResumeName"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                ml: 0.4,
                                wordWrap: "break-word",
                              }}
                              color="text.secondary"
                            >
                              {resume.name}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {resume === "" ? (
                        ""
                      ) : (
                        <div>
                          <Tooltip title="Download Resume" arrow>
                            <IconButton
                                onClick={() => {
                                  saveAs(
                                    `${baseUrl}/seekers/resume/${Context.profile.mobile_number}`,
                                    Context.profile.fileUpload.name
                                  );
                                }}>
                              <FileDownloadOutlinedIcon
                                size="medium"
                                disabled={!resume.fileUpload}
                                sx={{
                                  color: "green",
                                  border: "1px solid green",
                                  borderRadius: "50%",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          |
                          <Tooltip title="Delete Resume" arrow>
                            <IconButton
                                onClick={() => {
                                  setDeleteResume(true);
                                }}>
                              <DeleteIcon
                                sx={{
                                  color: "rgb(243 81 81)",
                                  border: "1px solid rgb(243 81 81)",
                                  borderRadius: "50%",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </Box>
                    <Box>
                      <Resume resume1={resume} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default ResumeCard;
