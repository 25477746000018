import React, { useEffect, useState } from "react";
import successfulPurchase from "../../../img/successfulPpurchase.png";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import PurchaseFail from "../../../img/paymentFailed.png";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import useAxios from "../../../api/useAxios";
function DataBaseThanksCard() {
  const navigate = useNavigate();
  const axiosData = useAxios();
  const [data, setdata] = useState();
  const [paydetials, setpaydetials] = useState();
  const [loading, setLoading] = useState(true);
  const [dynamicmessage, setdyn] = useState("");
  let params = useParams();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const getpaymentdetials = async () => {
    const _id = params.id;

    try {
      const response = await axiosData.post(
        ROUTINGDATA.PAYMENT + `/status/${_id}`
      );
      setdata(response.data);
      const paymentdetials = await axiosData.post(
        ROUTINGDATA.PAYMENT + `/get`,
        {
          listingQuery: { merchantTransactionId: _id },
        }
      );
      setpaydetials(paymentdetials.data);
      if (paymentdetials.data.paymentcategory === "Ad") {
        setdyn("Your AD has been posted ");
      } else {
        setdyn("You can now post your Job");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getpaymentdetials();
  }, []);
  const navi = () => {
    if (data.success && paydetials.status === "COMPLETED") {
      navigate(
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + paydetials.data.successnavigate,
        {
          state: userDetails.userId,
        }
      );
    } else {
      navigate(
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + paydetials.data.failednavigation
      );
    }
  };
  const homescreen = () => {
    navigate(ROUTINGDATA.LAND);
  };
  if (loading === false) {
    return (
      <div>
        <p
          style={{
            fontFamily: "Poppins-Medium",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          {data.success && paydetials.status === "COMPLETED"
            ? " Your Payment Was Successful!"
            : "Sorry Your Payment Was Failed!"}
        </p>
        <p
          style={{
            fontFamily: "Poppins-Medium",
            color: "#444",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {data.success && paydetials.status === "COMPLETED"
            ? " Now you can access the seekers resumes"
            : " Please Try again!"}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img
          className="successfulPurchaseImage"
          src={successfulPurchase}
          alt="successfulPurchase"
        /> */}
          <img
            className={
              data.success && paydetials.status === "COMPLETED"
                ? "successfulPurchaseImage"
                : "PurchaseFailImage"
            }
            src={
              data.success && paydetials.status === "COMPLETED"
                ? successfulPurchase
                : PurchaseFail
            }
            alt={
              data.success && paydetials.status === "COMPLETED"
                ? "successfulPurchase"
                : "PurchaseFailImage"
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Button
            sx={{
              textTransform: "capitalize",
              boxShadow: 0,
              fontFamily: "Poppins-Medium",
              padding: "5px 20px",
              backgroundColor: "#EC2172",
              marginLeft: "15px",
              color: "#fff",
              ":hover": {
                backgroundColor: "#d30a5a",
              },
            }}
            onClick={() => {
              navi();
            }}
          >
            {data.success && paydetials.status === "COMPLETED"
              ? "Go to profile"
              : "Try again"}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default DataBaseThanksCard;
