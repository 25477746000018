import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DashboardRounded as DashboardRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
  WorkRounded as WorkRoundedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  DataUsageSharp as DataUsageSharpIcon,
  ContactSupportSharp as ContactSupportSharpIcon,
  PostAdd as PostAddIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";

import { useMediaQuery } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import useAxios from "../../../api/useAxios";
import LogOutDialog from "../../admin-panel/admin-components/DialogBoxes/LogOutDialog";
import logo from "../../../img/logo_for-landingpage-3.png";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      [theme.breakpoints.down("sm")]: { marginLeft: 0 },
      marginLeft: `${drawerWidth + 50}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [[theme.breakpoints.up("md")] || [theme.breakpoints.down("md")]]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth + 50}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("sm")]: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MLAconsitiuencyPanel() {
  const theme = useTheme();
  const act = sessionStorage.getItem("active");
  const actneed = sessionStorage.getItem("activeneed");
  let actneedall = sessionStorage.getItem("mpmlaneedall_active");
  let actneedshort = sessionStorage.getItem("mpmlaneedshort_active");
  let actneedsupport = sessionStorage.getItem("mpmlaneedsupport_active");

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [openLog, setOpenLog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const axiosData = useAxios();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));

  const [allMla, setAllMla] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const mla = JSON.parse(localStorage.getItem("mla"));
  const getMpEmailId = async () => {
    try {
      const MLAData = await axiosData.post("mpmla/mlaconstituencyseeker", {
        email_id: mla,
      });
      const { data } = MLAData;
      setAllMla(data[0]);
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleClick = (event) => {
    sessionStorage.setItem("active", event);
    sessionStorage.removeItem("mpmlaneedshort_active");
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("activeneed");
    sessionStorage.removeItem("typeofsupport");
    sessionStorage.removeItem("shortradio");
    if (event === "dash") {
      sessionStorage.removeItem(`mla_supportradio`);
    }
  };
  const handleClickLog = (event) => {
    sessionStorage.setItem("activeneed", event);
    sessionStorage.removeItem("active");
  };

  const handleAllClick = (event) => {
    sessionStorage.setItem("mpmlaneedall_active", event);
    sessionStorage.removeItem("mpmlaneedshort_active");
    sessionStorage.removeItem("active");
    sessionStorage.removeItem("mpmlaneedsupport_active");
  };
  const handleShortClick = (event) => {
    sessionStorage.setItem("mpmlaneedshort_active", event);
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
    sessionStorage.removeItem("mpmlaneedsupport_active");
  };
  const handleSupportedClick = (event) => {
    sessionStorage.setItem("mpmlaneedsupport_active", event);
    sessionStorage.removeItem("mpmlaneedshort_active");
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
  };

  useEffect(() => {
    if (mla === null || mla === undefined) {
      navigate(ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.PageNotFound);
    }
  }, [location.pathname]);
  useEffect(() => {
    getMpEmailId();
  }, []);
  if (isLoading === false) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ backgroundColor: " #055779" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ textTransform: "capitalize" }}
            >
              {allMla.mla_constituency} Constituency
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth + 50,
              boxSizing: "border-box",
              backgroundColor: "#339fcd",
            },
            [theme.breakpoints.down("sm")]: {
              "& .MuiDrawer-paper": {
                backgroundColor: "#339fcd",
                width: drawerWidth,
                flexShrink: 0,
              },
            },
          }}
          variant={
            isMobile
              ? "temporary"
              : isDevice || isDeviceDown
              ? "persistent"
              : null
          }
          anchor="left"
          open={open}
        >
          <DrawerHeader className="header">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img className="image" src={logo} alt="" />
              </div>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </Box>
          </DrawerHeader>
          <List>
            <ListItemText>
              <span className="sidehead">MASTER</span>
            </ListItemText>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "dash" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MPDASHBOARD, { state: allMla });
                  handleClick("dash");
                  handleDrawerClose();
                  setCollapseOpen(false);
                }}
              >
                <ListItemIcon>
                  <DashboardRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Dashboard</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "seeker" ? "active" : "link-active"}
                onClick={(e) => {
                  navigate(ROUTINGDATA.MLASeeker, { state: allMla });
                  handleClick("seeker");
                  handleDrawerClose();
                  setCollapseOpen(false);
                }}
              >
                <ListItemIcon>
                  <WorkRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">All Job Seekers</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={actneed === "need" ? "active" : "link-active"}
                onClick={(e) => {
                  handleClickLog("need");
                  setCollapseOpen((t) => !t);
                }}
              >
                <ListItemIcon>
                  <ContactSupportSharpIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Need Support </span>
                </ListItemText>
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  {collapseOpen ? (
                    <ExpandLessIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "white" }} />
                  )}
                </span>
              </ListItemButton>
            </ListItem>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ borderRadius: "10px", pl: 4 }}
                  className={
                    actneedall === `mpMLAall` ? "active" : "link-active"
                  }
                  onClick={() => {
                    navigate(ROUTINGDATA.MLAneedSupport, { state: allMla });
                    setCollapseOpen(false);
                    handleAllClick(`mpMLAall`);
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon>
                    <DataUsageSharpIcon className="text" />
                  </ListItemIcon>
                  <ListItemText className="text" sx={{ color: "#fff" }}>
                    <span className="font">All Data</span>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  sx={{ borderRadius: "10px", pl: 4 }}
                  className={
                    actneedshort === `mpmlashort` ? "active" : "link-active"
                  }
                  onClick={() => {
                    handleShortClick("mpmlashort");
                    navigate(ROUTINGDATA.MLAShortlisted, { state: allMla });
                    setCollapseOpen(false);
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon>
                    <PostAddIcon className="text" />
                  </ListItemIcon>
                  <ListItemText className="text" sx={{ color: "#fff" }}>
                    <span className="font">Shortlisted Data</span>
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  sx={{ borderRadius: "10px", pl: 4 }}
                  className={
                    actneedsupport === `mpmlasupported`
                      ? "active"
                      : "link-active"
                  }
                  onClick={() => {
                    handleSupportedClick("mpmlasupported");
                    navigate(ROUTINGDATA.MLASupported, { state: allMla });
                    setCollapseOpen(false);
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon>
                    <FavoriteRoundedIcon className="text" />
                  </ListItemIcon>
                  <ListItemText className="text" sx={{ color: "#fff" }}>
                    <span className="font">Supported Data</span>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItem className="link-active" sx={{ padding: "1px 10px" }}>
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                onClick={() => setOpenLog(true)}
              >
                <ListItemIcon>
                  <LogoutRoundedIcon className="text" sx={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  className="text"
                  sx={{ color: "#fff", fontWeight: 800 }}
                >
                  <span className="font">Log Out</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open} className="main">
          <DrawerHeader />
          <Outlet />
        </Main>

        <LogOutDialog
          openLog={openLog}
          setOpenLog={setOpenLog}
          verifier={"mla"}
          act={"active"}
        />
      </Box>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
