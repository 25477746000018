import React, { useEffect, useState } from "react";

import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination,
  Card,
  Chip,
  FormControl,
  Paper,
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
const Moment = require("moment");
function VerifierDashboard() {
  const axiosData = useAxios();
  const navigate = useNavigate();

  const [verifierData, setVerifier] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const verifierName = JSON.parse(localStorage.getItem("verifier"));

  const [getApi, setGetApi] = useState([]);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const verifiervalue = sessionStorage.getItem("verifierradio");
  const verifierPageNumbe = sessionStorage.getItem("verifier_page_number");
  const verifierPageNumber = parseInt(verifierPageNumbe);
  const setVerifierPageNumber = verifierPageNumber ? verifierPageNumber : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getVerifierEmailId = async () => {
    const verifier = await axiosData.get(`family/email/${verifierName}`);
    const { data } = verifier;
    const ans = [...data];
    const sortbydate = ans.sort((a, b) => {
      return (
        new Moment(b.modifiedDate.split("-").reverse().join()).format(
          "YYYYMMDD"
        ) -
        new Moment(a.modifiedDate.split("-").reverse().join()).format(
          "YYYYMMDD"
        )
      );
    });

    setGetApi(sortbydate);
    getFilterValue(verifiervalue ? verifiervalue : "all", sortbydate);
  };
  const getFilterValue = (value, getapi) => {
    if (value === "all") {
      setVerifier([...getapi]);
      setTotalRows([...getapi].length);
      setPage(setVerifierPageNumber);
    } else if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Declined"
    ) {
      const fill = getapi.filter((d) => d.fieldVerification === value);
      setVerifier(fill);
      setTotalRows(fill.length);
      setPage(setVerifierPageNumber);
    } else {
      const ver = getapi.filter((i) => i.VerifierEmail === value);

      setVerifier(ver);
      setTotalRows(ver.length);
      setPage(setVerifierPageNumber);
    }
  };
  const closeFunctionCall = () => {
    setVerifier(getApi);
    setTotalRows(getApi.length);
    setPage(0);
  };
  const searchdatas = (searchdata) => {
    const datas = getApi.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      var valuess =
        item.Name.match(searchtext) || item.email_id.match(searchtext);

      if (valuess != null) {
        return item;
      }
    });
    setVerifier(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    getVerifierEmailId();
    setPage(setVerifierPageNumber);
    sessionStorage.removeItem("verifier_page_number");
  }, []);
  return (
    <>
      <Card sx={{ boxShadow: 0 }}>
        <Paper>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Grid container>
              <Grid item md={8}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Field Verification Type
                  </FormLabel>
                  <RadioGroup
                    row
                    id="radio-btn"
                    value={verifiervalue ? verifiervalue : "all"}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginRight: "18px" }}
                    onChange={(e) => {
                      setsearchicon(false);
                      setsearchdata("");
                      sessionStorage.setItem("verifierradio", e.target.value);
                      getFilterValue(e.target.value, getApi);
                      setPage(0);
                    }}
                  >
                    <FormControlLabel
                      name="usertype"
                      id="all"
                      control={<Radio value={"all"} />}
                      label="All"
                    />
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value={"Pending"} />}
                      label="Pending"
                    ></FormControlLabel>
                    <FormControlLabel
                      name="usertype"
                      control={<Radio value={"Completed"} />}
                      label="Completed"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  value={searchdata}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: `${searchicon ? "visible" : "hidden"}`,
                        }}
                        onClick={() => {
                          closeFunctionCall();
                          setsearchicon(false);
                          setsearchdata("");
                          getFilterValue(
                            verifiervalue ? verifiervalue : "all",
                            getApi
                          );
                        }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    sessionStorage.setItem("verifierradio", "all");
                    getFilterValue("all", getApi);
                    setsearchdata(e.currentTarget.value);
                    if (e.currentTarget.value.length >= 3) {
                      searchdatas(e.currentTarget.value);
                    }
                    setsearchicon(true);
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className="table-row">
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Family Income</TableCell>
                <TableCell> Field Verification</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {verifierData.length > 0 ? (
                <>
                  {" "}
                  {verifierData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow className="tablecell">
                        <TableCell>{item.Name} </TableCell>
                        <TableCell>{item.email_id} </TableCell>
                        <TableCell>{item.FamilyIncome} </TableCell>
                        <TableCell>
                          {" "}
                          <Chip
                            label={item.fieldVerification}
                            variant="filled"
                            color={
                              item.fieldVerification === "Pending" ||
                              item.fieldVerification === "pending"
                                ? "warning"
                                : item.fieldVerification === "completed" ||
                                  item.fieldVerification === "Completed"
                                ? "success"
                                : "error"
                            }
                            sx={{
                              color: "#ffffff",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Full Details" placement="top">
                            <IconButton
                              className="icon-btn"
                              size="Medium"
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.verifier +
                                    "/" +
                                    ROUTINGDATA.verifierFullDetails,
                                  { state: item.email_id }
                                );
                                sessionStorage.setItem(
                                  "verifier_page_number",
                                  page
                                );
                              }}
                            >
                              <OpenInNewRoundedIcon />
                            </IconButton>
                          </Tooltip>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <>
                  {" "}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Barlow-Bold" }}
                      >
                        NO Data Available
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

export default VerifierDashboard;
