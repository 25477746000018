import React, { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import "./Subscribe.css";
import {
  Card,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/system/Unstable_Grid/Grid";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import useAxios from "../../../api/useAxios";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function Subscribe() {
  const navigate = useNavigate();
  const [userData, setuserData] = useState();
  const [loading, setLoading] = useState(false);
  const [urls, seturls] = useState();
  const [standPackage, setStandPackage] = useState("");
  const [prePackage, setPrePackage] = useState("");
  const [standTotal, setStandTotal] = useState(0);
  const [preTotal, setPreTotal] = useState(0);
  const [disStandAmount, setDisStandAmount] = useState();
  const [disHotAmount, setDisHotAmount] = useState();
  const [empsettings, setempsettings] = useState();
  const [needJobPostPopop, setNeedJobPostPopop] = useState(false);
  const [subscribeInfoPopup, setSubscribeInfoPopup] = useState("");

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const axiosData = useAxios();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const get = async () => {
    setLoading(false);
    const setting = await axiosData.get(`employers/settings`);
    const paysettings = await axiosData.get(`General/phonepepaymentdetials`);
    seturls(paysettings.data.collections);
    setempsettings(setting.data);
    const response = await axiosData.get(
      `employers/email/${userDetails.emailId}/users`
    );
    setuserData(response.data);
    setPrePackage(1);
    setStandPackage(1);
    setLoading(true);
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      get();
    }
  }, []);
  useEffect(() => {
    if (empsettings) {
      if (standPackage >= empsettings.discount_valid) {
        setStandTotal(standPackage * empsettings.standard_price);
        setDisStandAmount(
          standPackage * empsettings.standard_price -
            standPackage *
              empsettings.standard_price *
              (empsettings.discount / 100)
        );
      } else {
        setStandTotal(standPackage * empsettings.standard_price);
      }
      if (prePackage >= empsettings.discount_valid) {
        setPreTotal(prePackage * empsettings.premimum_price);
        setDisHotAmount(
          prePackage * empsettings.premimum_price -
            prePackage *
              empsettings.premimum_price *
              (empsettings.discount / 100)
        );
      } else {
        setPreTotal(prePackage * empsettings.premimum_price);
      }
    }
  }, [standPackage, prePackage]);
  const subscribe = async (subscribeType, type, no_posting) => {
    try {
      const response = await axiosData.post(`${ROUTINGDATA.PAYMENT}`, {
        merchantTransactionId: "MUID" + Date.now(),
        merchantUserId: "T" + Date.now(),
        amount:
          subscribeType === "Premimum"
            ? prePackage >= empsettings.discount_valid
              ? Math.round(disHotAmount)
              : preTotal
            : standPackage >= empsettings.discount_valid
            ? Math.round(disStandAmount)
            : standTotal,
        data: {
          companyname: userData.company_name,
          mobile: userData.mobile_number,
          email: userData.email_id,
          pincode: userData.pincode,
          area: userData.area ? userData.area : "",
          subscribeType: subscribeType,
          type: type,
          no_posting: no_posting,
          updateQuery: {
            [type]: no_posting + userData[type],
          },
          listingQuery: { email_id: userData.email_id },
          collection: "employers",
          emailtemplate: "paymentmessage",
          successnavigate: "post-job",
          failednavigation: "Subcriptions",
          link: "https://www.velaivendum.com/#/main/Subcriptions",
          admintemplate: "admintemplate",
        },

        emailvarialbles: [
          {
            key: "name",
            value: userData.company_name,
          },
        ],
        send: empsettings.send,
        usertype: "employer",
        paymentcategory: "Jobpost",
        callbackUrl: urls.callbackurl
          ? urls.callbackurl
          : `https://velaivendum.com/p-services/payment/phonepe/callback`,
        redriectUrl: urls.redriecturl
          ? urls.redriecturl
          : `https://www.velaivendum.com/#/main/payment`,
      });
      if (empsettings.send) {
        window.open(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const standHandleChange = (event) => {
    setStandPackage(event.target.value);
  };
  const preHandleChange = (event) => {
    setPrePackage(event.target.value);
  };
  const freebtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#196b19",
      marginTop: 2,
      width: "50%",
    },
  };
  const Standardbtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#008ac0",
      marginTop: 2,
      width: "50%",
    },
  };
  const Premiumbtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#e40048",
      marginTop: 2,
      width: "50%",
    },
  };
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
      },
    },
  };
  if (loading) {
    return (
      <>
        <div style={{ width: "100%", textAlign: "end", height: "95%" }}>
          <div className="sub-Container">
            <div className="sub-main-grid">
              {/* <div class="flex-row-item" style={{}}>
            <Card className="sub-cards">
              <Typography
                sx={{
                  fontSize: 23,
                  color: "#fff",
                  p: 1.5,
                  backgroundColor: "#196b19",
                  fontWeight: 600,
                }}
              >
                Free
                <Typography>Trial Job Posting</Typography>
              </Typography>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CurrencyRupeeIcon />
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  0
                </Typography>
              </div>
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                PER MONTH
              </Typography>
              <div className="sub-free">
                <ClearRoundedIcon sx={{ color: "red" }} />
                <span sx={{ textAlign: "center" }}>Landing page search</span>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography sx={{ margin: 2, fontSize: 16 }}>
                  Job Post Quantity
                </Typography>
                <FormControl sx={{ m: 1, width: "80px" }} size="small">
                  <Select
                    value={freePackage}
                    // onChange={freeHandleChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">3</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginX: 50,
                }}
              >
                <Typography>Total</Typography>
                <Typography sx={{ paddingLeft: 2 }}>0</Typography>
              </div>
              <p style={{ color: "#e00048" }}>
                Three post jobs free for your trial
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  disabled={userData?.subscribeType === "Free" ? true : false}
                  sx={freebtn}
                  // disabled={userData.subscribeType !== "" ? true : false}
                  onClick={async () => {
                    await axiosData.put(
                      `employers/email/${userDetails.emailId}`,
                      { subscribeType: "Free" },
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    navigate(
                      ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTJOB,
                      {
                        state: userDetails.userId,
                      }
                    );
                  }}
                >
                  Free
                </Button>
              </div>
            </Card>
          </div> */}
              <div class="flex-row-item">
                <Card className="subCards">
                  <Typography
                    sx={{
                      fontSize: 23,
                      textAlign: "center",
                      color: "#fff",
                      p: 1.5,
                      backgroundColor: "#e40048",
                      fontWeight: 600,
                    }}
                  >
                    Hot-Vacancy
                    <Typography>Urgent Job Posting</Typography>
                  </Typography>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CurrencyRupeeIcon />
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                      {empsettings.premimum_price}
                    </Typography>
                  </div>
                  <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                    EACH POST
                  </Typography>

                  <div className="sub-free">
                    <CheckRoundedIcon sx={{ color: "green" }} />
                    <b style={{ fontSize: "14px" }}>Whatsapp Notification</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Hot_whatsappMSG" ? true : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            You will get Notification when
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "5px 20px 10px 20px",
                            }}
                          >
                            Seeker Apply the job
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Hot_whatsappMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div className="sub-free">
                    <CheckRoundedIcon sx={{ color: "green" }} />
                    <b style={{ fontSize: "14px" }}> Email Notification</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Hot_emailMSG" ? true : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            You will get Notification when
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 23px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            Post a job and Seeker Apply the job
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Hot_emailMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div className="sub-free">
                    <CheckRoundedIcon sx={{ color: "green" }} />
                    <b style={{ fontSize: "14px" }}>Post will be highlight</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Hot_LandingMSG" ? true : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            This job post will be highlight in
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            homepage and treat as urgent job{" "}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Hot_LandingMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>

                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ margin: 2, fontSize: 16 }}>
                      Job Post Quantity
                    </Typography>
                    <FormControl sx={{ m: 1, width: "80px" }} size="small">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={prePackage}
                        onChange={preHandleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginX: 50,
                    }}
                  >
                    <Typography>Total</Typography>
                    {prePackage >= empsettings.discount_valid && (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <b> {Math.round(disHotAmount)}</b>
                      </Typography>
                    )}
                    {prePackage >= empsettings.discount_valid ? (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <del>{preTotal}</del>
                      </Typography>
                    ) : (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <b>{preTotal}</b>
                      </Typography>
                    )}
                  </div>
                  {prePackage < empsettings.discount_valid ? (
                    <p style={{ color: "#e00048", margin: "10px 0px 0px 0px" }}>
                      Flat {empsettings.discount}% OFF | Buy{" "}
                      {empsettings.discount_valid} Job Postings or more
                    </p>
                  ) : (
                    <p style={{ color: "green", margin: "10px 0px 0px 0px" }}>
                      {empsettings.discount}% Discount Applied
                    </p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={Premiumbtn}
                      disabled={empsettings.button}
                      onClick={async () => {
                        subscribe("Premimum", "no_of_hot_posting", prePackage);
                      }}
                    >
                      BUY NOW
                    </Button>
                  </div>
                </Card>
              </div>
              <div class="flex-row-item">
                <Card className="subCards">
                  <Typography
                    sx={{
                      fontSize: 23,
                      textAlign: "center",
                      color: "#fff",
                      p: 1.5,
                      backgroundColor: "#008ac0",
                      fontWeight: 600,
                    }}
                  >
                    Standard
                    <Typography>Normal Job Posting</Typography>
                  </Typography>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CurrencyRupeeIcon />
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                      {empsettings.standard_price}
                    </Typography>
                  </div>

                  <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                    EACH POST
                  </Typography>

                  <div className="sub-free">
                    <CheckRoundedIcon sx={{ color: "green" }} />
                    <b style={{ fontSize: "14px" }}>Whatsapp Notification</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Normal_whatsappMSG"
                          ? true
                          : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            You will get Notification when
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "5px 20px 10px 20px",
                            }}
                          >
                            Seeker Apply the job
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Normal_whatsappMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div className="sub-free">
                    <CheckRoundedIcon sx={{ color: "green" }} />
                    <b style={{ fontSize: "14px" }}> Email Notification</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Normal_emailMSG" ? true : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            You will get Notification when
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 23px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            Post a job and Seeker Apply the job
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Normal_emailMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div className="sub-free">
                    <ClearRoundedIcon sx={{ color: "red" }} />
                    <b style={{ fontSize: "14px" }}>Post will be highlight</b>
                    <LightTooltip
                      placement="top"
                      open={
                        subscribeInfoPopup === "Normal_LandingMSG"
                          ? true
                          : false
                      }
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            This job post will be highlight in
                            <CancelOutlinedIcon
                              sx={{
                                fontSize: "16px",
                                margin: "0px 0px 10px 23px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSubscribeInfoPopup("");
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "3px 0px 5px 10px",
                            }}
                          >
                            homepage and treat as urgent job{" "}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ margin: "-3px 0px 0px 0px" }}
                        onClick={() => {
                          setSubscribeInfoPopup("Normal_LandingMSG");
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>

                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ margin: 2, fontSize: 16 }}>
                      Job Post Quantity
                    </Typography>
                    <FormControl sx={{ m: 1, width: "80px" }} size="small">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={standPackage}
                        onChange={standHandleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginX: 50,
                    }}
                  >
                    <Typography>Total</Typography>
                    {standPackage >= empsettings.discount_valid && (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <b>{Math.round(disStandAmount)}</b>
                      </Typography>
                    )}
                    {standPackage >= empsettings.discount_valid ? (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <del>{standTotal}</del>
                      </Typography>
                    ) : (
                      <Typography sx={{ paddingLeft: 2 }}>
                        <b>{standTotal}</b>
                      </Typography>
                    )}
                  </div>
                  {standPackage < empsettings.discount_valid ? (
                    <p style={{ color: "#e00048", margin: "10px 0px 0px 0px" }}>
                      Flat {empsettings.discount}% OFF | Buy{" "}
                      {empsettings.discount_valid} Job Postings or more
                    </p>
                  ) : (
                    <p style={{ color: "#e00048", margin: "10px 0px 0px 0px" }}>
                      {empsettings.discount}% Discount Applied
                    </p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      disabled={empsettings.button}
                      variant="contained"
                      sx={Standardbtn}
                      onClick={async () => {
                        subscribe(
                          "Standard",
                          "no_of_Standard_posting",
                          standPackage
                        );
                      }}
                    >
                      BUY NOW
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
            <Dialog
              open={needJobPostPopop}
              sx={{ "& .MuiPaper-root": { width: "450px" } }}
            >
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                Need Job Post
                <Typography>(Please Contact Us)</Typography>
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText>
                  <Typography sx={{ color: "black" }}>
                    <span style={{ paddingRight: "10px" }}>Email :</span>
                    {process.env.REACT_APP_EMAILID_FOR_ADMIN}
                  </Typography>
                  <Typography sx={{ color: "black", marginTop: 2 }}>
                    <span style={{ paddingRight: "10px" }}>Contact :</span>
                    8925016020
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setNeedJobPostPopop(false)}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      ></div>
    );
  }
}

export default Subscribe;
