import React, { useEffect, useRef, useState } from "react";
import "../EmployerUpdateProfile/EmployerUpdate.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, IconButton } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileUpdate from "../EmployerUpdateProfile/ProfileUpdate";
import CompanyDetails from "./CompanyDetails";
import CompanyProof from "./CompanyProof";
import ContactPersonDetails from "./ContactPersonDetails";
import Context from "./EmpContext";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WorkIcon from "@mui/icons-material/Work";
import SourceIcon from "@mui/icons-material/Source";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CurrencyRupeeSharpIcon from "@mui/icons-material/CurrencyRupeeSharp";
import moment from "moment/moment";
import {
  Drawer,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Tooltip,
  Paper,
  FormLabel,
  Autocomplete,
  TextField,
  FormControl,
  Popper,
  InputLabel,
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import FactoryIcon from "@mui/icons-material/Factory";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Divider from "@mui/material/Divider";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import useAxios from "../../../api/useAxios";
import Slide from "@mui/material/Slide";
import ListItemText from "@mui/material/ListItemText";
import { tooltipClasses } from "@mui/material/Tooltip";
const theme = createTheme();
const drawerWidth = 220;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EmployerUpdate = () => {
  const navigate = useNavigate();
  const axiosData = useAxios();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  if (userDetails === null || userDetails === undefined) {
    navigate(
      `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
    );
  }
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const Location = useLocation();
  const [declined, setDeclined] = useState();
  const [buyJobDetailTooltip, setBuyJobDetailTooltip] = useState(false);
  let [empData, setempData] = useState({});
  let [empDatabaseAccess, setempDatabaseAccess] = useState({});
  let [empDatabaseAccessArray, setempDatabaseAccessArray] = useState([]);
  let [errorJobTittle, seterrorJobTittle] = useState(false);
  let [empDatabaseJobRole, setempDatabaseJobRole] = useState("");
  let [empDatabaseJobRoleArray, setempDatabaseJobRoleArray] = useState([]);
  let [empDatabaseAccessArrayid, setempDatabaseAccessArrayid] = useState("");
  const [selectjobrolePopup, setSelectJobRolePopup] = useState(false);
  const [postJob, setPostJob] = useState(false);
  const [put, setput] = useState(false);
  const [state, setState] = useState(false);
  const [openShort, setOpenShort] = useState(false);
  const [quickactive, setQuickActive] = useState("quickactive");
  const [loading, setLoading] = useState(true);
  const [subscribeValidity, setSubscribValidity] = useState();
  const [trialExpiry, setTrialExpiry] = useState("");
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const jobs = async () => {
    try {
      const response = await axiosData.get(
        `employers/email/${userDetails.emailId}/users`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSubscribValidity(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    jobs();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const [open2, setOpen2] = React.useState(true);
  const [trialDays, setTrialDays] = useState(0);
  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-around",
  }));
  const getPostJobSettings = async () => {
    try {
      const postJobData = await axiosData.get("employers/Getsettings/postjob");
      const  data  = postJobData.data;
      setTrialDays(data.no_of_days_trial_view)
    } catch (err) {
      console.log("er", err);
    }
  };
  const getAppliedSeekers = async (id) => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyjobid/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data === "") {
        setPostJob(true);
        setempDatabaseJobRoleArray([]) 
      } else {
        const datasset = response.data;
        const datafill =datasset.map((i)=>{return i.job_title})
        const repeatedNames = new Set();
        const uniqueNames = datafill.filter(name => {
          if (repeatedNames.has(name)) {
            return false;
          } else {
            repeatedNames.add(name); 
            return true;
          }
        });
        setempDatabaseJobRoleArray([...uniqueNames])
        setSelectJobRolePopup(true)
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getPostJobSettings();
      axiosData
        .get(`employers/email/${Location.state}/users`)
        .then(async (data) => {
          empData = data.data;
          if (empData.email_id === userDetails.emailId) {
            setempData({ ...empData });
            const startDate = moment().format("DD-MM-YYYY");
              const expiryDate = empData?.createdDate;
              const start_date = moment(startDate, "DD-MM-YYYY");
              const expiry_date = moment(expiryDate, "DD-MM-YYYY");
              const daysDifference = start_date.diff(expiry_date, "days");
              if (daysDifference > trialDays) {
                setTrialExpiry("");
              } else {
                setTrialExpiry(daysDifference);
              }
            try {
              const email_id = data.data.email_id;
              const getOneAxios = await axiosData.get(
                `databaseaccess/${email_id}/user`
              );
              const datas = getOneAxios.data;
              const statusOpenData22 = datas.filter(
                (d) => d.data_access_status === "Open"
              );
              const statusOpenData = statusOpenData22.reverse()
              setempDatabaseAccessArray(statusOpenData);
              setempDatabaseAccess({ ...statusOpenData[0] });
            } catch (err) {
              console.log("Error", err);
            }
            const userId = empData.user_id;
            const emailId = empData.email_id;
            const company_name = empData.company_name;
            const mobile_number = empData.mobile_number;
            const logoPic = empData.logoUpload;
            localStorage.setItem(
              "user",
              JSON.stringify({
                userId,
                emailId,
                company_name,
                mobile_number,
                logoPic,
              })
            );
            setLoading(false);
          } else {
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setDeclined(true);
          }
        });
      setput(false);
    }
  }, [put]);

  const CompanyDetailsRoute = useRef(null);
  const GovermentProofRoute = useRef(null);
  const ContactDetailsRoute = useRef(null);

  if (loading === false) {
    return (
      <>
        <Context.Provider value={empData}>
          <div>
            <ThemeProvider theme={theme}>
              <Box className="full-content" sx={{ p: 2 }}>
                <Grid
                  sx={{
                    mb: 1,
                    pt: 1,
                    pl: 2,
                    mt: -1,
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                  id="mobileView"
                  container
                  xs={12}
                  sm={12}
                >
                  <div id="mobileView">
                    <div
                      style={{
                        display: "flex",
                        width: "90%",
                      }}
                    >
                      <MenuIcon onClick={toggleDrawer(true)} />

                      <Typography sx={{ fontSize: "18px", pb: 1, ml: 1 }}>
                        <b>Quick Links</b>
                      </Typography>
                    </div>

                    <Drawer
                      className="QDrawer"
                      sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                          width: drawerWidth,
                          boxSizing: "border-box",
                          backgroundColor: "#339fcd",
                        },
                      }}
                      variant="persistent"
                      anchor="left"
                      open={state}
                      onClose={toggleDrawer(false)}
                    >
                      <DrawerHeader sx={{ backgroundColor: "white" }}>
                        <Typography sx={{ fontSize: "18px" }}>
                          <b>Quick Links</b>
                        </Typography>{" "}
                        <CloseIcon
                          sx={{ ml: 0.3 }}
                          onClick={toggleDrawer(false)}
                        />
                      </DrawerHeader>
                      <Divider />
                      <Grid container flexDirection="column">
                        <ListItem
                          sx={{ padding: "1px 0px" }}
                          onClick={() =>
                            CompanyDetailsRoute.current.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                        >
                          <a className="hover">
                            <ListItemButton
                              sx={{ borderRadius: "10px" }}
                              className="link-active"
                              onClick={toggleDrawer(false)}
                            >
                              <ListItemIcon>
                                <ApartmentIcon className="text" />
                              </ListItemIcon>
                              <ListItemText
                                className="text"
                                sx={{ color: "#fff" }}
                              >
                                <span className="font">Company Details</span>
                              </ListItemText>
                            </ListItemButton>
                          </a>
                        </ListItem>
                        <ListItem
                          sx={{ padding: "1px 0px" }}
                          onClick={() => {
                            GovermentProofRoute.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          <a className="hover">
                            <ListItemButton
                              sx={{ borderRadius: "10px" }}
                              className="link-active"
                              onClick={toggleDrawer(false)}
                            >
                              <ListItemIcon>
                                <AssuredWorkloadIcon className="text" />
                              </ListItemIcon>
                              <ListItemText
                                className="text"
                                sx={{ color: "#fff" }}
                              >
                                <span className="font"> Goverment Proof</span>
                              </ListItemText>
                            </ListItemButton>
                          </a>
                        </ListItem>
                        <ListItem
                          sx={{ padding: "1px 0px" }}
                          onClick={() =>
                            ContactDetailsRoute.current.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                        >
                          <a className="hover">
                            <ListItemButton
                              sx={{ borderRadius: "10px" }}
                              className="link-active"
                              onClick={toggleDrawer(false)}
                            >
                              <ListItemIcon>
                                <PermContactCalendarIcon className="text" />
                              </ListItemIcon>
                              <ListItemText
                                className="text"
                                sx={{ color: "#fff" }}
                              >
                                <span className="font"> Contact Details</span>
                              </ListItemText>
                            </ListItemButton>
                          </a>
                        </ListItem>

                        <ListItemButton onClick={handleClick}>
                          <ListItemIcon>
                            <WorkIcon className="text" sx={{ color: "#fff" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Job Post"
                            className="text"
                            sx={{ color: "#fff" }}
                          />
                          {open ? (
                            <ExpandLess sx={{ color: "#fff" }} />
                          ) : (
                            <ExpandMore sx={{ color: "#fff" }} />
                          )}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.MAIN +
                                    ROUTINGDATA.POSTJOB,
                                  {
                                    state: userDetails.userId,
                                  }
                                );
                                toggleDrawer(false);
                              }}
                            >
                              <ListItemIcon>
                                <PostAddIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Post New Job"
                                sx={{ color: "#fff" }}
                              />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.MAIN +
                                    ROUTINGDATA.POSTEDJOB,
                                  {
                                    state: userDetails.userId,
                                  }
                                );
                                toggleDrawer(false);
                              }}
                            >
                              <ListItemIcon>
                                <ListAltIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Posted Jobs"
                                sx={{ color: "#fff" }}
                              />
                            </ListItemButton>
                          </List>
                        </Collapse>
                        {/* <ListItem
                            sx={{ padding: "1px 0px" }}
                            onClick={() => {
                              navigate(
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.APPLIEDSEEKERS,
                                {
                                  state: {
                                    userId: userDetails.userId,
                                    company_name: empData.company_name,
                                  },
                                }
                              );
                            }}
                          >
                            <a className="hover">
                              <ListItemButton
                                sx={{ borderRadius: "10px" }}
                                className="link-active"
                                onClick={toggleDrawer(false)}
                              >
                                <ListItemIcon>
                                  <HowToRegIcon className="text" />
                                </ListItemIcon>
                                <ListItemText
                                  className="text"
                                  sx={{ color: "#fff" }}
                                >
                                  <span className="font"> Applied Seekers</span>
                                </ListItemText>
                              </ListItemButton>
                            </a>
                          </ListItem> */}
                        <ListItem
                          sx={{ padding: "1px 0px" }}
                          onClick={() => {
                            navigate(
                              ROUTINGDATA.LAND +
                                ROUTINGDATA.MAIN +
                                ROUTINGDATA.SUBSCRIPTIONS,
                              {
                                state: {
                                  userId: userDetails.userId,
                                  company_name: empData.company_name,
                                },
                              }
                            );
                          }}
                        >
                          <a className="hover">
                            <ListItemButton
                              sx={{ borderRadius: "10px" }}
                              className="link-active"
                              onClick={toggleDrawer(false)}
                            >
                              <ListItemIcon>
                                <HowToRegIcon className="text" />
                              </ListItemIcon>
                              <ListItemText
                                className="text"
                                sx={{ color: "#fff" }}
                              >
                                <span className="font"> Buy Posting</span>
                              </ListItemText>
                            </ListItemButton>
                          </a>
                        </ListItem>
                        <ListItemButton onClick={handleClick2}>
                          <ListItemIcon>
                            <WorkIcon className="text" sx={{ color: "#fff" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Resume Access"
                            className="text"
                            sx={{ color: "#fff" }}
                          />
                          {open2 ? (
                            <ExpandLess sx={{ color: "#fff", marginLeft: "10px", }} />
                          ) : (
                            <ExpandMore sx={{ color: "#fff", marginLeft: "10px",}} />
                          )}
                        </ListItemButton>
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          {trialExpiry === ""?null:(<ListItemButton
                              // disabled={
                              //   trialExpiry === ""
                              //     ? true
                              //     : false
                              // }
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.MAIN +
                                    ROUTINGDATA.TRIALVIEWACCESS,
                                  {
                                    state: userDetails,
                                  }
                                );
                                toggleDrawer(false);
                              }}
                            >
                              <ListItemIcon>
                                <ListAltIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Job Seeker Search"
                                sx={{ color: "#fff" }}
                              />
                            </ListItemButton>)}
                            <ListItemButton
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.MAIN +
                                    ROUTINGDATA.BUYDATABASEACCESS,
                                  {
                                    state: userDetails,
                                  }
                                );
                                toggleDrawer(false);
                              }}
                            >
                              <ListItemIcon>
                                <SourceIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Buy Resume Access"
                                sx={{ color: "#fff" }}
                              />
                            </ListItemButton>
                            <ListItemButton
                              disabled={
                                empDatabaseAccess.data_access_status === "Open"
                                  ? false
                                  : true
                              }
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.MAIN +
                                    ROUTINGDATA.VIEWDATABASE,
                                  {
                                    state: userDetails,
                                  }
                                );
                                toggleDrawer(false);
                              }}
                            >
                              <ListItemIcon>
                                <ListAltIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="View Resumes"
                                sx={{ color: "#fff" }}
                              />
                            </ListItemButton>
                            <ListItemButton
                              disabled={
                                empDatabaseAccess.data_access_status === "Open"
                                  ? false
                                  : true
                              }
                              sx={{ pl: 4, borderRadius: "10px" }}
                              onClick={() => {
                                setOpenShort(!openShort);
                              }}
                            >
                              <ListItemIcon>
                                <ListAltIcon
                                  className="text"
                                  sx={{ color: "#fff" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Shortlisted Data"
                                sx={{ color: "#fff" }}
                              />
                              <KeyboardArrowDown
                                sx={{
                                  marginLeft: "2px",
                                  transform: openShort
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "0.2s",
                                }}
                              />
                            </ListItemButton>
                            {openShort &&
                              empDatabaseAccessArray.map((item) => (
                                <List
                                  sx={{
                                    pl: 4,
                                  }}
                                  component="div"
                                  disablePadding
                                >
                                  <ListItemButton
                                    key={item._id}
                                    sx={{
                                      borderRadius: "10px",
                                      fontSize:"12px"
                                    }}
                                    id="quickactive1"
                                    onClick={() => {
                                      setempDatabaseAccessArrayid(item._id)
                                      toggleDrawer(false);
                                      getAppliedSeekers(item._id)
                                      // navigate(
                                      //   ROUTINGDATA.LAND +
                                      //     ROUTINGDATA.MAIN +
                                      //     ROUTINGDATA.SEEKERSAPPLIED,
                                      //   {
                                      //     state: {
                                      //       id: item._id,
                                      //       source_from: `Database-${item._id}`,
                                      //     },
                                      //   }
                                      // );
                                    }}
                                  >
                                    {" "}
                                    <ListItemIcon>
                                      <NavigateNextIcon
                                        sx={{ color: "#fff", minWidth: "2px" }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={`${item.data_accessType}(ATD:${item.data_access_taken_Date})`}
                                      sx={{ color: "#fff" }}
                                    />
                                  </ListItemButton>
                                </List>
                              ))}
                          </List>
                        </Collapse>
                      </Grid>
                    </Drawer>
                  </div>
                </Grid>
                <Grid className="Card" sx={{ p: 4 }} xs={12} md={12} lg={12}>
                  <Box className="Avator">
                    <ProfileUpdate setput={setput} number={empData._id}  setBuyJobDetailTooltip={setBuyJobDetailTooltip}/>
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          wordWrap: "break-word",
                          fontWeight: 600,
                        }}
                      >
                        <ApartmentIcon
                          sx={{ mb: -0.8, mr: 1, color: "#135c82" }}
                        />
                        {empData.company_name}
                        <LightTooltip
                          open={buyJobDetailTooltip}
                          title={
                            <>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  margin: 1,
                                }}
                              >
                                Use Your Job Posts
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                Only {subscribeValidity.no_of_hot_posting} Post
                                Available in Hot Vacancies
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                Only {subscribeValidity.no_of_Standard_posting}{" "}
                                Post Available in Standard Vacancies
                              </Typography>

                              <div style={{ textAlign: "end", margin: "10px" }}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  sx={{ marginRight: 1 }}
                                  onClick={() => {
                                    setBuyJobDetailTooltip(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="success"
                                  onClick={() => {
                                    navigate(
                                      ROUTINGDATA.LAND +
                                        ROUTINGDATA.MAIN +
                                        ROUTINGDATA.SUBSCRIPTIONS,
                                      {
                                        state: userDetails.userId,
                                      }
                                    );
                                  }}
                                >
                                  Buy NoW
                                </Button>
                              </div>
                            </>
                          }
                        >
                          <IconButton
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              width: "30px",
                              height: "30px",
                              marginLeft: "3px",
                            }}
                            classes={{
                              root: "custom-root-class",
                              label: "custom-label-class",
                            }}
                            onClick={() => {
                              setBuyJobDetailTooltip(true);
                            }}
                          >
                            <CurrencyRupeeSharpIcon />
                          </IconButton>
                        </LightTooltip>
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          wordWrap: "break-word",
                          fontWeight: 600,
                        }}
                      >
                        <FactoryIcon
                          sx={{ mb: -0.8, mr: 1, color: "#135c82" }}
                        />
                        {empData.industry}
                      </Typography>

                      <Typography
                        gutterBottom
                        sx={{
                          wordWrap: "break-word",
                          fontWeight: 600,
                        }}
                      >
                        <MailOutlineIcon
                          sx={{ mb: -0.8, mr: 1, color: "#135c82" }}
                        />
                        {empData.email_id}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid container>
                  <Grid
                    id="EmpQuickLinkhide"
                    sx={{
                      mt: 4,
                      mr: 2,
                      pt: 2,
                      pl: 2,
                      position: "sticky",
                      top: 0,
                    }}
                    className="Card"
                    container
                    xs={12}
                    sm={12}
                    md={1.8}
                    lg={1.8}
                  >
                    <div style={{ height: "300px" }}>
                      <Grid container flexDirection="column">
                        <Typography sx={{ fontSize: "18px", pb: 2, fontFamily: "Barlow-Bold", }}>
                          <b>Quick Links</b>
                        </Typography>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            id={
                              quickactive === "quickactive"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            sx={{ fontWeight: 600, fontFamily: "Barlow-Bold", }}
                            onClick={() => {
                              CompanyDetailsRoute.current.scrollIntoView({
                                behavior: "smooth",
                              });
                              setQuickActive("quickactive");
                            }}
                          >
                            Company Details
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          {" "}
                          <Typography
                            sx={{ fontWeight: 600, fontFamily: "Barlow-Bold", }}
                            id={
                              quickactive === "quickactive2"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            onClick={() => {
                              GovermentProofRoute.current.scrollIntoView({
                                behavior: "smooth",
                              });
                              setQuickActive("quickactive2");
                            }}
                          >
                            Goverment Proof
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            id={
                              quickactive === "quickactive3"
                                ? "quickactive"
                                : "quickactive1"
                            }
                            sx={{ fontWeight: 600 , fontFamily: "Barlow-Bold",}}
                            onClick={() => {
                              ContactDetailsRoute.current.scrollIntoView({
                                behavior: "smooth",
                              });
                              setQuickActive("quickactive3");
                            }}
                          >
                            Contact Details
                          </Typography>
                        </Grid>

                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <div
                            style={{ display: "flex", gap: "30px" }}
                            onClick={handleClick}
                          >
                            <Typography
                              sx={{ fontWeight: 600, fontFamily: "Barlow-Bold", }}
                              id="quickactive1"
                              onClick={handleClick}
                            >
                              Job Post{" "}
                            </Typography>
                            {open ? (
                              <ExpandLess onClick={handleClick} />
                            ) : (
                              <ExpandMore onClick={handleClick} />
                            )}
                          </div>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ fontWeight: 600, pl: 4, fontFamily: "Barlow-Bold", }}
                                id="quickactive1"
                                onClick={() => {
                                  if (
                                    empData.no_of_free_posting <= 0 &&
                                    empData.no_of_hot_posting <= 0 &&
                                    empData.no_of_Standard_posting <= 0
                                  ) {
                                    navigate(
                                      ROUTINGDATA.LAND +
                                        ROUTINGDATA.MAIN +
                                        ROUTINGDATA.SUBSCRIPTIONS,
                                      {
                                        state: userDetails.userId,
                                      }
                                    );
                                  } else {
                                    navigate(
                                      ROUTINGDATA.LAND +
                                        ROUTINGDATA.MAIN +
                                        ROUTINGDATA.POSTJOB,
                                      {
                                        state: userDetails.userId,
                                      }
                                    );
                                  }
                                }}
                              >
                                Post New Job
                              </ListItemButton>
                              <ListItemButton
                                sx={{ fontWeight: 600, pl: 4 , fontFamily: "Barlow-Bold",}}
                                id="quickactive1"
                                onClick={() => {
                                  navigate(
                                    ROUTINGDATA.LAND +
                                      ROUTINGDATA.MAIN +
                                      ROUTINGDATA.POSTEDJOB,
                                    {
                                      state: userDetails.userId,
                                    }
                                  );
                                }}
                              >
                                Posted Jobs
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </Grid>
                        {/* <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 600 }}
                            id="quickactive1"
                            onClick={() => {
                              navigate(
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.APPLIEDSEEKERS,
                                {
                                  state: userDetails.userId,
                                }
                              );
                            }}
                          >
                            Applied Seekers
                          </Typography>
                        </Grid> */}
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <Typography
                            sx={{ fontWeight: 600, fontFamily: "Barlow-Bold", }}
                            id="quickactive1"
                            onClick={() => {
                              navigate(
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.SUBSCRIPTIONS,
                                {
                                  state: userDetails.userId,
                                }
                              );
                            }}
                          >
                            Buy Posting
                          </Typography>
                        </Grid>
                        <Grid sx={{ pb: 2, cursor: "pointer" }}>
                          <div
                            style={{ display: "flex",justifyContent:"space-between", gap: "0px" }}
                            onClick={handleClick2}
                          >
                            <Typography
                              sx={{ fontWeight: 400,
                                fontFamily: "Barlow-Bold", }}
                              id="quickactive1"
                              onClick={handleClick2}
                            >
                              Resume Access{" "}
                            </Typography>
                            {open2 ? (
                              <ExpandLess onClick={handleClick2}/>
                            ) : (
                              <ExpandMore onClick={handleClick2}/>
                            )}
                          </div>
                          <Collapse in={open2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            {trialExpiry === ""?null:(<Tooltip
                                title={trialExpiry === ""?"Job Seeker Search Closed":
                                   "7 Days Access Only"
                                }
                                arrow
                              >
                             <Typography> <ListItemButton
                                  // disabled={
                                  //   trialExpiry === ""
                                  //     ? true
                                  //     : false
                                  // }
                                  sx={{ fontWeight: 400,fontFamily: "Barlow-Bold", pl: 4 }}
                                  id="quickactive1"
                                  onClick={() => {
                                    navigate(
                                      ROUTINGDATA.LAND +
                                        ROUTINGDATA.MAIN +
                                        ROUTINGDATA.TRIALVIEWACCESS,
                                      {
                                        state: userDetails,
                                      }
                                    );
                                  }}
                                >
                                  Job Seeker Search
                                </ListItemButton>
                                </Typography>
                                </Tooltip>)}
                           <ListItemButton
                                sx={{ fontWeight: 400,fontFamily: "Barlow-Bold", pl: 4 }}
                                id="quickactive1"
                                onClick={() => {
                                  
                                  navigate(
                                    ROUTINGDATA.LAND +
                                      ROUTINGDATA.MAIN +
                                      ROUTINGDATA.BUYDATABASEACCESS,
                                    {
                                      state: userDetails,
                                    }
                                  );
                            
                                }}
                              >
                                Buy Resume Access
                              </ListItemButton>
                              <Tooltip
                                title={
                                  empDatabaseAccess?.data_access_status ===
                                  "Open"
                                    ? null
                                    : "Buy Access first"
                                }
                                arrow
                              >
                             <Typography> <ListItemButton
                                  disabled={
                                    empDatabaseAccess?.data_access_status ===
                                    "Open"
                                      ? false
                                      : true
                                  }
                                  sx={{ fontWeight: 400,fontFamily: "Barlow-Bold", pl: 4 }}
                                  id="quickactive1"
                                  onClick={() => {
                                    navigate(
                                      ROUTINGDATA.LAND +
                                        ROUTINGDATA.MAIN +
                                        ROUTINGDATA.VIEWDATABASE,
                                      {
                                        state: userDetails,
                                      }
                                    );
                                  }}
                                >
                                  View Resumes
                                </ListItemButton>
                                </Typography>
                                </Tooltip>
                              <Tooltip
                                title={
                                  empDatabaseAccess?.data_access_status ===
                                  "Open"
                                    ? null
                                    : "Buy Access first"
                                }
                                arrow
                              > 
                                <ListItemButton
                                  disabled={
                                    empDatabaseAccess?.data_access_status ===
                                    "Open"
                                      ? false
                                      : true
                                  }
                                  sx={{
                                    fontWeight: 400,
                                    fontFamily: "Barlow-Bold",
                                    pl: 4,
                                  }}
                                  id="quickactive1"
                                  onClick={() => {
                                    setOpenShort(!openShort);
                                  }}
                                >
                                  Shortlisted Data
                                  <KeyboardArrowDown
                                    sx={{
                                      marginLeft: "2px",
                                      transform: openShort
                                        ? "rotate(-180deg)"
                                        : "rotate(0)",
                                      transition: "0.2s",
                                    }}
                                  />
                                </ListItemButton>
                                {openShort &&
                                  empDatabaseAccessArray.map((item) => (
                                    <List component="div" disablePadding>
                                      <ListItemButton
                                        key={item._id}
                                        sx={{
                                          py: 0,
                                          minHeight: 32,
                                          fontSize:12.4,
                                          pl: 1,
                                          fontWeight: 200,
                                    fontFamily: "Barlow-Bold",
                                        }}
                                        id="quickactive1"
                                        onClick={() => {
                                          getAppliedSeekers(item._id)
                                          setempDatabaseAccessArrayid(item._id)
                                        }}
                                      >
                                        <ListItemIcon
                                          sx={{
                                            color: "#135c82",
                                            minWidth: "2px",
                                          }}
                                        >
                                          <ArrowRightIcon />
                                        </ListItemIcon>
                                        {`${item.data_accessType}(${item.data_access_taken_Date})`}
                                      </ListItemButton>
                                    </List>
                                  ))}
                                  </Tooltip>
                            </List>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid
                    id="EmpUpdscroll"
                    sx={{
                      mt: 2,
                    }}
                    container
                    xs={12}
                    sm={12}
                    md={9.4}
                    lg={10}
                  >
                    <Grid
                      ref={CompanyDetailsRoute}
                      className="Card"
                      sx={{ p: 4, mt: 2 }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <CompanyDetails setput={setput} />
                    </Grid>

                    <Grid
                      ref={GovermentProofRoute}
                      className="Card"
                      sx={{ p: 4, mt: 2 }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <CompanyProof setput={setput} />
                    </Grid>

                    <Grid
                      ref={ContactDetailsRoute}
                      className="Card"
                      sx={{ p: 4, mt: 2 }}
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <ContactPersonDetails setput={setput} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </ThemeProvider>
          </div>
        </Context.Provider>
        <Dialog
            open={selectjobrolePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" } }}
          >
              <DialogTitle
                id="alert-dialog-title"
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                {"Confirmation"}
              </DialogTitle>

              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText id="alert-dialog-description">
                  <Typography sx={{ mb: 2, color: "black", fontSize: "17px" }}>
                    <b>
                      Select Job Title
                        </b>
                  </Typography>
                </DialogContentText>
                <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Grid item xs={12}> 
                {empDatabaseJobRoleArray && (
                          <FormControl sx={{ mt: 3, mb: 1 }} fullWidth required>
                            <Autocomplete
                              disableClearable={empDatabaseJobRole ? false : true}
                              filterOptions={filterOptions}
                              id="job_title"
                              name="job_title"
                              options={empDatabaseJobRoleArray}
                              onChange={(event, value) => {
                                setempDatabaseJobRole(value);
                                seterrorJobTittle(true)
                              }}
                              value={empDatabaseJobRole}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="job_title"
                                  label={"Job Title"}
                                  name="job_title"
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start", // Placement set to "bottom-start"
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                           {errorJobTittle && (empDatabaseJobRole === ""|| empDatabaseJobRole === undefined|| empDatabaseJobRole === null)? (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      Job Title must be required
                    </div>
                  ) : null}
                          </FormControl>
                        )} 
                </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                  setSelectJobRolePopup(false)
                  setempDatabaseJobRole("");
                  seterrorJobTittle(false)
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  //disabled={notes && !notesError && !titleJobError? false : true}
                  onClick={()=>{
                    setSelectJobRolePopup(false)
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.SEEKERSAPPLIED,
                      {
                        state: {
                          id: empDatabaseAccessArrayid,
                          source_from: `Database-${empDatabaseAccessArrayid}`,
                          job_title:empDatabaseJobRole
                        },
                      }
                    );
                   }}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
          </Dialog>
          <Dialog
            open={postJob}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { width: "500px" } }}
          >
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
                No Seekers shortlisted from this resume access!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => { 
                  setPostJob(false);
                }}
                autoFocus
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        {" "}
        <Dialog TransitionComponent={Transition} open={declined}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Declined alert !
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {
                "Your profile is declined by admin so please contact contactus@usglobalsolutions.com."
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeclined(false);
                localStorage.removeItem("user");
                navigate(`${ROUTINGDATA.LAND}`);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div class="dots"></div>
      </div>
    );
  }
};

export default EmployerUpdate;
