/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Drawer,
  useMediaQuery,
  Box,
  CssBaseline,
  Toolbar,
  Tooltip,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  LogoutRounded as LogoutRoundedIcon,
  WorkRounded as WorkRoundedIcon,
  LoginRounded as LoginRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  PersonRounded as PersonRoundedIcon,
  DashboardRounded as DashboardRoundedIcon,
  ContactSupportSharp as ContactSupportSharpIcon,
  VolunteerActivism as VolunteerActivismIcon,
  AccountCircle as AccountCircleIcon,
  PeopleAltRounded as PeopleAltRoundedIcon,
  Settings as SettingsIcon,
  EngineeringRounded as EngineeringRoundedIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  HandshakeRounded as HandshakeRoundedIcon,
  Paid as PaidIcon,
} from "@mui/icons-material";

import "./admin-components/AdminPanel.css";
import logo from "../../img/logo_for-landingpage-3.png";

import { Outlet, useLocation, useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import LogOutDialog from "./admin-components/DialogBoxes/LogOutDialog";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import useAxios from "../../api/useAxios";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      [theme.breakpoints.down("sm")]: { marginLeft: 0 },
      marginLeft: `${drawerWidth + 50}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [[theme.breakpoints.up("md")] || [theme.breakpoints.down("md")]]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth + 50}px)`,
      //  marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("sm")]: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundColor: "red",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function AdminPanel() {
  const axiosData = useAxios();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  const navigate = useNavigate();

  let act = sessionStorage.getItem("active");
  let adminDetails = JSON.parse(localStorage.getItem("admin"));
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));

  let emailName;
  if (adminDetails) {
    emailName = adminDetails.emailId;
  } else if (adminDetails_CEO) {
    emailName = adminDetails_CEO.emailId;
  }
  //const [active, setActive] = useState(act);

  const [open, setOpen] = useState(true);
  const [openLog, setOpenLog] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState({ one: false, two: false });

  function handleClick(event) {
    sessionStorage.setItem("active", event);
    //setActive(act);
  }
  const collapseClick = (g) => {
    setCollapseOpen({ ...collapseOpen, [g]: !collapseOpen[g] });
  };

  useEffect(() => {
    if (emailName === null || emailName === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      let currentUrl = location.pathname;
      if (currentUrl === ROUTINGDATA.ADMINDASHBOARD) {
        sessionStorage.setItem("active", "dash");
      } else if (currentUrl === ROUTINGDATA.ADMINSEEKERS) {
        sessionStorage.setItem("active", "seeker");
      } else if (currentUrl === ROUTINGDATA.ADMINEMPLOYER) {
        sessionStorage.setItem("active", "employer");
      } else if (currentUrl === ROUTINGDATA.USER) {
        sessionStorage.setItem("active", "user");
      } else if (currentUrl === ROUTINGDATA.ADMINNeedSupport) {
        sessionStorage.setItem("active", "need");
      } else if (currentUrl === ROUTINGDATA.ADVERTISEMENTSETTING) {
        sessionStorage.setItem("active", "advertisement");
      } else if (currentUrl === ROUTINGDATA.PAYMENTVENDORS) {
        sessionStorage.setItem("active", "paymentvendors");
      }
    }
  }, [location.pathname]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          boxShadow: 0,
        }}
        position="fixed"
        open={open}
      >
        <Toolbar className="bar">
          <div>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  open ? setOpen(false) : setOpen(true);
                }}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </div>
          <div className="flex">
            <Typography sx={{ fontFamily: "Barlow-Bold" }}>
            {process.env.REACT_APP_WEBSITE_NAME}{process.env.REACT_APP_WEBSITE_NAME_TYPE}
            </Typography>
          </div>

          <Tooltip
            title={
              <div>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                  }}
                >
                 {process.env.REACT_APP_WEBSITE_NAME} account
                </span>
                <p
                  style={{
                    margin: "3px 0px",
                    fontSize: "13px",
                    textTransform: "uppercase",
                  }}
                >
                  admin
                </p>
                <p style={{ fontSize: "12px", margin: "0" }}>{emailName}</p>
              </div>
            }
            placement="bottom-end"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            // anchorOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            // keepMounted
            // transformOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  // mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    // ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(
                  ROUTINGDATA.ADMINSETTINGS + "/" + ROUTINGDATA.ADMINAPPROVAL
                );
                sessionStorage.setItem("active", "");
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
            {adminDetails_CEO?.emailId ===
            process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
              <MenuItem
                onClick={() => {
                  handleClose();
                  const email = process.env.REACT_APP_EMAILID_FOR_ADMIN;
                  const email_id =process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN;
                  axiosData.post("password", { email_id });
                  navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.OtpforPassword}`, {
                    state: { email_id: email },
                  });
                }}
              >
                <ListItemIcon>
                  <LockResetIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Change Admin Password</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenLog(true);
              }}
            >
              <ListItemIcon>
                <LoginRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth + 50,
            boxSizing: "border-box",
            backgroundColor: "#339fcd",
          },
          [theme.breakpoints.down("sm")]: {
            "& .MuiDrawer-paper": {
              backgroundColor: "#339fcd",
              width: drawerWidth,
              flexShrink: 0,
            },
          },
          "& .MuiDrawer-paper::-webkit-scrollbar": {
            width: "5px",
            transition: "0.5s",
          },
          "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            backgroundColor: "#13516b",
          },
        }}
        variant={
          isMobile
            ? "temporary"
            : isDevice || isDeviceDown
            ? "persistent"
            : null
        }
        anchor="left"
        open={open}
      >
        <DrawerHeader className="header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image" src={logo} alt="" />
            </div>
            <div
              style={{
                display: "flex",

                alignItems: "center",
              }}
            >
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </Box>
        </DrawerHeader>
        <List>
          <ListItemText>
            <span className="sidehead">Navigation</span>
          </ListItemText>
          <ListItem sx={{ mt: 1, padding: "1px 10px" }}>
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "dash" ? "active" : "link-active"}
                onClick={(e) => {
                  handleClick("dash");
                  navigate(ROUTINGDATA.ADMINDASHBOARD);
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <DashboardRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Dashboard</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>

          <ListItem sx={{ padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "seeker" ? "active" : "link-active"}
              onClick={() => {
                handleClick("seeker");
                collapseClick("one");
                navigate(ROUTINGDATA.ADMINSEEKERS);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <WorkRoundedIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Job Seekers</span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem sx={{ padding: "1px 10px" }}>
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "support" ? "active" : "link-active"}
              onClick={() => {
                handleClick("support");
                navigate(ROUTINGDATA.ADMINNeedSupport);
                setCollapseOpen(false);
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <ContactSupportSharpIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Need Support </span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem
            // className="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMINEMPLOYER);
            }}
          >
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "employer" ? "active" : "link-active"}
                onClick={() => {
                  handleClick("employer");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <PersonSearchRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Employers</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>

          <ListItem
            // className="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMINVOLUNTEER);
            }}
          >
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "volunteer" ? "active" : "link-active"}
                onClick={() => {
                  handleClick("volunteer");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <VolunteerActivismIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Volunteers</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
          <ListItem
            // className="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMINMENTOR);
            }}
          >
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "mentor" ? "active" : "link-active"}
                onClick={() => {
                  handleClick("mentor");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <PeopleAltRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Mentors</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
          <ListItem
            // className="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMINSERVICEPROVIDER);
            }}
          >
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "serviceProvider" ? "active" : "link-active"}
                onClick={() => {
                  handleClick("serviceProvider");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <EngineeringRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">Service Provider</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>

          <ListItem
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMININVESTOR);
            }}
          >
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "investor" ? "active" : "link-active"}
              onClick={() => {
                handleClick("investor");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <CurrencyExchangeRoundedIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Investors Data </span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADMINPARTNER);
            }}
          >
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "partner" ? "active" : "link-active"}
              onClick={() => {
                handleClick("partner");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <HandshakeRoundedIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Partners Data </span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem> */}
          <ListItem
            sclassName="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate("user");
            }}
          >
            <a className="hover">
              <ListItemButton
                sx={{ borderRadius: "10px" }}
                className={act === "user" ? "active" : "link-active"}
                onClick={() => {
                  handleClick("user");
                  handleDrawerClose();
                }}
              >
                <ListItemIcon>
                  <PersonRoundedIcon className="text" />
                </ListItemIcon>
                <ListItemText className="text" sx={{ color: "#fff" }}>
                  <span className="font">User</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
          <ListItem
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.ADVERTISEMENTSETTING);
            }}
          >
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "advertisement" ? "active" : "link-active"}
              onClick={() => {
                handleClick("advertisement");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <ManageHistoryIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Advertisement History </span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              navigate(ROUTINGDATA.PAYMENTVENDORS);
            }}
          >
            <ListItemButton
              sx={{ borderRadius: "10px" }}
              className={act === "paymentVendors" ? "active" : "link-active"}
              onClick={() => {
                handleClick("paymentVendors");
                handleDrawerClose();
              }}
            >
              <ListItemIcon>
                <PaidIcon className="text" />
              </ListItemIcon>
              <ListItemText
                className="text"
                sx={{
                  color: "#fff",
                }}
              >
                <span className="font">Payment Vendors</span>{" "}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            className="link-active"
            sx={{ padding: "1px 10px" }}
            onClick={() => {
              handleDrawerClose();
              setOpenLog(true);
            }}
          >
            <a className="hover">
              <ListItemButton sx={{ borderRadius: "10px" }}>
                <ListItemIcon>
                  <LogoutRoundedIcon className="text" sx={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  className="text"
                  sx={{ color: "#fff", fontWeight: 800 }}
                >
                  <span className="font">Log Out</span>
                </ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
        </List>
      </Drawer>

      <Main open={open} className="main">
        <DrawerHeader />

        <Outlet />
      </Main>
      <LogOutDialog
        openLog={openLog}
        setOpenLog={setOpenLog}
        act={"active"}
        verifier={
          adminDetails_CEO?.emailId ===
          process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
            ? "admin_dev"
            : "admin"
        }
      />
    </Box>
  );
}
