import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import useAxios from "../../../../api/useAxios";
import { useLocation } from "react-router";
import { useFormik } from "formik";

import Context from "../Context";
import { university } from "./Univercity";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Education({ setput }) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = React.useState(false);
  const [SelectedUniversity, setSelectedUniversity] = useState([]);
  const [year, setYear] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "ArrowUp", "ArrowDown"];

  const [educationName, setEducationName] = useState();

  const handleClickOpen = (Education) => {
    setOpen(true);

    formik.setFieldValue("Education", Education.Education);
    formik.setFieldValue("Institute", Education.Institute);
    formik.setFieldValue("course", Education.course);
    formik.setFieldValue("courseDuration", Education.courseDuration);
    formik.setFieldValue("grade", Education.grade);
  };

  const handleClose = () => {
    formik.handleReset("");
    setOpen(false);
  };
  const axiosData = useAxios();

  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      Education: "",
      Institute: "",
      course: "",
      courseDuration: "",
      grade: "",
    },
    validationSchema: Yup.object({
      Education: Yup.string().required("Education must be required"),
      Institute: Yup.string().required("University name must be required"),
      course:
        educationName === "Post Graduate(PG)" ||
        educationName === "Graduate(UG)" ||
        educationName === "DIPLOMA" ||
        educationName === "ITI"
          ? Yup.string()
              .max(50, "Must be 50 characters or less")
              .required("Degree name must be required")
          : Yup.string(),
      courseDuration: Yup.number().required("Passed out year must be required"),
      grade: educationName === "Post Graduate(PG)" ||
      educationName === "Graduate(UG)" ||
      educationName === "DIPLOMA" ||
      educationName === "ITI" ? Yup.number()
        .required("Marks must be Required")
        .min(50, "Minimum % must be higher than 50")
        .max(100, "Maximum % must be less than 100"):Yup.number()
        .required("Marks must be required")
        .min(35, "Minimum % must be higher than 35")
        .max(100, "Maximum % must be less than 100"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await axiosData.put(`seeker/email/${location.state}`, {
        Education: {
          Education: values.Education,
          Institute: values.Institute,
          course: values.course,
          courseDuration: values.courseDuration,
          grade: values.grade,
        },
      });
      setput(true);
      setIsSubmitting(false);
      formik.handleReset("");
      handleClose();
    },
  });

  const years = Array.from(
    { length: new Date().getFullYear() - 1980 },
    (_, i) => {
      const year = new Date().getFullYear() - i;
      return { year: year, label: year.toString() };
    }
  );

  const handleEducationChange = () => {
    // const selectedEducation = event.target.value;
    // formik.setFieldValue("Education", selectedEducation);
    formik.setFieldValue("Institute", "");
    formik.setFieldValue("course", "");
    formik.setFieldValue("courseDuration", "");
    formik.setFieldValue("grade", "");
  };

  const [universityData, setUniversityData] = useState([]);
  const [newUniversity, setNewUniversity] = useState("");

  const updatedOptions =
    newUniversity && !universityData.includes(newUniversity)
      ? [...universityData, newUniversity]
      : universityData;

  const universityName = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.universityName;
      if (data) {
        setUniversityData(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const [eductionType, setEductionType] = useState([]);
  const [schoolType, setSchoolType] = useState([]);

  const educationData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/education`);
      const data = getOneAxios?.data?.educationData;
      const data1 = getOneAxios?.data?.schoolType;
      if (data) {
        setEductionType(data);
        setSchoolType(data1);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    setEducationName(formik.values.Education);
  }, [formik.values.Education]);

  useEffect(() => {
    universityName();
    educationData();
  }, []);

  return (
    <Context.Consumer>
      {(context) => {
        let Education = "";
        if (context.profile.Education) {
          Education = context.profile.Education;
        }

        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              sx={{ "& .MuiPaper-root": { width: "500px" },zIndex:"99999" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">
                    <b>Education</b>
                  </Typography>
                  <Grid item xs={12} sm={12} md={8} square>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        mt: 1,
                      }}
                      color="text.secondary"
                    >
                      Mention your higher Education details.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ p: 3 }}>
                        <FormControl className="Educationfieldsize">
                          <Autocomplete
                            disableClearable={
                              formik.values.Education ? false : true
                            }
                            name="Education"
                            id="Education"
                            options={eductionType}
                            value={formik.values.Education}
                            onChange={(e, newValue) => {
                              formik.setFieldValue("Education", newValue);
                              handleEducationChange();
                            }}
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField {...params} label="Higher Education" />
                            )}
                          />
                          {formik.touched.Education &&
                            formik.errors.Education && (
                              <Typography
                                sx={{ color: "red", fontSize: "13px" }}
                              >
                                {formik.errors.Education}
                              </Typography>
                            )}
                        </FormControl>

                        {educationName === "HSC(12th)" ||
                        educationName === "SSLC(10th)" ||
                        educationName === "Below 10th" ? (
                          <Tooltip
                            title={
                              formik.values.Education
                                ? null
                                : "Choose higher education"
                            }
                            arrow
                          >
                            <FormControl
                              sx={{ mt: 2 }}
                              className="Educationfieldsize"
                            >
                              <Autocomplete
                                disabled={!educationName}
                                disableClearable={
                                  formik.values.course ? false : true
                                }
                                name="course"
                                id="course"
                                options={schoolType}
                                value={formik.values.course}
                                onChange={(e, newValue) => {
                                  formik.setFieldValue("course", newValue);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField {...params} label="School Type" />
                                )}
                              />
                              {formik.touched.course &&
                                formik.errors.course && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "13px" }}
                                  >
                                    {"School Type must be required"}
                                  </Typography>
                                )}
                            </FormControl>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              formik.values.Education
                                ? null
                                : "Choose higher education"
                            }
                            arrow
                          >
                            <FormControl
                              sx={{ mt: 2 }}
                              className="Educationfieldsize"
                            >
                              <TextField
                                disabled={!educationName}
                                placeholder={educationName === "ITI" || educationName === "DIPLOMA" ? "Enter Branch name" :"Enter Degree name"}
                                name="course"
                                label={educationName === "ITI" || educationName === "DIPLOMA"  ? "Branch name" :"Degree name"}
                                value={formik.values.course}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="course"
                              />
                              {formik.touched.course &&
                                formik.errors.course && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "13px" }}
                                  >
                                    { educationName === "ITI" || educationName === "DIPLOMA" ?  "Branch name must be required" : formik.errors.course }
                                  </Typography>
                                )}
                            </FormControl>
                          </Tooltip>
                        )}

                        {educationName === "ITI" ||
                        educationName === "HSC(12th)" ||
                        educationName === "SSLC(10th)" ||
                        educationName === "Below 10th" ? (
                          <Tooltip
                            title={
                              formik.values.Education
                                ? null
                                : "Choose higher education"
                            }
                            arrow
                          >
                            <FormControl
                              sx={{ mt: 2 }}
                              className="Educationfieldsize"
                            >
                              <TextField
                                disabled={!educationName}
                                placeholder={
                                  educationName === "ITI"
                                    ? "Enter institute name"
                                    : "Enter school name"
                                }
                                name="Institute"
                                label={
                                  educationName === "ITI"
                                    ? "Institute name"
                                    : "School name"
                                }
                                value={formik.values.Institute}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="Institute"
                              />
                              {formik.touched.Institute &&
                                formik.errors.Institute && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "13px" }}
                                  >
                                    {educationName === "ITI"
                                      ? "Institute name must required"
                                      : "School name  must required"}
                                  </Typography>
                                )}
                            </FormControl>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              formik.values.Education
                                ? null
                                : "Choose higher education"
                            }
                            arrow
                          >
                            <FormControl
                              sx={{ mt: 2 }}
                              className="Educationfieldsize"
                            >
                              <Autocomplete
                                disabled={!educationName}
                                disableClearable={
                                  formik.values.Institute ? false : true
                                }
                                id="Institute"
                                name="Institute"
                                options={updatedOptions}
                                onChange={(event, value) => {
                                  if (updatedOptions.includes(value)) {
                                    formik.setFieldValue("Institute", value);
                                    setSelectedUniversity(value);
                                    setNewUniversity("");
                                  } else {
                                    setSelectedUniversity("");
                                    setNewUniversity(value);
                                    formik.setFieldValue("Institute", value);
                                  }
                                }}
                                value={formik.values.Institute}
                                onBlur={formik.handleBlur}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "180px",
                                    overflowY: "auto",
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    id="Institute"
                                    label="University Name"
                                    name="Institute"
                                    value={newUniversity}
                                    onChange={(event) =>
                                      setNewUniversity(event.target.value)
                                    }
                                  />
                                )}
                              />
                              {formik.touched.Institute &&
                                formik.errors.Institute && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "13px" }}
                                  >
                                    {formik.errors.Institute}
                                  </Typography>
                                )}
                            </FormControl>
                          </Tooltip>
                        )}

                        <Tooltip
                          title={
                            formik.values.Education
                              ? null
                              : "Choose higher education"
                          }
                          arrow
                        >
                          <FormControl className="Educationfieldsize">
                            <Autocomplete
                              disabled={!educationName}
                              disableClearable={
                                formik.values.courseDuration ? false : true
                              }
                              sx={{ mt: 2 }}
                              id="courseDuration"
                              name="courseDuration"
                              options={years}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  formik.setFieldValue(
                                    "courseDuration",
                                    newValue.year
                                  );
                                } else {
                                  formik.setFieldValue("courseDuration", "");
                                }
                              }}
                              value={formik.values.courseDuration}
                              onBlur={formik.handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="courseDuration"
                                  label="Passed out year"
                                  variant="outlined"
                                />
                              )}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                PaperProps: {
                                  style: {
                                    maxHeight: "50px",
                                  },
                                },
                              }}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                            {formik.touched.courseDuration &&
                              formik.errors.courseDuration && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {formik.errors.courseDuration}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>

                        <Tooltip
                          title={
                            formik.values.Education
                              ? null
                              : "Choose higher education"
                          }
                          arrow
                        >
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              disabled={!educationName}
                              type="number"
                              placeholder="Mention Your Marks"
                              value={formik.values.grade}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  value === "" ||
                                  /^\d{0,3}(\.\d{0,2})?$/.test(value)
                                ) {
                                  formik.handleChange(e);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              id="Grade"
                              label="Marks(%)"
                              name="grade"
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                            <FormHelperText>
                              {formik.touched.grade && formik.errors.grade ? (
                                <Typography
                                  sx={{
                                    color: "red",
                                    fontSize: "13px",
                                    ml: -0.8,
                                  }}
                                >
                                  {formik.errors.grade}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    visibility: "hidden",
                                  }}
                                >
                                  none
                                </Typography>
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                </DialogTitle>

                <DialogActions sx={{ mr: 2, mt: -6, mb: 1 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      formik.isValid && formik.dirty && isSubmitting === false
                        ? false
                        : true
                    }
                    variant="contained"
                    type="submit"
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "#ffffff",

                          marginLeft: 12,
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Grid xs={12} md={12} lg={12}>
              <Box
                id="Education"
                sx={{ fontSize: "18px", textTransform: "uppercase" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <b style={{ color: "rgb(16 143 178)" }}>Education</b>
                  <Button
                    sx={{ ml: -1.5, mt: -1 }}
                    onClick={() => handleClickOpen(Education)}
                  >
                    <Tooltip title="Edit" arrow>
                      <EditIcon />
                    </Tooltip>
                  </Button>
                </Box>
              </Box>
              <hr />
              {Education.Education ? (
                <>
                  {" "}
                  <Typography
                    sx={{ fontWeight: 600 }}
                    variant="h5"
                    component="div"
                    color="text.secondary"
                  >
                    {Education.Institute}
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                    {Education.Education}
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                    {Education.course}
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                    {Education.courseDuration}
                  </Typography>
                  {Education.grade ? (
                    <Typography
                      sx={{ fontWeight: 600 }}
                      color="text.secondary"
                    >{`${Education.grade}${"%"}`}</Typography>
                  ) : null}
                </>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: "14px",
                    mt: 1,
                  }}
                  color="text.secondary"
                >
                  Mention your higher Education details.
                </Typography>
              )}
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default Education;
