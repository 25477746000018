import React, { useEffect, useState } from "react";
import "../Login.css";
import bgImg from "../../../img/login image/job-search.jpg";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useAxios from "../../../api/useAxios";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { ROUTINGDATA } from "../../ROUTINGDATA";

import Divider from "@mui/material/Divider";
import logo from "../../../img/logo_for-landingpage-3.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function ForgotPassword() {
  const axiosData = useAxios();
  const [open, setOpen] = useState(false);
  const [Errormessage, setErrormessage] = useState({
    heading: "Invalid Email ID !",
    message: "Please provide a Registered Email ID.",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email_id: "",
    },
    validationSchema: Yup.object({
      email_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const email_id = values.email_id;
      try {
        const res = await axiosData.post(`users/email`, { email_id: email_id });
        if (res.data === "") {
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        } else if (res.data.status === "InActive") {
          setErrormessage({
            ...Errormessage,
            heading: "InActive Profile",
            message: "Your Profile is InActive Please contact Administrative",
          });
          handleClickOpen();
        } else if (res.data.status === "Active") {
          await axiosData.post("password", { email_id });
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.OtpforPassword}`, {
            state: { email_id: values.email_id },
          });
        } else if (res.data.message === "EmailID not Matched") {
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        }
      } catch (err) {
        if (err.message === "Request failed with status code 204") {
          console.log("err", err);
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        } else {
          console.log(err);
        }
      }
    },
  });
  useEffect(() => {
    window.history.forward();
  }, []);
  return (
    <React.Fragment>
      <div id="forgotpasswordpage" className="forgotpasswordpage">
        <div className="forgotpasswordpage-logo-top">
          <div className="forgotpasswordimg">
            <img src={logo}  onClick={() => navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)} />
          </div>
        </div>
        <div className="forgotpassword-input-box">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Forgot Password</h2>
              </div>
              <div className="forgotpassword-contect">
                <p>
                  Enter your email and we'll send you otp to your Registered
                  email to verify
                </p>
              </div>
              <div className="space-forgotpassword">
                <div className="login-textfield-1">
                  <label>Email ID</label>
                  <TextField
                    id="email_id"
                    variant="outlined"
                    size="small"
                    name="email_id"
                    onChange={formik.handleChange}
                    value={formik.values.email_id}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <FormHelperText>
                    {formik.touched.email_id && formik.errors.email_id ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.email_id}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </div>

                <div className="loginbtn">
                  <Button variant="contained" fullWidth type="submit">
                    next
                  </Button>
                </div>
                <div className="back-to-loginpage">
                  <ArrowBackIosIcon fontSize="13px" sx={{ color: "#2b7695" }} />{" "}
                  <text style={{cursor:"pointer"}}
                   onClick={() => navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)}
                  >
                    Back to Login
                  </text>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "10px",
              padding: "10px 10px",
            },
          }}
        >
          <Box>
            <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
              <div
                style={{ display: "flex", gap: "3px", alignItems: "center" }}
              >
                <ErrorSharpIcon
                  sx={{ mt: -0.3, color: "crimson" }}
                  fontSize="large"
                />
                <Typography variant="h5" sx={{ ml: 0.3 }}>
                  {Errormessage.heading}
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
              >
                {Errormessage.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "lowercase",
                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    textTransform: "lowercase",
                    color: "white",
                    backgroundColor: "#ed1067",
                    borderColor: "#ed1067",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
