import React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import "../../ThankYouForm/Thankyou.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function PageNotFound() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`);
    setOpen(false);
  };
  return (
    <div>
      <Box
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ pt: 4 }}>
          <div className="dialog-title">
            <div style={{ fontSize: "100px" }}>401</div>
            <h1 className="head">No Authorization Found!</h1>
          </div>
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ textAlign: "center", wordSpacing: "1px" }}
              >
                {" "}
                This Page is Not Publically Available ...{" "}
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ wordSpacing: "2px", textAlign: "center" }}
              >
                To access it please login first!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 10px",
          }}
        >
          <Button variant="contained" className="grad1" onClick={handleClose}>
            Login
          </Button>
        </Box>
      </Box>
    </div>
  );
}
export default PageNotFound;
