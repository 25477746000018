import React from "react";
import jsPDF from "jspdf";
import moment from "moment";
import { IconButton } from "@mui/material";
import pdf from "../../../../img/admin-panel/icons8-pdf-48.png";
function ExportPDF({ headers, datas, fileName }) {
  const generatePdf = () => {
    // const string = renderToString(<Print />);
    // const pdf = new jsPDF("landscape");

    // pdf.html(string);
    // pdf.save("pdf");
    const doc = new jsPDF();

    doc.autoTable({
      head: headers,
      body: datas,
      columnStyles: {
        columnWidth: 400, // Set the width of all columns to 50
      },
      headerStyles: { fontSize: 6 },
      bodyStyles: { fontSize: 6, width: 400 },
    });

    doc.save(`${fileName}-(${moment().format("DD-MM-YY")})`);
  };
  return (
    <IconButton size="small" onClick={generatePdf}>
      <img src={pdf} alt="" />
    </IconButton>
  );
}

export default ExportPDF;
