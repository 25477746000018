import React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import tick from "./green-checkmark-line-icon.png";
import "./Thankyou.css";
import { Box, Typography } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Thankyou() {
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    navigate(ROUTINGDATA.LAND);
    setOpen(false);
  };

  return (
    <div>
      <Box
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ pt: 4 }}>
          <div className="dialog-title">
            <img src={tick} alt="" height="100px" width="100px" />
            <h1 className="head">Thank You For Your Registration!</h1>
          </div>
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ textAlign: "center", wordSpacing: "1px" }}
              >
                {" "}
                Your Profile is Under Review for Approval...{" "}
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ wordSpacing: "2px", textAlign: "center" }}
              >
                Contact Administrator +91 9080400330 For Further Details!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 10px",
          }}
        >
          <Button variant="contained" className="grad1" onClick={handleClose}>
            OK
          </Button>
        </Box>
      </Box>
      <Outlet />
    </div>
  );
}

export default Thankyou;
