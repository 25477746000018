import React from "react";
import { Box, Card, Grid, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
import GroupsIcon from '@mui/icons-material/Groups';
// import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import WorkIcon from '@mui/icons-material/Work';
// import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';

const StyledCard = styled(Card)(({ theme }) => ({
    "&.MuiCard-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "16px !important",
        flexDirection: "row",
        gap: "10px",
      },
  
      padding: "25px",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      alignContent: "space-between",
      boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    },
  }));
function StatCardsDatabase({ getApi,empDataAccess,empData}) {
    const fillStatForIndustry = getApi.filter((i) => {
        return i.industry === empData.industry;
      });
      const filStatForFreshers = getApi.filter((i) => {
        return i.work_status === "Fresher";
      });
      const filStatForExperienced = getApi.filter((i) => {
        return i.work_status === "Experienced";
      });
      const [Industry, Experienced, Freshers, ALL] = [
        fillStatForIndustry.length,
        filStatForExperienced.length,
        filStatForFreshers.length,
        getApi.length,
      ];
    
      const cardList = [
        {
          name: "Total Seekers",
          count: ALL,
          bgColor: "#2838a6",
          svg: <GroupsIcon fontSize="large" />,
        },
        // {
        //   name: `${empDataAccess.data_accessType_by_work_status === "Both"?"Total Fresher Seekers":""}`,
        //   count: `${empDataAccess.data_accessType_by_work_status === "Both"?Freshers:null}`,
        //   bgColor: "#20a4a4",
        //   svg: <PersonSearchIcon fontSize="large" />,
        // },
        // {
        //   name: `${empDataAccess.data_accessType_by_work_status === "Both"?"Total Experienced Seekers":""}`,
        //   count: `${empDataAccess.data_accessType_by_work_status === "Both"?Experienced:null}`,
        //   bgColor: "#534646",
        //   svg: <WorkIcon fontSize="large" />,
        // },       
        // {
        //   name: "Industry Based Total Seekers",
        //   count: IndustryDatabase,
        //   bgColor: "#28c7c7",
        //   svg: <LaptopChromebookIcon fontSize="large" />,
        // },
        // {
        //   name: `${empData.industry} Seekers`,
        //   count: Industry,
        //   bgColor: "#28a745",
        //   svg: <LocalLibraryIcon fontSize="large" />,
        // },
      ];
      const cardList2 = [
        {
          name: "Total Seekers",
          count: ALL,
          bgColor: "#2838a6",
          svg: <GroupsIcon fontSize="large" />,
        },
        // {
        //   name: `${empDataAccess.data_accessType_by_work_status === "Fresher"?"Total Fresher Seekers":empDataAccess.data_accessType_by_work_status === "Experienced"?"Total Experienced Seekers":""}`,
        //   count: `${empDataAccess.data_accessType_by_work_status === "Fresher"?Freshers:empDataAccess.data_accessType_by_work_status === "Experienced"?Experienced:null}`,
        //   bgColor: "#534646",
        //   svg: <WorkIcon fontSize="large" />,
        // },
        // {
        //   name: "Industry Based Total Seekers",
        //   count: IndustryDatabase,
        //   bgColor: "#28c7c7",
        //   svg: <LaptopChromebookIcon fontSize="large" />,
        // },
        // {
        //   name: `${empData.industry} Seekers`,
        //   count: Industry,
        //   bgColor: "#28a745",
        //   svg: <LocalLibraryIcon fontSize="large" />,
        // },
      ];
    return (
        <>
          <Grid
            container
            direction="row"
            spacing={5}
            md={12}
            sx={{ mb: "24px", width: "100%" }}
          >
            {empDataAccess.data_accessType_by_work_status === "Fresher" || empDataAccess.data_accessType_by_work_status === "Experienced"?cardList2.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <StyledCard
                  sx={{ backgroundColor: `${item.bgColor}` }}
                  elevation={6}
                >
                  <Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                    >
                      <Box sx={{ color: "white" }}>{item.svg}</Box>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "#f2f2f2",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                  </Box>
    
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </StyledCard>
              </Grid>
            )):cardList.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <StyledCard
                  sx={{ backgroundColor: `${item.bgColor}` }}
                  elevation={6}
                >
                  <Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                    >
                      <Box sx={{ color: "white" }}>{item.svg}</Box>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "#f2f2f2",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                  </Box>
    
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </>
      );
}

export default StatCardsDatabase