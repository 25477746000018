/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  Paper,
  Button,
  Chip,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";
import defaultimg from "../../../../img/mentorImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import EnterContactSupportDialog from "../../../admin-panel/admin-components/DialogBoxes/EnterContactSupportDialog";
import ContactSupportSnack from "../../../admin-panel/admin-components/SnackBars/ContactSupportSnack";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS";

function MPMLAShortList() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAShortlisted;

  const navigate = useNavigate();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const [shortlistedData, setShortListedData] = useState([]);
  const [getApi, setGetApi] = useState([]);
  const [openFormikContactDialog, setOpenFormikContactDialog] = useState(false);
  const [contactSeekDetails, setContactSeekDetails] = useState({
    name: "",
    email_id: "",
    contactName: "",
    mobile: 0,
  });
  const [openSupportSnack, setOpenSupportSnack] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [MPMLALoading, setMPMLALoading] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const MPMLAPageNumbe = sessionStorage.getItem("mpmlashortlist_page_number");
  const MPMLAPageNumber = parseInt(MPMLAPageNumbe);
  const setMPMLAPageNumber = MPMLAPageNumber ? MPMLAPageNumber : 0;
  const shortlisted = sessionStorage.getItem(`mpmla_shortradio`);
  const [mlaMail, setMlaMail] = useState("");
  const state = location.state.mla;

  const load = location.state.load;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const selectedApiForExcel = shortlistedData.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
    };
  });
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMpEmailId = async (value) => {
    try {
      const MpData = await axiosData.post("mpmla/mlaconstituencyseeker", value);
      const { data } = MpData;

      const shortlistedDatas = data[0].shortListed.reverse();
      setGetApi(shortlistedDatas);
      shortlistBy(shortlisted ? shortlisted : "all", shortlistedDatas);
      setMPMLALoading(true);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const shortlistBy = (shortvalue, total) => {
    if (shortvalue === "all") {
      setShortListedData(total);
      setTotalRows(total.length);
      setPage(setMPMLAPageNumber);
    } else if (shortvalue === "MP") {
      const fill = total.filter((d) => {
        return d.supported === "MP";
      });
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setMPMLAPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.supported === "");
      setShortListedData(fill);
      setTotalRows(fill.length);
      setPage(setMPMLAPageNumber);
    }
  };
  useEffect(() => {
    try {
      const mlaname = state.mla_constituency;
      const lowercasemla = mlaname.toLowerCase();
      const value = { email_id: `${lowercasemla}.mp@gmail.com` };
      setMlaMail(value);
      getMpEmailId(value);
      setPage(setMPMLAPageNumber);
    } catch (err) {
      console.log("Error", err);
    }
  }, [state]);
  if (MPMLALoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px", mb: 8 }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {state.mla_constituency}'s&nbsp;Short Listed Data
              </Typography>
            </Card>
          </Box>

          <Card sx={{ boxShadow: 0 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",

                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item md={8}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Filter By Support
                      </FormLabel>
                      <RadioGroup
                        row
                        id="radio-btn"
                        value={shortlisted ? shortlisted : "all"}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ marginRight: "18px" }}
                        onChange={(e) => {
                          sessionStorage.setItem(
                            `mpmla_shortradio`,
                            e.target.value
                          );

                          shortlistBy(e.target.value, getApi);
                        }}
                      >
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value="all" />}
                          label="All"
                        ></FormControlLabel>
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value="Pending" />}
                          label="Pending"
                        ></FormControlLabel>
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value={"MP"} />}
                          label="Completed "
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <Tooltip title="Export As Excel" placement="bottom">
                      <ExportCS
                        selectedApiForExcel={selectedApiForExcel}
                        fileName={"Seeker-ShortListed-Data"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Family Income</TableCell>
                    <TableCell>Supported Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shortlistedData.length > 0 ? (
                    shortlistedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => {
                        const supportedStat =
                          item.supported === "" ? "pending" : "Supported";
                        return (
                          <TableRow className="tablecell">
                            <TableCell>
                              <img
                                src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                height={"50"}
                                width="50"
                                onError={(e) => {
                                  e.target.src = defaultimg;
                                }}
                                style={{ clipPath: "circle(50%)" }}
                                alt="img"
                              />
                            </TableCell>
                            <TableCell>{item.Name}</TableCell>
                            <TableCell>{item.email_id}</TableCell>
                            <TableCell>{item.FamilyIncome}</TableCell>
                            <TableCell>
                              {" "}
                              <Chip
                                label={supportedStat}
                                variant="filled"
                                color={
                                  item.supported === "" ? "warning" : "info"
                                }
                                sx={{
                                  color: "#ffffff",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Full Details" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="Medium"
                                  onClick={() => {
                                    navigate(
                                      `${ROUTINGDATA.LAND}${ROUTINGDATA.MPConstituency}/${ROUTINGDATA.MP_MLAShortlisted_fullDetails}`,
                                      {
                                        state: item.email_id,
                                      }
                                    );
                                    sessionStorage.setItem(
                                      "mpmlashortlist_page_number",
                                      page
                                    );
                                  }}
                                >
                                  <OpenInNewRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              {item.supported === "" ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize ",
                                    boxShadow: 0,
                                    borderRadius: "16px",
                                  }}
                                  startIcon={<FavoriteRoundedIcon />}
                                  onClick={() => {
                                    setContactSeekDetails({
                                      ...contactSeekDetails,
                                      name: item.Name,
                                      email_id: item.email_id,
                                      mobile: item.mobile_number,
                                    });
                                    setOpenFormikContactDialog(true);
                                  }}
                                >
                                  Support
                                </Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <EnterContactSupportDialog
          openFormikContactDialog={openFormikContactDialog}
          setOpenFormikContactDialog={setOpenFormikContactDialog}
          contactSeekDetails={contactSeekDetails}
          setContactSeekDetails={setContactSeekDetails}
          getFunction={getMpEmailId}
          mlaMail={mlaMail}
          setOpenSupportSnack={setOpenSupportSnack}
        />{" "}
        <ContactSupportSnack
          contactName={contactSeekDetails.contactName}
          seekName={contactSeekDetails.name}
          openSupportSnack={openSupportSnack}
          setOpenSupportSnack={setOpenSupportSnack}
        />
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default MPMLAShortList;
